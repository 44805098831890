// ExpanTree.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled  } from '@mui/material/styles';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TopicIcon from '@mui/icons-material/Topic';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

const TreeBox = styled(Box)({
    margin:0,
    padding:0,
});
const ChildrenBox = styled(Box)({
    display:'block',
});
const TreeNodeBox = styled(Box)({
    display:'block'
});
const TreeNodeLineBox = styled(Box)({
    display: 'inline-block',
    cursor: 'pointer',
    height: 30,
    '&:hover': {backgroundColor: '#eeeeee', borderRadius: 5}
});
const NodeLabel = styled('div')({
    display:'inline-block',
    borderRadius: 5,
    padding: 5
});

export const CHILDREN_KEY = "_child_";

export default function ExpanTree({
    data, // tree data array. (ex. VehGroupNode object array). Should have uncheckAll()
    //uniqueKey, // dataKey
    //labelKey,
    //checkKey, // default '_checked'
    rootLabel, // show root if rootLabel is presented !! (no showRoot flag)
    //readOnly, // if true, no event sent.
    disabled,
    onLeafClicked,
    onLeafChecked,
    allNodeCheckable, // if true, parent nodes can be checked.
    exclusive, // if true, only one node can be checked by clicking on it.
    markOption, // special marker
}) {
    const [checked, setChecked]  = useState([]);
    const [clicked, setClicked]  = useState(null);

    const addCheckedNode = (node) => {
        const renewal = [...checked, node];
        setChecked(renewal);
        if(onLeafChecked) onLeafChecked(renewal, true, node); // True means add. (fals=>remove)
    };

    const removeCheckedNode = (node) => {
        const renewal = [];
        for(const one of checked) {
            if(!node.equalTo(one)) renewal.push(one);
        }
        setChecked(renewal);
        if(onLeafChecked) onLeafChecked(renewal, false, node); // True means add. (fals=>remove)
    };

    const onClickNode = (node) => {
        if(disabled) return;
        //if(node.hasChildren()) return;
        setClicked(node);
        if(onLeafClicked) onLeafClicked(node);

        if(onLeafChecked && (allNodeCheckable || !node.hasChildren())) {
            if(exclusive) {
                if(node.isChecked()) return;
                data.forEach((item) => item.uncheckAll());
            }
            node.toggle();
            if(exclusive) {
                setChecked([node]);
                if(onLeafChecked) onLeafChecked([node], true, node); // True means add. (fals=>remove)
            }
            else {
                if(node.isChecked()) addCheckedNode(node);
                else removeCheckedNode(node);
            }
        }
    };

    const renderMarker = (node) => {
        if(markOption) {
            if(node[markOption.keyToCheck]) return markOption.marker;
        }
        return null;
    };

    const renderNode = (node) => {
        return(
            <TreeNodeBox key={node.getPrimaryData()}>
                <TreeNodeLineBox onClick={()=>onClickNode(node)}>
                    {
                        node.hasChildren()
                        ? <FolderOpenIcon fontSize='small' style={{verticalAlign:'middle', color:'#888888'}} />
                        :
                        node.isChecked()
                        ? <CheckBoxIcon fontSize='small' color='primary' style={{verticalAlign:'middle'}} />
                        : <TopicIcon fontSize='small' style={{verticalAlign:'middle'}} />
                    }
                    <NodeLabel style={{
                        marginLeft: 5, 
                        backgroundColor: node.equalTo(clicked) ? '#eeeeee' : 'transparent',
                        color: node.isChecked() ? 'blue' : 'inherit',
                        border: node.equalTo(clicked) ? '1px solid #9073ba' : '0px'
                        }}>
                        {node.getLabel()}
                        {
                            markOption ? renderMarker(node) : null
                        }
                    </NodeLabel>
                    
                </TreeNodeLineBox>
                {
                    node.hasChildren()
                    ? renderList(node.children, true)
                    : null
                }
            </TreeNodeBox>
        );
    };

    const renderList = (list, addIndent) => {
        return(
            <ChildrenBox paddingLeft={addIndent ? 3 : 0}>
                {
                    list.map((node,idx)=>{
                        return(renderNode(node));
                    })
                }
            </ChildrenBox>
        );
    };

    const renderWithFakeTopNode = () => {
        return(
            <TreeBox>
                <TreeNodeLineBox>전체</TreeNodeLineBox>
                {renderList(data, true)}
            </TreeBox>
        );
    };

    if(rootLabel) return renderWithFakeTopNode();
    else return renderList(data)
}
