// MapMain.js
import React, {useEffect, useState} from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import {useLazyQuery, useReactiveVar} from '@apollo/client';
import { Grow } from '@mui/material';
// import { useInterval } from 'react-use';
import NearMeIcon from '@mui/icons-material/NearMe';
import PlaceIcon from '@mui/icons-material/Place';
import HubIcon from '@mui/icons-material/Hub';
import AlertDialog from './com/message/AlertDialog';
import ResponseAlert from './com/message/ResponseAlert';
import MapShell, {MapMode, MapContextMenu} from './com/map/MapShell';
import NaverRouteSearch from './com/map/NaverRouteSearch';
import PopVehPosInfo from './com/map/PopVehPosInfo';
import PopPoiInfo from './com/map/PopPoiInfo';
import PopRoutePointInfo from './com/map/PopRoutePointInfo';
import { AppWord, MapValue, AppPalette, TableColumnType, MapEvent, AppObject } from './model/AppConst';
import { 
    userInfoRepo, poiInfoRepo, appAlertRepo, overlapRepo, vehPosRepo,
    poiEditCommandRepo, tableShapeConfRepo,
    vehPosFilteredRepo, vehPosFilterCriteriaRepo, vehPosSearchRepo, naverRouteRepo,
} from './model/CvoModel';
import MapDataContainer from './com/map/MapDataContainer';
import MapMenuAndWatch from './com/map/MapMenuAndWatch';
import MapPoiInfoBatch from './com/poi_info/MapPoiInfoBatch';
import MapShapeInput from './com/map/MapShapeInput';
import NaverRouteResults from './com/map/NaverRouteResults';
import { VehPosTableColumns } from './com/veh_pos/MapVehPosListRepeat';
import useClientSize from './com/hook/useClientSize';
import ValueUtil from './model/ValueUtil';
import Util from './model/Util';
import { LIST_USER_TCOL_CONF21 } from './com/user_tcol_conf21/UserTcolConf21Gql';

const ViewName = 'MapMain';
const HeaderHeight = 64;
const DataBoxWidth = 400;
const DeviderWidth = 8;
const DeviderMargin = 2;
const DeviderSpaceWidth = DeviderWidth + DeviderMargin * 2;
const MinDataBoxWidth = 300;
const MapBoxId = "id_map";
const TriangleStroke = "rgb(92, 92, 92)";
const TriangleStyle = {fill:TriangleStroke, stroke:TriangleStroke, strokeWidth:1};

const TheContainer = styled(Box)({
    fontSize:'11pt', //    display:'flex'
    position: 'relative', overflow: 'hidden', padding:0,
});
const DataBox = styled(Box)({
    display:'inline-block', position:'relative', //backgroundColor: 'cyan',left:0, top:0,
    // display:'flex', marginRight:DeviderWidth + DeviderMargin * 2, 
});
const DataInnerWrapper = styled(Box)({
    position:'absolute', left:0, top:0, right:0, bottom:0,  // backgroundColor:'yellow',
    display:'flex', // height:'100%',
});
const HeaderAndMap = styled(Box)({
    display: 'inline-block', marginLeft: DeviderSpaceWidth, marginTop:0, //position:'relative',
    // flexGrow:1, display:'flex', flexDirection:'column',
});
// const       TheHeader = styled(Box)({ height:HeaderHeight, display:'flex', alignItems:'center', backgroundColor:AppPalette.PrimaryRGB, });
const       MapBox = styled(Box)({ overflow:'hidden'});

const DeviderBox = styled(Box)({
    position: 'absolute',
    width: DeviderWidth,
    minHeight: 50,
    height: '100%',
    background: 'linear-gradient(to right, #aaaaaa, #eeeeee, #aaaaaa)',
    cursor: 'ew-resize',
    display:'flex', alignItems:'center'
});

const DividerToggleBox = styled(Box)({
    height:80,
});

const TopControlBox = styled(Box)({
    position:'absolute', height: HeaderHeight, top:0, right:0, textAlign:'right', display:'flex', alignItems:'center',
    color:'white', justifyContent:AppWord.END, paddingRight:10,
    backgroundColor:AppPalette.PrimaryRGB
});

// Mouse over시 차량이나 POI 정보를 상단에 보여주고 Mouse out하면 감춘다.
const TopInfoBox = styled(Box)({
    position:'absolute', maxHeight: HeaderHeight + 4, top:1, right:1,
    backgroundColor:'#faffbb', opacity:0.70,
    boxSizing: 'border-box', padding: "1px 5px 2px 5px", overflow: "hidden",
    borderRadius: 10,
});
const TopInfoColumn = styled(Box)({display:'inline-block', paddingLeft:10, verticalAlign:'top'});
const InfoUnitRow = styled(Box)({ display: 'flex', margin:3, textAlign: 'left', alignItems:'center' });
const InfoTitleBox = styled(Box)({minWidth:50, fontSize:'0.7rem', color: '#777', display:'inline-block', padding:1, verticalAlign:'bottom', textAlign:'right'});
const InfoValueBox = styled(Box)({fontSize:'0.8rem', display:'inline-block', overflow:'hidden', padding:1, verticalAlign:'bottom'});

const AnyMenuBoxPadding = 6;
const OverlapRecordHeight = 30;
const OverlapBoxWidth = 240;
const OverlapInfoBox = styled(Box)({
    position:'absolute', backgroundColor:'white', opacity:0.8,
    width: OverlapBoxWidth,
    paddingTop: AnyMenuBoxPadding, paddingBottom: AnyMenuBoxPadding, boxSizing: 'border-box',
    fontSize: '0.9em',
    borderRadius:5, border: '1px solid #a88',overflow:'auto',
});
const OverlapRecord = styled(Box)({
    boxSizing: 'border-box', height: OverlapRecordHeight, 
    paddingLeft: AnyMenuBoxPadding, cursor: 'pointer',
    display: 'flex', alignItems:'center', //backgroundColor:'#eee'
    '&:not(:last-of-type)': {borderBottom: '1px solid #faa',},
    '&:hover':{backgroundColor:'#fdd'}
});

const ContextMenuWidth = 240;
const ContextMenuLineHeight = 30;
const ContextMenuBox = styled(Box)({
    position:'absolute', boxSizing: 'border-box', backgroundColor:'white', paddingTop: AnyMenuBoxPadding, paddingBottom: AnyMenuBoxPadding,
    width: ContextMenuWidth, fontSize: '0.9em', border: '1px solid #88f',
});

const ContextMenuLine = styled(Box)({
    boxSizing: 'border-box', height: ContextMenuLineHeight,paddingLeft: AnyMenuBoxPadding, cursor: 'pointer',
    display: 'flex', alignItems:'center',
    '&:not(:last-of-type)': {borderBottom: '1px solid #cdf',},
    '&:hover':{backgroundColor:'#eef'}
});

function isMatchToFilter(item, criteria) {
    const fkeys = Object.keys(criteria); // {vehTonId: [1, 3], someField: ['good', 'better']}
    var match = 0;
    for(const fkey of fkeys) {
        for(const value of criteria[fkey]) {
            if(item[fkey]===value) {
                match++;
                break;
            }
        }
    }
    return match === fkeys.length;
}

function filterPosBy(pos, vehFilterCriteria, search) {
    if(vehFilterCriteria) {
        if(!isMatchToFilter(pos, vehFilterCriteria)) return false;
    }

    let textMatch = false; // true if search is not set
    if(Boolean(search)) {
        const ts = search.trim();
        if(ts.length > 0) {
            for(const col of VehPosTableColumns) {
                if((col.option.type===TableColumnType.TEXT || col.option.type === TableColumnType.CHAR) && pos[col.id]) {
                    if(pos[col.id].indexOf(ts) >= 0) {
                        textMatch = true;
                    }
                }
            }
        }
        else textMatch = true;
    }
    else textMatch = true;
    return textMatch;
}

// const FetchInterval = 61; // seconds

/*
The field at path '/sweeperStatList' was declared as a non null type, 
but the code involved in retrieving data has wrongly returned a null value. 
The graphql specification requires that the parent field be set to null, 
or if that is non nullable that it bubble up null to its parent and so on. 
The non-nullable type is '[SweeperStat]' within parent type 'Query'
*/

/**
 * Map 화면의 전체 구성. Alert 처리.
 * 최초 지점목록도 여기서 조회해서 전달. (그렇지 않으면 지점 view 선택할 때마다 새로 내려받게 됨)
 * 위치도 여기서 반복 조회함. 그렇지 않으면 다른 view로 넘어갈 때 위치가 갱신되지 못함.
 */
export default function MapMain() {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [openDataBox, setOpenDataBox] = useState(true);
    const [leftWidth, setLeftWidth] = useState(DataBoxWidth);
    const [mapShell, setMapShell] = useState(null);
    // const [naverMap, setNaverMap] = useState(null);
    const [mapMenu, setMapMenu] = useState(sessionInfo.userAs.industryId===2 ? MapMode.Sweeper : MapMode.VehPos);
    const [contextMenuState, setContextMenuState] = useState({open:false, top: 0, left: 0, lat: 0, lon: 0, menus: null});
    const [overlapInfo, setOverlapInfo] = useState(null);
    const overlapInfoRenew = useReactiveVar(overlapRepo);
    const [popVehPos, setPopVehPos] = useState({open:false});
    const [popPoi, setPopPoi] = useState({open:false});
    const [popRoutePoint, setPopRoutePoint] = useState({open:false});
    const vehPosStat = useReactiveVar(vehPosRepo);
    const searchVehPos = useReactiveVar(vehPosSearchRepo);
    const vehFilterCriteria = useReactiveVar(vehPosFilterCriteriaRepo);
    // const [vehPosSelected, setVehPosSelected] = useState(null);
    // const [timeTryAndGet, setTimeTryAndGet] = useState(0); // Date().getTime() - 차량위치 가져온 시각.
    const [responseAlert, setResponseAlert] = useState(null);
    // const [searchRouteInfo, setSearchRouteInfo] = useState({});
    const [openMapPoiBatchInput, setOpenMapPoiBatchInput] = useState(false);
    const [naverRouteFrom, setNaverRouteFrom] = useState(null);
    const [naverRouteTo, setNaverRouteTo] = useState(null);
    const [naverRouteResult, setNaverRouteResult] = useState(null);
    const [topInfoState, setTopInfoState] = useState({open:false});
    const [mapShapeData, setMapShapeData] = useState({open:false, data:{dist:100, vertex:0}});
    const clientSize = useClientSize();
    const appAlerts = useReactiveVar(appAlertRepo);
    const poiInfoRecords = useReactiveVar(poiInfoRepo);
    const naverRoutesInRepo = useReactiveVar(naverRouteRepo);
    
    // ##### Call GraphQL to get List #####
    const [getUserTcolConf21List, responseList] = useLazyQuery(LIST_USER_TCOL_CONF21, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => { console.log(error); }
    });

    const isAdmin = sessionInfo.isEtrace();
    
    // 테이블 설정 조회
    useEffect(()=>{
        if(sessionInfo.signedIn) {
            getUserTcolConf21List();
        }
    }, [sessionInfo, getUserTcolConf21List]);

    // 초기화.
    useEffect(() => {
        const mapBox = document.getElementById(MapBoxId);
        const map = new window.naver.maps.Map(mapBox);
        // setNaverMap(map);
        const shell = new MapShell(map, sessionInfo);
        setMapShell(shell);
        // getPoiInfoList();
        if(sessionInfo) {
            if(sessionInfo.userAs.industryId===2) {
                sessionInfo.ifLocal(()=>console.log("$$$ Get sweeper stat list"));
            }
        }

        const onBubblePopInfoBox = (event) => {
            const {detail} = event;
            if(detail.type===MapValue.MarkerType.POI) {
                setPopPoi({open:true, data: detail.data});
            }
            else if(detail.type === MapValue.MarkerType.VEH_POS) {
                setPopVehPos({open:true, data: detail.data});
            }
            else if(detail.type === MapValue.MarkerType.ROUTE_POINT) {
                setPopRoutePoint({open:true, data: detail.data});
            }
        };
        document.addEventListener(MapValue.MapEvent.PopInfoBox, onBubblePopInfoBox);

        // 이 것을 해주지 않으면 Windows에서 기존 컨텍스트 메뉴가 추가로 뜸.
        document.addEventListener("contextmenu", (event) => {
            event.preventDefault();
        });

        const onBubbleNaverRoutePoint = (event) => {
            const {detail} = event;
            if(detail.isStart) {
                setNaverRouteFrom(detail.data);
            }
            else {
                setNaverRouteTo(detail.data);
            }
        };
        document.addEventListener(MapValue.MapEvent.SetRoutePoint, onBubbleNaverRoutePoint);
        return ()=>{
            document.removeEventListener(MapValue.MapEvent.PopInfoBox, onBubblePopInfoBox);
            document.removeEventListener(MapValue.MapEvent.SetRoutePoint, onBubbleNaverRoutePoint);
        };
    },[]);

    /*
    Context menu - MapShell.js 참조
    context menu를 MapDataControl로 넣을 수 없음 - 사라지는 상태도 있기 때문에...
    */
    useEffect(()=>{
        const onMapRightClick = (event) => {
            const menus = mapShell.getContextMenuList();
            if(menus===null) return;
            if(menus.length===0) return;
            const count = menus.length + 1; // 1 for close
            const height = ContextMenuLineHeight * count + AnyMenuBoxPadding * 2;
    
            const left = event.originalEvent.pageX > window.innerWidth - ContextMenuWidth - 10 ? event.originalEvent.pageX - ContextMenuWidth : event.originalEvent.pageX;
            const top = event.originalEvent.pageY > window.innerHeight - height - 10 ? event.originalEvent.pageY - height : event.originalEvent.pageY;
            const coordLat = event.coord._lat;
            const coordLon = event.coord._lng;

            setContextMenuState({
                open: true,
                top: top, left: left,
                lat: coordLat, lon: coordLon,
                menus: menus,
            });
        };

        if(mapShell) {
            mapShell.setMapEventHandler(MapEvent.RIGHT_CLICK, MapEvent.RIGHT_CLICK+ViewName, onMapRightClick);
            mapShell.setMouseOverMarkerCallback((data) => {
                if(data) {
                    setTopInfoState({open:true, data: data});
                }
                else setTopInfoState({open:false});
            });
        }
    }, [mapShell]);

    useEffect(()=>{
        if(mapShell && sessionInfo.signedIn) {
            const { viewLevel, initXcoord, initYcoord, initLat, initLon } = sessionInfo.userAs;
            if(initLat>=30.0, initLon>=110.0) {
                mapShell.setCenter(initLat, initLon, viewLevel || MapValue.Level.ZOOM_INIT);
            }
            else if(initXcoord>0 && initYcoord>0) {
                const {lat,lon} = mapShell.katek2ll({x:initXcoord, y:initYcoord});
                mapShell.setCenter(lat,lon, viewLevel || MapValue.Level.ZOOM_INIT);
            }
        }
    }, [mapShell, sessionInfo.signedIn, sessionInfo.userAs]);

    useEffect(()=>{
        if(overlapInfoRenew) {
            setOverlapInfo(overlapInfoRenew);
        }
        else setOverlapInfo(null);
    }, [overlapInfoRenew]);

    // 검색, 핉터 등이 변화하면 필터된 데이터를 repo테 넣고 지도에도 갱신한다.
    useEffect(()=>{
        // filter here. TODO: filter 내용을 상위 컴포넌트로 보내서 지도에 그 것만 나오게 해야 함.
        const records = vehPosStat.records.filter((pos)=>filterPosBy(pos, vehFilterCriteria, searchVehPos));
        sessionInfo.ifLocal(()=>console.log(`Search ${searchVehPos}. filtered ${records.length}`));
        vehPosFilteredRepo(records);
        if(mapShell) {
            mapShell.setVehPosList(records);
            mapShell.showVehPosMarkers();
        }
    }, [vehPosStat.records, searchVehPos, vehFilterCriteria, mapShell, sessionInfo]);

    useEffect(()=>{
        if(mapShell) {
            mapShell.setPoiRecords(poiInfoRecords);
            mapShell.showPoiMarkers();
        }
    }, [poiInfoRecords, mapShell]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.userTcolConf21List) tableShapeConfRepo(ValueUtil.reformTableColumnListToObject(data.userTcolConf21List));

    };

    // <<<<<<<<<<<<<<<<<<<<<<<<<<< Devider >>>>>>>>>>>>>>>>>>>>>>>>>
    const onMouseMoveDevider = (event) => {
        if (event.buttons === 1) {
            const max = clientSize.width * 0.75;
            const w = event.clientX - DeviderMargin;
            setLeftWidth(w < MinDataBoxWidth ? MinDataBoxWidth : w > max ? max : w);
            event.stopPropagation();
        }
    };
    const onMouseUp = () => {
        document.removeEventListener(AppWord.EV_MOUSE_MOVE, onMouseMoveDevider);
        document.removeEventListener(AppWord.EV_MOUSE_UP, onMouseUp);
    };

    const onMouseDown = (event) => {
        if(openDataBox) {
            document.addEventListener(AppWord.EV_MOUSE_MOVE, onMouseMoveDevider);
            document.addEventListener(AppWord.EV_MOUSE_UP, onMouseUp);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const AdditilnalMargin = 4;
    const MapBoxWidth
        = openDataBox
        ? clientSize.width - leftWidth - DeviderSpaceWidth - AdditilnalMargin
        : clientSize.width - DeviderSpaceWidth - AdditilnalMargin;

    const toggleDataBox = () => {
        const MapWidth
            = openDataBox
            ? clientSize.width - DeviderSpaceWidth - AdditilnalMargin
            : clientSize.width - leftWidth - DeviderSpaceWidth - AdditilnalMargin;

        const size = new window.naver.maps.Size(
            MapWidth,
            clientSize.height - HeaderHeight - 2
        );
        //naverMap.setSize(size);
        if(mapShell) mapShell.setSize(size);
        /*
        
        const size = naverMap.getSize();
        size.width = openDataBox ? clientSize.width - leftWidth - DeviderSpaceWidth : clientSize.width - DeviderSpaceWidth;
        naverMap.setSize(size);
        */
        setOpenDataBox(!openDataBox);
    };

    const onClickSetMenu = (menuId) => {
        setMapMenu(menuId);
        if(mapShell) {
            mapShell.setMapMode(menuId);
        }
    };

    const onMouseOutOverlapBox = (e) => {
        //overlapRepo(null);
        if(overlapInfoRenew) setOverlapInfo(null);
    };

    const setNaverTrackStart = () => {
        setNaverRouteFrom({lat:contextMenuState.lat, lon:contextMenuState.lon, address: null});
    };

    const setNaverTrackEnd = () => {
        setNaverRouteTo({lat:contextMenuState.lat, lon:contextMenuState.lon, address: null});
    };

    const onClickContextMenu = (menuKey) => {
        setContextMenuState({open:false});

        if(menuKey===MapContextMenu.AddPoi.key) {
            onClickSetMenu(MapMode.POI);


            mapShell.queryNaverReverseGeocode(contextMenuState.lat, contextMenuState.lon, (status, response)=>{
                if(mapShell.isNaverResponseOk(status)) {
                    const addr = ValueUtil.getNaverV2AddressString(response);
                    poiEditCommandRepo({open:true, lat: contextMenuState.lat, lon: contextMenuState.lon, addr: addr});
                }
                else {
                    poiEditCommandRepo({open:true, lat: contextMenuState.lat, lon: contextMenuState.lon, addr: '주소검색 오류'});
                }
            });
        }
        else if(menuKey===MapContextMenu.SetStartPos.key) setNaverTrackStart();
        else if(menuKey===MapContextMenu.SetEndPos.key) setNaverTrackEnd();
        else if(menuKey===MapContextMenu.DrawCircle.key) {
            const msd = {...mapShapeData};
            msd.open=true;
            msd.lat = contextMenuState.lat;
            msd.lon = contextMenuState.lon;
            setMapShapeData(msd);
        }
    };

    const onCloseMapShapeInput = (data) => {
        if(data) {
            const msd = {...mapShapeData};
            msd.open=false;
            msd.data = data;
            msd.shapeOnMap = true;
            setMapShapeData(msd);
            mapShell.drawShape(msd);
            Util.bubbleSnack("도형을 클릭하거나 새 도형을 그리면 사라집니다.");
        }
        else {
            const msd = {...mapShapeData};
            msd.open=false;
            setMapShapeData(msd);
        }
    };

    /**
     * naver route list: clicked on a row to show it on the map.
     * @param {NaverRoute} naverRoute 
     */
    const onClickNaverRouteRow = (naverRoute) => {
        setNaverRouteFrom(naverRoute.from);
        setNaverRouteTo(naverRoute.to);
        setNaverRouteResult(naverRoute);
    };

    /**
     * naver route list: clicked on a row to remove it.
     * @param {NaverRoute} naverRoute 
     */
    const onRequestRemoveRoute = (naverRoute) => {
        const routes = naverRoutesInRepo.filter((route)=>route.routeId!=naverRoute.routeId);
        naverRouteRepo(routes);
    }

    const onClickOpenPoiBatchInput = () => {
        setOpenMapPoiBatchInput(true);
    };

    // ############################################################
    const renderDoubleTriangleButtons = () => {
        let pointsA, pointsB; // = isOpen ? "0,20 8,16 8,24" : "0,20 8,16 8,24";
        if(openDataBox) {
            // show closing button
            pointsA = "0,20 8,16 8,24";
            pointsB = "0,40 8,36 8,44";
        }
        else {
            pointsA = "0,16 8,20 0,24";
            pointsB = "0,36 8,40 0,44";
        }

        return(
            <svg width="8" height="60">
                <polygon points={pointsA}
                    style={TriangleStyle}
                />
                <polygon points={pointsB}
                    style={TriangleStyle}
                />
          &nbsp;
        </svg>
        );
    };
    //<TheHeader>&nbsp; {/* To take space for TopControlBox */} </TheHeader>

    const renderContextMenuLine = (menu) => {
        return (
            <ContextMenuLine
                key={menu.key}
                onClick={(e)=>onClickContextMenu(menu.key)}
            >
                {menu.label}
            </ContextMenuLine>
        );
    };

    const renderContextMenu = () => {
        if(contextMenuState.open)
            return(
                <ContextMenuBox
                    style={{top: contextMenuState.top, left: contextMenuState.left}}
                >
                    {
                        contextMenuState.menus.map((menu)=>renderContextMenuLine(menu))
                    }
                    <ContextMenuLine
                        onClick={(e)=>{setContextMenuState({open:false})}}
                    >닫기</ContextMenuLine>
                </ContextMenuBox>
            );
        else return null;
    };

    const renderOverlapInfoBox = () => {
        if(overlapInfo) {
            //if(overlapInfo.y > 400) {}
            let height = overlapInfo.records.length * OverlapRecordHeight + AnyMenuBoxPadding * 2;
            if(height>400) height=400;
            const leftLimit = (openDataBox ? leftWidth : 0) + OverlapBoxWidth + 30;
            const top = overlapInfo.y > 400 ? overlapInfo.y - height + 10 : overlapInfo.y - 20;
            const left = overlapInfo.x > leftLimit ? overlapInfo.x - OverlapBoxWidth + 30 : overlapInfo.x - 40;
            return (
                <OverlapInfoBox style={{top:top, left: left, height: height+4}}
                    onMouseLeave={onMouseOutOverlapBox}
                >
                    {
                        overlapInfo.records.map((userData)=> {
                            if(userData.type===MapValue.MarkerType.POI) {
                                return (
                                    <OverlapRecord key={'poi-'+userData.data.poiId}
                                        onClick={()=>setPopPoi({open:true, data: userData.data})}
                                        onMouseEnter={()=>setTopInfoState({open:true, data:{markerData:userData.data, type:userData.type}})}
                                        onMouseLeave={()=>setTopInfoState({open:false})}
                                    >
                                        <PlaceIcon fontSize={AppWord.SMALL} color={AppPalette.SuccessColor}/>
                                        {userData.data.poiName}
                                    </OverlapRecord>
                                );
                            }
                            else if(userData.type===MapValue.MarkerType.VEH_POS) {
                                return (
                                    <OverlapRecord key={'veh-'+userData.data.vehId}
                                    onClick={()=>setPopVehPos({open:true, data: userData.data})}
                                    onMouseEnter={()=>mapShell.handleMouseOverVehPos(userData)}
                                    onMouseLeave={(e)=>mapShell.onMouseLeaveVehPos(e)}
                                >
                                        <NearMeIcon fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor} />
                                        {userData.data.vehAlias}
                                    </OverlapRecord>
                                );
                            }
                            else if(userData.type===MapValue.MarkerType.CLUSTER) {
                                return (
                                    <OverlapRecord key={'c-'+userData.data.clusterId}
                                    onClick={(e)=>{mapShell.handleClickCluster(userData.data); onMouseOutOverlapBox(e)}}
                                    >
                                        <HubIcon fontSize={AppWord.SMALL} color={AppPalette.DisabledColor} />
                                        {userData.data.size()}개 지점
                                    </OverlapRecord>
                                );
                            }
                            else return null;
                        })
                    }
                </OverlapInfoBox>
            );
        }
        else return null;
    };

    const renderInfoUnit = (label, value) => {
        return (
            <InfoUnitRow>
                <InfoTitleBox>{label}</InfoTitleBox>
                <InfoValueBox>{value}</InfoValueBox>
            </InfoUnitRow>
        );
    };

    const renderGpsTimeAge = (pos) => {
        let txt;
        if(pos.gpsTime==='2000-01-01 00:00:00') txt = '기록없음';
        else {
            const gpsTimeSec = pos.gpsTimeAsSec;
            if(!gpsTimeSec) return null;
    
            const sec = (new Date().getTime()/1000 - gpsTimeSec);
            if(sec < 180) txt = '최신보고';
            else if(sec > 86400) {
                const days = Math.floor(sec/86400);
                txt = `${days}일`;
                if(days<3) {
                    const hours = Math.floor((sec % 86400)/3600);
                    txt += ` ${hours}시간`;
                }
                txt += '전';
            }
            else {
                const hours = Math.floor(sec / 3600);
                const minutes = Math.floor((sec % 3600) / 60);
                const minTxt = `${minutes}분`;
                if(hours>0) txt = `${hours}시간 ` + minTxt;
                else txt = minTxt;
                txt += '전';
            }
        }
        return renderInfoUnit('보고경과', txt);
    };

    const renderTopVehPosBox = () => {
        const data = topInfoState.data || {};
        const pos = data.markerData || {};
        const old = data.oldest || {};

        return(
            <TopInfoBox onMouseEnter={()=>setTopInfoState({open:false})}>
                <TopInfoColumn>
                    {renderInfoUnit('차량번호',pos.vehPlates)}
                    {renderInfoUnit('차종,톤수',pos.vehTypeTon)}
                </TopInfoColumn>
                <TopInfoColumn>
                    {renderInfoUnit('최종보고',pos.gpsTime)}
                    {renderGpsTimeAge(pos)}
                </TopInfoColumn>
                <TopInfoColumn>
                    {
                        old.gpsTime ?
                        renderInfoUnit('경로꼬리',`${old.gpsTime.substr(5).replace('-','/')} 부터`)
                        : null
                    }
                    {renderInfoUnit('누적거리',`${ValueUtil.int2str(Math.round(pos.distKm))} Km`)}
                </TopInfoColumn>
            </TopInfoBox>
        );
    };

    const renderTopPoiBox = () => {
        const data = topInfoState.data || {};
        const poi = data.markerData || {};
        return(
            <TopInfoBox onMouseEnter={()=>setTopInfoState({open:false})}>
                <TopInfoColumn>
                    {renderInfoUnit('지점',poi.poiName)}
                    {renderInfoUnit('지점타입',poi.typeName)}
                </TopInfoColumn>
                <TopInfoColumn>
                    {renderInfoUnit('연락처',poi.tel || '없음')}
                    {renderInfoUnit('주소',poi.addr || '없음')}
                </TopInfoColumn>
                {
                    poi.custPoiCode ?
                    <TopInfoColumn>
                    {renderInfoUnit('코드',poi.custPoiCode)}
                    </TopInfoColumn>
                    : null
                }
            </TopInfoBox>
        );
    };

    const renderTopInfoBox = () => {
        const data = topInfoState.data || {};
        if(data.type===MapValue.MarkerType.VEH_POS) return renderTopVehPosBox();
        else return renderTopPoiBox();
    };

    return (
        <TheContainer height={clientSize.height} onMouseLeave={onMouseOutOverlapBox}>
            {
                openDataBox
                ?
                <DataBox width={leftWidth} height={clientSize.height - 2}
                    onMouseEnter={onMouseOutOverlapBox}
                >
                    <DataInnerWrapper>
                        <MapDataContainer
                            mapShell={mapShell}
                            headerHeight={HeaderHeight}
                            mapMenu={mapMenu}
                            onChangeMapMenu={onClickSetMenu}
                        />
                    </DataInnerWrapper>
                </DataBox>
                :
                null
            }
            <HeaderAndMap width={MapBoxWidth}>
                <MapBox id={MapBoxId} height={clientSize.height - HeaderHeight - 8} style={{marginTop:HeaderHeight}}
                >
                    Wait...
                    {/* 왜 -8을 해야하는지 당위성은 알지 못함. 하지만 현상은 패딩이 있는 것처럼 나타나기 때문에 어쩔 수 없이 적용함. */}
                </MapBox>
            </HeaderAndMap>
            <DeviderBox onMouseDown={onMouseDown}
                onMouseEnter={onMouseOutOverlapBox}
                style={{left:openDataBox ? (leftWidth + DeviderMargin) : 0, top:0}}
            >
                <DividerToggleBox
                    onClick={toggleDataBox}
                    style={{cursor:openDataBox ? "w-resize" : "e-resize"}}
                >
                    {renderDoubleTriangleButtons()}
                </DividerToggleBox>
            </DeviderBox>
            <TopControlBox
                width={openDataBox ? clientSize.width-leftWidth : clientSize.width}
                onMouseEnter={onMouseOutOverlapBox}
            >
                {
                    naverRouteFrom || naverRouteTo
                    ?
                    <NaverRouteSearch
                        mapShell={mapShell}
                        posFrom={naverRouteFrom}
                        posTo={naverRouteTo}
                        resultNaverRoute={naverRouteResult}
                        onCancel={()=>{setNaverRouteFrom(null); setNaverRouteTo(null); setNaverRouteResult(null);}}
                    />
                    :
                    null
                }
                {
                    naverRoutesInRepo.length > 0
                    ? <NaverRouteResults
                        results={naverRoutesInRepo}
                        onRequestShowNaverRoute={onClickNaverRouteRow}
                        onRequestRemoveRoute={onRequestRemoveRoute}
                    />
                    : null
                }
                <MapMenuAndWatch
                    mapShell={mapShell} onAlert={setResponseAlert} disabled={naverRouteFrom || naverRouteTo}
                    onClickOpenPoiBatchInput={onClickOpenPoiBatchInput}
                />
                
            </TopControlBox>
            <Grow in={topInfoState.open}>
                {renderTopInfoBox()}
            </Grow>
            {renderOverlapInfoBox()}
            {renderContextMenu()}
            {
                appAlerts.length > 0
                ?
                <AlertDialog open={appAlerts.length > 0}
                    data={appAlerts[0]}
                    onClose={()=>{
                        appAlertRepo(appAlerts.slice(1));
                    }}/>
                :
                null
            }
            <PopVehPosInfo
                open={popVehPos.open}
                vehPosData={popVehPos.data}
                onClickClose={()=>setPopVehPos({open:false})}
            />
            <PopPoiInfo
                open={popPoi.open}
                poiData={popPoi.data}
                onClickClose={()=>setPopPoi({open:false})}
            />
            <PopRoutePointInfo
                open={popRoutePoint.open}
                vehPosData={popRoutePoint.data}
                onClickClose={()=>setPopRoutePoint({open:false})}
            />
            <MapShapeInput
                open={mapShapeData.open}
                dataDefault={mapShapeData.data}
                onClose={onCloseMapShapeInput}
            />
            {
                isAdmin && openMapPoiBatchInput
                ?
                <MapPoiInfoBatch
                    mapShell={mapShell}
                    onCloseView={()=>setOpenMapPoiBatchInput(false)}
                    onAlert={setResponseAlert}
                />
                :
                null
            }
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </TheContainer>
    );
}

/*



angular js / app_pos.js
=======================
차량위치목록:
    - 주기적 조회.
    - 필터링.
    - 목록에 마우스 올리면 팝오버로 전체 내용 보여주기. FlexyTable로 하면 됨.
    - 목록에서 차량선택. 경로리스트 비워주고, 차량의 위치로 지도 이동.
    - 차량 마커들 출력 - 북쪽부터. 목록의 필터 적용. 기존 마커 지운후 새로 그림. 각 마커는 클릭, mouseover 이벤트 리스터 설정.
    - 위치 마커들과 경로 마커+폴리라인은 배타적으로 출력됨.
    - 지도에서 마커 겹치면 차량, 지점 등의 목록을 Popup list로 보여줌. 아이콘 다르게 해서... (onMouseOverVehPosMarker)
    - 마커 클릭 : 차량정보 보여주기 (onClickVehPosMarker) Dialog.
    - 새로운 아이디어: 마우스 올렸을 때 (겹침목록출력 처리가 없다면) 이전 위치(들)을 희미하게 보여주는? 내리면 숨기고.
    - 차량 1개 추억. (하나만 보여주고 계속 새로운 위치를 중심에 둔다.)
    - 위치 수신 후 이벤트 알림. if(rec.man_report_yn=='Y' && rec.ir_action != 0 && rec.ir_action != 255) IN onGetListVehPos()
    - 강제로 reload. reload 직후이거나 직전이면 하지 않음

경로:
    - 작은 아이콘과 빨간 경로
    - 목록에 마우스 올리면 팝오버로 전체 내용 보여주기. FlexyTable로 하면 됨.
    - 위치마커들과 배타적 출력.
    - 마커 클릭 : 해당 지점에서의 정보 보여주기 (showRoutePointInfoPopup) Dialog.

청소차량:
    - 청소차량 운행상태 (getSweeperStatListAndRoute. 목록. 위치목록 대신.)
    - 처음 로그인하면 오늘 데이터를 먼저 가져온다.
    - 청소차량 경로는 다르게 그림:
==

위치마커 그룹과 경로+폴리라인은 하나의 객체를 경쟁적으로 차지하도록 설계.
    - 목록에서 라인 선택. 해당 위치로 지도 이동.
    - 목록에 마우스 올리면 팝오버로 전체 내용 보여주기. FlexyTable로 하면 됨.

*/
