// TermMngLogInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette, CvoCodes, AppWord } from '../../model/AppConst';
import { TermMngLogDictionary } from './TermMngLogGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import RadioSelector from '../common/RadioSelector';
import ValueUtil from '../../model/ValueUtil';
import { codeTreeRepo } from '../../model/CvoModel';
import { useReactiveVar } from '@apollo/client';
import CodeSelector from '../common/CodeSelector';
import TextCombo from '../common/TextCombo';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    padding: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
});
const InputMap = TermMngLogDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.

// Only Edit
export default function TermMngLogInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    customerList, // If ...
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [NetworkList, setNetworkList] = useState([]);
    //const [TermStateList, setTermStateList] = useState([]);
    //const [OpenForList, setOpenForList] = useState([]);
    //const [FirmTypeList, setFirmTypeList] = useState([]);
    const [ModelList, setModelList] = useState([]);
    const [BillTypeList, setBillTypeList] = useState([]);
    //const [ContrMonthList, setContrMonthList] = useState([]);
    //const [ContrAmountList, setContrAmountList] = useState([]);
    const [TermChangeList, setTermChangeList] = useState([]);
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        if(CodeTree) {
            setNetworkList(ValueUtil.codeToSelectList(CvoCodes.AC_NETWORK_CD, CodeTree)); // 통신사
            //setTermStateList(ValueUtil.codeToSelectList(CvoCodes.AC_TERM_STATE_CD, CodeTree));
            //setOpenForList(ValueUtil.codeToSelectList(CvoCodes.AC_OPEN_FOR_CD, CodeTree));
            //setFirmTypeList(ValueUtil.codeToSelectList(CvoCodes.AC_FIRM_TYPE_CD, CodeTree));
            setModelList(ValueUtil.codeToSelectList(CvoCodes.AC_MODEL_CD, CodeTree)); // 모델.
            setBillTypeList(ValueUtil.codeToSelectList(CvoCodes.AC_BILL_TYPE_CD, CodeTree)); // 요금제
            //setContrMonthList(ValueUtil.codeToSelectList(CvoCodes.AC_CONTR_MONTH_CD, CodeTree));
            //setContrAmountList(ValueUtil.codeToSelectList(CvoCodes.AC_CONTR_AMOUNT_CD, CodeTree));
            setTermChangeList(ValueUtil.codeToSelectList(CvoCodes.AC_TERM_CHANGE_CD, CodeTree)); // 변경사유.
        }
    }, [CodeTree]);

    useEffect(()=>{
        if(item) {
			const d = {...item};
			// d.tel = ValueUtil.reformWith(item.tel, AppWord.NUM_N_HYPN);
            setInputData(d);
        }
		else setInputData(defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
    }, [item]);

    const changeInputData = (data) => {
		setInputData(data ? data : defaultInputData);
        setChanged(true);
    };

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput2(event, InputMap, inputData, inputError, true);

        changeInputData(newData);
        setInputError(newError);
        setHasError(hasError);
    };

    const onChangeNumeric = (event) => {
        if(event.target.value) {
            if(/[^0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

    const onChangeCode = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        changeInputData(data);
    };

    const renderSelectorField = (dict, value, selectFrom) => {
        return(
            <FieldBox>
                <CodeSelector
                    id={dict.id}
                    value={value || dict.default || ""}
                    label={dict.label}
                    codes={selectFrom}
                    onChange={onChangeCode}
                />
            </FieldBox>
        );
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size={AppWord.SMALL}
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    return (
        <Dialog open={open} maxWidth="xl">
            <EditorTitle>
                변경정보 수정
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                        {renderTextFieldBox(InputMap.termId, inputData.termId,
                            inputError.termId, onChangeNumeric) /* 단말기ID */}
                        {renderSelectorField(InputMap.acNetworkCd, inputData.acNetworkCd, NetworkList) /* 통신사 */}
                        <FieldBox>
                            <TextCombo
                                id={InputMap.custName.id}
                                label={InputMap.custName.label}
                                selectFrom={customerList}
                                uniqueKey={InputMap.custName.id}
                                text={inputData.custName || ''}
                                error={inputError.custName}
                                onChange={(id,value)=>{onChangeTextData({target:{id:id, value:value}})}}
                            />{ /* 업체명 - combo */}
                        </FieldBox>
                        {renderSelectorField(InputMap.acTermChangeCd, inputData.acTermChangeCd, TermChangeList) /* 변경사유 */}
                    </InputColumn>
                    <InputColumn>
                            {renderTextFieldBox(InputMap.openerOld, inputData.openerOld,
								inputError.openerOld, onChangeTextData) /* 양도인 */}
                            {renderSelectorField(InputMap.acModelCdOld, inputData.acModelCdOld, ModelList) /* 변경전 모델 */}
                            {renderTextFieldBox(InputMap.serialNoOld, inputData.serialNoOld,
								inputError.serialNoOld, onChangeTextData) /* 변경전 S/N */}
                            {renderTextFieldBox(InputMap.usimNumOld, inputData.usimNumOld,
								inputError.usimNumOld, onChangeTextData) /* 변경전 USIM */}
                            {renderSelectorField(InputMap.acBillTypeCdOld, inputData.acBillTypeCdOld, BillTypeList) /* 변경전 요금제 */}

                    </InputColumn>
                    <InputColumn>
                            {renderTextFieldBox(InputMap.openerName, inputData.openerName,
								inputError.openerName, onChangeTextData) /* 양수인 */}
                            {renderSelectorField(InputMap.acModelCd, inputData.acModelCd, ModelList) /* 변경후 모델 */}
                            {renderTextFieldBox(InputMap.serialNo, inputData.serialNo,
								inputError.serialNo, onChangeTextData) /* 변경후 S/N */}
                            {renderTextFieldBox(InputMap.usimNum, inputData.usimNum,
								inputError.usimNum, onChangeTextData) /* 변경후 USIM */}
                            {renderSelectorField(InputMap.acBillTypeCd, inputData.acBillTypeCd, BillTypeList) /* 변경후 요금제 */}
                            
                    </InputColumn>
                    <InputColumn>
                        <FieldBox>
                            <TextField id={InputMap.changeMemo.id}
                                multiline
                                rows={4}
                                type={InputMap.changeMemo.type}
                                label={InputMap.changeMemo.label}
                                required={InputMap.changeMemo.required}
                                value={inputData.changeMemo}
                                error={inputError.changeMemo}
                                helperText={InputMap.changeMemo.help}
                                onChange={onChangeTextData} />{/* 변경메모 */}
                        </FieldBox>
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}