// DownloadInfoInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, IconButton, Popover } from '@mui/material';
import { AppPalette, AppWord, AppObject } from '../../model/AppConst';
import useClientSize from '../hook/useClientSize';
import DownloadFileInput from './DownloadFileInput';
import { DownloadInfoDictionary } from './DownloadInfoGql';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import IsoIcon from '@mui/icons-material/Iso';
import DownloadIcon from '@mui/icons-material/Download';
import ConfirmDialog from '../message/ConfirmDialog';
import RadioSelector from '../common/RadioSelector';
import ValueUtil from '../../model/ValueUtil';

const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({ padding: 0, display:'flex' });

const InputColumn = styled(Box)({
    flexGrow: 1, paddingTop: 10, margin: ColumnMargin, verticalAlign: 'top', display:'flex', flexDirection:'column'
});

const FieldBox = styled(Box)({ display: 'block', paddingTop: 10, });
const InputMap = DownloadInfoDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.

const DataTypeItemBox = styled(Box)({cursor:'pointer', minWidth:100, padding:10, borderBottom: AppPalette.BorderCCC});

const PreviewBox = styled(Box)({flexGrow:1, overflow:'auto', borderRadius:5, border: AppPalette.BorderCCC});

const FileItemBox = styled(Box)({padding:10, borderBottom: AppPalette.BorderCCC, fontSize:'0.85em'});

const PairBox = styled(Box)({ height:240, display:'flex', width:'100%', });

export default function DownloadInfoInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    dataTypes, // 다운로드 분류는 목록에서 추출한 것이 들어온다.
    item,
    cvoFiles, // 첨부할 파일들. 필터링해야 함. 편집할 때만 가능하다.
    isEdit, // == Boolean(item). 편집일 때에는 파일을 추가 첨부할 수 있다.
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [downloadFiles, setDownloadFiles] = useState([]);
    const [typesPopAnchor, setTypesPopAnchor] = useState(null);
    const [adminMode, setAdminMode] = useState(false);
    const {loading:loadingSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.
    const clientSize = useClientSize();

    useEffect(()=>{
        if(open) {
            if(item && isEdit) {
                const d = {...item};
                if(item.files) setDownloadFiles(item.files);
                setInputData(d);
            }
            else setInputData(defaultInputData);
            setInputError({});
            setChanged(false);
            setHasError(false);
        }
    }, [item, open, isEdit]);

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // add extra data if necessary.
        if(isEdit) param.msgSeq = item.msgSeq;

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '다운로드 정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput2(event, InputMap, inputData, inputError, isEdit);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

	// 본문 형식
    const onChangeRadioSelector = (event) => {
        const data = {...inputData};
        data[event.target.id] = event.target.value;
        setInputData(data);
    };

    const onClickTypeItem = (type) => {
        const data = {...inputData};
        data.dataType = type;
        setTypesPopAnchor(null);
        setInputData(data);
        setChanged(true);
    };

    const onCloseFileInputBox = () => {
        setAdminMode(false);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderFileList = () => {
        if(isEdit) {
            if(adminMode) {
                return(
                    <PairBox>
                        <DownloadFileInput
                            downloadInfo={item}
                            cvoFiles={cvoFiles}
                            downloadFiles={downloadFiles}
                            setDownloadFiles={(files)=>{setDownloadFiles(files)}}
                            onClose={onCloseFileInputBox}
                        />
                    </PairBox>
                );
            }
            else if(downloadFiles.length > 0) {
                return(
                    <Box>
                        <Box><Button onClick={()=>{setAdminMode(true)}}><IsoIcon fontSize={AppWord.SMALL} /> 첨부파일 관리</Button></Box>
                        {
                            downloadFiles.map((file) => {
                                return(
                                    <FileItemBox key={file.fileNid}>
                                        첨부 : &nbsp;
                                        <a href={file.driveLink} target="_blank" rel="noopener noreferrer">
                                            <DownloadIcon fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor}
                                                style={{verticalAlign:'bottom'}}
                                            />
                                            {file.title}
                                        </a>
                                    </FileItemBox>
                                );
                            })
                        }
                    </Box>
                );
            }
            else {
                return (
                    <Box>첨부파일이 없습니다. &nbsp; &nbsp; &nbsp;
                        <Button onClick={()=>{setAdminMode(true)}}>
                            <IsoIcon fontSize={AppWord.SMALL} /> 첨부파일 관리
                        </Button>
                    </Box>
                );
            }
        }
        else {
            return(
                <EditorContentRow>
                    <Box style={{padding:10}}>정보 추가 시에는 저장 완료한 후 파일을 첨부할 수 있습니다.</Box>
                </EditorContentRow>
            );
        }
    };


    const renderIconAndPopover = () => {
        const OpenTypePopover = Boolean(typesPopAnchor);
        const IdDataTypePopover = OpenTypePopover ? "id_data_types_popover" : null;

        if(dataTypes.length > 0) {
            return (
                <>
                <IconButton size={AppWord.SMALL} color={AppPalette.PrimaryColor}
                    aria-describedby={IdDataTypePopover}
                    onClick={(event)=>{setTypesPopAnchor(event.currentTarget)}}
                >
                    <PlaylistAddCheckIcon fontSize={AppWord.SMALL}/>
                </IconButton>
                <Popover
                    id={IdDataTypePopover}
                    open={OpenTypePopover}
                    anchorEl={typesPopAnchor}
                    onClose={()=>{setTypesPopAnchor(null)}}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Box style={{padding:5}}>
                        {
                            dataTypes.map((type)=> {
                                return(
                                    <DataTypeItemBox key={type} onClick={()=>onClickTypeItem(type)}>
                                        {type}
                                    </DataTypeItemBox>
                                );
                            })
                        }
                    </Box>
                </Popover>
                </>
            );
        }
        return null;

    };

    const BoxWidth = clientSize.width >= 1000 ? clientSize.width * 0.84 : clientSize.width * 0.94;

    return (
        <Dialog open={open} maxWidth="xl">
            <EditorTitle>
                {isEdit ? '다운로드정보 수정' : '다운로드정보 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                        <FieldBox style={{minWidth:BoxWidth}}>
                            <TextField id={InputMap.title.id} size={AppWord.SMALL}
                                type={InputMap.title.type}
                                label={InputMap.title.label}
                                required={InputMap.title.required}
                                value={inputData.title || ''}
                                error={inputError.title}
                                helperText={InputMap.title.help}
                                onChange={onChangeTextData}
                                style={{width: 600}}
                            />
                        </FieldBox>
                    </InputColumn>
                </EditorContentRow>
                <EditorContentRow>
                    <InputColumn>
                        <div>
                            <TextField id={InputMap.dataType.id} size={AppWord.SMALL}
                                type={InputMap.dataType.type}
                                label={InputMap.dataType.label}
                                required={InputMap.dataType.required}
                                value={inputData.dataType || ''}
                                error={inputError.dataType}
                                helperText={InputMap.dataType.help}
                                onChange={onChangeTextData}
                                style={{width: 140}}
                            />
                            {renderIconAndPopover()}
                        </div>
                    </InputColumn>
                    <InputColumn>
                            
                            <RadioSelector
                                label={InputMap.textFormat.label}
                                id={InputMap.textFormat.id}
                                selectFrom={AppObject.TEXT_FORMATS}
                                initialValue={inputData.textFormat}
                                onChange={onChangeRadioSelector}
                                horizontal={true}
                            />
                    </InputColumn>
                </EditorContentRow>
                <EditorContentRow>
                    <InputColumn>
                            <Box style={{fontWeight:'bold'}}>본문내용</Box>
                            <TextField id={InputMap.contents.id}
                                multiline rows={isEdit ? 7 : 9}
                                type={InputMap.contents.type}
                                placeholder='다운로드 파일들에 대한 간단한 설명을 입력하세요'
                                required={InputMap.contents.required}
                                value={inputData.contents || ''}
                                error={inputError.contents}
                                onChange={onChangeTextData}
                                fullWidth />
                    </InputColumn>
                    {
                        inputData.textFormat==="markdown"
                        ?
                        <InputColumn style={{maxHeight:250}}>
                            <Box style={{fontWeight:'bold'}}>미리보기</Box>
                            <PreviewBox>
                                <ReactMarkdown>{inputData.contents}</ReactMarkdown>
                            </PreviewBox>
                        </InputColumn>
                        :
                        null
                    }
                </EditorContentRow>
                {renderFileList()}
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant={AppPalette.VariantContained} color={AppPalette.PrimaryColor}>
                            저장 {isEdit ? null : " 후 파일선택"}
                        </Button>
                        <Button onClick={onClickCancel} color={AppPalette.WarnColor}
                            variant={AppPalette.VariantContained}>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}
