// MenuGql.js
import { gql } from "@apollo/client";

export const MENU_TREE = gql`
query menuToUserTree {
    menuToUserTree {
        kindId
        kindName
        kindOrder
        kindDesc
        menus {
            menuId
            menuName
            userId
            myName
            bookMark
            menuOrder
            regTime
        }
    }
}
`;

export const MENU_TREE_FOR_USER_SELECTION = gql`
query menuToUserTreeForSelection($userId:String!) {
  menuToUserTreeForSelection(userId:$userId) {
    kindId
    kindName
    menus {
      menuId
      menuName
      menuOrder
      userId
    }
  }
}
`;


export const MAP_MENU_TO_USER_RET_TREE = gql`
mutation MapMenuToUserReturnTree($menuMapInfo: InputMenuToUserMap!) {
    menuToUserMap(menuMapInfo: $menuMapInfo) {
        ok
        message
        list {
            kindId
            kindName
            menus {
                menuId
                menuName
                menuOrder
                userId
            }
        }
    }
}
`;

export const REMOVE_MENU_TO_USER_RET_TREE = gql`
mutation RemoveMenuToUserReturnTree($menuMapInfo: InputMenuToUserRemove!) {
    menuToUserRemove(menuMapInfo: $menuMapInfo) {
        ok
        message
        list {
            kindId
            kindName
            menus {
                menuId
                menuName
                menuOrder
                userId
            }
        }
    }
}
`;


/*
menuToUserMap(menuMapInfo: InputMenuToUserMap!): responseWithMenuTreeForSelection
menuToUserRemove(menuMapInfo: InputMenuToUserRemove!): responseWithMenuTreeForSelection

*/