// UserPoiRelation.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, IconButton } from '@mui/material';
import TossTable from '../common/TossTable';
import ResponseAlert from '../message/ResponseAlert';
import ClearIcon from '@mui/icons-material/Clear';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_USER_POI_RELATION_POI_LIST_FOR_USER, ADD_USER_POI_RELATION_RET_POIS, REMOVE_USER_POI_RELATION_RET_POIS,
	UserPoiRelationDictionary
} from './UserPoiRelationGql';
import { AppObject, AppWord, AppPalette } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const UserPoiRelationContainer = styled(Box)({
	display:'flex', flexDirection:'column', height:'100%'
});

const ShortInfoBox = styled(Box)({padding:5, color:'#555', fontSize:'0.9rem'});
const PairBox = styled(Box)({ // has 2 SetBox
    display:'flex', flexGrow:1, // backgroundColor:'yellow'
});

const SetBox =  styled(Box)({ // has SetTitleBox, SearchBox and TableBox
    flexGrow:1,
    display:'flex',
    flexDirection: 'column',
    marginLeft:3,
    marginRight:3,
    width:'50%'
});
const SetTitleBox = styled(Box)({fontWeight:'bold', padding:10});
const SearchBox = styled(Box)({padding:5});

const TableBox = styled(Box)({
    flexGrow:1,
    border:AppPalette.BorderCCC,
    overflow:'auto',
    borderRadius:5,
    position:'relative'
});

const AllocTableColumns = [
    {id:'poiName', minWidth:80, label:'지점'},
    {id:'typeName', minWidth:80, label:'지점타입'}
];

//const EditFields = ValueUtil.getFieldsToSubmit(UserPoiRelationDictionary.inputMap, true);

export default function UserPoiRelation({
    userPoiRel,
    userInfo
}) {
    const [poiListIn, setPoiListIn] = useState([]);
    const [poiListOut, setPoiListOut] = useState([]);
    const [searchIn, setSearchIn] = useState('');
    const [searchOut, setSearchOut] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);
    // const responseList = useQuery(GET_USER_POI_RELATION_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});

    const ErrorTitle = userPoiRel === AppWord.USER_POI_VIEW_REL
        ? UserPoiRelationDictionary.errorTitleRelation
        : UserPoiRelationDictionary.errorTitleMap;
    // ##### Call GraphQL to get List #####
    const [getUserPoiRelationList, responseList] = useLazyQuery(LIST_USER_POI_RELATION_POI_LIST_FOR_USER, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
	});
    
    // ##### GraphQL Mutation.
    const [addUserPoiRelation, responseAdd] = useMutation( ADD_USER_POI_RELATION_RET_POIS, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [removeUserPoiRelation, responseRemove] = useMutation( REMOVE_USER_POI_RELATION_RET_POIS, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getUserPoiRelationList({variables:{userId: userInfo.userId, userPoiRel: userPoiRel}});
    }, [userInfo, userPoiRel, getUserPoiRelationList]);


    // >>>>>>>>> callbacks <<<<<<<<<<<<<

    const devidePoiList = (list) => {
        const poisIn = [];
        const poisOut = [];
        for(const g of list) {
            if(g.userId === userInfo.userId) poisIn.push(g);
            else poisOut.push(g);
        }
        setPoiListIn(poisIn);
        setPoiListOut(poisOut);
    };
    
    const onCompleteGetList = (data, clientOption) => {
        if(data.userPoiRelationPoiListForUser) {
            devidePoiList(data.userPoiRelationPoiListForUser);
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.userPoiRelationAddAndReturnPoiList.ok) devidePoiList(data.userPoiRelationAddAndReturnPoiList.list);
        else setResponseAlert({open:true, resultData: data.userPoiRelationAddAndReturnPoiList, title: ErrorTitle.Add});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.userPoiRelationRemoveAndReturnPoiList.ok) devidePoiList(data.userPoiRelationRemoveAndReturnPoiList.list);
        else setResponseAlert({open:true, resultData: data.userPoiRelationRemoveAndReturnPoiList, title: ErrorTitle.Remove});
    };

    // +++++++ UI callbacks ++++++++

    const onClickRemove = (item) => {
        const param = {variables:{userPoiRelation:{userPoiRel: userPoiRel, poiId: item.poiId, userId: userInfo.userId}}};
        removeUserPoiRelation(param);
    };

    // Handler - Submit for mutation fired by UserPoiRelationInput component.
    const onClickAdd = (item) => {
        const param = {variables:{userPoiRelation:{userPoiRel: userPoiRel, poiId: item.poiId, userId: userInfo.userId}}};
        addUserPoiRelation(param);
    };

    if(ValueUtil.hasAnyAuthError(
        responseList, responseAdd, responseRemove
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------

    return (
        <UserPoiRelationContainer>
            <ShortInfoBox>
                {
                    userPoiRel===AppWord.USER_POI_OPER_AUTH
                    ?
                    "지점과 사용자의 관계를 맺어 둠으로써 임의 차량이 해당 지점에 배차될 때 한시적으로 차량관제를 부여하게 합니다. 상시 관제권한은 관제권한설정를 사용합니다."
                    :
                    "사용자가 지도를 사용할 때 나타날 지점들을 설정합니다. 너무 많은 지점이 있을 때 시용할 수 있습니다. 설정이 하나도 없으면 모든 지점이 지도에 표시됩니다."
                }
            </ShortInfoBox>
            <PairBox>
                <SetBox>
                    <SetTitleBox>
                        설정된 지점
                    </SetTitleBox>
                    <SearchBox>
                        <TextField size="small" label="검색"
                            value={searchIn}
                            onChange={(e) => setSearchIn(e.target.value)}
                        />
                        {
                            searchIn ?
                            <IconButton size="small"
                                onClick={()=>{setSearchIn('')}}
                            >
                                <ClearIcon fontSize='small'/>
                            </IconButton> : null
                        }
                    </SearchBox>
                    <TableBox>
                        <TossTable
                            columns={AllocTableColumns}
                            records={poiListIn}
                            search={searchIn}
                            onClickSend={onClickRemove}
                            isLeft={true}
                        />
                    </TableBox>
                </SetBox>
                <SetBox>
                    <SetTitleBox>설정가능한 자점</SetTitleBox>
                    <SearchBox>
                        <TextField size="small" label="검색"
                            value={searchOut}
                            onChange={(e) => setSearchOut(e.target.value)}
                        />
                        {
                            searchOut ?
                            <IconButton size="small"
                                onClick={()=>{setSearchOut('')}}
                            >
                                <ClearIcon fontSize='small'/>
                            </IconButton> : null
                        }
                    </SearchBox>
                    <TableBox>
                        <TossTable
                            columns={AllocTableColumns}
                            records={poiListOut}
                            search={searchOut}
                            onClickSend={onClickAdd}
                        />
                    </TableBox>
                </SetBox>
            </PairBox>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </UserPoiRelationContainer>
    )
}
