// DownloadInfoList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button, IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo} from '../../model/CvoModel';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PageviewIcon from '@mui/icons-material/Pageview';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { DownloadInfoDictionary } from './DownloadInfoGql';

const DownloadInfoListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
	display:'flex', alignItems:'center', height:AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const HeaderControl = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});

const ButtonBox = styled(Box)({'&>*': {marginLeft:10}});

const Dict = DownloadInfoDictionary.inputMap;
const TableColumns = [
	{ id: 'title', label: Dict.title.label, width: 300, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'contents', label: Dict.contents.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'textFormat', label: Dict.textFormat.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },,
    { id: 'dataType', label: Dict.dataType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regTime', label: '등록', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    //{ id: 'delYn', label: Dict.delYn.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} }
];

export default function DownloadInfoList({
	records,
	selected,
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
    onRequestView,
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [search, setSearch] = useState('');

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            case AppWord.CMD_VIEW: onRequestView(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
		if(onClickItem) onClickItem(item);
    };

	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    const ViewCommand = {id:AppWord.CMD_VIEW, icon: <PageviewIcon fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor} />};

    return(
        <DownloadInfoListContainer>
            <HeaderBox>
				<HeaderControl>
					<FormControl variant='outlined' size={AppWord.SMALL}>
						<InputLabel htmlFor="search-DownloadInfo-list" style={{backgroundColor:'white'}}>검색</InputLabel>
						<OutlinedInput id="search-DownloadInfo-list" onChange={onChangeSearch}
							value={search}
							disabled={records.length === 0}
							size={AppWord.SMALL}
							endAdornment={
								Boolean(search) ?
								<InputAdornment position={AppWord.END}>
									<IconButton
									onClick={()=>{setSearch('')}}
									size={AppWord.SMALL}
									edge={AppWord.END}
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
								:
								null
							}
						/>
					</FormControl>
				</HeaderControl>
				<ButtonBox>
				{
					isAdmin
					?
						<Button
							onClick={onRequestAdd}
							variant={AppPalette.VariantContained} color={AppPalette.PrimaryColor}>
							<AddIcon fontSize={AppWord.SMALL} />
							다운로드 정보 추가
						</Button>
					: null
				}
				</ButtonBox>
            </HeaderBox>
            <FlexyTable
                name="download_info_list"
                uniqueKey="msgSeq"
                columns={TableColumns}
                records={records}
                search={search}
                tools={isAdmin ? [
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} color={AppPalette.WarnColor} />},
                    ViewCommand
                ] : [
                    ViewCommand
                ]}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </DownloadInfoListContainer>
    );

}
