// FlawedList.js
import React from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Waiting } from './Waiting';

const commonStyle = {
    borderRadius:20,
    margin: 10,
    padding: 10,
    textAlign: 'center',
};
const ErrorBox = styled(Box)({
    ...commonStyle,
    border: "5px solid #ffcccc"
});

const NoDataBox = styled(Box)({
    ...commonStyle,
    border: "5px solid #eeffff",
});

const ContentBox = styled(Box)({
    display: 'inline-block'
});

/*
 List용 컴포넌트 안에서 오류가 있으면 오류 내용을 출력하게 하는 컴포넌트.
    if(ValueUtil.flawedListResponse(responseList))
        return <FlawedList title={LocalTitle} gqlResponse={responseList} />;
*/
export default function FlawedList({title, gqlResponse}) {
    const {loading, error, data} = gqlResponse;
    if(loading) {
        return <Waiting />;
    }
    else if(error) {
        return(
            <ErrorBox>
                <ContentBox>
                    <Box style={{fontSize:36}}>{title} 수신 오류</Box>
                    {
                        error.graphQLErrors.map((e,idx)=>{
                            return(<Box key={idx}>{e.message}</Box>);
                        })
                    }
                </ContentBox>
            </ErrorBox>
        );
    }
    else if(!data) {
        return (
            <NoDataBox>
                <ContentBox>
                    <Box style={{fontSize:36}}>{title}</Box>
                    <Box>수신한 데이터가 없습니다</Box>
                </ContentBox>
            </NoDataBox>
        );
    }
    return null;
}
