// Waiting.js
import React from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { List, ListItem, ListItemText, CircularProgress } from '@mui/material';

const OutterMsgContainer = styled(Box)({
    width: '100%',
    textAlign:'center',
    marginTop:90,
});

const InnerMsgContainer = styled(Box)({
    width: 600,
    display:'inline-block',
    verticalAlign: 'middle',
});

const WaitingTitleBox = styled(Box)({
    display:'block',
    color:'#ccccff',
    padding:40,
    fontSize:'48pt',
    borderRadius:50,
});

const ErrorTitleBox = styled(Box)({
    display:'block',
    color:'#ccccff',
    backgroundColor:'#ffaa00',
    padding:40,
    fontSize:'48pt',
    borderRadius:50,
});

const MessageBox = styled(Box)({
    fontSize:"20pt",
    color:"#888888"
})

export function Waiting({message}) {
    return (
        <OutterMsgContainer>
            <InnerMsgContainer>
                <WaitingTitleBox>잠시만 기다려 주세요...</WaitingTitleBox>
                <CircularProgress color='secondary' size={80}/>
                <MessageBox>
                    {message}
                </MessageBox>

            </InnerMsgContainer>
        </OutterMsgContainer>
    )
}

export function ErrorBox({title, messages, graphQLErrors}) {
    return (
        <OutterMsgContainer>
            <InnerMsgContainer>
                <ErrorTitleBox>{title ? title : "오류가 발생했습니다"}</ErrorTitleBox>
                <MessageBox>
                    <List>
                    {
                        messages
                        ?
                        messages.map((msg,idx)=>{
                            return(
                                <ListItem key={idx}>
                                    <ListItemText>{msg}</ListItemText>
                                </ListItem>
                            );
                        })
                        : null
                    }
                    {
                        graphQLErrors
                        ?
                        graphQLErrors.map((e,idx)=>{
                            return(
                                <ListItem key={idx}>
                                    <ListItemText>
                                        {e.message}
                                    </ListItemText>
                                </ListItem>
                            );
                        })
                        :
                        null
                    }
                    {
                        !messages && !graphQLErrors
                        ?
                        <ListItem>
                            <ListItemText>
                                알 수 없는 오류가 발생했습니다.
                            </ListItemText>
                        </ListItem>
                        : null
                    }
                    </List>
                </MessageBox>
            </InnerMsgContainer>
        </OutterMsgContainer>
    )
}

/*


        css={css`
          color: #20b2aa;

          :hover {
            color: #2e8b57;
          }
        `}
*/