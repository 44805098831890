//FlexyTableStyled.js
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';

/*
FlexySize, BorderColor,
PanTableContainer, FooterBox, TableContainer,
TableHeaderRow, TableHeaderRowContent, TableBodyBox,
TableRowDiv, HeaderToolBox, HeaderColumnLabel,
HeaderColumnDiv, HeaderColumnResizer, ResizerBar,
CellAttr, CellDiv, IndexCellDiv, HeaderToolRow,
ColumnToolIconDiv
*/

// Header는 테이블이 가지지 않도록 하자. 바깥의 컨트롤러가 가지게 해야...
export const FlexySize = {
    FooterHeight: 48,
    HeaderRowHeight: 46,
    RowHeight: 40,
    CellPaddingLeft: 5,
    IndexCellWidth: 70,
    ResizerWidth: 19,
    BubbleRowHeight: 24,
    CommonPadding: 10,
};

export const BorderColor = '#dddddd';
export const HoverRowBackgroundColor = 'rgb(226, 252, 253)';

export const FlexyTableContainer = styled(Box)({
    flexGrow:1,
    position:'relative',
    whiteSpace:'nowrap',
    border: '1px solid ' + BorderColor,
    borderRadius: 3, // backgroundColor:'#f88'
});

export const FooterBox = styled(Box)({
    height: FlexySize.FooterHeight,
});
export const TableContainer = styled(Box)({
    position:'absolute', top:0, left:0, bottom:0, ////////backgroundColor: '#8f8',
    overflowX: 'auto', width:'100%',
    overflowY: 'auto', // 내부에서 TableBodyBox가 상하 스크롤.
});
export const TableHeaderRow = styled(Box)({
    position: 'absolute',
    display:'block',
    top:0,
    left:0,
    height: FlexySize.HeaderRowHeight, // 위, 아래 border.
    //borderTop: '1px solid ' + BorderColor,
    //borderRight: '1px solid ' + BorderColor, // borderLeft는 각 칼럼에서.
    //borderBottom: '1px solid ' + BorderColor,
    overflow:'hidden',
    borderBottomRightRadius:5,
    background:'linear-gradient(#dddddd, #eaeaea, #ededed, #f0f0f0, #f2f2f2, #f0f0f0, #ededed, #eaeaea, #dddddd)'
});
export const TableHeaderRowContent = styled(Box)({ // inside TableHeaderRow
    height: FlexySize.HeaderRowHeight-2,
    position: 'absolute',
    top:0,
    verticalAlign: 'top'
});
export const TableBodyBox = styled(Box)({
    position: "relative", ////////backgroundColor:'yellow',
});

export const TableTopMarginBox = styled(Box)({
    height: FlexySize.HeaderRowHeight,
    background: 'linear-gradient(#d8d8d8, #eaeaec, #ededef, #f0f0f2, #f2f2f4, #f0f0f2, #edede4, #eaeaed, #d8d8d8)'
});

export const TableToolBarDiv = styled(Box)({
    position: 'absolute',
    top: 3,
    display: 'inline-block',
    backgroundColor: 'white',
    borderRadius: 5,
    border: '1px solid #0088ff',
    padding: '0px 3px 0px 3px'
});

export const TableRowDiv = styled(Box)({
    position: 'relative',
    display: 'block',
    boxSizing: 'border-box',
    height: FlexySize.RowHeight,
    padding: 0,
    borderTop: '1px solid ' + BorderColor,
    cursor: 'pointer',
    fontSize: '10pt', // width: '100%',
    "&:hover": {
        color: '#5c0000',
        backgroundColor: HoverRowBackgroundColor
    },
    "&:last-of-type": {borderBottom: '1px solid ' + BorderColor}
});


export const HeaderColumnLabel = styled('div')`
    display: inline-flex; height:98%; align-items: center; justify-content: center;
`;
export const HeaderColumnDiv = styled('div')`
    position: relative;
    display: inline-block;
    cursor: default;
    height: ${FlexySize.HeaderRowHeight-1}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: ${FlexySize.CellPaddingLeft}px;
    vertical-align: top;
    :hover > div {font-size: 8pt; visibility:visible;}
`;
//export const HeaderColumnUnsortable = styled('div')``;
// for resizing a column    ,
export const HeaderColumnResizer = styled('div')`
    position: absolute;
    height: ${FlexySize.HeaderRowHeight - 2}px;
    width: ${FlexySize.ResizerWidth}px;
    top: 1px;
    display: inline-block;
    background: linear-gradient(#d8d8d8, #eaeaec, #ededef, #f0f0f2, #f2f2f4, #f0f0f2, #edede4, #eaeaed, #d8d8d8);
    align-content: center;
`;
export const ResizerBar = styled('div')`
    display: inline-block;
    cursor: col-resize;
    height: ${FlexySize.HeaderRowHeight - 2}px;
    width: 2px;
    margin: 0px 0px 0px 4px;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    background: linear-gradient(#d8d8d8, #eaeaec, #ededef, #f0f0f2, #f2f2f4, #f0f0f2, #edede4, #eaeaed, #d8d8d8);
    font-size: 1pt;
`;

const CellAttr = {
    display: 'inline-flex',
    height: FlexySize.RowHeight-1,//1, // padding + 1
    //verticalAlign: 'top',
    alignItems:'center'
    //paddingTop: 10,
    //'&>*': {verticalAlign:'center'}
};
const CellDivS = styled('div')({
    ...CellAttr,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: FlexySize.CellPaddingLeft,
    borderLeft: '1px solid #fff',
});

const InCell = styled('div')({flexGrow:1});

export const CellDiv = ({children, style}) => {
    return(
        <CellDivS>
            <InCell style={style}>{children}</InCell>
        </CellDivS>
    );
};

const MonoDivS = styled('div')({
    ...CellAttr,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: FlexySize.CellPaddingLeft,
    borderLeft: '1px solid #fff',
    fontFamily: 'Consolas, Monaco, Courier, monospace'
});

export const MonoDiv = ({children, style}) => {
    return(
        <MonoDivS>
            <InCell style={style}>{children}</InCell>
        </MonoDivS>
    );
};

export const IndexCellDiv = styled('div')({
    ...CellAttr,
    width: FlexySize.IndexCellWidth,
    justifyContent:'center',
});



export const HeaderToolBox = styled('div')({
    display: 'inline-block',
    visibility: 'hidden',
    position: 'absolute',
    top: 4, right: 4,
    fontSize: '8pt',
});
export const HeaderToolRow = styled('div')({
    display:'block',
});
// icon <||>  |><| |-> <-|
const ColumnToolAttr = {
    display: 'inline-block',
    cursor: 'pointer',
    fontFamily: 'Arial',
    fontSize: '6pt',
    verticalAlign: 'middle',
    padding: '2px 0px 2px 0px',
    borderRadius: 3,
    width: 14,
    height: 10,
    textAlign: 'center',
    margin: 1
};
export const ColumnToolIconDiv = styled('div')({
    ...ColumnToolAttr,
    color: '#0088ff',
    border: '1px solid #0088ff',
    backgroundColor: '#f3f3f3',
});
export const ColumnToolSelectedIconDiv = styled('div')({
    ...ColumnToolAttr,
    color: '#ff8800',
    border: '1px solid #ff8800',
    backgroundColor: '#dddddd',
});

export const BubbleRowDiv = styled('div')({
    display:'block',
    height: FlexySize.BubbleRowHeight - 4, // - padding, border-bottom
    paddingTop: 3,
    borderBottom: '1px solid #cccccc'
});

export const BubbleColumnDiv = styled('div')({
    display:'inline-block',
    verticalAlign: 'top',
    '&:first-of-type': {color: '#777777', paddingRight:5}
});

export const BubbleColumnPair = styled('div')({
    display: 'inline-block',
    padding: 5,
    verticalAlign: 'top',
})

export const BubbleBoxForFlexy = styled(Box)({
    position:'fixed', //'absolute',
    display:'inline-block',
    backgroundColor: '#ffffaa',
    border: '3px solid ' + HoverRowBackgroundColor,
    borderRadius: 5,
    padding: 5,
    fontSize: 'small',
    overflow: 'auto'
});

/*
export const TriangleDownDiv = styled('div')`
    width: 0;
    height: 10;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 6px solid #555;
`;
*/
// import CodeIcon from '@mui/icons-material/Code';