// term_model_info/TermModelInfoGql.js
import { gql } from "@apollo/client";


export const LIST_TERM_MODEL_INFO = gql`
query ListTermModelInfo {
    termModelInfoList {
        modelId
        model
        etc
        makerId
        protocol
        passwd
        hasGuiYn
        alarmYn
    }
}
`;
