// user_poi_relation/UserPoiRelationGql.js
import { gql } from "@apollo/client";

export const UserPoiRelationDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        userPoiRel : { toAdd: true, toEdit: true },
        poiId : { toAdd: true, toEdit: true },
        userId : { toAdd: true, toEdit: true },
    },
    errorTitleMap: {
        Add: '지도표시지점 저장 오류',
        Remove: '지도표시지점 삭제 오류',
        List: '지도표시지점목록 조회 오류',
    },
    errorTitleRelation: {
        Add: '관계설정 저장 오류',
        Remove: '관계설정 삭제 오류',
        List: '관계설정목록 조회 오류',
    }
};

const USER_POI_RELATION_LIST_PART = gql`
fragment UserPoiRelationListPart on UserPoiRelation {
    userPoiRel
    poiId
    userId
    userName
    regUserId
    regTime
    custId
    poiName
    poiType
    typeName
    custPoiCode
    lat
    lon
}
`;


export const LIST_USER_POI_RELATION_POI_LIST_FOR_USER = gql`
${USER_POI_RELATION_LIST_PART}
query ListPoisUserPoiRelationForUser($userPoiRel:String, $userId:String) {
    userPoiRelationPoiListForUser(userPoiRel:$userPoiRel,userId:$userId) {
        ...UserPoiRelationListPart
    }
}
`;

export const LIST_USER_POI_RELATION_USER_LIST_FOR_POI = gql`
${USER_POI_RELATION_LIST_PART}
query ListUsersUserPoiRelationForPoi($userPoiRel:String, $poiId:Int) {
    userPoiRelationUserListForPoi(userPoiRel:$userPoiRel,poiId:$poiId) {
        ...UserPoiRelationListPart
    }
}
`;

export const ADD_USER_POI_RELATION_RET_POIS = gql`
${USER_POI_RELATION_LIST_PART}
mutation AddUserPoiRelationReturnPois($userPoiRelation:InputUserPoiRelation!) {
    userPoiRelationAddAndReturnPoiList(userPoiRelation: $userPoiRelation) {
        ok
        message
        list {
            ...UserPoiRelationListPart
        }
    }
}
`;

export const REMOVE_USER_POI_RELATION_RET_POIS = gql`
${USER_POI_RELATION_LIST_PART}
mutation RemoveUserPoiRelationReturnPois($userPoiRelation:InputUserPoiRelation!) {
    userPoiRelationRemoveAndReturnPoiList(userPoiRelation: $userPoiRelation) {
    ok
    message
        list {
            ...UserPoiRelationListPart
        }
    }
}
`;

export const ADD_USER_POI_RELATION_RET_USERS = gql`
${USER_POI_RELATION_LIST_PART}
mutation AddUserPoiRelationReturnUsers($userPoiRelation:InputUserPoiRelation!) {
    userPoiRelationAddAndReturnUserList(userPoiRelation: $userPoiRelation) {
        ok
        message
        list {
            ...UserPoiRelationListPart
        }
    }
}
`;

export const REMOVE_USER_POI_RELATION_RET_USERS = gql`
${USER_POI_RELATION_LIST_PART}
mutation RemoveUserPoiRelationReturnUsers($userPoiRelation:InputUserPoiRelation!) {
    userPoiRelationRemoveAndReturnUserList(userPoiRelation: $userPoiRelation) {
        ok
        message
        list {
            ...UserPoiRelationListPart
        }
    }
}
`;
