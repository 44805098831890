// VehInfoList.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button, IconButton, TextField } from '@mui/material';
import VehFilter from '../common/VehFilter/VehFilter';
import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlawedList from '../message/FlawedList';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import styled from '@emotion/styled';
import ConfirmDialog from '../message/ConfirmDialog';
const LocalTitle = '차량정보';

const VehInfoListContainer = styled(Box)({
    flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    display: 'inline-block',
    float: 'right',
});

const ToolEventToggleUseYn = 'toggleUseYn';
const TableColumns = [
    //{ id: 'veh_id', label: 'veh_id', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.INTEGER },
    //{ id: 'cust_id', label: 'cust_id', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.TEXT },
	{ id: 'vehPlates', label: '차량번호', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'vehAlias', label: '지도표시명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'gpsTime', label: '최종보고', width: 180, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
	{ id: 'distKm', label: '누적거리', width: 100, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'vehTypeTon', label: '차종-톤수', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'termId', label: '단말기번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehAddr', label: '차고지', width: 100, show: false, option:{align: 'inherit', type:TableColumnType.TEXT} },
	{ id: 'driverName', label: '기사', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'driverMobile', label: '휴대전화', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'termPowerValue', label: '전원공급방식', width: 120, show: true, option:{align: 'inherit', type:TableColumnType.TEXT} },
    { id: 'useYn', label: '사용여부', width: 100, show: true, option:{
        align: 'center', type:TableColumnType.CHAR, ynBoolean: ['사용 중','사용중지'],
        toggle: ToolEventToggleUseYn
    } },
    { id: 'termEtc', label: '비고(단말기)', width: 300, show: true, option:{align: 'inherit', type:TableColumnType.TEXT} },
    { id: 'custName', label: '운송사', width: 100, show: true, option:{align: 'inherit', type:TableColumnType.TEXT} }, // VEH_AND_TERM
    /* 청소업체 운행통계에 이 코드 사용.
    { id: 'veh01_cd', label: 'veh01_cd', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.INTEGER },
    { id: 'veh02_cd', label: 'veh02_cd', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.INTEGER },
    { id: 'veh03_cd', label: 'veh03_cd', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.INTEGER }*/
];

export default function VehInfoList({
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
    onRequestToggleUseage,
    responseList,
    responseRemove
}) {
    const [filterBoxState, setFilterBoxState] = useState({open:false});
    const [vehFilterCriteria, setVehFilterCriteria] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [search, setSearch] = useState('');
    const {loading:loadingList, error:errorOfList, data:dataList} = responseList;
    const clientSize = useClientSize();


    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const checkUseVehicle = (row) => {
        setPromptToConfirm({
            data: true,
            title: row.vehPlates + ' 차량사용',
            messages: ['사용중지된 차량을 다시 사용상태로 바꿉니다.', '계속하시겠습니까?'],
            labelToYes: '예, 사용합니다',
            callback: (yes) => {
                setPromptToConfirm(null);
                if(yes) {
                    onRequestToggleUseage({vehId:row.vehId, useYn:"Y"});
                }
            }
        });
    };

    const checkNotToUseVehicle = (row) => {
        setPromptToConfirm({
            data: true,
            title: row.vehPlates + ' 차량사용중지',
            messages: ['차량을 사용중지합니다.', '계속하시겠습니까?'],
            labelToYes: '예, 사용중지합니다',
            callback: (yes) => {
                setPromptToConfirm(null);
                if(yes) {
                    onRequestToggleUseage({vehId:row.vehId, useYn:"N"});
                }
            }
        });
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            case ToolEventToggleUseYn+"+Y": checkUseVehicle(row); break;
            case ToolEventToggleUseYn+"+N": checkNotToUseVehicle(row); break;
            default: return;
        }
    };

    const onCloseFilterBox = () => {setFilterBoxState({open:false});};

    const onClickSearchFilterButton = (event) => {
        setFilterBoxState({
            open:true, 
            top: clientSize.HeaderHeight 
                + clientSize.dataContainerPadding 
                + clientSize.SmallBoxPadding,
            left: event.clientX - 10});
    };

    // filter test:  vehTonId: 1 + 3
    //const criteria = {vehTonId: [1, 3]};
    const onCompleteFilterBox = (criteria) => {
        setVehFilterCriteria(criteria);
        setFilterBoxState({open:false});
    };


    // Handling list event
    if(ValueUtil.flawedListResponse(responseList))
        return <FlawedList title={LocalTitle} gqlResponse={responseList} />;


    // redner -------------------
    const renderConfirmDialog = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    return(
        <VehInfoListContainer>
            <HeaderBox>
                <ButtonBox>
                    <Button variant='contained' onClick={onRequestAdd}>
                        <AddIcon fontSize='small' />
                        &nbsp;차량 추가
                        </Button>
                </ButtonBox>

                <TextField id="search" onChange={onChangeSearch}
                    value={search}
                    label="검색" size='small'/>
                <IconButton size='small' onClick={onClickSearchFilterButton}>
                    <FilterAltIcon />
                </IconButton>
            </HeaderBox>
            <FlexyTable
                name="veh_info_list"
                uniqueKey="vehId"
                columns={TableColumns}
                records={dataList.vehInfoList}
                search={search}
                criteria={vehFilterCriteria}
                tools={[
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize='small' />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize='small' />}
                ]}
                onClickTool={onClickToolOnRow}
            />
            <VehFilter open={filterBoxState.open}
                top={filterBoxState.top}
                left={filterBoxState.left}
                useCustAgree={true}
                onComplete={onCompleteFilterBox}
                onCloseBox={onCloseFilterBox} />
            {renderConfirmDialog()}
        </VehInfoListContainer>
    );

}
/*

            ///<PanTable
                name="veh_info_list"
                uniqueKey="vehId"
                height={TableHeight} width={clientSize.dataAreaWidth - AppNumber.dataContainerPadding * 2}
                columns={TableColumns}
                records={dataList.vehInfoList}
                search={search}
                criteria={vehFilterCriteria}
                tools={[
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize='small' />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize='small' />}
                ]}
                onClickTool={onClickToolOnRow}
            />

*/
