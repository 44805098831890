//com/archive/ArchiveGql.js
import { gql } from "@apollo/client";


export const LIST_POS_ARCHIVE = gql`
query ListPosArchive {
    getArchiveList {
        fileName
        size
        type
    }
}
`;

