// BcdIoDataListForVehicle.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button, IconButton, Popover, TextField } from '@mui/material';
import LocalParkingIcon from '@mui/icons-material/LocalParking'; // staying
import AirIcon from '@mui/icons-material/Air'; // Not staying
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TimelineIcon from '@mui/icons-material/Timeline'; // Route
import ClearIcon from '@mui/icons-material/Clear';
import ResponseAlert from '../message/ResponseAlert';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType } from '../../model/AppConst';
import { useLazyQuery } from '@apollo/client';
import { LIST_BCD_IO_DATA_FOR_VEHICLE, LIST_OF_VEHICLE } from './BcdIoDataGql';
import DurationPicker from '../common/DurationPicker';

const BcdIoDataListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display: 'flex', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding, alignItems: 'center'
});

const ControllerBox = styled(Box)({
    display: 'flex', alignItems: 'center'
});

const TabBox = styled(Box)({
    flexGrow:1,
    textAlign: 'right', display: 'flex', alignItems: 'center', paddingLeft: 20
});

const SelectorBox = styled(Box)({
    display: 'inline-block', overflow:'auto', backgroundColor:'#f5f5f5', border:'1px solid #ccc', borderRadius:5, padding:10
});

const SelectRow = styled(Box)({
    borderBottom: '1px solid #ddd', cursor:'pointer',
    '&:hover': {backgroundColor:'white'}
});

const ItemMainText = styled(Box)({
    display:'inline-block', width:130, overflow:'hidden', padding:5,
});

const ItemSubText = styled(Box)({
    display:'inline-block', maxWidth:200, overflow:'hidden', padding:5, fontSize: '0.95em', color:'#555'
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'poiNamePrev', label: '이전지점', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'depTimePrev', label: '이전지점 출발시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'distKm', label: '이동거리(Km)', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'secRun', label: '이동시간', width: 90, show: true, option:{align: 'right', print:(r,i,v)=>{return ValueUtil.formatDuration(v)}} },
    { id: 'poiName', label: '지점', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'arrTime', label: '도착시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'depTime', label: '출발시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'secStay', label: '머문시간', width: 90, show: true, option:{align: 'right', print:(r,i,v)=>{return ValueUtil.formatDuration(v)}} },
    { id: 'staying', label: '방문상태', width: 80, show: true, option:{align: 'center', print:(r,i,v) => {
        if(v) return <><LocalParkingIcon fontSize={AppWord.SMALL} color="primary" style={{verticalAlign:'bottom'}}/> 방문 중</>
        else return <><AirIcon fontSize={AppWord.SMALL} color="disabled" style={{verticalAlign:'bottom'}}/> 떠남</>
    }} },
    { id: 'custName', label: '업체명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

const EmptyDuration = {from:ValueUtil.getYmdHmsInputText(null, 86400), till:ValueUtil.getYmdHmsInputText(null)};
const NullVehicle = {vehPlates:'차량선택:'};

export default function BcdIoDataListForVehicle({
    tabSelector,
	maxHeight,
	maxWidth,
    onRequestViewRoute,
}) {
    const [vehicle, setVehicle] = useState(NullVehicle);
    const [vehicleList, setVehicleList] = useState([]);
    const [searchSelector, setSearchSelector] = useState('');
    const [selectorAnchor, setSelectorAnchor] = useState(null);
    const [records, setRecords] = useState([]);
    const [duration, setDuration] = useState(EmptyDuration);
    const [search, setSearch] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getVehicleList, responseVehList] = useLazyQuery(LIST_OF_VEHICLE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetVehicleList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '차량목록 조회 오류'})}
    });

    useEffect(()=>{
        getVehicleList();
    },[getVehicleList]);

    const [getBcdIoDataList, responseList] = useLazyQuery(LIST_BCD_IO_DATA_FOR_VEHICLE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '출발, 도착 기록 조회 오류'})}
    });

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetVehicleList = (data, option) => {
        if(data.vehInfoList) {
            if(data.vehInfoList.length>0) {
                let newVeh = data.vehInfoList[0];
                for(var rec of data.vehInfoList) {
                    if(rec.vehId===vehicle.vehId) {
                        newVeh = {...rec};
                        break;
                    }
                }
                setVehicle(newVeh);
            }
            else setVehicle(NullVehicle);
            setVehicleList(data.vehInfoList);
        }
    };

    const onCompleteGetList = (data, clientOption) => {
        if(data.bcdIoDataListForVehicle) {
            setRecords(data.bcdIoDataListForVehicle);
        }
    };

    const onChangeFrom = (from) => {
        const dur = {...duration};
        dur.from = from;
        setDuration(dur);
    };

    const onChangeTill = (till) => {
        const dur = {...duration};
        dur.till = till;
        setDuration(dur);
    };

    const onClickToGetList = () => {
        if(duration.from && duration.till && vehicle && vehicle.vehId)
            getBcdIoDataList({
                variables: {
                    vehId: vehicle.vehId,
                    timeFrom: duration.from,
                    timeTill: duration.till}
            });
    };

    const onClickSelectVehicle = (item) => {
        setVehicle(item);
        setSelectorAnchor(null);
    };

    const onCloseSelector = () => {
        setSelectorAnchor(null);
    };

    const onClickOpenSelector = (event) => {if(vehicleList) setSelectorAnchor(event.currentTarget)};

    const onChangeSearchSelector = (event) => {
        if(event.target.value) {
            setSearchSelector(event.target.value.trim());
        }
        else setSearchSelector('');
    };

    const onClickToolOnRow = (toolId, row) => {
        if(toolId === AppWord.ROUTE) {
            if(onRequestViewRoute) {
                // find timeFrom.
                const timeTill = row.arrTime;
                let timeFrom = duration.from;
                let prevRec = {vehId:null};
                for(const rec of records) {
                    if(rec.vehId===row.vehId) {
                        if(row.vehId===prevRec.vehId && row.arrTime === rec.arrTime) {
                            timeFrom = prevRec.depTime;
                            break;
                        }
                        prevRec = rec;
                    }
                }
                onRequestViewRoute({vehId:row.vehId, vehPlates:row.vehPlates}, timeFrom, timeTill);
            }
        }
    };

    const filterSelector = (item) => {
        if(searchSelector && searchSelector.length>0) {
            return item.vehPlates.indexOf(searchSelector) >= 0;
        }
        return true;
    };

    const renderVehicleSelector = (height) => {
        const open = Boolean(selectorAnchor);
        return(
            <Popover
                open={open}
                anchorEl={selectorAnchor}
                onClose={onCloseSelector}
                anchorOrigin={{ vertical: "top", horizontal: "right", }}
                transformOrigin={{ vertical: "top", horizontal: "left", }}
            >
                <SelectorBox
                    maxHeight={height}
                >
                    {
                        vehicleList.length > 5
                        ?
                        <>
                        <TextField size={AppWord.SMALL}
                            onChange={onChangeSearchSelector}
                        />
                        <IconButton size={AppWord.SMALL}
                            onClick={()=>{setSearchSelector('')}}
                        >
                            <ClearIcon size={AppWord.SMALL} />
                        </IconButton>
                        </>
                        :
                        null
                    }
                    {
                        vehicleList.length===0
                        ? <span>차량이 없습니다</span>
                        :
                        vehicleList.filter(filterSelector).map((item) => {
                            return(
                                <SelectRow key={item.vehId}
                                    onClick={()=>{onClickSelectVehicle(item)}}
                                >
                                    <ItemMainText>{item.vehPlates}</ItemMainText>
                                    <ItemSubText>{item.driverName} {item.driverMobile}</ItemSubText>
                                </SelectRow>
                            );
                        })
                    }
                </SelectorBox>
            </Popover>
        );
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - AppNumber.dataContainerPadding * 2;
	
    const TableHeight = height - clientSize.HeaderToolBoxHeight
        - clientSize.SmallBoxPadding * 2 - 2;

    return(
        <BcdIoDataListContainer>
            <HeaderBox>
                <ControllerBox>
                    <span style={{fontWeight:'bold'}}>{vehicle.vehPlates}</span>
                    <IconButton
                        onClick={onClickOpenSelector}
                        color="primary"
                    >
                        <KeyboardArrowDownIcon />
                    </IconButton>
                    {renderVehicleSelector(TableHeight-clientSize.SmallBoxPadding * 2)}
                    <DurationPicker
                        from={duration.from} onChangeFrom={onChangeFrom}
                        till={duration.till} onChangeTill={onChangeTill}
                    />
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={!Boolean(duration)}
                        onClick={onClickToGetList}
                        style={{marginLeft:10, marginRight:20}}
                    >
                        조회
                    </Button>
                </ControllerBox>
                <TabBox>
                    {tabSelector}
                </TabBox>
            </HeaderBox>
            <FlexyTable
                name="bcd_io_data_list_for_vehicle"
                uniqueKey="dataKey"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[{id:AppWord.ROUTE, icon:<TimelineIcon fontSize={AppWord.SMALL} color="primary" />}]}
                onClickTool={onClickToolOnRow}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </BcdIoDataListContainer>
    );
}
/*

            ///<PanTable
                name="bcd_io_data_list_for_vehicle"
                uniqueKey="dataKey"
                height={TableHeight} width={width}
                columns={TableColumns}
                records={records}
                search={search}
                tools={[{id:AppWord.ROUTE, icon:<TimelineIcon fontSize={AppWord.SMALL} color="primary" />}]}
                onClickTool={onClickToolOnRow}
            />

*/
