// veh_group_bcd_map/VehGroupBcdMapGql.js
import { gql } from "@apollo/client";

import ValueUtil from "../../model/ValueUtil";

export const VehGroupBcdMapDictionary = {
    inputMap: {
        groupId : {toAdd: true, toEdit: true,},
        poiId : {toAdd: true, toEdit: true,},
        bcdBorderCd : {
            id: 'bcdBorderCd', label: '출발도착지점유형', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default:'LOCATION',
            check: (value) => {return true},
        },
        bcdEventCd : {
            id: 'bcdEventCd', label: '감지 내용', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default:'BOTH',
            check: (value) => {return true},
        },
        borderDesc : {
            id: 'borderDesc', label: '메모(70자 이내)', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '70자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,200)},
        },

    },
    errorTitle: {
        Add: '출발도착 정보 설정 오류',
        Remove: '출발도착 정보 삭제 오류',
        List: '출발도착 목록 조회 오류',
    }
};


export const LIST_VEH_GROUP_POI_AND_MAP = gql`
query ListVehGroupPoiAndMap($userId:String!) {
    vehGroupBcdMapList {
        dataKey
        groupId
        groupName
        poiId
        poiName
        bcdBorderCd
        bcdBorderValue
        bcdEventCd
        bcdEventValue
        borderDesc
        regUserId
        regTime
    }
    vehGroupListForUser(userId:$userId) {
        groupId
        groupName
        parentNodeId
        userId
        treeLevel
    }
    poiInfoList {
        poiId
        poiName
        poiType
        typeName
        custPoiCode
        addr
        tel
        useBcdYn
        bcdMapped
    }

}
`;

export const SET_VEH_GROUP_BCD_MAP = gql`
mutation SetVehGroupBcdMap($vehGroupBcdMap:InputVehGroupBcdMapSet!) {
  vehGroupBcdMapSet(vehGroupBcdMap: $vehGroupBcdMap) {
    ok
    message
        list {
            dataKey
            groupId
            groupName
            poiId
            poiName
            bcdBorderCd
            bcdBorderValue
            bcdEventCd
            bcdEventValue
            borderDesc
            regUserId
            regTime
        }
  }
}
`;


export const REMOVE_VEH_GROUP_BCD_MAP = gql`
mutation RemoveVehGroupBcdMap($vehGroupBcdMap:InputVehGroupBcdMapRemove!) {
    vehGroupBcdMapRemove(vehGroupBcdMap: $vehGroupBcdMap) {
        ok
        message
        list {
            dataKey
            groupId
            groupName
            poiId
            poiName
            bcdBorderCd
            bcdBorderValue
            bcdEventCd
            bcdEventValue
            borderDesc
            regUserId
            regTime
        }
    }
}
`;