// CustPoiType.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CustPoiTypeList from './CustPoiTypeList';
import CustPoiTypeInput from './CustPoiTypeInput';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_CUST_POI_TYPE, GET_CUST_POI_TYPE, ADD_CUST_POI_TYPE, EDIT_CUST_POI_TYPE, REMOVE_CUST_POI_TYPE,
	CustPoiTypeDictionary
} from './CustPoiTypeGql';
import { ViewMode, AppObject, AppNumber, AppPalette } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser, poiTypeRepo } from '../../model/CvoModel';
import { ButtonWarning } from '../common/CvoButtons';

const dcPad = AppNumber.dataContainerPadding;
const ListOrEditor = styled(Box)({
	display:'flex'
});

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const ErrorTitle =CustPoiTypeDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(CustPoiTypeDictionary.inputMap, true);


export default function CustPoiType({
    open,
    onClose
}) {
	const [custPoiTypeRecords, setCustPoiTypeRecords] = useState([]);
	const [iconPoolRecords, setIconPoolRecords] = useState([]);
	//const [custPoiTypeSelected, setCustPoiTypeSelected] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();
    // const responseList = useQuery(GET_CUST_POI_TYPE_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});

    // ##### Call GraphQL to get List #####
    const [getCustPoiTypeList, responseList] = useLazyQuery(LIST_CUST_POI_TYPE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getCustPoiTypeItemToEdit, responseItemToEdit] = useLazyQuery(GET_CUST_POI_TYPE, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation.  ###
    const [addCustPoiType, responseAdd] = useMutation( ADD_CUST_POI_TYPE, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editCustPoiType, responseEdit] = useMutation( EDIT_CUST_POI_TYPE, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeCustPoiType, responseRemove] = useMutation( REMOVE_CUST_POI_TYPE, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        if(open) getCustPoiTypeList();
    }, [open, getCustPoiTypeList]);

    const onResultCustPoiTypes = (records) => {
        poiTypeRepo(records);
        setCustPoiTypeRecords(records);
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.custPoiTypeList) setCustPoiTypeRecords(data.custPoiTypeList);
        //else setResponseAlert({open:true, resultData: data.custPoiTypeRemove, title: ErrorTitle.List});
        if(data.iconPoolList) setIconPoolRecords(data.iconPoolList);
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.custPoiTypeAdd.ok) {
            setEditorState(null);
            onResultCustPoiTypes(data.custPoiTypeAdd.list);
        }
        else setResponseAlert({open:true, resultData: data.custPoiTypeAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.custPoiTypeEdit.ok) {
            setEditorState(null);
            onResultCustPoiTypes(data.custPoiTypeEdit.list);
        }
        else setResponseAlert({open:true, resultData: data.custPoiTypeEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.custPoiTypeRemove.ok) onResultCustPoiTypes(data.custPoiTypeRemove.list);
        else setResponseAlert({open:true, resultData: data.custPoiTypeRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.custPoiTypeItem) {
            const item = {...data.custPoiTypeItem};
            //for(const field of EditFields) item[field] = data.custPoiTypeItem[field];
            setEditorState({item: item});
        }
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestEdit = (data) => {
        if(responseEdit) responseEdit.reset();
        getCustPoiTypeItemToEdit({variables: {custId: data.custId,poiType: data.poiType}});
        // setEditorState({item: data});
    };

    const onRequestRemove = (item) => {
        setPromptToConfirm({
            data: item,
            title: '지점타입 삭제',
            messages: [
                '지정한 ' + item.typeName + ' 을 삭제하시겠습니까?',
                '해당 지점타입에 속한 지점이 없으면 타입정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{custPoiType:{poiType: data.poiType}}};
                    removeCustPoiType(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by CustPoiTypeInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {custPoiType: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editCustPoiType(param);
        else addCustPoiType(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList,
        responseItemToEdit,
        responseAdd,
        responseEdit,
        responseRemove
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <CustPoiTypeInput
                isEdit={true}
                item={es.item}
                iconList={iconPoolRecords}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
            :
            <CustPoiTypeInput
                isEdit={false}
                iconList={iconPoolRecords}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

    const renderList = () => {
        return(
            <CustPoiTypeList
                records={custPoiTypeRecords}
                onRequestAdd={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
            />
        );
    };

    let contentHeight = clientSize.dataAreaHeight - 100;
    if(contentHeight>500) contentHeight=500;
    else if(contentHeight<200) contentHeight = 200;

    return (
        <Dialog maxWidth='lg' open={open}>
            <EditorTitle>
                {
                    Boolean(editorState)
                    ?
                    (
                        editorState.item ? editorState.item.typeName + " 지점타입 수정" : "지점타입 추가"
                    )
                    : "지점타입 관리"
                }
                
            </EditorTitle>
            <DialogContent>
                <ListOrEditor style={{minWidth:600, height:contentHeight}}>
                {
                    Boolean(editorState)
                    ? renderEditor()
                    : renderList()
                }

                </ListOrEditor>
            </DialogContent>
            {
                Boolean(editorState) ? null :
                <DialogActions>
                    <ButtonWarning onClick={onClose} disabled={Boolean(editorState)}>
                        닫기
                    </ButtonWarning>
                </DialogActions>
            }
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </Dialog>
    )
}
