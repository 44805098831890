//CustAgree.js

import React from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

const CustAgreeContainer = styled(Box)({
    position:"absolute", top:10, bottom:10, left:10, right:10,
	display:'flex', height: '100%', justifyContent:'center', alignItems:'center'
});

const MsgBox = styled(Box)({
    flex:1, textAlign:'center', fontSize:'1.2rem', fontWeight:'bold', padding:20, display:'inline-block',margin:50,
    border:'5px solid #99ccff', borderRadius:10,
    color:'#39f'
});


export default function CustAgree() {
    return (
        <CustAgreeContainer>
            <MsgBox>
                <p>협력그룹 관리는 그룹관리 화면에 포함하였습니다.</p>
                <p>기초정보관리 &gt; 차량그룹관리 메뉴를 이용해 주세요.</p>
            </MsgBox>

        </CustAgreeContainer>
    )
}
