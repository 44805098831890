// user_oper_auth/UserOperAuthGql.js
import { gql } from "@apollo/client";

export const UserOperAuthDictionary = {
    errorTitle: {
        Add: '차량관제권한 설정 오류',
        Remove: '차량관제권한 해제 오류',
    }
};

export const LIST_USER_INFO_WATCH_GROUP = gql`
query ListUserOperAuthOverGroup($groupId:Int!) {
    userInfoListOverGroup(groupId:$groupId) {
        userId
        custId
        userName
        groupId
    }
}
`;

export const ADD_USER_OPER_AUTH = gql`
mutation AddUserOperAuth($userOperAuth:InputUserOperAuth!) {
  userOperAuthAdd(userOperAuth: $userOperAuth) {
    ok
    message
    list {
        userId
        custId
        userName
        groupId
    }
  }
}
`;

export const REMOVE_USER_OPER_AUTH = gql`
mutation RemoveUserOperAuth($userOperAuth:InputUserOperAuth!) {
  userOperAuthRemove(userOperAuth: $userOperAuth) {
    ok
    message
    list {
        userId
        custId
        userName
        groupId
    }
  }
}
`;


export const ADD_USER_OPER_AUTH_RET_GROUPS = gql`
mutation AddUserOperAuthReturnGroups($userOperAuth:InputUserOperAuth!) {
    userOperAuthAddToReturnGroups(userOperAuth: $userOperAuth) {
    ok
    message
    list {
        groupId
        groupName
        parentNodeId
        userId
        treeLevel
    }
  }
}
`;

export const REMOVE_USER_OPER_AUTH_RET_GROUPS = gql`
mutation RemoveUserOperAuthReturnGroups($userOperAuth:InputUserOperAuth!) {
    userOperAuthRemoveToReturnGroups(userOperAuth: $userOperAuth) {
    ok
    message
    list {
        groupId
        groupName
        parentNodeId
        userId
        treeLevel
    }
  }
}
`;


export const ADD_USER_OPER_AUTH_RET_CUST_AGREES = gql`
mutation AddUserOperAuthReturnCustAgrees($userOperAuth:InputUserOperAuth!) {
    userOperAuthAddToReturnCustAgrees(userOperAuth: $userOperAuth) {
    ok
    message
    list {
        groupId
        groupName
        givingCustId
        givingCustName
        editYn
        userId
    }
  }
}
`;

export const REMOVE_USER_OPER_AUTH_RET_CUST_AGREES = gql`
mutation RemoveUserOperAuthReturnCustAgrees($userOperAuth:InputUserOperAuth!) {
    userOperAuthRemoveToReturnCustAgrees(userOperAuth: $userOperAuth) {
    ok
    message
    list {
        groupId
        groupName
        givingCustId
        givingCustName
        editYn
        userId
    }
  }
}
`;
