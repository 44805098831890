// ResponseAlert.js
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'; // Alert by data.message
import WarningAmberIcon from '@mui/icons-material/WarningAmber'; // Alert by error
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const IdDialogTitle = "aria-alert-dialog-title-etrace";
const CloseData = {open:false, messages: []};

export default function ResponseAlert({
    open,
    onClose,
    alertData,
}) {
    const [data, setData] = useState(CloseData);
    const [authError, setAuthError] = useState(false);

    useEffect(() => {
        let msgs = [];
        let errorMsg;
        let resMsg;
        let alertTitle;

        if(open && alertData) {
            if(ValueUtil.hasAuthError(alertData)) {
                errorMsg = alertData.error.message;
                msgs.push("로그아웃 되었습니다. 다시 로그인 해 주세요");
                msgs.push("오랫동안 활동이 없는 경우 그럴 수 있습니다.");
                alertTitle = "로그인상태가 아닙니다.";
                setAuthError(true);
            }
            else {
                if(alertData.error) errorMsg = alertData.error.message;
                if(alertData.resultData) {
                    if(!alertData.resultData.ok) resMsg = alertData.resultData.message;
                }
                alertTitle = alertData.title;

                if(errorMsg) {
                    if(errorMsg.indexOf("\n")) {
                        const lines = errorMsg.split("\n").filter((line) => {return line.trim().length > 0});
                        msgs = msgs.concat(lines);
                    }
                    else msgs.push(errorMsg);
                }
        
                if(resMsg) {
                        if(resMsg.indexOf("\n")) {
                            const lines = resMsg.split("\n").filter((line) => {return line.trim().length > 0});
                            msgs = msgs.concat(lines);
                        }
                        else msgs.push(resMsg);
                }

                if(msgs.length===0 && alertTitle) {
                    msgs.push("오류메시지가 특정되지 않았습니다.");
                }
            }
    
            if(msgs.length > 0) {
                setData({
                    title: alertTitle,
                    messages: msgs,
                    fromError: Boolean(errorMsg)
                });
            }
        }

    }, [open, alertData]);

    const closeDialog = () => {
        // setData(CloseData);
        if(authError) userInfoRepo(NoUser);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            aria-labelledby={IdDialogTitle}
        >
            <DialogTitle id={IdDialogTitle}>
                {data.title || "서버와 데이터 교환 중 오류"}
            </DialogTitle>
            <DialogContent>
                <ul>
                {
                    data.messages.map((msg,idx)=>{
                        return (
                            <li key={idx}>{msg}</li>
                        );
                    })
                }
                </ul>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} autoFocus variant='outlined' color='primary'
                    startIcon={data.fromError ? <WarningAmberIcon /> : <CheckIcon />}>
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}

/*

            aria-describedby={IdDialogContentText}
                <DialogContentText id={IdDialogContentText}>

                </DialogContentText>
*/

