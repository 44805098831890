// UserGetAlarmInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';
import { UserGetAlarmDictionary } from './UserGetAlarmGql';
import Time24Picker from '../common/Time24Picker';
import ConfirmDialog from '../message/ConfirmDialog';
import ValueUtil from '../../model/ValueUtil';

const UgaItemBox = styled(Box)({
    minWidth:100,
    padding:10, margin:5,
    borderRadius:10
});

const TitleBox = styled(Box)({fontWeight:'bold'});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
});

const InputMap = UserGetAlarmDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.

export default function UserGetAlarmInput({
    userInfo,
    item,
    onClickMutate, // Ask controller to submit.
    onClickRemove
}) {
    const [enable, setEnable] = useState(false);
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);

    useEffect(()=>{
        let data;
        if(item) {
            data = {...item};
            data.timeFrom = ValueUtil.filterHms(data.timeFrom, InputMap.timeFrom.default);
            data.timeTill = ValueUtil.filterHms(data.timeTill, InputMap.timeTill.default);
            data.alarmInterval = ValueUtil.thisOrThen(data.alarmInterval, InputMap.alarmInterval.default);
        }
        else data = defaultInputData;

		setInputData(data);
		setInputError({});
		setChanged(false);
		setHasError(false);
        setEnable(false);
    }, [item, userInfo.userId]);

	const resetData = () => {
        let data;
        if(item) {
            data = {...item};
            data.timeFrom = ValueUtil.filterHms(data.timeFrom, InputMap.timeFrom.default);
            data.timeTill = ValueUtil.filterHms(data.timeTill, InputMap.timeTill.default);
            data.alarmInterval = ValueUtil.thisOrThen(data.alarmInterval, InputMap.alarmInterval.default);
        }
        else data = defaultInputData;
		setInputData(data);
		setInputError({});
		setChanged(false);
		setHasError(false);
        setEnable(false);
	};

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, false); // {...inputData};
        param.userId = userInfo.userId;
        onClickMutate(param);
    };

    const onClickCancel = () => {
            resetData();
    };

    const changeInputData = (data) => {
        setInputData(data);
        setChanged(true);
    };

    // Time picker as well
    const onChangeValueForId = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        changeInputData(data);
    };

    const onClickRemoveThis = () => {
        if(item.userId)
            setPromptToConfirm({
                data: item,
                title: '알람수신 종료',
                messages: [
                    '해당 사용자의 ' + item.alarmType + ' 알람 수신을 종료하시겠습니까?',
                    '이 알람수신설정이 즉시, 완전히 삭제됩니다.',
                    '삭제된 정보는 복구할 수 없으며, 필요한 경우 제설정해야 합니다',
                    '정보 삭제를 진행하시겠습니까?'
                ],
                callback: (data) => {
                    setPromptToConfirm(null);
                    if(data) {
                        const param = {userId: userInfo.userId, alarmTypeId: data.alarmTypeId};
                        onClickRemove(param);
                    }
                }
            });
    };

    // redner -------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTimePicker = (dict, value) => {
        return(
            <FieldBox>
                <Time24Picker
                    id={dict.id}
                    label={dict.label}
                    value={value}
                    disabled={!enable}
                    onChange={onChangeValueForId}
                />
            </FieldBox>
        );
    };

    const color = enable ? 'red' : item.userId ? 'blue' : '#ccc';

    const style= {border:'5px solid ' + color};

    return (
        <UgaItemBox style={style}>
            <TitleBox style={{color: item.userId ? 'black' : '#555'}}>{item.alarmType}</TitleBox>
            {renderTimePicker(InputMap.timeFrom, inputData.timeFrom)}
            {renderTimePicker(InputMap.timeTill, inputData.timeTill)}
            <FieldBox>
                <TextField id={InputMap.alarmInterval.id} size="small" style={{width:125}}
                    type={InputMap.alarmInterval.type}
                    label={InputMap.alarmInterval.label}
                    required={InputMap.alarmInterval.required}
                    value={inputData.alarmInterval}
                    error={inputError.alarmInterval}
                    disabled={!enable}
                    onChange={(e)=>{onChangeValueForId(e.target.id, e.target.value)}} />
            </FieldBox>
            <Box style={{marginTop:10}}>
                {
                    enable
                    ?
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                    :
                    <>
                    <Button variant='contained' color='primary'
                        onClick={()=>setEnable(true)}
                    >
                        {item.userId ? "수정" : "추가"}
                    </Button>
                    {
                        item.userId ?
                    <Button variant='contained' color='warning' onClick={onClickRemoveThis}>
                        삭제
                    </Button>
                    :
                    null
                    }
                    </>
                }
            </Box>
            {renderPromptToConfirmBox()}
        </UgaItemBox>
    );
}
