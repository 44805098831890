// DownloadInfo.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
//import { Link, Button, IconButton } from '@mui/material';
import DownloadInfoList from './DownloadInfoList';
import DownloadInfoInput from './DownloadInfoInput';
import DownloadInfoItem from './DownloadInfoItem';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import {
    LIST_DOWNLOAD_INFO, GET_DOWNLOAD_INFO, ADD_DOWNLOAD_INFO, EDIT_DOWNLOAD_INFO, REMOVE_DOWNLOAD_INFO,
	DownloadInfoDictionary
} from './DownloadInfoGql';
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;
const DownloadInfoContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});

const OpenWhich = AppObject.OpenWhich;
const ErrorTitle =DownloadInfoDictionary.errorTitle;
const DefaultDataTypes = ['매뉴얼','신청서'];
//const EditFields = ValueUtil.getFieldsToSubmit(DownloadInfoDictionary.inputMap, true);

// View link: https://drive.google.com/file/d/1m5fqQHLds4Ar0D30DvVFFH6VZi-5OGS9/view?usp=drive_link
// Download : https://drive.google.com/uc?export=download&id=1m5fqQHLds4Ar0D30DvVFFH6VZi-5OGS9
export default function DownloadInfo() {
	const [downloadInfoRecords, setDownloadInfoRecords] = useState([]);
	const [downloadInfoSelected, setDownloadInfoSelected] = useState(null);
    const [showState, setShowState] = useState({open:OpenWhich.Nothing});
    const [dataTypes, setDataTypes] = useState(DefaultDataTypes);
    const [cvoFiles, setCvoFiles] = useState([]);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
	const sessionInfo = useReactiveVar(userInfoRepo);

	const isStaff = ValueUtil.isEtrace(sessionInfo);

    // ##### Call GraphQL to get List #####
    const [getDownloadInfoList, responseList] = useLazyQuery(LIST_DOWNLOAD_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getDownloadInfo, responseItem] = useLazyQuery(GET_DOWNLOAD_INFO, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation.  ###
    const [addDownloadInfo, responseAdd] = useMutation( ADD_DOWNLOAD_INFO, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editDownloadInfo, responseEdit] = useMutation( EDIT_DOWNLOAD_INFO, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeDownloadInfo, responseRemove] = useMutation( REMOVE_DOWNLOAD_INFO, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getDownloadInfoList({variables: {forWhom: 'all'}});
    }, []);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.downloadInfoList) {
            const typesMap = {};
            const types = [...DefaultDataTypes];
            for(const di of data.downloadInfoList) {
                if(!DefaultDataTypes.includes(di.dataType) && !typesMap[di.dataType]) {
                    typesMap[di.dataType] = 1;
                    types.push(di.dataType);
                }
            }
            setDownloadInfoRecords(data.downloadInfoList);
            setDataTypes(types);
        }
        if(data.cvoFileList) setCvoFiles(data.cvoFileList);
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.downloadInfoAdd.ok) {
            // setEditorState(null);
            if(data.downloadInfoAdd.list) {
                // Backend에서는 생성된 데이터 하나만 목록에 넣어서 반환함. 이 데이터를 View 데이터로 넣어서 확인하고 파일을 첨부할 수 있게 한다.
                if(data.downloadInfoAdd.list.length === 1) {
                    setShowState({open:OpenWhich.View, item: {...data.downloadInfoAdd.list[0]}});
                    getDownloadInfoList({variables: {forWhom: 'all'}});
                }
                else if(data.downloadInfoAdd.list.length > 1) setDownloadInfoRecords(data.downloadInfoAdd.list); // 예비. Not Likely
            }
            // getDownloadInfoList(); // setDownloadInfoRecords(data.downloadInfoAdd.list);
        }
        else setResponseAlert({open:true, resultData: data.downloadInfoAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.downloadInfoEdit.ok) {
            setShowState(null);
            setDownloadInfoRecords(data.downloadInfoEdit.list);
        }
        else setResponseAlert({open:true, resultData: data.downloadInfoEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.downloadInfoRemove.ok) setDownloadInfoRecords(data.downloadInfoRemove.list);
        else setResponseAlert({open:true, resultData: data.downloadInfoRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.downloadInfoItem) {
            const item = {...data.downloadInfoItem};
            // for(const field of EditFields) item[field] = data.downloadInfoItem[field];
            const ss = {...showState}
            ss.item = item;
            if(!ss.open) ss.open = OpenWhich.View;
            setShowState(ss);
        }
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(!isStaff) return;
        if(responseAdd) responseAdd.reset();
        setShowState({item: undefined, open: OpenWhich.Create}); // input component will set a default data.
    };

    const onRequestEdit = (data) => {
        if(!isStaff) return;
        if(responseEdit) responseEdit.reset();
        setShowState({open: OpenWhich.Edit});
        getDownloadInfo({variables: {msgSeq: data.msgSeq}});
        // setEditorState({item: data});
    };

    const onRequestView = (data) => {
        setShowState({open: OpenWhich.View});
        getDownloadInfo({variables: {msgSeq: data.msgSeq}});
    };

    const onRequestRemove = (item) => {
        if(!isStaff) return;
        setPromptToConfirm({
            data: item,
            title: '다운로드 정보 삭제',
            messages: [
                item.title + ' 다운로드 정보를 삭제하시겠습니까?',
                '해당 정보 및 부수하는 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{downloadInfo:{msgSeq: data.msgSeq}}};
                    removeDownloadInfo(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by DownloadInfoInput component.
    const onClickMutate = (item, isEdit) => {
        if(!isStaff) return;
        // item.custId = ''; // testing error callback.
        const param = {variables: {downloadInfo: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editDownloadInfo(param);
        else addDownloadInfo(param);
    };

    const onCloseViewer = () => {
        const ss = {...showState};
        ss.open = OpenWhich.Nothing;
        setShowState(ss);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList,
        responseAdd,
        responseEdit,
        responseItem,
        responseRemove
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderItemViewer = () => {
        let openWhich = OpenWhich.Nothing;
        let item = {};
        if(Boolean(showState)) {
            if(showState.item && (showState.open === OpenWhich.Edit || showState.open === OpenWhich.View)) {
                item = showState.item;
                openWhich = showState.open;
            }
            else if(showState.open === OpenWhich.Create) openWhich = showState.open;
        }

        if(openWhich===OpenWhich.Create) {
            // item property는 할당하지 않는다.
            return (
                <DownloadInfoInput
                    open={openWhich===OpenWhich.Create}
                    isEdit={false}
                    dataTypes={dataTypes}
                    cvoFiles={cvoFiles}
                    responseSaving={responseAdd}
                    onClickMutate={onClickMutate}
                    onClose={onCloseViewer}
                />
            );
        }
        else if(openWhich===OpenWhich.Edit) {
            return(
                <DownloadInfoInput
                    open={openWhich===OpenWhich.Edit}
                    isEdit={true}
                    item={item}
                    dataTypes={dataTypes}
                    cvoFiles={cvoFiles}
                    responseSaving={responseEdit}
                    onClickMutate={onClickMutate}
                    onClose={onCloseViewer}
                />
            );
        }
        else {
            return(
                <DownloadInfoItem
                    open={openWhich===OpenWhich.View}
                    item={item}
                    cvoFiles={cvoFiles}
                    onClose={onCloseViewer}
                />
            );
        }
    };

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <DownloadInfoContainer>
            <DownloadInfoList
                records={downloadInfoRecords}
				selected={downloadInfoSelected}
				onClickItem={setDownloadInfoSelected}
                onRequestAdd={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
                onRequestView={onRequestView}
            />
            {renderItemViewer()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </DownloadInfoContainer>
    )
}
