// terms_stat/TermsStatGql.js
import { gql } from "@apollo/client";


export const LIST_TERMS_STAT_ALL = gql`
query ListTermsStat {
    termsStatList {
        custId
        custName
        normalTerm
        normalOff
        closedTerm
        stoppedTerm
        waitTerm
        usingTerm
        notUsing
        workingTerm
        allTerms
        regTime
    }
    termsStatTotal {
        normalTerm
        normalOff
        closedTerm
        stoppedTerm
        waitTerm
        usingTerm
        notUsing
        workingTerm
        allTerms
    }
}
`;
/* 
export const GET_TERMS_STAT_SUMMARY = gql`
query ListTermsStat {
    termsStatTotal {
        normalTerm
        normalOff
        closedTerm
        stoppedTerm
        waitTerm
        usingTerm
        notUsing
        workingTerm
        allTerms
    }
}
`;
 */