// term_change_log/TermChangeLogGql.js
import { gql } from "@apollo/client";


export const LIST_TERM_CHANGE_LOG = gql`
query ListTermChangeLog($termId:String) {
    termChangeLogList(termId:$termId) {
        termId
        changeTime
        changeReason
        regUserId
        regUserName
        dataKey
    }
}
`;


export const LIST_TERM_STATE_LOG = gql`
query ListTermStateLog($devId:Int) {
    termStateLogList(devId:$devId) {
        devId
        changeTime
        termId
        termStateCd
        termStateValue
        isLatest
        regUserId
        regUserName
        dataKey
    }
}
`;
