// NotifyBoard.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import NotifyBoardList from './NotifyBoardList';
import NotifyBoardInput from './NotifyBoardInput';
import NotifyBoardItem from './NotifyBoardItem';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    LIST_NOTIFY_BOARD, GET_NOTIFY_BOARD, ADD_NOTIFY_BOARD, EDIT_NOTIFY_BOARD, REMOVE_NOTIFY_BOARD,
	NotifyBoardDictionary
} from './NotifyBoardGql';
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;
const NotifyBoardContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});

const OpenWhich = AppObject.OpenWhich;
const ErrorTitle =NotifyBoardDictionary.errorTitle;

export default function NotifyBoard() {
	const [notifyBoardRecords, setNotifyBoardRecords] = useState([]);
	const [notifyBoardSelected, setNotifyBoardSelected] = useState(null);
    const [showState, setShowState] = useState({open:OpenWhich.Nothing});
    // const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
	const sessionInfo = useReactiveVar(userInfoRepo);

	const isStaff = sessionInfo.signedIn ? ValueUtil.isEtrace(sessionInfo) : false;

    // ##### Call GraphQL to get List #####
    const [getNotifyBoardList, responseList] = useLazyQuery(LIST_NOTIFY_BOARD, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getNotifyBoardItem, responseItemToEdit] = useLazyQuery(GET_NOTIFY_BOARD, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation.  ###
    const [addNotifyBoard, responseAdd] = useMutation( ADD_NOTIFY_BOARD, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editNotifyBoard, responseEdit] = useMutation( EDIT_NOTIFY_BOARD, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeNotifyBoard, responseRemove] = useMutation( REMOVE_NOTIFY_BOARD, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getNotifyBoardList();
    }, []);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.notifyBoardList) setNotifyBoardRecords(data.notifyBoardList);
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.notifyBoardAdd.ok) {
            setShowState(null);
            // setEditorState(null);
            if(data.notifyBoardAdd.list.length>0) {
                const records = [...notifyBoardRecords];
                records.unshift(data.notifyBoardAdd.list[0]);
                setNotifyBoardRecords(records);
            }
        }
        else setResponseAlert({open:true, resultData: data.notifyBoardAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.notifyBoardEdit.ok) {
            setShowState(null);
            // setEditorState(null);
            setNotifyBoardRecords(data.notifyBoardEdit.list);
        }
        else setResponseAlert({open:true, resultData: data.notifyBoardEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.notifyBoardRemove.ok) setNotifyBoardRecords(data.notifyBoardRemove.list);
        else setResponseAlert({open:true, resultData: data.notifyBoardRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.notifyBoardItem) {
            const item = {...data.notifyBoardItem};
            // for(const field of EditFields) item[field] = data.notifyBoardItem[field];
            const ss = {...showState}
            ss.item = item;
            if(!ss.open) ss.open = OpenWhich.View;
            setShowState(ss);
            // setEditorState({item: item});
        }
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(!isStaff) return;
        if(responseAdd) responseAdd.reset();
        setShowState({item: undefined, open: OpenWhich.Create});
        // setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestEdit = (data) => {
        if(!isStaff) return;
        if(responseEdit) responseEdit.reset();
        setShowState({open: OpenWhich.Edit});
        getNotifyBoardItem({variables: {msgSeq: data.msgSeq}});
        // setEditorState({item: data});
    };

    const onRequestView = (data) => {
        setShowState({open: OpenWhich.View});
        getNotifyBoardItem({variables: {msgSeq: data.msgSeq}});
    };

    const onRequestRemove = (item) => {
        setPromptToConfirm({
            data: item,
            title: '공지사항 삭제',
            messages: [
                '이 공지사항을 삭제하시겠습니까?',
                '해당 정보 및 부수하는 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{notifyBoard:{msgSeq: data.msgSeq}}};
                    removeNotifyBoard(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by NotifyBoardInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {notifyBoard: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editNotifyBoard(param);
        else addNotifyBoard(param);
    };

    const onCloseViewer = () => {
        const ss = {...showState};
        ss.open = OpenWhich.Nothing;
        setShowState(ss);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList,
        responseItemToEdit,
        responseAdd,
        responseEdit,
        responseRemove
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderItemViewer = () => {
        let openWhich = OpenWhich.Nothing;
        let item = {};
        if(Boolean(showState)) {
            if(showState.item && (showState.open === OpenWhich.Edit || showState.open === OpenWhich.View)) {
                item = showState.item;
                openWhich = showState.open;
            }
            else if(showState.open === OpenWhich.Create) openWhich = showState.open;
        }

        if(openWhich===OpenWhich.Create) {
            return(
                <NotifyBoardInput
                    open={openWhich===OpenWhich.Create}
                    isEdit={false}
                    responseSaving={responseAdd}
                    onClickMutate={onClickMutate}
                    onClose={onCloseViewer}
                />
            );
        }
        else if(openWhich===OpenWhich.Edit) {
            return(
                <NotifyBoardInput
                    open={openWhich===OpenWhich.Edit}
                    isEdit={true}
                    item={item}
                    responseSaving={responseEdit}
                    onClickMutate={onClickMutate}
                    onClose={onCloseViewer}
                />
            );
        }
        else
            return(
                <NotifyBoardItem
                    open={openWhich===OpenWhich.View}
                    item={item}
                    onClose={onCloseViewer}
                />
            );
    };

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <NotifyBoardContainer>
            <NotifyBoardList
                records={notifyBoardRecords}
				selected={notifyBoardSelected}
				onClickItem={setNotifyBoardSelected}
                onRequestAdd={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
                onRequestView={onRequestView}
                hideFirstTopButton={!sessionInfo.signedIn}
            />
            {renderItemViewer()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </NotifyBoardContainer>
    )
}
