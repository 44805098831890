// address_info/AddressInfoGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const AddressInfoDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        addressId : {toAdd: false, toEdit: true},
        addrGroupId : {
            id: 'addrGroupId', label: '주소그룹', type: 'select', required: true,
            toAdd: true, toEdit: true
        },
        fullname : {
            id: 'fullname', label: '이름', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.textInRange(value,1,45)},
        },
        company : {
            id: 'company', label: '회사', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '20자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,80)},
        },
        department : {
            id: 'department', label: '부서', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '12자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },
        workRank : {
            id: 'workRank', label: '직위', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '6자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,20)},
        },
        job : {
            id: 'job', label: '업무', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '10자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,30)},
        },
        zipCode : {
            id: 'zipCode', label: '우편번호', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '7자 이내 입력',
            check: (value) => {return ValueUtil.isNullString(value) || /^[-0-9]{5,7}$/.test(value)},
        },
        address1 : {
            id: 'address1', label: '주소', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '비워두거나 한글 30자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,100)},
        },
        address2 : {
            id: 'address2', label: '주소2', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '비워두거나 20자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,80)},
        },
        hpNo : {
            id: 'hpNo', label: '휴대전화', type: 'tel', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value);}
        },
        telNo : {
            id: 'telNo', label: '일반전화', type: 'tel', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value);}
        },
        email : {
            id: 'email', label: '메일주소', type: 'email', required: false,
            toAdd: true, toEdit: true,
            help: '60자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || (ValueUtil.goodEmail(value) && value.length <= 60 );},
        },
        etc : {
            id: 'etc', label: '비고', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '1000자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,1000)},
        },
        /*senderYn : {
            id: 'senderYn', label: 'senderYn label', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '1자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,1)},
        },*/
    },
    errorTitle: {
        Add: '주소정보 생성 오류',
        Edit: '주소정보 수정 오류',
        Remove: '주소정보 삭제 오류',
        Get: '주소정보 조회 오류',
        List: '주소목록 조회 오류',
    }
};

const ADDRESS_INFO_LIST_PART = gql`
fragment AddressInfoListPart on AddressInfo {
    custId
    addressId
    addrGroupId
    addrGroupName
    fullname
    company
    department
    workRank
    job
    zipCode
    address1
    address2
    hpNo
    telNo
    email
    senderYn
    etc
    regUserId
    regDate
}
`;


export const LIST_ADDRESS_INFO = gql`
${ADDRESS_INFO_LIST_PART}
query ListAddressInfo {
    addressInfoList {
        ...AddressInfoListPart
    }
}
`;

export const GET_ADDRESS_INFO = gql`
${ADDRESS_INFO_LIST_PART}
query GetAddressInfo($addressId:Int) {
    addressInfoItem(addressId:$addressId) {
        ...AddressInfoListPart
    }
}
`;

export const ADD_ADDRESS_INFO = gql`
mutation AddAddressInfo($addressInfo:InputAddressInfoAdd!) {
  addressInfoAdd(addressInfo: $addressInfo) {
    ok
    message
  }
}
`;

export const EDIT_ADDRESS_INFO = gql`
mutation EditAddressInfo($addressInfo:InputAddressInfoEdit!) {
  addressInfoEdit(addressInfo: $addressInfo) {
    ok
    message
  }
}
`;

export const REMOVE_ADDRESS_INFO = gql`
mutation RemoveAddressInfo($addressInfo:InputAddressInfoRemove!) {
  addressInfoRemove(addressInfo: $addressInfo) {
    ok
    message
  }
}
`;
