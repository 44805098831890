// TermMngList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import {userInfoRepo, NoUser} from '../../model/CvoModel';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { TermMngDictionary } from './TermMngGql';

const TermMngListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const Dict = TermMngDictionary.inputMap;
const TableColumns = [
    { id: 'termId', label: Dict.termId.label, width: 110, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'salesStaffName', label: Dict.salesStaffName.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'serialNo', label: Dict.serialNo.label, width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'usimNum', label: Dict.usimNum.label, width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'acNetworkValue', label: Dict.acNetworkCd.label, width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'acTermStateValue', label: Dict.acTermStateCd.label, width: 70, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'acOpenForValue', label: Dict.acOpenForCd.label, width: 70, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'groupMemo', label: Dict.groupMemo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'openDate', label: Dict.openDate.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.DATE} },
    { id: 'custName', label: Dict.custName.label, width: 200, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'acFirmTypeValue', label: Dict.acFirmTypeCd.label, width: 70, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'openerName', label: Dict.openerName.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custCorpNo', label: Dict.custCorpNo.label, width: 160, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'compBizno', label: Dict.compBizno.label, width: 110, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'acModelValue', label: Dict.acModelCd.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'acBillTypeValue', label: Dict.acBillTypeCd.label, width: 70, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'salePrice', label: Dict.salePrice.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'acContrMonthValue', label: Dict.acContrMonthCd.label, width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'acContrAmountValue', label: Dict.acContrAmountCd.label, width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'supportAmount', label: Dict.supportAmount.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'representitive', label: Dict.representitive.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'thermComp', label: Dict.thermComp.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'memoInstall', label: Dict.memoInstall.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'generalMemo', label: Dict.generalMemo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'abandonDate', label: Dict.abandonDate.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.DATE} },
    // { id: 'regUserId', label: Dict.regUserId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regDate', label: '등록일', width: 100, show: true, option:{align: 'center', type: TableColumnType.DATETIME} }
	// { id: 'termMngSeq', label: Dict.termMngSeq.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    // { id: 'custId', label: Dict.custId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function TermMngList({
	records,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
    search,
    filterData
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);

    /* 
    const DefaultFilter = {
        openDate: '',
        abandonDate: '',
        acNetworkCd: NotSelectedValue.value,
        acTermStateCd: NotSelectedValue.value,
        acOpenForCd: NotSelectedValue.value,
        acFirmTypeCd: NotSelectedValue.value,
        acModelCd: NotSelectedValue.value,
        acBillTypeCd: NotSelectedValue.value,
        acContrMonthCd: NotSelectedValue.value,
        acContrAmountCd: NotSelectedValue.value,
    }; */
    const filterItem = (row) => {
        let filterCount = 0;
        let matchCount = 0;
        if(ValueUtil.realLen(filterData.openDate)>0) {
            filterCount++;
            if(row.openDate && row.openDate.indexOf(filterData.openDate)===0) matchCount++;
            else return false;
        }
        if(ValueUtil.realLen(filterData.abandonDate)>0) {
            filterCount++;
            if(row.abandonDate && row.abandonDate.indexOf(filterData.abandonDate)===0) matchCount++;
            else return false;
        }
        for(const cd of Object.keys(filterData)) {
            if(cd.indexOf('ac')===0) {
                const filterValue = filterData[cd];
                const value = row[cd];
                if(filterValue !== AppWord.NO_FILTER) {
                    filterCount++;
                    if(value===filterValue) matchCount++;
                    else return false;
                }
            }
        }

        return filterCount===matchCount;
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            default: return;
        }
    };

	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);
    if(!isAdmin) return <Box>***</Box>;

    return(
        <TermMngListContainer>
            <FlexyTable
                name="term_mng_list"
                uniqueKey="termMngSeq"
                columns={TableColumns}
                records={records}
                filterFunction={filterItem}
                search={search}
                tools={[
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} />}
                ]}
                onClickTool={onClickToolOnRow}
            />
        </TermMngListContainer>
    );
}



