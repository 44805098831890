// TermStockList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
//import useClientSize from '../hook/useClientSize';
import { Button, IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo} from '../../model/CvoModel';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { TermStockDictionary } from './TermStockGql';

const TermStockListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
	display:'flex', alignItems:'center', height:AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const HeaderControl = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});
const ButtonBox = styled(Box)({display:'flex', alignItems:'center'});

const Dict = TermStockDictionary.inputMap;
const TableColumns = [
	{ id: 'ioDate', label: Dict.ioDate.label, width: 120, show: true, option:{align: 'center', type: TableColumnType.DATE} },
    { id: 'model', label: Dict.modelId.label, width: 200, show: true, option:{align: 'left', type: TableColumnType.TEXT} },
    { id: 'inCount', label: Dict.inCount.label, width: 70, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'outCount', label: Dict.outCount.label, width: 70, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'stockNow', label: '재고', width: 70, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'regUserName', label: '등록자', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regDate', label: '등록일', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'ioEtc', label: Dict.ioEtc.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} }
];

export default function TermStockList({
    dateFrom,
    dateTill,
	records,
	selected,
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
    onRequestList,
    onChangeDuration,
}) {
    // const [duration, setDateRange] = useState({dateFrom: ValueUtil.getYmdText(86400*31), dateTill: ValueUtil.getYmdText(0)});
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [search, setSearch] = useState('');
    //const clientSize = useClientSize();


    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
		if(onClickItem) onClickItem(item);
    };

    const onChangeDate = (event) => {
        const range = {dateFrom: dateFrom, dateTill: dateTill};
        range[event.target.id] = event.target.value;
        onChangeDuration(range);
    };

    const onClickGetList = () => {
        onRequestList();
    };

	const isAdmin = ValueUtil.isEtrace(sessionInfo);
    if(!isAdmin) return <Box>***</Box>

    return(
        <TermStockListContainer>
            <HeaderBox>
				<HeaderControl>
                    <span>조회날짜범위 &nbsp;</span>
                    <TextField id='dateFrom' type='date' value={dateFrom} size={AppWord.SMALL} onChange={onChangeDate} />
                    ~
                    <TextField id='dateTill' type='date' value={dateTill} size={AppWord.SMALL} onChange={onChangeDate} />
                    &nbsp;
                    <Button size={AppWord.SMALL}
                        onClick={onClickGetList}
                        variant='contained' color="primary"
                        disabled={!Boolean(dateFrom) || !Boolean(dateTill)}
                    >
                        조회
                    </Button>
                    &nbsp;&nbsp;
					<FormControl variant='outlined' size={AppWord.SMALL}>
						<InputLabel htmlFor="search-TermStock-list" style={{backgroundColor:'white'}}>검색</InputLabel>
						<OutlinedInput id="search-TermStock-list" onChange={onChangeSearch}
							value={search}
							disabled={records.length === 0}
							size={AppWord.SMALL}
							endAdornment={
								Boolean(search) ?
								<InputAdornment position={AppWord.END}>
									<IconButton
									onClick={()=>{setSearch('')}}
									size={AppWord.SMALL}
									edge={AppWord.END}
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
								:
								null
							}
						/>
					</FormControl>
				</HeaderControl>
				<ButtonBox>
                    <Button
                        onClick={onRequestAdd}
                        variant='contained' color='primary'>
                        <AddIcon fontSize={AppWord.SMALL} />
                        재고정보 추가
                    </Button>
				</ButtonBox>
            </HeaderBox>
            <FlexyTable
                name="term_stock_list"
                uniqueKey="dataKey"
                columns={TableColumns}
                records={records}
                search={search}
                tools={isAdmin ? [
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} />}
                ] : []}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </TermStockListContainer>
    );

}