// AlertDialog.js
import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const IdAppAlertDialogTitle = 'id-app-alert-dialog-title-etrace-23048';


export default function AlertDialog({
    open,
    data,
    onClose,
}) {
    let lines = [];
    if(data.messages) {
        for(const text of data.messages) {
            if(text) {
                const t = text.split("\n").filter((one) => {return one.trim().length > 0});
                lines = lines.concat(t);
            }
        }
    }

    return (
        <Dialog
            open={open}
            onClose={()=>onClose()}
            aria-labelledby={IdAppAlertDialogTitle}>
            <DialogTitle id={IdAppAlertDialogTitle}>
                {data.title}
            </DialogTitle>
            <DialogContent>
                {
                    data.message ? <p>{data.message}</p> : null
                }
                {
                    lines.length > 0
                    ?
                    <ul>
                    {
                        lines.map((msg,idx)=>{
                            return (<li key={idx}>{msg}</li>);
                        })
                    }
                    </ul>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>onClose()} autoFocus
                    startIcon={<CheckIcon />}>
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    )
}
