// VehGroupBcdMapList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo} from '../../model/CvoModel';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { FlexySize } from '../common/flexytable/FlexyTableStyled';

const VehGroupBcdMapListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});

const TitleBox = styled('span')({fontWeight:'bold', marginRight:10, fontSize:'1.05rem'});

const LabelBox = styled(Box)({display:'flex', alignItems:'center', height:'100%'});
const PoiGroupRenderStyle = {height:FlexySize.RowHeight-1, paddingLeft:5};
const ColorSelected = '#8ef';

export default function VehGroupBcdMapList({
    title,
	maxHeight,
	maxWidth,
	selected,
    onClickItem,
    onRequestEdit,
    onRequestRemove,
    mapList,
    poiSelected,
    groupSelected,
    criteria
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [search, setSearch] = useState('');
    //const [itemSelected, setItemSelected] = useState(selected);
    const clientSize = useClientSize();

    const RenderPoiName = (rec, value) => {
        const style = {...PoiGroupRenderStyle};
        if(poiSelected && poiSelected.poiId === rec.poiId) style.backgroundColor = ColorSelected;
        return(
            <LabelBox style={style}>{value}</LabelBox>
        );
    };

    const RenderGroupName = (rec, value) => {
        const style = {...PoiGroupRenderStyle};
        if(groupSelected && groupSelected.groupId === rec.groupId) style.backgroundColor = ColorSelected;
        return(
            <LabelBox style={style}>{value}</LabelBox>
        );
    };

    const TableColumns = [
        { id: 'poiName', label: '지점', width: 120, show: true, option:{align: 'inherit', print: (r,i,v)=>{return RenderPoiName(r,v)}} },
        { id: 'groupName', label: '차량그룹', width: 100, show: true, option:{align: 'inherit', print: (r,i,v)=>{return RenderGroupName(r,v)}} },
        // { id: 'bcdBorderValue', label: '지역유형', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
        { id: 'bcdEventValue', label: '감지내용', width: 120, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
        // { id: 'borderDesc', label: Dict.borderDesc.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    ];

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
        //setItemSelected(item);
		if(onClickItem) onClickItem(item);
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - AppNumber.dataContainerPadding * 2;
	
    const TableHeight = height - clientSize.HeaderToolBoxHeight
        - clientSize.SmallBoxPadding * 2 - 2;

	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    return(
        <VehGroupBcdMapListContainer>
            <HeaderBox>
                {
                    title ? <TitleBox>{title}</TitleBox> : null
                }
				<FormControl variant='outlined' size={AppWord.SMALL}>
					<InputLabel htmlFor="search-VehGroupBcdMap-list" style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id="search-VehGroupBcdMap-list" onChange={onChangeSearch}
						value={search}
						disabled={mapList.length === 0}
						size={AppWord.SMALL}
                        style={{width:120}}
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
            </HeaderBox>
            <FlexyTable
                name="veh_group_bcd_map_list"
                uniqueKey="dataKey"
                columns={TableColumns}
                records={mapList}
                search={search}
                criteria={criteria}
                tools={isAdmin ? [
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize='small' color="primary" />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize='small' color='warning'/>}
                ] : []}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </VehGroupBcdMapListContainer>
    );

}