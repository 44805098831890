// VehInfo.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import VehInfoList from './VehInfoList';
import VehInfoInput from './VehInfoInput';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_VEH_INFO, GET_VEH_INFO, ADD_VEH_INFO, EDIT_VEH_INFO, REMOVE_VEH_INFO,
    VEH_INFO_TERM_OFF, VEH_INFO_SET_USAGE,
	VehInfoDictionary
} from './VehInfoGql';
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;
const VehInfoContainer = styled(Box)({ position:"absolute", top:dcPad, bottom:dcPad, left:dcPad, right:dcPad, display:"flex"});

const ErrorTitle =VehInfoDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(VehInfoDictionary.inputMap, true);

// TODO : 예전에는 일괄 등록이 있음. 새로 구현해야 함. MapPoiInfoBatch, PoiInfoGql.js 참조.


export default function VehInfo({
    maxWidth,
    maxHeight
}) {
	const [vehInfoSelected, setVehInfoSelected] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getVehInfoList, responseList] = useLazyQuery(LIST_VEH_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {
            setResponseAlert({open:true, error: error, title: "차량목록 가져오기 실패."})
        }
    });
    const [getVehInfoItemToEdit, responseItemToEdit] = useLazyQuery(GET_VEH_INFO, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation.
    const [addVehInfo, responseAdd] = useMutation( ADD_VEH_INFO, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editVehInfo, responseEdit] = useMutation( EDIT_VEH_INFO, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeVehInfo, responseRemove] = useMutation( REMOVE_VEH_INFO, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});
    const [askTermOff, responseTermOff] = useMutation( VEH_INFO_TERM_OFF, {
        onCompleted: (data, option) => onCompleateTermOff(data, option),
        onError: (error) => {setResponseAlert({open:true, error: error, title: '단말기 떼어내기 오류'})}
    });
    const [setUsage, responseSetUsage] = useMutation( VEH_INFO_SET_USAGE, {
        onCompleted: (data, option) => onCompleteSetUsage(data, option),
        onError: (error) => {setResponseAlert({open:true, error: error, title: '차량 사용여부 변경 오류'})}
    });


    useEffect(() => {
        getVehInfoList();
    }, [getVehInfoList]);


    const getVehInfoListOfCust = () => {
        getVehInfoList();
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(vehInfoSelected && data.vehInfoList) {
            for(const item of data.custInfoList) {
                if(item.vehId===vehInfoSelected.vehId) {
                    setVehInfoSelected(item);
                    return;
                }
            }
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.vehInfoAdd.ok) {
            // setEditorState(null);
            getVehInfoList(); // getVehInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.vehInfoAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.vehInfoEdit.ok) {
            setEditorState(null);
            getVehInfoList(); // getVehInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.vehInfoEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.vehInfoRemove.ok) getVehInfoListOfCust();
        else setResponseAlert({open:true, resultData: data.vehInfoRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.vehInfoItem) {
            const item = {};
            for(const field of EditFields) item[field] = data.vehInfoItem[field];
            if(data.vehInfoItem.termId) item.termId = data.vehInfoItem.termId; // for slecting from TERM_INFO list.
            setEditorState({item: item});
        }
    };

    const onCompleateTermOff = (data, option) => {
        if(data.vehInfoTermOff.ok) {
            setEditorState(null);
            getVehInfoList();
        }
        else setResponseAlert({open:true, resultData: data.vehInfoTermOff, title: '단말기 떼어내기 오류'});
    };

    const onCompleteSetUsage = (data, option) => {
        if(data.vehInfoSetUsage.ok) {
            getVehInfoList();
        }
        else setResponseAlert({open:true, resultData: data.vehInfoSetUsage, title: '차량 사용여부 변경 오류'});
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestEdit = (item) => {
        if(responseEdit) responseEdit.reset();
        getVehInfoItemToEdit({variables: {vehId: item.vehId}});
        // setEditorState({item: item});
    };

    const onRequestRemove = (item) => {
        // test O.K.
        setPromptToConfirm({
            data: item,
            title: '차량정보 삭제',
            messages: [
                '선택한 ' + item.vehPlates + ' 차량을 삭제하시겠습니까?',
                '해당 차량 및 부수정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{vehInfo:{vehId: data.vehId}}};
                    removeVehInfo(param);
                }
            }
        });
    };

    const onRequestTermOff = (item) => {
        console.log('onRequestTermOff:', item);
        setEditorState(null);
        askTermOff({variables: {vehInfo: {vehId: item.vehId}}});
    };

    const onRequestToggleUseage = (item) => {
        console.log('onRequestToggleUseage:', item);
        setUsage({variables: {vehInfo:item}});
    };

    // Handler - Submit for mutation fired by VehInfoInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {vehInfo: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editVehInfo(param);
        else addVehInfo(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };

    if(ValueUtil.hasAnyAuthError(
        responseAdd, responseEdit, responseRemove, responseList, responseItemToEdit, responseTermOff, responseSetUsage
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <VehInfoInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                onRequestTermOff={onRequestTermOff}
                />
            :
            <VehInfoInput
                open={Boolean(editorState)}
                isEdit={false}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth;

    return (
        <VehInfoContainer>
            <VehInfoList
				maxHeight={height}
				maxWidth={width}
                responseList={responseList}
				selected={vehInfoSelected}
				onClickItem={setVehInfoSelected}
                onRequestAdd={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
                onRequestToggleUseage={onRequestToggleUseage}
            />
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehInfoContainer>
    )
}
