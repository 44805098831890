// MapVehRoute.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button, IconButton, Popover } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CellTowerIcon from '@mui/icons-material/CellTower'; // cell position
import GpsFixedIcon from '@mui/icons-material/GpsFixed'; // speed < 2
import NavigationIcon from '@mui/icons-material/Navigation'; // speed >= 2
import ResponseAlert from "../message/ResponseAlert";
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType, AppPalette } from '../../model/AppConst';
import { useLazyQuery } from '@apollo/client';
import { LIST_ROUTE_FOR_MAP } from '../veh_route/VehRouteGql';
import DurationPicker from '../common/DurationPicker';
import dayjs from 'dayjs';

// const dcPad = AppNumber.dataContainerPadding;
const VehRouteListContainer = styled(Box)({
    // position:"absolute", top:0, right:0, bottom: 0, left: dcPad,
    flexGrow:1,
	display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display:'flex', // height: AppNumber.HeaderToolBoxHeight,
    alignItems: 'center', padding: AppNumber.SmallBoxPadding,
    // '&>*': {verticalAlign: 'middle'}
});

const TableBox = styled(Box)({flexGrow:1, display:'flex'});

const DurationView = styled(Box)({
    display:'inline-block',fontFamily: 'Consolas, Monaco, Courier, monospace', fontSize:'0.9em'
});

const PickerBox = styled(Box)({
    display:'flex', alignItems:'center', padding:10
});

const StateColumn = {
    id:'-mv-mark', label:'상태', width:50, show: true,
    option: {
        aligh:'center', type: TableColumnType.GRAPH,
        form: (rec, rowIndx) => {
            if(rec.posSys==='C' || rec.posSys===null) return <CellTowerIcon fontSize={AppWord.SMALL} color='disabled' />
            if(rec.speed < 2) return <GpsFixedIcon fontSize={AppWord.SMALL} color='success'/>;
            return <NavigationIcon fontSize={AppWord.SMALL} color='primary' style={{transform: 'rotate('+rec.direction+'deg)'}}/>;
        }
    }
};
// TODO: 도어 개폐 값 출력.
const TableColumns = [
    { id: 'gpsTime', label: '최종보고', width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    StateColumn,
    { id: 'location', label: '위치', width: 400, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'speed', label: '속도(Km/h)', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'distKm', label: '운행거리(Km)', width: 100, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'pwrTypeValue', label: '전원타입', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'posSysValue', label: '위치타입', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'irActionName', label: '업무상태', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'tempr1', label: '전면온도', width: 70, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr3', label: '중면온도', width: 70, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr2', label: '후면온도', width: 70, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'poiName', label: '출발/도착지', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'bcdEventValue', label: '출발/도착', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'roadName', label: '도로', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'roadMaxSpeed', label: '허용속도', width: 80, show: true, option:{align: 'center', type: TableColumnType.INTEGER} },
];

const EmptyDuration = {from:null, till:null};

// 일반 업체용. 청소업체 경로는 별도. 왜? 청소업체는 지정 날짜 하루치를 무조건 가져와야 하므로.
export default function MapVehRoute({
    mapShell,
    vehPos,
}) {
    const [route, setRoute] = useState([]);
    const [duration, setDuration] = useState(EmptyDuration);
    const [selectorAnchor, setSelectorAnchor] = useState(null);
    const [itemSelected, setItemSelected] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);

    // ##### Call GraphQL to get List #####
    const [getVehRouteList, responseList] = useLazyQuery(LIST_ROUTE_FOR_MAP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "경로조회 오류"})}
    });

    useEffect(()=>{
        setRoute([]);
        if(vehPos) {
            if(vehPos.gpsTime) {
                if(vehPos.gpsTime === AppWord.NOT_REAL_GPS_TIME) setDuration(EmptyDuration);
                else {
                    const djs = dayjs(vehPos.gpsTime);
                    const date = djs.toDate();
                    const from = ValueUtil.getYmdHmsInputText(date, 5*3600);
                    setDuration({from:from, till: vehPos.gpsTime});
                }
            }
            else setDuration(EmptyDuration);
        }
        else setDuration(EmptyDuration);
    }, [vehPos]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        let gpsTimeSel = itemSelected ? itemSelected.gpsTime : undefined;

        if(data.vehRouteList) {
            const newRoute = [];
            const staySpot = {};

            let lastArr = '1000-', lastDep = '1000-', lastStay=0, lastBetween=0, lastTime=null;
            let sdist = 0;
            let preDist = 0;
            let i = 0;

            for(const p of data.vehRouteList) {
                if(p.distKm > preDist) {
                    if(i>0) {
                        sdist += (p.distKm - preDist);
                    }
                    preDist = p.distKm;
                }
                p.sessionDist = sdist;
                if(p.distKm > 0) i++;

                // 동정정보 반영.
                if(p.timeArrival && p.timeDeparture) {
                    lastArr = p.timeArrival;
                    lastDep = p.timeDeparture;
                    lastStay = p.staySec;
                    lastBetween = p.distBetween;
                    lastTime = p.gpsTime;
                    staySpot[lastTime] = {east:p.lon+0.0000001, west:p.lon-0.0000001, north:p.lat+0.0000001, south:p.lat-0.0000001};
                }
                else {
                    if(lastArr < p.gpsTime && lastDep > p.gpsTime && lastTime) {
                        p.timeArrival = lastArr;
                        p.timeDeparture = lastDep;
                        p.staySec = lastStay;
                        p.distBetween = lastBetween;
                        if(staySpot[lastTime].east < p.lon) staySpot[lastTime].east = p.lon;
                        if(staySpot[lastTime].west > p.lon) staySpot[lastTime].west = p.lon;
                        if(staySpot[lastTime].north < p.lat) staySpot[lastTime].north = p.lat;
                        if(staySpot[lastTime].south > p.lat) staySpot[lastTime].south = p.lat;
                    }
                    else lastTime = null;
                }

                newRoute.push(p);

                if(gpsTimeSel) {
                    setItemSelected(p);
                    gpsTimeSel = undefined;
                }
            }

            for(let i=0;i<newRoute.length;i++) {
                if(staySpot[newRoute[i].gpsTime]) {
                    newRoute[i].stayBox = staySpot[newRoute[i].gpsTime];
                }
            }

            setRoute(newRoute);
            if(newRoute.length>0) {
                mapShell.setVehicleRoute(vehPos, newRoute);
                mapShell.showRouteMarkers();
            }
        }
    };

    const onClickToGetList = () => {
        setSelectorAnchor(null);
        if(vehPos.vehId && duration.from && duration.till)
            getVehRouteList({variables: {vehId: vehPos.vehId, timeFrom: duration.from, timeTill: duration.till}});
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
        mapShell.morphTo(item.lat, item.lon);
    };

    const onChangeFrom = (from) => {
        const dur = {...duration};
        dur.from = from;
        setDuration(dur);
    };

    const onChangeTill = (till) => {
        const dur = {...duration};
        dur.till = till;
        setDuration(dur);
    };

    const onCloseSelector = () => {
        setSelectorAnchor(null);
    };

    return(
        <VehRouteListContainer>
            <HeaderBox>
                <IconButton
                    color={AppPalette.PrimaryColor}
                    onClick={(e)=>setSelectorAnchor(e.currentTarget)}
                >
                    <CalendarMonthIcon/>
                </IconButton>
                <Popover
                    open={Boolean(selectorAnchor)}
                    anchorEl={selectorAnchor}
                    onClose={onCloseSelector}
                    anchorOrigin={{ vertical: AppWord.TOP, horizontal: AppWord.LEFT, }}
                    transformOrigin={{ vertical: AppWord.TOP, horizontal: AppWord.LEFT, }}
                >
                    <PickerBox>
                        <DurationPicker
                            from={duration.from} onChangeFrom={onChangeFrom}
                            till={duration.till} onChangeTill={onChangeTill}
                        />
                        <Button
                            size={AppWord.SMALL}
                            variant={AppPalette.VariantContained}
                            color={AppPalette.PrimaryColor}
                            disabled={!Boolean(duration) || !Boolean(vehPos)}
                            onClick={onClickToGetList}
                            style={{marginLeft:10, marginRight:10}}
                        >
                            조회
                        </Button>
                    </PickerBox>
                </Popover>
                {
                    duration.from && duration.till
                    ?
                    <DurationView>
                        <Box>{duration.from.substr(0,16)} 에서</Box>
                        <Box>{duration.till.substr(0,16)} 까지</Box>
                    </DurationView>
                    :
                    <span>기간을 선택하세요</span>
                }
                {
                    duration.from && duration.till && route.length===0
                    ?
                    <Button size={AppWord.SMALL}
                        variant={AppPalette.VariantContained}
                        color={AppPalette.PrimaryColor}
                        onClick={onClickToGetList}
                        style={{marginLeft:10}}
                    >
                        조회
                    </Button>
                    : null
                }
            </HeaderBox>
            <TableBox>
                <FlexyTable
                    name="map_veh_route_list"
                    uniqueKey="gpsTime"
                    columns={TableColumns}
                    records={route}
                    tools={[]}
                    onClickOneRow={onSelectItem}
                    selected={itemSelected ? [itemSelected] : null}
                />
            </TableBox>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehRouteListContainer>
    );

}
