// route_monthly/RouteMonthlyGql.js
import { gql } from "@apollo/client";


const ROUTE_MONTHLY_LIST_PART = gql`
fragment RouteMonthlyListPart on RouteMonthly {
    vehId
    routeDate
    routeMonth
    custId
    custName
    vehPlates
    secondsRun
    hoursRun
    daysRun
    totalDist
    avgSpeed
    avgSpeedRun
    maxSpeed
    avgCycleMake
    avgCycleReport
    positionCount
    hhmmFrom
    hhmmTill
    driveCanStart
    driveMustEnd
}
`;

export const LIST_ROUTE_MONTHLY = gql`
${ROUTE_MONTHLY_LIST_PART}
query ListRouteMonthly($routeDate:String!) {
    routeMonthlyList(routeDate:$routeDate) {
        ...RouteMonthlyListPart
    }
}
`;

export const LIST_ROUTE_MONTHLY_OF_VEH = gql`
${ROUTE_MONTHLY_LIST_PART}
query ListRouteMonthlyOfVeh($vehId: Int!, $dateFrom: String!, $dateTill: String!) {
    routeMonthlyListOfVeh(vehId:$vehId, dateFrom:$dateFrom, dateTill:$dateTill) {
        ...RouteMonthlyListPart
    }
}
`;

