// BcdIoData.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Button, Tabs, Tab, AppBar, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import BcdIoDataList from './BcdIoDataList';
import BcdIoDataListForVehicle from './BcdIoDataListForVehicle';
import BcdIoDataListForPoi from './BcdIoDataListForPoi';
import VehRouteListMini from '../veh_route/VehRouteListMini';
import { AppNumber } from '../../model/AppConst';

const dcPad = AppNumber.dataContainerPadding;
const BcdIoDataContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, // display:'flex'
});

// 각 목록을 숨기거나 보이게 하기 위해서 (diaplay: 'flex' or 'none')
const ListWrapper = styled(Box)({
	position:"absolute", top:0, right:0, bottom: 0, left: 0,
});

const RouteContainer = styled(Box)({
    position:'relative'
});

const BcdView = {Duration:1, ForVehicle: 2, ForPoi:3};


export default function BcdIoData() {
    const [viewNow, setViewNow] = useState(BcdView.Duration);
    const [durationRoute, setDurationRoute] = useState(null);
    const [vehPlates, setVehPlates] = useState('');
    const clientSize = useClientSize();

    const onClickViewTab = (event, value) => {
        setViewNow(value);
    };

    const onRequestPopRoute = (vehInfo, timeFrom, timeTill) => {
        setVehPlates(vehInfo.vehPlates);
        setDurationRoute({...vehInfo, from:timeFrom, till:timeTill});
    };

    const AppSelector =
        <AppBar position="static" color='default'
            style={{boxShadow:'none'}}
        >
            <Tabs value={viewNow} onChange={onClickViewTab}
                scrollButtons="auto"
                variant='fullWidth' textColor="inherit"
            >
                <Tab value={BcdView.Duration} label="기간 조회" />
                <Tab value={BcdView.ForVehicle} label="차량별" />
                <Tab value={BcdView.ForPoi} label="지점별" />
            </Tabs>
        </AppBar>;

    const dialogWidth = clientSize.dataAreaWidth > 1000 ? 1000 : clientSize.dataAreaWidth * 0.8;
	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <BcdIoDataContainer>
            <ListWrapper style={{display: viewNow===BcdView.Duration ? 'flex' : 'none'}}>
                <BcdIoDataList
                    maxHeight={clientSize.dataAreaHeight}
                    maxWidth={clientSize.dataAreaWidth - dcPad * 2}
                    tabSelector={AppSelector}
                    onRequestViewRoute={onRequestPopRoute}
                />
            </ListWrapper>
            <ListWrapper style={{display: viewNow===BcdView.ForVehicle ? 'flex' : 'none'}}>
                <BcdIoDataListForVehicle
                    maxHeight={clientSize.dataAreaHeight}
                    maxWidth={clientSize.dataAreaWidth - dcPad * 2}
                    tabSelector={AppSelector}
                    onRequestViewRoute={onRequestPopRoute}
                />
            </ListWrapper>
            <ListWrapper style={{display: viewNow===BcdView.ForPoi ? 'flex' : 'none'}}>
                <BcdIoDataListForPoi
                    maxHeight={clientSize.dataAreaHeight}
                    maxWidth={clientSize.dataAreaWidth - dcPad * 2}
                    tabSelector={AppSelector}
                />
            </ListWrapper>
            <Dialog open={Boolean(durationRoute)}
                onClose={()=>{setDurationRoute(null)}}
                maxWidth="lg"
            >
                <DialogTitle>
                    {vehPlates} 이동경로
                </DialogTitle>
                <DialogContent style={{minWidth:dialogWidth}}>
                    <RouteContainer height={clientSize.dataAreaHeight-50 - 200}>
                        <VehRouteListMini
                            vehPos={durationRoute}
                            durationGiven={durationRoute}
                            maxHeight={clientSize.dataAreaHeight-50 - 20}
                            maxWidth={clientSize.dataAreaWidth - dcPad * 3 - 160}
                        />
                    </RouteContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setDurationRoute(null)}} variant='contained'>닫기</Button>
                </DialogActions>
            </Dialog>
        </BcdIoDataContainer>
    )
}


/*

            <Dialog open={Boolean(durationRoute)}
                onClose={()=>{setDurationRoute(null)}}
                maxWidth="lg"
            >
                <DialogTitle>
                    {vehPlates} 이동경로
                </DialogTitle>
                <DialogContent style={{height:clientSize.dataAreaHeight-200, width:clientSize.dataAreaWidth - dcPad * 2 - 60}}>
                    <RouteContainer width={clientSize.dataAreaWidth - dcPad * 2 - 60} height={clientSize.dataAreaHeight-200 - 200}>
                        <VehRouteListMini
                            vehPos={durationRoute}
                            durationGiven={durationRoute}
                            maxHeight={clientSize.dataAreaHeight-200 - 200}
                            maxWidth={clientSize.dataAreaWidth - dcPad * 2 - 160}
                        />
                    </RouteContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setDurationRoute(null)}} variant='contained'>닫기</Button>
                </DialogActions>
            </Dialog>

*/