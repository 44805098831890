// PoiInfoNarrowList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FlexyTable from '../common/flexytable/FlexyTable';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';

const PoiInfoListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});

const TitleBox = styled('span')({fontWeight:'bold', marginRight:10, fontSize:'1.05rem'});

const TableColumns = [
    { id: 'poiName', label: '지점', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'addr', label: '주소', width: 140, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'tel', label: '연락처', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function PoiInfoNarrowList({
    title,
    records,
	selected,
    onClickItem,
    tools,
    onClickToolOnRow,
    hideSearchBox
}) {
    //const [poiInfoList, setPoiInfoList] = useState([]);
    const [search, setSearch] = useState('');

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onSelectItem = (item) => {
        //setItemSelected(item);
		if(onClickItem) onClickItem(item);
    };

    const onClickToolOnRowHere = (toolId, row) => {
        if(onClickToolOnRow) onClickToolOnRow(toolId, row);
    };


    const InputId = 'search-PoiInfo-nar-list';
    return(
        <PoiInfoListContainer>
            <HeaderBox>
                {
                    title ? <TitleBox>{title}</TitleBox> : null
                }
                {
                    hideSearchBox ? null :
                    <FormControl variant='outlined' size='small'>
                        <InputLabel htmlFor={InputId} style={{backgroundColor:'white'}}>검색</InputLabel>
                        <OutlinedInput id={InputId} onChange={onChangeSearch}
                            value={search}
                            disabled={records.length === 0}
                            size='small'
                            endAdornment={
                                Boolean(search) ?
                                <InputAdornment position={AppWord.END}>
                                    <IconButton
                                    onClick={()=>{setSearch('')}}
                                    size={AppWord.SMALL}
                                    edge={AppWord.END}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                                :
                                null
                            }
                        />
                    </FormControl>
                }
            </HeaderBox>
            <FlexyTable
                name="poi_info_narrow_list"
                uniqueKey="poiId"
                columns={TableColumns}
                records={records}
                search={search}
                tools={tools ? tools : []}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
                onClickTool={onClickToolOnRowHere}
                hideFirstTopButton={true}
            />
        </PoiInfoListContainer>
    );
}