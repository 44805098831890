// CustPeople.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton } from '@mui/material';
import CustPeopleList from './CustPeopleList';
import CustPeopleInput from './CustPeopleInput';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_CUST_PEOPLE, GET_CUST_PEOPLE, ADD_CUST_PEOPLE, EDIT_CUST_PEOPLE, REMOVE_CUST_PEOPLE,
	CustPeopleDictionary
} from './CustPeopleGql';
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

//const dcPad = AppNumber.dataContainerPadding;
const CustPeopleContainer = styled(Box)({
	flexGrow:1, display:'flex'
});


const ErrorTitle =CustPeopleDictionary.errorTitle;

export default function CustPeople({
    custInfo,
}) {
	const [custPeopleSelected, setCustPeopleSelected] = useState(null);
    const [custPeopleRecords, setCustPeopleRecords] = useState([]);
    const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);

    // ##### Call GraphQL to get List #####
    const [getCustPeopleList, responseList] = useLazyQuery(LIST_CUST_PEOPLE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {if(data.custPeopleList) setCustPeopleRecords(data.custPeopleList)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getCustPeopleItemToEdit, responseItemToEdit] = useLazyQuery(GET_CUST_PEOPLE, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation. 'onError' ###
    const [addCustPeople, responseAdd] = useMutation( ADD_CUST_PEOPLE, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editCustPeople, responseEdit] = useMutation( EDIT_CUST_PEOPLE, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeCustPeople, responseRemove] = useMutation( REMOVE_CUST_PEOPLE, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        if(custInfo) getCustPeopleList({variables:{custId:custInfo.custId}});
    }, [custInfo, getCustPeopleList]);


    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteAdd = (data, clientOption) => {
        if(data.custPeopleAdd.ok) {
            setCustPeopleRecords(data.custPeopleAdd.list);
            setEditorState(null);
        }
        else setResponseAlert({open:true, resultData: data.custPeopleAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.custPeopleEdit.ok) {
            setCustPeopleRecords(data.custPeopleEdit.list);
            setEditorState(null);
        }
        else setResponseAlert({open:true, resultData: data.custPeopleEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.custPeopleRemove.ok) {
            setCustPeopleRecords(data.custPeopleRemove.list);
            setEditorState(null);
        }
        else setResponseAlert({open:true, resultData: data.custPeopleRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.custPeopleItem) {
            const item = {...data.custPeopleItem};
            setEditorState({item: item});
        }
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestEdit = (item) => {
        if(responseEdit) responseEdit.reset();
        getCustPeopleItemToEdit({variables: {custId: item.custId,recId: item.recId}});
        // setEditorState({item: item});
    };

    const onRequestRemove = (item) => {

        setPromptToConfirm({
            data: item,
            title: '담당자 정보 삭제',
            messages: [
                '담당자 ' + item.pName + '의 정보를 삭제하시겠습니까?',
                '해당 담당자의 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{custPeople:{custId: data.custId,recId: data.recId}}};
                    removeCustPeople(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by CustPeopleInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {custPeople: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editCustPeople(param);
        else addCustPeople(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList,
        responseAdd,
        responseEdit,
        responseRemove,
        responseItemToEdit
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <CustPeopleInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                custId={custInfo.custId}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
            :
            <CustPeopleInput
                open={Boolean(editorState)}
                isEdit={false}
                custId={custInfo.custId}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <CustPeopleContainer>
            <CustPeopleList
                records={custPeopleRecords}
				selected={custPeopleSelected}
				onClickItem={setCustPeopleSelected}
                onRequestAdd={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
            />
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </CustPeopleContainer>
    )
}
