// ActLogList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ClearIcon from '@mui/icons-material/Clear';
import FlexyTable from '../common/flexytable/FlexyTable';
import LoginIcon from '@mui/icons-material/Login'; // login
import LogoutIcon from '@mui/icons-material/Logout'; // user_info + logout
import DesktopMacSharpIcon from '@mui/icons-material/DesktopMacSharp'; // AppMain + start
import MenuOpenIcon from '@mui/icons-material/MenuOpen'; // AppMain + menu-selected
import DoneIcon from '@mui/icons-material/Done'; // Others
import { AppNumber, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
//import { ActTableColumns as TableColumns} from './ActLogGql';

const ActLogListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
	display:'flex', alignItems:'center', height:AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const HeaderControl = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});

const AppMain = 'AppMain';
const Middle = 'middle';
const MidIcon = {verticalAlign: Middle};
function printActType(row,idx,value) {
    if(row.tableName===AppMain) {
        if(value==='start')
            return <span><DesktopMacSharpIcon size={AppWord.SMALL} color={AppPalette.PrimaryColor} style={MidIcon}/> {value}</span>;
        if(value==='menu-selected')
            return <span><MenuOpenIcon size={AppWord.SMALL} color={AppPalette.PrimaryColor} style={{verticalAlign:Middle, opacity:0.5}}/> {value}</span>;
    }
    if(row.tableName==='user_info') {
        if(value==='login')
            return <span><LoginIcon size={AppWord.SMALL} color={AppPalette.SuccessColor} style={MidIcon}/> {value}</span>;
        if(value==='logout')
            return <span><LogoutIcon size={AppWord.SMALL} color={AppPalette.SecondaryColor} style={MidIcon}/> {value}</span>;
    }
    if(value==='insert') return <span><AddIcon size={AppWord.SMALL} color={AppPalette.DisabledColor} style={MidIcon}/> {value}</span>;
    if(value==='update') return <span><NoteAltIcon size={AppWord.SMALL} color={AppPalette.DisabledColor} style={MidIcon}/> {value}</span>;
    if(value==='delete') return <span><ClearIcon size={AppWord.SMALL} color={AppPalette.WarnColor} style={MidIcon}/> {value}</span>;
    return <span><DoneIcon size={AppWord.SMALL} color={AppPalette.DisabledColor} style={MidIcon}/> {value}</span>;
}
const TableColumns = [
	// { id: 'logNid', label: Dict.logNid.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tableName', label: '정보', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'actType', label: '활동유형', width: 150, show: true, option:{align: 'inherit', print: (r,i,v) => printActType(r,i,v)} },
    { id: 'actTime', label: '시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'procName', label: '프로시져', width: 90, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'actText', label: '내용', width: 220, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custId', label: 'CUST_ID', width: 90, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'sessionKey', label: 'Session Key', width: 160, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regUserId', label: 'ID', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userName', label: '이름', width: 80, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} }
];

/**
 * 특정 세션(사용자)의 활동 기록 조회 뷰.
 */
export default function ActLogList({
	records,
}) {
    const [search, setSearch] = useState('');


    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const SearchInputId = 'search-ActLog-list-of-session';
    return(
        <ActLogListContainer>
            <HeaderBox>
				<HeaderControl>
					<FormControl variant='outlined' size={AppWord.SMALL}>
						<InputLabel htmlFor={SearchInputId} style={{backgroundColor:'white'}}>검색</InputLabel>
						<OutlinedInput id={SearchInputId} onChange={onChangeSearch}
							value={search}
							disabled={records.length === 0}
							size={AppWord.SMALL}
							endAdornment={
								Boolean(search) ?
								<InputAdornment position='end'>
									<IconButton
									onClick={()=>{setSearch('')}}
									size={AppWord.SMALL}
									edge="end"
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
								:
								null
							}
						/>
					</FormControl>
				</HeaderControl>
            </HeaderBox>
            <FlexyTable
                name="act_log_list"
                uniqueKey="logNid"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[]}
            />
        </ActLogListContainer>
    );
}