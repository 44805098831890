// TermStock.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton } from '@mui/material';
import TermStockList from './TermStockList';
import TermStockInput from './TermStockInput';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    LIST_TERM_STOCK, GET_TERM_STOCK, ADD_TERM_STOCK, EDIT_TERM_STOCK, REMOVE_TERM_STOCK,
	TermStockDictionary
} from './TermStockGql';
import { ViewMode, AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;
const TermStockContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});


const ErrorTitle =TermStockDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(TermStockDictionary.inputMap, true);


export default function TermStock() {
    const [duration, setDuration] = useState({dateFrom: ValueUtil.getYmdText(86400*31), dateTill: ValueUtil.getYmdText(0)});
	const [termStockRecords, setTermStockRecords] = useState([]);
	const [termStockSelected, setTermStockSelected] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
	const sessionInfo = useReactiveVar(userInfoRepo);

    // ##### Call GraphQL to get List #####
    const [getTermStockList, responseList] = useLazyQuery(LIST_TERM_STOCK, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getTermStockItemToEdit, responseItemToEdit] = useLazyQuery(GET_TERM_STOCK, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation. ###
    const [addTermStock, responseAdd] = useMutation( ADD_TERM_STOCK, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editTermStock, responseEdit] = useMutation( EDIT_TERM_STOCK, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeTermStock, responseRemove] = useMutation( REMOVE_TERM_STOCK, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        if(duration) {
            if(duration.dateFrom && duration.dateTill) {
                getTermStockList({variables:duration});
            }
        }
    }, []);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.termStockList) setTermStockRecords(data.termStockList);
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.termStockAdd.ok) {
            setEditorState(null);
            if(duration) getTermStockList({variables:duration});
        }
        else setResponseAlert({open:true, resultData: data.termStockAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.termStockEdit.ok) {
            setEditorState(null);
            if(duration) getTermStockList({variables:duration});
        }
        else setResponseAlert({open:true, resultData: data.termStockEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.termStockRemove.ok) {// getTermStockOfCust(); // setTermStockRecords(data.termStockRemove.list);
            if(duration) getTermStockList({variables:duration});
        }
        else setResponseAlert({open:true, resultData: data.termStockRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.termStockItem) {
            const item = {};
            for(const field of EditFields) item[field] = data.termStockItem[field];
            setEditorState({item: item});
        }
    };

    // +++++++ UI callbacks ++++++++
    const onRequestList = () => {
        const from = ValueUtil.parseDate(duration.dateFrom);
        const till = ValueUtil.parseDate(duration.dateTill);
        let param;
        if(from && till) {
            if(from.getTime() <= till.getTime()) param = {...duration};
            else param = {dateFrom: duration.dateTill, dateTill: duration.dateFrom};
            getTermStockList({variables:param});
        }
    };

    const onChangeDuration = (newRange) => {
        setDuration(newRange);
    };

    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestEdit = (data) => {
        if(responseEdit) responseEdit.reset();
        getTermStockItemToEdit({variables: {ioDate: data.ioDate,modelId: data.modelId}});
        // setEditorState({item: data});
    };

    const onRequestRemove = (item) => {
        setPromptToConfirm({
            data: item,
            title: '단말기 재고 정보 삭제',
            messages: [
                '지정한 단말기 재고 정보를 삭제하시겠습니까?',
                '해당 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{termStock:{ioDate: data.ioDate,modelId: data.modelId}}};
                    removeTermStock(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by TermStockInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {termStock: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editTermStock(param);
        else addTermStock(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList, responseAdd, responseEdit, responseItemToEdit, responseRemove
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <TermStockInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
            :
            <TermStockInput
                open={Boolean(editorState)}
                isEdit={false}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

	const isAdmin = ValueUtil.isEtrace(sessionInfo);
    if(!isAdmin) return <Box>***</Box>
    
	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <TermStockContainer>
            <TermStockList
                dateFrom={duration.dateFrom}
                dateTill={duration.dateTill}
                records={termStockRecords}
				selected={termStockSelected}
				onClickItem={setTermStockSelected}
                onRequestAdd={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
                onRequestList={onRequestList}
                onChangeDuration={onChangeDuration}
            />
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </TermStockContainer>
    )
}
