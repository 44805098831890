// veh_route/VehRouteGql.js
import { gql } from "@apollo/client";

/*
가져오지 않을 필드들:
        devId
        recvTime
        insertTime
        statusCode
        loadCondCd
        gpioBits
        custPoiCode
        addrPrefix
        poiDistM
        roadRank
        minsBatteryAlive
        isEngineOn01
        isGpsGood01
        extSensorName
        extSensorData
        tempr1Break
        tempr2Break
        tempr3Break
        tempr4Break
        tempr1Alarm
        tempr2Alarm
        tempr3Alarm
        tempr4Alarm
        dataPath
        tachoTempr1
        tachoTempr2
        tachoTempr3
        tachoTempr4
        tachoTempr5
        tachoTempr6
        tachoHumid1
        tachoHumid2
        tachoHumid3
        tachoHumid4
        tachoHumid5
        tachoHumid6
        tachoPeriod
        tachoRpm
        tachoBreak
        tachoTaillight
        tachoInput
        irLat
        irLon
        irYCoord
        irXCoord
        irDist
        irMins
        irPoiType
        irPoiSeq
        dataDeploy
        poiId
        midPoint {
            diffSeconds
            lat
            lon
        }
*/

export const LIST_ROUTE_FOR_MAP = gql`
query ListVehRouteForMap($vehId:Int, $timeFrom: String, $timeTill: String) {
    vehRouteList(vehId:$vehId, timeFrom:$timeFrom, timeTill:$timeTill) {
        custId
        vehId
        gpsDatetime
        gpsTime
        vehPlates
        vehAlias
        speed
        distKm
        direction
        location
        eventCd
        svcCondCd
        svcCondValue
        pwrType
        pwrTypeValue
        posSys
        posSysValue
        xDist
        yDist
        tachoDist
        tachoSpeed
        locStay
        roadName
        roadMaxSpeed
        lon
        lat
        tempr1
        tempr2
        tempr3
        tempr4
        door0
        door1
        door2
        itinRepType
        irAction
        irActionName
        poiName
        bcdEventCd
        bcdEventValue
        keyTime
        timeArrival
        timeDeparture
        timePrevStop
        runSec
        staySec
        distBetween
        midPoints {
            diffSeconds
            lat
            lon
        }
    }
}
`;

export const LIST_VEH_ROUTE = gql`
query ListVehRoute($vehId:Int, $timeFrom: String, $timeTill: String) {
    vehRouteList(vehId:$vehId, timeFrom:$timeFrom, timeTill:$timeTill) {
        custId
        vehId
        gpsDatetime
        gpsTime
        gpsHhmm
        vehPlates
        vehAlias
        termId
        speed
        distKm
        direction
        location
        eventCd
        svcCondCd
        svcCondValue
        pwrType
        pwrTypeValue
        posSys
        posSysValue
        xDist
        yDist
        tachoDist
        tachoSpeed
        locStay
        roadName
        roadMaxSpeed
        lon
        lat
        cycleMake
        cycleReport
        batteryVolt
        tempr1
        tempr2
        tempr3
        tempr4
        door0
        door1
        door2
        itinRepType
        irAction
        irActionName
        poiName
        bcdEventCd
        bcdEventValue
        keyTime
        timeArrival
        timeDeparture
        timePrevStop
        runSec
        staySec
        distBetween
    }
}
`;

export const LIST_VEH_ROUTE_TEMPR = gql`
query ListVehRouteTempr($vehId:Int, $timeFrom: String, $timeTill: String) {
    vehRouteTemprList(vehId:$vehId, timeFrom:$timeFrom, timeTill:$timeTill) {
        vehId
        custId
        vehPlates
        gpsTime
        vehAlias
        termId
        recvTime
        insertTime
        speed
        distKm
        direction
        eventCd
        xDist
        yDist
        location
        lon
        lat
        midRoute
        tempr1
        tempr2
        tempr3
        tempr4
        tempr1Break
        tempr2Break
        tempr3Break
        tempr4Break
        tempr1Alarm
        tempr2Alarm
        tempr3Alarm
        tempr4Alarm
        tachoTempr1
        tachoTempr2
        tachoTempr3
        tachoTempr4
        tachoTempr5
        tachoTempr6
        tachoHumid1
        tachoHumid2
        tachoHumid3
        tachoHumid4
        tachoHumid5
        tachoHumid6
        tachoPeriod
        tachoSpeed
        tachoRpm
        tachoBreak
        tachoTaillight
        tachoInput
        tachoDist
        tempr1Ll
        tempr1Ul
        tempr2Ll
        tempr2Ul
        tempr3Ll
        tempr3Ul
        tempr4Ll
        tempr4Ul
        tachoTempr1Ll
        tachoTempr1Ul
        tachoTempr2Ll
        tachoTempr2Ul
        tachoTempr3Ll
        tachoTempr3Ul
        tachoTempr4Ll
        tachoTempr4Ul
        tachoTempr5Ll
        tachoTempr5Ul
        tachoTempr6Ll
        tachoTempr6Ul
        tachoHumid1Ll
        tachoHumid1Ul
        tachoHumid2Ll
        tachoHumid2Ul
        tachoHumid3Ll
        tachoHumid3Ul
        tachoHumid4Ll
        tachoHumid4Ul
        tachoHumid5Ll
        tachoHumid5Ul
        tachoHumid6Ll
        tachoHumid6Ul
        tachoSpeedLimit
        tachoRpmLimit
        tempAlertLimit
        poiId
        poiName
        bcdEventCd
        bcdEventValue
    }
}
`;