//TossTable.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled  } from '@mui/material/styles';
import {
    IconButton, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow
} from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'; // >>
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'; // <<

const HeaderCell = styled(TableCell)({
    backgroundColor:'#eee',
    cursor:'pointer'
});

function isInRecord(record, columns, search) {
    if(!Boolean(search)) return false;
    if(search.length === 0) return false;
    for(const column of columns) {
        const val = record[column.id];
        if(val) {
            const str = '' + val;
            if(str.indexOf(search) >= 0) return true;
        }
    }
    return false;
}

function compareForSearchOnly(a, b, search, columns) {
    let toSearch = false;
    if(search) toSearch = search.length > 0;
    if(!toSearch) return 0;

    if(isInRecord(a, columns, search)) {
        if(isInRecord(b, columns, search)) return 0;
        else return -1;
    }
    else {
        if(isInRecord(b, columns, search)) return 1;
        else return 0;
    }
}

function compareWithSearch(a, b, isReverse, id, search, columns) {
    let toSearch = false;
    if(search) toSearch = search.length > 0;

    if(toSearch) {
        if(!a[id]) {
            if(!b[id]) return 0;
            else return 1;
        }
        else {
            if(!b[id]) return -1;

            if(isInRecord(a, columns, search)) {
                if(isInRecord(b, columns, search)) {
                    if(a[id] === b[id]) return 0;
                    else if(a[id] < b[id]) return isReverse ? 1 : -1;
                    else return isReverse ? -1 : 1;
                }
                else return -1;
            }
            else {
                if(isInRecord(b, columns, search)) return 1;
                else {
                    if(a[id] === b[id]) return 0;
                    else if(a[id] < b[id]) return isReverse ? 1 : -1;
                    else return isReverse ? -1 : 1;
                }
            }
        }
    }
    else {
        if(!a[id]) {
            if(!b[id]) return 0;
            else return 1;
        }
        else {
            if(!b[id]) return -1;
            
            if(a[id] === b[id]) return 0;
            else if(a[id] < b[id]) return isReverse ? 1 : -1;
            else return isReverse ? -1 : 1;
        }
    }
}

// 데이터를 주고받기 위한 테이블.
// 2개의 테이블을 나란히 놓고 좌측과 우측이 데이터를 주고 받을 수 있도록 함.
// 주고 받는 방식은 버튼 클릭. (드랙엔 드랍도 생각했으나 오히려 더 불편할 듯.)
// 좌측 테이블은 우측에 우측테이블은 좌측 끝에 아이콘 버튼을 둠.
export default function TossTable({
    columns, // id, label, minWidth
    records,
    search,
    isLeft, // 좌측에 있는 테이블. 아이콘을 우측 끝에 둠.
    onClickSend
}) {
    const [sortKey, setSortKey] = useState(null);
    const [sortReverse, setSortReverse] = useState(false);

    useEffect(()=>{
        setSortKey(null);
    }, [records]);

    const onClickHeader = (column) => {
        if(sortKey === column.id) setSortReverse(!sortReverse);
        else {
            setSortKey(column.id);
            setSortReverse(false);
        }
    };

    const compareRecord = (a, b) => {
        return compareWithSearch(a, b, sortReverse, sortKey, search, columns);
    };

    const compareSearching = (a, b) => {
        return compareForSearchOnly(a, b, search, columns);
    };

    const renderRecords = () => {
        const list = sortKey ? records.sort(compareRecord) : records.sort(compareSearching);
        return(
            <TableBody style={{overflow:'auto'}}>
                {
                    list.map((rec, idx) => {
                        return(
                            <TableRow key={idx} hover>
                                {
                                    !isLeft ?
                                    <TableCell>
                                        <IconButton size='small' onClick={()=>{onClickSend(rec)}} color='primary'>
                                            <KeyboardDoubleArrowLeftIcon fontSize='small'/>
                                        </IconButton>
                                    </TableCell>
                                    : null
                                }
                                {
                                    columns.map((c, colIdx) => {
                                        const id = c.id;
                                        const val = rec[id];
                                        if(c.option) {
                                            if(c.option.print) {
                                                return(
                                                    <TableCell key={colIdx}>
                                                        {c.option.print(rec, idx, val)}
                                                    </TableCell>
                                                );
                                            }
                                        }
                                        return(
                                            <TableCell key={colIdx}>
                                                {val}
                                            </TableCell>
                                        );
                                    })
                                }
                                {
                                    isLeft ?
                                    <TableCell>
                                        <IconButton size='small' onClick={()=>{onClickSend(rec)}} color='secondary'>
                                            <KeyboardDoubleArrowRightIcon fontSize='small'/>
                                        </IconButton>
                                    </TableCell>
                                    : null
                                }
                            </TableRow>
                        );
                    })
                }
            </TableBody>
        );
    };

    return (
        <TableContainer style={{position:'absolute', top:0, bottom:0}}>
            <Table aria-label='toss-table' size='small' stickyHeader>
                <TableHead>
                    <TableRow>
                        {
                            !isLeft ?
                            <HeaderCell width={36}>&nbsp;</HeaderCell>
                            : null
                        }
                        {
                            columns.map((column) => {
                                const minWidth = column.minWidth ? column.minWidth : 40;
                                const style = {minWidth: minWidth, overflow:'hidden'};
                                if(column.width) {
                                    style.width = column.width;
                                }

                                if(sortKey === column.id) {
                                    if(sortReverse) style.color = 'red';
                                    else style.color = 'blue';
                                }

                                return(
                                    <HeaderCell
                                        key={column.id}
                                        align={column.align || 'left'}
                                        style={style}
                                        onClick={()=>{onClickHeader(column)}}
                                    >
                                        {column.label}
                                    </HeaderCell>
                                );
                            })
                        }
                        {
                            isLeft ?
                            <HeaderCell width={36}>&nbsp;</HeaderCell>
                            : null
                        }
                    </TableRow>
                </TableHead>
                {renderRecords()}
            </Table>
        </TableContainer>
    )
}
