// UserOperAuth.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_USER_INFO_WATCH_GROUP, ADD_USER_OPER_AUTH, REMOVE_USER_OPER_AUTH
} from './UserOperAuthGql';
import TossTable from '../common/TossTable';
import { AppObject, AppPalette } from '../../model/AppConst';

const AllocBox = styled(Box)({ // has TitleBox and PairBox
    flexGrow:1,
    display:'flex',
    flexDirection: 'column',
});

const TitleBox = styled(Box)({fontWeight:'bold', padding:10, fontSize: '1.1rem', borderBottom:AppPalette.BorderCCC});

const PairBox = styled(Box)({ // has 2 SetBox
    display:'flex',
    flexGrow:1,
});

const SetBox =  styled(Box)({ // has SetTitleBox, SearchBox and TableBox
    flexGrow:1,
    display:'flex',
    flexDirection: 'column',
    marginLeft:3,
    marginRight:3,
    width:'50%'
});
const SetTitleBox = styled(Box)({fontWeight:'bold', padding:10});
const SearchBox = styled(Box)({padding:5});

const TableBox = styled(Box)({
    flexGrow:1,
    border:AppPalette.BorderCCC,
    overflow:'auto',
    borderRadius:5,
    position:'relative'
});

const SmallText = styled(Box)({fontSize:'0.85rem', padding:10});

const ErrorTitle = {
    Add: '차량관제권한 설정 오류',
    Remove: '차량관제권한 해제 오류',
};

const AllocTableColumns = [
    {id:'userName', minWidth:80, label:'사용자'},
    {id:'userId', minWidth:80, label:'아이디', width:80}
];

export default function UserOperAuth({
    groupId,
    groupName,
    informHowmanyInGroup
}) {
    const [userListIn, setUserListIn] = useState([]);
    const [userListOut, setUserListOut] = useState([]);
    const [searchIn, setSearchIn] = useState('');
    const [searchOut, setSearchOut] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);

    // ##### Call GraphQL to get List #####
    const [getListUserOperAuthOverGroup, responseList] = useLazyQuery(LIST_USER_INFO_WATCH_GROUP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)}, 
		onError: (error) => {setResponseAlert({open:true, error: error, title: "사용자 목록 조회 오류(관제권한 확인용)"})}
    });
    // ##### GraphQL Mutation. ###
    const [addUserOperAuth, responseAdd] = useMutation( ADD_USER_OPER_AUTH, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [removeUserOperAuth, responseRemove] = useMutation( REMOVE_USER_OPER_AUTH, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(()=>{
        if(groupId) getListUserOperAuthOverGroup({variables: {groupId: groupId}});
    }, [groupId, getListUserOperAuthOverGroup]);

    const devideUserList = (list) => {
        const groupIn = [];
        const groupOut = [];
        for(const g of list) {
            if(g.groupId === groupId) groupIn.push(g);
            else groupOut.push(g);
        }
        setUserListIn(groupIn);
        setUserListOut(groupOut);
        if(informHowmanyInGroup) informHowmanyInGroup(groupId, groupIn.length);
    };

    // <<<<<<<<<<< onComplete:: gql query <<<<<<<<<<<<<<<<
    // 최초 목록 가져오기 완료. 2개의 목록으로 분리해 준다.
    const onCompleteGetList = (data, clientOption) => {
        if(data.userInfoListOverGroup) {
            devideUserList(data.userInfoListOverGroup);
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.userOperAuthAdd.ok) devideUserList(data.userOperAuthAdd.list);
        else setResponseAlert({open:true, resultData: data.userOperAuthAdd, title: ErrorTitle.Add});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.userOperAuthRemove.ok) devideUserList(data.userOperAuthRemove.list);
        else setResponseAlert({open:true, resultData: data.userOperAuthRemove, title: ErrorTitle.Remove});
    };

    // >>>>>>>>>>>> callback >>>>>>>>>>>>>
    const onClickAlloc = (rec) => {
        addUserOperAuth({variables: {userOperAuth: {userId: rec.userId, groupId: groupId}}});
    };

    const onClickDealloc = (rec) => {
        removeUserOperAuth({variables: {userOperAuth: {userId: rec.userId, groupId: groupId}}});
    };

    if(!groupId) return null;

    return(
        <AllocBox>
            <TitleBox>{groupName} 그룹에 대한 관제권한 관리 </TitleBox>
            <SmallText>관리자들은 항상 관제권한을 가지므로 목록에 나타나지 않습니다.</SmallText>
            <PairBox>
                <SetBox>
                    <SetTitleBox>
                        관제권한 보유 사용자
                    </SetTitleBox>
                    <SearchBox>
                        <TextField size="small" label="검색"
                            value={searchIn}
                            onChange={(e) => setSearchIn(e.target.value)}
                        />
                        {
                            searchIn ?
                            <IconButton size="small"
                                onClick={()=>{setSearchIn('')}}
                            >
                                <ClearIcon fontSize='small'/>
                            </IconButton> : null
                        }
                    </SearchBox>
                    <TableBox>
                        <TossTable
                            columns={AllocTableColumns}
                            records={userListIn}
                            search={searchIn}
                            onClickSend={onClickDealloc}
                            isLeft={true}
                        />
                    </TableBox>
                </SetBox>
                <SetBox>
                    <SetTitleBox>관제권한이 없는 사용자</SetTitleBox>
                    <SearchBox>
                        <TextField size="small" label="검색"
                            value={searchOut}
                            onChange={(e) => setSearchOut(e.target.value)}
                        />
                        {
                            searchOut ?
                            <IconButton size="small"
                                onClick={()=>{setSearchOut('')}}
                            >
                                <ClearIcon fontSize='small'/>
                            </IconButton> : null
                        }
                    </SearchBox>
                    <TableBox>
                        <TossTable
                            columns={AllocTableColumns}
                            records={userListOut}
                            search={searchOut}
                            onClickSend={onClickAlloc}
                        />
                    </TableBox>
                </SetBox>
            </PairBox>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </AllocBox>
    );
}
