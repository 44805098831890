// term_info/TermInfoGql.js
import { gql } from "@apollo/client";
import { CvoCodes, TableColumnType } from "../../model/AppConst";
import ValueUtil from "../../model/ValueUtil";

export const TermInfoDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        custId : {toAdd: true, toEdit: true},
        devId : {toAdd: false, toEdit: true},
        termId : {
            id: 'termId', label: '단말기번호', type: 'tel', required: true,
            toAdd: true, toEdit: true,
            help: '숫자 11자 이내로 입력하세요.',
            check: (value) => {return value && /^01\d{9,10}$/.test(value)},
        },
        serialNo : {
            id: 'serialNo', label: '시리얼 번호', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '공백없이 50자 이내.',
            check: (value) => {return value && /^\S+$/.test(value)},
        },
        modelId : {
            id: 'modelId', label: '단말기 모델', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default: 32,
            check: (value) => {return value > 0 && value < 100},
        },
        networkCd : {
            id: 'networkCd', label: '통신망', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default:'SKT',
            check: (value) => {return true},
        },
        posSys : {
            id: 'posSys', label: '위치추적방식', type: 'text', required: true,
            toAdd: true, toEdit: true,
            default:'GPS',
            check: (value) => {return true},
        },
        openDate : {
            id: 'openDate', label: '개통일', type: 'date', required: false,
            toAdd: true, toEdit: true,
            help: '선택사항입니다.',
            check: (value) => {return true},
        },
        installDate : {
            id: 'installDate', label: '설치일', type: 'date', required: false,
            toAdd: true, toEdit: true,
            help: '선택사항입니다.',
            check: (value) => {return true},
        },
        //billMeth : {}, payMeth : {}, payTerm : {},
        billAmount : {
            id: 'billAmount', label: '청구금액', type: 'number', required: false,
            toAdd: true, toEdit: true,
            help: '월별청구금액.',
            default: 10000,
            check: (value) => {return value > 0 && value <= 900000},
        },
        vat : {
            required: true, label:'부가가치세', toAdd: true, toEdit: true,
            default: 10,
        },
        // termStateCd : { },
        altTermId : {
            id: 'altTermId', label: '대체단말기번호', type: 'tel', required: false,
            toAdd: true, toEdit: true,
            help: '숫자 11자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || /^01\d{8,9}$/.test(value)},
        },
        etc : {
            id: 'etc', label: '비고', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '약 200자 이내로 제한됩니다.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,600)},
        },
    },
    // for response error printing
    queryName: {Add:'userInfoAdd', Edit:'userInfoEdit', Remove:'userInfoRemove'},
    codeToField: {
        [CvoCodes.NETWORK_CD]: 'networkCd',
        [CvoCodes.POS_SYS]: 'posSys'
    },
    errorTitle: {
        Add: '단말기 정보 생성 오류',
        Edit: '단말기 정보 수정 오류',
        Remove: '단말기 정보 삭제 오류',
        Get: '단말기 정보 조회 오류',
    }
};



export const TermInfoTableColumns = [
	// { id: 'devId', label: 'devId', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'termId', label: '단말기번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'serialNo', label: '시리얼번호', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehPlates', label: '차량번호', width: 80, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehAlias', label: '지도표시명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    // { id: 'posSysValue', label: 'posSys', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regDate', label: '등록일', width: 100, show: true, option:{type: TableColumnType.DATETIME} },
    { id: 'openDate', label: '개통일', width: 100, show: true, option:{type: TableColumnType.DATE} },
    { id: 'installDate', label: '설치일자', width: 100, show: true, option:{type: TableColumnType.DATE} },
    { id: 'termStateValue', label: '현재상태', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'gpsTime', label: '최종보고', width: 170, show: true, option:{type: TableColumnType.DATETIME} },
    { id: 'model', label: '단말기모델', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'networkValue', label: '통신사', width: 70, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'altTermId', label: '대체단말기', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'etc', label: '비고', width: 180, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} }
];

const TERM_INFO_LIST_PART = gql`
fragment TermInfoListPart on TermInfo {
    devId
    termId
    serialNo
    custId
    custName
    modelId
    model
    networkCd
    networkValue
    posSys
    posSysValue
    openDate
    installDate
    billMeth
    billAmount
    vat
    installFee
    termStateCd
    termStateValue
    salesStaffName
    etc
    altTermId
    gpsTime
    vehPlates
    vehAlias
    regDate
}
`;

const TERM_INFO_NO_LIST_PART = gql`
fragment TermInfoNoListPart on TermInfo {
    payMeth
    payTerm
    tabbUseYn
    deleveryDate
    manufactureDate
    buyPrice
    sellPrice
    telcomAssist
    regUserId
}
`;


export const LIST_TERM_INFO = gql`
${TERM_INFO_LIST_PART}
query ListTermInfo($custId:String) {
    termInfoList(custId:$custId) {
        ...TermInfoListPart
    }
}
`;
export const SIMPLE_LIST_TERM_INFO = gql`
query ListTermInfo($custId:String) {
    termInfoList(custId:$custId) {
        devId
        termId
        serialNo
        custName
        modelId
        model
        termStateCd
        termStateValue
        vehPlates
    }
}
`;


export const LIST_TERM_INFO_AVAILABLE = gql`
query ListTermInfoAvailable($custId:String) {
    termInfoListAvailable(custId:$custId) {
        devId
        termId
        serialNo
        modelId
        model
        altTermId
        termStateCd
        termStateValue
        regDate
    }
}
`;

export const GET_TERM_INFO = gql`
${TERM_INFO_LIST_PART}
${TERM_INFO_NO_LIST_PART}
query GetTermInfo($devId:Int) {
    termInfoItem(devId:$devId) {
        ...TermInfoListPart
        ...TermInfoNoListPart
    }
}
`;

export const ADD_TERM_INFO = gql`
mutation AddTermInfo($termInfo:InputTermInfoAdd!) {
  termInfoAdd(termInfo: $termInfo) {
    ok
    message
  }
}
`;

export const ADD_TERM_INFO_SMART_LOGIS = gql`
mutation AddTermInfo($termInfo:InputTermInfoAddSmartLogis!) {
  termInfoAddSmartLogis(termInfo: $termInfo) {
    ok
    message
  }
}
`;

export const EDIT_TERM_INFO = gql`
mutation EditTermInfo($termInfo:InputTermInfoEdit!) {
  termInfoEdit(termInfo: $termInfo) {
    ok
    message
  }
}
`;

export const REMOVE_TERM_INFO = gql`
mutation RemoveTermInfo($termInfo:InputTermInfoRemove!) {
  termInfoRemove(termInfo: $termInfo) {
    ok
    message
  }
}
`;

export const SET_TERM_INFO_STATE = gql`
mutation SetTermInfoState($termInfo:InputTermInfoSetState!) {
  termInfoSetState(termInfo: $termInfo) {
    ok
    message
  }
}
`;