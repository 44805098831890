// TermMngLogList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo, NoUser, codeTreeRepo} from '../../model/CvoModel';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, CvoCodes, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { TermMngLogDictionary } from './TermMngLogGql';
import CodeSelector from '../common/CodeSelector';

const TermMngLogListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
	display:'flex', alignItems:'center', padding: AppNumber.SmallBoxPadding,
});
const HeaderControl = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});

const FilterField = styled(Box)({display:'inline-block', marginRight:10});

const Dict = TermMngLogDictionary.inputMap;
const InputMap = Dict;
const TableColumns = [
    { id: 'termId', label: Dict.termId.label, width: 110, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'acNetworkValue', label: Dict.acNetworkCd.label, width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'changeDate', label: '변경일자', width: 120, show: true, option:{align: 'center', type: TableColumnType.DATE} },
    //{ id: 'custId', label: Dict.custId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: Dict.custName.label, width: 200, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'acTermChangeValue', label: Dict.acTermChangeCd.label, width: 70, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'openerOld', label: Dict.openerOld.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'acModelValueOld', label: Dict.acModelCdOld.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'serialNoOld', label: Dict.serialNoOld.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'usimNumOld', label: Dict.usimNumOld.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'acBillTypeValueOld', label: Dict.acBillTypeCdOld.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'openerName', label: Dict.openerName.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'acModelValue', label: Dict.acModelCd.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'serialNo', label: Dict.serialNo.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'usimNum', label: Dict.usimNum.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'acBillTypeValue', label: Dict.acBillTypeCd.label, width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'supportAmount', label: Dict.supportAmount.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'regTime', label: '기록시각', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'changeMemo', label: Dict.changeMemo.label, width: 200, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

const NotSelectedValue = {value:AppWord.NO_FILTER, label:"(전체)"};
const DefaultFilter = {
    changeDate: '',
    acNetworkCd: NotSelectedValue.value,
};

function getYmdFilterText(txt) {
    let ymd = '';
    if(txt) {
        const len = txt.length;
        if(len >= 4) {
            ymd = txt.substr(0,4);
            if(len >= 6) {
                ymd += '-' + txt.substr(4,2);
                if(len >= 8) ymd += '-' + txt.substr(6,2);
            }
        }
    }
    return ymd;
}

export default function TermMngLogList({
	records,
	selected,
    onClickItem,
    onRequestEdit,
    onRequestRemove,
}) {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [NetworkList, setNetworkList] = useState([]);
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [filterData, setFilterData] = useState({...DefaultFilter});
    const [filterChangeDate, setFilterChangeDate] = useState('');
    const [search, setSearch] = useState('');

    useEffect(()=>{
        if(CodeTree) {
            setNetworkList(ValueUtil.codeToSelectList(CvoCodes.AC_NETWORK_CD, CodeTree, NotSelectedValue));
            setFilterData({...DefaultFilter});
        }
    }, [CodeTree]);

    const filterItem = (row) => {
        let filterCount = 0;
        let matchCount = 0;
        if(ValueUtil.realLen(filterData.changeDate)>0) {
            filterCount++;
            if(row.changeDate && row.changeDate.indexOf(filterData.changeDate)===0) matchCount++;
            else return false;
        }
        for(const cd of Object.keys(filterData)) {
            if(cd.indexOf('ac')===0) {
                const filterValue = filterData[cd];
                const value = row[cd];
                if(filterValue !== AppWord.NO_FILTER) {
                    filterCount++;
                    if(value===filterValue) matchCount++;
                    else return false;
                }
            }
        }

        return filterCount===matchCount;
    };

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
		if(onClickItem) onClickItem(item);
    };

    const onChangeFilterDate = (event) => {
        const txt = event.target.value;
        const id = event.target.id;
        if(txt) {
            if(/[^0-9]/.test(txt)) return;
            if(txt.length>8) return;
        }

        setFilterChangeDate(txt);

        const ymdTxt = getYmdFilterText(txt);

        if(filterData[id] !== ymdTxt) {
            const data = {...filterData};
            data[id] = ymdTxt;
            setFilterData(data);
        }
    };
    
    const onChangeCodeFilter = (id, value) => {
        const data = {...filterData};
        data[id] = value;
        setFilterData(data);
    };

    const renderSelectorField = (dict, value, selectFrom) => {
        return(
            <FilterField>
                <CodeSelector
                    id={dict.id}
                    value={value || dict.default}
                    label={dict.label}
                    codes={selectFrom}
                    onChange={onChangeCodeFilter}
                />
            </FilterField>
        );
    };

    return(
        <TermMngLogListContainer>
            <HeaderBox>
				<HeaderControl>
					<FormControl variant='outlined' size={AppWord.SMALL}>
						<InputLabel htmlFor="search-TermMngLog-list" style={{backgroundColor:'white'}}>검색</InputLabel>
						<OutlinedInput id="search-TermMngLog-list" onChange={onChangeSearch}
							value={search}
							disabled={records.length === 0}
							size={AppWord.SMALL}
							endAdornment={
								Boolean(search) ?
								<InputAdornment position={AppWord.END}>
									<IconButton
									onClick={()=>{setSearch('')}}
									size={AppWord.SMALL}
									edge={AppWord.END}
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
								:
								null
							}
						/>
					</FormControl>
                    &nbsp;
                    <TextField size={AppWord.SMALL}
                        id="changeDate" value={filterChangeDate} label="변경일자 yyyy[mm[dd]]"
                        onChange={onChangeFilterDate}
                    />
                    &nbsp;
                    {renderSelectorField(InputMap.acNetworkCd, filterData.acNetworkCd, NetworkList) /* 통신사 */}
				</HeaderControl>
            </HeaderBox>
            <FlexyTable
                name="term_mng_log_list"
                uniqueKey="logNid"
                columns={TableColumns}
                records={records}
                search={search}
                filterFunction={filterItem}
                tools={[
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} />}
                ]}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </TermMngLogListContainer>
    );

}