// TermInfoList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlawedList from '../message/FlawedList';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppPalette, AppWord } from '../../model/AppConst';
import { TermInfoTableColumns as TableColumns } from './TermInfoGql';
const LocalTitle = '단말기정보';

const dcPad = AppNumber.dataContainerPadding;
const TermInfoListContainer = styled(Box)({
    flex:1,
    display:"flex", flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

export default function TermInfoList({
	maxHeight,
	maxWidth,
	selected,
    onClickItem,
    onRequestAdd,
    onRequestAddSmartLogis,
    onRequestEdit,
    onRequestRemove,
    onRequestSetState,
    isAdminMode,
    isListMode,
    responseList,
    responseRemove
}) {
    const [filterCriteria, setFilterCriteria] = useState(null);
    const [search, setSearch] = useState('');
    const [itemSelected, setItemSelected] = useState(selected);
    const {loading:loadingList, error:errorOfList, data:dataList} = responseList;
    const clientSize = useClientSize();


    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row, event) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row, event); break;
            case AppWord.CMD_EDIT: onRequestEdit(row, event); break;
            case AppWord.CMD_SETTING: onRequestSetState(row, event); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
		if(onClickItem) onClickItem(item);
    };

    // Handling list event
    if(ValueUtil.flawedListResponse(responseList))
        return <FlawedList title={LocalTitle} gqlResponse={responseList} />;

    return(
        <TermInfoListContainer>
            <HeaderBox>
                <ButtonBox>
                    <Button
                        variant='contained' color='info'
                        onClick={onRequestAddSmartLogis}
                    >
                        <PhonelinkRingIcon />
                        신규 스마트폰
                    </Button>
                    {
                        isAdminMode ?
                        <Button style={{marginLeft:10}}
                            onClick={onRequestAdd}
                            variant='contained' color='primary'>
                            <AddIcon fontSize='small' />
                            단말기 추가
                        </Button>
                        : null
                    }
                    &nbsp;
                </ButtonBox>
                <TextField id="search" onChange={onChangeSearch}
                    value={search}
                    label="검색" size='small'/>
            </HeaderBox>
            <FlexyTable
                name="term_info_list"
                uniqueKey="devId"
                columns={TableColumns}
                records={dataList.termInfoList}
                search={search}
                criteria={filterCriteria}
                tools={isAdminMode ? [
                    {id:AppWord.CMD_SETTING, icon: <SettingsIcon fontSize={AppWord.SMALL} />, title: '상태 변경 하기'},
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL}  color={AppPalette.PrimaryColor} />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL}  color={AppPalette.SecondaryColor} />}
                ] : undefined}
                onClickTool={isAdminMode ? onClickToolOnRow : undefined}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </TermInfoListContainer>
    );

}
