// UserInfoInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette, AppWord } from '../../model/AppConst';
import { UserInfoDictionary } from './UserInfoGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import RadioSelector from '../common/RadioSelector';
import ValueUtil from '../../model/ValueUtil';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;
//const ColumnCount = 2;
//const EditorWidth = ColumnBoxWidth * ColumnCount + ColumnMargin * ColumnCount * 2 + 20;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
});

const InputMap = UserInfoDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.

export default function UserInfoInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    custId,
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    //const [inputData, setInputData] = useState(item ? item : {...defaultInputData}); // 입력한 데이터.
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null); // for response error
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        if(item) {
            const d = {...item};
            d.telno = ValueUtil.reformWith(item.telno, AppWord.NUM_N_HYPN);
            d.mobile = ValueUtil.reformWith(item.mobile, AppWord.NUM_N_HYPN);
            resetData(d);
        }
        else resetData();
    }, [item, open]);

    const resetData = (data) => {
        setInputData(data ? data : defaultInputData);
        setInputError({});
        setChanged(false);
        setHasError(false);
    };

    // 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = {...inputData};
        // add extra data if necessary.
        if(!isEdit) param.custId = custId;

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['사용자 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeNumAndHyphen = (event) => {
        if(event.target.value) {
            if(/[^-0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

    const onChangeRadioSelector = (event) => {
        const data = {...inputData};
        data[event.target.id] = event.target.value;
        setInputData(data);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>
                {isEdit ? '사용자 정보 수정' : '사용자 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                        <FieldBox>
                            <TextField id={InputMap.userId.id}
                                type={InputMap.userId.type}
                                label={InputMap.userId.label}
                                required={InputMap.userId.required}
                                disabled={isEdit}
                                value={inputData.userId}
                                error={inputError.userId}
                                helperText={InputMap.userId.help}
                                onChange={onChangeTextData} />
                        </FieldBox>

                        <FieldBox>
                            <TextField id={InputMap.password.id}
                                type={InputMap.password.type}
                                label={InputMap.password.label}
                                required={InputMap.password.required}
                                value={inputData.password || ''}
                                error={inputError.password}
                                helperText={InputMap.password.help}
                                onChange={onChangeTextData} />
                        </FieldBox>

                        <FieldBox>
                            <TextField id={InputMap.userName.id}
                                type={InputMap.userName.type}
                                label={InputMap.userName.label}
                                required={InputMap.userName.required}
                                value={inputData.userName}
                                error={inputError.userName}
                                helperText={InputMap.userName.help}
                                onChange={onChangeTextData} />
                        </FieldBox>

                    </InputColumn>
                    <InputColumn>
                        <FieldBox>
                            <RadioSelector
                                id={InputMap.classNo.id}
                                label={InputMap.classNo.label}
                                selectFrom={custId===AppWord.ETRACE_CUST_ID ? InputMap.classNo.selectEtrace : InputMap.classNo.select}
                                initialValue={inputData.classNo}
                                onChange={onChangeRadioSelector}
                            />

                        </FieldBox>
                    </InputColumn>

                </EditorContentRow>
                <EditorContentRow>
                    <InputColumn>

                        <FieldBox>
                            <TextField id={InputMap.telno.id}
                                type={InputMap.telno.type}
                                label={InputMap.telno.label}
                                required={InputMap.telno.required}
                                value={inputData.telno || ''}
                                error={inputError.telno}
                                helperText={InputMap.telno.help}
                                onChange={onChangeNumAndHyphen}
                            />
                        </FieldBox>
                        <FieldBox>
                            <TextField id={InputMap.mobile.id}
                                type={InputMap.mobile.type}
                                label={InputMap.mobile.label}
                                required={InputMap.mobile.required}
                                value={inputData.mobile || ''}
                                error={inputError.mobile}
                                helperText={InputMap.mobile.help}
                                onChange={onChangeNumAndHyphen}
                            />
                        </FieldBox>
                    </InputColumn>

                    <InputColumn>
                        <FieldBox>
                            <TextField id={InputMap.email.id}
                                type={InputMap.email.type}
                                label={InputMap.email.label}
                                required={InputMap.email.required}
                                value={inputData.email || ''}
                                error={inputError.email}
                                helperText={InputMap.email.help}
                                onChange={onChangeTextData}
                            />
                        </FieldBox>
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}

/*

            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}} />
    const _onChangeTextData = (event) => {
        const id = event.target.id;
        const value = event.target.value;
        const dataInfo = InputMap[id];

        const e = {...inputError};
        var hasError = false;
        if(!dataInfo.check(value)) {
            e[id] = dataInfo.help;
            hasError = true;
        }
        else e[id] = undefined;

        const data = {...inputData};
        data[id] = value;
    
        if(!hasError) {
            for(const id of Object.keys(InputMap)) {
                if(e[id]) {
                    hasError = true;
                    break;
                }
                else if(InputMap[id].required && !data.hasOwnProperty(id)) {
                    hasError = true;
                    break;
                }
            }
        }

        setInputData(data);
        setInputError(e);
        setHasError(hasError);
        setChanged(true);
    };

*/