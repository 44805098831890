// TermInfoInputSmartLogis.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette } from '../../model/AppConst';
import { TermInfoDictionary } from './TermInfoGql';
import ConfirmDialog from '../message/ConfirmDialog';
import HelpTopic from '../common/HelpTopic';
import ValueUtil from '../../model/ValueUtil';

const ColumnBoxWidth = 300;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    minWidth: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
});

const InputMap = { termId:TermInfoDictionary.inputMap.termId, etc:TermInfoDictionary.inputMap.etc}; // 입력양식 상수, validation
const defaultInputData = {termId:'', etc:''}; // ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.


export default function TermInfoInputSmartLogis({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    time,
    // isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        if(item) {
			const d = {...item};
			d.etc = ValueUtil.nullToString(item.etc);
            resetData(d);
        }
        else resetData(defaultInputData);
        //setInputError({});
        //setChanged(false);
        //setHasError(false);
        //resetData();
    }, [item, time]);

    const resetData = (data) => {
        setInputData(data ? data : defaultInputData);
        setInputError({});
        setChanged(false);
        setHasError(false);
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = {...inputData};
        onClickMutate(param, false);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['단말기 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeNumericText = (event) => {
        if(event.target.value) {
            if(/[^0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

    // render ---------------------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>신규 스마트폰(스마트로지스 단말기) 추가</EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                        <FieldBox>
                            <TextField id={InputMap.termId.id} size="small"
                                type={InputMap.termId.type}
                                label="스마트폰 번호"
                                required={InputMap.termId.required}
                                value={inputData.termId}
                                error={inputError.termId}
                                helperText="안드로이드폰 번호를 숫자만 입력"
                                onChange={onChangeNumericText} />
                        </FieldBox>
                        <FieldBox>
                            <TextField id={InputMap.etc.id} rows={3} multiline size="small"
                                type={InputMap.etc.type}
                                label={InputMap.etc.label}
                                required={InputMap.etc.required}
                                value={inputData.etc}
                                error={inputError.etc}
                                helperText={InputMap.etc.help}
                                onChange={onChangeTextData} />
                        </FieldBox>

                    </InputColumn>
                    <InputColumn width={500}>
                        <HelpTopic id="using-smartphone"
                            title="스마트폰 사용"
                            content={
                                <div>
                                    <p>
                                        위치전송용 단말기를 대신할 스마트폰은 안드로이드폰만을 사용할 수 있습니다. (아이폰, 블랙베리 등은 위치정보전송에 적합하지 않습니다)
                                        등록된 스마트폰을 사용하는 기사님은 해당 스마트폰에서 "Play 스토어"를 실행한 후 <b>스마트로지스</b>를 
                                        받아 설치해야 합니다.
                                    </p>
                                </div>
                            }
                        />
                        <HelpTopic title="서비스 이용료"
                            content={
                                <div>
                                    <p>
                                    등록된 스마트폰에서 위치정보를 받기 시작하면 그 때부터 서비스이용료가 운송사에 부과되므로 유의하시기 바랍니다.
                                    </p>
                                </div>
                            }
                        />
                        <HelpTopic title="차량등록"
                            content={
                                <div>
                                    <p>
                                    차량등록은 스마트폰 등록을 마친 후 '기초정보관리'-'차량목록 및 차량정보관리' 메뉴에서 별도로 등록해야 합니다.
                                    </p>
                                </div>
                            }
                        />
                        <HelpTopic title="스마트폰 번호 입력 주의사항" borderColor={'#ff0000'}
                            content={
                                <div>
                                    <p>
                                    스마트폰 등록 후 스마트폰 정보를 수정하려면 저희 ETRACE로 연락하셔서 수정해야 하므로, 전화번호 입력에 유의해 주십시오.
                                    </p>
                                </div>
                            }
                        />
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}
