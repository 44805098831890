// MyInfo.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import MyInfoInput from './MyInfoInput';
import ChangeMyPassword from './ChangeMyPassword';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { GET_MY_INFO_N_ALARMS, EDIT_USER_INFO, CHANGE_USER_INFO_PASSWORD, UserInfoDictionary } from './UserInfoGql';
import { AppWord, AppObject, AppNumber, AppPalette } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';
import Util from '../../model/Util';

const dcPad = AppNumber.dataContainerPadding;
const UserInfoContainer = styled(Box)({ position:"absolute", top:dcPad, bottom:dcPad, left:dcPad, right:dcPad, display:"flex" });

const WorkBox = styled(Box)({
    flexGrow:1, display:'flex', flexDirection:'column', textAlign:'center', alignItems:'center'
});

const InfoWrapper = styled(Box)({
    display: 'inline-block', textAlign:'left', // backgroundColor:'yellow', // flexGrow:0,
    border: AppPalette.BorderCCC, borderRadius:10, overflow:'auto',
});
const InfoHeader = styled(Box)({
    backgroundColor: AppPalette.HeaderBackground, padding:10, fontWeight:'bold', color:'white'
});
const InfoBody = styled(Box)({
    padding:20,
});
const InfoColumn = styled(Box)({
    display: 'inline-block', verticalAlign: 'top', paddingLeft:50,
    '&:first-of-type': {paddingLeft:0}
});
const InfoLine = styled(Box)({marginBottom:15});
const InfoLabel = styled(Box)({fontSize:'0.85em', color:'#333', marginBottom:5});
const InfoValue = styled(Box)({fontSize:'1.1em', color:'#003'});

const ErrorTitle =UserInfoDictionary.errorTitle;
const InfoDict = UserInfoDictionary.inputMap;
const EditFields = ValueUtil.getFieldsToSubmit(UserInfoDictionary.inputMap, true);

export default function MyInfo() {
    const sessionInfo = useReactiveVar(userInfoRepo);
    const [myInfo, setMyInfo] = useState({});
    const [alarmList, setAlarmList] = useState([]);
    const [openEditor, setOpenEditor] = useState(false);
    const [openPasswordSetter, setOpenPasswordSetter] = useState(false);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);

    // ##### Call GraphQL to get List #####
    const [getMyUserInfo, responseItemToEdit] = useLazyQuery(GET_MY_INFO_N_ALARMS, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation.  ###
    const [editUserInfo, responseEdit] = useMutation( EDIT_USER_INFO, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [chagePassword, resChangePassword] = useMutation( CHANGE_USER_INFO_PASSWORD, {
		onCompleted: (data, option) => onCompleteChangePassword(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.ChPwd})}
	} );

    useEffect(() => {
        getMyUserInfo({variables:{userId: sessionInfo.user.userId}});
    }, [getMyUserInfo, sessionInfo]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteEdit = (data, clientOption) => {
        if(data.userInfoEdit.ok) {
            setOpenEditor(false);
            getMyUserInfo({variables:{userId: sessionInfo.user.userId}});
        }
        else setResponseAlert({open:true, resultData: data.userInfoEdit, title: ErrorTitle.Edit});
    };

    const onCompleteChangePassword = (data, option) => {
        if(data.userInfoChangePassword.ok) {
            setOpenPasswordSetter(false);
            Util.bubbleSnack("암호가 변경되었습니다. 새 암호를 잊지 마세요~!");
        }
        else setResponseAlert({open:true, resultData: data.userInfoChangePassword, title: ErrorTitle.ChPwd});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.userInfoItem) {
            setMyInfo(data.userInfoItem);
        }
        if(data.userGetAlarmList) setAlarmList(data.userGetAlarmList.filter((alarm)=>Boolean(alarm.timeFrom)));
    };

    // +++++++ UI callbacks ++++++++
    const onClickEdit = () => {
        if(responseEdit) responseEdit.reset();
        setOpenEditor(true);
    };

    const onClickChangePassword = () => {
        setOpenPasswordSetter(true);
    };

    // Handler - Submit for mutation fired by UserInfoInput component.
    const onClickMutate = (item) => {
        // item.custId = ''; // testing error callback.
        const userInfo = ValueUtil.refineToSubmit(item);
        userInfo.classNo = sessionInfo.user.classNo;
        const param = {variables: {userInfo: userInfo}};
        editUserInfo(param);
    };

    const onClickMutatePassword = (data) => {
        chagePassword({variables: {userInfo:data}});
    };

    if(ValueUtil.hasAnyAuthError(
        responseEdit, responseItemToEdit, resChangePassword
    )) userInfoRepo(NoUser);

    const renderEditor = () => {
        return(
            <MyInfoInput
                open={openEditor}
                isEdit={true}
                item={myInfo}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={()=>{setOpenEditor(false);}}
                />
        );
    };


    return (
        <UserInfoContainer>
            <WorkBox>
                <InfoWrapper>
                    <InfoHeader>
                        내 사용자 정보
                    </InfoHeader>
                    <InfoBody>
                        <Box>
                            <InfoColumn>
                                <InfoLine>
                                    <InfoLabel>{InfoDict.userId.label}</InfoLabel>
                                    <InfoValue>{myInfo.userId}</InfoValue>
                                </InfoLine>
                                <InfoLine>
                                    <InfoLabel>{InfoDict.userName.label}</InfoLabel>
                                    <InfoValue>{myInfo.userName}</InfoValue>
                                </InfoLine>
                                <InfoLine>
                                    <InfoLabel>{InfoDict.classNo.label}</InfoLabel>
                                    <InfoValue>{myInfo.className}</InfoValue>
                                </InfoLine>
                                <InfoLine>
                                    <InfoLabel>소속</InfoLabel>
                                    <InfoValue>{myInfo.custName}</InfoValue>
                                </InfoLine>
                                <InfoLine>
                                    <InfoLabel>{InfoDict.telno.label}</InfoLabel>
                                    <InfoValue>{myInfo.telno || '없음'}</InfoValue>
                                </InfoLine>
                                <InfoLine>
                                    <InfoLabel>{InfoDict.mobile.label}</InfoLabel>
                                    <InfoValue>{myInfo.mobile || '없음'}</InfoValue>
                                </InfoLine>
                                <InfoLine>
                                    <InfoLabel>{InfoDict.email.label}</InfoLabel>
                                    <InfoValue>{myInfo.email || '없음'}</InfoValue>
                                </InfoLine>
                            </InfoColumn>
                            <InfoColumn>
                                {
                                    alarmList.length===0
                                    ?
                                    <InfoLine>
                                        <InfoLabel>알람 수신 설정</InfoLabel>
                                        <InfoValue>수신하는 알람 없음</InfoValue>
                                    </InfoLine>
                                    :
                                    alarmList.map((alarm,idx) => {
                                        return(
                                            <InfoLine key={idx}>
                                                <InfoLabel>{alarm.alarmType} 알람 수신 설정</InfoLabel>
                                                <InfoValue>
                                                    <NotificationsActiveIcon fontSize={AppWord.SMALL} color={AppPalette.WarnColor}
                                                        style={{marginRight:10, verticalAlign:'bottom'}}
                                                    />
                                                    {alarm.timeFrom.substr(0,2)} ~ {alarm.timeTill.substr(0,2)}시 ({alarm.alarmInterval}분 간격)</InfoValue>
                                            </InfoLine>
                                        );
                                    })
                                }
                            </InfoColumn>
                        </Box>
                        <Box>
                            <Button onClick={onClickEdit} variant={AppPalette.VariantContained}>
                                <NoteAltIcon fontSize={AppWord.SMALL}/> 수정
                            </Button>
                            <Button onClick={onClickChangePassword} variant={AppPalette.VariantContained}
                                style={{marginLeft:10}}
                            >
                                <VpnKeyIcon fontSize={AppWord.SMALL}/> 암호변경
                            </Button>
                        </Box>
                    </InfoBody>
                </InfoWrapper>
            </WorkBox>
            {renderEditor()}
            <ChangeMyPassword
                userInfo={myInfo}
                open={openPasswordSetter}
                onClickMutate={onClickMutatePassword}
                onClose={()=>{setOpenPasswordSetter(false)}}
                responseSaving={resChangePassword}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </UserInfoContainer>
    )
}
/*


*/