// ActLog.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import ActLogList from './ActLogList';
import ActLogListLogin from './ActLogListLogin';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery } from '@apollo/client';
import {
    LIST_ACT_LOG, LIST_ACT_LOG_LOGIN, ActLogDictionary
} from './ActLogGql';
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';
import { TextField } from '@mui/material';
import { FlexySize } from '../common/flexytable/FlexyTableStyled';
import { LoginTableColumns } from './ActLogGql';

const MinCustListBoxWidth = 200;
const DeviderWidth = 4;
const dcPad = AppNumber.dataContainerPadding;
const ActLogContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});

const LoginListBox = styled(Box)({
    display:'flex', flexDirection:'column', minWidth:MinCustListBoxWidth, paddingRight:DeviderWidth,
    boxSizing:'border-box'
});
const DateSelectBox = styled(Box)({display:'flex', alignItems:'center'});
const ActListBox = styled(Box)({
    flexGrow:1, display:'flex', flexDirection:'column', paddingLeft:10, boxSizing:'border-box'
});

const DeviderBox = styled(Box)({
    position: 'absolute',
    width: DeviderWidth,
    minHeight: 50,
    height: '100%',
    background: 'linear-gradient(to right, #aaaaaa, #eeeeee, #aaaaaa)',
    cursor: 'ew-resize',
});

const ErrorTitle =ActLogDictionary.errorTitle;
/**
 * 사용자의 활동 이력을 조회할 수 있는 뷰, 컨트롤러. 2024.1.24.
 */
export default function ActLog() {
    const [date, setDate] = useState(ValueUtil.getYmdText(3600*5));
	const [actLogLoginRecords, setActLogLoginRecords] = useState([]);
	const [actLogRecords, setActLogRecords] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();
    const [leftWidth, setLeftWidth] = useState(window.innerWidth * 0.35 < MinCustListBoxWidth ? MinCustListBoxWidth : window.innerWidth * 0.35);

    // ##### Call GraphQL to get List #####
    const [getActLogList, responseList] = useLazyQuery(LIST_ACT_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getActLogListLogin, responseListLogin] = useLazyQuery(LIST_ACT_LOG_LOGIN, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetListLogin(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.ListLogin})}
    });
    
    // Get sum of width of LoginTableColumns
    const SumOfWidth = LoginTableColumns.reduce((sum, column) => {
        return sum + column.width;
    }, 0) + FlexySize.IndexCellWidth + 20;

    useEffect(() => {
        const ymd = ValueUtil.getYmdText(3600*5);
        getActLogListLogin({variables: {timeFrom: ymd+' 00:00:00', timeTill: ymd+' 23:59:59'}});
    }, []);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetListLogin = (data, clientOption) => {
        if(data.actLogListLogin) setActLogLoginRecords(data.actLogListLogin);
    };

    const onCompleteGetList = (data, clientOption) => {
        if(data.actLogList) setActLogRecords(data.actLogList);
    };

    // +++++++ UI callbacks ++++++++
    const onRequestView = (data) => {
        getActLogList({variables: {sessionKey:data.sessionKey, regUserId: data.regUserId}});
    };

    const onChangeDate = (event) => {
        // console.log('Date selected ' + event.target.value);
        setDate(event.target.value);
        getActLogListLogin({variables: {timeFrom: event.target.value+' 00:00:00', timeTill: event.target.value+' 23:59:59'}});
    };

    // <<<<<<<<<<<<<<<<<<<<<<<<<<< Devider >>>>>>>>>>>>>>>>>>>>>>>>>
    const onMouseMoveDevider = (event) => {
        if (event.buttons === 1) {
            const w = event.clientX - clientSize.dataContainerPadding;
            setLeftWidth(w < MinCustListBoxWidth ? MinCustListBoxWidth : w > SumOfWidth ? SumOfWidth : w);
            event.stopPropagation();
        }
    };

    const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMoveDevider);
        document.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDown = (event) => {
        document.addEventListener('mousemove', onMouseMoveDevider);
        document.addEventListener('mouseup', onMouseUp);
        event.preventDefault();
        event.stopPropagation();
    };

    if(ValueUtil.hasAnyAuthError(
        responseListLogin, responseList
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    return (
        <ActLogContainer>
            <LoginListBox style={{width:leftWidth}}>
                <DateSelectBox>
                    <TextField type='date' value={date} size="small" onChange={onChangeDate} />
                </DateSelectBox>
                <ActLogListLogin
                    records={actLogLoginRecords}
                    onRequestView={onRequestView}
                />
            </LoginListBox>
            <ActListBox>
                <ActLogList
                    records={actLogRecords}
                />
            </ActListBox>
            <DeviderBox onMouseDown={onMouseDown}
                style={{left:leftWidth, top:0}}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </ActLogContainer>
    )
}