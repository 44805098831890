// VehInfoNarrowList.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import FlexyTable from '../common/flexytable/FlexyTable';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import styled from '@emotion/styled';

const TableColumns = [
    //{ id: 'veh_id', label: 'veh_id', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.INTEGER },
    //{ id: 'cust_id', label: 'cust_id', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.TEXT },
	{ id: 'vehPlates', label: '차량번호', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'vehAlias', label: '지도표시명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'gpsTime', label: '최종보고', width: 180, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
	{ id: 'distKm', label: '누적거리', width: 100, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'vehTypeTon', label: '차종-톤수', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'termId', label: '단말기번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehAddr', label: '차고지', width: 100, show: false, option:{align: 'inherit', type:TableColumnType.TEXT} },
	{ id: 'driverName', label: '기사', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'driverMobile', label: '휴대전화', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'termPowerValue', label: '전원공급방식', width: 120, show: true, option:{align: 'inherit', type:TableColumnType.TEXT} },
    { id: 'useYn', label: '사용여부', width: 100, show: true, option:{align: 'center', type:TableColumnType.CHAR, ynBoolean: ['사용 중','사용중지']} },
    { id: 'termEtc', label: '비고(단말기)', width: 300, show: true, option:{align: 'inherit', type:TableColumnType.TEXT} },
    { id: 'custName', label: '운송사', width: 100, show: true, option:{align: 'inherit', type:TableColumnType.TEXT} }, // VEH_AND_TERM
    /* 청소업체 운행통계에 이 코드 사용.
    { id: 'veh01_cd', label: 'veh01_cd', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.INTEGER },
    { id: 'veh02_cd', label: 'veh02_cd', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.INTEGER },
    { id: 'veh03_cd', label: 'veh03_cd', minWidth: 100, show: true, align: 'inherit', type:TableColumnType.INTEGER }*/
];

const VehInfoListContainer = styled(Box)({
    flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ColumnVehPlates = 'vehPlates';

export default function VehInfoNarrowList({
    itemSelected,
    columnList,
    onClickItem,
    records,
    tools,
}) {
    const [columns, setColumns] = useState([ColumnVehPlates]);
    const [search, setSearch] = useState('');

    useEffect(()=>{
        const newCols = [];
        for(const col of TableColumns) {
            if(columnList.includes(col.id)) {
                newCols.push(col);
            }
        }
        if(newCols.length===0) newCols.push(ColumnVehPlates);
        setColumns(newCols);
    }, [columnList]);

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    return(
        <VehInfoListContainer>
            <HeaderBox>
                <TextField id="search" onChange={onChangeSearch}
                    value={search}
                    disabled={records.length === 0}
                    label="차량검색" size={AppWord.SMALL}/>
            </HeaderBox>
            <FlexyTable
                name="veh_info_narrow_list"
                uniqueKey="vehId"
                columns={columns}
                records={records}
                selected={itemSelected ? [itemSelected] : null}
                onClickOneRow={onClickItem}
                search={search}
                tools={tools ? tools : []}
                hideFirstTopButton={true}
            />
        </VehInfoListContainer>
    );

}

