//common/InfoIconButton.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { IconButton, Popover } from '@mui/material';
import { AppPalette, AppWord } from '../../model/AppConst';

const InfoBox = styled(Box)({
    display: 'inline-block', overflow:'auto', backgroundColor:'#f5f5f5', padding:10, maxWidth:400
});

export default function InfoIconButton({
    icon,
    buttonColor,
    text
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
        <IconButton size={AppWord.SMALL}
            color={buttonColor || AppPalette.InfoColor}
            onMouseEnter={(e)=>{setAnchorEl(e.currentTarget)}}
        >
            {icon}
        </IconButton>
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={()=>{setAnchorEl(null)}}
            anchorOrigin={{ vertical: "top", horizontal: "left", }}
            transformOrigin={{ vertical: "top", horizontal: "left", }}
        >
            <InfoBox onMouseLeave={()=>{setAnchorEl(null)}}>{text}</InfoBox>
        </Popover>
        </>
    )
}
