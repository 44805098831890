// CustInfoSelector.js
import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Box } from '@mui/system';
import { Link, Button, IconButton, TextField, Popover, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import { LIST_CUST_INFO } from './CustInfoGql';
import { AppNumber, TableColumnType, AppObject, AppWord, AppPalette } from '../../model/AppConst';
import { custListRepo } from '../../model/CvoModel';
import styled from '@emotion/styled';

const CustSelectorContainer = styled(Box)({ display:"flex", flexDirection:'column', padding:10 });
const HeaderBox = styled(Box)({height: AppNumber.HeaderToolBoxHeight,display:'flex', alignItems:'center'});
const ListBox = styled(Box)({flexGrow:1, border: AppPalette.BorderCCC, borderRadius:5, padding:5, marginTop:5, overflow:'auto'});
const CustRow = styled(Box)({
    cursor:'pointer',
    '&:nth-of-type(odd)': {backgroundColor:'#eee'},
    '&:hover': {color:'blue'}
});
const CellBox = styled(Box)({
    fontSize: '0.9em',
    display:'inline-flex', height:36, alignItems:'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: 5,
});

const TableColumns = [
    { id: 'custId', label: '업체ID', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 150, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'firmTypeValue', label: '가입자구분', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'bizTypeValue', label: '업종', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custStateValue', label: '상태', width: 60, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'telNo', label: '대표전화', width: 140, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function CustInfoSelector({
    popoverHeight,
    onChange,
}) {
    const [cust, setCust] = useState(null);
    const [custList, setCustList] = useState([]);
    const [anchorElement, setAnchorElement] = useState(null);
    const [search, setSearch] = useState('');
    const custListInRepo = useReactiveVar(custListRepo);

    const [getCustInfoList, responseList] = useLazyQuery(LIST_CUST_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)}
    });

    useEffect(()=>{
        if(custListInRepo.length > 0) {
            setCustList(custListInRepo);
        }
        else {
            getCustInfoList();
        }
    }, [custListInRepo, getCustInfoList]);

    // To filter
    const filterRecord = (item) => {
        if(search) {
            const searchText = search.trim();
            if(searchText.length===0) return true;
            for(const c of TableColumns) {
                if(item[c.id] && item[c.id].indexOf(searchText) >= 0) return true;
            }
            return false;
        }
        else return true;
    };
    
    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.custInfoList) {
            setCustList(data.custInfoList);
            custListRepo(data.custInfoList);
        }
    };

    const onClickCustInfo = (custInfo) => {
        setAnchorElement(null);
        setCust(custInfo);
        onChange(custInfo);
    };

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const renderCustRow = (cust) => {
        return(
            <CustRow key={cust.custId} onClick={()=>{onClickCustInfo(cust)}}>
                {
                    TableColumns.map((col) => {
                        return(
                            <CellBox key={col.id} width={col.width}>
                                {
                                    cust[col.id]
                                    ? <span>{cust[col.id]}</span>
                                    : <span>&nbsp;</span>
                                }
                            </CellBox>
                        );
                    })
                }
            </CustRow>
        );
    };

    const name = cust && cust.custName ? cust.custName : '업체 선택';
    const open = Boolean(anchorElement);
    const idForOpen = open ? 'cust-info-selector' : undefined;

    return (
        <>
        <Button
            endIcon={<ExpandMoreIcon fontSize={AppWord.SMALL} />}
            onClick={(e)=>{setAnchorElement(e.currentTarget)}}
        >
            {name}
        </Button>
        <Popover
                id={idForOpen}
                open={open}
                onClose={()=>{setAnchorElement(null)}}
                anchorEl={anchorElement}
                anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
        >
            <CustSelectorContainer height={popoverHeight}>
                <HeaderBox>
                    <FormControl variant='outlined' size='small'>
                        <InputLabel htmlFor="search-BcdNoticeMore-list" style={{backgroundColor:'white'}}>검색</InputLabel>
                        <OutlinedInput id="search-BcdNoticeMore-list" onChange={onChangeSearch}
                            value={search}
                            disabled={custList.length === 0}
                            size={AppWord.SMALL}
                            endAdornment={
                                Boolean(search) ?
                                <InputAdornment position={AppWord.END}>
                                    <IconButton
                                    onClick={()=>{setSearch('')}}
                                    size={AppWord.SMALL}
                                    edge={AppWord.END}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                                :
                                null
                            }
                        />
                    </FormControl>
                </HeaderBox>
                <ListBox>
                    {
                        custList.filter(filterRecord).map((c) => {
                            return(
                                renderCustRow(c)
                            );
                        })
                    }
                </ListBox>
            </CustSelectorContainer>
        </Popover>
        </>
    );
}
