// SelectFromList.js
import React, { useState } from 'react';
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { AppWord } from '../../model/AppConst';

export default function SelectFromList({
    id,
    label,
    list, // select from this
    value, // initial selection if not null/undef
    valueKey, // AKA primary key
    labelKey, // key to get label
    onChange,
    minWidth,
}) {
    //const [value, setValue] = useState(selected);
    const LK = labelKey || 'label';
    const VK = valueKey || 'value';

    const onMenuSelected = (event) => {
        //setValue(event.target.value);
        onChange(id, event.target.value);
    };

    const IdInputLabel = "id-input-label-" + id;
    return(
        <FormControl
        >
            <InputLabel id={IdInputLabel}>{label}</InputLabel>
            <Select
                id={id}
                name={id}
                size={AppWord.SMALL}
                labelId={IdInputLabel}
                value={value || 14}
                onChange={onMenuSelected}
                label={label}
                style={{minWidth: minWidth ? minWidth : 100}}
            >
                {
                    list.map((item,idx) => {
                        return(
                            <MenuItem key={item[VK]} value={item[VK]}>
                                {item[LK]}
                            </MenuItem>
                        );
                    })
                }
            </Select>
        </FormControl>
    );
}
