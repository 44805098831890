// TermStockInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette, CvoCodes, AppWord } from '../../model/AppConst';
import CodeSelector from '../common/CodeSelector';
import { TermStockDictionary } from './TermStockGql';
import { LIST_TERM_MODEL_INFO } from '../term_model_info/TermModelInfoGql';
import ConfirmDialog from '../message/ConfirmDialog';
import { appAlertRepo } from '../../model/CvoModel';
import ValueUtil from '../../model/ValueUtil';
import { useQuery, useReactiveVar } from '@apollo/client';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
});
const InputMap = TermStockDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = {
    ioDate: ValueUtil.getYmdText(),
    modelId:32,
    inCount:0,
    outCount:0,
    ioEtc:''
};

export default function TermStockInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    custId, // If ...
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const appAlerts = useReactiveVar(appAlertRepo);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.
    const [modelList, setModelList] = useState([]);
    const responseModelList = useQuery(LIST_TERM_MODEL_INFO, {
        onCompleted: (data, option) => {onCompleteGetModelList(data, option)}
    });

    useEffect(()=>{
        if(open) {
            if(item) {
                const d = {...item};
                // d.tel = ValueUtil.reformWith(item.tel, AppWord.NUM_N_HYPN);
                setInputData(d);
            }
            else {
                setInputData(defaultInputData);
            }
            setInputError({});
            setChanged(false);
            setHasError(false);
        }
    }, [open, item]);

	const changeInputData = (data) => {
		setInputData(data);
		setChanged(true);
	};

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

    const onCompleteGetModelList = (data, option) => {
        if(data.termModelInfoList) {
            setModelList(data.termModelInfoList.map((obj) => {
                return {value: obj.modelId, label: obj.model}
            }));
        }
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        if(inputData.inCount===0 && inputData.outCount===0) {
            appAlertRepo(ValueUtil.pushToRepoArray(appAlerts, {
                title: "재고 수량 오류", messages: ["입고와 출고 수량 모두가 0일 수는 없습니다."]
            }));
            return;
        }
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // add extra data if necessary.
        if(!isEdit) param.custId = custId;

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);
        changeInputData(newData);
        setInputError(newError);
        setHasError(hasError);
    };

    const onChangeDateData = (event) => {
        const data = {...inputData};
        data[event.target.id] = ValueUtil.isDateFormat(event.target.value) ? event.target.value : '';
        changeInputData(data);
    };

    const onChangeCode = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        changeInputData(data);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size={AppWord.SMALL}
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    const renderSelectorField = (dict, value, selectFrom) => {
        return(
            <FieldBox>
                <CodeSelector
                    id={dict.id}
                    value={value || dict.default}
                    label={dict.label}
                    codes={selectFrom}
                    onChange={onChangeCode}
                />
            </FieldBox>
        );
    };

    const iData = inputData || {...defaultInputData};

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>
                {isEdit ? '단말기 재고 정보 수정' : '단말기 재고 정보 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
						    {renderTextFieldBox(InputMap.ioDate, iData.ioDate,
								inputError.ioDate, onChangeDateData) /* 날짜 */}
                            {renderSelectorField(InputMap.modelId, iData.modelId, modelList) /* 단말기 모델 select */}
                            {renderTextFieldBox(InputMap.inCount, iData.inCount,
								inputError.inCount, onChangeTextData) /* 입고수량 */}
                            {renderTextFieldBox(InputMap.outCount, iData.outCount,
								inputError.outCount, onChangeTextData) /* 출고수량 */}
                            {renderTextFieldBox(InputMap.ioEtc, iData.ioEtc,
								inputError.ioEtc, onChangeTextData) /* 비고 */}

                    </InputColumn>
                    <InputColumn>
                    </InputColumn>
                    <InputColumn>
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}
