// RouteMonthly.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton } from '@mui/material';
import ResponseAlert from '../message/ResponseAlert';
import RouteMonthlyList from './RouteMonthlyList';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LIST_ROUTE_MONTHLY, LIST_ROUTE_MONTHLY_OF_VEH } from './RouteMonthlyGql';
// import { LIST_VEH_INFO } from '../veh_info/VehInfoGql'; // to select a vehicle (vehId)
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { NoUser, userInfoRepo } from '../../model/CvoModel';
import RouteMonthlyListOfVeh from './RouteMonthlyListOfVeh';

const dcPad = AppNumber.dataContainerPadding;
const RouteMonthlyContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});

/**
 * 월별운행통계목록을 가져오기 위해 필요한 각 월의 첫날짜 목록을 만든다.
 * 24개월 전부터 어제 날짜에 해당하는 달의 첫째 날짜까지 24개까지만 만든다.
 * 단, 과거날짜는 2023-01-01일 이전으로 갈 수 없다
 * ValueUtil.getYmdOfDate(date)를 사용하여 날짜 문자열을 만든다.
 */
function getFirstDateList() {
    const lastDate = new Date();
    lastDate.setDate(lastDate.getDate() - 1);
    lastDate.setDate(1);
    const dates = [{
        value:ValueUtil.getYmdOfDate(lastDate),
        label:`${lastDate.getFullYear()}년 ${lastDate.getMonth()+1}월`
    }];
    const dateLimit = new Date(2023, 0, 1);
    for(let i=0; i<23; i++) {
        lastDate.setMonth(lastDate.getMonth() - 1);
        if(lastDate < dateLimit) break;
        dates.push({
            value:ValueUtil.getYmdOfDate(lastDate),
            label:`${lastDate.getFullYear()}년 ${lastDate.getMonth()+1}월`
        });
    }
    return dates;
}

const FirstDateList = getFirstDateList();

export default function RouteMonthly() {
    //const [firstDateList, setFirstDateList] = useState(getFirstDateList());
	const [routeMonthlyRecords, setRouteMonthlyRecords] = useState([]);
    const [routeDate, setRouteDate] = useState(FirstDateList[0].value);
	const [routeMonthlyOfVehRecords, setRouteMonthlyOfVehRecords] = useState([]);
    const [vehDurParam, setVehDurParam] = useState({});
    const [openListOfVeh, setOpenListOfVeh] = useState(false);
    // const [vehInfoList, setVehInfoList] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);

    // ##### Call GraphQL to get List #####
    const [getRouteMonthlyList, responseMonthlyList] = useLazyQuery(LIST_ROUTE_MONTHLY, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetMonthlyList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '월별 운행 통계 목록 가져오기 실패.'})}
    });

    const [getRouteMonthlyOfVehList, responseMonthlyOfVehList] = useLazyQuery(LIST_ROUTE_MONTHLY_OF_VEH, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetMonthlyOfVehList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '월별 운행 통계 목록 가져오기 실패.'})}
    });

    useEffect(() => {
        const lastMonth = FirstDateList[0].value;
        setRouteDate(lastMonth);
        getRouteMonthlyList({variables:{routeDate:lastMonth}});
    }, [getRouteMonthlyList])

    /* const [getVehInfoList, responseVehInfoList] = useLazyQuery(LIST_VEH_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetVehInfoList(data, option)},
		onError: (error) => {
            setResponseAlert({open:true, error: error, title: "차량목록 가져오기 실패."})
        }
    }); */

    // 컴포넌트를 처음 실행하면
    // 1. 하루 이전의 월에 해당하는 데이터를 조회한다.
    // 2. 차량 목록을 조회해서 차량별 데이터 조회를 위한 목록을 준비한다. => 안한다.
    // 만약 삭제한 차량에 대한 차량별 통계조회를 원한다면?
    // - 삭제한 차량에 대해서는 영영 조회할 수 없게 한다. 통계정보를 보고 싶다면 차량을 삭제하지 않도록 유도하는 호과.
    // - 만일 보여주려 한다면 삭제한 차량들에 대해 별도의 목록을 유지할 수 있는 테이블을 만들어야 한다. 옳은가?
    // 월별 통계에는 없지만 차량목록(VEH_INFO table)에는 있는 차량은 어떻게 처리할까?
    // - 일단 생각하지 말자. 차량목록에 있는 차량은 월별 통계에도 있어야 한다.
    // - 그렇다면 차량 목록은 가져올 필요가 없다.

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    // const onCompleteGetVehInfoList = (data, clientOption) => { if(data.vehInfoList) setVehInfoList(data.vehInfoList); };

    const onCompleteGetMonthlyList = (data, clientOption) => {
        console.log(data);
        if(data.routeMonthlyList) setRouteMonthlyRecords(data.routeMonthlyList);
    };

    const onCompleteGetMonthlyOfVehList = (data, clientOption) => {
        if(data.routeMonthlyListOfVeh) {
            setRouteMonthlyOfVehRecords(data.routeMonthlyListOfVeh);
            setOpenListOfVeh(true);
        }
    };

    const onChangeRouteDate = (routeDate) => {
        setRouteDate(routeDate);
        getRouteMonthlyList({variables:{routeDate:routeDate}});
    };

    const onRequestListOfVeh = (param) => {
        console.log('onRequestListOfVeh', param);
        setVehDurParam(param);
        getRouteMonthlyOfVehList({variables:param});
    };

    if(ValueUtil.hasAnyAuthError(
        responseMonthlyList, responseMonthlyOfVehList/*, responseVehInfoList,  */
    )) userInfoRepo(NoUser);

    return (
        <RouteMonthlyContainer>
            <RouteMonthlyList
                routeDate={routeDate}
                monthList={FirstDateList}
                onChangeMonth={onChangeRouteDate}
                records={routeMonthlyRecords}
                onRequestListOfVeh={onRequestListOfVeh}
            />
            <RouteMonthlyListOfVeh
                open={openListOfVeh}
                param={vehDurParam}
                records={routeMonthlyOfVehRecords}
                onClose={() => {setOpenListOfVeh(false)}}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </RouteMonthlyContainer>
    );
}