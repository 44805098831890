// TextCombo.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Popover } from '@mui/material';
import { AppPalette, AppWord } from '../../model/AppConst';

const TextComboContainer = styled('div')({display:'inline-block'});

const PopDataBox = styled(Box)({
    width:300, backgroundColor:'white', borderRadius:5, padding:10,
    maxHeight:400, 
    border: AppPalette.BorderCCC,
    display:'flex', flexDirection:'column'
});

const ListBox = styled(Box)({
    borderTop: AppPalette.BorderCCC, padding:10, flexGrow:1, overflow:'auto'
});

const RowBox = styled(Box)({
    display:'flex', alignItems:'center', padding:10, cursor:'pointer',
    '&:hover': {backgroundColor:'#eef'},
});

/**
 * Autocomplete 사용하려다가 짜증나서 만들었음. Text 콤보로만 사용.
 * @returns Combobox like component
 */
export default function TextCombo({
    id,
    label,
    text,
    selectFrom, // array of object to select from.
    uniqueKey, // unique key in selectFrom
    onChange, // onChange(id, value);
    size,
    error,
}) {
    const [textOfCombo, setTextOfCombo] = useState('');
    const [comboAnchor, setComboAnchor] = useState(null);
    //const [showPopover, setShowPopover] = useState(false);


    useEffect(()=>{
        if(text) setTextOfCombo(text);
        else setTextOfCombo('');
    }, [text]);

    const onChangeTextOfCombo = (event) => {
        setTextOfCombo(event.target.value);
        onChange(id, event.target.value);
    };

    const onClickRecord = (row) => {
        const txt = row[uniqueKey];
        setTextOfCombo(txt);
        setComboAnchor(null);
        onChange(id, row[uniqueKey])
    };

    const onFocusMainTextField = (event) => {
        if(comboAnchor)

        setComboAnchor(event.currentTarget);
        //setShowPopover(true);
    };

    const InnerTextFieldID = "whoisthebestuserinthisserviceitsyou" + id;

    const onFocusPopover = (event) => {
        document.getElementById(InnerTextFieldID).focus();
    };

    const filterFunc = (row) => {
        if(textOfCombo) {
            if(textOfCombo.length>0) {
                if(row[uniqueKey].indexOf(textOfCombo)>=0) return true;
                else return false;
            }
        }
        return true;
    };

    return (
        <TextComboContainer>
        <TextField
            id={id} label={label}
            onClick={onFocusMainTextField}
            value={textOfCombo}
            size={size || AppWord.SMALL}
            error={error}
        />
        <Popover
            open={Boolean(comboAnchor)}
            anchorEl={comboAnchor}
            onClose={()=>setComboAnchor(null)}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            onFocus={onFocusPopover}
        >
            <PopDataBox>
                <TextField
                    id={InnerTextFieldID} label={label}
                    fullWidth
                    onChange={onChangeTextOfCombo}
                    value={textOfCombo}
                    size={size || AppWord.SMALL}
                />
                <ListBox style={{backgroundColor:'white'}}>
                    {
                        selectFrom.filter(filterFunc).map((c)=>{
                            return(
                                <RowBox key={c[uniqueKey]}
                                    onClick={()=>onClickRecord(c)}
                                >
                                    {c[uniqueKey]}
                                </RowBox>);
                        })
                    }
                </ListBox>
            </PopDataBox>
        </Popover>
        </TextComboContainer>
    )
}
