// CustPoiTypeList.js
import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import {userInfoRepo} from '../../model/CvoModel';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppPalette, AppWord, MapValue, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { CustPoiTypeDictionary } from './CustPoiTypeGql';

const CustPoiTypeListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
	display:'flex', alignItems:'center', justifyContent:AppWord.END, height:AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});

const Dict = CustPoiTypeDictionary.inputMap;
const TableColumns = [
	//{ id: 'custId', label: Dict.custId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'poiType', label: Dict.poiType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    {
        id: 'iconSaveName', label: Dict.iconSeq.label, width: 50, show: true,
        option:{
            align:'center', type: TableColumnType.GRAPH,
            form: (rec, rowIndex) => {
                return <img src={`${MapValue.POI_ICON_DIR}/${rec.iconSaveName}`} alt={rec.iconName} />;
            }
        }
    },
    { id: 'typeName', label: Dict.typeName.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'typeDesc', label: Dict.typeDesc.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'groupNid', label: Dict.groupNid.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'viewLevel', label: Dict.viewLevel.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    //{ id: 'iconSeq', label: Dict.iconSeq.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    // ${MapValue.POI_ICON_DIR}/${poi.iconSaveName
    {
        id: 'fontColor', label: '색깔지정', width: 100, show: true,
        option: {
            align: 'center', type: TableColumnType.GRAPH,
            form: (rec, rowIndex) => {
                return(
                    <div style={{
                        width:80, padding:5, color:rec.fontColor, backgroundColor: rec.backColor, textAlign:'center',
                        borderRadius:5, border: AppPalette.BorderCCC
                    }}>
                        지점명
                    </div>
                );
            }
        }
    },
    //{ id: 'fontSize', label: Dict.fontSize.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} }
];

export default function CustPoiTypeList({
	records,
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);

	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
		if(onClickItem) onClickItem(item);
    };

    return(
        <CustPoiTypeListContainer>
            <HeaderBox>
				{
					isAdmin
					?
						<Button
							onClick={onRequestAdd}
							variant={AppPalette.VariantContained} color={AppPalette.PrimaryColor}>
							<AddIcon fontSize={AppWord.SMALL} />
							추가
						</Button>
					: null
				}
            </HeaderBox>
            <FlexyTable
                name="cust_poi_type_list"
                uniqueKey="poiType"
                hideFirstTopButton={true}
                columns={TableColumns}
                records={records}
                tools={isAdmin ? [
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} />}
                ] : []}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
            />
        </CustPoiTypeListContainer>
    );

}
