// VehGroupBcdMap.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Button, Checkbox, TextField,
    FormControl, FormControlLabel, FormLabel, Radio, RadioGroup
} from '@mui/material';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import VehGroupBcdMapList from './VehGroupBcdMapList';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    LIST_VEH_GROUP_POI_AND_MAP, SET_VEH_GROUP_BCD_MAP, REMOVE_VEH_GROUP_BCD_MAP,
	VehGroupBcdMapDictionary
} from './VehGroupBcdMapGql';
import VehGroupBcdMapInput from './VehGroupBcdMapInput';
import GroupVehAlloc from '../group_veh_alloc/GroupVehAlloc';
import ExpanTree from '../common/ExpanTree/ExpanTree';
import HelpTopic from '../common/HelpTopic';
import PoiInfoList from '../poi_info/PoiInfoList';
import VehGroupNode from '../../model/VehGroupNode';
import { NumCode, AppObject, AppNumber, AppPalette, AppWord } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const YouCanSeeMarkIcon = <WhereToVoteIcon fontSize='small' color='primary' style={{verticalAlign:'bottom'}}/>;

const dcPad = AppNumber.dataContainerPadding;
const VehGroupBcdMapContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});

// in ..Container
const MappingBoxWidth = 400;
const MappingBox = styled(Box)({display:"flex", paddingRight:dcPad});
const FactorBox = styled(Box)({flexGrow:1, display:"flex", flexDirection:"column", height:"100%", paddingLeft:dcPad});

// in FactorBox
const OptionBox = styled(Box)({
    height:AppNumber.HeaderToolBoxHeight, //backgroundColor:'#f9f9f9',
    display:'flex', alignItems:AppWord.END, justifyContent:AppWord.END, marginBottom: dcPad, paddingRight: dcPad
});
const PoiAndGroup = styled(Box)({flexGrow:1, display:"flex"});
const InfoBox = styled(Box)({padding:10, border:AppPalette.BorderCCC, borderRadius:10, marginTop: dcPad, display:'flex'});

// in PoiAndGroup
const PoiListBox = styled(Box)({display:'flex', marginRight:dcPad});
const GroupTreeSet = styled(Box)({flexGrow:1, display:'flex', flexDirection: 'column', marginLeft: dcPad, paddingTop: AppNumber.SmallBoxPadding});

// in GroupTreeSet
const BoldTitle = styled(Box)({fontWeight:'bold', padding:AppNumber.SmallBoxPadding, fontSize: '1.05rem', height: AppNumber.HeaderToolBoxHeight});
const VehGroupsBox = styled(Box)({flexGrow: 1,  overflow: 'auto', border: AppPalette.BorderCCC, padding: 15, borderRadius: 10});


const InputMap = VehGroupBcdMapDictionary.inputMap;
const ErrorTitle =VehGroupBcdMapDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(VehGroupBcdMapDictionary.inputMap, true);
const EmptyItem = {bcdEventCd:'BOTH', bcdBorderCd: 'LOCATION'};

export default function VehGroupBcdMap({
    maxWidth,
    maxHeight,
}) {
    const sessionInfo = useReactiveVar(userInfoRepo);
    const [vehGroupBcdMapList, setVehGroupBcdMapList] = useState([]);
    const [mapCriteria, setMapCriteria] = useState(null);
    const [vehGroupTreeData, setVehGroupTreeData] = useState([]);
    const [poiInfoList, setPoiInfoList] = useState([]);
    const [poiSelected, setPoiSelected] = useState(null); // when poi list clicked
    const [groupSelected, setGroupSelected] = useState(null); // when group tree clicked
    const [foundMapping, setFoundMapping] = useState(false); // poiSelected, groupSelected 의 감지설정 발견됨.
    const [showVehicles, setShowVehicles] = useState(false);
    const [filterOnSelect, setFilterOnSelect] = useState(false);
    const [editorMode, setEditorMode] = useState(NumCode.NULL); // 3가지 상태로 늘려서 그룹내 소속 차량 확인, 수정할 수 있도록 하자.
    const [itemInEditor, setItemInEditor] = useState(EmptyItem);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    // const responseList = useQuery(GET_VEH_GROUP_BCD_MAP_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});
    const clientSize = useClientSize();

    useEffect(()=>{
        let found = false;
        if(poiSelected && groupSelected) {
            for(const map of vehGroupBcdMapList) {
                if(map.poiId===poiSelected.poiId && groupSelected.groupId===map.groupId) {
                    found = true;
                    break;
                }
            }
            setFoundMapping(found);
        }
    }, [poiSelected, groupSelected, vehGroupBcdMapList]);

    // ##### Call GraphQL to get List #####
    const [getVehGroupPoiAndMapList, responseList] = useLazyQuery(LIST_VEH_GROUP_POI_AND_MAP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    
    // ##### GraphQL Mutation.
    const [setVehGroupBcdMap, responseSetting] = useMutation( SET_VEH_GROUP_BCD_MAP, {
		onCompleted: (data, option) => onCompleteSet(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [removeVehGroupBcdMap, responseRemove] = useMutation( REMOVE_VEH_GROUP_BCD_MAP, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getVehGroupPoiAndMapList({variables:{userId:sessionInfo.userAs.userId}});
    }, [getVehGroupPoiAndMapList, sessionInfo]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.vehGroupBcdMapList) {
            setVehGroupBcdMapList(data.vehGroupBcdMapList);
        }
        if(data.vehGroupListForUser) {
            setVehGroupTreeData(VehGroupNode.makeTree(data.vehGroupListForUser));
        }
        if(data.poiInfoList) {
            setPoiInfoList(data.poiInfoList);
        }
    };

    const onCompleteSet = (data, clientOption) => {
        if(data.vehGroupBcdMapSet.ok) {
            setEditorMode(NumCode.NULL);
            setItemInEditor(EmptyItem);
            setVehGroupBcdMapList(data.vehGroupBcdMapSet.list);
        }
        else setResponseAlert({open:true, resultData: data.vehGroupBcdMapSet, title: ErrorTitle.Add});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.vehGroupBcdMapRemove.ok) setVehGroupBcdMapList(data.vehGroupBcdMapRemove.list);
        else setResponseAlert({open:true, resultData: data.vehGroupBcdMapRemove, title: ErrorTitle.Remove});
    };

    // +++++++ UI callbacks ++++++++
    const onRequestEdit = (item) => {
        setItemInEditor(item);
        setEditorMode(NumCode.EDIT);
    };

    const onClickNewSetting = () => {
        if(poiSelected && groupSelected) {
            // Check if it exists already.
            for(const rec of vehGroupBcdMapList) {
                if(rec.poiId===poiSelected.poiId && rec.groupId===groupSelected.groupId) {
                    onRequestEdit(rec);
                    return;
                }
            }
            const item = {
                ...EmptyItem,
                poiId: poiSelected.poiId, poiName: poiSelected.poiName,
                groupId:groupSelected.groupId, groupName:groupSelected.groupName
            };
            if(responseSetting) responseSetting.reset();
            setItemInEditor(item);
            setEditorMode(NumCode.NEW);
        }
    };

    const onCancelSettingMap = () => {
        setEditorMode(NumCode.NULL);
        setItemInEditor(EmptyItem);
    };

    const onRequestRemove = (item) => {

        setPromptToConfirm({
            data: item,
            title: '출발도착감지설정 삭제',
            messages: [
                item.poiName + ' 지점에 대한 ' + item.groupName + ' 그룹 차량의 출발, 도착 감지설정을 삭제하시겠습니까?',
                '해당 설정 정보는 삭제되고 그에 따른 감지에 따른 알림, 추가 데이터 생성등은 더 이상 발생하지 않습니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{vehGroupBcdMap:{groupId: data.groupId,poiId: data.poiId}}};
                    removeVehGroupBcdMap(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by VehGroupBcdMapInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {vehGroupBcdMap: ValueUtil.refineToSubmit(item)}};
        setVehGroupBcdMap(param);
    };

    const onGroupClicked = (group) => {
        setGroupSelected(group.children.length===0 ? group : null);
        setShowVehicles(true);
        if(filterOnSelect) setMapCriteria({groupId:[group.groupId]});
    };

    const onPoiClicked = (poi) => {
        setPoiSelected(poi);
        if(filterOnSelect) setMapCriteria({poiId: [poi.poiId]});
    }

    if(ValueUtil.hasAnyAuthError(responseList, responseSetting, responseRemove)) userInfoRepo(NoUser);

	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        if(isAdmin)
            return(
                <VehGroupBcdMapInput
                    open={editorMode === NumCode.NEW || editorMode === NumCode.EDIT}
                    onClickMutate={onClickMutate}
                    onClose={onCancelSettingMap}
                    item={itemInEditor}
                    isEdit={editorMode === NumCode.EDIT}
                    responseSaving={responseSetting}
                />
            );
        else return null
    };

    const isAllowedForGroup = () => {
        if(isAdmin) return true;
        if(groupSelected && groupSelected.userId===sessionInfo.userAs.userId) return true;
        return false;
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - dcPad * 2;
    const PoiAndGroupWidth = width - MappingBoxWidth;// - dcPad; // ValueUtil.getNumberInRange(width*0.25, 150, 300);
    const PoiListWidth = PoiAndGroupWidth / 2;
    const InfoBoxHeight = showVehicles ? 380 : 260;

    const renderInfoBox = ()  => {
        if(showVehicles) {
            return(
                <InfoBox height={InfoBoxHeight}>
                    <GroupVehAlloc
                        groupId={groupSelected.groupId} groupName={groupSelected.groupName}
                        onCloseView={()=>{setShowVehicles(false)}}/>
                </InfoBox>
            );
        }
        else {
            return(
                <InfoBox height={InfoBoxHeight}>
                    <HelpTopic
                        title="지정된 지점에 특정 그룹의 차량에 대한 출발, 도착 감지"
                        content={
                            <ul>
                                <li>지점을 지정하여 특정 그룹의 차량들이 도착(진입)하거나 출발(진출)하는 것을 감지하도록 설정합니다.</li>
                                <li>지점과 그룹을 클릭하고 감지설정추가 버튼을 클릭하여 새로운 설정을 추가합니다.</li>
                                <li>좌측 현재 감지 설정 목록의 아이콘을 클릭하여 내용을 수정하거나 삭제할 수 있습니다.</li>
                                <li>관리자가 아닌 경우에는 감지설정을 추가, 변경 삭제할 수 없습니다.</li>
                                <li>특정 차량이 지정한 그룹에 속해있는지 확인하려면 그룹을 클릭했을 때 나타나는 목록을 참조합니다.</li>
                            </ul>
                        }
                    />
                </InfoBox>
            );
        }
    };

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <VehGroupBcdMapContainer>
            <MappingBox width={MappingBoxWidth}>
                <VehGroupBcdMapList
                    title="현재 감지 설정"
                    maxHeight={height}
                    maxWidth={MappingBoxWidth}
                    mapList={vehGroupBcdMapList}
                    onRequestRemove={onRequestRemove}
                    onRequestEdit={onRequestEdit}
                    poiSelected={poiSelected}
                    groupSelected={groupSelected}
                    criteria={mapCriteria}
                />
            </MappingBox>
            <FactorBox>
                <OptionBox>

                    <FormControlLabel
                        label="지점, 그룹 선택시 감지설정 목록 필터링 하기"
                        control={
                            <Checkbox color={AppPalette.PrimaryColor}
                                value={filterOnSelect}
                                onChange={(e)=>{setFilterOnSelect(e.target.checked); setMapCriteria(null)}
                        } />}
                    />
                    <Button
                        onClick={onClickNewSetting}
                        disabled={!isAdmin || !Boolean(groupSelected) || !Boolean(poiSelected) || !isAllowedForGroup() || foundMapping}
                        variant='contained' color={AppPalette.PrimaryColor}
                    >
                        선택한 지점, 그룹에 감지설정추가
                    </Button>
                </OptionBox>
                <PoiAndGroup height={height - InfoBoxHeight - AppNumber.HeaderToolBoxHeight - dcPad * 3}>
                    <PoiListBox width={PoiListWidth}>
                        <PoiInfoList
                            title="전체 지점"
                            records={poiInfoList}
                            maxWidth={PoiListWidth}
                            maxHeight={height - InfoBoxHeight - AppNumber.HeaderToolBoxHeight - dcPad * 3}
                            onClickItem={onPoiClicked}
                        />
                    </PoiListBox>
                    <GroupTreeSet>
                        <BoldTitle>전체 차량그룹</BoldTitle>
                        <VehGroupsBox>
                            <ExpanTree
                                data={vehGroupTreeData}
                                onLeafClicked={onGroupClicked}
                                exclusive={true}
                                markOption={
                                    {
                                        keyToCheck: 'userId',
                                        marker: YouCanSeeMarkIcon
                                    }
                                }
                            />
                        </VehGroupsBox>
                    </GroupTreeSet>
                </PoiAndGroup>
                {renderInfoBox()}
            </FactorBox>
            {renderPromptToConfirmBox()}
            {renderEditor()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehGroupBcdMapContainer>
    )
}
