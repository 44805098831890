import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const CustPeopleDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        custId : { id: 'custId', label: '업체지정', required: true, toAdd: true, toEdit: true,},
        recId : { id: 'recId', label: '담당자 지정', type: 'number', required: true, toAdd: false, toEdit: true, },
        roleCd : {
            id: 'roleCd', label: '담당역할 지정', type: 'select', required: false,
            toAdd: true, toEdit: true, default: 'GENERAL',
            check: (value) => {return true},
        },
        pName : {
            id: 'pName', label: '담당자', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '20자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.textInRange(value,1,20)},
        },
        telNo : {
            id: 'telNo', label: '연락처', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,15)},
        },
        dept : {
            id: 'dept', label: '부서', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '20자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,20)},
        },
        jobRank : {
            id: 'jobRank', label: '직급', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '30자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,30)},
        },
        roleDetail : {
            id: 'roleDetail', label: '업무', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '100자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,100)},
        },
        mobile : {
            id: 'mobile', label: '휴대전화', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,128)},
        },
        email : {
            id: 'email', label: '메일주소', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '40자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,256)},
        },
        messenger : {
            id: 'messenger', label: '메신저', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '120자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,120)},
        },
        pMemo : {
            id: 'pMemo', label: '메모', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '6000자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,6000)},
        },
        faxNo : {
            id: 'faxNo', label: '팩스', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,15)},
        },
        addr : {
            id: 'addr', label: '주소', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '150자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,150)},
        },
        zipCd : {
            id: 'zipCd', label: '우편번호', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,7)},
        },
    },
    errorTitle: {
        Add: '담당자 정보 생성 오류',
        Edit: '담당자 정보 수정 오류',
        Remove: '담당자 정보 삭제 오류',
        Get: '담당자 정보 조회 오류',
        List: '담당자 목록 조회 오류',
    }
};

const CUST_PEOPLE_LIST_PART = gql`
fragment CustPeopleListPart on CustPeople {
    custId
    recId
    custName
    roleCd
    roleValue
    pName
    telNo
    dept
    jobRank
    roleDetail
    mobile
    email
    messenger
    pMemo
    faxNo
    addr
    zipCd
    regUserId
    regTime
}
`;


export const LIST_CUST_PEOPLE = gql`
${CUST_PEOPLE_LIST_PART}
query ListCustPeople($custId:String!) {
    custPeopleList(custId:$custId) {
        ...CustPeopleListPart
    }
}
`;

export const GET_CUST_PEOPLE = gql`
${CUST_PEOPLE_LIST_PART}
query GetCustPeople($custId:String!, $recId:Int!) {
    custPeopleItem(custId:$custId, recId:$recId) {
        ...CustPeopleListPart
    }
}
`;

export const ADD_CUST_PEOPLE = gql`
${CUST_PEOPLE_LIST_PART}
mutation AddCustPeople($custPeople:InputCustPeopleAdd!) {
    custPeopleAdd(custPeople: $custPeople) {
        ok
        message
        list {
            ...CustPeopleListPart
        }
    }
}
`;

export const EDIT_CUST_PEOPLE = gql`
${CUST_PEOPLE_LIST_PART}
mutation EditCustPeople($custPeople:InputCustPeopleEdit!) {
    custPeopleEdit(custPeople: $custPeople) {
        ok
        message
        list {
            ...CustPeopleListPart
        }
    }
}
`;

export const REMOVE_CUST_PEOPLE = gql`
${CUST_PEOPLE_LIST_PART}
mutation RemoveCustPeople($custPeople:InputCustPeopleRemove!) {
    custPeopleRemove(custPeople: $custPeople) {
        ok
        message
        list {
            ...CustPeopleListPart
        }
    }
}
`;