// RouteDailyList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button, IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ResponseAlert from '../message/ResponseAlert';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType } from '../../model/AppConst';
import { LIST_ROUTE_DAILY } from './RouteDailyGql';
import { useLazyQuery } from '@apollo/client';

const dcPad = AppNumber.dataContainerPadding;
const RouteDailyListContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection:'column'
	//flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display: 'flex',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    alignItems: 'center',
});

const ColumnCustName = { id: 'custName', label: '업체명', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} };

const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'totalDist', label: '총 주행거리', width: 120, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    ColumnCustName,
    //{ id: 'routeDate', label: Dict.routeDate.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATE} },
    //{ id: 'custId', label: Dict.custId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'termId', label: Dict.termId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'secondsRun', label: Dict.secondsRun.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
];

function makeDateId(date) {return 'date' + date.replaceAll("-","")}

function makeRecordsForVehicles(array) {
    // array of routeDailyList
    // const columnAndRecords = {columns:[], records: []};
    const columns = TableColumns.slice(0,2);
    const temprec = [];
    const vehMap = {};
    const dateMap = {};

    for(const rec of array) {
        const vehId = rec.vehId;
        const dateId = makeDateId(rec.routeDate);
        if(!dateMap[dateId]) dateMap[dateId] = rec.routeDate;
        let veh;
        if(vehMap[vehId]) {
            veh = vehMap[vehId];
        }
        else {
            veh = {vehPlates: rec.vehPlates, totalDist:0, custName: rec.custName};
            vehMap[vehId] = veh;
            temprec.push(veh);
        }
        veh[dateId] = rec.totalDist;
    }
    const dateIds = Object.keys(dateMap).sort();

    for(const rec of temprec) {
        let dist = 0;
        for(const d of dateIds) {
            dist += rec[d];
        }
        rec.totalDist = dist;
    }

    for(const did of dateIds) {
        columns.push({ id: did, label: dateMap[did], width: 100, show: true, option:{align: 'right', type: TableColumnType.FLOAT } })
    }
    columns.push(ColumnCustName);
    return {columns:columns, records:temprec};
}


export default function RouteDailyList({
	maxHeight,
	maxWidth,
}) {
    const [dateRange, setDateRange] = useState({dateFrom: ValueUtil.getYmdText(86400*7), dateTill: ValueUtil.getYmdText(86400)});
    const [columns, setColumns] = useState(TableColumns.slice());
    const [records, setRecords] = useState([]);
    //const [filterCriteria, setFilterCriteria] = useState(null);
    const [search, setSearch] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getRouteDailyList, responseList] = useLazyQuery(LIST_ROUTE_DAILY, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetDailyList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "누적거리통계 조회 오류"})}
    });

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetDailyList = (data, clientOption) => {
        if(data.routeDailyList) {
            const columnAndRecords = makeRecordsForVehicles(data.routeDailyList);
            setColumns(columnAndRecords.columns);
            setRecords(columnAndRecords.records);
        }
    };

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };
    // 13:53 (3333) 135457

    const onChangeDate = (event) => {
        const range = {...dateRange};
        range[event.target.id] = event.target.value;
        setDateRange(range);
    };

    const onClickGetList = () => {
        const from = ValueUtil.parseDate(dateRange.dateFrom);
        const till = ValueUtil.parseDate(dateRange.dateTill);
        let param;
        if(from && till) {
            if(from.getTime() <= till.getTime()) param = {...dateRange};
            else param = {dateFrom: dateRange.dateTill, dateTill: dateRange.dateFrom};
            getRouteDailyList({variables: param});
        }
    };

    return(
        <RouteDailyListContainer>
            <HeaderBox>
                <span>조회날짜범위 &nbsp;</span>
                <TextField id='dateFrom' type='date' value={dateRange.dateFrom} size={AppWord.SMALL} onChange={onChangeDate} />
                ~
                <TextField id='dateTill' type='date' value={dateRange.dateTill} size={AppWord.SMALL} onChange={onChangeDate} />
                &nbsp;
                <Button size={AppWord.SMALL}
                    onClick={onClickGetList}
                    variant='contained' color="primary"
                    disabled={!Boolean(dateRange.dateFrom) || !Boolean(dateRange.dateTill)}
                >
                    조회
                </Button>
                &nbsp;&nbsp;
				<FormControl variant='outlined' size={AppWord.SMALL}>
					<InputLabel htmlFor="search-RouteDaily-list" style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id="search-RouteDaily-list" onChange={onChangeSearch}
						value={search}
						disabled={records.length === 0}
						size={AppWord.SMALL}
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
            </HeaderBox>
            <FlexyTable
                name="route_daily_list"
                uniqueKey="vehId"
                columns={columns}
                records={records}
                search={search}
                tools={[]}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </RouteDailyListContainer>
    );

}
