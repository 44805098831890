// route_hourly/RouteHourlyGql.js
import { gql } from "@apollo/client";

/*
    termId
*/

export const LIST_ROUTE_HOURLY = gql`
query ListRouteHourly($date:String) {
    routeHourlyList(date:$date) {
        vehId
        vehPlates
        vehAlias
        custName
        vehTonId
        vehTypeId
        vehTypeTon
        timeStart
        hourStart
        gpsTime
        custId
        secondsRun
        totalDist
        avgSpeed
        avgSpeedRun
        maxSpeed
        avgCycleMake
        avgCycleReport
        positionCount
    }
}
`;



export const LIST_ROUTE_HOURLY_OFF = gql`
query ListRouteHourlyOff($date:String) {
    routeHourlyListOff(date:$date) {
        vehId
        routeDate
        custId
        custName
        vehPlates
        vehAlias
        vehTonId
        vehTypeId
        vehTypeTon
        driverName
        driverMobile
        totalDist
        allowedDist
        distNotAllowed
        driveCanStart
        driveMustEnd
    }
}
`;
