// route_daily_bill/RouteDailyBillGql.js
import { gql } from "@apollo/client";

export const LIST_ROUTE_DAILY_BILL = gql`
query ListRouteDailyBill($routeDate: String) {
    routeDailyBillList(routeDate:$routeDate) {
        vehId
        routeDate
        daysUsed
        positionCount
        custId
        custName
        vehPlates
        gpsTime
        devId
        termId
        model
        serialNo
        billAmount
        termStateCd
        openDate
        termStateTime
        etc
        networkValue
        payTermValue
        termStateValue
    }
}
`;
