// group_veh_alloc/GroupVehAllocGql.js
import { gql } from "@apollo/client";


export const LIST_VEH_INFO_OVER_GROUP = gql`
query ListGroupVehAlloc($groupId:Int!) {
    vehInfoListOverGroup(groupId:$groupId) {
        vehId
        custId
        vehPlates
        groupId
        driverName
    }
}
`;

export const ADD_GROUP_VEH_ALLOC = gql`
mutation AddGroupVehAlloc($groupVehAlloc:InputGroupVehAllocPair!) {
  groupVehAllocAdd(groupVehAlloc: $groupVehAlloc) {
    ok
    message
    list {
        vehId
        custId
        vehPlates
        groupId
        driverName
    }
  }
}
`;

export const REMOVE_GROUP_VEH_ALLOC = gql`
mutation RemoveGroupVehAlloc($groupVehAlloc:InputGroupVehAllocPair!) {
  groupVehAllocRemove(groupVehAlloc: $groupVehAlloc) {
    ok
    message
    list {
        vehId
        custId
        vehPlates
        groupId
        driverName
    }
  }
}
`;

export const EMPTY_GROUP_VEH_ALLOC = gql`
mutation EmptyGroupVehAlloc($groupId:Int!) {
  groupVehAllocEmpty(groupId: $groupId) {
    ok
    message
    list {
        vehId
        custId
        vehPlates
        groupId
        driverName
    }
  }
}
`;
