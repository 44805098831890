// NaverRouteResults.js
import React, {useEffect, useState} from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Popover, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import ClearIcon from '@mui/icons-material/Clear';
import { AppPalette, AppWord } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';

const MinimalContainer = styled(Box)({display:'inline',marginLeft:10});

const PopControlBox = styled(Box)({
    minWidth:400, padding:10, borderRadius: 5,
    backgroundColor: '#f6f9ff', border: "1px solid "+AppPalette.PrimaryRGB,
    display:'flex', flexDirection:'column'
});

const SpeedGuide = styled(Box)({
    fontSize:'0.85rem',
});

const SpeedLine = styled('span')({width:35, height:10, marginBottom:10, display:'inline-block', fontSize:'0.4rem'});
const SpeedLabel = styled('span')({padding: '5px 10px 2px 10px'});

const RouteRow = styled(Box)({
    padding:3, margin:1, borderRadius:3, border: '1px solid #aca', display:'flex', fontSize:'0.9rem',
    '&:hover':{backgroundColor:'#eee'}
});

const RoutePlaces = styled(Box)({ flexGrow:1, textAlign:'left' });
const RouteDistBox = styled(Box)({width:160});
const RouteDistRow = styled(Box)( {display:'flex'} );
const RouteDistValue = styled(Box)({flexGrow:1, textAlign:'right'});
const RouteDistUnit  = styled(Box)({width:60, textAlign:'left', paddingLeft:5});
const RouteMethod = styled(Box)({width:120,textOverflow: 'ellipsis', overflow:'hidden', fontSize:'0.8rem'});
const IconBox = styled(Box)({display:'inline-flex', alignItems:'center', justifyContent:'center' });
const PopTopRight = { vertical: 'top', horizontal: 'right', };

export default function NaverRouteResults({
    results,
    onRequestShowNaverRoute,
    onRequestRemoveRoute,
    disabled
}) {
    const [anchorElement, setAnchorElement] = useState(null);

    if(results.length===0) return null;

    const onClickRoute = (route) => {
        if(onRequestShowNaverRoute) onRequestShowNaverRoute(route);
    };

    const renderRouteRow = (naverRoute, idx) => {
        return(
            <RouteRow key={idx}>
                <RoutePlaces>
                    <Box>
                        출발: {naverRoute.from.address}
                    </Box>
                    <Box>
                        도착: {naverRoute.to.address}
                    </Box>
                </RoutePlaces>
                <RouteDistBox>
                    <RouteDistRow>
                        <RouteDistValue>{Math.round(naverRoute.distance/100)/10}</RouteDistValue>
                        <RouteDistUnit>Km</RouteDistUnit>
                    </RouteDistRow>
                    <RouteDistRow>
                        <RouteDistValue>{ValueUtil.int2str(naverRoute.tollFare)}</RouteDistValue>
                        <RouteDistUnit>원(톨)</RouteDistUnit>
                    </RouteDistRow>
                </RouteDistBox>
                <RouteMethod>
                    <Box>{naverRoute.timeString} 검색</Box>
                    <Box style={{textOverflow: 'ellipsis', overflow:'hidden'}}>{naverRoute.getTrackLabel()}</Box>
                </RouteMethod>
                <IconBox>
                    <IconButton size={AppWord.SMALL}
                        onClick={()=>onClickRoute(naverRoute)}
                    >
                        <VisibilityIcon fontSize={AppWord.SMALL} />
                    </IconButton>
                    <IconButton size={AppWord.SMALL}
                        onClick={()=>onRequestRemoveRoute(naverRoute)}
                    >
                        <ClearIcon fontSize={AppWord.SMALL} />
                    </IconButton>
                </IconBox>
            </RouteRow>
        );
    };

    const popId = 'map-naver-route-results-pop';
    const openPopOver = Boolean(anchorElement);

    return (
        <MinimalContainer>
            <IconButton size={AppWord.SMALL} color={AppPalette.InheritColor}
                onMouseEnter={(e)=>{if(!disabled) setAnchorElement(e.currentTarget)}}
            >
                <StackedLineChartIcon fontSize={AppWord.SMALL} />
            </IconButton>
            <Popover
                id={popId}
                open={openPopOver}
                onClose={()=>setAnchorElement(null)}
                anchorEl={anchorElement}
                anchorOrigin={PopTopRight}
                transformOrigin={PopTopRight}
            >
                <PopControlBox onMouseLeave={()=>setAnchorElement(null)}>
                    <SpeedGuide>
                        <SpeedLine style={{backgroundColor:'#f00'}}>&nbsp;</SpeedLine>
                        <SpeedLabel>시속 10Km 이하</SpeedLabel>
                        <SpeedLine style={{backgroundColor:'#f90'}}>&nbsp;</SpeedLine>
                        <SpeedLabel>시속 30Km 이하</SpeedLabel>
                        <SpeedLine style={{backgroundColor:'#33f'}}>&nbsp;</SpeedLine>
                        <SpeedLabel>시속 60Km 이하</SpeedLabel>
                        <SpeedLine style={{backgroundColor:'#0b0'}}>&nbsp;</SpeedLine>
                        <SpeedLabel>시속 60Km 초과</SpeedLabel>
                    </SpeedGuide>
                    {
                        results.map((naverRoute,idx)=>renderRouteRow(naverRoute,idx))
                    }
                </PopControlBox>
            </Popover>
        </MinimalContainer>
    )
}
