// VehConfList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo, NoUser} from '../../model/CvoModel';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ClearIcon from '@mui/icons-material/Clear';
import FlexyTable from '../common/flexytable/FlexyTable';
import ResponseAlert from '../message/ResponseAlert';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LIST_VEH_CONF, GET_VEH_CONF, SET_VEH_CONF_TEMP_ALARM, VehConfDictionary } from './VehConfGql';
import VehConfInput from './VehConfInput';

const dcPad = AppNumber.dataContainerPadding;
const VehConfListContainer = styled(Box)({
    position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection:'column'
	// flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

const PrintTempLimit = (value) => {
    if(value===null || value===undefined || value < AppNumber.MinGoodTemp || value > AppNumber.MaxGoodTemp) return '제한없음';
    else return value;
};

const Dict = VehConfDictionary.inputMap;
const ErrorTitle =VehConfDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(VehConfDictionary.inputMap, true);
const LimitWidth = 100;
const TableColumns = [
	{ id: 'vehPlates', label: "차량번호", width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'tempr1Ll', label: Dict.tempr1Ll.label, width: LimitWidth, show: true, option:{align: 'center', print: (r,i,v)=>{return PrintTempLimit(v)}} },
    { id: 'tempr1Ul', label: Dict.tempr1Ul.label, width: LimitWidth, show: true, option:{align: 'center', print: (r,i,v)=>{return PrintTempLimit(v)}} },
    { id: 'tempr2Ll', label: Dict.tempr2Ll.label, width: LimitWidth, show: true, option:{align: 'center', print: (r,i,v)=>{return PrintTempLimit(v)}} },
    { id: 'tempr2Ul', label: Dict.tempr2Ul.label, width: LimitWidth, show: true, option:{align: 'center', print: (r,i,v)=>{return PrintTempLimit(v)}} },
    { id: 'tempr3Ll', label: Dict.tempr3Ll.label, width: LimitWidth, show: true, option:{align: 'center', print: (r,i,v)=>{return PrintTempLimit(v)}} },
    { id: 'tempr3Ul', label: Dict.tempr3Ul.label, width: LimitWidth, show: true, option:{align: 'center', print: (r,i,v)=>{return PrintTempLimit(v)}} },
    { id: 'tempr4Ll', label: Dict.tempr4Ll.label, width: LimitWidth, show: true, option:{align: 'center', print: (r,i,v)=>{return PrintTempLimit(v)}} },
    { id: 'tempr4Ul', label: Dict.tempr4Ul.label, width: LimitWidth, show: true, option:{align: 'center', print: (r,i,v)=>{return PrintTempLimit(v)}} },
    { id: 'tempAlertLimit', label: Dict.tempAlertLimit.label, width: 120, show: true, option:{align: 'center', type: TableColumnType.INTEGER} },
];

export default function VehConfList({
	maxHeight,
	maxWidth,
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [records, setRecords] = useState([]);
    const [search, setSearch] = useState('');
    const [itemSelected, setItemSelected] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getVehConfList, responseList] = useLazyQuery(LIST_VEH_CONF, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetConfList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "차량 온도알람설정 목록 조회 오류"})}
    });
    const [getVehConfItemToEdit, responseItemToEdit] = useLazyQuery(GET_VEH_CONF, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetConfItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    const [editVehConf, responseEdit] = useMutation( SET_VEH_CONF_TEMP_ALARM, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );


    useEffect(() => {
        getVehConfList();
    }, [getVehConfList]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetConfList = (data, clientOption) => {
        if(data.vehConfList) {
            setRecords(data.vehConfList.slice());
        }
    };

    const onCompleteGetConfItem = (data, option) => {
        if(data.vehConfItem) {
            const item = {};
            for(const field of EditFields) item[field] = data.vehConfItem[field];
            setEditorState({item: item, editAllowed: data.vehConfItem.editAllowed});
        }
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.vehConfSetTempAlarm.ok) {
            setEditorState(null);
            setRecords(data.vehConfSetTempAlarm.list.slice());
        }
        else setResponseAlert({open:true, resultData: data.vehConfSetTempAlarm, title: ErrorTitle.Edit});
    };

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const getItemAndPermissionInfo = (data) => {
        getVehConfItemToEdit({variables: {vehId: data.vehId}});
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            // case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: getItemAndPermissionInfo(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
    };


    const onCloseEditor = () => {
        setEditorState(null);
    };

    const onRequestSetConfig = (item) => {
        const itemChecked = { // 일일이 체크해야 함.
            vehId: item.vehId
        };
        for(const key of Object.keys(Dict)) {
            const val = item[key];
            if(key.startsWith("tempr")) {
                if(val===null || val===undefined || val==='') {
                    if(key.endsWith('Ll')) {
                        itemChecked[key] = AppNumber.BigMinusTempLimit;
                    }
                    else itemChecked[key] = AppNumber.BigPlusTempLimit;
                }
                else itemChecked[key] = val;
            }
            else itemChecked[key] = val;
        }
        const param = {variables: {vehConf: itemChecked}};
        editVehConf(param);
    };

    const renderEditor = () => {
        return(
            <VehConfInput
                open={Boolean(editorState)}
                vehPlates={itemSelected ? itemSelected.vehPlates : ''}
                item={editorState ? editorState.item : null}
                editAllowed={editorState ? editorState.editAllowed : false}
                responseSaving={responseEdit}
                onClickMutate={onRequestSetConfig}
                onClose={onCloseEditor}
                />
        );
    };

    return(
        <VehConfListContainer>
            <HeaderBox>
				<FormControl variant='outlined' size='small'>
					<InputLabel htmlFor="search-VehConf-list" style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id="search-VehConf-list" onChange={onChangeSearch}
						value={search}
						size='small'
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
            </HeaderBox>
            <FlexyTable
                name="veh_conf_list"
                uniqueKey="vehId"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize='small' />}
                ]}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
            />
            {renderEditor()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}
            />
        </VehConfListContainer>
    );

}
