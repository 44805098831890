//RegulationReportGql
import { gql } from "@apollo/client";

export const GET_POS_REPORT_LOG = gql`
query GetPosReportLog($routeDate: String) {
    getPosReportLog(routeDate:$routeDate) {
        vehId
        routeDate
        etrace
        svc
        collMean
        vehPlates
        custName
        hhmmFrom
        hhmmTill
        positionCount
        model
    }
}
`;

export const GET_POS_REPORT_NOTI_LOG = gql`
query GetPosReportNotiLog {
    getPosReportNotiLog {
        logNid
        regTime
        collectYn
        manager
        client
        vehPlates
        userName
        purpose
        media
    }
}
`;

export const GET_POS_DOWNLOAD_LOG = gql`
query GetPosDownloadLog {
    getPosDownloadLog {
        userId
        userName
        svcTime
        custId
        ofCustName
        svcUrl
        purpose
        rangeFrom
        rangeTo
        dataKey
    }
}
`;

export const GET_SERVICE_LOG = gql`
query GetServiceLog($svcTime: String) {
    getServiceLog(svcTime:$svcTime) {
        userId
        svcTime
        userIp
        custId
        custName
        svcUrl
        etrace
        svc
        media
        randVal
        menuName
        custAs
        userAs
        userName
        userNameAs
        dataKey
    }
}
`;

export const GET_POS_USAGE_LOG = gql`
query GetPosUsageLog {
    getPosUsageLog {
        logNid
        regTime
        collectYn
        manager
        client
        vehPlates
        userName
        purpose
        media
    }
}
`;

export const GET_SESSION_LOG = gql`
query GetSessionLog($actDate: String) {
    getSessionLog(actDate:$actDate) {
        userId
        actTime
        sessionIo
        userName
        classNo
        custId
        custName
        actMemo
        dataKey
    }
}
`;