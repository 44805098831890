// AppConst.js
import { styled } from '@mui/material/styles';

export const UserClass = {
    EtraceAdmin:{classNo:1, className:'시스템 관리자'},
    EtraceServiceManager:{classNo: 10, className: '서비스 관리자'},
    EtraceManager:{classNo: 20, className: '서비스 중간관리자'},
    EtraceStaff:{classNo: 100, className: '서비스 관계자'},
    CustAdmin:{classNo: 1000, className: '관리자'},
    User:{classNo: 2000, className: '일반 사용자'},
};

export const LocalJob = {
    DELETE:'delete'
};

export const MainMenu = {
    SMS:'sms', POI_GROUP:'poigroup'
};

export const CvoMsg = {OK:"ok"};
export const ErrorMsg = {
    AUTH_ERROR:'auth_error'
};

export const ViewMode = {LIST:1, ITEM:2, NEW:3, EDIT:4}; // smsPhrase에서만 사용. 거시기함. 향후 제거.
export const NumCode = {
    NULL:0, NEW:1, EDIT:2
};

export const AppNumber = {
    HeaderHeight: 100, MenuLeft: 150,
    ViewTabHeight: 30, // 2023.5.12
    dataContainerPadding: 10,
    SmallBoxPadding: 10,
    HeaderToolBoxHeight: 42,
    FooterHeight: 54, // from 52
    BigMinusTempLimit: -999999999,
    BigPlusTempLimit: 999999999,
    MinGoodTemp: -555,
    MaxGoodTemp: 555,
};

export const AppObject = {
    NoCachedFetch: {fetchPolicy: "no-cache"},
    DefaultMenu: {menuId:"veh_pos", menuName:"위치, 경로, 동정정보"},
    DefaultAdminMenu: {menuId:"cust_info", menuName:"업체 정보관리"},
    EditMyInfoMenu: {menuId:"_edit_my_user_info_", menuName:"내 정보 수정"},
    TEXT_FORMATS: [{value: 'text', label: '일반텍스트'},{value: 'markdown', label: '마크다운'}],
    OpenWhich: {Nothing:0, Create:1, Edit:2, View:3},
    CosineLat: [1],
};
// fill cosine lat.
for(let i = 1;i<90;i++) { AppObject.CosineLat.push(Math.cos((i * Math.PI) / 180)); }
AppObject.CosineLat.push(0);

export const AppWord = {
    ETRACE_CUST_ID:'1000000000',
    CMD_ADD: 'aDD', CMD_EDIT:'edit', CMD_REMOVE:'remove', CMD_VIEW:'view',
    CMD_ASIS:'asis', // 다른 사용자인 척 가장하기(이트레이스)
    CMD_UP: 'up', CMD_DOWN:'down', CMD_DONE:'done', CMD_READY:'ready',CMD_ROUTE: "route",
    CMD_LOG: "log", CMD_SETTING: "setting", CMD_SET_START:'set_r_start', CMD_SET_END:'set_r_end',
    TOP:'top', LEFT:'left', RIGHT:'right', BOTTOM:'bottom',
    EV_MOUSE_MOVE: 'mousemove',EV_MOUSE_UP: 'mouseup',
    UD_EVT_AUTH_ERROR: '__ud_evt_got_auth_error__',
    NUM_N_HYPN: '-0123456789',
    NUMBERS: '0123456789',
    LOCAL_ID_KEY: 'loKeyUId',
    MAP_LOCALSTORAGE_KEY: 'mpLoStCfKeY',
    SNACK_EVENT: "appSnackEvt",
    USER_POI_VIEW_REL: "VIEW_REL",
    USER_POI_OPER_AUTH:"OPER_AUTH",
    NOT_REAL_GPS_TIME: '2000-01-01 00:00:00',
    SMALL: 'small', LARGE:'large', END: 'end', CENTER:'center',
    ROUTE: "route",
    MENU_CUST_INFO: "cust_info",
    MENU_VEH_POS: "veh_pos",
    MENU_CRUISE_SCHED:'cruise_sched',
    MENU_ROUTINE_ROUTE:'routine_route',
    MENU_TERM_CH_LOG: 'term_change_log',
    MENU_TERM_STT_LOG: 'term_state_log',
    NO_FILTER: '__ALL__', // list all, no filter
};


export const AppPalette = {
    HeaderBackground: '#30135a',
    WarningPurpleBack: '#6600cc',
    BorderCCC: '1px solid #cccccc',
    PrimaryColor: 'primary', PrimaryRGB:'#1976d2',
    WarnColor: 'warning', WarnRGB: '#ed6c02',
    ErrorColor: 'error', ErrorRGB: '#d32f2f',
    InfoColor: 'info', InfoRGB: '#0288d1',
    InheritColor:'inherit',
    SecondaryColor: 'secondary', SecondaryRGB:'#9c27b0',
    SuccessColor: 'success', SuccessRGB:'#2e7d32',
    DisabledColor: 'disabled',
    VariantContained: 'contained',
    VariantOutlined: 'outlined',
    NaverGreen: '#2db400'
};

export const CvoCodes = {
    ACT_TYPE : 'ACT_TYPE',
	AC_BILL_TYPE_CD : 'AC_BILL_TYPE_CD',
	AC_CONTR_AMOUNT_CD : 'AC_CONTR_AMOUNT_CD',
	AC_CONTR_MONTH_CD : 'AC_CONTR_MONTH_CD',
	AC_FIRM_TYPE_CD : 'AC_FIRM_TYPE_CD',
	AC_MODEL_CD : 'AC_MODEL_CD',
	AC_NETWORK_CD : 'AC_NETWORK_CD',
	AC_OPEN_FOR_CD : 'AC_OPEN_FOR_CD',
	AC_TERM_CHANGE_CD : 'AC_TERM_CHANGE_CD',
	AC_TERM_CTRL_USER : 'AC_TERM_CTRL_USER',
	AC_TERM_STATE_CD : 'AC_TERM_STATE_CD',
	ADAS_MODEL_CD : 'ADAS_MODEL_CD',
	API_RESPONSE_CD : 'API_RESPONSE_CD',
	AS_OK_CD : 'AS_OK_CD',
	AS_STATUS_CD : 'AS_STATUS_CD',
	AS_TYPE_CD : 'AS_TYPE_CD',
	BCD_BORDER_CD : 'BCD_BORDER_CD',
	BCD_EVENT_CD : 'BCD_EVENT_CD',
	BILL_METH : 'BILL_METH',
	BIZ_TYPE_CD : 'BIZ_TYPE_CD',
	CO : 'CO',
	COMM_TYPE_CD : 'COMM_TYPE_CD',
	COMPLAINT_CD : 'COMPLAINT_CD',
	CURR_ROAD_KIND : 'CURR_ROAD_KIND',
	CUST_HIST_CD : 'CUST_HIST_CD',
	CUST_STATE_CD : 'CUST_STATE_CD',
	CUST_WORK_MAP : 'CUST_WORK_MAP',
	CVO_DOWNLOAD : 'CVO_DOWNLOAD',
	DATA_IF_CD : 'DATA_IF_CD',
	ETRACE_ADMIN : 'ETRACE_ADMIN',
	ETRACE_CONTACT : 'ETRACE_CONTACT',
	FIELD_CD : 'FIELD_CD',
	FIRM_TYPE_CD : 'FIRM_TYPE_CD',
	IP_ADDR_CD : 'IP_ADDR_CD',
	ISSUE_CD : 'ISSUE_CD',
	LANG_CD : 'LANG_CD',
	MDT800_EV_CODE : 'MDT800_EV_CODE',
	NETWORK_CD : 'NETWORK_CD',
	NOTICE_BY : 'NOTICE_BY',
	Ne : 'Ne',
	PAY_METH : 'PAY_METH',
	PAY_TERM : 'PAY_TERM',
	PLACE_TYPE_CD : 'PLACE_TYPE_CD',
	POI_BY_GROUP_CD : 'POI_BY_GROUP_CD',
	POI_TYPE : 'POI_TYPE',
	PORT_CD : 'PORT_CD',
	POS_SYS : 'POS_SYS',
	PROD_KIND_CD : 'PROD_KIND_CD',
	ROAD_KIND : 'ROAD_KIND',
	ROAD_RANK : 'ROAD_RANK',
	ROLE_CD : 'ROLE_CD',
	SCHEDULE_CD : 'SCHEDULE_CD',
	SCH_CUST_BY : 'SCH_CUST_BY',
	SELC_AREA : 'SELC_AREA',
	SELC_WARN_LEVEL : 'SELC_WARN_LEVEL',
	SMPHONE_REPORT_MODE : 'SMPHONE_REPORT_MODE',
	SMS_RELAY_CUST : 'SMS_RELAY_CUST',
	SVCSYS_CD : 'SVCSYS_CD',
	TERM_CTRL : 'TERM_CTRL',
	TERM_INC_CD : 'TERM_INC_CD',
	TERM_POWER_CD : 'TERM_POWER_CD',
	TERM_PROTOCOL : 'TERM_PROTOCOL',
	TERM_STATE_CD : 'TERM_STATE_CD',
	USER_POI_REL : 'USER_POI_REL',
	USER_STATE_CD : 'USER_STATE_CD',
}

// Table
export const Selectable = { NO: 0, SINGLE: 1, MULTIPLE: 2 }; // 테이블의 체크박스에서 선택가능한 개수.
export const TableColumnType = {
    TEXT: 1, CHAR:2, INTEGER:10, FLOAT:11,
    DATE:20, DATETIME:21, HHMM_TEXT:22,
    ENUM:30, BOOLEAN: 31,
    GRAPH: 40, // Have to hav form(record, rowIndex) function in column definition.
};
export const TableRowMenu = { LIST:1, VIEW:2, NEW:3, EDIT:4, DELETE:5, CHECK:10, PLUS:11, MINUS:12 };

export const StyledCo = {
    FieldLabel: styled('span')({
        fontSize: '0.9rem',
        color: '#033',
    })
};

// ############ Naver Map ###########

export const MapEvent = {
    CLICK:'click', RIGHT_CLICK:'rightclick', DOUBLE_CLICK:'dblclick', MOUSE_UP: 'mouseup',
    DRAG_START:'dragstart', DRAG:'drag',DRAG_END:'dragend',
    ZOOM_CHANGED:'zoom_changed', BOUNDS_CHANGED: 'bounds_changed',
    MOUSE_OVER:'mouseover', MOUSE_OUT:'mouseout',
    IDLE: 'idle', CENTER_CHANGED: 'center_changed'
}; //mousedown, mouseup, click, dblclick, rightclick, mouseover, mouseout, mousemove

export const MapValue = {
    NaverService: 'naver',
    NaverSearchRouteURL: 'https://naveropenapi.apigw.ntruss.com/map-direction/v1/driving',
    Korea: { East: 132, West: 124, South: 33, North: 39 },
    MarkerType: {VEH_POS:1, ROUTE_POINT:2, POI:3, CLUSTER:4, START:11, END:12, INFO:21,},
    MapEvent: {PopInfoBox:'popinfo', SetRoutePoint:'naverroutepoint'},
    LABEL_CONTAINER_WIDTH: 70, // 마커 라벨상자 폭. css poi-marker와 맞춰야 함.
    MARKER_LABEL_HEIGHT: 22, // 마커 라벨 높이
    USER_DATA: 'userData',
    POI_ICON_DIR: 'poi_icon',
    NEWTOP_POI_ICON_DIR: 'img',
    VEH_ICON_DIR: 'img/marker/',
    MISC_DIR: 'img/misc/',
    CLUSTER_Z_INDEX:99900000,
    POI_Z_INDEX:99990000,
    VEHPOS_Z_INDEX:99999900,
    ROUTE_Z_INDEX:99999990,
    ClusterIconSize: 44, // in pixel
    ClusterCoverSize: 110, // in pixel
    Level: {
        ZOOM_INIT: 15,
        ZOOM_MIN: 7, // Default 6 - 한반도 (내 노트북에서 볼 때)
        ZOOM_MAX: 21, // Default 21 - 골목
        ZOOM_FOR_ROUTE_POINT: 16,
        MAX_FOR_CLUSTER: 15,
    },
    POS_MARKER_SIZE: 22,
    POS_MARKER_HIGHT: 46, // MARKER_LABEL_HEIGHT + POS_MARKER_SIZE
    ROUTE_MARKER_SIZE: 12,
    NaverMapTyles: [
        {type: window.naver.maps.MapTypeId.NORMAL, label: '일반', title: '일반지도'},
        {type: window.naver.maps.MapTypeId.TERRAIN, label: '지형', title: '지형지도'},
        {type: window.naver.maps.MapTypeId.SATELLITE, label: '위성', title: '위성지도'},
        {type: window.naver.maps.MapTypeId.HYBRID, label: '겹침', title: '겹침지도'},
    ],
    LevelOptions: [
        {level:7, label:'레벨 7-전국 수준'}, // 기존UI: 레벨 0
        {level:8, label:'레벨 8'},
        {level:9, label:'레벨 9'},
        {level:10, label:'레벨 10'},
        {level:11, label:'레벨 11-도 수준'},
        {level:12, label:'레벨 12'},
        {level:13, label:'레벨 13'},
        {level:14, label:'레벨 14-시 수준'}, // default
        {level:15, label:'레벨 15'},
        {level:16, label:'레벨 16'},
        {level:17, label:'레벨 17'},
        {level:18, label:'레벨 18-동 수준'},
        {level:19, label:'레벨 19'},
        {level:20, label:'레벨 20'},// 기존UI: 레벨 13
        {level:21, label:'레벨 21-빌딩,거리 수준'} 
    ],
    NaverTrackOption: [
        {value:'trafast',		label:'실시간 빠른길'},
        {value:'tracomfort',	label:'실시간 편한길'},
        {value:'traoptimal',	label:'실시간 최적'},
        {value:'traavoidtoll',	label:'무료 우선'},
        {value:'traavoidcaronly',label:'자동차 전용도로 회피 우선'}
    ],
    NaverTrackMethod: {
        Fast: 'trafast',
        Comfort: 'tracomfort',
        Optimal: 'traoptimal',
        //NoToll: 'traavoidtoll',
        //NotDedicated: 'traavoidcaronly',
    },
    PopupOption: 'left=1,top=1,menubar=0,status=1,toolbar=0,scrollbars=0,resizable=yes',
}
/*
동단
경상북도 울릉군 울릉읍 독도리 동도. 북위 37° 14′ 24″ 동경 131° 52′ 22″[1]
서단
인천광역시 옹진군 백령면 연화리. 북위 37° 58′ 10″ 동경 124° 36′ 36″[1]
남단
제주특별자치도 서귀포시 대정읍 마라리. 북위 33° 06′ 43″ 동경 126° 16′ 07″[1]
북단
강원도 고성군 현내면 대강리 북위 38° 36′ 40″ 동경 128° 21′ 51″[2]
*/


