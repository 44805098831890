// ValueUtil.js
import {ErrorMsg, UserClass} from './AppConst';

//const USER_ID_PATTERN = /^[_a-zA-Z0-9]{3,20}$/;
//const WORD_CODE_PATTERN = /^\w+$/;
//const NON_SPACE_CODE_PATTERN = /^[-.@#\w]+$/;
//const NUMBER_CODE_PATTERN = /^\d+$/;
const PHONE_PATTERN = /^\d+[-\d]{7,15}$/;
const SMART_LOGIS_TERM_PATTERN = /^01\d{8,9}$/;
const EMAIL_PATTERN = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
const DATE_PATTERN = /^[12]\d{3}-[01]\d-[0-3]\d$/;
const HMS_PATTERN = /^[0-2]\d:[0-5]\d:[0-5]\d$/;
const RGB_PATTERN = /^#[0-9A-Fa-f]{6}$/;
const CUST_ID_PATTERN = /^(10|19|20)\d{8}$/;
const HTTP_URL_PATTERN = /^https?:\/\/\w+(\.\w+)+/;
//const TEN_ZEROS = '0000000000';
const PW_UPPER_CASE = /[A-Z]/;
const PW_LOWER_CASE = /[a-z]/;
const PW_NUMBER = /[0-9]/;
const PW_PUNCT = /[-~.!@#$%^&*()_+=[\]|\\;:‘“<>,?/]/;
const CSV_PATTERN = /^\w+(,\w+)*$/;

export default class ValueUtil {

    // auth_error 받았는지 점검.
    static hasAuthError(gqlResponse) {
        if(gqlResponse) {
            if(gqlResponse.error) return gqlResponse.error.message===ErrorMsg.AUTH_ERROR;
        }
        return false;
    }

    static hasAnyAuthError(...args) {
        for(const res of args) {
            if(ValueUtil.hasAuthError(res)) return true;
        }
        return false;
    }

    static flawedListResponse(gqlResponse) {
        if(gqlResponse.loading) return true;
        if(gqlResponse.error) return true;
        return !gqlResponse.data ? true : false;
    }

    /* static isCommonResponseOk(gqlResponse, queryName) {
        if(gqlResponse) {
            if(gqlResponse.data) {
                if(gqlResponse.data[queryName]) {
                    return gqlResponse.data[queryName].ok ? true : false;
                }
            }
        }
        return false;
    }

    static isCommonResponseError(gqlResponse, queryName) {
        if(gqlResponse.error) return true;
        if(gqlResponse.data) {
            if(gqlResponse.data[queryName]) {
                return gqlResponse.data[queryName].ok ? false : true;
            }
        }
        return false;
    }

    static getCommonResponseErrors(gqlResponse, queryName) {
        if(gqlResponse.error) return gqlResponse.error.graphQLErrors.map((e)=>e.message);
        if(gqlResponse.data) {
            if(gqlResponse.data[queryName]) {
                if(!gqlResponse.data[queryName].ok) {
                    return [gqlResponse.data[queryName].message]
                }
            }
        }
        return [];
    }
    */

    static makeResponseAlertMessage(title, responseError, resultData) {
        let dataError = false;
        if(resultData) {
            dataError = !resultData.ok;
        }
        if(responseError || dataError)
            return({
                open: true,
                title: title,
                error: responseError,
                resultData: resultData
            });
        else return undefined;
    }
    // useReactiveVar 사용하는 배열은 직접 push, slice, shift 등 할 수 없어서 이렇게 해줘야 함.
    static pushToRepoArray(arrayOfRepo, one, isUnshift) {
        const arr = arrayOfRepo.slice(0);
        if(isUnshift) arr.unshift(one);
        else arr.push(one);
        return arr;
    }

    // tableState, column 데이터를 비교해서 대상 레코드 읽어내기.
    static getTableRecord(tableMeta, columns) {
        var obj = {};
        for(var i=0; i<columns.length; i++) {
            obj[columns[i].name] = tableMeta.rowData[i];
        }
        return obj;
    }

    static numToText(number, length) {
        return number.toString().padStart(length,"0");
    }

    static getYmdOfDate(date) {
        return(
            '' + date.getFullYear() + '-' + ValueUtil.numToText(date.getMonth()+1, 2) 
            + '-' + ValueUtil.numToText(date.getDate(), 2) );
    }
    
    static getYmdText(beforeSec) {
        var d = new Date();
        d.setTime(d.getTime() - (beforeSec || 0) * 1000);
        return(
            '' + d.getFullYear() + '-' + ValueUtil.numToText(d.getMonth()+1, 2) 
            + '-' + ValueUtil.numToText(d.getDate(), 2) );
    }

    static getYmdHmsText(date, beforeSec) {
        var d = date ? date : new Date();
        if(beforeSec) {
            d.setTime(d.getTime() - beforeSec * 1000);
        }
        return(
            '' + d.getFullYear() 
            + ValueUtil.numToText(d.getMonth()+1, 2)
            + ValueUtil.numToText(d.getDate(), 2)
            + ValueUtil.numToText(d.getHours(), 2) 
            + ValueUtil.numToText(d.getMinutes(), 2) 
            + ValueUtil.numToText(d.getSeconds(), 2) );
    }

    static getYmdHmsInputText(date, beforeSec) {
        var d = date ? date : new Date();
        if(beforeSec) {
            d.setTime(d.getTime() - beforeSec * 1000);
        }
        return(
            '' + d.getFullYear() + "-"
            + ValueUtil.numToText(d.getMonth()+1, 2) + "-"
            + ValueUtil.numToText(d.getDate(), 2) + " "
            + ValueUtil.numToText(d.getHours(), 2)  + ":"
            + ValueUtil.numToText(d.getMinutes(), 2)  + ":"
            + ValueUtil.numToText(d.getSeconds(), 2) );
    }

    static isNumber(value) {
        if(value===undefined || value===null) return false;
        if(typeof value === 'number') return true;
        return false;
    }

    static formatDuration(duration) {
        if(duration===undefined || duration===null) return null;
        if(duration < 1) return "0분";
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;
      
        const parts = [];
        if (hours > 0) {
            parts.push(hours + "시간");
        }
        if (minutes > 0) {
            parts.push(minutes + "분");
        }
        if (seconds > 0 && hours < 1) {
            parts.push(seconds + "초");
        }
        return parts.join(" ");
    }

    static degreeToText(deg) {
        const d = Math.floor(deg);
        const minute = (deg - d) * 60;
        const m = Math.floor(minute);
        const s = Math.round((minute-m)*60);
        return `${d}도 ${m}분 ${s}초`;
    }

    static reformWith(text, restrict) {
        if(!text) return text;
        let t = '';
        let ch;
        for(var i=0; i<text.length; i++) {
            ch = text.substring(i,i+1);
            if(restrict.indexOf(ch)>=0) {
                t += ch;
            }
        }
        return t;
    }

    static nullToString(text) {
        if(text===undefined || text===null) return '';
        return text;
    }

    static nullToMidnight(time) {
        if(time===undefined || time===null) return '00:00:00';
        return time;
    }

    static thisOrThen(value, defaultValue) {
        if(value===undefined || value===null) return defaultValue;
        return value;
    }

    static filterHms(hms, defaultValue) {
        if(ValueUtil.isHourMinSec(hms)) {
            return hms;
        }
        return defaultValue;
    }

    static int2str(num) {
        const minus = num < 0;
        var s = '' + Math.floor(Math.abs(num));
        var r = '';
        var add = '';
        while(s.length > 3) {
            r = s.substring(s.length-3) + add + r;
            s = s.substring(0, s.length-3);
            add = ',';
        }
        r = s + add + r;
        return minus ? '-' + r : r;
    }

    static float2str(num, round) {
        const minus = num < 0;
        var i = Math.floor(Math.abs(num));
        // var r = minus ? num + i : num - i;
        if(num === i) {
            return ValueUtil.int2str(num);
        }
        var rawStr = '' + num;
        const ri = rawStr.indexOf('.');
        var frac = ri < 0 ? '.0' : rawStr.substring(ri, ri+round+1);
        var rs = ValueUtil.int2str(i) + frac;

        return minus ? '-' + rs : rs;
    }

    static isNullString(str) {
        if(str===undefined || str===null) return true;
        return str.length===0;
    }

    static realLen(str) {
        if(Boolean(str)) {
            return str.trim().length;
        }
        return 0;
    }

    static goodPasswordRequired(value) {
        var chk = 0;
        if(PW_UPPER_CASE.test(value)) chk++;
        if(PW_LOWER_CASE.test(value)) chk++;
        if(PW_NUMBER.test(value)) chk++;
        if(PW_PUNCT.test(value)) chk++;
        if(chk >= 3) {
            if(value.length >= 8) return true;
        }
        return false;
    };

    static goodEmail(str) {
        if(str) {
            if( EMAIL_PATTERN.test(str) ) return true;
        }
        return false;
    };

    static goodPasswordNullable = (value) => {
        if(ValueUtil.isNullString(value)) return true;
        return ValueUtil.goodPasswordRequired(value);
    };

    static isPhoneNumber(value) { return PHONE_PATTERN.test(value); };

    static goodSmartLogisNumber = (value) => {return SMART_LOGIS_TERM_PATTERN.test(value)}

    static isDateFormat(value) { return DATE_PATTERN.test(value)}

    static isColorFormat(value) { return RGB_PATTERN.test(value)}

    static isGoodCustId(value) { return CUST_ID_PATTERN.test(value)}

    static isHourMinSec(value) {return value && HMS_PATTERN.test(value);};

    static isHttpUrl(value) {return Boolean(value) && HTTP_URL_PATTERN.test(value);};

    static isRegexpMatch(value, regexp) {return regexp.test(value);};

    static isCsvText(value) {return value && CSV_PATTERN.test(value)};

    static parseDate(value) {
        if(!Boolean(value)) return null;
        const ymd = value.split(/\D/);
        if(ymd.length < 3) return null;
        const date = new Date();
        date.setFullYear(ymd[0]);
        date.setMonth(parseInt(ymd[1]-1));
        date.setDate(parseInt(ymd[2]));
        return date;
    }

    // *GQL.js file에 정의된 입력양식 상수 중 기초 데이터(default)만 추출.
    static defaultValuesFromInputMap(inputMap) {
        const defMap = {};
        for(const key of Object.keys(inputMap)) {
            const dict = inputMap[key];
            if(dict.toAdd && Boolean(dict.label)) {
                let defVal;
                if(inputMap[key].hasOwnProperty('default')) defVal = dict.default;
                else {
                    const type = dict.type;
                    if(type==='number') defVal = 0;
                    else if(type==='date') defVal = ''; // ValueUtil.getYmdText();
                    else if(type==='datetime-local') defVal = ValueUtil.getYmdHmsText();
                    else if(type==='time') defVal = '00:00:00';
                    else defVal = '';
                }
                defMap[key] = defVal;
            }
        }
        return defMap;
    }

    // 텍스프 필드 입력 평가.
    static evalTextInput(event, InputMap, inputData, inputError) {
        const id = event.target.id;
        const value = event.target.value;
        const dataInfo = InputMap[id];

        const e = {...inputError};
        var hasError = false;

        if(dataInfo.check && !dataInfo.check(value)) {
            e[id] = true; // dataInfo.help;
            hasError = true;
        }
        else e[id] = undefined;

        const data = {...inputData};
        data[id] = value;
    
        if(!hasError) {
            for(const id of Object.keys(InputMap)) {
                if(e[id]) {
                    // 다른 필드에 오류가 있는지 검사.
                    hasError = true;
                    break;
                }
                else if(InputMap[id].required && !data.hasOwnProperty(id)) {
                    // 오류가 없더라도 필수 입력 필드에 데이터 없으면 전체 오류 표시.
                    hasError = true;
                    break;
                }
            }
        }
        return [data, e, hasError];
    }

    static hasDataError(InputMap, data, inputError, isEdit) {
        for(const id of Object.keys(InputMap)) {
            const imap = InputMap[id];
            const needAction = isEdit ? imap.toEdit : imap.toAdd;
            if(inputError[id]) {
                // 다른 필드에 오류가 있는지 검사.
                return true;
            }
            else if(needAction && imap.required && !data.hasOwnProperty(id)) {
                // 오류가 없더라도 필수 입력 필드에 데이터 없으면 전체 오류 표시.
                return true;
            }
        }
        return false;
    }

    static checkInputData(InputMap, inputData, isEdit) {
        const errorMap = {};
        let foundError = false;
        
        for(const id of Object.keys(InputMap)) {
            const value = inputData[id];
            const imap = InputMap[id];
            const needSubmit = isEdit ? imap.toEdit : imap.toAdd;
            if(value===undefined || value===null) {
                if(needSubmit && imap.required) {
                    errorMap[id] = true;
                    foundError = true;
                }
            }
            else if(imap.check && !imap.check(value)) {
                errorMap[id] = true;
                foundError = true;
            }
            else errorMap[id] = false;
        }
        return {errorMap, foundError};
    }

    // 텍스프 필드 입력 평가.
    static evalTextInput2(event, InputMap, inputData, inputError, isEdit) {
        const id = event.target.id;
        const value = event.target.value;
        const dataInfo = InputMap[id];

        const e = {...inputError};
        let hasError = false;

        if(dataInfo.check && !dataInfo.check(value)) {
            e[id] = true; // dataInfo.help;
            hasError = true;
        }
        else e[id] = undefined;

        const data = {...inputData};
        data[id] = value;
    
        if(!hasError) {
            hasError = ValueUtil.hasDataError(InputMap, data, e, isEdit);
            /*
            for(const id of Object.keys(InputMap)) {
                const imap = InputMap[id];
                const needAction = isEdit ? imap.toEdit : imap.toAdd;
                if(e[id]) {
                    // 다른 필드에 오류가 있는지 검사.
                    hasError = true;
                    break;
                }
                else if(needAction && imap.required && !data.hasOwnProperty(id)) {
                    // 오류가 없더라도 필수 입력 필드에 데이터 없으면 전체 오류 표시.
                    hasError = true;
                    break;
                }
            }
            */
        }
        return [data, e, hasError];
    }

    static getCodeNode(cdType, codeTree) {
        for(const node of codeTree) {
            if(node.cdType === cdType) return node;
        }
        return null;
    }

    static codeToSelectList(cdType, codeTree, addFirst) {
        const node = ValueUtil.getCodeNode(cdType, codeTree);
        if(node) {
            const array = node.codes.map((node) => {
                return {value: node.cd, label: node.cdName}
            });
            if(addFirst) array.unshift({...addFirst});
            return array;
        }
        return [];
    }

    // String value = '' 이면 nullify.
    static refineToSubmit(obj) {
        for(const key of Object.keys(obj)) {
            if(obj[key] === '') obj[key] = null;
        }
        return obj;
    }

    static getFieldsToSubmit(inputMap, isEdit) {
        const rv = [];
        const reqKey = isEdit ? 'toEdit' : 'toAdd';
        for(const field of Object.keys(inputMap)) {
            const dic = inputMap[field];
            if(dic[reqKey]) rv.push(field);
        }
        return rv;
    }

    static getDataToSubmit(data, inputMap, isEdit) {
        let newData = {};
        for(const field of ValueUtil.getFieldsToSubmit(inputMap, isEdit)) {
            let value = data[field];
            if(value === '') value = null;
            newData[field] = value; // data[field];
        }
        return newData;
    }

    static getUtf8BytesLength(text) {
        if(!text) return 0;

        let length = 0;
        let index = 0;
        const maxLength = 300;
        
        while (index < text.length) {
            const chunk = text.substring(index, index + maxLength);
            
            //let bytes = new TextEncoder().encode(chunk);
            let ch;
            let chunkLength = 0;// bytes.length;
            for(var i=0;i < chunk.length; i++) {
                ch = chunk.charCodeAt(i);
                chunkLength += (ch >> 11 ? 3 : ch >> 7 ? 2 : 1);
            }

            length += chunkLength;
            index += maxLength;
        }
        
        return length;
    }

    static textInRange(text, min, max) {
        if(text) {
            const len = ValueUtil.getUtf8BytesLength(text);
            return len >= min && len <= max;
        }
        return min <= 0 && max >= 0;
    }

    static getNumberInRange(value, min, max) {
        if(value < min) return min;
        if(value > max) return max;
        return value;
    }

    static custOwnCode(kinds) {
        const codes = {};
        for(const kind of kinds) {
            codes[kind.codeKind] = {
                codeName: kind.codeName,
                children: []
            };

            for(const code of kind.codes) {
                codes[kind.codeKind].children.push({...code});
            }
        }
        return codes;
    }

    static isEtrace(sessionInfo) {return sessionInfo.user.classNo <= UserClass.EtraceStaff.classNo;}
    static isCustAdmin(sessionInfo) { return sessionInfo.user.classNo <= UserClass.CustAdmin.classNo; }

    static getSizeInText(bytes) {
        const units = ['B', 'KB', "MB", 'GB'];
        let num = bytes;
        let idx = 0;
        while(num > 1024 && idx < units.length-1) {
            num = num / 1024;
            idx++;
        }
        const size = Math.round(num * 10) / 10;
        return `${size} ${units[idx]}`;
    }

    // Map
    static degreesToRadians(degrees) {
        return (degrees * Math.PI) / 180;
    }
      
    static radiansToDegrees(radians) {
        return (radians * 180) / Math.PI;
    }
      
    static getPolygon(lat, lon, numPoints, meter) {
        if (numPoints < 3 || numPoints > 16) {
            return [];
        }

        const vertices = [];
        const earthRadius = 6371000; // Earth's radius in meters
        const tilt = numPoints===4 ? 45 : 0;
      
        for (let i = 0; i < numPoints; i++) {
            const angle = ( (i * 360) / numPoints + tilt ) % 360 ; // Calculate the angle between vertices
            const lat1 = ValueUtil.degreesToRadians(lat);
            const lon1 = ValueUtil.degreesToRadians(lon);
            const angularDistance = meter / earthRadius; // Convert meters to radians
        
            const lat2 = Math.asin(Math.sin(lat1) * Math.cos(angularDistance) + Math.cos(lat1) * Math.sin(angularDistance) * Math.cos(ValueUtil.degreesToRadians(angle)));
            const lon2 = lon1 + Math.atan2(Math.sin(ValueUtil.degreesToRadians(angle)) * Math.sin(angularDistance) * Math.cos(lat1), Math.cos(angularDistance) - Math.sin(lat1) * Math.sin(lat2));
        
            vertices.push({
                lat: ValueUtil.radiansToDegrees(lat2),
                lon: ValueUtil.radiansToDegrees(lon2),
            });
        }
        const first = {...vertices[0]};
        vertices.push(first);
      
        return vertices;
    }

    // Map
    static getUrlBase () {
        let url = window.location.href;
        const idx = url.indexOf('#');
        if(idx>0) return url.substring(0, idx);
        return url;
    }

    // Naver
    static getNaverV2AddressString(obj) {
        if(obj.v2) {
            // address: {jibunAddress: '서울특별시 중구 소공동  ', roadAddress: ''}
            if(obj.v2.address) {
                if(ValueUtil.realLen(obj.v2.address.roadAddress)) {
                    return obj.v2.address.roadAddress.trim();
                }
                else if(ValueUtil.realLen(obj.v2.address.jibunAddress)) {
                    return obj.v2.address.jibunAddress.trim();
                }
            }
        }
        return '';
    }

    // user_tcol_conf21
    /**
     * Parses a csv string and returns an array of objects with column name and width
     * @param {string} colsCsv - columns with width in order of display
     * @returns array of objects
     */
    static parseTableConfCsv(colsCsv) {
        return colsCsv.split(/,/).map((item)=>{
            const vset = item.split(/:/);
            return {column:vset[0], width:parseInt(vset[1])};
        });
    }

    /**
     * Parse each every item of user_tcol_conf21 list and pack into a object to save in repo.
     * @param {Array} list - Array data from user_tcol_conf21 table
     * @returns 
     */
    static reformTableColumnListToObject(list) {
        const conf = {};
        for(const rec of list) {
            const {userId, viewName, colsCsv, regDate} = rec;
            const colAndWidthList = ValueUtil.parseTableConfCsv(colsCsv);
            conf[viewName] = {
                userId: userId, table: viewName, date: regDate,
                changed: false, // flag for later saving
                columns: colAndWidthList, // list
            };
        }
        return conf;
    }
}
