// NotImplemented.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled  } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Button } from '@mui/material';
import {useReactiveVar} from '@apollo/client';
import { appAlertRepo } from '../../model/CvoModel';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber } from '../../model/AppConst';

const Margin = 30;
const dcPad = AppNumber.dataContainerPadding;
const OuterContainer = styled(Box)({
    position:"absolute", top:dcPad, bottom:dcPad, left: dcPad, right: dcPad,
    display:"flex", justifyContent:"center", alignItems:"center",
    textAlign:'center',
    paddingTop:Margin,
});

const ContentBox = styled(Box)({
    display:'inline-block',
    textAlign:'center',
    border: '5px solid #ccfdfd',
    borderRadius: 30
});

const TimeBox = styled(Box)({
    fontSize: '48pt',
    color: '#ccfdfd'
});

export default function NotImplemented() {
    const [now, setNow] = useState(new Date());
    const appAlerts = useReactiveVar(appAlertRepo);
    const clientSize = useClientSize();

    const BoxHeight = clientSize.dataAreaHeight - Margin * 2;
    const BoxWidth = clientSize.dataAreaWidth - Margin * 2;
    const TopPadding = BoxHeight / 2 - 100;

    const sendAlert = () => {
        const time = new Date();
        const str = "Now " + time.getTime();
        //const arr = appAlerts.slice(0);
        // appAlerts.push();

        appAlertRepo(ValueUtil.pushToRepoArray(appAlerts, {
            title: "Testing alert",
            messages: [str]
        }));
    };

    return (
        <OuterContainer>
            <ContentBox height={BoxHeight || 300} width={BoxWidth} onMouseEnter={()=>{setNow(new Date())}}>
                <div style={{height:TopPadding}}></div>
                <TimeBox>
                    {/* T {now.getHours()}:{now.getMinutes()} */}
                    아직 작업중인 메뉴입니다.
                </TimeBox>
                <p>window nnerHeight {clientSize.height}</p>
                <p>dataAreaHeight {clientSize.dataAreaHeight}</p>
                <p>BoxHeight {BoxHeight}</p>
                <Button style={{marginTop:50}} onClick={()=>{sendAlert()}}>
                    Test alert
                </Button>
            </ContentBox>
        </OuterContainer>
    );
}

/*

                <TimeBox onMouseMove={()=>{setNow(new Date())}}>
                    {now}
                </TimeBox>
*/
