// ColumnConfigDialog.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled  } from '@mui/material/styles';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { AppPalette, AppWord, AppObject } from '../../../model/AppConst';
import ConfirmDialog from '../../message/ConfirmDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { NoUser, tableShapeConfRepo, openTableShapeConfRepo, userInfoRepo } from '../../../model/CvoModel';
import { BATCH_USER_TCOL_CONF21 } from '../../user_tcol_conf21/UserTcolConf21Gql';
import { useMutation, useReactiveVar } from '@apollo/client';
import ValueUtil from '../../../model/ValueUtil';

const ConfigBoxTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const ContentWrap = styled(Box)({
    minWidth:400, flexGrow:1, display:'flex', flexDirection:'column'
});
const Guide = styled(Box)({fontSize:'0.9rem'});
const ListContainer = styled(Box)({ flexGrow:1, overflowY:'auto', border:AppPalette.BorderCCC, borderRadius:5, padding:5 });

const ColumnBox = styled(Box)({ minWidth:300, display:'flex',  });
const LabelBox = styled(Box)({ margin:2, padding:5, border: AppPalette.BorderCCC, flexGrow:1, display:'flex', alignItems:'center', borderRadius:5 });
const VisibleBox = styled(Box)({ margin:2, padding:5, display:'flex', alignItems:'center' });

function getIndexById(columns, id) {
    let idx = 0;
    for(const col of columns) {
        if(col.id===id) return idx;
        idx++;
    }
    return -1;
}

function getColumnById(columns, id) {
    for(const col of columns) {
        if(col.id === id) return {...col};
    }
    return undefined;
}

function mergeTableColumns(columns, config) {
    if(config) {
        const merged = [];
        const showMap = {};
        // 보이는 것들을 먼저 넣는다.
        for(const cw of config.columns) {
            showMap[cw.column] = true;
            const col = getColumnById(columns, cw.column); // one of columns declared in the code
            if(col) {
                if(cw.width) col.width = cw.width;
                col.show = true;
                merged.push(col);
            }
        }
        // 나머지 보여주지 않는 것들은 뒤에 둔다.
        for(const col of columns.filter((col) => !showMap[col.id])) {
            const copy = {...col};
            copy.show = false;
            merged.push(copy);
        }
        return merged;
    }
    else {
        return columns.map((col)=>{return {...col}});
    }
}
/**
 * Column configuration dialog box
 * @param {object} props - {open, tableName, tableColumns, onClose} all mandatory
 */
export default function ColumnConfigDialog({
    open,
    tableName,
    tableColumns, // default columns in source code
    onClose
}) {
    const [columns, setColumns] = useState([]); // 전체 테이블 목록. 현재 보이는 것과 그렇지 않은 것 구분. width 역시 포함.
    const [changed, setChanged] = useState(false);
    const [itemDragging, setItemDragging] = useState(null);
    const [itemBeneath, setItemBeneath] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
	const sessionInfo = useReactiveVar(userInfoRepo);
    const tableShapeConf = useReactiveVar(tableShapeConfRepo);

    // ##### GraphQL Mutation.   ###
    const [batchUserTcolConf21, responseBatch] = useMutation( BATCH_USER_TCOL_CONF21, {
		onCompleted: (data, option) => onCompleteBatch(data, option), 
		onError: (error) => {updateRepo(true)} // 그냥 레포에 저장하고 나중에 처리하게 한다. (true for changed)
	} );

    useEffect(()=>{
        openTableShapeConfRepo(open ? true : false);
    }, [open]);

    useEffect(()=>{
        if(open) {
            const merged = mergeTableColumns(tableColumns, tableShapeConf[tableName]);
            setColumns(merged);
            setChanged(false);
        }
    }, [tableColumns, tableShapeConf, tableName, open]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteBatch = (data, clientOption) => {
        if(data.userTcolConf21Batch.ok) {
            updateAllRepoAsSaved();
            clearDragStates();
            onClose();
        }
        else {
            updateRepo(true);
            onClose();
        }
    };

    const clearDragStates = () => {
        if(itemDragging || itemBeneath) {
            setItemDragging(null);
            setItemBeneath(null);
        }
    };

    const changeColumns = (newColumns) => {
        setColumns(newColumns);
        setChanged(true);
    };

    const onStartItemDrag = (e, column, index) => {
        setItemDragging(column);
    };

    const onDraggingOverItem = (e, column, index) => {
        e.preventDefault();
        if(!Boolean(itemBeneath) || itemBeneath.id !== column.id) {
            setItemBeneath(column);
        }

    };

    const moveToEnd = () => {
        if(itemDragging) {
            const column = {...itemDragging};
            column.show = true;
            
            const newCols = columns.filter((c) => c.id !== column.id && c.show);
            newCols.push(column);
            for(const col of columns.filter((c) => c.id !== column.id && !c.show)) newCols.push(col);
            changeColumns(newCols);
            
            clearDragStates();
        }
    };

    const onItemDropped = (e) => {
        e.preventDefault();
        if(itemDragging) {
            if(itemBeneath) {
                // dragging column must be shown.
                const idxBeneath = getIndexById(columns, itemBeneath.id);
                if(idxBeneath >= 0) {
                    const col = {...itemDragging};
                    col.show = true; // It must be shown. Otherwise, user don't need to drag it.
                    const idxMoving = getIndexById(columns, col.id);
                    if(idxMoving>=0) {
                        const newCols = [...columns];
                        newCols.splice(idxMoving, 1);
                        newCols.splice(idxBeneath, 0, col);
                        
                        changeColumns(newCols);
                    }
                }
                clearDragStates();
            }
            else {
                moveToEnd();
            }
        }
    };

    const onMouseOutOfBox = (e) => {
        moveToEnd();
    };

    const onClickToggleVisibility = (column, index) => {
        const copy = {...column};
        copy.show = !column.show;

        const newCols = columns.filter((c) => c.id !== column.id && c.show);
        newCols.push(copy);
        for(const col of columns.filter((c) => c.id !== column.id && !c.show)) newCols.push(col);

        changeColumns(newCols);
    };

    const makeObjectForRepo = (checkChanged) => {
        const obj = tableShapeConf[tableName] || {userId: sessionInfo.user.userId, table:tableName, date:'', changed:checkChanged, columns:[]};
        obj.columns = columns.filter((c)=>c.show).map((col)=>{return {column:col.id, width: col.width}});
        obj.date = ValueUtil.getYmdText();
        obj.changed = checkChanged ? true : false;
        return obj;
    };

    const updateRepo = (checkChanged) => {
        const repoMap = {...tableShapeConf};
        repoMap[tableName] = makeObjectForRepo(checkChanged);

        tableShapeConfRepo(repoMap);
    };

    const updateAllRepoAsSaved = () => {
        const objForRepo = makeObjectForRepo(false);

        const ymd = ValueUtil.getYmdText();
        const newRepoData = {};
        for(const name of Object.keys(tableShapeConf)) {
            const conf = tableShapeConf[name];
            if(conf.changed) {
                conf.changed = false;
                conf.date = ymd;
            }
            newRepoData[name] = conf;
        }
        newRepoData[tableName] = objForRepo;
        tableShapeConfRepo(newRepoData);
    };

    const onClickSave = () => {
        if(sessionInfo.isDisguised()) {
            // Don't save it.
            updateRepo(false);
            onClose();
        }
        else {
            // 과거에 칼럼 너비만 수정한 것들이 있다면 그들까지 한꺼번에 저장한다.
            const array = Object.values(tableShapeConf).filter((conf)=>conf.changed && conf.table !== tableName);
            array.push(makeObjectForRepo(false));
            const inputArray = array.map((conf)=>{
                const csv = conf.columns.map((c)=>{
                    return c.column + ':' + c.width
                }).join(',');
                return {viewName: conf.table, colsCsv: csv};
            });
            batchUserTcolConf21({variables: {userTcolConf21s:inputArray}});
        }
    };

    // 과거에 칼럼 너비만 수정한 것들이 있다면 저장 후 종료한다.
    const saveRemainingUnchangedAndClose = () => {
        const inputArray = Object.values(tableShapeConf).filter((conf)=>conf.changed)
            .map((conf)=>{
                const csv = conf.columns.map((c)=>{
                    return c.column + ':' + c.width
                }).join(',');
                return {viewName: conf.table, colsCsv: csv};
            });
        if(inputArray.length > 0) 
            batchUserTcolConf21({variables: {userTcolConf21s:inputArray}});
        else onClose();
    };

    const onClickCancel = () => {
        // cancel 할때도 repo 검사해서 저장해 주기.
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '설정정보가 변경되었습니다.',
                messages: ['설정정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        saveRemainingUnchangedAndClose();
                        // clearDragStates();
                        // onClose();
                    }
                }
            });
        }
        else {
            saveRemainingUnchangedAndClose();
            //clearDragStates();
            //onClose();
        }
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const StyleDragging = {cursor:'grabbing', backgroundColor:'#77f', opacity:0.5};
    const StyleNormal = {cursor:'grab', backgroundColor:'white'};

    if(ValueUtil.hasAnyAuthError(responseBatch)) userInfoRepo(NoUser);

    return (
        <Dialog maxWidth="lg" open={open}
            onDrop={onItemDropped}
        >
            <ConfigBoxTitle>테이블 출력항목 및 순서 설정</ConfigBoxTitle>
            <DialogContent
                style={{minWidth:500, display:'flex'}}
                onMouseLeave={onMouseOutOfBox}
            >
                <ContentWrap>
                    <Guide>
                        <ul>
                            <li>마우스로 잡아 끌어 떨어뜨리면 순서를 바꿉니다.</li>
                            <li>상자 밖에 떨어뜨리면 맨 끝으로 이동합니다.</li>
                            <li>끌어 옮기면 보이지 않던 행도 보여지도록 바꿉니다.</li>
                        </ul>
                    </Guide>
                    <ListContainer
                    >
                        {
                            columns.map((c, index)=>{
                                let dragging = false;
                                if(itemDragging) dragging = itemDragging.id === c.id;

                                return(
                                    <ColumnBox key={c.id}
                                        draggable
                                        onDragStart={(e)=>onStartItemDrag(e, c, index)}
                                        onDragOver={(e)=>onDraggingOverItem(e, c, index)}
                                        style={dragging ? StyleDragging : StyleNormal}
                                    >
                                        <LabelBox>
                                            {c.label}
                                        </LabelBox>
                                        <VisibleBox>
                                            <IconButton
                                                color={c.show ? AppPalette.PrimaryColor : AppPalette.InheritColor}
                                                onClick={(e)=>onClickToggleVisibility(c,index)}
                                            >
                                            {
                                                c.show ?
                                                <VisibilityIcon fontSize={AppWord.SMALL} /> :
                                                <VisibilityOffIcon fontSize={AppWord.SMALL} />
                                            }
                                        </IconButton>
                                        </VisibleBox>
                                    </ColumnBox>
                                );
                            })
                        }
                    </ListContainer>

                </ContentWrap>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClickSave}
                    disabled={!changed || columns.filter((c)=>c.show).length < 1}
                    color={AppPalette.PrimaryColor}
                    variant={AppPalette.VariantContained}
                >
                    저장
                </Button>
                &nbsp;
                <Button onClick={onClickCancel}
                    color={AppPalette.WarnColor}
                    variant={AppPalette.VariantContained}
                >
                    취소
                </Button>
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    )
}

/*


colsCsv
: 
"custId:90,custName:150,firmTypeValue:90,bizTypeValue:100,agentTelNo:100,regDate:100,custStateValue:70,smsUseFlag:120,billMethValue:100,agentName:100,agentEmail:100,compBizno:140,custCorpNo:100,billStaffName:100,billStaffTelNo:140,billStaffEmail:150,billStaffFaxNo:100,salesStaffName:100,reprName:100,telNo:100,faxNo:100,addr:350,etc:600"
viewName
: 
"cust_info_list"

*/
