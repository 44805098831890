// MapShapeInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Button, Dialog, DialogTitle, DialogContent, DialogActions, Slider, Checkbox, TextField } from '@mui/material';
import { AppPalette, AppWord, AppObject } from '../../model/AppConst';


const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const InputRow = styled(Box)({ padding: 10 });
const LabelBox = styled('div')({fontSize:'0.85rem', color:AppPalette.PrimaryRGB, padding:"10px 2px 5px 5px"});

/**
 * 지도에서 지정한 위치에 원이나 다각형을 그릴 수 있도록 데이터를 입력 받는다.
 * @returns 원, 다각형 입력 대화상자
 */
export default function MapShapeInput({
    open,
    dataDefault,
    onClose
}) {
    const [dist, setDist] = useState(50);
    const [isPolygon, setIsPolygon] = useState(false);
    const [vertex, setVertex] = useState(6);

    useEffect(()=>{
        if(dataDefault) {
            if(dataDefault.dist) setDist(dataDefault.dist);
            if(dataDefault.vertex >= 3 && dataDefault.vertex <= 16) {
                setVertex(dataDefault.vertex);
                setIsPolygon(true);
            }
            else setIsPolygon(false);
        }
    }, [dataDefault]);

    const onClickOk = () => {
        const data = {
            dist: dist,
            vertex: isPolygon ? vertex : 0 // 0 means a circle
        };
        onClose(data);
    };

    const renderCircleText = () => {
        return(
            <InputRow>
                <Box>다각형을 그리려면 위 다각형 그리기를 체크하세요</Box>
            </InputRow>
        );
    };

    const renderVertexInput = () => {
        return(
            <InputRow>
                <LabelBox>다각형의 꼭지점 수 ({vertex}개)</LabelBox>
                <Slider
                    value={vertex}
                    onChange={(e,v)=>setVertex(v)}
                    min={3} max={16}
                    step={1}
                    valueLabelDisplay='auto'
                    valueLabelFormat={(v) => `${v} 개`}
                    aria-labelledby="shape-vertex-slider"
                    style={{width:'90%'}}
                />
            </InputRow>
        );
    };

    return (
        <Dialog open={open}>
            <EditorTitle>원 또는 다각형 그리기</EditorTitle>
            <DialogContent>
                <InputRow>
                    <TextField id="dist-input" size="small"
                        type="number"
                        label="반경(미터)"
                        required={true}
                        value={dist}
                        error={dist < 10 || dist > 30000}
                        helperText="중심에서 다각형의 꼭지점 까지 거리 또는 반경"
                        onChange={(e)=>setDist(e.target.value)} />
                </InputRow>
                <InputRow>
                    <FormControlLabel
                        label="다각형 그리기"
                        control={
                            <Checkbox
                                name="polygon-check"
                                checked={isPolygon}
                                onChange={(event)=>setIsPolygon(event.target.checked)}
                            />
                        }
                    />
                </InputRow>
                {
                    isPolygon ? renderVertexInput() : renderCircleText()
                }
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClickOk}
                    variant={AppPalette.VariantContained}
                    color={AppPalette.PrimaryColor}>
                    그리기
                </Button>
                <Button
                    onClick={()=>onClose()}
                    color={AppPalette.WarnColor}
                    variant={AppPalette.VariantContained}>취소</Button>
            </DialogActions>
        </Dialog>
    );
}
