// com/map/MarkerManager.js
import { AppPalette, MapEvent, MapValue } from "../../model/AppConst";
import ValueUtil from "../../model/ValueUtil";
import MarkerUtil from "./MarkerUtil";
import PolygonWork from "./PolygonWork";
const NM = window.naver.maps;

function isOverlapped(a, b) {
    return !(
        a.right <= b.left ||
        a.left >= b.right ||
        a.top >= b.bottom ||
        a.bottom <= b.top
      );
};

const NEW_POI_KEY = 'new-poi';
const NAVER_ROUTE_START_ICON = 'add_navi_from_30x22.gif';
const NAVER_ROUTE_END_ICON = 'add_navi_to_30x22.gif';

/**
 * Marker 집단 관리.
 * 한 종류의 마커를 표시, 숨김, 생성하기 위한 관리클래스.
 * 차량위치 또는 클러스터와 지점 마커 2가지.
 * 참고1: 모든 마커는 option에 userData를 가져야 함. userData: {type, data}
 * 참고2: 모든 마커는 option에 icon 가진다. {content, size:{width,height}, anchor:{x,y}}
 */
export default class MarkerManager {
    mapShell = null;
    //dataKey = null;

    markerType = 0;
    markers = []; // 보여주는 마커들. {marker, listeners, key}
    markerPool = []; // 보여주지 않는 마커들 저장. 생성 전에 먼저 확인해서 꺼내 씀. 없으면 생성.
    midMarkers = []; // POI polygon 그리기용. markers 하나로는 감당하기 어려워서 어쩔 수 없음. hide, show 필요치 않음.
    //positions = []; // poi, vehPos, poiCluster

    layer = null; // Polygon, polyline...

    constructor(mapShell) {
        this.mapShell = mapShell;
        //this.dataKey = dataKey; // clusterId or vehId
    }

    showAll = () => {
        let markerSet;
        const map = this.mapShell.getMap();
        if(this.layer) {
            this.layer.setMap(map);
        }
        while(Boolean(markerSet = this.markerPool.shift())) {
            markerSet.marker.setMap(map);
            this.markers.push(markerSet);
        }
    };

    hideAll = () => {
        let markerSet;
        while(Boolean(markerSet = this.markers.shift())) {
            markerSet.marker.setMap(null);
            this.markerPool.push(markerSet);
        }
        if(this.layer) {
            this.layer.setMap(null);
        }
    };

    /**
     * Remove every listers of markers and empty marker pool.
     */
    clear = () => {
        this.hideAll();
        for(const marker of this.markerPool) {
            for(const listener of marker.listeners)
                NM.Event.removeListener(listener);
        }
        this.markerPool = [];
        this.layer = null;
    };

    /**
     * 겹침에 대한 처리를 하기 위해 마커의 경계(픽셀)를 구한다.
     * @param {object} marker 
     * @param {number} tolerance - 겹침 범위의 높이를 넓히거나 좁힌다.
     * @returns 마커가 차지하는 사각형의 경계.
     */
    getMarkerRect = (marker, tolerance, sideShrink) => {
        const {size, anchor} = marker.getOptions('icon'); // {content, size:{width,height}, anchor:{x,y}}
        const t = tolerance===undefined || tolerance===null ? -1 : tolerance+0;
        const leftDiff = anchor.x;
        const rightDiff = size.width - leftDiff;
        const topDiff = anchor.y;
        const bottomDiff = size.height - topDiff;

        const markerPos = marker.getPosition();
        const offset = this.mapShell.coordToOffset(markerPos);
        return {
            left:offset.x - leftDiff + sideShrink, 
            right:offset.x + rightDiff - sideShrink, 
            top:offset.y - topDiff - t, 
            bottom: offset.y + bottomDiff + t
        };
    };

    getDataOverlapped = (marker, tolerance) => {
        let len = 0;
        if(marker.userData.type===MapValue.MarkerType.VEH_POS) len = ValueUtil.getUtf8BytesLength(marker.userData.data.vehAlias);
        else if(marker.userData.type===MapValue.MarkerType.POI) len = ValueUtil.getUtf8BytesLength(marker.userData.data.poiName);
        let sideShrink = 0;
        if(len>0) {
            const {size} = marker.getOptions('icon');
            const width = len * 7 + 20; // 20 for both side paddings. 7 for one letter approx.
            if(width<size.width) {
                sideShrink = (size.width-width)/2;
            }
        }
        //ValueUtil.getUtf8BytesLength(marker.userData)
        const rect = this.getMarkerRect(marker, tolerance, sideShrink);
        const res = [];
        for(const markerSet of this.markers) {
            const r = this.getMarkerRect(markerSet.marker,0,0);
            if(isOverlapped(rect, r)) {
                res.push(markerSet.marker.userData); // {data:poi, type:MapValue.MarkerType.POI}
            }
        }
        return res;
    };

    /*
    return !(
        rect.right <= r.left ||
        rect.left >= r.right ||
        rect.top <= r.bottom ||
        rect.bottom >= r.top
      );

    makeVehPosMarker = (pos, SpeedEnough) => {
        const MarkerHeight = MapValue.POS_MARKER_SIZE + MapValue.MARKER_LABEL_HEIGHT;
        const MarkerAnchorX = MapValue.LABEL_CONTAINER_WIDTH / 2;

        let iconFile, useRunColor=false;
        if(pos.posSys==='C') iconFile = '/cellpos_w22.png';
        else {
            if(pos.reportAgeSec > 1800) iconFile = '/park_w22.png';
            else {
                if(pos.speed < SpeedEnough) iconFile = '/stopped_22x22.png';
                else {
                    var dir = degreeToIndex16(pos.direction % 360);
                    iconFile = '/arrow_' + dir + '.png';
                    useRunColor = true;
                }
            }
        }
        const iconUrl = MapValue.VEH_ICON_DIR + 'black' + iconFile;
        
        let fontColor, backColor;
        if(useRunColor) {
            fontColor = pos.operFontColor;
            backColor = pos.operBackColor;
        }
        else {
            fontColor = pos.noOperFontColor;
            backColor = pos.noOperBackColor;
        }

        const marker = new NM.Marker({
            position: new NM.LatLng(pos.lat, pos.lon),
            userData: {
                type:MapValue.MarkerType.VEH_POS,
                data:pos,
            },
            // map: map,
            icon: {
                content: 
                    `<div style="
                        z-index: ${MapValue.VEHPOS_Z_INDEX};
                        display: inline-block; font-size:8pt;
                        height:${MarkerHeight}px;
                        width:${MapValue.LABEL_CONTAINER_WIDTH}px;
                        text-align: center;">
                        <img src="${iconUrl}" style="width:22;height:22;" />
                        <div style="width:${MapValue.LABEL_CONTAINER_WIDTH}px;height: 24px;margin: 0px;">
                            <div class="label" style="background-color:${backColor};color:${fontColor};">
                                ${pos.vehAlias}
                            </div>
                        </div>
                    </div>`,
                size: new NM.Size(MapValue.LABEL_CONTAINER_WIDTH, MarkerHeight),
                anchor: new NM.Point(MarkerAnchorX, MapValue.POS_MARKER_SIZE/2),
            },
            draggable: false,
            zIndex: MapValue.VEHPOS_Z_INDEX
        });

        return marker;
    };*/

    findMarker = (key) => {
        let index = 0;
        for(const marker of this.markers) {
            if(marker.key===key) return [index, true];
            index++;
        }
        index = 0;
        for(const marker of this.markerPool) {
            if(marker.key===key) return [index, false];
            index++;
        }
        return [-1, undefined];
    };

    getMarkerSet = (key) => {
        const [idx, visible] = this.findMarker(key);
        if(idx < 0) return undefined;
        return this.markers[idx];
    };

    pushMarkerSet = (markerSet) => {
        this.markerPool.push(markerSet);
    };

    removeMarker = (key) => {
        const [idx, visible] = this.findMarker(key);
        if(idx < 0) return undefined;
        if(visible) {
            const [markerObj] = this.markers.splice(idx,1);
            for(const listener of markerObj.listeners)
                NM.Event.removeListener(listener);
            markerObj.marker.setMap(null);
        }
        else {
            const [marker] = this.markerPool.splice(idx,1);
            for(const listener of marker.listeners)
                NM.Event.removeListener(listener);
        }
    };


    /**
     * 주어진 배열의 모든 요소들에 대해 마커를 만들어 저장하고 표시함.
     * @param {number} markerType - marker type to draw
     * @param {Array} records - array of vehPos, poi, etc
     * @param {object} options - marker 생성 옵션들. {speedEnough, callbacks}
     */
    drawMarkers = (markerType, records, options) => {
        // const markerType = options.markerType; // MapValue.MarkerType.*
        const callbacks = options.callbacks; // 이벤트와 핸들러 짝. {eventKey, handler}
        let key, generator;
        if(markerType===MapValue.MarkerType.VEH_POS) {
            key = 'vehId';
            generator = (rec, options, isLast) => {return MarkerUtil.generateVehPosMarker(rec, options.speedEnough)};
        }
        else if(markerType===MapValue.MarkerType.CLUSTER) {
            key = 'clusterId';
            generator = (rec, options, isLast) => {return MarkerUtil.generateClusterMarker(rec)};
        }
        else if(markerType===MapValue.MarkerType.POI) {
            key = 'poiId';
            generator = (rec, options, isLast) => {return MarkerUtil.generatePoiMarker(rec)};
        }
        else if(markerType===MapValue.MarkerType.ROUTE_POINT) {
            key = 'gpsTime';
            generator = (rec, options, isLast) => {return MarkerUtil.generateRoutePointMarker(rec, options.speedEnough, isLast)}
        }
        else {
            return;
        }

        this.clear();
        this.markerType = markerType;
        const path = [];
        const lastIndex = records.length-1;
        let idx=0;

        for(const rec of records) {
            const primeKey = rec[key];
            const marker = generator(rec, options, lastIndex===idx);
                //markerType===MapValue.MarkerType.VEH_POS
                //? this.makeVehPosMarker(rec, options.speedEnough)
                //: this.makePoiMarker(rec);

            const listeners = [];
            if(callbacks) {
                for(const pair of callbacks) {
                    const {eventKey, callback} = pair;
                    const listener = NM.Event.addListener(marker, eventKey, callback);
                    listeners.push(listener);
                }
            }
            this.markerPool.push({key: primeKey, marker:marker, listeners: listeners});

            if(markerType===MapValue.MarkerType.ROUTE_POINT) {
                if(rec.midPoints) {
                    // Cellizion 단말기에 뭔가 오류가 있음. 데이터와 같은 위치가 중간위치에 들어옴.
                    for(let i=0; i<rec.midPoints.length; i++) {
                        const p = rec.midPoints[i];
                        if(rec.lat !== p.lat || rec.lon !== p.lon)
                            path.push(new NM.LatLng(p.lat, p.lon));
                    }
                }
                path.push(new NM.LatLng(rec.lat, rec.lon));

                if(rec.stayBox) {
                    const stayRect = new NM.Rectangle({
                        map:null,
                        bounds: new NM.LatLngBounds(
                            new NM.LatLng(rec.stayBox.south-0.0005, rec.stayBox.west-0.0006), // south-west
                            new NM.LatLng(rec.stayBox.north+0.0005, rec.stayBox.east+0.0006)  // north-east
                        ),
                        strokeColor: AppPalette.PrimaryRGB,
                        strokeWeight: 3,
                        fillColor: '#00f',
                        fillOpacity: 0.5
                    });
                    this.markerPool.push({key:'stay-'+primeKey, marker:stayRect, listeners:[]});
                    const itinMarker = MarkerUtil.generateStayMarker(rec);
                    this.markerPool.push({key:'itin-'+primeKey, marker:itinMarker, listeners:[]});
                }
            }
            idx++;
        }

        
        if(path.length > 1) {
            if(markerType===MapValue.MarkerType.ROUTE_POINT) {
                this.layer = new NM.Polyline({
                    path: path,
                    strokeColor: '#ff0000',
                    strokeOpacity: 1,
                    strokeWeight: 3
                });
            } // map.fitBounds(self.models.vehRoutePolyline.getBounds());
        }
        this.showAll();
    };

    /**
     * Make a new marker and show where a new POI should be added.
     * @param {number} lat - lattitude
     * @param {number} lon - longitude
     */
    makeAndShowNewPoiMarker = (lat, lon, onMove) => {
        const newMarker = new NM.Marker({
            position: new NM.LatLng(lat, lon),
            map: this.mapShell.getMap(),
            clickable: true,
            draggable: true,
            zIndex: MapValue.POI_Z_INDEX+2
        });
        this.markers.push({key:NEW_POI_KEY, marker: newMarker, listeners:[
            NM.Event.addListener(newMarker, MapEvent.DRAG_END, onMove)
        ]});
    };

    /**
     * POI Polygon의 중간마커들을 모두 숨긴 후 삭제한다.
     */
    clearMidMarkers = () => {
        if(this.midMarkers.length>0) {
            for(const mm of this.midMarkers) {
                for(const listener of mm.listeners)
                    NM.Event.removeListener(listener);
                mm.marker.setMap(null);
            }
            this.midMarkers = [];
        }
    };

    /**
     * 지점 출발도착구역을 위한 polygon을 그리고 Handle marker, Mid marker를 모두 그려야 함.
     * @param {PolygonWork} polygonWork - 폴리곤 관리용 class 객체
     * @param {object} options - 기타 선택사항.
     */
    drawEditingPoiPolygon = (polygonWork, options) => {
        const posArray = polygonWork.getToDraw();
        

        const vertexHandlers = options.vertexHandlers;
        const midMarkerHandlers = options.midMarkerHandlers;

        // 이전 마커가 있으면 먼저 지워야 함. 단, 신규 지점용 마커는 빼고.
        let newMarker = undefined;
        if(this.markers.length > 0) {
            if(this.markers[0].key===NEW_POI_KEY) {
                newMarker = this.markers.shift();
            }
        }
        
        // midMarkers 별도로..
        this.clearMidMarkers();
        this.clear();

        if(newMarker) this.markers.push(newMarker);

        if(posArray.length<4) {
            return;
        };

        const map = this.mapShell.getMap();

        this.layer = new NM.Polygon({
            map: map,
            paths: [posArray.map((p)=>new NM.LatLng(p.lat,p.lon))],
            fillColor: '#f00',
            fillOpacity: 0.25,
            strokeColor: '#f00',
            strokeOpacity: 0.6,
            strokeWeight: 1
        });

        let pos = posArray.shift();
        let cnt = 0;
        while(posArray.length>0) {
            cnt++;
            const listeners = [];
            const vertexMarker = new NM.Marker({
                position: new NM.LatLng(pos.lat, pos.lon),
                map: map,
                userData: {key:cnt, index:cnt-1, position:pos},
                clickable: true,
                draggable: true,
                icon: {
                    style:'circle', radius:5, strokeWeight:2, strokeColor:'#000', fillColor:'#ff0',
                    fillOpacity:0.5,
                    anchor:NM.Position.CENTER
                },
                zIndex: MapValue.POI_Z_INDEX+5
            });

            if(vertexHandlers) {
                for(const pair of vertexHandlers) {
                    const {eventKey, callback} = pair;
                    const listener = NM.Event.addListener(vertexMarker, eventKey, callback);
                    listeners.push(listener);
                }
            }

            this.markers.push({key:cnt, marker:vertexMarker, listeners: listeners});

            const pos2 = posArray.shift();
            const midLat = (pos.lat + pos2.lat)/2;
            const midLon = (pos.lon + pos2.lon)/2;
            const midListen = [];

            const midMarker = new NM.Marker({
                position: new NM.LatLng(midLat, midLon),
                map: map,
                userData: {key:-cnt, index:cnt, position:pos},
                clickable: true,
                draggable: true,
                icon: {
                    style:'circle', radius:7, strokeWeight:2, strokeColor: '#f00', fillColor:'#fff',
                    fillOpacity:0.9, anchor:NM.Position.CENTER
                },
                zIndex: MapValue.POI_Z_INDEX+10
            });

            if(midMarkerHandlers) {
                for(const pair of midMarkerHandlers) {
                    const {eventKey, callback} = pair;
                    const listener = NM.Event.addListener(midMarker, eventKey, callback);
                    midListen.push(listener);
                }
            }

            this.midMarkers.push({key:cnt, marker:midMarker, listeners: midListen});
            pos = pos2;
        }
    };

    /**
     * 지점 출발도착구역을 위한 polygon을 그린다.
     * @param {PolygonWork} polygonWork - 폴리곤 관리용 class 객체
     */
    drawPolygon = (polygonWork) => {
        const posArray = polygonWork.getToDraw();
        this.clear();

        if(posArray.length<4) {
            return;
        };

        const map = this.mapShell.getMap();

        this.layer = new NM.Polygon({
            map: map,
            paths: [posArray.map((p)=>new NM.LatLng(p.lat,p.lon))],
            fillColor: '#0f0',
            fillOpacity: 0.25,
            strokeColor: '#f00',
            strokeOpacity: 0.6,
            strokeWeight: 1
        });
    };

    /**
     * 기존과 같은 수의 꼭지점이 있고 일부 위치만 바뀐 경우에 사용한다.
     * 폴리곤을 새로 그려주고 중간위치 마커의 위치도 바꾼다.
     * @param {PolygonWork} polygonWork - 폴리곤 관리용 class 객체
     * @returns 
     */
    setPoiPolygonPath = (polygonWork) => {
        const posArray = polygonWork.getToDraw();
        if(posArray.length<4) return;
        this.layer.setPath(posArray.map((p)=>new NM.LatLng(p.lat,p.lon)));

        let pos = posArray.shift();
        let cnt = 0;
        while(posArray.length>0) {
            const pos2 = posArray.shift();
            const midLat = (pos.lat + pos2.lat)/2;
            const midLon = (pos.lon + pos2.lon)/2;
            this.midMarkers[cnt].marker.setPosition(new NM.LatLng(midLat, midLon));
            cnt++;
            pos = pos2;
        }
    };

    /**
     * 중간마커를 끌 때 폴리곤만 새로 그려준다.
     * @param {PolygonWork} polygonWork - 폴리곤 관리용 class 객체
     */
    refreshPoiPolygonPath = (polygonWork) => {
        const posArray = polygonWork.getToDraw();
        if(posArray.length<4) return;
        this.layer.setPath(posArray.map((p)=>new NM.LatLng(p.lat,p.lon)));
    };

    getAreaSize = () => this.layer.getAreaSize ? this.layer.getAreaSize() : 0;

    // ################### Naver Route #################
    setNaverRouteStartPos = (pos, isStart) => {
        // public/img/misc/add_navi_from_30x22.gif, public/img/misc/add_navi_to_30x22.gif
        const iconFile = isStart ? NAVER_ROUTE_START_ICON : NAVER_ROUTE_END_ICON;
        this.removeMarker(iconFile);
        const marker = MarkerUtil.generateNaverRouteMarker(pos, MapValue.MISC_DIR + iconFile, 30, 22);
        marker.setMap(this.mapShell.getMap());
        this.markers.push({key:iconFile, marker:marker, listeners: []});
    };

    /**
     * Draw polylines connected and put them into markers array. (not as this.layer)
     * @param {object} route NaverRoute object
     */
    drawNaverRoute = (route) => {
        const paths = route.getPathsToDraw();
        let idx=0, south=90, north=-99, east=-180, west=180;
        const map = this.mapShell.getMap();

        for(const guide of paths) {
            const polygon = new NM.Polyline({
                map: map,
                path: guide.path.map((p)=>new NM.LatLng(p.lat,p.lon)),
                strokeColor: guide.color,
                strokeOpacity: 0.6,
                strokeWeight: 5
            });
            const b = polygon.getBounds();
            this.markers.push({key:'polygon'+idx, marker: polygon, listeners:[]});

            if(b._ne._lng > east) east = b._ne._lng;
            if(b._ne._lat > north) north = b._ne._lat;
            if(b._sw._lng < west) west = b._sw._lng;
            if(b._sw._lat < south) south = b._sw._lat;
        }

        const bounds = new NM.LatLngBounds(
            new NM.LatLng(south, west),
            new NM.LatLng(north, east)
        );
        map.fitBounds(bounds);
        /*
        new naver.maps.LatLngBounds(sw, ne) - sw	naver.maps.LatLng	남서쪽의 위/경도 좌표
                this.layer = new NM.Polyline({
                    path: path.map((p)=>new NM.LatLng(p.lat,p.lon)),
                    strokeColor: guide.color,
                    strokeOpacity: 1,
                    strokeWeight: 3
                });
        */
    };
}