// address_group/AddressGroupGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const AddressGroupDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        addrGroupId : { toAdd: false, toEdit: true},
        addrGroupName : {
            id: 'addrGroupName', label: '주소그룹', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '16자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.textInRange(value,1,50)},
        },
    },
    errorTitle: {
        Add: '주소그룹 생성 오류',
        Edit: '주소그룹정보 수정 오류',
        Remove: '주소그룹 삭제 오류',
        List: '주소그룹 조회 오류',
    }
};

const ADDRESS_GROUP_LIST_PART = gql`
fragment AddressGroupListPart on AddressGroup {
    custId
    addrGroupId
    addrGroupName
    regUserId
    regTime
}
`;

export const LIST_ADDRESS_GROUP = gql`
${ADDRESS_GROUP_LIST_PART}
query ListAddressGroup {
    addressGroupList {
        ...AddressGroupListPart
    }
}
`;


export const ADD_ADDRESS_GROUP = gql`
mutation AddAddressGroup($addressGroup:InputAddressGroupAdd!) {
  addressGroupAdd(addressGroup: $addressGroup) {
    ok
    message
    list {
        addrGroupId
        addrGroupName
    }
  }
}
`;

export const EDIT_ADDRESS_GROUP = gql`
mutation EditAddressGroup($addressGroup:InputAddressGroupEdit!) {
  addressGroupEdit(addressGroup: $addressGroup) {
    ok
    message
    list {
        addrGroupId
        addrGroupName
    }
  }
}
`;

export const REMOVE_ADDRESS_GROUP = gql`
mutation RemoveAddressGroup($addressGroup:InputAddressGroupRemove!) {
  addressGroupRemove(addressGroup: $addressGroup) {
    ok
    message
    list {
        addrGroupId
        addrGroupName
    }
  }
}
`;
