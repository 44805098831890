// com/common/GeoConvert.js

// 상수들
const EPSLN = 0.0000000001;
//const S2R = 4.84813681109536E-06;
	
const X_W2B = 128;
const Y_W2B = -481;
const Z_W2B = -664;
	
// GeoEllips - 타원체 종류(생성자 인수)
export const GeoEllips = {Bessel1984:0, WGS84:1}; // const EllipsBessel1984	= 0; const EllipsWGS84	= 1;
// GeoSystem - 좌표계 종류(생성자 인수)
export const GeoSystem = {
    Geographic:0,
    TmWest		: 1,
    TmMid		: 2,
    TmEast		: 3,
    Katec		: 4,
    Utm52		: 5,
    Utm51		: 6,
};
	
//GeoEllipses
const C_arMajor = [6377397.155, 6378137.0];
const C_arMinor = [6356078.96325, 6356752.3142];
// GeoSystems
const C_arScaleFactor = [1, 1, 1, 1, 0.9999, 0.9996, 0.9996 ];
const C_arLonCenter = [ 0.0, 2.18171200985643, 2.21661859489632, 2.2515251799362, 2.23402144255274, 2.25147473507269, 2.14675497995303 ];
const C_arLatCenter = [ 0.0, 0.663225115757845, 0.663225115757845, 0.663225115757845, 0.663225115757845, 0.0, 0.0 ];
const C_arFalseNorthing = [ 0.0, 500000.0, 500000.0, 500000.0, 600000.0, 0.0, 0.0 ];
const C_arFalseEasting = [ 0.0, 200000.0, 200000.0, 200000.0, 400000.0, 500000.0, 500000.0 ];

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Internal Value calculation Functions
function toDegrees(radian) {return radian * 180 / Math.PI;};
function toRadians(degree) {return degree * Math.PI / 180;};
function e0fn(x) { return 1.0 - 0.25 * x * (1.0 + x / 16.0 * (3.0 + 1.25 * x)); }; // - out
function e1fn(x) { return 0.375 * x * (1.0 + 0.25 * x * (1.0 + 0.46875 * x)); }; // -out
function e2fn(x) { return 0.05859375 * x * x * (1.0 + 0.75 * x); };
function e3fn(x) { return x * x * x * (35.0 / 3072.0); };
//function e4fn(x) { return Math.sqrt( Math.pow(1 + x, 1 + x) * Math.pow(1 - x, 1 - x) ); };

function mlfn( e0, e1, e2, e3, phi ) {
    return e0 * phi - e1 * Math.sin(2 * phi) + e2 * Math.sin(4 * phi) - e3 * Math.sin(6 * phi);
};

function asinz(value) {
    if(Math.abs(value) > 1 ) return Math.asin(value>0 ? 1 : -1);
    return Math.asin(value);
};

class GeoPoint {
    x=0;
    y=0;

    constructor(x,y) {
        this.x = x;
        this.y = y;
    }
    
    getX = () => { return this.x; };
    getY = () => { return this.y; };
    getLat = () => this.y;
    getLon = () => this.x;
    setX = (x) => { this.x = x; return this; };
    setY = (y) => { this.y = y; return this; };
    setLat = (lat) => {this.y = lat; return this;};
    setLon = (lon) => {this.x = lon; return this;};
};

export default class GeoConvert {
    /**
     * 좌표변환 클래스.
     * @param {number} eSrcEllips - 소스 타원체 지정
     * @param {number} eSrcSystem - 소스 좌표계 지정
     * @param {number} eDstEllips - 타겟 타원체 지정
     * @param {number} eDstSystem - 타겟 좌표계 지정
     */
    constructor(eSrcEllips, eSrcSystem, eDstEllips, eDstSystem) {
        //
		// member 변수들
		this.VERSION = '0.1';
		//
		this.m_srcEllips = eSrcEllips;	// int
		this.m_srcSys = eSrcSystem;	// int
		this.m_dstEllips = eDstEllips;	// int
		this.m_dstSys = eDstSystem;	// int

		// doubles except two.
		this.m_iDeltaX	= 0; // 0, 
		this.m_iDeltaY = 0; // 0, 
		this.m_iDeltaZ = 0; // 0,
		this.m_dTemp		= 0; // 0, 
		this.m_dEsTemp = 0; // 0,
		this.m_dDeltaA	= 0; // 0, 
		this.m_dDeltaF = 0; // 0, 
		this.m_dSrcEs	= 0; // 0, 
		this.m_dSrcE = 0; // 0, 
		this.m_dSrcE0 = 0; // 0, 
		this.m_dSrcE1 = 0; // 0, 
		this.m_dSrcE2 = 0; // 0, 
		this.m_dSrcE3 = 0; // 0,
		this.m_dSrcMl0	= 0; // 0, 
		this.m_dSrcEsp = 0; // 0, 
		this.m_dSrcInd = 0; // 0, int
		this.m_dDstEs	= 0; // 0, 
		this.m_dDstE = 0; // 0, 
		this.m_dDstE0 = 0; // 0, 
		this.m_dDstE1 = 0; // 0, 
		this.m_dDstE2 = 0; // 0, 
		this.m_dDstE3 = 0; // 0,
		this.m_dDstMl0	= 0; // 0, 
		this.m_dDstEsp = 0; // 0, 
		this.m_dDstInd = 0; // 0, int
		
		//this.m_done_setSrcType = false;
		//this.m_done_setDstType = false;
		
		//this.setSrcType(eSrcEllips, eSrcSystem);
	
		let temp = C_arMinor[this.m_srcEllips] / C_arMajor[this.m_srcEllips];
		this.m_dSrcEs = 1 - temp * temp;
		this.m_dSrcE = Math.sqrt(this.m_dSrcEs);
		this.m_dSrcE0 = e0fn(this.m_dSrcEs);
		this.m_dSrcE1 = e1fn(this.m_dSrcEs);
		this.m_dSrcE2 = e2fn(this.m_dSrcEs);
		this.m_dSrcE3 = e3fn(this.m_dSrcEs);

		this.m_dSrcMl0
            = C_arMajor[this.m_srcEllips]
            * mlfn(
                this.m_dSrcE0,
                this.m_dSrcE1,
                this.m_dSrcE2,
                this.m_dSrcE3,
                C_arLatCenter[this.m_srcSys]
            );

		this.m_dSrcEsp = this.m_dSrcEs / (1 - this.m_dSrcEs);
	
		this.m_dSrcInd = this.m_dSrcEs < 0.00001 ? 1 : 0;

		//this.setDstType(eDstEllips, eDstSystem);
		temp = C_arMinor[this.m_dstEllips] / C_arMajor[this.m_dstEllips];
		this.m_dDstEs = 1 - temp * temp;
		this.m_dDstE  = Math.sqrt(this.m_dDstEs);
		this.m_dDstE0 = e0fn(this.m_dDstEs);
		this.m_dDstE1 = e1fn(this.m_dDstEs);
		this.m_dDstE2 = e2fn(this.m_dDstEs);
		this.m_dDstE3 = e3fn(this.m_dDstEs);
		this.m_dDstMl0
            = C_arMajor[this.m_dstEllips]
            * mlfn(
                this.m_dDstE0,
                this.m_dDstE1,
                this.m_dDstE2,
                this.m_dDstE3,
                C_arLatCenter[this.m_dstSys]
            );
		this.m_dDstEsp = this.m_dDstEs / (1 - this.m_dDstEs);
	
		this.m_dDstInd = this.m_dDstEs < 0.00001 ? 1 : 0;
		
		// initDatumVar
		let iDefFact = this.m_srcEllips - this.m_dstEllips;

		this.m_iDeltaX = iDefFact * X_W2B;
		this.m_iDeltaY = iDefFact * Y_W2B;
		this.m_iDeltaZ = iDefFact * Z_W2B;

		this.m_dTemp = C_arMinor[this.m_srcEllips] / C_arMajor[this.m_srcEllips];
		//dF = 1.0 - m_dTemp; // flattening
		this.m_dEsTemp = 1.0 - this.m_dTemp * this.m_dTemp; // e2
	
        // output major axis - input major axis
		this.m_dDeltaA = C_arMajor[this.m_dstEllips] - C_arMajor[this.m_srcEllips];

        // Output Flattening - input flattening
		this.m_dDeltaF
            = C_arMinor[this.m_srcEllips] / C_arMajor[this.m_srcEllips]
            - C_arMinor[this.m_dstEllips] / C_arMajor[this.m_dstEllips];
    }
    
    /**
     * Convert GeoPoint
     * @param {GeoPoint} p position 
     * @returns position converted
     */
    convert = (p) => {
        return this.convertXY(p.getX(), p.getY());
    };

    /**
     * Convert and return array
     * @param  {...any} args - ([lat, lon]) or (lat, lon)
     * @returns 위도와 경도로 이루어진 배열
     */
    convertLanLonArray = (...args) => {
        let x, y;
        if(args.length===1) {
            y=args[0][0];
            x=args[0][1];
        }
        else if(args.length>1) {
            y=args[0];
            x=args[1];
        }
        else return null;
        const r = this.convertXY(x, y);
        return [r.y, r.x];
    };
    
    /**
     * Main Convert Function
     * @param {number} dInX - 경도 대응 값
     * @param {number} dInY - 위도 대응 값
     * @returns 
     */
    convertXY = function( dInX, dInY ) {
        let dInLon, dInLat, dOutLon, dOutLat, dOutX, dOutY; // dTmX, dTmY , 
        let inp, outp; // GeoPoint
        
        // Convert to Radian Geographic
        if (this.m_srcSys === GeoSystem.Geographic ) {
            dInLon = toRadians(dInX);
            dInLat = toRadians(dInY);
        }
        else {
            inp = this.Tm2Geo(new GeoPoint(dInX, dInY));
            //( $dInLon, $dInLat ) = $this->Tm2Geo($dInX, $dInY, ); # Geographic calculating
            dInLon = inp.getX();
            dInLat = inp.getY();
        }
    
        if (this.m_srcEllips === this.m_dstEllips ) {
            dOutLon = dInLon;
            dOutLat = dInLat;
        }
        else  {
            outp = this.datumTrans(new GeoPoint(dInLon, dInLat));
            dOutLon = outp.getX();
            dOutLat = outp.getY();
        }
    
        // now we should make a output. but it depends on user options
        if (this.m_dstSys === GeoSystem.Geographic) { // if output option is latitude & longitude
            dOutX = toDegrees(dOutLon);
            dOutY = toDegrees(dOutLat);
        }
        else { // if output option is cartesian systems
            //GeoPoint
            const tm = this.Geo2Tm(new GeoPoint(dOutLon, dOutLat) );
            dOutX = tm.getX();
            dOutY = tm.getY();
        }
        return new GeoPoint( dOutX, dOutY );
    };
		
    /**
     * Converting longitude, latitude to TM X, Y
     * @param {GeoPoint} inp - input data
     * @returns Position converted to TM
     */
    Geo2Tm = (inp) => {
        const lon = inp.getX();
        const lat = inp.getY();

        let delta_lon, // Delta longitude (Given longitude - center longitude)
            sin_phi, cos_phi, // sin and cos value
            al, als, var_b, var_c, var_t, tq, // temporary values
            con, var_n, ml; // cone constant, small m
        let xv, yv;
        // LL to TM Forward equations from here
        delta_lon = lon - C_arLonCenter[this.m_dstSys];
        sin_phi = Math.sin(lat);
        cos_phi = Math.cos(lat);
    
        if (this.m_dDstInd !== 0)  {
            var_b = cos_phi * Math.sin(delta_lon);
            if ((Math.abs(Math.abs(var_b) - 1)) < 0.0000000001) {
                console.log( "Point goes infinite.");
                return(null);
            }
        }
        else {
            var_b = 0;
            xv = 0.5 * C_arMajor[this.m_dstEllips]
                * C_arScaleFactor[this.m_dstSys]
                * Math.log((1.0 + var_b) / (1.0 - var_b));

            con = Math.acos(cos_phi * Math.cos(delta_lon) / Math.sqrt(1.0 - var_b * var_b));

            if (lat < 0) {
                con = -con;
                yv = C_arMajor[this.m_dstEllips]
                     * C_arScaleFactor[this.m_dstSys]
                     * (con - C_arLatCenter[this.m_dstSys]);
            }
        }
        // 위 else 블락도 이상하다. 없어도 될 것 같다.
        // 그 안에서 xv, yv를 결정하는데, 아래로 가면 두 값이 새로이 결정된다.
    
        al	= cos_phi * delta_lon;
        als	= al * al;
        var_c	= this.m_dDstEsp * cos_phi * cos_phi;
        tq	= Math.tan(lat);
        var_t	= tq * tq;
        con	= 1.0 - this.m_dDstEs * sin_phi * sin_phi;
        var_n	= C_arMajor[this.m_dstEllips] / Math.sqrt(con);
        ml	= C_arMajor[this.m_dstEllips] 
            * mlfn(this.m_dDstE0, this.m_dDstE1, this.m_dDstE2, this.m_dDstE3, lat);
    
        xv = C_arScaleFactor[this.m_dstSys]
            * var_n * al * (
                1 + als / 6 * (
                    1 - var_t + var_c + als / 20 * (
                        5 - 18 * var_t + var_t * var_t + 72 * var_c - 58 * this.m_dDstEsp
                    )
                )
            ) + C_arFalseEasting[this.m_dstSys];

        yv = C_arScaleFactor[this.m_dstSys] * (
            ml - this.m_dDstMl0 + var_n * tq * (
                als * (0.5 + als / 24 * (
                    5 - var_t + 9 * var_c + 4 * var_c * var_c + als / 30 * (
                        61 - 58 * var_t + var_t * var_t + 600 * var_c - 330 * this.m_dDstEsp
                    )
                ))
            )
        ) + C_arFalseNorthing[this.m_dstSys];

        return new GeoPoint(xv, yv);
    };
	
    /**
     * Converting TM X,Y to Longitude and Latitude
     * @param {GeoPoint} inp - input data
     * @returns Position converted to lat/lon
     */
    Tm2Geo = (inp) => {
        let xv = inp.getX();
        let yv = inp.getY();

        let con, phi; // temporary angles
        let delta_Phi; // difference between longitudes
        let i; // int
        let
            sin_phi, cos_phi, tan_phi,
            var_c, cs, var_t, ts, var_n, var_r, var_d, ds, // var_f, var_h, var_g, temp,
            lat, lon;
        
        const max_iter = 6; // constant maximun number of iterations
    
        /* Seems to be redundant !!!
        # 카텍에서 경위도로 변환하는 함수인데
        # 소스인 카첵좌표의 타원체는 항상 Bessel이다. 즉 0이다.
        # 아래 if 블락이 꼭 필요한 것이고 그 아래가 else라고 하더라도 우리가 필요한 경우는 Bessel인 else 블락이므로
        # 아래 부분이 없다고 단정해도 문제는 없다.
        # 어쩄든 오리지널 소스가 없어서 제대로 확인은 어렵다.

        if (this.m_dSrcInd !== 0) {
            var_f = Math.exp(xv / (C_arMajor[this.m_srcEllips] * C_arScaleFactor[this.m_srcSys]));
            var_g = 0.5 * (var_f - 1 / var_f);
            temp = C_arLatCenter[this.m_srcSys]
                + yv / (C_arMajor[this.m_srcEllips] * C_arScaleFactor[this.m_srcSys]);
            var_h = Math.cos(temp);
            con = Math.sqrt((1 - var_h * var_h) / (1 + var_g * var_g));
            lat = asinz(con);
    
            if (temp < 0) { lat *= -1; }
    
            if ((var_g === 0) && (var_h === 0)) {
                lon = C_arLonCenter[this.m_srcSys];
            }
            else {
                lon = Math.atan(var_g / var_h) + C_arLonCenter[this.m_srcSys];
            }
        }
        */


        //# TM to LL inverse equations from here
        xv -= C_arFalseEasting[this.m_srcSys];
        yv -= C_arFalseNorthing[this.m_srcSys];
        
    
        con = (this.m_dSrcMl0 + yv / C_arScaleFactor[this.m_srcSys]) / C_arMajor[this.m_srcEllips];
        phi = con;

        i = 0;
        do {
            delta_Phi = ((con + this.m_dSrcE1 * Math.sin(2 * phi)
                - this.m_dSrcE2 * Math.sin(4 * phi)
                + this.m_dSrcE3 * Math.sin(6 * phi)) / this.m_dSrcE0) - phi;
            phi = phi + delta_Phi;
    
            if (i >= max_iter) {
                if(window.console && console.log) console.log( "Latitude failed to converge" );
                return null;
            }
    
            i++;
        } while(Math.abs(delta_Phi) > EPSLN);
        
        if (Math.abs(phi) < (Math.PI/2) ) { //# (pi / 2)) {
            sin_phi = Math.sin(phi);
            cos_phi = Math.cos(phi);
            tan_phi = Math.tan(phi);
            var_c	= this.m_dSrcEsp * cos_phi * cos_phi;
            cs	= var_c * var_c;
            var_t	= tan_phi * tan_phi;
            ts	= var_t * var_t;
            con	= 1.0 - this.m_dSrcEs * sin_phi * sin_phi;
            var_n	= C_arMajor[this.m_srcEllips] / Math.sqrt(con);
            var_r	= var_n * (1.0 - this.m_dSrcEs) / con;
            var_d	= xv / (var_n * C_arScaleFactor[this.m_srcSys]);
            ds	= var_d * var_d;
            lat	= phi - (var_n * tan_phi * ds / var_r) * (
                0.5 - ds / 24 * (
                    5 + 3 * var_t + 10 * var_c - 4 * cs - 9 * this.m_dSrcEsp
                    - ds / 30 * (
                        61 + 90 * var_t + 298 * var_c + 45 * ts
                        - 252 * this.m_dSrcEsp - 3 * cs
                    )
                )
            );
            lon	= C_arLonCenter[this.m_srcSys] + (
                var_d * (1 - ds / 6 * (
                    1 + 2 * var_t + var_c - ds / 20 * (
                        5 - 2 * var_c + 28 * var_t
                        - 3 * cs + 8 * this.m_dSrcEsp + 24 * ts
                    )
                )
            ) / cos_phi);
        }
        else
        {
            lat = Math.PI * 0.5 * Math.sin(yv);
            lon = C_arLonCenter[this.m_srcSys];
        }
        return new GeoPoint(lon, lat);
    };
		
    // Molodensky Datum Transformation function for general datum transformation.
    // Coded by Shin, Sanghee(endofcap@geo.giri.co.kr) 24th Feb, 1999
    // Reference manual : DEFENSE MAPPING AGENCY TECHNICAL MANUAL 8358.1
    // You can read above manual in this home page. http://164.214.2.59/GandG/tm83581/toc.htm
    // Converted to C++ by Jang, Byyng-jin(jangbi@taff.co.kr) 20th Ap
    /**
     * Converting somthing I don't know.
     * @param {GeoPoint} inp - input data
     * @returns Position converted to something
     */
    datumTrans = (inp) => {
        const dInLon = inp.getX();
        const dInLat = inp.getY();
        
        let dRm
            = C_arMajor[this.m_srcEllips] * (1 - this.m_dEsTemp)
            / Math.pow( ( 1 - this.m_dEsTemp * Math.sin(dInLat) * Math.sin(dInLat) ) , 1.5 );
        let dRn = C_arMajor[this.m_srcEllips] / Math.sqrt(1 - this.m_dEsTemp * Math.sin(dInLat) * Math.sin(dInLat));
    
        let dDeltaPhi = (
            (
                (
                    ( -this.m_iDeltaX * Math.sin(dInLat) * Math.cos(dInLon) - this.m_iDeltaY * Math.sin(dInLat) * Math.sin(dInLon) )
                    + this.m_iDeltaZ * Math.cos(dInLat)
                )
                + this.m_dDeltaA * dRn * this.m_dEsTemp * Math.sin(dInLat) * Math.cos(dInLat) / C_arMajor[this.m_srcEllips]
            )
            + this.m_dDeltaF * (dRm/this.m_dTemp + dRn*this.m_dTemp) * Math.sin(dInLat) * Math.cos(dInLat)
        ) / dRm;
        let dDeltaLamda = ( -this.m_iDeltaX * Math.sin(dInLon) + this.m_iDeltaY * Math.cos(dInLon)) / (dRn * Math.cos(dInLat));
        
        return new GeoPoint( dInLon + dDeltaLamda, dInLat + dDeltaPhi );
    };
}