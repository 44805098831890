// ViewRoutineDistribInput.js
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Checkbox, 
    FormControlLabel, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { AppPalette, AppNumber, CvoCodes, AppWord } from '../../model/AppConst';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ConfirmDialog from '../message/ConfirmDialog';
import VehInfoNarrowList from '../veh_info/VehInfoNarrowList';
import PoiInfoNarrowList from '../poi_info/PoiInfoNarrowList';
import { ViewRoutineDistribDictionary } from './ViewRoutineDistribGql';
import ValueUtil from "../../model/ValueUtil";
import Util from '../../model/Util';
//import FlexyTable from '../common/flexytable/FlexyTable';
//import HeightReader from '../common/HeightReader';


const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const dcPad = AppNumber.dataContainerPadding;
const ContentContainer = styled(Box)({
    display:'flex', position:'absolute', left:dcPad, top:dcPad, bottom:dcPad, right:dcPad, // backgroundColor:'#eee'
}); // has: VehicleListBox RouteAndPoiBox

const VehicleListBoxWidth = 300;
const VehicleListBox = styled(Box)({
    display:'flex', width:VehicleListBoxWidth
});

const RouteAndPoiBox = styled(Box)({
    flexGrow:1, display: 'flex', flexDirection:'column', paddingLeft:10
}); // has: PresentRouteBox, PoiPairBox

const PresentRouteBox = styled(Box)({marginBottom:10});
const PoiPairBox = styled(Box)({
    flexGrow:1, display:'flex'
}); // has: 2 SetBox // not DestinListBox, PoiListdBox

const PresentRouteListHeight = 240;
const PresentRouteListHeader = styled(Box)({
    display:'flex', alignItems:'center',
    height:AppNumber.HeaderToolBoxHeight, fontWeight:'bold', fontSize:'1.05rem'
});
const PresentRouteList = styled(Box)({
    maxHeight:PresentRouteListHeight, overflow:'auto', border: AppPalette.BorderCCC, borderRadius:5, padding: 10
});
const NoPresentRouteList = styled(Box)({padding:10, border: AppPalette.BorderCCC, borderRadius:5});
const EditWarning = styled(Box)({padding:10, marginTop:10, color:'red'});

const RouteRow = styled(Box)({display:'flex', margin:5});
const RoutePoiBox = styled(Box)({display:'inline-block', padding:10, border: AppPalette.BorderCCC, borderRadius:5});
const RouteDestinBox = styled(Box)({flexGrow:1, overflow: 'auto'});
const RouteRoadAndPoi = styled(Box)({display:'inline-block'});
const RouteRoadBox = styled(Box)({display:'inline-block'});

// -----------------------------------------------------------------
const InputMap = ViewRoutineDistribDictionary.inputMap;
const DestinListBox = styled(Box)({width:'50%', display:'flex', marginRight:10});
const DestinWrapper = styled(Box)({ flexGrow:1, position:'relative', border: AppPalette.BorderCCC, borderRadius:5, fontSize:'0.85rep' });
const DestinAbsolute = styled(Box)({
    position: 'absolute', overflow: 'auto', top:2, right:2, bottom:2, left:2
});
const DestinRow = styled(Box)({
    display:'flex', margin:2,
    '&:nth-of-type(odd)': {backgroundColor: '#eee'}
});
const CellPad = '5px 1px 5px 5px';
const DestinPoi = styled(Box)({width:150, display:'flex', alignItems:'center', padding:CellPad});
const DestinType = styled(Box)({width:120, display:'flex', alignItems:'center', padding:CellPad});
const DestinNoti = styled(Box)({flexGrow:1,  display:'flex', alignItems:'center',});
const PoiListdBox = styled(Box)({width:'50%', display:'flex'});

const InputRow = styled(Box)({display:'flex', marginTop:15, marginLeft:5});
const InputTitle = styled(Box)({width:150, display:'flex', alignItems:'center', padding:CellPad})
const InputCell = styled(Box)({flexGrow:1,  display:'flex', alignItems:'center',});

function getPoiForInput(poi, noti) {
    const pi = {poiId:poi.poiId, poiName: poi.poiName, notiYn:(noti ? noti : 'Y'), tel:poi.tel, addr:poi.addr};
    return pi;
}

const EmptyData = {
    notiFrom: 9,
    notiTill: 19,
    notiDestFrom: 9,
    notiDestTill: 19,
    destinMemo:'',
};
const IdDestList = 'destList';

export default function ViewRoutineDistribInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    poiList,
    vehList,
    routeList,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onRequestEdit,
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(EmptyData); // 입력한 데이터.
    const [vehicle, setVehicle] = useState(null);
    const [presentRoutes, setPresentRoutes] = useState([]);
    const [poiIdList, setPoiIdList] = useState([]); // 출발지와 목적지들의 poi_id
    const [destinSelected, setDestinSelected] = useState([]);
    const [poiHover, setPoiHover] = useState(undefined);
    // const [destinPoiIdList, setDestinPoiIdList] = useState([]); // destinSelected 중 poiId만 모은 것. 연산 용이. 최종 전송 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.
    //const contentRef = useRef(null);

    useEffect(()=>{
        if(vehicle && routeList.length > 0) {
            setPresentRoutes(routeList.filter((route)=>{return route.vehId===vehicle.vehId}));
        }
        else setPresentRoutes([]);
    }, [routeList, vehicle]);

    useEffect(()=>{
        if(isEdit) {
            const destin = [{poiId:item.poiId, notiYn:item.notiYn}]; // 출발지.
            for(const dest of item.destins) destin.push({poiId:dest.poiId, notiYn:dest.notiYn}); // 도착지들
            setVehicle({vehId: item.vehId, vehPlates: item.vehPlates});
            setInputData({
                vehId: item.vehId,
                // notiYn: item.notiYn, destCsv: item.destCsv, poiId: item.poiId, -- add later
                notiFrom: item.notiFrom,
                notiTill: item.notiTill,
                notiDestFrom: item.destins.length > 0 ? item.destins[0].notiFrom : 0,
                notiDestTill: item.destins.length > 0 ? item.destins[0].notiTill : 0,
                destinMemo: item.destinMemo
            });
            const poiIds = []; // destin.map((d => d.poiId));
            const ynMap = {};
            for(const p of destin) {
                poiIds.push(p.poiId);
                ynMap[p.poiId] = p.notiYn;
            }
            setDestinSelected(
                poiList
                .filter((poi)=>poiIds.includes(poi.poiId))
                .sort((a,b)=>poiIds.indexOf(a.poiId) - poiIds.indexOf(b.poiId))
                .map((poi)=>getPoiForInput(poi, ynMap[poi.poiId]))
            );
            setPoiIdList(poiIds);
        }
        else {
            setInputData(EmptyData);
            setPoiIdList([]);
            setDestinSelected([]);
        }

        setChanged(false);
        setInputError({});
        setHasError(false);
    }, [isEdit, item, poiList]);

    const checkHasError = (inputError) => {
        for(const v of Object.values(inputError)) {
            if(Boolean(v)) {
                return true;
            }
        }
        return false;
    };

    const checkError = (data) => {
        const error = {...inputError};

        error.vehId = data.vehId ? undefined : "차량이 선택되지 않았습니다.";
        error.notiFrom = InputMap.notiFrom.check(data.notiFrom) ? undefined : "출발지 출발알림 시작시각이 올바르지 않습니다."
        error.notiTill = InputMap.notiTill.check(data.notiTill) ? undefined : "출발지 출발알림 종료시각이 올바르지 않습니다."
        error.notiDestFrom = InputMap.notiDestFrom.check(data.notiDestFrom) ? undefined : "도착지 도착알림 시작시각이 올바르지 않습니다."
        error.notiDestTill = InputMap.notiDestTill.check(data.notiDestTill) ? undefined : "도착지 도착알림 종료시각이 올바르지 않습니다."
        error.destList = poiIdList.length > 1 ? undefined : "2곳 이상의 지점이 선택되어야 합니다.";
        let gotError = checkHasError(error);
        return {newError:gotError, error: error};
    };

    const changeDestinSelected = (array) => {
        const destin = array.map((poi) => poi.poiId);
        setPoiIdList(destin);
        setDestinSelected(array);
        setChanged(true);
        if(destin.length >= 2) {
            const error = {...inputError};
            error.destList = undefined;
            const gotError = checkHasError(error);
            setHasError(gotError);
            setInputError(error);
        }
    };

    const resetData = () => {
        setVehicle(null);
        changeDestinSelected([]);
        setInputData(EmptyData);
        setChanged(false);
        setInputError({});
        setHasError(false);
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const pois = [...destinSelected];
        const depPoi = pois.shift();
        const notiAndDestList = pois.map((poi)=>poi.notiYn + ":" + poi.poiId);

        const param = ValueUtil.getDataToSubmit(
            {...inputData, poiId: depPoi.poiId, notiYn: depPoi.notiYn, destCsv: notiAndDestList.join(",")},
            InputMap, isEdit);

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const changeAndCheck = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        const {newError, error} = checkError(data);
        setHasError(newError);
        setInputError(error);
        setInputData(data);
        setChanged(true);
    };

    const onClickToolOnPoiListRow = (toolId, row) => {
        if(toolId === AppWord.CMD_ADD) {
            if(inputData.vehId) {
                const oldDestin = [...destinSelected];
                oldDestin.push(getPoiForInput(row));
                changeDestinSelected(oldDestin);
            }
            else {
                Util.bubbleSnack('차량을 먼저 선택해 주세요', {duration: 5000});
            }
        }
    };



    const onChangeNotiMark = (poi, event) => {
        // row.__checked = event.target.checked; // react doesn't like this.
        const newPoi = {...poi};
        newPoi.notiYn = event.target.checked ? 'Y' : 'N';
        const destins = [...destinSelected];
        for(let i=0;i<destins.length; i++) {
            if(destins[i].poiId === newPoi.poiId) {
                destins[i] = newPoi;
                changeDestinSelected(destins);
                return;
            }
        }
    };

    const changeDestinOrder = (destin, isUp) => {
        const lastIdx = destinSelected.length - 1;
        let idx = -1;
        for(let i=0;i<destinSelected.length;i++) {
            if(destin.poiId===destinSelected[i].poiId) {
                idx = i;
                break;
            }
        }
        if(idx < 0 || (idx===0 && isUp) || (idx===lastIdx && !isUp)) return;
        const diff = isUp ? -1 : 1;
        const newDestin = destinSelected.slice();
        const rep = newDestin[idx+diff];
        newDestin[idx] = rep;
        newDestin[idx+diff] = destin;

        changeDestinSelected(newDestin);
    };

    // ########################### ########################### ########################### ###########################
    const onClickToolOnDestinListRow = (toolId, row) => {
        if(toolId === AppWord.CMD_REMOVE) {
            const newDestin = destinSelected.filter((d)=>d.poiId !== row.poiId);
            changeDestinSelected(newDestin);
        }
        else if(toolId===AppWord.CMD_UP) changeDestinOrder(row, true);
        else if(toolId===AppWord.CMD_DOWN) changeDestinOrder(row, false);
        else return;
    };

    const onClickVehicle = (veh) => {
        if(!vehicle || veh.vehId !== vehicle.vehId) {
            setVehicle(veh);
            changeAndCheck(InputMap.vehId.id, veh.vehId);
        }
    };

    const onChangHour = (event) => {
        changeAndCheck(event.target.name, event.target.value);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderVehicles = () => {
        return(
            <VehicleListBox>
                <VehInfoNarrowList
                    columnList={['vehPlates', 'driverName', 'driverMobile']}
                    records={vehList}
                    selected={vehicle}
                    onClickItem={onClickVehicle}
                />
            </VehicleListBox>
        );
    };

    const renderPoiList = () => {
        return(
            <PoiListdBox>
                <PoiInfoNarrowList
                    title="지점"
                    records={poiList.filter((poi)=>{return !poiIdList.includes(poi.poiId)})}
                    tools={[
                        {id:AppWord.CMD_ADD, icon:<AddLocationIcon fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor} />}
                    ]}
                    onClickToolOnRow={onClickToolOnPoiListRow}
                />
            </PoiListdBox>
        );
    };

    const renderInputHour = (inputMap, label, data, callback) => {
        const labelId = inputMap.id + "-LaBeL";
        return(
            <FormControl>
                <InputLabel id={labelId}>
                    {label}
                </InputLabel>
                <Select
                    name={inputMap.id}
                    id={inputMap.id} labelId={labelId} value={data || ''}
                    label={label}
                    onChange={callback}
                    size={AppWord.SMALL}
                    style={{width:90}}
                >
                    {
                        [...Array(24).keys()].map((hour) => {
                            return(
                                <MenuItem key={hour} value={hour}>{hour}시</MenuItem>
                            );
                        })
                    }
                </Select>
            </FormControl>
        );
    };

    const renderDestinList = () => {
        return(
            <DestinListBox>
                <DestinWrapper>
                    <DestinAbsolute>
                        {
                            destinSelected.length > 0
                            ?
                            <DestinRow style={{fontWeight:'bold'}}>
                                <DestinPoi>지점</DestinPoi>
                                <DestinType>구분</DestinType>
                                <DestinNoti>알림여부</DestinNoti>
                            </DestinRow>
                            :
                            <Box style={{padding:10,textAlign:'center', marginTop:30}}>
                                오른쪽 지점 테이블에서 지점을 선택해 추가해 주세요.
                            </Box>
                        }
                        {
                            destinSelected.map((poi, idx)=>{
                                return(
                                    <DestinRow key={idx}
                                        onMouseEnter={()=>{setPoiHover(poi.poiId)}}
                                        onMouseLeave={()=>{setPoiHover(undefined)}}
                                    >
                                        <DestinPoi>{poi.poiName}</DestinPoi>
                                        <DestinType>
                                            {
                                                poi.poiId === poiHover
                                                ?
                                                <>
                                                    <IconButton size={AppWord.SMALL}
                                                        onClick={()=>{onClickToolOnDestinListRow(AppWord.CMD_UP, poi)}}
                                                    >
                                                        <ArrowUpwardIcon fontSize={AppWord.SMALL} />
                                                    </IconButton>
                                                    <IconButton size={AppWord.SMALL}
                                                        onClick={()=>{onClickToolOnDestinListRow(AppWord.CMD_DOWN, poi)}}
                                                    >
                                                        <ArrowDownwardIcon fontSize={AppWord.SMALL} />
                                                    </IconButton>
                                                    <IconButton size={AppWord.SMALL}
                                                        onClick={()=>{onClickToolOnDestinListRow(AppWord.CMD_REMOVE, poi)}}
                                                    >
                                                        <WrongLocationIcon fontSize={AppWord.SMALL} color={AppPalette.WarnColor} />
                                                    </IconButton>
                                                </>
                                                :
                                                idx===0 ? '출발지' :'도착지'
                                            }
                                        </DestinType>
                                        <DestinNoti>
                                            <FormControlLabel
                                                label={idx===0 ? "출발알림" : "도착알림"}
                                                control={
                                                    <Checkbox
                                                        checked={poi.notiYn==='Y'}
                                                        onChange={(e)=>onChangeNotiMark(poi, e)}
                                                        size={AppWord.SMALL}
                                                        color={AppPalette.PrimaryColor}
                                                    />}
                                            />
                                        </DestinNoti>
                                    </DestinRow>
                                );
                            })
                        }
                        {
                            destinSelected.length > 1
                            ?
                            <>
                            <InputRow>
                                <InputTitle>출발알림허용</InputTitle>
                                <InputCell>
                                    {renderInputHour(InputMap.notiFrom, "시작시각", inputData.notiFrom, onChangHour)}
                                </InputCell>
                                <InputCell>
                                    {renderInputHour(InputMap.notiTill, "종료시각", inputData.notiTill, onChangHour)}
                                </InputCell>
                            </InputRow>
                            <InputRow>
                                <InputTitle>도착알림허용</InputTitle>
                                <InputCell>
                                    {renderInputHour(InputMap.notiDestFrom, "시작시각", inputData.notiDestFrom, onChangHour)}
                                </InputCell>
                                <InputCell>
                                    {renderInputHour(InputMap.notiDestTill, "종료시각", inputData.notiDestTill, onChangHour)}
                                </InputCell>
                            </InputRow>
                            </>
                            :
                            destinSelected.length === 1
                            ?
                            <Box style={{padding:10,textAlign:'center', marginTop:10}}>
                                2개 이상의 지점을 선택하세요
                            </Box>
                            : null
                        }
                    </DestinAbsolute>
                </DestinWrapper>
            </DestinListBox>
        );
    };


    const renderPresentRoutes = () => {
        return(
            <PresentRouteBox>
                <PresentRouteListHeader>
                    {
                        vehicle
                        ?
                        `${vehicle.vehPlates} 차량에 현재 설정되어 있는 배송노선`
                        : '차량 선택 후 배송노선 설정'
                    }
                </PresentRouteListHeader>
                {
                    !vehicle
                    ?
                    <NoPresentRouteList>
                        <ul>
                            <li>차량을 먼저 선택하세요</li>
                            <li>배송노선에 오른쪽 테이블의 지점을 추가합니다. 지점에 마우스를 올리고 오른쪽의 <AddLocationIcon
                                fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor} style={{verticalAlign:'bottom'}} /> 아이콘을
                            클릭하면 됩니다.
                            </li>
                            <li>왼쪽 테이블에서도 지점의 마우스를 올린 후 지점 삭제, 순서 변경 등을 할 수 있습니다.</li>
                            <li>배송노선의 첫번째 지점이 출발지입니다.</li>
                        </ul>
                    </NoPresentRouteList>
                    :
                    presentRoutes.length===0
                    ?
                    <NoPresentRouteList>기존에 설정되어있는 정기배송노선이 없습니다.</NoPresentRouteList>
                    :
                    <PresentRouteList>
                        {
                            presentRoutes.map((route)=>{
                                return(
                                    <RouteRow key={route.recId}>
                                        <RoutePoiBox>{route.poiName}</RoutePoiBox>
                                        <RouteDestinBox>
                                            {
                                                route.destins.map((dest, idx)=>{
                                                    return(
                                                        <RouteRoadAndPoi key={idx}>
                                                            <RouteRoadBox>--</RouteRoadBox>
                                                            <RoutePoiBox>{dest.poiName}</RoutePoiBox>
                                                        </RouteRoadAndPoi>
                                                    );
                                                })
                                            }
                                        </RouteDestinBox>
                                    </RouteRow>
                                );
                            })
                        }
                    </PresentRouteList>
                }
                {
                    isEdit && vehicle && vehicle.vehId !== item.vehId
                    ?
                    <EditWarning>
                        <div>기존 {item.vehPlates} 차량의 정기배송노선 수정을 시작했으나 다른 차량을 선택했습니다.</div>
                        <div>저장할 경우 새로운 차량의 경로로 저장되고 기존 차량의 배송경로는 그대로 유지되는 점 양지하시기 바랍니다.</div>
                    </EditWarning>
                    : null
                }
            </PresentRouteBox>
        );
    };

    
    return (
        <Dialog open={open} fullScreen>
            <EditorTitle>
                {isEdit ? item.vehPlates + '배송노선 정보 수정' : '신규 배송노선 추가'}
            </EditorTitle>
            <DialogContent
                style={{position:'relative'}}
            >
                <ContentContainer>
                    {renderVehicles()}
                    <RouteAndPoiBox>
                        {renderPresentRoutes()}
                        <PoiPairBox>
                            {renderDestinList()}
                            {renderPoiList()}
                        </PoiPairBox>
                    </RouteAndPoiBox>

                </ContentContainer>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed || poiIdList.length < 2}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}