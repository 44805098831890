// ViewRoutineDistrib.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { useInterval } from 'react-use';
import useClientSize from '../hook/useClientSize';
import { Slide, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ViewRoutineDistribList from './ViewRoutineDistribList';
import ViewRoutineDistribInput from './ViewRoutineDistribInput';
import RoutineDistribLogListPop from './RoutineDistribLogListPop';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    ViewRoutineDistribDictionary,
    LIST_VIEW_ROUTINE_DISTRIB, LIST_VIEW_ROUTINE_DIST_VEH_AND_POI,
    SET_VIEW_ROUTINE_DISTRIB, REMOVE_VIEW_ROUTINE_DISTRIB,
    LIST_ROUTINE_DISTRIB_LOG_FOR_VEH
} from './ViewRoutineDistribGql';
import { AppWord, AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser, currentMenuRepo } from '../../model/CvoModel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function newRoute(rec) {
    return {
        custId: rec.custId,
        recId: rec.recId,
        vehId: rec.vehId,
        vehPlates: rec.vehPlates,
        driverName: rec.driverName,
        driverMobile: rec.driverMobile,
        poiId: rec.poiId,
        poiName: rec.poiName,
        notiYn: rec.notiYn,
        notiFrom: rec.notiFrom,
        notiTill: rec.notiTill,
        arrivalTime: rec.arrivalTime,
        departTime: rec.departTime,
        destinMemo: rec.destinMemo,
        userId: rec.userId,
        destins: []
    };
}


function reformCruiseList(records) {
    const cmap = {}; // routeId and sub records
    const list = [];

    for(const rec of records) {
        let route;
        if(rec.departOrDest === 'DEPART') {
            route = newRoute(rec);
            cmap[rec.vehId] = route;
            list.push(route);
        }
        else {
            route = cmap[rec.vehId]
            route.destins.push(rec);
        }
    }
    return list;
}

function makeItemToEdit(route) {
    //
}

const dcPad = AppNumber.dataContainerPadding;
const ViewRoutineDistribContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});


const ErrorTitle =ViewRoutineDistribDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(ViewRoutineDistribDictionary.inputMap, true);
const FetchInterval = 601; // seconds


export default function ViewRoutineDistrib({
    maxWidth,
    maxHeight,
    custId,
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [routineRecords, setRoutineRecords] = useState([]);
    const [timeGotRoutineRecords, setTimeGotRoutineRecords] = useState(new Date().getTime() / 1000);
    const [poiList, setPoiList] = useState([]);
    const [poiIndex, setPoiIndex] = useState({}); // poiId to poiList index.
    const [poiPopData, setPoiPopData] = useState(null);
    const [vehicleList, setVehicleList] = useState([]);
    const [routineLog, setRoutineLog] = useState([]);
    const [showRoutineLog, setShowRoutineLog] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
	const [viewRoutineDistribSelected, setViewRoutineDistribSelected] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const currentMenu = useReactiveVar(currentMenuRepo);
    // const responseList = useQuery(GET_VIEW_ROUTINE_DISTRIB_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});
    const clientSize = useClientSize();
	
	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    // ##### Call GraphQL to get List #####
    const [getViewRoutineDistribListPoiAndVeh, responseListRoutinePoiAndVeh]
        = useLazyQuery(LIST_VIEW_ROUTINE_DIST_VEH_AND_POI, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetListRoutinePoiAndVeh(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List + "(2)"})}
    });
    const [getViewRoutineDistribList, responseList] = useLazyQuery(LIST_VIEW_ROUTINE_DISTRIB, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getRoutineLogList, responseRoutineLog] = useLazyQuery(LIST_ROUTINE_DISTRIB_LOG_FOR_VEH, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetRoutineLogForVeh(data, option)},
        onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.GetLog})}
    });
    
    // ##### GraphQL Mutation. 'onError' for prevent from printing error at consol-e (Uncaught exception) ###
    const [setViewRoutineDistrib, responseAdd] = useMutation( SET_VIEW_ROUTINE_DISTRIB, {
		onCompleted: (data, option) => onCompleteSetRoutineDist(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [removeViewRoutineDistrib, responseRemove] = useMutation( REMOVE_VIEW_ROUTINE_DISTRIB, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getViewRoutineDistribListPoiAndVeh();
    }, [getViewRoutineDistribListPoiAndVeh]);

    useInterval(()=>{
        // 10초에 1번씩 실행.
        if(currentMenu.menuId===AppWord.MENU_ROUTINE_ROUTE) {
            const now = new Date().getTime() / 1000;
            const diff = now - timeGotRoutineRecords;
            if(diff > FetchInterval) {
                setTimeGotRoutineRecords(now);
                getViewRoutineDistribList();
            }
        }
    }, 10000);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetListRoutinePoiAndVeh = (data, clientOption) => {
        if(data.viewRoutineDistribList) {
            setRoutineRecords(reformCruiseList(data.viewRoutineDistribList));
        }
        if(data.poiInfoList) {
            const indexMap = {};
            data.poiInfoList.forEach((poi, idx) => {indexMap[poi.poiId] = idx;});
            setPoiIndex(indexMap);
            setPoiList(data.poiInfoList);
        }
        if(data.vehInfoList) setVehicleList(data.vehInfoList);
    }
    const onCompleteGetList = (data, clientOption) => {
        if(data.viewRoutineDistribList) {
            setRoutineRecords(reformCruiseList(data.viewRoutineDistribList));
        }
    };

    const onCompleteGetRoutineLogForVeh = (data, clientOption) => {
        if(data.routineDistribLogListForVeh) {
            setRoutineLog(data.routineDistribLogListForVeh);
            setShowRoutineLog(true);
        }
        else setRoutineLog([]);
    };

    const onCompleteSetRoutineDist = (data, clientOption) => {
        if(data.viewRoutineDistribSet.ok) {
            setItemToEdit(null);
            setRoutineRecords(reformCruiseList(data.viewRoutineDistribSet.list));
        }
        else setResponseAlert({open:true, resultData: data.viewRoutineDistribSet, title: ErrorTitle.Add});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.viewRoutineDistribRemove.ok) setRoutineRecords(reformCruiseList(data.viewRoutineDistribRemove.list));
        else setResponseAlert({open:true, resultData: data.viewRoutineDistribRemove, title: ErrorTitle.Remove});
    };

    // +++++++ UI callbacks ++++++++
    // Handler - Submit for mutation fired by ViewRoutineDistribInput component.
    const onClickMutate = (item, isEdit) => {
        if(!isAdmin) return;
        // item.custId = ''; // testing error callback.
        const param = {variables: {viewRoutineDistrib: ValueUtil.refineToSubmit(item)}};
        setViewRoutineDistrib(param);
    };

    const onRequestAdd = () => {
        if(!isAdmin) return;
        if(responseAdd) responseAdd.reset();
        setItemToEdit({notiYn:'Y'});
    };

    const onRequestEdit = (item) => {
        if(!isAdmin) return;
        if(responseAdd) responseAdd.reset();
        setItemToEdit(item); // input component에서 정리.
    };

    const onRequestRemove = (item) => {
        setPromptToConfirm({
            data: item,
            title: item.vehPlates + ' 차량의 정기배송노선 삭제',
            messages: [
                item.vehPlates + ' 차량의 정기배송노선을 삭제하시겠습니까?',
                '해당 정기배송노선 및 부수하는 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{viewRoutineDistrib:{vehId: data.vehId}}};
                    removeViewRoutineDistrib(param);
                }
            }
        });
    };

    const onClickPoiLink = (poiId) => {
        if(poiIndex[poiId]) {
            const poi = poiList[poiIndex[poiId]];
            if(poi) {
                setPoiPopData(poi);
                return;
            }
        }
        for(const poi of poiList) {
            if(poi.poiId === poiId) {
                setPoiPopData(poi);
                return;
            }
        };
    };

    const onCloseEditor = () => {
        setItemToEdit(null);
    };

    const onRequestGetRoutineLogForVeh = (route) => {
        const param = {variables: {vehId: route.vehId}};
        getRoutineLogList(param);
    };

    if(ValueUtil.hasAnyAuthError(
        responseList, responseListRoutinePoiAndVeh, // log,
        responseAdd, responseRemove,)
        ) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = itemToEdit ? itemToEdit : {};

        return(
            <ViewRoutineDistribInput
                open={Boolean(itemToEdit)}
                isEdit={Boolean(es.vehId)}
                item={es}
                poiList={poiList}
                vehList={vehicleList}
                routeList={routineRecords}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

    const renderPoiInfoDialog = () => {
        const open = Boolean(poiPopData);
        const poi = open ? poiPopData : {};
        return(

        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{setPoiPopData(null)}}
        >
            <DialogTitle>{poi.poiName}</DialogTitle>
            <DialogContent>
                <Box style={{fontWeight:'bold'}}>지점: {poi.poiName}</Box>
                {
                    poi.addr
                    ?
                    <div>{poi.addr}</div> : null
                }
                {
                    poi.tel
                    ?
                    <div>연락처: {poi.tel}</div> : null
                }
            </DialogContent>
        </Dialog>
        );
    };

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <ViewRoutineDistribContainer>
            <ViewRoutineDistribList
                records={routineRecords}
                onRequestSet={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
                onRequestGetLogForVeh={onRequestGetRoutineLogForVeh}
                onClickPoi={onClickPoiLink}
            />
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
            {renderPoiInfoDialog()}
            <RoutineDistribLogListPop
                open={showRoutineLog}
                records={routineLog}
                onClose={()=>{setShowRoutineLog(false)}}
            />
        </ViewRoutineDistribContainer>
    )
}