// MapPoiInfoInput.js
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { IconButton, Button, TextField, Popover, Chip } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppPalette, AppWord, MapValue } from '../../model/AppConst';
import ConfirmDialog from '../message/ConfirmDialog';
import { PoiInfoDictionary, ADD_POI_INFO, EDIT_POI_INFO } from './PoiInfoGql';
import { useMutation, useReactiveVar } from '@apollo/client';
import ResponseAlert from '../message/ResponseAlert';
import { NoUser, poiInfoRepo, poiTypeRepo, userInfoRepo, poiPositionRepo } from '../../model/CvoModel';
import { ButtonPrimary, ButtonWarning } from '../common/CvoButtons';

const PoiInfInputContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', justifyContent:AppWord.END, padding: AppNumber.SmallBoxPadding,
});
const BodyBox = styled(Box)({ flexGrow:1, overflow:'auto'});
const RowBox = styled(Box)({padding:"5px 2px 5px 10px"});

const LabelBox = styled('div')({fontSize:'0.85rem', color:AppPalette.PrimaryRGB, padding:"10px 2px 5px 5px"});
const GuideBox = styled(Box)({
    fontSize: '0.9rem', color:'#345', padding:"5px 2px 5px 10px"
});

const TypeSelectorBox = styled(Box)({
    maxHeight:400, overflow:'auto', // border: AppPalette.BorderCCC, borderRadius:20, padding:10
});

const TypeRecord = styled(Box)({
    padding:"5px 15px 5px 15px", fontSize:'0.95rem', color:'#222',
    '&:hover': {color:'blue', backgroundColor: '#cef'},
    cursor:'pointer', display:'flex', alignItems:'center', borderRadius:3,
});

const TypeCheckbox = styled('div')({
    width: 30, textAlign:'center'
});

const TypeIcon = styled('div')({
    width:28, textAlign:'center', overflow:'hidden'
});

const TypeColor = styled('div')({
    width:50, textAlign:'center', border: '1px solid #999', borderRadius:3, marginRight:10
});

const InputMap = PoiInfoDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = {radius:0,xDist:1, yDist:1, lat:0, lon:0};

const PoiErrorTitle =PoiInfoDictionary.errorTitle;
const EmptyDMS = '__도 __분 __초';

/**
 * 지점정보 생성 및 수정용 컴퍼넌트. Dialog 사용하지 않음. 좌측에 지점목록과 배타적으로 위치를 차지함.
 */
export default function MapPoiInfoInput({
    open,
    mapShell,
    item,
    isEdit,
    onClose, // Cancel editing.
}) {
    const poiTypeRecords = useReactiveVar(poiTypeRepo);
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [posText, setPosText] = useState(null);
    const [polygon, setPolygon] = useState([]); // Polygon working (drawing now)
    const [polygonArea, setPolygonArea] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null); // for response error
    const [typePopoverAnchor, setTypePopoverAnchor] = useState(null);
    const poiPosition = useReactiveVar(poiPositionRepo);
    const [posChanged, setPosChanged] = useState({}); // inputData와 새로 움직이는 마커 포지현의 동시 처리가 쉽지 않아 별도로....
    const posChangedWhen = useRef();

    useEffect(()=>{
        if(open && mapShell) {
            const onMoveMarker = (pos) => {
                const data = {...pos};
                data.lat = pos.lat;
                data.lon = pos.lon;
                const {x, y} = mapShell.ll2katek(pos);
                data.xDist = x;
                data.yDist = y;
                data.when = new Date().getTime();
                setPosChanged(data);
            };
            if(isEdit) {
                setInputData(item);
                mapShell.setOpenPoiEditor(open, item, null, null, onMoveMarker); // dragend Event.
                // 예전에는 지점위치 변경을 위해 컨텍스트 메뉴 사용했으나, 이번에는 직접 마커를 움직이게 한다.
                // 기존 마커 위에 새 마커를 띄우고 드랙하면 된대.
            }
            else {
                const data = {...defaultInputData};
                data.lat = item.lat;
                data.lon = item.lon;
                data.addr = item.addr;
                setInputData(data);
                mapShell.setOpenPoiEditor(open, item, item.lat, item.lon, onMoveMarker); // dragend Event.
            }
        }
    }, [mapShell, open, isEdit, item]); // inputData 추가하지 말 것. (무한루프)

    //     const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
    useEffect(()=>{
        if(posChanged.when && posChanged.when !== posChangedWhen.current) {
            const data = {...inputData};
            data.lat = posChanged.lat;
            data.lon = posChanged.lon;
            data.xDist = posChanged.xDist;
            data.yDist = posChanged.yDist;
            posChangedWhen.current = posChanged.when;
            setInputData(data);
            if(isEdit) setChanged(true);
        }
    }, [posChanged, inputData, isEdit, mapShell]);

    useEffect(()=>{
        // get position setting from context menu handler
        if(poiPosition) {
            const data = {...inputData};
            data.lat = poiPosition.lat;
            data.lon = poiPosition.lon;
            const {x, y} = mapShell.ll2katek(poiPosition);
            data.xDist = x;
            data.yDist = y;
            setInputData(data);
        }
    }, [poiPosition]); // inputData 추가하지 말 것. (무한루프)

    useEffect(()=>{
        const lat = inputData.lat > 0 ? ValueUtil.degreeToText(inputData.lat) : EmptyDMS;
        const lon = inputData.lon > 0 ? ValueUtil.degreeToText(inputData.lon) : EmptyDMS;
        setPosText({lat:lat, lon:lon});
    }, [inputData]);

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
        setPolygon([]);
	};

    // 지도 앱의 복잡성 때문에 이 곳에서 처리 (보통은 상위의 컨트롤러에서 처리)
    // ##### GraphQL Mutation.  ###
    const [addPoiInfo, responseAdd] = useMutation( ADD_POI_INFO, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: PoiErrorTitle.Add})}
	} );
    const [editPoiInfo, responseEdit] = useMutation( EDIT_POI_INFO, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: PoiErrorTitle.Edit})}
	} );

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteAdd = (data, clientOption) => {
        if(data.poiInfoAdd.ok) {
            poiInfoRepo(data.poiInfoAdd.list);
            resetData();
            onClose();
        }
        else setResponseAlert({open:true, resultData: data.poiInfoAdd, title: PoiErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.poiInfoEdit.ok) {
            poiInfoRepo(data.poiInfoEdit.list);
            resetData();
            onClose();
        }
        else setResponseAlert({open:true, resultData: data.poiInfoEdit, title: PoiErrorTitle.Edit});
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const data = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        const {x, y} = mapShell.ll2katek({lat:data.lat, lon:data.lon});
        data.xDist = x;
        data.yDist = y;
        // add extra data if necessary.
        if(isEdit) {
            data.poiId = item.poiId;
            editPoiInfo({variables: {poiInfo:data}});
        }
        else addPoiInfo({variables: {poiInfo:data}});

        //onClickMutate(param, isEdit);
    };

    if(ValueUtil.hasAnyAuthError(
        responseAdd, responseEdit
    )) userInfoRepo(NoUser);

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '지점 정보가 변경되었습니다.',
                messages: ['지점정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput2(event, InputMap, inputData, inputError, isEdit);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeNumAndHyphen = (event) => {
        if(event.target.value) {
            if(/[^-0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

    const prepareNewPolygon = () => {
        let plat, plon;
        if(isEdit) {plat=item.lat; plon=item.lon;}
        else {plat=item.lat; plon=item.lon;}

        const dLon = 0.0017; // * 3; // 약 111미터 (0.001 / sin(30deg)=0.588)
        const dLat = 0.001; // * 3; // 약 111미터

        const left = plon - dLon;
        const right = plon + dLon;
        const top = plat + dLat;
        const bottom = plat - dLat;
        const positions = [
            {lat:top, lon:left},
            {lat:top, lon:right},
            {lat:bottom, lon:right},
            {lat:bottom, lon:left}
        ];
        setPolygon(positions);
        mapShell.setEditingPoiPolygon(positions);
    };

    const prepareEditPolygon = () => {
        mapShell.setEditingPoiPolygonShape(inputData.zoneShape);
        setPolygon(mapShell.getPolygonWork().getList());
    };

    const onClickStartDrawingPolygon = () => {
        // if !isEdit || !zoneShape
        if(inputData.useBcdYn==='Y' && inputData.zoneShape) prepareEditPolygon();
        else prepareNewPolygon();
    };

    const clearWorkingPolygon = () => {
        mapShell.setEditingPoiPolygon([]);
        setPolygon([]);
    };

    const onClickCancelDrawingPolygon = () => {
        //mapShell.clearPoiPolygon();
        // 이전에 데이터를 가지고 있는 (편집중인) 지점이라면 기존 폴리곤을 넣어준다.
        clearWorkingPolygon();
        mapShell.drawCurrentPolyginWhileNotAdujusting(inputData);
    };

    const saveInputData = (newData) => {
        const {errorMap, foundError} = ValueUtil.checkInputData(InputMap, newData, isEdit);
        setInputData(newData);

        setInputError(errorMap);
        setHasError(foundError);
        setChanged(true);
    };

    const onClickRemovePolygon = () => {
        const newData = {...inputData};
        newData.useBcdYn = 'N';
        newData.zoneShape = null;
        saveInputData(newData);
        mapShell.drawCurrentPolyginWhileNotAdujusting(newData);
    };

    const onClickSavePolygon = () => {
        const area = mapShell.getEditingPolygonArea();
        setPolygonArea(Math.round(area));

        const polygonWork = mapShell.getPolygonWork();
        const data = {...inputData};
        data.zoneShape = polygonWork.makeZoneShape();;
        data.useBcdYn = 'Y';
        
        saveInputData(data);
        clearWorkingPolygon();
        mapShell.drawCurrentPolyginWhileNotAdujusting(data);
    };

    const onSelectType = (type) => {
        const newData = {...inputData};
        newData.poiType = type.poiType;
        newData.typeName = type.typeName;
        saveInputData(newData);
        setTypePopoverAnchor(null);
    };

    const renderTextFieldBox = (dict, value, error, runOnChange, full) => {
        return(
            <RowBox>
                <TextField id={dict.id} size={AppWord.SMALL}
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value || ''}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </RowBox>

        );
    };


    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderPolygonStatus = () => {
        if(polygon.length>2) {
            return(
                <>
                <GuideBox>
                    <ul>
                        <li>꼭지점 위치 변경:
                            검은색 원을 마우스로 끌어서 꼭지점 위치를 바꿀 수 있습니다.
                        </li>
                        <li>꼭지점 추가:
                            꼭지점 사이의 빨간색 원을 마우스로 끌어 놓으면 그 위치에 새로운 꼭지점이 추가 됩니다.
                        </li>
                        <li>꼭지점 삭제:
                            검은색 원을 마우스로 우클릭하면 그 꼭지점이 삭제됩니다.
                        </li>
                        <li>완료하기:
                            구역 확정 버튼을 눌러 변경을 완료하고, 그 후 저장해야 온전히 반영됩니다.
                        </li>
                    </ul>
                </GuideBox>
                <RowBox>
                    <ButtonPrimary size={AppWord.SMALL}
                        onClick={onClickSavePolygon}
                    >구역 확정</ButtonPrimary>
                    &nbsp;
                    <ButtonWarning size={AppWord.SMALL}
                        onClick={onClickCancelDrawingPolygon}
                    >구역변경 취소</ButtonWarning>
                </RowBox>
                </>
            );
        }
        else {
            return(
                <>
                <GuideBox>
                    {
                        inputData.useBcdYn==='Y'
                        ? '설정 삭제 버튼을 누르면 확인하지 않고 삭제합니다'
                        : '출발, 도착 감지가 필요하지 않은 경우는 생성하지 않아도 됩니다.'
                    }
                </GuideBox>
                <RowBox>
                    <ButtonPrimary size={AppWord.SMALL}
                        onClick={onClickStartDrawingPolygon}
                    >설정 {inputData.useBcdYn==='Y' ? '변경' : '시작'}</ButtonPrimary>
                    &nbsp;
                    {
                        inputData.useBcdYn==='Y' ?
                        <ButtonWarning size={AppWord.SMALL}
                            onClick={onClickRemovePolygon}
                        >설정 삭제</ButtonWarning>
                        : null
                    }
                </RowBox>
                </>
            );
        }
    };

    const renderPoiTypeSelector = () => {
        if(poiTypeRecords) {
            const open = Boolean(typePopoverAnchor);
            const IdForPopover = open ? 'id_type_select_popover' : null;
            return(
                <RowBox>
                    <LabelBox>지점 타입
                        : <span style={{color:'black', fontWeight:'bold'}}>
                            {inputData.typeName || '지점 타입을 선택하세요'}
                        </span>
                        <IconButton
                            aria-describedby={IdForPopover}
                            onClick={(e)=>setTypePopoverAnchor(e.currentTarget)}
                            color={AppPalette.PrimaryColor}
                            style={{border:'1px solid #cdf', marginLeft:10}}
                            size={AppWord.SMALL}
                        >
                            <ListIcon />
                        </IconButton>
                        <Popover
                            id={IdForPopover}
                            open={open}
                            anchorEl={typePopoverAnchor}
                            onClose={()=>setTypePopoverAnchor(null)}
                            anchorOrigin={{
                                vertical: AppWord.TOP,
                                horizontal: AppWord.LEFT,
                            }}
                            transformOrigin={{
                                vertical: AppWord.TOP,
                                horizontal: AppWord.LEFT,
                            }}

                        >
                            <TypeSelectorBox>
                                {
                                    poiTypeRecords.map((t)=>{
                                        return (
                                            <TypeRecord key={t.poiType} onClick={()=>onSelectType(t)}>
                                                <TypeCheckbox>
                                                    {
                                                        t.poiType === inputData.poiType
                                                        ?
                                                        <RadioButtonCheckedIcon color={AppPalette.PrimaryColor} size={AppWord.SMALL}/>
                                                        :
                                                        <RadioButtonUncheckedIcon color={AppPalette.DisabledColor} size={AppWord.SMALL}/>
                                                    }
                                                </TypeCheckbox>
                                                <TypeIcon>
                                                    {
                                                        t.iconSaveName ?
                                                        <img src={MapValue.POI_ICON_DIR + "/" + t.iconSaveName.trim()} alt={t.typeName} 
                                                            style={{verticalAlign:'bottom'}}
                                                        />
                                                        : null
                                                    }
                                                </TypeIcon>
                                                <TypeColor
                                                    style={{
                                                        color: t.fontColor,
                                                        backgroundColor: t.backColor,
                                                    }}
                                                >
                                                    지점명
                                                </TypeColor>
                                                <span>{t.typeName}</span>
                                            </TypeRecord>
                                        )
                                    })
                                }
                            </TypeSelectorBox>
                        </Popover>
                    </LabelBox>
                </RowBox>
            );
        }
        else return <Box>No types</Box>
    };
    
    const renderThreeFields = () => {
        if(polygon.length>2) {
            return(
                <Box style={{textAlign:'center'}}>
                    <MoreVertIcon color={AppPalette.DisabledColor}/>
                </Box>
            );
        }
        else {
            return(
                <>
                {renderPoiTypeSelector()}
                {renderTextFieldBox(InputMap.tel, inputData.tel, inputError.tel, onChangeNumAndHyphen) /* Tel */}
                {renderTextFieldBox(InputMap.custPoiCode, inputData.custPoiCode, inputError.custPoiCode, onChangeTextData) /* cust Poi Code */}
                {renderTextFieldBox(InputMap.etc, inputData.etc, inputError.etc, onChangeTextData) /* Etc */}
                </>
            );
        }
    };

    return (
        <PoiInfInputContainer>
            <HeaderBox>
                <Button variant={AppPalette.VariantContained}
                    disabled={hasError || polygon.length > 0 || !changed}
                    onClick={onClickSubmit}
                >
                    저장
                </Button>
                &nbsp;
                <Button variant={AppPalette.VariantContained} color={AppPalette.WarnColor}
                    onClick={onClickCancel}
                >
                    취소
                </Button>
            </HeaderBox>
            <BodyBox>
                {renderTextFieldBox(InputMap.poiName, inputData.poiName, inputError.poiName, onChangeTextData) /* poi Name */}
                {renderTextFieldBox(InputMap.addr, inputData.addr, inputError.addr, onChangeTextData) /* _____ */}
                {renderThreeFields()}
                <LabelBox>
                    좌표 (아이콘을 마우스로 움직일 수 있습니다)
                </LabelBox>
                <RowBox><Chip label={`위도: 북위 ${posText ? posText.lat : '...'}`} variant={AppPalette.VariantOutlined} /></RowBox>
                <RowBox><Chip label={`경도: 동경 ${posText ? posText.lon : '...'}`} variant={AppPalette.VariantOutlined} /></RowBox>
                <LabelBox>
                    출발, 도착 감지구역 ({inputData.useBcdYn==='Y' ? '설정됨.' + (polygonArea ? ` (${polygonArea} 평방미터)` : '') : '현재 설정 없음'})
                </LabelBox>
                {renderPolygonStatus()}
            </BodyBox>
            {renderPromptIgnoreChange()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </PoiInfInputContainer>
    )
}
