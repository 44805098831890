// route_daily/RouteDailyGql.js
import { gql } from "@apollo/client";

export const LIST_ROUTE_DAILY = gql`
query ListRouteDaily($dateFrom:String, $dateTill:String) {
    routeDailyList(dateFrom:$dateFrom, dateTill:$dateTill) {
        vehId
        routeDate
        custId
        custName
        vehPlates
        termId
        secondsRun
        totalDist
        avgSpeed
        avgSpeedRun
        maxSpeed
        avgCycleMake
        avgCycleReport
        positionCount
        hhmmFrom
        hhmmTill
        driveCanStart
        driveMustEnd
    }
}
`;