// VehGroupForUser.js
import React, {useState, useEffect} from 'react';
import { Box } from '@mui/system';
import { styled  } from '@mui/material/styles';
import { Button } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import HelpTopic from '../common/HelpTopic';
import { LIST_VEH_GROUP_FOR_USER } from './VehGroupGql';
import { LIST_CUST_AGREE_FOR_USR } from '../cust_agree/CustAgreeGql';
import {
    ADD_USER_OPER_AUTH_RET_GROUPS, REMOVE_USER_OPER_AUTH_RET_GROUPS,
    ADD_USER_OPER_AUTH_RET_CUST_AGREES, REMOVE_USER_OPER_AUTH_RET_CUST_AGREES
} from '../user_oper_auth/UserOperAuthGql';
//import HandshakeIcon from '@mui/icons-material/Handshake';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { AppObject } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';
import ResponseAlert from '../message/ResponseAlert';
import ExpanTree from '../common/ExpanTree/ExpanTree';
import CheckTable from '../common/CheckTable';
import HelpPop from '../common/HelpPop';
import VehGroupNode from '../../model/VehGroupNode';
import useClientSize from '../hook/useClientSize';

const BorderCcc = '1px solid #cccccc';

const GroupWorkBox = styled(Box)({
    display: 'flex', height:'100%', // backgroundColor:'green'
});

const MyGroupAndTitleBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column', // backgroundColor:'yellow'
});

const TitleBox = styled(Box)({fontWeight:'bold', padding:10, fontSize: '1.1rem', width:200});

const VehGroupsBox = styled(Box)({
    flexGrow: 1,
    border: BorderCcc,
    borderRadius: 5,
    minWidth: 180,
    position:'relative'
});

const GroupsInnerWrapper = styled(Box)({
    position: 'absolute',
    left:0, top:0, right:0, bottom:0,
    padding: '3px 0px 3px 5px',
    overflow:'auto'
});

const CustAgreeTableBox = styled(Box)({
    position:'relative'
});

const HelpGroup = styled('span')({
    float:'right'
});

const LaButton = styled(Button)({marginRight: 10});

const InfoBox = styled(Box)({padding: 10, fontSize: '0.9rem', color:'#555555'});

const CustAgreeAndHelpBox = styled(Box)({
    flexGrow:1,
    marginLeft: 15,
    // display:'flex',
    overflow:'auto',
});

const CustAgreeBox = styled(Box)({marginBottom:10});

const HelpBox = styled(Box)({
    overflow:'auto',
    fontSize: '0.93rem',
    border: BorderCcc,
    marginLeft: 10,
    borderRadius: 10,
    minWidth: 180, maxWidth:500,
});

const YouCanSeeMarkIcon = <WhereToVoteIcon fontSize='small' color='primary' style={{verticalAlign:'bottom'}}/>;

const ErrorTitle = {
    Add: '차량관제권한 설정 오류',
    Remove: '차량관제권한 해제 오류',
};

const CustAgreeColumns = [
    {id:'groupName', minWidth:80, label:'그룹'},
    {id:'givingCustName', label:'제공업체'}, // {id:'givingCustId', label:'제공업체ID'},
    {id:'editYn', label:'정보변경', format: (value) => {return value==='Y' ? '가능' : '불가'}}
];


export default function VehGroupForUser({
    userInfo
}) {
    const [vehGroupTreeData, setVehGroupTreeData] = useState([]);
    const [custAgreeList, setCustAgreeList] = useState([]);
    // const [selected, setSelected] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize(); // Scroll the tree
    // const responseList = useQuery(GET_VEH_INFO_LIST, {fetchPolicy: "no-cache"});
    const [getVehGroupList, responseGroupsList] = useLazyQuery(LIST_VEH_GROUP_FOR_USER, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)}, 
		onError: (error) => {setResponseAlert({open:true, error: error, title: "전체 그룹 조회 오류"})}
    });
    const [getCustAgreeList, responseCustAgreeList] = useLazyQuery(LIST_CUST_AGREE_FOR_USR, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetCustAgreeList(data, option)}, 
		onError: (error) => {setResponseAlert({open:true, error: error, title: "협력받은 그룹 목록 조회 오류"})}
    });
    // ##### GraphQL Mutation.
    const [addUserOperAuthRetGroups, responseAdd] = useMutation( ADD_USER_OPER_AUTH_RET_GROUPS, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [removeUserOperAuthRetGroups, responseRemove] = useMutation( REMOVE_USER_OPER_AUTH_RET_GROUPS, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});
    const [addUserOperAuthRetAgrees, responseAddRetAgrees] = useMutation( ADD_USER_OPER_AUTH_RET_CUST_AGREES, {
		onCompleted: (data, option) => onCompleteAddRetAgrees(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [removeUserOperAuthRetAgrees, responseRemoveRetAgrees] = useMutation( REMOVE_USER_OPER_AUTH_RET_CUST_AGREES, {
		onCompleted: (data, option) => onCompleteRemoveRetAgrees(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});


    // const [responseList, setResponseList] = useState({data:[]});
    const getVehGroupListForUser = () => {getVehGroupList({variables: {userId: userInfo.userId}})};
    useEffect(() => {
        //getVehGroupListForUser();
        if(userInfo) {
            getVehGroupList({variables: {userId: userInfo.userId}});
            getCustAgreeList({variables: {userId: userInfo.userId}});
        }
    }, [userInfo, getVehGroupList, getCustAgreeList]);

    // <<<<<<<<<<<<< Response handlers <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.vehGroupListForUser) {
            setVehGroupTreeData(VehGroupNode.makeTree(data.vehGroupListForUser));
            // setSelected(null);
        }
    };

    const onCompleteGetCustAgreeList = (data, clientOption) => {
        if(data.custAgreeListForUser) {
            setCustAgreeList(data.custAgreeListForUser);
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.userOperAuthAddToReturnGroups.ok)
            setVehGroupTreeData(VehGroupNode.makeTree(data.userOperAuthAddToReturnGroups.list));
        else setResponseAlert({open:true, resultData: data.userOperAuthAddToReturnGroups, title: ErrorTitle.Add});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.userOperAuthRemoveToReturnGroups.ok)
            setVehGroupTreeData(VehGroupNode.makeTree(data.userOperAuthRemoveToReturnGroups.list));
        else setResponseAlert({open:true, resultData: data.userOperAuthRemoveToReturnGroups, title: ErrorTitle.Remove});
    };

    const onCompleteAddRetAgrees = (data, clientOption) => {
        if(data.userOperAuthAddToReturnCustAgrees.ok)
            setCustAgreeList(data.userOperAuthAddToReturnCustAgrees.list);
        else setResponseAlert({open:true, resultData: data.userOperAuthAddToReturnCustAgrees, title: ErrorTitle.Add});
    };

    const onCompleteRemoveRetAgrees = (data, clientOption) => {
        if(data.userOperAuthRemoveToReturnCustAgrees.ok)
            setCustAgreeList(data.userOperAuthRemoveToReturnCustAgrees.list);
        else setResponseAlert({open:true, resultData: data.userOperAuthRemoveToReturnCustAgrees, title: ErrorTitle.Remove});
    };


    // >>>>>>>>> callbacks >>>>>>>>>>>>>
    const onGroupClicked = (group) => {
        if(isLeafNode(group)) {
            // setSelected(group);
            if(group.userId === userInfo.userId) {
                removeUserOperAuthRetGroups({variables:{userOperAuth:{userId: userInfo.userId, groupId: group.groupId}}});
            }
            else {
                addUserOperAuthRetGroups({variables:{userOperAuth:{userId: userInfo.userId, groupId: group.groupId}}});
            }
        }
    };

    const onCheckCustAgreeItem = (agree) => {
        if(userInfo.userId===agree.userId)
            removeUserOperAuthRetAgrees({variables:{userOperAuth:{userId: userInfo.userId, groupId: agree.groupId}}});
        else
            addUserOperAuthRetAgrees({variables:{userOperAuth:{userId: userInfo.userId, groupId: agree.groupId}}});
    };

    const isLeafNode = (node) => {
        if(node) {
            if(node.children.length===0) return true;
        }
        return false;
    };

    // Handler - Submit for mutation fired by VehGroupInput component.

    if(ValueUtil.hasAnyAuthError(
        responseGroupsList, responseAdd, responseRemove,
        responseAddRetAgrees, responseAddRetAgrees, responseRemoveRetAgrees,
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------

    const renderGroupHelpPop = () => {
        return(
            <HelpGroup>
                <HelpPop
                    content={
                        <Box width={500}>
                            <HelpTopic
                                title="우리 그룹의 관제권한"
                                content={
                                    <ul>
                                        <li>우리 회사의 차량그룹 전체입니다.</li>
                                        <li>일반사용자에게는 특정 그룹들을 관제할 수 있도록 설정합니다.</li>
                                        <li>선택한 사용자가 현재 관제권한이 있는 경우 {YouCanSeeMarkIcon} 아이콘을 볼 수 있습니다.</li>
                                        <li>관제그룹으로 설정된 경우에만 위치목록, 지도 등에서 그룹에 속한 차량을 볼 수 있습니다.</li>
                                        <li>차량은 여러 그룹에 속할 수 있고, 사용자는 여러 그룹을 관제할 수 있도록 설정할 수 있으므로
                                            차량이 관제권한이 주어진 어떤 그룹에라도 속한다면 그 차량을 볼 수 있게 됩니다.</li>
                                        <li>또한, 관제 권한이 주어진 경우에는 차량의 정보를 수정할 수도 있습니다.</li>
                                        <li>그룹을 클릭하면 해당 그룹에 대해 관제권한을 설정하거나 해제합니다.</li>
                                        <li>기존에 그 그룹에 대해 관제권한이 없었다면 설정하고, 있었다면 해제합니다.</li>
                                        <li>설정이나 해제는 재차 확인하지 않고 즉시 반영합니다.</li>
                                        <li>단, 최종단 그룹에 대해서만 관제권한이 설정되는 점 유의해 주세요.</li>
                                        <li>관리자는 항상 모든 차량을 관제할 수 있습니다. 따라서 관제권한을 별도로 설정하지 않습니다.</li>
                                    </ul>
                                }
                            />
                        </Box>
                    }
                />
            </HelpGroup>
        );
    };

    const renderAgreeHelpPop = () => {
        return(
            <HelpGroup>
                <HelpPop
                    anchorOrigin={{ vertical: 'top', horizontal: 'right',}}
                    transformOrigin={{ vertical: 'top', horizontal: 'right',}}
                    content={
                        <Box width={500}>
                            <HelpTopic
                                title="협력그룹의 관제권한"
                                content={
                                    <ul>
                                        <li>타 업체에서 우리회사에 제공한 협력차량그룹에 대한 관제권한을 관리합니다.</li>
                                        <li>일반사용자에게만 해당하며 관리자는 항상 모든 협력그룹 차량을 볼 수 있습니다.</li>
                                        <li>선택한 사용자가 현재 관제권한이 있는 경우 {YouCanSeeMarkIcon} 아이콘을 볼 수 있습니다.</li>
                                        <li>그룹을 클릭하면 해당 그룹에 대해 관제권한을 설정하거나 해제합니다.</li>
                                        <li>기존에 그 그룹에 대해 관제권한이 없었다면 설정하고, 있었다면 해제합니다.</li>
                                        <li>설정이나 해제는 재차 확인하지 않고 즉시 반영합니다.</li>
                                    </ul>
                                }
                            />
                        </Box>
                    }
                />
            </HelpGroup>
        );
    };

    return (
        <GroupWorkBox>
            <MyGroupAndTitleBox>
                <TitleBox>
                    {renderGroupHelpPop()}
                    그룹
                </TitleBox>
                <VehGroupsBox>
                    <GroupsInnerWrapper>
                    <ExpanTree
                        data={vehGroupTreeData}
                        onLeafClicked={onGroupClicked}
                        exclusive={true}
                        markOption={
                            {
                                keyToCheck: 'userId',
                                marker: YouCanSeeMarkIcon
                            }
                        }
                    /></GroupsInnerWrapper>
                </VehGroupsBox>
            </MyGroupAndTitleBox>
            <CustAgreeAndHelpBox>
                {
                    custAgreeList.length > 0 ?
                    <CustAgreeBox>
                        <TitleBox>
                            {renderAgreeHelpPop()}
                            협력받은 그룹
                        </TitleBox>
                        <CustAgreeTableBox>
                            <CheckTable
                                columns={CustAgreeColumns}
                                records={custAgreeList}
                                idToCheck={'userId'}
                                onCheckItem={onCheckCustAgreeItem}
                                iconTrue={<WhereToVoteIcon fontSize='small' color='primary' style={{verticalAlign:'bottom'}}/>}
                            />
                        </CustAgreeTableBox>
                    </CustAgreeBox>
                    : null
                }
                <HelpTopic
                    title="관제권한"
                    content={
                        <ul>
                            <li>일반사용자에게는 특정 그룹들을 관제할 수 있도록 설정합니다.<br/>(관리자는 항상 관제가 허용됩니다)</li>
                            <li>현재 관제권한이 있는 경우 {YouCanSeeMarkIcon} 아이콘을 볼 수 있습니다.</li>
                            <li>관제그룹으로 설정된 경우에만 위치목록, 지도 등에서 그룹에 속한 차량을 볼 수 있습니다.</li>
                            <li>선택한 사용자에게 관제권한을 주거나 금지하려면 해당 그룹을 클릭하세요. 설정은 다시 묻지 않고 즉시 반영됩니다.</li>
                            <li>단, 최종단 그룹에 대해서만 관제권한이 설정되는 점 유의해 주세요.</li>
                        </ul>
                    }
                />

            </CustAgreeAndHelpBox>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </GroupWorkBox>
    )
}
