//com/common/MultiPicker.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button, IconButton } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { AppWord } from '../../model/AppConst';
import Util from '../../model/Util';

const MultiPickerBox = styled(Box)({
    display:'inline-block',
});

const MenuItemsBox = styled(Box)({display:'inline-block'});

const ItemBox = styled(Box)({
    display:'inline-block',
    color: '#77f',
    backgroundColor:'#f5f5f5',
    border:'1px solid #79f', borderRadius:5,
    cursor: 'pointer',
    marginLeft:1,
    textAlign:'center',
    padding:2,
    '&>:hover': {color:'red'},
    '&.selected': {color:'black', backgroundColor:'#9bf'}
});

const DotBox = styled(Box)({
    display:'inline', width:1,color:'#9bf',
    marginLeft:1,paddingBottom:5
});

const NarrowButton = styled(Button)({
    padding:2,
    '&.MuiButtonBase-root':{paddingLeft:3,paddingRight:3}
});

export default function MultiPicker({
    title,
    data,
    maxCount,
    onChange,
    selected,
    minItemWidth,
    fontSize,
    onChangeSize,
}) {
    const [openAll, setOpenAll] = useState(false);
    const [mouseLeft, setMouseLeft] = useState(false);
    
    // const [selected, setSelected] = useState(defaultSelected ||  []);

    const onClickItem = (key) => {
        if(selected.includes(key)) {
            const changed = selected.filter((item)=>item !== key);
            onChange(changed);
        }
        else {
            if(selected.length >= maxCount) {
                Util.bubbleSnack("총 " + maxCount + "개를 초과할 수 없습니다.");
                return;
            }
            //setSelected((prevSelected) => [...prevSelected, key]);
            onChange([...selected, key]);
        }
    };

    const onClickOpenClose = (bool) => {
        if(bool) setMouseLeft(false);
        setOpenAll(bool);
        if(onChangeSize) onChangeSize(bool);
    };

    // .filter((val)=>{if(openAll) return true; return selected.includes(val.key)})
    return (
        <MultiPickerBox>
            <span>{title || '선택정보'}</span>&nbsp;
            <MenuItemsBox
                onMouseEnter={()=>{onClickOpenClose(true)}}
                onMouseLeave={()=>{setMouseLeft(true)}}
            >
            {
                data.map((item) => {
                    const on = selected.includes(item.key);
                    if(on || openAll)
                        return(
                            <ItemBox key={item.key}
                                style={{
                                    minWidth:minItemWidth || 24,
                                    fontSize: fontSize || '0.9em'
                                }}
                                className={on ? 'selected' : ''}
                                onClick={() => onClickItem(item.key)}
                            >
                                {item.label}
                            </ItemBox>
                        );
                    else return <DotBox key={item.key}>.</DotBox>;
                })
            }
            </MenuItemsBox>
            &nbsp;
            {
                openAll ?
                (
                    mouseLeft ?
                    <NarrowButton variant="outlined" size={AppWord.SMALL}
                        onClick={()=>onClickOpenClose(false)}
                    >
                        <KeyboardDoubleArrowLeftIcon fontSize={AppWord.SMALL} />
                        닫기
                    </NarrowButton>
                    :
                    <IconButton size={AppWord.SMALL}
                        onClick={()=>onClickOpenClose(false)}
                    >
                        <KeyboardDoubleArrowLeftIcon fontSize={AppWord.SMALL} />
                    </IconButton>
                )
                :
                <NarrowButton variant="outlined" size={AppWord.SMALL}
                    onClick={()=>{onClickOpenClose(true)}}
                >
                    <KeyboardDoubleArrowRightIcon fontSize={AppWord.SMALL}/>
                    변경
                </NarrowButton>
            }
        </MultiPickerBox>
    );
}
