//MenuUser.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled  } from '@mui/material/styles';
import { Link, Button, IconButton, TextField } from '@mui/material';
import { MENU_TREE_FOR_USER_SELECTION, MAP_MENU_TO_USER_RET_TREE, REMOVE_MENU_TO_USER_RET_TREE } from './MenuGql';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AppObject, AppPalette } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import HelpTopic from '../common/HelpTopic';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const MenuWorkContainer = styled(Box)({display:'flex', height:'100%'}); // has MenuTreeBox, HelpTopicsBox
const HelpTopicsBox = styled(Box)({flexGrow:2});

const MenuTreeBox = styled(Box)({
    //flex:1, // backgroundColor:'green'
    minWidth:260,
    overflow:'auto',
    marginRight:10,
    border: AppPalette.BorderCCC,
    borderRadius:5,
    padding:10
});

const MenuColumnBox = styled(Box)({
    paddingTop: 10,
});

const MenuKindName = styled(Box)({fontWeight:'bold'});
const MenusInKind = styled(Box)({paddingLeft:20});
const MenuItem = styled(Box)({
    borderRadius:5,
    "&:hover": {backgroundColor:'#ddd'}
});

const ErrorTitle = {
    Add: '메뉴 설정 오류', Remove:'메뉴 삭제 오류', Tree: '메뉴 트리 조회 오류'
};

export default function MenuUser({
    userInfo
}) {
    const [menuTree, setMenuTree] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);

    // ##### Call GraphQL to get List #####
    const [getMenu21UserTree, responseList] = useLazyQuery(MENU_TREE_FOR_USER_SELECTION, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetMenuTree(data, option)}, 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Tree})}
    });
    // ##### GraphQL Mutation. ###
    const [mapMenu21User, responseAdd] = useMutation( MAP_MENU_TO_USER_RET_TREE, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [removeMenu21User, responseRemove] = useMutation( REMOVE_MENU_TO_USER_RET_TREE, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        if(userInfo) getMenu21UserTree({variables: {userId: userInfo.userId}});
    }, [userInfo, getMenu21UserTree]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetMenuTree = (data, clientOption) => {
        if(data.menuToUserTreeForSelection) {
            setMenuTree(data.menuToUserTreeForSelection);
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.menuToUserMap.ok) {
            setMenuTree(data.menuToUserMap.list);
        }
        else setResponseAlert({open:true, resultData: data.menuToUserMap, title: ErrorTitle.Add});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.menuToUserRemove.ok) setMenuTree(data.menuToUserRemove.list);
        else setResponseAlert({open:true, resultData: data.menuToUserRemove, title: ErrorTitle.Remove});
    };

    const onClickMenu = (menu) => {
        if(userInfo.userId === menu.userId) {
            removeMenu21User({variables: {menuMapInfo:{userId:userInfo.userId, menuId: menu.menuId}}});
        }
        else {
            mapMenu21User({variables: {menuMapInfo:{userId:userInfo.userId, menuId: menu.menuId}}});
        }
    };

    if(ValueUtil.hasAnyAuthError(
        responseList, responseAdd, responseRemove
    )) userInfoRepo(NoUser);

    return (
        <MenuWorkContainer>
            <MenuTreeBox>
                {
                    menuTree.map((kind) => {
                        return(
                            <MenuColumnBox key={kind.kindId}>
                                <MenuKindName>{kind.kindName}</MenuKindName>
                                <MenusInKind>
                                    {
                                        kind.menus.map((menu)=> {
                                            return(
                                                <MenuItem key={menu.menuId}>
                                                    <IconButton onClick={()=>{onClickMenu(menu)}} size="small">
                                                        {
                                                            menu.userId
                                                            ?
                                                            <CheckBoxIcon fontSize='small' color='primary' />
                                                            :
                                                            <CheckBoxOutlineBlankIcon fontSize='small' />
                                                        }
                                                    </IconButton>
                                                    {menu.menuName}
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </MenusInKind>
                            </MenuColumnBox>
                        );
                    })
                }
            </MenuTreeBox>
            <HelpTopicsBox>
                <HelpTopic
                    title="사용자별 메뉴 출력 선택"
                    content={
                        <div>
                            <p>관리자는 각 사용자별로 각 메뉴의 출력여부를 선택할 수 있습니다.</p>
                            <p>사용가능한 메뉴는 해당 사용자의 자격(관리자 혹은 일반사용자)에 따라 다를 수 있습니다.</p>
                            <p>각 메뉴의 앞의 체크박스를 클릭하면 즉시 선택상태가 데이터베이스에 반영됩니다. 선택과 해제상태를 전환할 때
                                계속 진행할 것인지 관리자에게 다시 묻지 않습니다.
                            </p>
                        </div>
                    }
                />
            </HelpTopicsBox>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </MenuWorkContainer>
    )
}
// 2022.12.31