// user_tcol_conf21/UserTcolConf21Gql.js
import { gql } from "@apollo/client";

export const UserTcolConf21Dictionary = {
    inputMap: {
        viewName : {
            id: 'viewName', label: '테이블명', type: 'text', required: true, toAdd: true, toEdit: true,
        },
        colsCsv : {
            id: 'colsCsv', label: '칼럼', type: 'text', required: true, toAdd: true, toEdit: true,
        },

    },
    errorTitle: {
        Set: '칼럼설정 오류',
        List: '칼럼설정 목록 조회 오류',
        Remove: '칼럼설정 삭제 오류',
        Get: '칼럼설정 조회 오류',
    }
};

const USER_TCOL_CONF21_LIST_PART = gql`
fragment UserTcolConf21ListPart on UserTcolConf21 {
    userId
    viewName
    colsCsv
    regDate
}
`;



export const LIST_USER_TCOL_CONF21 = gql`
${USER_TCOL_CONF21_LIST_PART}
query ListUserTcolConf21 {
    userTcolConf21List {
        ...UserTcolConf21ListPart
    }
}
`;

export const GET_USER_TCOL_CONF21 = gql`
${USER_TCOL_CONF21_LIST_PART}
query GetUserTcolConf21($viewName:String!) {
    userTcolConf21Item(viewName:$viewName) {
        ...UserTcolConf21ListPart
    }
}
`;

// userTcolConf21Save(userTcolConf21: InputUserTcolConf21Add!): CommonResponse
export const SAVE_USER_TCOL_CONF21 = gql`
mutation SaveUserTcolConf21($userTcolConf21:InputUserTcolConf21Add!) {
    userTcolConf21Save(userTcolConf21: $userTcolConf21) {
        ok
        message
    }
}
`;
// InputUserTcolConf21Add {viewName: String, colsCsv: String}

// userTcolConf21Batch(userTcolConf21s: [InputUserTcolConf21Add!]!): CommonResponse
export const BATCH_USER_TCOL_CONF21 = gql`
mutation BatchUserTcolConf21($userTcolConf21s:[InputUserTcolConf21Add!]!) {
    userTcolConf21Batch(userTcolConf21s: $userTcolConf21s) {
        ok
        message
    }
}
`;

// userTcolConf21Remove(userTcolConf21: InputUserTcolConf21Remove!): CommonResponse
export const REMOVE_USER_TCOL_CONF21 = gql`
mutation RemoveUserTcolConf21($userTcolConf21:InputUserTcolConf21Remove!) {
    userTcolConf21Remove(userTcolConf21: $userTcolConf21) {
        ok
        message
    }
}
`;
