import { gql } from "@apollo/client";
// import ValueUtil from "../../model/ValueUtil";

export const BcdNoticeMoreDictionary = {
    inputMap: {
        vehId : {toAdd: true, toEdit: true},
        poiId : {toAdd: true, toEdit: true},
        noticeTo : {label: '전화번호',toAdd: true, toEdit: true}, // CSV mobile
        // noticeBy : {toAdd: true, toEdit: true}, // SMS
        cargo : {toAdd: true, toEdit: true},
        // onArrival : {id: 'onArrival', label: '도착알림',toAdd: true, toEdit: true},// YN
        // onDepart : {id: 'onDepart', label: '출발알림',toAdd: true, toEdit: true}, // YN
    },
    errorTitle: {
        Add: '방문지 및 알람 설정 생성 오류',
        Upload: '방문지 및 알람 설정 일괄 업로드 오류',
        Remove: '방문지 및 알람 설정 삭제 오류',
        List: '방문지 및 알람 설정 목록 조회 오류',
        Get: '방문지 및 알람 설정 조회 오류',
    }
};

const BCD_NOTICE_MORE_LIST_PART = gql`
fragment BcdNoticeMoreListPart on BcdNoticeMore {
    dataKey
    vehId
    vehPlates
    poiId
    poiName
    custPoiCode
    noticeTo
    noticeBy
    userIdCsv
    cargo
    onArrival
    onDepart
    timeArrival
    timeDepart
    uploadMessage
    regUserId
    regTime
}
`;




export const LIST_BCD_NOTICE_MORE = gql`
${BCD_NOTICE_MORE_LIST_PART}
query ListBcdNoticeMore {
    bcdNoticeMoreList {
        ...BcdNoticeMoreListPart
    }
}
`;

export const GET_BCD_NOTICE_MORE = gql`
${BCD_NOTICE_MORE_LIST_PART}
query GetBcdNoticeMore($vehId:Int, $poiId:Int) {
    bcdNoticeMoreItem(vehId:$vehId, poiId:$poiId) {
        ...BcdNoticeMoreListPart
    }
}
`;
/*
export const ADD_BCD_NOTICE_MORE = gql`
${BCD_NOTICE_MORE_LIST_PART}
mutation AddBcdNoticeMore($bcdNoticeMore:InputBcdNoticeMoreAdd!) {
    bcdNoticeMoreAdd(bcdNoticeMore: $bcdNoticeMore) {
        ok
        message
        list {
            ...BcdNoticeMoreListPart
        }
    }
}
`;*/

export const UPLOAD_BCD_NOTICE_MORE = gql`
${BCD_NOTICE_MORE_LIST_PART}
mutation UploadBcdNoticeMore($bcdNoticeMore:[InputBcdNoticeMoreAdd]!) {
    bcdNoticeMoreUpload(bcdNoticeMore: $bcdNoticeMore) {
        ok
        message
        list {
            ...BcdNoticeMoreListPart
        }
    }
}
`;

export const TRUNCATE_BCD_NOTICE_MORE = gql`
${BCD_NOTICE_MORE_LIST_PART}
mutation TruncateBcdNoticeMore {
    bcdNoticeMoreTruncate {
        ok
        message
        list {
            ...BcdNoticeMoreListPart
        }
    }
}
`;

export const REMOVE_BCD_NOTICE_MORE = gql`
${BCD_NOTICE_MORE_LIST_PART}
mutation RemoveBcdNoticeMore($bcdNoticeMore:InputBcdNoticeMoreRemove!) {
    bcdNoticeMoreRemove(bcdNoticeMore: $bcdNoticeMore) {
        ok
        message
        list {
            ...BcdNoticeMoreListPart
        }
    }
}
`;
