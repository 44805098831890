// map/PopPoiInfo.js
import React from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { AppPalette } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { userInfoRepo } from '../../model/CvoModel';
import ValueUtil from '../../model/ValueUtil';

const InfoTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.PrimaryRGB,
    color: 'white',
    minWidth: 300,
});

const IconCircle = styled(Box)({
    display: 'inline-flex', backgroundColor:'white', color:AppPalette.PrimaryRGB, marginRight: 10,
    borderRadius:40, height: 40, width:40, alignItems:'center', justifyContent:'center'
});

const InfoRow = styled(Box)({
    height:32, display:'flex', alignItems:'center',
});

const InfoLabel = styled('div')({
    width: 160, paddingLeft: 15
});

const Info = styled('div')({
    paddingLeft:15, flexGrow: 1, minWidth:250
});

export default function PopPoiInfo({
    open,
    poiData,
    onClickClose
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
	const isStaff = sessionInfo.signedIn ? ValueUtil.isEtrace(sessionInfo) : false;

    const renderInfo = (label, text) => {
        return(
            <InfoRow>
                <InfoLabel>{label}</InfoLabel>
                <Info>
                    {text}
                </Info>
            </InfoRow>
        );
    };

    const data = open ? poiData : {};

    return (
        <Dialog open={open} maxWidth="md" onClose={onClickClose}>
            <InfoTitle>
                <IconCircle>
                    <PlaceIcon color={AppPalette.InheritColor} />
                </IconCircle>
                {data.poiName}
                {
                    isStaff
                    ? ' (poi_id=' + data.poiId + ')'
                    : null
                }
            </InfoTitle>
            <DialogContent style={{paddingTop:12}}>
                {renderInfo('지점', data.poiName)}
                {renderInfo('연락처', data.tel)}
                {renderInfo('주소', data.addr)}
                {renderInfo('고객지점코드', data.custPoiCode)}
                {renderInfo('비고', data.etc)}
                {renderInfo('지점타입', data.typeName)}
                {renderInfo('출발도착감지', data.useBcdYn==='Y' ? '가능' : '불가능')}
            </DialogContent>
            <DialogActions>
                <Button
                    color={AppPalette.PrimaryColor}
                    onClick={onClickClose}
                    variant={AppPalette.VariantContained}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    )
}
