// download_info/DownloadInfoGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";
import { TableColumnType } from "../../model/AppConst";

const downloadInfoInputMap = {
    msgSeq : {
        id: 'msgSeq', required: true, toAdd: false, toEdit: true,
    },
    title : {
        id: 'title', label: '제목', type: 'text', required: true,
        toAdd: true, toEdit: true,
        help: '100자 이내로 입력하세요.',
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,300)},
    },
    contents : {
        id: 'contents', label: '내용', type: 'text', required: false,
        toAdd: true, toEdit: true,
        check: (value) => {return ValueUtil.textInRange(value,1,2140967295)},
    },
    textFormat : {
        id: 'textFormat', label: '본문 형식', type: 'radio', required: true,
        toAdd: true, toEdit: true,
        default:'text'
    },
    dataType : {
        id: 'dataType', label: '분류', type: 'text', required: false,
        toAdd: true, toEdit: true,
        default:'신청서',
        help: '10자 이내',
        check: (value) => {return ValueUtil.textInRange(value,1,30)},
    },
};

export const DownloadInfoDictionary = {
    inputMap: downloadInfoInputMap,
    attachedFilesColumns : [
        { id: 'title', label: downloadInfoInputMap.title.label, width: 300, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
        { id: 'dataType', label: downloadInfoInputMap.dataType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    ],
    errorTitle: {
        Add: '다운로드정보 생성 오류',
        Edit: '다운로드정보 수정 오류',
        Remove: '다운로드정보 삭제 오류',
        Get: '다운로드정보 조회 오류',
        List: '다운로드 목록 조회 오류',
    }
};

const DOWNLOAD_INFO_LIST_PART = gql`
fragment DownloadInfoListPart on DownloadInfo {
    msgSeq
    title
    contents
    textFormat
    dataType
    regUserId
    regTime
    delYn
}
`;


export const LIST_DOWNLOAD_INFO = gql`
${DOWNLOAD_INFO_LIST_PART}
query ListDownloadInfo($forWhom:String) {
    downloadInfoList {
        ...DownloadInfoListPart
    }
    cvoFileList(forWhom:$forWhom) {
        fileNid
        filePath
        driveLink
        memo
        fileSize
        conType
    }
}
`;

export const GET_DOWNLOAD_INFO = gql`
${DOWNLOAD_INFO_LIST_PART}
query GetDownloadInfo($msgSeq:Int) {
    downloadInfoItem(msgSeq:$msgSeq) {
        ...DownloadInfoListPart
        files {
            msgSeq
            fileNid
            title
            regUserId
            regTime
            filePath
            driveLink
            fileSize
            memo
        }
    }
}
`;

export const ADD_DOWNLOAD_INFO = gql`
${DOWNLOAD_INFO_LIST_PART}
mutation AddDownloadInfo($downloadInfo:InputDownloadInfoAdd!) {
    downloadInfoAdd(downloadInfo: $downloadInfo) {
        ok
        message
        list {
            ...DownloadInfoListPart
        }
    }
}
`;

export const EDIT_DOWNLOAD_INFO = gql`
${DOWNLOAD_INFO_LIST_PART}
mutation EditDownloadInfo($downloadInfo:InputDownloadInfoEdit!) {
    downloadInfoEdit(downloadInfo: $downloadInfo) {
        ok
        message
        list {
            ...DownloadInfoListPart
        }
    }
}
`;

export const REMOVE_DOWNLOAD_INFO = gql`
${DOWNLOAD_INFO_LIST_PART}
mutation RemoveDownloadInfo($downloadInfo:InputDownloadInfoRemove!) {
    downloadInfoRemove(downloadInfo: $downloadInfo) {
        ok
        message
        list {
            ...DownloadInfoListPart
        }
    }
}
`;
