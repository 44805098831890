// MapPoiInfoBatch.js
import React, {useEffect, useRef, useState} from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Button, Popover, IconButton, TextField, Chip
} from '@mui/material';
import { AppWord, AppPalette, AppObject, MapValue, TableColumnType } from '../../model/AppConst';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PolygonWork from '../map/PolygonWork';
import FlexyTable from '../common/flexytable/FlexyTable';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { userInfoRepo, poiTypeRepo, poiInfoRepo } from '../../model/CvoModel';
import useClientSize from '../hook/useClientSize';
import ValueUtil from '../../model/ValueUtil';
import { LIST_POI_INFO_FOR_MAP, BATCH_POI_INFO, PoiInfoDictionary } from './PoiInfoGql';
import PoiBatch, {UploadStat} from './PoiBatch';

const FixedContainer = styled(Box)({
    position:"fixed", display:'flex', flexDirection:'column', width:600, top:0, left:0, backgroundColor:'white',
    padding:5, boxSizing:'border-box',
    zIndex:600
});

const ControlBox = styled(Box)({padding:5, display:'flex', borderBottom: AppPalette.BorderCCC, '&>button':{marginLeft:5}});
const TitleInControl = styled(Box)({flexGrow:1, fontWeight:'bold', fontSize:'1.05rem', display:'flex', alignItems:'center'});
const WorkBox = styled(Box)({ flexGrow:1, display:'flex' });
const TsvBox = styled(Box)({padding:5, display:'flex', flexDirection:'column'});
const TsvHeader = styled(Box)({paddingBottom:5});

const RowBox = styled(Box)({padding:"5px 2px 5px 0px"});
const LabelBox = styled('div')({fontSize:'0.85rem', display:'inline', color:AppPalette.PrimaryRGB, padding:"10px 2px 5px 0px"});

const TypeSelectorBox = styled(Box)({ maxHeight:400, overflow:'auto' });
const TypeRecord = styled(Box)({
    padding:"5px 15px 5px 15px", fontSize:'0.95rem', color:'#222',
    '&:hover': {color:'blue', backgroundColor: '#cef'},
    cursor:'pointer', display:'flex', alignItems:'center', borderRadius:3,
});
const TypeCheckbox = styled('div')({ width: 30, textAlign:'center' });
const TypeIcon = styled('div')({ width:28, textAlign:'center', overflow:'hidden' });
const TypeColor = styled('div')({ width:50, textAlign:'center', border: '1px solid #999', borderRadius:3, marginRight:10 });
const TextFieldWrapper = styled(Box)({paddingRight:10});
const TextFieldScroll = styled('textarea')({
    width:'100%', whiteSpace: 'nowrap', overflowX: 'auto', fontSize:'0.8rem', lineHeight: 1.7, color:'#334', padding:5
});

const StatusBox = styled(Box)({ backgroundColor:'#eee', fontSize:'0.8rem', padding:8 });

const TableColumns = [
    { id: 'stat', label: '상태', width: 50, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    //상태값 : null || bad, sch, ready || n/a, up, ok || fail
    { id: 'poiName', label: '지점명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'addr', label: '도로명주소', width: 300, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'typeName', label: '지점타입', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'tel', label: 'Tel', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custPoiCode', label: '고객지점코드', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'lat', label: '위도', width: 110, show: true, option:{align: 'right', print: (r,i,v)=>{if(v) return ValueUtil.degreeToText(v); else return 'N/A';}} },
    { id: 'lon', label: '경도', width: 110, show: true, option:{align: 'right', print: (r,i,v)=>{if(v) return ValueUtil.degreeToText(v); else return 'N/A';}} },
    { id: 'error', label: '오류', width:200, show:true, option:{align: 'inherit', type: TableColumnType.TEXT}}
];

const WorkStage = {ReadFile:1, SearchNaver:2, SearchingNow:3, Upload:4, SavingNow:5, Done:0};
const EmptyType = {poiType:'', typeName:'지점을 선택하세요'}

export default function MapPoiInfoBatch({
    mapShell,
    onCloseView,
    onAlert,
}) {
    const clientSize = useClientSize();
    const sessionInfo = useReactiveVar(userInfoRepo);
    const poiTypeRecords = useReactiveVar(poiTypeRepo);
    const [stageNow, setStage] = useState(WorkStage.ReadFile);
    const [poiTypeSelected, setPoiTypeSelected] = useState({...EmptyType});
    const [typePopoverAnchor, setTypePopoverAnchor] = useState(null);
    const [radius, setRadius] = useState(0);
    const [tsvText, setTsvText] = useState('');
    const [tsvErrors, setTsvErrors] = useState(0);
    const [recordsParsed, setRecordsParsed] = useState([]);

    const StageHelp = {};
    StageHelp[WorkStage.ReadFile] = '지점타입을 선택하고 입력 형식을 점검하세요';
    StageHelp[WorkStage.SearchNaver] = '네이버 주소검색을 실행할 수 있습니다';
    StageHelp[WorkStage.SearchingNow] = '네이버 주소검색 실행 중 입니다';
    StageHelp[WorkStage.Upload] = '주소검색이 완료되었습니다. 저장해 주세요';
    StageHelp[WorkStage.Done] = '작업이 완료되었습니다. 종료할 수 있습니다';

    // let indexSearch = 0;

    useEffect(()=>{
        if(poiTypeRecords.length>0) setPoiTypeSelected(poiTypeRecords[0].poiType);
    }, [poiTypeRecords]);

    // ##### Call GraphQL to get List #####
    const [getPoiInfoList, responsePoiList] = useLazyQuery(LIST_POI_INFO_FOR_MAP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetPoiList(data, option)},
		onError: (error) => {onAlert({open:true, error: error, title: "지점목록 조회 오류"})}
    });

    const [batchPoiInfo, responseBatch] = useMutation(BATCH_POI_INFO, {
        onCompleted: (data, option) => onCompleteBatch(data, option),
        onError: (error) => {onAlert({open:true, error: error, title: "지점정보 일괄저장 오류"})}
    });

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetPoiList = (data, clientOption) => {
        if(data.poiInfoListForMap) {
            poiInfoRepo(data.poiInfoListForMap.map((p)=>{
                if((!p.lat || !p.lon) && p.xDist && p.yDist) {
                    const {lat, lon} = mapShell.katek2ll({x:p.xDist, y:p.yDist});
                    p.lat = lat;
                    p.lon = lon;
                    return p;
                }
                return p;
            }));
        }
        if(data.custPoiTypeList) poiTypeRepo(data.custPoiTypeList);
    };

    const onCompleteBatch = (data, clientOption) => {
        if(data.poiInfoBatch.ok) {
            setRecordsParsed(PoiBatch.refreshWithResult(recordsParsed, data.poiInfoBatch.list));
            getPoiInfoList();
            setStage(WorkStage.Done);
        }
        else {
            onAlert({open:true, error: data.poiInfoBatch, title: "지점정보 일괄저장 오류"})
        }
    };

    if(!sessionInfo.isEtrace()) return null;
    const isDisguised = sessionInfo.isDisguised();

    const onSelectType = (type) => {
        setPoiTypeSelected({poiType:type.poiType, typeName:type.typeName});
        setTypePopoverAnchor(null);
    };

    const onChangeTsv = (e) => {
        setTsvText(e.target.value);
        setTsvErrors(0);
    };

    const saveAll = () => {
        const pwork = new PolygonWork(mapShell);
        const numRadius = typeof radius === 'string' ? parseInt(radius) : radius;

        const records = recordsParsed.filter((item)=>item.stat === UploadStat.READY).map((item)=>{
            const {x, y} = mapShell.ll2katek(item);
            item.xDist = Math.round(x);
            item.yDist = Math.round(y);
            item.branchOrder = 0;
            item.etc = '일괄등록';

            if(numRadius >= 5) {
                item.radius = numRadius;
                item.zoneShape = pwork.makeZoneRectFromXY(item.xDist, item.yDist, numRadius);
            }

            // 신규지만 편집용 데이터와 같은 내용 올린다. 일괄정보 처리를 위해서... (true at the end)
            return ValueUtil.getDataToSubmit(item, PoiInfoDictionary.inputMap, true);
        });

        batchPoiInfo({variables: {poiInfoList: records}});
    };

    const onClickSave = () => {
        setStage(WorkStage.SavingNow);
        saveAll();
    };

    /**
     * Recursively search position using naver geocode api
     */
    const geocodeOne = () => {
        let item = PoiBatch.getFirstPoiOfStat(recordsParsed, UploadStat.NEW);
        
        if(item) {
            mapShell.queryNaverGeocode(item.addr, (status, response) => {
                if(mapShell.isNaverResponseOk(status)) {
                    if(response.v2.addresses.length>0) {
                        const naverRes = response.v2.addresses[0];
                        item.lat = parseFloat(naverRes.y);
                        item.lon = parseFloat(naverRes.x);
                        item.stat = UploadStat.READY;
                    }
                    else item.stat = UploadStat.NA;
                }
                else item.stat = UploadStat.FAIL;

                setRecordsParsed(PoiBatch.updateToRecords(recordsParsed, item));

                setTimeout(function() {geocodeOne()}, 1000);
            });
        }
        else {
            setStage(WorkStage.Upload);
        }
    };

    const onClickSearchNaver = () => {
        if(recordsParsed.length>0) {
            setStage(WorkStage.SearchingNow);
            mapShell.setIndexBatch(0);
            geocodeOne();
        }
    };

    const onClickParse = () => {
        if(tsvText) {
            const {errorCount, records} = PoiBatch.parse(tsvText, poiTypeSelected);
            setRecordsParsed(records);
            setTsvErrors(errorCount);
            if(records.length > errorCount) setStage(WorkStage.SearchNaver);
        }
    };

    const onClickPoi = (item) => {
        if(item.lat && item.lon)
            mapShell.morphTo(item.lat, item.lon, MapValue.Level.MAX_FOR_CLUSTER+1);
    };

    const clearTsv = () => {
        setTsvErrors(0);
        setTsvText('');
    };

    const onClickClose = () => {
        setStage(WorkStage.ReadFile);
        clearTsv();
        onCloseView();
    };

    const onClickRest = () => {
        clearTsv();
        // setRadius(0);
        setRecordsParsed([]);
        setStage(WorkStage.ReadFile);
    };

    const renderPoiTypeSelector = () => {
        if(poiTypeRecords) {
            const open = Boolean(typePopoverAnchor);
            const IdForPopover = open ? 'id_poi_type_select_popover' : null;

            return(
                <RowBox>
                    <TextField
                        id="radius" onChange={(e)=>setRadius(e.target.value)}
                        label="반경. 5m미만 무시"
                        type='number' size={AppWord.SMALL}
                        style={{width:120, marginRight:20}}
                        value={radius}
                    />
                    <LabelBox>지점 타입
                    </LabelBox>
                        : <span style={{color:'black', fontWeight:'bold'}}>
                            {poiTypeSelected.typeName || '지점 타입을 선택하세요'}
                        </span>
                        <IconButton
                            aria-describedby={IdForPopover}
                            onClick={(e)=>setTypePopoverAnchor(e.currentTarget)}
                            color={AppPalette.PrimaryColor}
                            style={{border:'1px solid #cdf', marginLeft:10}}
                            size={AppWord.SMALL}
                        >
                            <KeyboardArrowDownIcon />
                        </IconButton>
                        <Popover
                            id={IdForPopover}
                            open={open}
                            anchorEl={typePopoverAnchor}
                            onClose={()=>setTypePopoverAnchor(null)}
                            anchorOrigin={{
                                vertical: AppWord.TOP,
                                horizontal: AppWord.LEFT,
                            }}
                            transformOrigin={{
                                vertical: AppWord.TOP,
                                horizontal: AppWord.LEFT,
                            }}

                        >
                            <TypeSelectorBox>
                                {
                                    poiTypeRecords.map((t)=>{
                                        return (
                                            <TypeRecord key={t.poiType} onClick={()=>onSelectType(t)}>
                                                <TypeCheckbox>
                                                    {
                                                        t.poiType === poiTypeSelected.poiType
                                                        ?
                                                        <RadioButtonCheckedIcon color={AppPalette.PrimaryColor} size={AppWord.SMALL}/>
                                                        :
                                                        <RadioButtonUncheckedIcon color={AppPalette.DisabledColor} size={AppWord.SMALL}/>
                                                    }
                                                </TypeCheckbox>
                                                <TypeIcon>
                                                    {
                                                        t.iconSaveName ?
                                                        <img src={MapValue.POI_ICON_DIR + "/" + t.iconSaveName.trim()} alt={t.typeName} 
                                                            style={{verticalAlign:'bottom'}}
                                                        />
                                                        : null
                                                    }
                                                </TypeIcon>
                                                <TypeColor
                                                    style={{
                                                        color: t.fontColor,
                                                        backgroundColor: t.backColor,
                                                    }}
                                                >
                                                    지점명
                                                </TypeColor>
                                                <span>{t.typeName}</span>
                                            </TypeRecord>
                                        )
                                    })
                                }
                            </TypeSelectorBox>
                        </Popover>
                </RowBox>
            );
        }
        else return <Box>No types</Box>
    };

    const renderButtonVariable = () => {
        if(stageNow === WorkStage.ReadFile) {
            return(
                <Button variant={AppPalette.VariantContained} size={AppWord.SMALL}
                    onClick={onClickParse}
                    disabled={!tsvText || !poiTypeSelected.poiType}
                >
                    1.입력
                </Button>
            );
        }
        else if(stageNow === WorkStage.SearchNaver) {
            return(
                <Button variant={AppPalette.VariantContained} size={AppWord.SMALL}
                    color={AppPalette.SuccessColor}
                    onClick={onClickSearchNaver}
                >
                    2.검색
                </Button>
            );
        }
        else if(stageNow === WorkStage.SearchingNow) {
            return(
                <Button variant={AppPalette.VariantContained} size={AppWord.SMALL}
                    color={AppPalette.SuccessColor}
                    disabled={true}
                >
                    검색중
                </Button>
            );
        }
        else if(stageNow === WorkStage.Upload) {
            return(
                <Button variant={AppPalette.VariantContained} size={AppWord.SMALL}
                    onClick={onClickSave}
                >
                    3.저장
                </Button>
            );
        }
        else if(stageNow === WorkStage.SavingNow) {
            return(
                <Button variant={AppPalette.VariantContained} size={AppWord.SMALL}
                    disabled={true}
                >
                    저장중
                </Button>
            );
        }
        return null;
    };

    return (
        <FixedContainer height={clientSize.height}>
            <ControlBox>
                <TitleInControl style={{color: isDisguised ? AppPalette.PrimaryRGB : 'red'}}>
                    {isDisguised ? sessionInfo.userAs.custName : '(주)이트레이스(?)'} POI 일괄 입력 
                </TitleInControl>
                {renderButtonVariable()}
                <Button variant={AppPalette.VariantContained} size={AppWord.SMALL}
                    onClick={onClickRest} color={AppPalette.WarnColor}
                    disabled={stageNow === WorkStage.SearchingNow || stageNow === WorkStage.SavingNow}
                >
                    리셋
                </Button>
                <Button variant={AppPalette.VariantContained} size={AppWord.SMALL}
                    onClick={onClickClose} color={AppPalette.InheritColor}
                >
                    종료
                </Button>
            </ControlBox>
            <TsvBox>
                <TsvHeader>
                    <Box>
                        {renderPoiTypeSelector()}
                    </Box>
                    <Box>
                        {
                            tsvErrors > 0
                            ?
                            <>
                            <LabelBox>오류</LabelBox>
                            입력한 데이터에 {tsvErrors}개의 오류가 있습니다.
                            </>
                            :
                            <>
                            <LabelBox>형식: </LabelBox>
                            지점명*<span
                                            style={{color:AppPalette.PrimaryRGB}}>[tab]</span>도로주소*<span
                                            style={{color:AppPalette.PrimaryRGB}}>[tab]</span>Tel<span
                                            style={{color:AppPalette.PrimaryRGB}}>[tab]</span>고객지점코드 x 100줄 이내
                            </>
                        }
                        <Chip label="x" color={AppPalette.WarnColor} size={AppWord.SMALL}
                            disabled={!Boolean(tsvText)} style={{marginLeft:10}}
                            onClick={()=>clearTsv()}
                        />
                    </Box>
                </TsvHeader>
                <TextFieldWrapper>
                    <TextFieldScroll rows={10}
                        value={tsvText || ''}
                        onChange={onChangeTsv}
                    />
                </TextFieldWrapper>
            </TsvBox>
            <WorkBox>
                <FlexyTable
                    name="poi_info_list_searched"
                    uniqueKey="poiId"
                    columns={TableColumns}
                    records={recordsParsed}
                    tools={[]}
                    onClickOneRow={onClickPoi}
                />
            </WorkBox>
            <StatusBox>
                {
                    StageHelp[stageNow] || '.....'
                }
                {
                    !isDisguised
                    ? <span style={{color:"red"}}>&nbsp;(자체 POI?)</span>
                    : null
                }
            </StatusBox>
        </FixedContainer>
    )
}
