// MyInfoInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette, AppWord } from '../../model/AppConst';
import { UserInfoDictionary } from './UserInfoGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import RadioSelector from '../common/RadioSelector';
import ValueUtil from '../../model/ValueUtil';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;
//const ColumnCount = 2;
//const EditorWidth = ColumnBoxWidth * ColumnCount + ColumnMargin * ColumnCount * 2 + 20;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
});

const InputMap = UserInfoDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.

export default function MyInfoInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    //const [inputData, setInputData] = useState(item ? item : {...defaultInputData}); // 입력한 데이터.
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        if(open) {
            if(item) {
                const d = {...item};
                d.telno = ValueUtil.reformWith(item.telno, AppWord.NUM_N_HYPN);
                d.mobile = ValueUtil.reformWith(item.mobile, AppWord.NUM_N_HYPN);
                resetData(d);
            }
            else resetData();
        }
    }, [item, open]);

    const resetData = (data) => {
        setInputData(data ? data : defaultInputData);
        setInputError({});
        setChanged(false);
        setHasError(false);
    };

    // 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, true);

        onClickMutate(param);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['사용자 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeNumAndHyphen = (event) => {
        if(event.target.value) {
            if(/[^-0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>내 정보 수정</EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                        <FieldBox>
                            <TextField id={InputMap.userId.id}
                                type={InputMap.userId.type}
                                label={InputMap.userId.label}
                                disabled={true}
                                value={inputData.userId}/>
                        </FieldBox>


                        <FieldBox>
                            <TextField id={InputMap.userName.id}
                                type={InputMap.userName.type}
                                label={InputMap.userName.label}
                                required={InputMap.userName.required}
                                value={inputData.userName}
                                error={inputError.userName}
                                helperText={InputMap.userName.help}
                                onChange={onChangeTextData} />
                        </FieldBox>

                    </InputColumn>
                    <InputColumn>

                        <FieldBox>
                            <TextField id={InputMap.telno.id}
                                type={InputMap.telno.type}
                                label={InputMap.telno.label}
                                required={InputMap.telno.required}
                                value={inputData.telno || ''}
                                error={inputError.telno}
                                helperText={InputMap.telno.help}
                                onChange={onChangeNumAndHyphen}
                            />
                        </FieldBox>
                        <FieldBox>
                            <TextField id={InputMap.mobile.id}
                                type={InputMap.mobile.type}
                                label={InputMap.mobile.label}
                                required={InputMap.mobile.required}
                                value={inputData.mobile || ''}
                                error={inputError.mobile}
                                helperText={InputMap.mobile.help}
                                onChange={onChangeNumAndHyphen}
                            />
                        </FieldBox>
                        <FieldBox>
                            <TextField id={InputMap.email.id}
                                type={InputMap.email.type}
                                label={InputMap.email.label}
                                required={InputMap.email.required}
                                value={inputData.email || ''}
                                error={inputError.email}
                                helperText={InputMap.email.help}
                                onChange={onChangeTextData}
                            />
                        </FieldBox>
                    </InputColumn>

                </EditorContentRow>
                <EditorContentRow>
                    
                        <FieldBox>
                            <TextField id={InputMap.password.id}
                                type={InputMap.password.type}
                                label={InputMap.password.label}
                                required={InputMap.password.required}
                                value={inputData.password || ''}
                                error={inputError.password}
                                helperText="본인 확인을 위해 암호를 입력해 주세요."
                                onChange={onChangeTextData} />
                        </FieldBox>

                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}
