// view_cruise/ViewCruiseGql.js
import { gql } from "@apollo/client";

export const ViewCruiseDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        vehId : {id:"vehId", toAdd: true, toEdit: false,},
        poiIdDep : {id:"poiIdDep", toAdd: true, toEdit: true,},
        routeId : {id:"routeId", toAdd: false, toEdit: true,},
        destList : {id:"destList", toAdd: true, toEdit: true},
        currentFlag : {id:"currentFlag", toAdd: true, toEdit: true,},
    },
    errorTitle: {
        Add: '배송노선 정보 생성 오류',
        Edit: '배송노선 정보 수정 오류',
        Remove: '배송노선 정보 삭제 오류',
        List: '배송노선 정보 조회 오류',
        Truncate: '배송노선 일괄 삭제 오류',
        SetFlag: '배송노선 상태 변경 오류',
        CruiseLog: '배송노선 과거기록 조회 오류'
    }
};

const VIEW_CRUISE_LIST_PART = gql`
fragment ViewCruiseListPart on ViewCruise {
    custId
    routeId
    recId
    vehId
    vehPlates
    driverName
    driverMobile
    depNotiDoneYn
    lastNotiTime
    centerAta
    atdFrom
    ataTo
    poiIdDep
    poiNameDep
    poiIdArr
    poiNameArr
    movement
    sendDepMsgTo
    sendArrMsgTo
    arrNotiDoneYn
    currentFlag
    routeDone
    routeRunning
    userId
    regTime
}
`;

export const LIST_VIEW_CRUISE = gql`
${VIEW_CRUISE_LIST_PART}
query ListViewCruise {
    viewCruiseList {
        ...ViewCruiseListPart
    }
}
`;

export const LIST_VIEW_CRUISE_AND_POI = gql`
${VIEW_CRUISE_LIST_PART}
query ListThree($custId:String) {
    viewCruiseList {
        ...ViewCruiseListPart
    }
    poiInfoList {
        poiId
        poiName
        poiType
        typeName
        custPoiCode
        addr
        tel
        useBcdYn
        bcdMapped
    }
    vehInfoList(custId:$custId) {
        vehId
        custId
        custName
        vehPlates
        vehAlias
        devId
        vehTypeTon
        driverName
        driverMobile
        useYn
    }
}
`;


export const ADD_VIEW_CRUISE = gql`
${VIEW_CRUISE_LIST_PART}
mutation AddViewCruise($viewCruise:InputViewCruiseAdd!) {
    viewCruiseAdd(viewCruise: $viewCruise) {
        ok
        message
        list {
            ...ViewCruiseListPart
        }
    }
}
`;

export const EDIT_VIEW_CRUISE = gql`
${VIEW_CRUISE_LIST_PART}
mutation EditViewCruise($viewCruise:InputViewCruiseEdit!) {
    viewCruiseEdit(viewCruise: $viewCruise) {
        ok
        message
        list {
            ...ViewCruiseListPart
        }
    }
}
`;

export const REMOVE_VIEW_CRUISE = gql`
${VIEW_CRUISE_LIST_PART}
mutation RemoveViewCruise($viewCruise:InputViewCruiseRemove!) {
    viewCruiseRemove(viewCruise: $viewCruise) {
        ok
        message
        list {
            ...ViewCruiseListPart
        }
    }
}
`;

export const TRUNCATE_VIEW_CRUISE = gql`
${VIEW_CRUISE_LIST_PART}
mutation TruncateViewCruise {
    viewCruiseTruncate {
        ok
        message
        list {
            ...ViewCruiseListPart
        }
    }
}
`;

export const SET_FLAG_VIEW_CRUISE = gql`
${VIEW_CRUISE_LIST_PART}
mutation SetFlagViewCruise($viewCruise:InputViewCruiseSetFlag!) {
    viewCruiseSetFlag(viewCruise: $viewCruise) {
        ok
        message
        list {
            ...ViewCruiseListPart
        }
    }
}
`;

export const LIST_CRUISE_LOG_FOR_VEH = gql`
query ListCruiseLogForVeh($vehId:Int) {
    cruiseLogListForVeh(vehId:$vehId) {
        cruiseNid
        logTime
        custId
        routeId
        vehId
        vehPlates
        poiId
        centerName
        centerAta
        centerAtd
        lastAta
        destinCsv
    }
}
`;

export const LIST_CRUISE_LOG_FOR_POI = gql`
query ListCruiseLogForVeh($poiId:Int) {
    cruiseLogListForPoi(poiId:$poiId) {
        cruiseNid
        logTime
        custId
        routeId
        vehId
        vehPlates
        poiId
        centerName
        centerAta
        centerAtd
        lastAta
        destinCsv
    }
}
`;

