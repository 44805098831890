// CustPeopleInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette, CvoCodes, AppWord } from '../../model/AppConst';
import { CustPeopleDictionary } from './CustPeopleGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ValueUtil from '../../model/ValueUtil';
import CodeSelector from '../common/CodeSelector';
import { codeTreeRepo } from '../../model/CvoModel';
import { useReactiveVar } from '@apollo/client';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
    paddingBottom: 4
});

const SelectSlot = styled(Box)({
    display: 'table-cell',
});

const InputMap = CustPeopleDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.


export default function CustPeopleInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    custId, // If ...
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        if(item) {
			const d = {...item};
			// d.tel = ValueUtil.reformWith(item.tel, AppWord.NUM_N_HYPN);
            setInputData(d);
        }
		else setInputData({...defaultInputData, custId: custId});
		setInputError({});
		setChanged(false);
		setHasError(false);
    }, [item, custId]);

    const RoleCodeList = ValueUtil.codeToSelectList(CvoCodes.ROLE_CD, CodeTree);

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // add extra data if necessary.
        param.custId = custId;
        if(isEdit) param.recId = item.recId;

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '담당자 정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeNumAndHyphen = (event) => {
        if(event.target.value) {
            if(/[^-0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

	// If necessary
    const onChangeCode = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        setInputData(data);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size={AppWord.SMALL}
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value || ''}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    const renderSelectorSlot = (dict, value, selectFrom) => {
        return(
            <SelectSlot>
                    <CodeSelector
                        id={dict.id}
                        value={value || dict.default}
                        label={dict.label}
                        codes={selectFrom}
                        onChange={onChangeCode}
                        minWidth={180}
                    />
            </SelectSlot>
        );
    };

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>
                {isEdit ? '담당자 정보 수정' : '담당자 정보 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                            {renderTextFieldBox(InputMap.pName, inputData.pName,
								inputError.pName, onChangeTextData) /* 담당자 name */}
                            {renderSelectorSlot(InputMap.roleCd, inputData.roleCd, RoleCodeList)}
                            {renderTextFieldBox(InputMap.telNo, inputData.telNo,
								inputError.telNo, onChangeNumAndHyphen) /* 연락처 */}
                            {renderTextFieldBox(InputMap.dept, inputData.dept,
								inputError.dept, onChangeTextData) /* 부서 */}
                            {renderTextFieldBox(InputMap.jobRank, inputData.jobRank,
								inputError.jobRank, onChangeTextData) /* 직급 */}
                            {renderTextFieldBox(InputMap.roleDetail, inputData.roleDetail,
								inputError.roleDetail, onChangeTextData) /* 업무 */}
                            {renderTextFieldBox(InputMap.mobile, inputData.mobile,
								inputError.mobile, onChangeNumAndHyphen) /* 휴대전화 */}

                    </InputColumn>
                    <InputColumn>
                            {renderTextFieldBox(InputMap.email, inputData.email,
								inputError.email, onChangeTextData) /* email */}
                            {renderTextFieldBox(InputMap.messenger, inputData.messenger,
								inputError.messenger, onChangeTextData) /* messenger */}
                            {renderTextFieldBox(InputMap.faxNo, inputData.faxNo,
								inputError.faxNo, onChangeNumAndHyphen) /* fax */}
                            {renderTextFieldBox(InputMap.zipCd, inputData.zipCd,
								inputError.zipCd, onChangeTextData) /* zipCd */}
                            {renderTextFieldBox(InputMap.addr, inputData.addr,
								inputError.addr, onChangeTextData) /* addr */}
                            <FieldBox>
                                <TextField id={InputMap.pMemo.id} rows={4} multiline size={AppWord.SMALL}
                                    type={InputMap.pMemo.type}
                                    label={InputMap.pMemo.label}
                                    required={InputMap.pMemo.required}
                                    value={inputData.pMemo || ''}
                                    error={inputError.pMemo}
                                    onChange={onChangeTextData} />
                            </FieldBox>
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}