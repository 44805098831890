// notify_board/NotifyBoardGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

const notiInputMap = {
    msgSeq : { required: true, toAdd: false, toEdit: true, },
    title : {
        id: 'title', label: '제목', type: 'text', required: true,
        toAdd: true, toEdit: true,
        help: '100자 이내로 입력하세요.',
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,300)},
    },
    contents : {
        id: 'contents', label: '본문내용', type: 'text', required: false,
        toAdd: true, toEdit: true,
        check: (value) => {return ValueUtil.textInRange(value,1,2140967295)},
    },
    textFormat : {
        id: 'textFormat', label: '본문 형식', type: 'radio', required: true,
        toAdd: true, toEdit: true,
        default:'text'
    },
};

export const NotifyBoardDictionary = {
    inputMap: notiInputMap,
    errorTitle: {
        Add: '공지사항 생성 오류',
        Edit: '공지사항 수정 오류',
        Remove: '공지사항 삭제 오류',
        Get: '공지사항 조회 오류',
        List: '공지사항 목록 조회 오류',
    }
};

const NOTIFY_BOARD_LIST_PART = gql`
fragment NotifyBoardListPart on NotifyBoard {
    msgSeq
    title
    regUserId
    regTime
    delYn
    readIt
}
`;

const NOTIFY_BOARD_NO_LIST_PART = gql`
fragment NotifyBoardNoListPart on NotifyBoard {
    contents
    textFormat
    files {
        msgSeq
        fileNid
        title
        filePath
        driveLink
        fileSize
        memo
    }
}
`;


export const LIST_NOTIFY_BOARD = gql`
${NOTIFY_BOARD_LIST_PART}
query ListNotifyBoard {
    notifyBoardList {
        ...NotifyBoardListPart
    }
}
`;

export const GET_NOTIFY_BOARD = gql`
${NOTIFY_BOARD_LIST_PART}
${NOTIFY_BOARD_NO_LIST_PART}
query GetNotifyBoard($msgSeq:Int!) {
    notifyBoardItem(msgSeq:$msgSeq) {
        ...NotifyBoardListPart
        ...NotifyBoardNoListPart
    }
}
`;

export const ADD_NOTIFY_BOARD = gql`
${NOTIFY_BOARD_LIST_PART}
${NOTIFY_BOARD_NO_LIST_PART}
mutation AddNotifyBoard($notifyBoard:InputNotifyBoardAdd!) {
    notifyBoardAdd(notifyBoard: $notifyBoard) {
        ok
        message
        list {
            ...NotifyBoardListPart
            ...NotifyBoardNoListPart
        }
    }
}
`;

export const EDIT_NOTIFY_BOARD = gql`
${NOTIFY_BOARD_LIST_PART}
mutation EditNotifyBoard($notifyBoard:InputNotifyBoardEdit!) {
    notifyBoardEdit(notifyBoard: $notifyBoard) {
        ok
        message
        list {
            ...NotifyBoardListPart
        }
    }
}
`;

export const REMOVE_NOTIFY_BOARD = gql`
${NOTIFY_BOARD_LIST_PART}
mutation RemoveNotifyBoard($notifyBoard:InputNotifyBoardRemove!) {
    notifyBoardRemove(notifyBoard: $notifyBoard) {
        ok
        message
        list {
            ...NotifyBoardListPart
        }
    }
}
`;
