// ConfirmDialog.js
import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { AppPalette } from '../../model/AppConst';

const IdConfirmDialogTitle = 'id-cfm-dialog-title-etrace';

export default function ConfirmDialog({
    open,
    prompt,
    onClose
}) {
    return (
        <Dialog
            open={open}
            onClose={()=>onClose()}
            aria-labelledby={IdConfirmDialogTitle}>
            <DialogTitle id={IdConfirmDialogTitle} style={{backgroundColor:AppPalette.WarningPurpleBack, color:'white'}}>
                {prompt.title}
            </DialogTitle>
            <DialogContent>
                {prompt.message ? <p>{prompt.message}</p> : null}
                {
                    prompt.messages ?
                    <ul>
                    {
                        prompt.messages.map((msg, idx) => {
                            return(<li key={idx}>{msg}</li>);
                        })
                    }
                    </ul>
                    : null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>onClose()} autoFocus variant='outlined'
                    startIcon={<WarningAmberIcon />}>
                    {prompt.labelToNo ? prompt.labelToNo :"아니오"}
                </Button>
                <Button onClick={()=>onClose(prompt.data)} variant='outlined' color='secondary'
                    startIcon={<CheckIcon />}>
                    {prompt.labelToYes ? prompt.labelToYes : "예"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
