// AppMenuInHeader.js
import React from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { AppNumber } from '../../model/AppConst';
import MenuBox from './MenuBox';

const TypeMenuBox = styled(Box)`
    width: 100%;
    padding-left:${AppNumber.MenuLeft}px;
    color: #cccccc;
    font-size: small;
    min-height: 25;
    cursor: pointer;

    :hover {color: yellow}
`;

export default function AppMenuInHeader({onMouseEnter, onClick}) {
    // 
    return(
        <TypeMenuBox onMouseEnter={onMouseEnter}
            onClick={onClick}>
            <MenuBox itemMode={false} />
        </TypeMenuBox>
    );
}
