// bcd_io_data/BcdIoDataGql.js
import { gql } from "@apollo/client";

const BCD_IO_DATA_LIST_COMMON = gql`
fragment BcdIoDataListCommon on BcdIoData {
    dataKey
    custId
    custName
    vehId
    poiId
    arrDatetime
    arrTime
    depTime
    poiName
    vehPlates
    driverName
    driverMobile
    arrDist
    depDist
    xDist
    yDist
    lat
    lon
    secStay
    staying
}
`;


export const LIST_BCD_IO_DATA = gql`
${BCD_IO_DATA_LIST_COMMON}
query ListBcdIoData($timeFrom:String, $timeTill:String) {
    bcdIoDataList(timeFrom:$timeFrom, timeTill:$timeTill) {
        ...BcdIoDataListCommon
        poiNamePrev
        depTimePrev
        distKm
        secRun
    }
}
`;

export const LIST_BCD_IO_DATA_FOR_VEHICLE = gql`
${BCD_IO_DATA_LIST_COMMON}
query ListBcdIoDataForVehicle($vehId:Int, $timeFrom:String, $timeTill:String) {
    bcdIoDataListForVehicle(vehId:$vehId, timeFrom:$timeFrom, timeTill:$timeTill) {
        ...BcdIoDataListCommon
        distKm
        secRun
    }
}
`;

export const LIST_BCD_IO_DATA_FOR_POI = gql`
${BCD_IO_DATA_LIST_COMMON}
query ListBcdIoDataForPoi($poiId:Int, $timeFrom:String, $timeTill:String) {
    bcdIoDataListForPoi(poiId:$poiId, timeFrom:$timeFrom, timeTill:$timeTill) {
        ...BcdIoDataListCommon
    }
}
`;


export const SIMPLE_LIST_VEH_INFO = gql`
query ListVehInfo($custId:String) {
    vehInfoList(custId:$custId) {
        vehId
        custId
        custName
        vehPlates
        vehAlias
        driverName
        driverMobile
    }
}
`;

export const LIST_OF_VEHICLE = gql`
    query ListOfVehicle($custId:String) {
        vehInfoList(custId:$custId) {
            vehId
            custId
            custName
            vehPlates
            vehAlias
            driverName
            driverMobile
        }
    }
`;


export const LIST_OF_POI = gql`
    query ListOfPoi {
        poiInfoList {
            poiId
            poiName
            tel
            addr
        }
    }
`;
