//
import React, { useEffect } from 'react';
import { Radio, FormControlLabel, FormControl, FormLabel, RadioGroup } from '@mui/material';

const IdLabel = 'id-form-control-form-label-above-group';

export default function RadioSelector({
    id,
    label,
    initialValue,
    selectFrom,
    onChange,
    horizontal,
    disabled
}) {
    const [value, setValue] = React.useState(initialValue);

    useEffect(()=>{setValue(initialValue)}, [initialValue]);

    const handleChange = (event) => {
        setValue(event.target.value);
        onChange({target: {id: id, value: event.target.value}}); // mimic event
    };
  
    return (
        <FormControl>
            {Boolean(label) ? <FormLabel id={IdLabel}>{label}</FormLabel> : null}
            <RadioGroup
                aria-labelledby={IdLabel}
                name={id}
                value={value}
                onChange={handleChange}
                row={horizontal}
            >
                {
                    selectFrom.map((item) => {
                        return(
                            <FormControlLabel key={item.value}
                                value={item.value}
                                control={<Radio disabled={disabled ? true : false} />}
                                label={item.label} />
                        );
                    })
                }
            </RadioGroup>
        </FormControl>
    );
}
