// ViewCruiseInput.js
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette, AppNumber, CvoCodes, AppWord } from '../../model/AppConst';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ConfirmDialog from '../message/ConfirmDialog';
import VehInfoNarrowList from '../veh_info/VehInfoNarrowList';
import PoiInfoNarrowList from '../poi_info/PoiInfoNarrowList';
import { ViewCruiseDictionary } from './ViewCruiseGql';
//import FlexyTable from '../common/flexytable/FlexyTable';
//import HeightReader from '../common/HeightReader';


const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const dcPad = AppNumber.dataContainerPadding;
const ContentContainer = styled(Box)({
    display:'flex', position:'absolute', left:dcPad, top:dcPad, bottom:dcPad, right:dcPad, // backgroundColor:'#eee'
}); // has: VehicleListBox RouteAndPoiBox

const VehicleListBoxWidth = 300;
const VehicleListBox = styled(Box)({
    display:'flex', width:VehicleListBoxWidth
});

const RouteAndPoiBox = styled(Box)({
    flexGrow:1, display: 'flex', flexDirection:'column', paddingLeft:10
}); // has: PresentRouteBox, PoiPairBox

const PresentRouteBox = styled(Box)({marginBottom:10});
const PoiPairBox = styled(Box)({
    flexGrow:1, display:'flex'
}); // has: 2 SetBox // not DestinListBox, PoiListdBox

const PresentRouteListHeight = 240;
const PresentRouteListHeader = styled(Box)({
    display:'flex', alignItems:'center',
    height:AppNumber.HeaderToolBoxHeight, fontWeight:'bold', fontSize:'1.05rem'
});
const PresentRouteList = styled(Box)({
    maxHeight:PresentRouteListHeight, overflow:'auto', border: AppPalette.BorderCCC, borderRadius:5, padding: 10
});
const NoPresentRouteList = styled(Box)({padding:10, border: AppPalette.BorderCCC, borderRadius:5});

const RouteRow = styled(Box)({display:'flex', margin:5});
const RoutePoiBox = styled(Box)({display:'inline-block', padding:10, border: AppPalette.BorderCCC, borderRadius:5});
const RouteDestinBox = styled(Box)({flexGrow:1, overflow: 'auto'});
const RouteRoadAndPoi = styled(Box)({display:'inline-block'});
const RouteRoadBox = styled(Box)({display:'inline-block'});
// -----------------------------------------------------------------
const InputMap = ViewCruiseDictionary.inputMap;
const DestinListBox = styled(Box)({width:'50%', display:'flex', marginRight:10});
const PoiListdBox = styled(Box)({width:'50%', display:'flex'});
const EmptyData = {destList:[]};

export default function ViewCruiseInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    poiList,
    vehList,
    routeList,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onRequestEdit,
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(EmptyData); // 입력한 데이터.
    const [vehicle, setVehicle] = useState(null);
    const [presentRoutes, setPresentRoutes] = useState([]);
    const [destinSelected, setDestinSelected] = useState([]);
    // const [destinPoiIdList, setDestinPoiIdList] = useState([]); // destinSelected 중 poiId만 모은 것. 연산 용이. 최종 전송 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.
    //const contentRef = useRef(null);

    useEffect(()=>{
        if(vehicle && routeList.length > 0) {
            setPresentRoutes(routeList.filter((route)=>{return route.vehId===vehicle.vehId}));
        }
        else setPresentRoutes([]);
    }, [routeList, vehicle]);

    useEffect(()=>{
        if(isEdit) {
            const destin = [item.poiIdDep];
            for(const dest of item.destins) destin.push(dest.poiIdArr);
            setVehicle({vehId: item.vehId, vehPlates: item.vehPlates});
            setInputData({routeId: item.routeId,destList:destin});
            setDestinSelected(poiList.filter((poi)=>destin.includes(poi.poiId)).sort((a,b)=>destin.indexOf(a.poiId) - destin.indexOf(b.poiId)));
        }
        else {
            setInputData(EmptyData);
            setDestinSelected([]);
        }

        setChanged(false);
        setInputError({});
        setHasError(false);
    }, [isEdit, item, poiList]);

    const resetData = () => {
        setVehicle(null);
        setDestinSelected([]);
        // setDestinPoiIdList([]);
        setInputData(EmptyData);
        setChanged(false);
        setInputError({});
        setHasError(false);
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const pois = [...inputData.destList]
        const depId = pois.shift();
        const param = {poiIdDep: depId, destCsv: pois.join(",")};
        if(isEdit) param.routeId = item.routeId;
        else param.vehId = inputData.vehId;
        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const changeAndCheck = (id, value) => {
        const data = {...inputData};
        const error = {...inputError};
        data[id] = value;
        if(!isEdit) error.vehId = data.vehId ? undefined : "차량이 선택되지 않음";
        error.destList = data.destList.length > 1 ? undefined : "2곳 이상의 지점이 선택되어야 합니다.";
        setHasError(Boolean(error.vehId) || Boolean(error.destList));
        setInputError(error);
        setInputData(data);
        setChanged(true);
    };

    const onClickToolOnPoiListRow = (toolId, row) => {
        if(toolId === AppWord.CMD_ADD) {
            const oldDestin = destinSelected.slice();
            oldDestin.push(row);
            changeAndCheck(InputMap.destList.id, oldDestin.map((d)=>d.poiId));
            // setDestinPoiIdList(oldDestin.map((d)=>d.poiId));
            setDestinSelected(oldDestin);
        }
    };

    const changeDestinOrder = (destin, isUp) => {
        const lastIdx = destinSelected.length - 1;
        let idx = -1;
        for(let i=0;i<destinSelected.length;i++) {
            if(destin.poiId===destinSelected[i].poiId) {
                idx = i;
                break;
            }
        }
        if(idx < 0 || (idx===0 && isUp) || (idx===lastIdx && !isUp)) return;
        const diff = isUp ? -1 : 1;
        const newDestin = destinSelected.slice();
        const rep = newDestin[idx+diff];
        newDestin[idx] = rep;
        newDestin[idx+diff] = destin;

        changeAndCheck(InputMap.destList.id, newDestin.map((d)=>d.poiId));
        // setDestinPoiIdList(newDestin.map((d)=>d.poiId));
        setDestinSelected(newDestin);
    };

    const onClickToolOnDestinListRow = (toolId, row) => {
        if(toolId === AppWord.CMD_REMOVE) {
            const newDestin = destinSelected.filter((d)=>d.poiId !== row.poiId);
            changeAndCheck(InputMap.destList.id, newDestin.map((d)=>d.poiId));
            // setDestinPoiIdList(newDestin.map((d)=>d.poiId));
            setDestinSelected(newDestin);
        }
        else if(toolId===AppWord.CMD_UP) changeDestinOrder(row, true);
        else if(toolId===AppWord.CMD_DOWN) changeDestinOrder(row, false);
        else return;
    };

    const onClickVehicle = (veh) => {
        if(!isEdit || !vehicle || veh.vehId !== vehicle.vehId) {
            setVehicle(veh);
            changeAndCheck(InputMap.vehId.id, veh.vehId);
        }
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderVehicles = () => {
        return(
            <VehicleListBox>
                <VehInfoNarrowList
                    columnList={['vehPlates', 'driverName', 'driverMobile']}
                    records={vehList}
                    selected={vehicle}
                    onClickItem={onClickVehicle}
                />
            </VehicleListBox>
        );
    };

    const renderPoiList = () => {
        return(
            <PoiListdBox>
                <PoiInfoNarrowList
                    title="지점"
                    records={poiList.filter((poi)=>{return !inputData.destList.includes(poi.poiId)})}
                    tools={[
                        {id:AppWord.CMD_ADD, icon:<AddLocationIcon fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor} />}
                    ]}
                    onClickToolOnRow={onClickToolOnPoiListRow}
                />
            </PoiListdBox>
        );
    };

    const renderDestinList = () => {
        return(
            <DestinListBox>
                <PoiInfoNarrowList
                    title={"배송노선" + (inputData.destList.length > 0 ? " - " + destinSelected[0].poiName + " 출발" : "")}
                    hideSearchBox={true}
                    records={destinSelected}
                    tools={[
                        {id:AppWord.CMD_UP, icon:<ArrowUpwardIcon fontSize={AppWord.SMALL} />},
                        {id:AppWord.CMD_DOWN, icon:<ArrowDownwardIcon fontSize={AppWord.SMALL} />},
                        {id:AppWord.CMD_REMOVE, icon:<WrongLocationIcon fontSize={AppWord.SMALL} color={AppPalette.WarnColor} />},
                    ]}
                    onClickToolOnRow={onClickToolOnDestinListRow}
                />
            </DestinListBox>
        );
    };


    const renderPresentRoutes = () => {
        return(
            <PresentRouteBox>
                <PresentRouteListHeader>
                    {
                        vehicle
                        ?
                        `${vehicle.vehPlates} 차량에 현재 설정되어 있는 배송노선`
                        : '차량 선택 후 배송노선 설정'
                    }
                </PresentRouteListHeader>
                {
                    !vehicle
                    ?
                    <NoPresentRouteList>
                        <ul>
                            <li>차량을 먼저 선택하세요</li>
                            <li>배송노선에 오른쪽 테이블의 지점을 추가합니다. 지점에 마우스를 올리고 오른쪽의 <AddLocationIcon
                                fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor} style={{verticalAlign:'bottom'}} /> 아이콘을
                            클릭하면 됩니다.
                            </li>
                            <li>왼쪽 테이블에서도 지점의 마우스를 올린 후 지점 삭제, 순서 변경 등을 할 수 있습니다.</li>
                            <li>배송노선의 1번 지점이 출발지입니다.</li>
                        </ul>
                    </NoPresentRouteList>
                    :
                    presentRoutes.length===0
                    ?
                    <NoPresentRouteList>기존에 설정되어있는 배송노선이 아직 없습니다.</NoPresentRouteList>
                    :
                    <PresentRouteList>
                        {
                            presentRoutes.map((route)=>{
                                return(
                                    <RouteRow key={route.routeId}>
                                        <RoutePoiBox>{route.poiNameDep}</RoutePoiBox>
                                        <RouteDestinBox>
                                            {
                                                route.destins.map((dest, idx)=>{
                                                    return(
                                                        <RouteRoadAndPoi key={idx}>
                                                            <RouteRoadBox>--</RouteRoadBox>
                                                            <RoutePoiBox>{dest.poiNameArr}</RoutePoiBox>
                                                        </RouteRoadAndPoi>
                                                    );
                                                })
                                            }
                                        </RouteDestinBox>
                                    </RouteRow>
                                );
                            })
                        }
                    </PresentRouteList>
                }
            </PresentRouteBox>
        );
    };

    
    return (
        <Dialog open={open} fullScreen>
            <EditorTitle>
                {isEdit ? item.vehPlates + '배송노선 정보 수정' : '신규 배송노선 추가'}
            </EditorTitle>
            <DialogContent
                style={{position:'relative'}}
            >
                <ContentContainer>
                    {renderVehicles()}
                    <RouteAndPoiBox>
                        {renderPresentRoutes()}
                        <PoiPairBox>
                            {renderDestinList()}
                            {renderPoiList()}
                        </PoiPairBox>
                    </RouteAndPoiBox>

                </ContentContainer>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed || inputData.destList.length < 2}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}