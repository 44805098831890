// com/archive/ArchiveDownload.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ResponseAlert from '../message/ResponseAlert';
import useClientSize from '../hook/useClientSize';
import FlexyTable from '../common/flexytable/FlexyTable';
import { AppNumber, AppObject, AppWord, TableColumnType } from '../../model/AppConst';
import { LIST_POS_ARCHIVE } from './ArchiveGql';
import { useLazyQuery } from '@apollo/client';

const dcPad = AppNumber.dataContainerPadding;

const ArchiveDownloadContainer = styled(Box)({
    position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection:'column'
	// flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block'
});

const PrintDownloadLink = (value) => {
    const year = value.substring(0,4);
    const mm = value.substring(4);
    return(
        <a href={"/download/archive/" + value}
            target="_blank" rel="noopener noreferrer"
        >
            {year}년 {mm}월 위치추적사실확인자료 다운로드
        </a>
    );
};

const TableColumns = [
    { id: 'type', label: '파일종류', width: 200, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'size', label: '파일크기', width: 200, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'fileName', label: '다운로드', width: 400, show: true, option:{align: 'inherit', print: (r,i,v) => {return PrintDownloadLink(v)}} },
];

export default function ArchiveDownload({
	maxHeight,
	maxWidth,
}) {
    const [archiveList, setArchiveList] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    let lastDownload = new Date(); // Not before 3 min passed.

    // ##### Call GraphQL to get List #####
    const [getArchiveList, responseList] = useLazyQuery(LIST_POS_ARCHIVE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetArchiveList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "위치추적사실확인자료 조회 오류"})}
    });

    useEffect(()=>{
        getArchiveList();
    }, [getArchiveList]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetArchiveList = (data, clientOption) => {
        if(data.getArchiveList) {
            setArchiveList(data.getArchiveList.slice());
            lastDownload = new Date();
        }
    };

    const onClickReload = () => {
        const now = new Date();
        const diff = (now.getTime() - lastDownload.getTime())/1000;
        if(diff > 1800) {
            getArchiveList();
        }
    };

    return (
        <ArchiveDownloadContainer>
            <HeaderBox>
                <ButtonBox>
                    <Button variant='contained' color='inherit'
                        onClick={onClickReload}
                    >
                        <RefreshIcon fontSize={AppWord.SMALL}/>
                        다시 읽기
                    </Button>
                </ButtonBox>
            </HeaderBox>
            <FlexyTable
                name="archive_pos_list"
                uniqueKey="fileName"
                columns={TableColumns}
                records={archiveList}
                tools={[]}
                hideFirstTopButton={true}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </ArchiveDownloadContainer>
    )
}
