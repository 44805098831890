// user_info/UserInfoGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const UserInfoDictionary = {
    passwordMap: {
        custId : {toAdd: true, toEdit: true},
        userId : {toAdd: true, toEdit: true},
        password : {
            id:'password', label:'현재 암호', type: 'password', required: true,
            toAdd: true, toEdit: true,
        },
        newPassword : {
            id:'newPassword', label:'신규 암호', type: 'password', required: true,
            toAdd: true, toEdit: true,
            help: '대문자, 소문자, 숫자, 특수문자 3가지 이상을 섞어 8자 이상',
            check:(value)=>{return ValueUtil.goodPasswordRequired(value)} },
        confirm : {
            id:'confirm', label:'암호 확인', type: 'password', required: true,
            toAdd: true, toEdit: true
        },
    },
    inputMap: {
        custId : {toAdd: true, toEdit: true},
        userId : {
            id:'userId', label:'사용자 ID', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '4~20자 이내의 영문, 숫자만 사용합니다.',
            check:(value)=>{return /^[_a-zA-Z0-9]{4,20}$/.test(value)} },
        password : {
            id:'password', label:'암호', type: 'password', required: true,
            toAdd: true, toEdit: true,
            help: '대문자, 소문자, 숫자, 특수문자 3가지 이상을 섞어 8자 이상',
            check:(value)=>{return ValueUtil.goodPasswordRequired(value)} },
        userName : {
            id:'userName', label:'이름', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '2자~10자 이내로 입력합니다.',
            check:(value)=>{return Boolean(value) && value.length > 1 && value.length < 11} },
        classNo : {
            id: 'classNo', label:'권한', type: 'radio', required: true,
            toAdd: true, toEdit: true,
            default: 2000,
            selectEtrace: [
                {value: 10, label: '서비스 관리자'},
                {value: 20, label: '서비스 중간관리자'},
                {value: 100, label: '서비스 관계자'},
                {value: 1000, label: '관리자'},
                {value: 2000, label: '일반 사용자'},
                // 4000 고객의 손님
            ],
            select: [
                {value: 1000, label: '관리자'},
                {value: 2000, label: '일반 사용자'},
                // 4000 고객의 손님
            ],
            check:(value)=>{return [10,20,100,1000,2000].includes(value);}
        },
        telno: {
            id: 'telno', label: '사용자 연락처', type: 'tel', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value);}
        },
        mobile: {
            id: 'mobile', label: '사용자 휴대전화', type: 'tel', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value);}
        },
        email : {
            id: 'email', label: '사용자 메일주소', type: 'email', required: false,
            toAdd: true, toEdit: true,
            help: '60자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || (ValueUtil.goodEmail(value) && value.length <= 60 );},
        },
    },
    // for response error printing
    // queryName: {Add:'userInfoAdd', Edit:'userInfoEdit', Remove:'userInfoRemove'},
    errorTitle: {
        List: '사용자 목록 조회 오류',
        Add: '사용자 정보 생성 오류',
        Edit: '사용자 정보 수정 오류',
        Remove: '사용자 정보 삭제 오류',
        Get: '사용자 정보 조회 오류',
        ChPwd: '암호변경오류',
    }
};


const USER_INFO_LIST_PART = gql`
fragment UserInfoListPart on UserInfo {
    userId
    custId
    userName
    custName
    classNo
    className
    email
    telno
    mobile
    userStateCd
    userStateValue
    loginTime
}
`;

const USER_INFO_NO_LIST_PART = gql`
fragment UserInfoNoListPart on UserInfo {
    password
    viewLevel
    initXcoord
    initYcoord
    initLat
    initLon
    viewType
    alarmSmsYn
    alarmMsgYn
    alarmInterval
    alarmFrom
    alarmTill
    warnFontColor
    warnBackColor
    canResetYn
    langCd
    jsonUserConf
    logoutTime
    regUserId
    regDate
}
`;


export const LIST_USER_INFO = gql`
${USER_INFO_LIST_PART}
query ListUserInfo($custId:String) {
    userInfoList(custId:$custId) {
        ...UserInfoListPart
    }
}
`;

export const GET_USER_INFO = gql`
${USER_INFO_LIST_PART}
${USER_INFO_NO_LIST_PART}
query GetUserInfo($userId:String!) {
    userInfoItem(userId:$userId) {
        ...UserInfoListPart
        ...UserInfoNoListPart
    }
}
`;

export const GET_MY_INFO_N_ALARMS = gql`
query GetMyInfoAndAlarms($userId:String!) {
    userGetAlarmList(userId:$userId) {
        userId
        alarmTypeId
        alarmType
        timeFrom
        timeTill
        alarmInterval
        regUserId
        regDate
    }
    userInfoItem(userId:$userId) {
        userId
        custId
        userName
        custName
        classNo
        className
        email
        telno
        mobile
        userStateCd
        userStateValue
        canResetYn
        langCd
    }
}
`;

export const ADD_USER_INFO = gql`
mutation AddUserInfo($userInfo:InputUserInfoAdd!) {
  userInfoAdd(userInfo: $userInfo) {
    ok
    message
  }
}
`;

export const EDIT_USER_INFO = gql`
mutation EditUserInfo($userInfo:InputUserInfoEdit!) {
  userInfoEdit(userInfo: $userInfo) {
    ok
    message
  }
}
`;

export const REMOVE_USER_INFO = gql`
mutation RemoveUserInfo($userInfo:InputUserInfoRemove!) {
    userInfoRemove(userInfo: $userInfo) {
        ok
        message
    }
}
`;

export const SET_USER_INFO_STATE = gql`
mutation UserInfoSetState($userInfo:InputUserInfoSetState!) {
    userInfoSetState(userInfo: $userInfo) {
        ok
        message
    }
}
`;

export const CHANGE_USER_INFO_PASSWORD = gql`
mutation UserInfoChangePassword($userInfo:InputUserInfoChangePassword!) {
    userInfoChangePassword(userInfo: $userInfo) {
        ok
        message
    }
}
`;

export const SET_USER_INFO_MAP_VIEW_ZERO = gql`
mutation UserInfoSetMapViewZero($userInfo:InputUserInfoSetMapViewZero!) {
    userInfoSetMapViewZero(userInfo: $userInfo) {
        ok
        message
    }
}
`;
