// UserInfoAdmin.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import UserInfoList from './UserInfoList';
import UserInfoInput from './UserInfoInput';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    LIST_USER_INFO, GET_USER_INFO, ADD_USER_INFO, EDIT_USER_INFO, REMOVE_USER_INFO,
    UserInfoDictionary
} from './UserInfoGql';
import { LOG_IN_AS } from '../session/SessionGql';
import { AppObject, AppNumber, CvoMsg, AppWord } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';
import Util from '../../model/Util';
import UserSessionInfo from '../common/UserSessionInfo';

const UserInfoAdminContainer = styled(Box)({flexGrow:1});

const ErrorTitle = UserInfoDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(UserInfoDictionary.inputMap, true);


/**
 * 사용자 정보 컨트롤러.
 */
export default function UserInfoAdmin({
    maxWidth,
    maxHeight,
    custInfo,
}) {
	const [userInfoSelected, setUserInfoSelected] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [promptRemove, setPromptRemove] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const sessionInfo = useReactiveVar(userInfoRepo);
    const clientSize = useClientSize();

	const isAdmin = ValueUtil.isEtrace(sessionInfo);

    // ##### Call GraphQL to get List #####
    const [getUserInfoList, responseList] = useLazyQuery(LIST_USER_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
        onError: (error) => {setResponseAlert({open:true, error: error, title: "사용자 목록 조회 오류"})}
    });
    const [getUserInfoItemToEdit, responseItemToEdit] = useLazyQuery(GET_USER_INFO,
        {
            ...AppObject.NoCachedFetch,
            onCompleted: (data, option) => {onCompleteGetItem(data, option)},
            onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
        });
    
    // ##### GraphQL Mutation ###
    const [addUserInfo, responseAdd] = useMutation( ADD_USER_INFO,
        {
            onCompleted: (data, option) => onCompleteAdd(data, option), 
            onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
        } );
    const [editUserInfo, responseEdit] = useMutation( EDIT_USER_INFO,
        {
            onCompleted: (data, option) => onCompleteEdit(data, option),
            onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
        } );
    const [removeUserInfo, responseRemove] = useMutation( REMOVE_USER_INFO,
        {
            onCompleted: (data, option) => onCompleteRemove(data, option),
            onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
        });
    const [logInAsUser, responseUserAs] = useLazyQuery(LOG_IN_AS, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteLoginAsUser(data, option)},
        onError: (error) => {setResponseAlert({open:true, error: error, title: "타 사용자로 가장하기 오류"})}
    });

    useEffect(() => {
        if(custInfo) getUserInfoList({variables:{custId:custInfo.custId}});
        else getUserInfoList();
    }, [custInfo, getUserInfoList]);


    const getUserInfoListOfCust = () => {
        if(custInfo) getUserInfoList({variables:{custId:custInfo.custId}});
        else getUserInfoList();
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(userInfoSelected && data.userInfoList) {
            for(const item of data.userInfoList) {
                if(item.userId===userInfoSelected.userId) {
                    setUserInfoSelected(item);
                    return;
                }
            }
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.userInfoAdd.ok) {
            setEditorState(null);
            getUserInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.userInfoAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.userInfoEdit.ok) {
            setEditorState(null);
            getUserInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.userInfoEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.userInfoRemove.ok) getUserInfoListOfCust();
        else setResponseAlert({open:true, resultData: data.userInfoRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.userInfoItem) {
            const item = {};
            for(const field of EditFields) item[field] = data.userInfoItem[field];
            setEditorState({item: item});
        }
    };

    // admin
    const onCompleteLoginAsUser = (data, option) => {
        if(data.loginAs) {
            if(data.loginAs.userAs.msg===CvoMsg.OK) {
                // userInfoRepo({...data.loginAs, signedIn: true, sessionChecked: true});
                userInfoRepo(new UserSessionInfo(true, true, data.loginAs));
                // inform AppMain.js
                Util.bubbleSnack("타 사용자로 로그인 성공: " + data.loginAs.userAs.userName);
            }
        }
    };

    // +++++++ UI callbacks ++++++++
    const onRequestNew = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestEdit = (item) => {
        if(responseEdit) responseEdit.reset();
        getUserInfoItemToEdit({variables: {userId: item.userId}});
        // setEditorState({item: item});
    };

    const onRequestAsUser = (user) => {
        if(!isAdmin) return;
        if(sessionInfo.user.classNo >= user.classNo) {
            Util.bubbleSnack("권한이 큰 사용자로 가장할 수 없습니다.");
            return;
        }
        if(user.custId===AppWord.ETRACE_CUST_ID) {
            Util.bubbleSnack("이트레이스 사용자 대상으로 허용되지 않는 기능입니다.");
            return;
        }
        logInAsUser({variables: {userId: user.userId}});
    };

    const onRequestRemove = (item) => {

        setPromptRemove({
            data: item,
            title: '사용자 정보 삭제',
            messages: [
                '사용자 ' + item.userName + '(' + item.userId + ')님을 삭제하시겠습니까?',
                '해당 사용자의 정보 및 부수하는 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptRemove(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{userInfo:{userId: data.userId, custId: data.custId}}};
                    removeUserInfo(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by UserInfoInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {userInfo: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editUserInfo(param);
        else addUserInfo(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };

    // Event bubbling ?
    // const customEvent = new CustomEvent('build', { detail: { name: 'primary' } });
    // document.dispatchEvent(customEvent);
    // At main:
    // document.addEventListener('build', function({ detail }) { const { name } = detail; ... }

    // ---------------------------- Result handling ----------------------------
    if(ValueUtil.hasAnyAuthError(
        responseList, responseAdd, responseEdit, responseRemove, responseUserAs
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptRemoveBox = () => {
        const prompt = promptRemove ? promptRemove : {};
        return (
            <ConfirmDialog
                open={Boolean(promptRemove)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <UserInfoInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
            :
            <UserInfoInput
                open={Boolean(editorState)}
                isEdit={false}
                custId={custInfo ? custInfo.custId : ''}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

    const height = maxHeight || clientSize.dataAreaHeight;
    const width = maxWidth || clientSize.dataAreaWidth;

    return (
        <UserInfoAdminContainer>
            <UserInfoList
                maxHeight={height}
                maxWidth={width - AppNumber.dataContainerPadding - 2}
                responseList={responseList}
                selected={userInfoSelected}
                onClickItem={setUserInfoSelected}
                onRequestAdd={onRequestNew}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
                onRequestAsIs={onRequestAsUser}
            />
            {renderEditor()}
            {renderPromptRemoveBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </UserInfoAdminContainer>
    )
}
