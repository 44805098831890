// BcdNoticeMoreList.js
import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button, IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo, NoUser} from '../../model/CvoModel';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlexyTable from '../common/flexytable/FlexyTable';
import HelpPop from '../common/HelpPop';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';

const BcdNoticeMoreListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'poiName', label: '지점', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custPoiCode', label: '지점코드', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'noticeTo', label: '통보대상', width: 200, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userIdCsv', label: '관제권한부여', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    // { id: 'noticeBy', label: Dict.noticeBy.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'cargo', label: '배송내역', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'onArrival', label: Dict.onArrival.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'onDepart', label: Dict.onDepart.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'timeArrival', label: Dict.timeArrival.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    //{ id: 'timeDepart', label: Dict.timeDepart.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    //{ id: 'regTime', label: Dict.regTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} }
];

export default function BcdNoticeMoreList({
    onRequestParse,
    onRequestRemove,
    records,
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [search, setSearch] = useState('');
    const fileInputRef = useRef(null);

	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);


    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        if(!isAdmin) return;
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            default: return;
        }
    };

    const handleFileRead = (event) => {
        const content = event.target.result;
        onRequestParse(content);
    };

    const onChooseFile = (event) => {
        if(event.target.files.length > 0) {
            const file = event.target.files[0];
    
            if (file) {
                const reader = new FileReader();
                reader.onload = handleFileRead;
                reader.readAsText(file);
            }
        }
    };

    return(
        <BcdNoticeMoreListContainer>
            <HeaderBox>
				{
					isAdmin
					?
					<ButtonBox>
                        <input
                            type="file"
                            accept=".csv"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={onChooseFile}
                        />
                        {
                            fileInputRef.current
                            ?
                            <Button
                                onClick={()=>{fileInputRef.current.value=''; fileInputRef.current.click()}}
                                variant='contained' color={AppPalette.PrimaryColor}
                            >CSV 파일로 일괄 업로드</Button>
                            : null
                        }
                        <HelpPop
                            title="도움말"
                            content={
                                <div style={{padding:10}}>
                                    <h5>방문지 및 알림 설정에 대하여</h5>
                                    <p>이 화면은 차량별로 방문지를 설정합니다. 방문지 설정의 목적은 다음과 같습니다.</p>
                                    <ul>
                                        <li>차량이 방문지에 도착할 때마다 통보대상으로 등록된 전화번호로 문자를 전송합니다.</li>
                                        <li>부차적으로 해당 지점과 사용자가 지점관계설정이 되어 있을 경우 사용자별 관제그룹 설정과
                                            관계없이 관제권한을 부여합니다.</li>
                                    </ul>
                                    <p>문자 통보를 위해서는 이트레이스와 문자전송에 관한 별도의 계약이 있어야 합니다. 계약이 없는 경우는
                                        설정을 해도 문자가 전송되지 않습니다.<br/>
                                        사용자와 지점간의 관계설정은 사용자 관리 메뉴에서 사용자를 선택한 후 지점관계설정 탭을 선택하여 설정할 수 있습니다.<br/>
                                        점포배송이나 주기적인 상품배송시 점주 등에게 도착을 통보하거나 한시적으로 관제권한을 부여하기 위해 사용합니다.</p>
                                    <h5>CSV 파일 형식</h5>
                                    <p>설정은 CSV파일 업로드를 통해서만 가능합니다. 일괄 업로드를 위한 CSV 파일의 각 행의 형식은 다음과 같습니다.</p>
                                    <p style={{color:'blue'}}>차량번호,지점코드,배송내역,연락처A,연락처B</p>
                                    <p>각 열의 내용:</p>
                                    <ol>
                                        <li>차량번호*: 시스템에 등록된 차량번호입니다.</li>
                                        <li>지점코드*: 지점등록 시 지점별로 설정한 코드값입니다.</li>
                                        <li>배송내역: 해당 지점과 관련된 배송내역으로 한글15자, 영문50자 이하입니다.</li>
                                        <li>연락처 A*: 문자를 받을 수 있는 전화번호입니다.</li>
                                        <li>연락처 B: 문자를 받을 수 있는 추가 전화번호입니다.</li>
                                    </ol>
                                    <p>별표(*) 표시된 열의 값은 반드시 입력해야 합니다.</p>
                                </div>
                            }
                        />
					</ButtonBox>
					: null
				}
				<FormControl variant='outlined' size='small'>
					<InputLabel htmlFor="search-BcdNoticeMore-list" style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id="search-BcdNoticeMore-list" onChange={onChangeSearch}
						value={search}
						disabled={records.length === 0}
						size='small'
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
            </HeaderBox>
            <FlexyTable
                name="bcd_notice_more_list"
                uniqueKey="dataKey"
                columns={TableColumns}
                records={records}
                search={search}
                tools={isAdmin ? [
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize='small' />}
                ] : []}
                onClickTool={onClickToolOnRow}
            />
        </BcdNoticeMoreListContainer>
    );
}

