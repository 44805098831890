// TermsStatAll.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo} from '../../model/CvoModel';
import ReplayIcon from '@mui/icons-material/Replay';
import ClearIcon from '@mui/icons-material/Clear';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType, AppPalette } from '../../model/AppConst';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { LIST_TERMS_STAT_ALL } from './TermsStatGql';
import Util from '../../model/Util';

const dcPad = AppNumber.dataContainerPadding;
const ContainerWrapper = styled(Box)({ position:"absolute", top:dcPad, bottom:dcPad, left:dcPad, right:dcPad, display:"flex" });

const TermsStatListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
	display:'flex', alignItems:'center', height:AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const HeaderControl = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});

const SumBox = styled(Box)({
    display:'inline-flex', border:AppPalette.BorderCCC, borderRadius:3
});
const SumItem = styled(Box)({ display:'inline-block', margin:1, textAlign:'center' });
const SumLabel = styled('div')({fontSize:'0.7rem', color:'#349', backgroundColor:'#eee', padding:'2px 15px 2px 15px', borderRadius:3});
const SumValue = styled(Box)({fontSize:'0.8rem', padding:'4px 15px 4px 15px', color:'#333'});

const TableColumns = [
	{ id: 'custId', label: '업체ID', width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
	{ id: 'custName', label: '업체', width: 140, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'allTerms', label: '가입', width: 80, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'usingTerm', label: '사용', width: 80, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'normalTerm', label: '정상가동', width: 80, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'normalOff', label: '정지단말기', width: 80, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    {
        id: 'workRate', label: '가동률', width: 80, show: true,
        option:{
            align: 'right',
            print: (r,i,v)=>{
                if(r.allTerms<1) return '';
                return Math.round((r.usingTerm * 1000 / r.allTerms)) / 10;
            }
        }
    },
    { id: 'workingTerm', label: '지금 보고 중(15분)', width: 120, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'notUsing', label: '미사용', width: 80, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'stoppedTerm', label: '일시정지', width: 80, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'waitTerm', label: '사용대기', width: 80, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    // { id: 'closedTerm', label: '___', width: 120, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'regTime', label: '기록시각', width: 180, show: true, option:{align: 'right', type: TableColumnType.DATETIME} }
];


export default function TermsStatAll() {
    const [search, setSearch] = useState('');
    const [records, setRecords] = useState([]);
    const [total, setTotal] = useState({});
    const [whenGot, setWhenGot] = useState(0);
    const [responseAlert, setResponseAlert] = useState(null);
    const sessionInfo = useReactiveVar(userInfoRepo);

	const isAdmin = ValueUtil.isEtrace(sessionInfo);

    const renderTotal = () => {
        return(
            <SumBox>
                <SumItem>
                    <SumLabel>전체</SumLabel>
                    <SumValue>{total.allTerms}</SumValue>
                </SumItem>
                <SumItem>
                    <SumLabel>사용중</SumLabel>
                    <SumValue>{total.usingTerm}</SumValue>
                </SumItem>
                <SumItem>
                    <SumLabel>정상가동</SumLabel>
                    <SumValue>{total.normalTerm}</SumValue>
                </SumItem>
                <SumItem>
                    <SumLabel>보고 중</SumLabel>
                    <SumValue>{total.workingTerm}</SumValue>
                </SumItem>
                <SumItem>
                    <SumLabel>정지단말기</SumLabel>
                    <SumValue style={{color:'red'}}>{total.normalOff}</SumValue>
                </SumItem>
                <SumItem>
                    <SumLabel>장애율</SumLabel>
                    <SumValue>{total.stopRate}</SumValue>
                </SumItem>
            </SumBox>
        );
    };

    // ##### Call GraphQL to get List #####
    const [getTermsStatListAll, responseList] = useLazyQuery(LIST_TERMS_STAT_ALL, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetListAll(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "통계조회 오류"})}
    });

    useEffect(()=>{
        getTermsStatListAll();
    }, [getTermsStatListAll]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetListAll = (data, clientOption) => {
        if(data.termsStatList) {
            setRecords(data.termsStatList);
            setWhenGot(new Date().getTime()/1000);
        }
        if(data.termsStatTotal) {
            const statTotal = {...data.termsStatTotal};
            statTotal.workRate = statTotal.allTerms > 0 ? Math.round((statTotal.usingTerm * 10000 / statTotal.allTerms)) / 100 : 0.0;
            statTotal.stopRate = Math.round((100.0 - statTotal.workRate)*100) / 100;
            setTotal(statTotal);
        }
    };

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickReload = () => {
        const now = new Date().getTime()/1000;
        const diff = now - whenGot;
        if(diff >= 15) {
            getTermsStatListAll();
        }
        else {
            Util.bubbleSnack(`${Math.round(15-diff)}초 후에 다시 시도해 주세요`);
        }
    };

    if(!isAdmin) return(<Box></Box>);
    
    return(
        <ContainerWrapper>
            <TermsStatListContainer>
                <HeaderBox>
                    <HeaderControl>
                        <FormControl variant='outlined' size={AppWord.SMALL}>
                            <InputLabel htmlFor="search-TermsStat-list" style={{backgroundColor:'white'}}>검색</InputLabel>
                            <OutlinedInput id="search-TermsStat-list" onChange={onChangeSearch}
                                value={search}
                                disabled={records.length === 0}
                                size={AppWord.SMALL}
                                endAdornment={
                                    Boolean(search) ?
                                    <InputAdornment position={AppWord.END}>
                                        <IconButton
                                        onClick={()=>{setSearch('')}}
                                        size={AppWord.SMALL}
                                        edge={AppWord.END}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                    :
                                    null
                                }
                            />
                        </FormControl>
                    </HeaderControl>
                    <Box>
                        {renderTotal()}
                        <IconButton size={AppWord.SMALL}
                            onClick={onClickReload}
                            color={AppPalette.PrimaryColor}
                        >
                            <ReplayIcon />
                        </IconButton>
                    </Box>
                </HeaderBox>
                <FlexyTable
                    name="terms_stat_all"
                    uniqueKey="custId"
                    columns={TableColumns}
                    records={records}
                    search={search}
                />
            </TermsStatListContainer>
        </ContainerWrapper>
    );
}
