// PosDownloadLog.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import FlexyTable from '../common/flexytable/FlexyTable';
import { AppNumber, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';

const PosReportContainer = styled(Box)({
    flexGrow:1, display:'flex', flexDirection: 'column'
});

const HeaderBox = styled(Box)({
    height: AppNumber.HeaderToolBoxHeight, display:'flex', alignItems:'center',
});

const TableColumns = [
    { id: 'manager', label:'취급자', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userName', label: '요청자', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'purpose', label: '목적', width: 240, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'svcUrl', label: '서비스', width: 200, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'ofCustName', label: '대상', width: 240, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'svcTime', label: '확인시각', width: 160, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'rangeFrom', label: '확인범위시작', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.DATE} },
    { id: 'rangeTo', label: '확인범위끝', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.DATE} },
];

export default function PosDownloadLog({records, menu, onRequestData}) {
    const [search, setSearch] = useState('');

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <PosReportContainer>
            <HeaderBox>
                <SaveIcon color={AppPalette.SuccessColor}/>
                <span style={{display:'inline-block', marginRight:10, color:'#ccc'}}>.....</span>
                {menu}
                <Button
                    variant={AppPalette.VariantContained}
                    onClick={onRequestData}
                    style={{marginLeft:10, marginRight: 20}}
                >
                    <RefreshIcon fontSize={AppWord.SMALL} /> {records.length===0 ? "이력 조회" : "다시 읽기"}
                </Button>
				<FormControl variant='outlined' size='small'>
					<InputLabel htmlFor="search-RouteDaily-list" style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id="search-RouteDaily-list" onChange={onChangeSearch}
						value={search}
						disabled={records.length === 0}
						size='small'
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
            </HeaderBox>
            <FlexyTable
                name="pos_download_log_list"
                uniqueKey="dataKey"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[]}
            />
        </PosReportContainer>
    );
}
