//ThermoChart.js
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export default function ThermoChart({
    data,
    min,
    max,
    thermoFlag, // {fore, center, aft}
    graphHeight,
    graphWidth,
}) {
    return (
        <LineChart
            height={graphHeight} width={graphWidth}
            data={data}
            margin={{top:5, right:40, left: 0, bottom: 5}}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis  type="number" domain={[min-1, max+1]}/>
            <Tooltip />
            <Legend />
            {thermoFlag.fore   ? <Line type="monotone" dataKey="전면온도" stroke="#ff0000" activeDot={{ r: 3 }} /> : null}
            {thermoFlag.center ? <Line type="monotone" dataKey="중면온도" stroke="#000000" activeDot={{ r: 3 }} /> : null}
            {thermoFlag.aft    ? <Line type="monotone" dataKey="후면온도" stroke="#0099ff" activeDot={{ r: 3 }} /> : null}
        </LineChart>
    );
}
