// CustInfo.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Button, IconButton, AppBar, Toolbar } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import useClientSize from '../hook/useClientSize';
import CustInfoList from './CustInfoList';
import CustInfoInput from './CustInfoInput';
import ResponseAlert from '../message/ResponseAlert';
import ConfirmDialog from '../message/ConfirmDialog';
import CustGeneralData from './CustGeneralData';
import UserInfoAdmin from '../user_info/UserInfoAdmin';
import CustPeople from '../cust_people/CustPeople';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    LIST_CUST_INFO, GET_CUST_INFO, ADD_CUST_INFO, EDIT_CUST_INFO, SET_STATE_CUST_INFO, CustInfoDictionary
} from './CustInfoGql';
import { AppPalette, AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, custListRepo, NoUser } from '../../model/CvoModel';

const ErrorTitle =CustInfoDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(CustInfoDictionary.inputMap, true);

const MinCustListBoxWidth = 250;
const MinSubBoxWidth = 250;
const DeviderWidth = 4;
const DeviderRightMargin = 4;
const CustNameTitleHeight = 48;
const WorkBoxMargin = 10;
const UserTitleBoxHeight = 48;

const dcPad = AppNumber.dataContainerPadding;
const CustInfoContainer = styled(Box)({ position:"absolute", top:dcPad, bottom:dcPad, left:dcPad, right:dcPad, display:"flex" });
const CustListBox = styled(Box)({
    display:'inline-flex', //backgroundColor:'#eee',
    minWidth: MinCustListBoxWidth,
});

const SubBox = styled(Box)({
    display:'inline-flex', flexDirection: 'column',
    minWidth: MinSubBoxWidth,
    marginLeft: DeviderWidth + DeviderRightMargin,
    position: 'relative', // backgroundColor:'#f9f9f9'
});

const DeviderBox = styled(Box)({
    position: 'absolute',
    width: DeviderWidth,
    minHeight: 50,
    height: '100%',
    background: 'linear-gradient(to right, #aaaaaa, #eeeeee, #aaaaaa)',
    cursor: 'ew-resize',
});

const SubBoxHeader = styled(Box)({
    position:'relative'
});

const CustNameTitle = styled('div')({
    flexGrow:1, // display:'inline-block',
    fontSize: 'large',
    fontWeight: 'bold',
    //padding: 10,
    //borderBottom: '1px solid #cccccc',
    //height: CustNameTitleHeight - 21, // 21 = padding * 2 + border
});

const WorkBox = styled(Box)({
    flexGrow:1,
    border: '1px solid #cccccc',
    borderRadius: 5,
    margin: WorkBoxMargin,
    display:'flex', flexDirection:'column'
});

const WaitBox = styled(Box)({ flexGrow:1, display:'flex', alignItems:'center', justifyContent:'center' });

const CMenuKey = {General:'gen', User:'user', Pic:'pic'};
const CustMenu = [
    {id:CMenuKey.User, label:'사용자'},
    {id:CMenuKey.General, label:'관제정보'},
    {id:CMenuKey.Pic,  label:'담당자'},
];

export default function CustInfo() {
    const [custInfoSelected, setCustInfoSelected] = useState(null);
    const [custInfoList, setCustInfoList] = useState([]);
    const [openManager, setOpenManager] = useState(false); // 사용자, 담당자 관리 화면(우측)
    const [theMenu, setTheMenu] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();
    const [leftWidth, setLeftWidth] = useState(window.innerWidth * 0.35 < MinCustListBoxWidth ? MinCustListBoxWidth : window.innerWidth * 0.35);
	const sessionInfo = useReactiveVar(userInfoRepo);

	const isAdmin = ValueUtil.isEtrace(sessionInfo);

    // ##### Call GraphQL to get List #####
    const [getCustInfoList, responseList] = useLazyQuery(LIST_CUST_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
        onError: (error) => {setResponseAlert({open:true, error: error, title: '고객목록 조회 오류'})}
    });
    const [getCustInfoItemToEdit, responseItemToEdit] = useLazyQuery(GET_CUST_INFO, {
            ...AppObject.NoCachedFetch,
            onCompleted: (data, option) => {onCompleteGetItem(data, option)},
            onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
        });
    
    // ##### GraphQL Mutation.  ###
    const [addCustInfo, responseAdd] = useMutation( ADD_CUST_INFO, {
            onCompleted: (data, option) => onCompleteAdd(data, option), 
            onError: (error) => { console.log("We got error"); setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
        } );
    const [editCustInfo, responseEdit] = useMutation( EDIT_CUST_INFO, {
            onCompleted: (data, option) => onCompleteEdit(data, option),
            onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
        } );
    const [setStateCustInfo, responseSetState] = useMutation( SET_STATE_CUST_INFO, {
            onCompleted: (data, option) => onCompleteSetState(data, option),
            onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.SetState})}
        });

    useEffect(()=>{
        if(!theMenu && custInfoSelected) setTheMenu(CMenuKey.User);
    }, [theMenu, custInfoSelected]);

    useEffect(() => {
        getCustInfoList();
    }, []);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.custInfoList) {
            setCustInfoList(data.custInfoList);
            custListRepo(data.custInfoList); // save in model.

            if(custInfoSelected) {
                for(const item of data.custInfoList) {
                    if(item.custId===custInfoSelected.custId) {
                        setCustInfoSelected(item);
                        return;
                    }
                }
            }
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.custInfoAdd.ok) {
            setEditorState(null);
            getCustInfoList();
        }
        else setResponseAlert({open:true, resultData: data.custInfoAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.custInfoEdit.ok) {
            setEditorState(null);
            getCustInfoList();
        }
        else setResponseAlert({open:true, resultData: data.custInfoEdit, title: ErrorTitle.Edit});
    };

    const onCompleteSetState = (data, clientOption) => {
        if(data.custInfoSetState.ok) {getCustInfoList();}
        else setResponseAlert({open:true, resultData: data.custInfoSetState, title: ErrorTitle.SetState});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.custInfoItem) {
            const item = {};
            for(const field of EditFields) item[field] = data.custInfoItem[field];
            setEditorState({item: item});
        }
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestEdit = (item) => {
        if(responseEdit) responseEdit.reset();
        getCustInfoItemToEdit({variables: {custId: item.custId}});
        // setEditorState({item: item});
    };

    const onRequestSetState = (data) => {
        if(responseSetState) responseSetState.reset();
        setStateCustInfo({variables: {custInfo: data}});
    };

    // Handler - Submit for mutation fired by CustInfoInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {custInfo: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editCustInfo(param);
        else addCustInfo(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };

    const onClickStopCust = (custInfo) => {
        if(custInfo) {
            setPromptToConfirm({
                data: {custId: custInfo.custId, custStateCd: CustInfoDictionary.custState.STOPPED},
                title: '고객 이용자격 일시정지',
                messages: ['고객 ' + custInfo.custName + '의 이용자격이 일시정지됩니다.', '계속하시겠습니까?'],
                callback: (data) => {
                    setPromptToConfirm(null);
                    if(data) onRequestSetState(data);
                }
            });
        }
    };

    const onClickRestoreCust = (custInfo) => {
        console.log('onClickRestoreCust', custInfo);
        if(custInfo) {
            setPromptToConfirm({
                data: {custId: custInfo.custId, custStateCd: CustInfoDictionary.custState.NORMAL},
                title: '고객 이용자격 회복',
                messages: ['고객 ' + custInfo.custName + '의 이용자격이 정상상태로 회복됩니다.', '계속하시겠습니까?'],
                callback: (data) => {
                    setPromptToConfirm(null);
                    if(data) onRequestSetState(data);
                }
            });
        }
    };

    const onClickGoodbyeCust = (custInfo) => {
        if(custInfo) {
            setPromptToConfirm({
                data: {custId: custInfo.custId, custStateCd: CustInfoDictionary.custState.LEFT},
                title: '고객 이용 해지',
                messages: ['고객 ' + custInfo.custName + '의 이용자격을 해지합니다.', '계속하시겠습니까?'],
                callback: (data) => {
                    setPromptToConfirm(null);
                    if(data) onRequestSetState(data);
                }
            });
        }
    }; // 해지

    // <<<<<<<<<<<<<<<<<<<<<<<<<<< Devider >>>>>>>>>>>>>>>>>>>>>>>>>
    const onMouseMoveDevider = (event) => {
        if (event.buttons === 1) {
            const w = event.clientX - clientSize.dataContainerPadding;
            setLeftWidth(w < MinCustListBoxWidth ? MinCustListBoxWidth : w);
            event.stopPropagation();
        }
    };

    const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMoveDevider);
        document.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDown = (event) => {
        document.addEventListener('mousemove', onMouseMoveDevider);
        document.addEventListener('mouseup', onMouseUp);
        event.preventDefault();
        event.stopPropagation();
    };

    const ContentHeight = clientSize.dataAreaHeight - dcPad * 2; // - clientSize.SmallBoxPadding * 2 - 2;
    const SubBoxWidth = clientSize.dataAreaWidth - leftWidth - DeviderWidth - DeviderRightMargin;

    if(ValueUtil.hasAnyAuthError(
        responseItemToEdit,
        responseAdd,
        responseList,
        responseEdit
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptRemoveBox = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <CustInfoInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
            :
            <CustInfoInput
                open={Boolean(editorState)}
                isEdit={false}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

    const renderByTheMenu = () => {
        if(theMenu===CMenuKey.User) {
            const UserBoxHeight = ContentHeight - CustNameTitleHeight - WorkBoxMargin - 2; // -2 for border
            const UserBoxWidth = SubBoxWidth - WorkBoxMargin * 2 - 2;
            return(
                <WorkBox>
                    <UserInfoAdmin maxHeight={UserBoxHeight - UserTitleBoxHeight} maxWidth={UserBoxWidth}
                        custInfo={custInfoSelected} />
                </WorkBox>
            );
        }
        else if(theMenu===CMenuKey.General) return <WorkBox><CustGeneralData custInfo={custInfoSelected} /></WorkBox>;
        else if(theMenu===CMenuKey.Pic) return <WorkBox><CustPeople custInfo={custInfoSelected} /></WorkBox>;
        else {
            return( <WorkBox><WaitBox><div style={{display:'inline-block'}}>잠시만 기다려 주세요.</div></WaitBox></WorkBox> );
        }
    };

    if(!isAdmin) return <CustInfoContainer>&nbsp;</CustInfoContainer>;

    // 업체를 선택하면 우측에 사용자 박스를, 아니면 업체목록 단독으로 표시.
    if(custInfoSelected && openManager) {
        return (
            <CustInfoContainer aria-label='cust-info-list-and-subbox'>
                <CustListBox width={leftWidth}>
                    <CustInfoList
                        records={custInfoList}
                        selected={custInfoSelected}
                        onClickItem={setCustInfoSelected}
                        onRequestAdd={onRequestAdd}
                        onRequestEdit={onRequestEdit}
                        onRequestStopCust={onClickStopCust}
                        onRequestGoodbyeCust={onClickGoodbyeCust}
                        onRequestRestoreCust={onClickRestoreCust}
                    />
                </CustListBox>
                <SubBox width={SubBoxWidth} height={ContentHeight}>
                    <SubBoxHeader>
                        <AppBar component="nav" position='static'>
                            <Toolbar>
                                <CustNameTitle>
                                    {custInfoSelected.custName}({custInfoSelected.custStateValue})
                                </CustNameTitle>
                                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    {
                                        CustMenu.map((menu)=>{
                                            return(
                                                <Button key={menu.id}
                                                    sx={{
                                                        color: theMenu===menu.id ? 'yellow' : '#fff',
                                                        fontWeight: theMenu===menu.id ? 'bold' : 'normal'
                                                    }}
                                                    onClick={()=>{setTheMenu(menu.id)}}
                                                >
                                                    {menu.label}
                                                </Button>
                                            );
                                        })
                                    }
                                    <span>|</span>
                                    <IconButton onClick={()=>setOpenManager(false)}
                                        color={AppPalette.InheritColor}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </Box>

                            </Toolbar>
                        </AppBar>

                    </SubBoxHeader>
                    {renderByTheMenu()}
                </SubBox>
                <DeviderBox onMouseDown={onMouseDown}
                    style={{left:leftWidth, top:0}}
                    />
                {renderEditor()}
                {renderPromptRemoveBox()}
                <ResponseAlert open={responseAlert ? responseAlert.open : false}
                    alertData={responseAlert}
                    onClose={() => {setResponseAlert(null)}}/>
            </CustInfoContainer>
        );
    }
    else {
        return(
            <CustInfoContainer aria-label='cust-info-list-only'>
                <CustInfoList
                    maxWidth={clientSize.dataAreaWidth - dcPad * 2}
                    maxHeight={ContentHeight}
                    records={custInfoList}
                    selected={custInfoSelected}
                    onClickItem={setCustInfoSelected}
                    showAddButton={true}
                    onRequestAdd={onRequestAdd}
                    onRequestEdit={onRequestEdit}
                    onRequestStopCust={onClickStopCust}
                    onRequestGoodbyeCust={onClickGoodbyeCust}
                    onRequestRestoreCust={onClickRestoreCust}
                    onRequestManage={setOpenManager}
                />
                {renderEditor()}
                {renderPromptRemoveBox()}
                <ResponseAlert open={responseAlert ? responseAlert.open : false}
                    alertData={responseAlert}
                    onClose={() => {setResponseAlert(null)}}/>
            </CustInfoContainer>
        );
    }
}
