// MenuBox.js
import React, {useState} from 'react';
import { styled  } from '@mui/material/styles';
import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/system';
import { Link } from '@mui/material';
import Util from '../../model/Util';
import { menuTreeRepo } from '../../model/CvoModel';
import useClientSize from '../hook/useClientSize';

const MenuTable = styled(Box)({
    display:"table",
    borderRadius: 10,
    // zIndex:99999,
    //borderBottomLeftRadius: 10,
    //borderBottomRightRadius: 10,
});

const MenuRow = styled(Box)({display:"table-row"});
const MenuColumn = styled(Box)({
    display:"table-cell",
    fontSize: "small",
    minWidth: 140,
    paddingBottom: 3,
    paddingTop: 10,
    paddingLeft: 7,
    paddingRight: 7,
    textAlign: "left",
});
const MenuItemsColumn = styled(Box)`
    display: table-cell;
    font-size: small;
    min-width: 140px;
    padding: 10px 2px 3px 2px;
    overflow: auto;

  :hover {
    background-color: #d3dddd;
  }

  :first-of-type {border-bottom-left-radius: 10px;}
  :last-of-type {border-bottom-right-radius: 10px;}
`;

const MenuKindBox = styled(Box)({
    color:"yellow",
    backgroundColor: "#30135a",
})
const MenuItemBox = styled(Box)({
    fontSize:"10pt",
    textAlign: "left",
    height: 25,
    padding: "8px 0px 2px 5px",
    borderRadius:3,
    "&:hover": {backgroundColor: "#e3eddd"}
});


// backgroundColor:"#eeeeee",

export default function MenuBox({itemMode, onMenuSelected}) {
    const [helpDone, setHelpDone] = useState(false);
    const menuTree = useReactiveVar(menuTreeRepo);
    const clientSize = useClientSize();

    const onMouseEnterFirstTime = (event) => {
        if(!helpDone && !itemMode) {
            Util.bubbleSnack("메뉴바를 눌러 상세 메뉴를 열 수 있습니다.", {vertical:'top'});
            setHelpDone(true);
        }
    };

    const renderMenus = (menus) => {
        return(
            <>
            {
                menus.map((menu)=>{
                    return(
                        <MenuItemBox key={menu.menuId}>
                            <Link style={{color:"black",cursor:"pointer"}}
                                underline='none'
                                onClick={()=>{onMenuSelected(menu)}}>
                                {menu.menuName}
                            </Link>
                        </MenuItemBox>
                    );
                })
            }
            </>
        );
    };

    return (
        <MenuTable>
            <MenuRow
                onMouseEnter={onMouseEnterFirstTime}
            >
                {
                    menuTree.map((kind) => {
                        return(
                            <MenuColumn key={kind.kindId}>
                                {
                                    itemMode
                                    ?
                                    <MenuKindBox>{kind.kindName}</MenuKindBox>
                                    :
                                    kind.kindName
                                }
                            </MenuColumn>
                        );
                    })
                }
            </MenuRow>
            {
                itemMode
                ?
                <MenuRow style={{backgroundColor: "#cccccc", paddingTop:10}}>
                    {
                        menuTree.map((kind) => {
                            return(
                                <MenuItemsColumn
                                    key={kind.kindId}
                                    style={{
                                        paddingBottom: 30,
                                        maxHeight: clientSize.dataAreaHeight - 60
                                    }}>
                                    {renderMenus(kind.menus)}
                                </MenuItemsColumn>
                            );
                        })
                    }
                </MenuRow>
                :
                null
            }
        </MenuTable>
    )
}
