// VehTonNode.js

export default class VehTonNode {
    custId = null;
    vehTonId = 0;
    vehTypeId = 0;
    vehTypeName = null;
    ton = null;
    vehTypeTon = null;
    tonDesc = null;
    operFontColor = null;
    operBackColor = null;
    noOperFontColor = null;
    noOperBackColor = null;
    emerFontColor = null;
    emerBackColor = null;
    offFontColor = null;
    offBackColor = null;
    regUserId = null;
    regDate = null;


    boolRoot = null; // 이 노드가 루트인가?
    boolChecked = false;
    // children = [];

    constructor() {
        this.boolRoot = true;
    }

    setFromObject = (data) => {
        this.vehTonId = data.vehTonId;
        this.custId = data.custId;

        this.vehTypeId = data.vehTypeId;
        this.vehTypeName = data.vehTypeName;
        this.ton = data.ton;
        this.vehTypeTon = data.vehTypeTon;
        this.tonDesc = data.tonDesc;
        this.operFontColor = data.operFontColor;
        this.operBackColor = data.operBackColor;
        this.noOperFontColor = data.noOperFontColor;
        this.noOperBackColor = data.noOperBackColor;
        this.emerFontColor = data.emerFontColor;
        this.emerBackColor = data.emerBackColor;
        this.offFontColor = data.offFontColor;
        this.offBackColor = data.offBackColor;
        this.regUserId = data.regUserId;
        this.regDate = data.regDate;

        this.boolRoot = false;
        if(data.__checked) this.boolChecked = true;
    }

    equalTo = (node) => {
        if(node) return node.getPrimaryData() === this.getPrimaryData();
        return false;
    };
    getPrimaryData = () => {return this.vehTonId;};
    getLabel = () => {return this.ton;};
    hasParent = () => true;
    getParentNodeId = () => {return this.vehTypeId;};
    isRoot = () => false;
    isChecked = () => {return this.boolChecked;};
    checkIfSame = (tonId) => {
        this.boolChecked = this.vehTonId === tonId;
        return this.boolChecked;
    };
    // check = () => {this.boolChecked = true; return true;};
    // uncheck = () => {this.boolChecked = false;};
    toggle = () => {this.boolChecked = !this.boolChecked;};
    hasChildren = () => false;
    uncheckAll = () => {
        this.boolChecked = false;
    };

}
