// SessionGql.js
import {gql} from '@apollo/client';

const SESSION_DATA = gql`
fragment WholeSessionData on SessionData {
    user {
      userId
      password
      userName
      custId
      custName
      industryId
      classId
      classNo
      admin
      etrace
      loginTime
      logoutTime
      secondAuthMsg
      viewLevel
      initXcoord
      initYcoord
      initLat
      initLon
      smsUseFlag
      poiByGroupCd
      option
      msg
    }
    userAs {
      userId
      password
      userName
      custId
      custName
      industryId
      classId
      classNo
      admin
      etrace
      loginTime
      logoutTime
      secondAuthMsg
      viewLevel
      initXcoord
      initYcoord
      initLat
      initLon
      smsUseFlag
      poiByGroupCd
      option
      msg
    }
}
`;

export const LOG_IN = gql`
${SESSION_DATA}
query logIn($userId:String!, $password:String!) {
  login(userId:$userId, password:$password) {
    ...WholeSessionData
  }
}
`;

export const LOG_IN_AS = gql`
${SESSION_DATA}
query LoginAs($userId:String!) {
    loginAs(userId:$userId) {
        ...WholeSessionData
    }
}
`;

export const LOG_OUT = gql`
${SESSION_DATA}
query logOut {
  logout {
    ...WholeSessionData
  }
}
`;

export const GET_SESSION_DATA = gql`
${SESSION_DATA}
query {
  getSessionData {
    ...WholeSessionData
  }
}
`;

export const GET_CODE_N_MENU = gql`
query {
    codeTree {
        cdType
        cdTypeName
        codes {
            cd
            cdOld
            cdName
            cdType
            cdOrder
            text1
            text2
            text3
            text4
            intVal1
            intVal2
            intVal3
            cdDesc
        }
    }
    menuToUserTree {
        kindId
        kindName
        kindOrder
        kindDesc
        menus {
            menuId
            menuName
            userId
            myName
            bookMark
            menuOrder
            regTime
        }
    }
    userTcolConf21List {
        userId
        viewName
        colsCsv
        regDate
    }
}
`;
