// CvoModel.js
// 공통으로 사용하는 데이터.

import { makeVar } from "@apollo/client";
import { AppObject } from "./AppConst";
import UserSessionInfo from "../com/common/UserSessionInfo";

export const NoUser = new UserSessionInfo(true, false, {});
const NoSessionCheckd = new UserSessionInfo(false, false, {}); // {...NoUser, sessionChecked: false};
export const custListRepo = makeVar([]); // for admin only.
export const userInfoRepo = makeVar(NoSessionCheckd);
export const codeTreeRepo = makeVar([]);
export const currentMenuRepo = makeVar(AppObject.DefaultMenu);
export const menuTreeRepo = makeVar([]);
export const appAlertRepo = makeVar([]);

// 한시적 설정.
export const showRouteMonthlyActiveOnlyRepo = makeVar(false); // true === totalDist > 0 인 것만 보기.

// for FlexyTable
export const tableShapeConfRepo = makeVar({}); // veh_pos: {columns:[{id,width}], saved:true}
export const openTableShapeConfRepo = makeVar(false);

// for map
export const vehPosRepo = makeVar({when:0, records:[]}); // 수신한 모든 차량위치.
export const vehPosFilteredRepo = makeVar([]); // for map to display
export const vehPosFilterCriteriaRepo = makeVar(null);
export const vehPosSearchRepo = makeVar('');
export const poiTypeRepo = makeVar([]); // for map
export const poiInfoRepo = makeVar([]); // for map
export const sweeperStatRepo = makeVar([]); // for map. 탭 선택할 때마다 다시 가져오지 않도록.
export const sweeperSelectedRepo = makeVar(null);
export const sweeperRouteRepo = makeVar([]); // global. 탭 선택할 때마다 다시 가져오지 않도록.
export const overlapRepo = makeVar(null); // map shell to MapMain
export const poiPositionRepo = makeVar(null); // 안쓰는 듯. poi position 전달용.(map to MapMain to view)
//export const polygonRepo = makeVar([]); // 폴리곤 편집 상태를 지도에서 view로 전달.
export const poiEditCommandRepo = makeVar(null);
export const naverSearchResultRepo = makeVar([]); 
export const naverSearchRepo = makeVar('');
export const naverRouteRepo = makeVar([]); // 경로탐색 결과. 클래스 NaverRoute



/* const EmptyUser =  {
    userName:"", custId:"", custName:"", userId:"",
    industryId:0,
    classNo:99999,
    admin:false, etrace:false,
    loginTime:"", logoutTime:"",
    viewLevel:0, initXcoord:0, initYcoord:0,
    smsUseFlag:"N", poiByGroupCd:"NO", option:"",
    secondAuthMsg:"", msg:""
}; */
/* {
    sessionChecked: true,
    signedIn: false,
    user: {...EmptyUser},
    userAs: {...EmptyUser}
}; */