// ActLogListLogin.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo} from '../../model/CvoModel';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppPalette, AppWord } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { LoginTableColumns as TableColumns} from './ActLogGql';

const ActLogListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
    minHeight:AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const HeaderControl = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});

/**
 * 사용자의 로그인 기록만 조회. 개별 기록을 선택하여 각 세션의 활동 이력을 조회할 수 있는 상위 기능을 호출한다.
 */
export default function ActLogListLogin({
	records,
    onRequestView,
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [search, setSearch] = useState('');


    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_VIEW: onRequestView(row); break;
            default: return;
        }
    };


	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);
    if(!isAdmin) {
        return(
            <Box>Not allowed</Box>
        );
    }

    const SearchInputId = 'search-ActLog-list-sessions';
    return(
        <ActLogListContainer>
            <HeaderBox>
				<HeaderControl>
					<FormControl variant='outlined' size={AppWord.SMALL}>
						<InputLabel htmlFor={SearchInputId} style={{backgroundColor:'white'}}>검색</InputLabel>
						<OutlinedInput id={SearchInputId} onChange={onChangeSearch}
							value={search}
							disabled={records.length === 0}
							size={AppWord.SMALL}
							endAdornment={
								Boolean(search) ?
								<InputAdornment position='end'>
									<IconButton
									onClick={()=>{setSearch('')}}
									size={AppWord.SMALL}
									edge="end"
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
								:
								null
							}
						/>
					</FormControl>
				</HeaderControl>
            </HeaderBox>
            <FlexyTable
                name="act_log_list"
                uniqueKey="logNid"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[
                    {id:AppWord.CMD_VIEW, icon:<ManageSearchOutlinedIcon fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor} />}
                ]}
                onClickTool={onClickToolOnRow}
            />
        </ActLogListContainer>
    );

}
