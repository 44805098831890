// CvoFileInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import RadioSelector from '../common/RadioSelector';
import { AppPalette, CvoCodes, AppWord } from '../../model/AppConst';
import { CvoFileDictionary } from './CvoFileGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ValueUtil from '../../model/ValueUtil';
import { useReactiveVar } from '@apollo/client';
import { userInfoRepo } from '../../model/CvoModel';

const ColumnBoxWidth = 600;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({ display: 'block', paddingTop: 10, paddingBottom: 10, });

const HintBox = styled(Box)({
    display: 'block', marginTop: 10, marginBottom: 10, padding:12, border: AppPalette.BorderCCC, borderRadius:5
});

const InputMap = CvoFileDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = {forWhom:'all', fileSize:1}; // ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.

const SizeUnit = [
    {value:1, label:'바이트'},
    {value:1024, label:'KB'},
    {value:1024*1024, label:'MB'},
    {value:1024*1024*1024, label:'GB'},
];

export default function CvoFileInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
    const [sizeBy, setSizeBy] = useState(1);
    const [sizeUnit, setSizeUnit] = useState(1024);
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.
	const sessionInfo = useReactiveVar(userInfoRepo);

	const isAdmin = ValueUtil.isEtrace(sessionInfo);

    useEffect(()=>{
        if(open) {
            if(item) {
                const d = {...item};
                d.url = d.filePath;
                for(let i=SizeUnit.length-1; i>=0; i--) {
                    if(d.fileSize >= SizeUnit[i].value) {
                        setSizeUnit(SizeUnit[i].value);
                        setSizeBy(Math.floor(d.fileSize / SizeUnit[i].value));
                        break;
                    }
                }

                setInputData(d);
            }
            else setInputData(defaultInputData);
        }
        else setInputData(defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
    }, [item, open]);

	const resetData = (data) => {
        setSizeBy(1);
        setSizeUnit(1024);
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // add extra data if necessary.
        if(isEdit) param.fileNid = item.fileNid;
        param.forWhom = isAdmin ? 'all' : '';

        onClickMutate(param, isEdit);
    };
    //   https://drive.google.com/file/d/10ormVFH8DZ1p9J7ndLp90bmyBSi8Un-f/view?usp=drive_link

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '파일 정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput2(event, InputMap, inputData, inputError, isEdit);
        
        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeSizeBy = (event) => {
        const data = {...inputData};
        data.fileSize = event.target.value * sizeUnit;
        setInputData(data);
        setSizeBy(event.target.value);
    };

    const onChangeRadioSelector = (event) => {
        const data = {...inputData};
        data.fileSize = event.target.value * sizeBy;
        setInputData(data);
        setSizeUnit(event.target.value);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange, full) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size={AppWord.SMALL} fullWidth
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value || ''}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>
                {isEdit ? '파일 정보 수정' : '파일 정보 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                            {renderTextFieldBox(InputMap.memo, inputData.memo,
								inputError.memo, onChangeTextData) /* _____ */}
                            {renderTextFieldBox(InputMap.url, inputData.url,
								inputError.url, onChangeTextData, true) /* _____ */}
                            {
                                isAdmin
                                ?
                                <HintBox style={{color:'#333', fontSize:'0.9em'}}>
                                    <a href="https://drive.google.com/drive/folders/0AAoobJCRofVeUk9PVA"
                                        target="_blank" rel="noopener noreferrer"
                                    >Google 공유 Drive</a>에 파일을 올려 놓고 
                                    그 파일에 우클릭한 후
                                    '공유' - '공유' - [일반 엑세스]에서 "링크가 있는 모든 사용자"를
                                    선택하면 누구든 다운로드 받을 수 있게 됩니다.
                                </HintBox>
                                : null
                            }
                            {/*renderTextFieldBox(InputMap.forWhom, inputData.forWhom,
								inputError.forWhom, onChangeTextData)  _____ */}
                            <FieldBox>
                                <TextField id="sizeBy" size={AppWord.SMALL}
                                    type={InputMap.fileSize.type}
                                    label={InputMap.fileSize.label}
                                    required={InputMap.fileSize.required}
                                    value={sizeBy || ''}
                                    helperText={InputMap.fileSize.help}
                                    onChange={onChangeSizeBy}
                                    style={{marginRight:10, width:160}}
                                />

                                <RadioSelector
                                    id="sizeUnit"
                                    selectFrom={SizeUnit}
                                    initialValue={sizeUnit}
                                    onChange={onChangeRadioSelector}
                                    horizontal={true}
                                />
    
                            </FieldBox>
                            {/* renderTextFieldBox(InputMap.conType, inputData.conType,
								inputError.conType, onChangeTextData) _____ */}
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}
