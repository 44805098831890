// UserInfo.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { AppBar, Tabs, Tab } from '@mui/material';
import UserInfoList from './UserInfoList';
import UserInfoInput from './UserInfoInput';
import MenuUser from '../menu/MenuUser';
import VehGroupForUser from '../veh_group/VehGroupForUser';
import UserGetAlarmList from '../user_get_alarm/UserGetAlarmList';
import UserPoiRelation from '../user_poi_relation/UserPoiRelation';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import HelpTopic from '../common/HelpTopic';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_USER_INFO, GET_USER_INFO, ADD_USER_INFO, EDIT_USER_INFO, REMOVE_USER_INFO,
	UserInfoDictionary
} from './UserInfoGql';
import { AppWord, AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const UserListBoxWidth = 420;
const dcPad = AppNumber.dataContainerPadding;
const UserInfoContainer = styled(Box)({ position:"absolute", top:dcPad, bottom:dcPad, left:dcPad, right:dcPad, display:"flex" });
const UserListBox = styled(Box)({
    width:UserListBoxWidth, marginRight:5
});
const WorkBox = styled(Box)({flexGrow:1, display:'flex', flexDirection:'column'}); // contains WorkHeader, AppBar Box, WorkView
const WorkHeader = styled(Box)({height:AppNumber.HeaderToolBoxHeight, display:'flex', justifyContent:'center', alignItems:'center'});
const NameTitle = styled(Box)({flex:1, fontWeight:'bold', color:'#777'});
const WorkView = styled(Box)({
    flexGrow:1, //backgroundColor:'#bff',
    position:'relative',
});
const WorkInnerWrapper = styled(Box)({
    position: 'absolute',
    left:0, top:0, right:0, bottom:0, //  paddingLeft: 10, paddingTop: 10
});
const DataTitle = styled('dt')({fontWeight:'bold', marginTop:5});
const GuideBox = styled(Box)({height:'100%', overflow:'auto'});

const ErrorTitle =UserInfoDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(UserInfoDictionary.inputMap, true);

const UserMenus = {Menu:1, Group:2, Alarm:3, PoiRel:4, PoiForMap:5};

export default function UserInfo() {
	const [userInfoSelected, setUserInfoSelected] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const [work, setWork] = useState(UserMenus.Menu);
    // const responseList = useQuery(GET_USER_INFO_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});
    //const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getUserInfoList, responseList] = useLazyQuery(LIST_USER_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getUserInfoItemToEdit, responseItemToEdit] = useLazyQuery(GET_USER_INFO, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation.  ###
    const [addUserInfo, responseAdd] = useMutation( ADD_USER_INFO, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editUserInfo, responseEdit] = useMutation( EDIT_USER_INFO, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeUserInfo, responseRemove] = useMutation( REMOVE_USER_INFO, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getUserInfoList();
    }, [getUserInfoList]);


    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(userInfoSelected && data.userInfoList) {
            for(const item of data.userInfoList) {
                if(item.userId===userInfoSelected.userId) {
                    setUserInfoSelected(item);
                    return;
                }
            }
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.userInfoAdd.ok) {
            setEditorState(null);
            getUserInfoList(); // getUserInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.userInfoAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.userInfoEdit.ok) {
            setEditorState(null);
            getUserInfoList(); // getUserInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.userInfoEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.userInfoRemove.ok) getUserInfoList();
        else setResponseAlert({open:true, resultData: data.userInfoRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.userInfoItem) {
            const item = {};
            for(const field of EditFields) item[field] = data.userInfoItem[field];
            setEditorState({item: item});
        }
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestEdit = (item) => {
        if(responseEdit) responseEdit.reset();
        getUserInfoItemToEdit({variables: {userId: item.userId}});
    };

    const onRequestRemove = (item) => {

        setPromptToConfirm({
            data: item,
            title: '사용자 정보 삭제',
            messages: [
                '사용자 ' + item.userName + '(' + item.userId + ')님을 삭제하시겠습니까?',
                '해당 사용자의 정보 및 부수하는 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{userInfo:{userId: data.userId}}};
                    removeUserInfo(param);
                }
            }
        });
    };

    const onClickUser = (user) => {
        setUserInfoSelected(user);
    };

    // Handler - Submit for mutation fired by UserInfoInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {userInfo: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editUserInfo(param);
        else addUserInfo(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList, responseAdd, responseItemToEdit,
        responseEdit, responseRemove
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <UserInfoInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
            :
            <UserInfoInput
                open={Boolean(editorState)}
                isEdit={false}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

    const GuideWhenNoUser = () => {
        return(
            <GuideBox>
                <HelpTopic
                    title="사용자를 선택한 후 다음과 같은 작업을 할 수 있습니다."
                    content={
                        <div>
                            <dl>
                                <DataTitle>메뉴설정</DataTitle>
                                <dd>각 사용자별로 사용할 수 있는 메뉴를 선택합니다. 메뉴설정은 특정 사용자가 특정 메뉴를 사용할 수 없도록 제한하는
                                    측면도 있으나, 불필요한 메뉴를 제한함으로써 중요한 업무에 집중하게 하는 의미가 더욱 큽니다.
                                </dd>
                                <DataTitle>관제그룹설정</DataTitle>
                                <dd>
                                    <p>관리자가 아닌 일반 사용자는 특정한 차량그룹에 대해서만 관제가 가능합니다. 관제가능한 그룹을 선택하여 위치목록, 지도화면
                                    등에서 꼭 필요한 차량만 볼 수 있게 해 주세요.
                                    다른 업체로부터 협력을 받는 경우 타 업체의 협력그룹이 별도로 나타날 수 있습니다. 이에 대해서도 협력받은 타사 차량그룹을
                                    선택적으로 관제할 수 있는 설정이 가능합니다.</p>
                                    <p>타사의 협력을 받지 않는 경우에는 협력그룹에 대한 '비어있는 목록' 자체가 나타나지 않습니다.</p>
                                </dd>
                                <DataTitle>알람설정</DataTitle>
                                <dd>사용자별로 어떤 종류의 알람을, 몇분 간격으로, 어떤 시간에 받을 수 있는지 설정합니다. 모든 업체가 이 설정이 필요한 것은 아니며,
                                    설정을 하더라도 문자전송과 관련된 계약이 없으면 알람은 전송되지 않습니다.</dd>
                                <DataTitle>지점관계설정</DataTitle>
                                <dd>특정사용자와 지점과의 관계를 설정하여 그 지점에 방문 예정(별도 설정)일 경우 한시적으로 관제권한이 주어지게 하는 기능입니다.
                                    예를 들어 해당 지점의 관계자, 혹은 (점포라면) 점장을 사용자로 등록하고 지점관계를 설정하면 배차가 이루어진 날에만 해당 차량을
                                    지도에서 확인할 수 있을 것입니다.
                                </dd>
                                <DataTitle>지도표시지점설정</DataTitle>
                                <dd>지점이 많은 경우, 지도에 표시되는 지점을 사용자별로 표시해 줌으로써 불필요한 지점 출력을 방지하고 쾌적한 지도이용을
                                    이끌어낼 수 있습니다. 단, 사용자별로 설정된 지점이 하나라도 있으면 다른 지점들은 나타나지 않으니 주의가 필요합니다.
                                </dd>
                            </dl>
                        </div>
                    }
                />
            </GuideBox>
        );
    };

    const renderByTabMenu = () => {
        if(!userInfoSelected) return GuideWhenNoUser();
        switch(work) {
            case UserMenus.Group: return <VehGroupForUser userInfo={userInfoSelected} />;
            case UserMenus.Menu: return <MenuUser userInfo={userInfoSelected} />
            case UserMenus.Alarm: return <UserGetAlarmList userInfo={userInfoSelected} />
            case UserMenus.PoiRel: return <UserPoiRelation userInfo={userInfoSelected} userPoiRel={AppWord.USER_POI_OPER_AUTH} />
            case UserMenus.PoiForMap: return <UserPoiRelation userInfo={userInfoSelected} userPoiRel={AppWord.USER_POI_VIEW_REL}/>
            default: return <Box><Box>Menu: {work}</Box><Box>Not implemented</Box></Box>
        }
    };

	const width = UserListBoxWidth;

    return (
        <UserInfoContainer>
            <UserListBox>
                <UserInfoList
                    maxWidth={width}
                    responseList={responseList}
                    selected={userInfoSelected}
                    onClickItem={onClickUser}
                    onRequestAdd={onRequestAdd}
                    onRequestEdit={onRequestEdit}
                    onRequestRemove={onRequestRemove}
                />
            </UserListBox>
            <WorkBox>
                <WorkHeader>
                    {
                        userInfoSelected
                        ? <NameTitle>{userInfoSelected.userName} 사용자 관련 설정 </NameTitle>
                        : <NameTitle>사용자를 먼저 선택해 주세요</NameTitle>
                    }
                </WorkHeader>
                <Box>
                    <AppBar position='relative'>
                        <Tabs value={work} onChange={(e,v)=>setWork(v)} textColor="inherit">
                            <Tab value={UserMenus.Menu} label="메뉴설정" disabled={!userInfoSelected} />
                            <Tab value={UserMenus.Group} label="관제그룹설정" disabled={!userInfoSelected} />
                            <Tab value={UserMenus.Alarm} label="알람설정" disabled={!userInfoSelected} />
                            <Tab value={UserMenus.PoiRel} label="지점관계설정" disabled={!userInfoSelected} />
                            <Tab value={UserMenus.PoiForMap} label="지도표시지점설정" disabled={!userInfoSelected} />
                        </Tabs>
                    </AppBar>
                </Box>
                <WorkView>
                    <WorkInnerWrapper>
                        {renderByTabMenu()}
                    </WorkInnerWrapper>
                </WorkView>
            </WorkBox>
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </UserInfoContainer>
    )
}
/*


*/