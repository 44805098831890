// CustGetAlarmList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Link, Button, Select, MenuItem, TextField } from '@mui/material';
import {userInfoRepo, NoUser} from '../../model/CvoModel';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlawedList from '../message/FlawedList';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
const LocalTitle = '알람수신정보';

const CustGetAlarmListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

const TableColumns = [
    { id: 'mobile', label: '휴대전화', width: 120, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'alarmType', label: '알람', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'rcvrName', label: '수신자이름', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'alarmInterval', label: '알람수신간격(분)', width: 120, show: true, option:{align: 'center', type: TableColumnType.INTEGER} },
    { id: 'timeFrom', label: '알람수신시작시각', width: 120, show: true, option:{align: 'center', type: TableColumnType.HHMM_TEXT} },
    { id: 'timeTill', label: '알람수신종료시각', width: 120, show: true, option:{align: 'center', type: TableColumnType.HHMM_TEXT} },
];

export default function CustGetAlarmList({
    alarmTypeList, // for filter.
	maxHeight,
	maxWidth,
	selected,
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
    responseList
}) {
    const [filterCriteria, setFilterCriteria] = useState(null);
    const [alarmTypeSelected, setAlarmTypeSelected] = useState(0);
    const [custGetAlarmList, setCustGetAlarmList] = useState([]);
    const sessionInfo = useReactiveVar(userInfoRepo);
    const [search, setSearch] = useState('');
    const {loading:loadingList, error:errorOfList, data:dataList} = responseList;
    const clientSize = useClientSize();

    useEffect(()=>{
        if(dataList && dataList.custGetAlarmList)
            setCustGetAlarmList(dataList.custGetAlarmList.filter((alarm) => {
                return alarmTypeSelected===0 || alarm.alarmTypeId === alarmTypeSelected
            }).map((alarm)=>{
                alarm.alarmOnMobile = alarm.mobile + ":" + alarm.alarmTypeId;
                return alarm;
            }));
    }, [dataList, alarmTypeSelected]);

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            default: return;
        }
    };

    const onSelectAlarmType = (event) => {
        setAlarmTypeSelected(event.target.value);
    };


    // Handling list event
    if(ValueUtil.flawedListResponse(responseList))
        return <FlawedList title={LocalTitle} gqlResponse={responseList} />;

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - AppNumber.dataContainerPadding * 2;
	
    const TableHeight = height - clientSize.HeaderToolBoxHeight
        - clientSize.SmallBoxPadding * 2 - 2;

    const renderAlarmTypeSelector = () => {
        return(
            <Select value={alarmTypeSelected} onChange={onSelectAlarmType} size='small'>
                <MenuItem value={0}>모든 알람타입</MenuItem>
            {
                alarmTypeList.map((alarm)=>{
                    return(
                        <MenuItem key={alarm.alarmTypeId} value={alarm.alarmTypeId}>{alarm.alarmType}</MenuItem>
                    );
                })
            }
        </Select>
        );
    };

    const isAdmin = ValueUtil.isCustAdmin(sessionInfo);
    return(
        <CustGetAlarmListContainer>
            <HeaderBox>
                {
                    isAdmin
                    ?
                    <ButtonBox>
                        <Button
                            onClick={onRequestAdd}
                            variant='contained' color='primary'>
                            <AddIcon fontSize='small' /> 추가
                        </Button>
                    </ButtonBox>
                    : null
                }
                {renderAlarmTypeSelector()}
                <TextField id="search" onChange={onChangeSearch} style={{marginLeft:20}}
                    value={search}
                    label="검색" size='small'/>
            </HeaderBox>
            <FlexyTable
                name="cust_get_alarm_list"
                uniqueKey="alarmOnMobile"
                columns={TableColumns}
                records={custGetAlarmList}
                search={search}
                criteria={filterCriteria}
                tools={isAdmin ? [
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize='small' />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize='small' />}
                ] : []}
                onClickTool={onClickToolOnRow}
				selected={selected ? [selected] : null}
            />
        </CustGetAlarmListContainer>
    );

}