// veh_info_log/VehInfoLogGql.js
import { gql } from "@apollo/client";

export const LIST_VEH_INFO_LOG = gql`
query ListVehInfoLog($vehId:Int!, $custId:String) {
    vehInfoLogList(vehId:$vehId, custId:$custId) {
        vehId
        chTime
        vehPlates
        devId
        termId
        changeReason
        regUserName
        custId
        oldVehPlates
        newnameYn
        isLatestYn
        regUserId
        dataKey
    }
}
`;
