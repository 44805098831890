// com/map/MapShell.js
import { MapEvent, MapValue } from "../../model/AppConst";
import { overlapRepo } from "../../model/CvoModel";
import PlaceTree from "./PlaceTree";
import PositionTail from "./PositionTail";
import MarkerManager from "./MarkerManager";
import GeoConvert, {GeoEllips, GeoSystem} from "../common/GeoConvert";
import PolygonWork from "./PolygonWork";
import Util from "../../model/Util";
import ValueUtil from "../../model/ValueUtil";

// Library to provide basic geospatial operations like distance calculation, conversion of decimal coordinates to sexagesimal and vice versa, etc.
// https://github.com/manuelbieh/geolib

const NM = window.naver.maps;
//const NaverClientId = 'yxawp2eee3';
//const NaverSecret = 'txM08az1YuxdVFYs3m2EFAU0CQ9ekR9cGfzrOB39';
const NaverMapTypes = [
    {type: window.naver.maps.MapTypeId.NORMAL, label: '일반', title: '일반지도'},
    {type: window.naver.maps.MapTypeId.TERRAIN, label: '지형', title: '지형지도'},
    {type: window.naver.maps.MapTypeId.SATELLITE, label: '위성', title: '위성지도'},
    {type: window.naver.maps.MapTypeId.HYBRID, label: '겹침', title: '겹침지도'},
];

export const MapMode = {VehPos:1, Route:2, Sweeper: 10, POI: 20, EditPOI: 21, NaverSearch: 30};

const MapLayerMode = {VehPos:1, Route:2, SweeperRoute:3}; // 청소업체는 아래에 항상 경로를 출력하고 지도에 그 경로를 그림.
const LocalEvent = {
    VehPosSelected:'vehpossel',MarkerRightClick:'rclkMark', MarkerClick:'mclk', MarkerDrag:'mdr',
    MarkerOver:'mover', MarkerDelete:'mkdel', MarkerAny:'mkany',
};
const INFO_MARKER_WIDTH = 160;
const INFO_MARKER_HEIGHT = 40;

export const MapContextMenu = {
    //SetInitPos : {key:'init_pos', label:'초기위치로 설정'}, -- 상단 메뉴로 ..
    DrawCircle : {key:'dr_ccle', label:'원/다각형 그리기'},
    AddPoi : {key:'add_poi', label:'지점 추가'},
    // SetPoiPos : {key:'setpp', label:'지점위치 설정'}, -- 마커를 직접 움직이면 되므로 없어도 됨.
    SetStartPos : {key:'setstpos', label:'경로탐색출발지'},
    SetEndPos : {key:'setend', label:'경로탐색도착지'},
};

/*
Context menu
- 초기위치로 설정 - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
- 원 그리기 -- dialog로 거리와 단위를 지정 / - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
- 지점 추가 - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
- 지점위치 설정 - 지점작업하고 있을 때.
- 경로탐색출발지 - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
- 경로탐색도착지 - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
*/

export default class MapShell {
    map = null;
    sessionInfo = null;
    mapService = null;
    bounding = {};
    speedEnough = 5; // TODO : Set according to the session data.
    //boundaryChanged = 0;

    vehPosList = []; // Experiment. instead ov vehPosTree.
    // vehPosHistory = {};
    posTail = null; // PositionTail object
    vehPosTrack = null; // mouse 올리면 보여주는 과거 위치정보의 움직임 폴리라인.
    // vehPosTree = null; // PlactTree for veh_pos
    circleOrPolygon = {shape:null, listener:null};
    placeTree = null; // PlaceTree for POIs
    clusterTree = null; // PlaceTree for clusters
    vehPosSelected = null;
    followVehicle = false;
    routeData = {};
    sweeperRouteData = {};

    indexBatch = 0; // functional component에서 내부변수 사용은 spooky함. 용도가 이상하긴 하지만 안정적인 변수 사용을 위해 POI 일괄저장용 변수를 여기에 선언해서 사용.

    poiMarkerManager = null;
    vehPosMarkerManager = null;
    routeMarkerManager = null;
    informationMarker = null;
    poiEditMarkerManager = null; // POI, polygon 편집용.
    poiNowMarkerManager = null; // 현재 mouseover한 지점의 폴리곤 보여주기: MarkerManager
    sweeperRouteMarkerManager = null; // 청소업체용 경로.
    naverRouteMarkerManager = null;

    mapMode = MapMode.VehPos;
    vehLayerMode = MapLayerMode.VehPos; // 차량위치 또는 차량경로 출력 여부.
    openPoiEditor = false;
    // _poiPolygon = []; // for poiEditMarkerManager
    polygonWork = null; // 편집중인 지점의 폴리곤 작업을 위한 것 : PolygonWork
    polygonNow = null; // 현재 mouseover한 지점의 폴리곤 보여주기 : PolygonWork

    // Listeners _xxListener 변수는 외부에서 설정한 실제 리스너로 왼쪽의 내부 리스너가 호출해 준다.
    lastEvent = {event:null};
    mapEventHandlers = {};
    mapMouseOverListener = null;
    mapRightClickListener = null;
    mapLeftClickListener = null;
    mapLevelChangeListener = null;
    mapMouseOutListener = null;
    mapDragEndedListener = null;
    mapBoundsChangedListener = null;

    latLonToKatek = null;
    katekToLatLon = null;

    onMouseOverAnyMarker = null; // user defined marker mouse over handler.

    /**
     * 네이버 지도 API를 감싸기 위한 shell 클래스
     * @param {window.naver.maps.Map} map - naver map instance
     * @param {UserSessionInfo} sessionInfo - 사용자 세션 정보.
     * @param {string} service - default 값 사용 'naver'
     */
    constructor(map, sessionInfo, service) {
        this.map = map;
        this.sessionInfo = sessionInfo;
        this.mapService = service || MapValue.NaverService;

        if(sessionInfo) this.speedEnough = sessionInfo.userAs.industryId===2 ? 2 : 5;

        this.bounding = {
            north: MapValue.Korea.North,
            south: MapValue.Korea.South,
            east: MapValue.Korea.East,
            west: MapValue.Korea.West
        };
        
        // this.vehPosTree = new PlaceTree(this, this.bounding);
        this.placeTree = new PlaceTree(this, this.bounding);

        this.poiMarkerManager = new MarkerManager(this);
        this.vehPosMarkerManager = new MarkerManager(this);
        this.routeMarkerManager = new MarkerManager(this);
        this.sweeperRouteMarkerManager = new MarkerManager(this);

        this.vehLayerMode = MapLayerMode.VehPos;

        this.latLonToKatek = new GeoConvert(GeoEllips.WGS84, GeoSystem.Geographic, GeoEllips.Bessel1984, GeoSystem.Katec);
        this.katekToLatLon = new GeoConvert(GeoEllips.Bessel1984, GeoSystem.Katec, GeoEllips.WGS84, GeoSystem.Geographic);
        this.polygonWork = new PolygonWork(this);

        this.posTail = new PositionTail(2); // 2 hours to show

        // this.mapIdleListener = NM.Event.addListener(this.map, MapEvent.IDLE, this.mapIdleHandler);
        this.mapMouseOverListener = NM.Event.addListener(this.map, MapEvent.MOUSE_OVER, this.mapMouseOverHandler);
        this.mapRightClickListener = NM.Event.addListener(this.map,  MapEvent.RIGHT_CLICK, this.mapRightClickHandler);
        this.mapLeftClickListener = NM.Event.addListener(this.map, MapEvent.CLICK, this.mapLeftClickHandler);
        this.mapLevelChangeListener = NM.Event.addListener(this.map, MapEvent.ZOOM_CHANGED, this.mapLevelChangeHandler);
        this.mapMouseOutListener = NM.Event.addListener(this.map, MapEvent.MOUSE_OUT, this.mapMouseOutHandler);

        this.mapDragEndedListener = NM.Event.addListener(this.map, MapEvent.DRAG_END, this.mapDragEndedHandler);
        this.mapBoundsChangedListener = NM.Event.addListener(this.map, MapEvent.BOUNDS_CHANGED, this.mapBoundsChangedHandler);
    }

    getMapTypes = () => { return NaverMapTypes; };

    destroy = () => {
        NM.Event.removeListener(this.mapRightClickListener);
        NM.Event.removeListener(this.mapLeftClickListener);
        NM.Event.removeListener(this.mapLevelChangeListener);
        NM.Event.removeListener(this.mapMouseOutListener);
        NM.Event.removeListener(this.mapDragEndedListener);
        NM.Event.removeListener(this.mapBoundsChangedListener);
    };

    setMapMode = (mode) => {
        this.mapMode = mode;
        this.clearSweeperRoute(); // 모드 바뀔때마다 해도 된다.

        if(this.mapMode===MapMode.VehPos) {
            this.vehLayerMode = MapLayerMode.VehPos;
            this.showVehPosMarkers();
        }
    };

    setSpeedEnough = (speed) => this.speedEnough = speed;

    setMapEventHandler = (eventKey, handlerKey, handler) => {
        if(!this.mapEventHandlers[eventKey]) this.mapEventHandlers[eventKey] = {};
        this.mapEventHandlers[eventKey][handlerKey] = handler;
    };

    setLastEvent = (evtKey, option) => {
        this.lastEvent = Boolean(option) ? {...option} : {};
        this.lastEvent.event = evtKey;
        this.lastEvent.time = new Date().getTime();
    };

    runEventHandlers = (eventKey, event) => {
        if(this.mapEventHandlers[eventKey]) {
            for(const func of Object.values(this.mapEventHandlers[eventKey])) {
                func(event, this);
            }
            this.setLastEvent(eventKey);
        }
    };

    removeEventHandlers = (eventKey, handlerKey) => {
        if(this.mapEventHandlers[eventKey]) {
            delete this.mapEventHandlers[eventKey][handlerKey];
        }
    }

    setMouseOverMarkerCallback = (callback) => this.onMouseOverAnyMarker = callback;

    ll2katek = ({lat, lon}) => {
        const point = this.latLonToKatek.convertXY(lon, lat);
        return {x:point.getX(), y: point.getY()};
    };

    katek2ll = ({x, y}) => {
        const point = this.katekToLatLon.convertXY(x, y);
        return {lat:point.getLat(), lon:point.getLon()};
    };

    getContextMenuList = () => {
        if(this.vehLayerMode===MapLayerMode.Route) {
            // 원 그리기 only
            return [MapContextMenu.DrawCircle];
        }
        else {
            const menus = [];

            if(this.openPoiEditor) {
                return [];
            }
            else if(this.mapMode===MapMode.Sweeper) {
                // 청소업체.
                menus.push(MapContextMenu.DrawCircle);
            }
            else {
                //if(this.mapMode===MapMode.POI)
                    menus.push(MapContextMenu.AddPoi);
                menus.push(MapContextMenu.SetStartPos);
                menus.push(MapContextMenu.SetEndPos);
                menus.push(MapContextMenu.DrawCircle);
            }
            return menus;
        }
        /*
        Context menu
        - 초기위치로 설정 - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
        - 원 그리기 -- dialog로 거리와 단위를 지정 / - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
        - 지점 추가 - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
        - 지점위치 설정 - 지점작업하고 있을 때.
        - 경로탐색출발지 - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
        - 경로탐색도착지 - 경로표시되고 있지 않을 때. 지점작업하고 있지 않을 때.
        */
    };

    /**
     * mouse-enter(의 의미) 이벤트 핸들러.
     * @param {object} e 
     */
    mapMouseOverHandler = (e) => {
        if(this.lastEvent.event===MapEvent.BOUNDS_CHANGED) {
            this.showPoiMarkers();
        }
        this.runEventHandlers(MapEvent.MOUSE_OVER, e);
    };

    /**
     * 지점추가를 위해 우측 클릭.
     * @param {object} e 
     */
    mapRightClickHandler = (e) => {
        /*
        {
            "type": "rightclick",
            "originalEvent": {          "isTrusted": true            },
            "domEvent": {               "isTrusted": true            },
            "pointerEvent": {           "isTrusted": true },
            "offset": {  "x": 9,                "y": 11            },
            "point": {   "x": 218.28893730007815,                "y": 99.13023022115107            },
            "coord": {   "y": 37.5735834, "_lat": 37.5735834, "x": 126.9688181, "_lng": 126.9688181},
            "latlng": {  "y": 37.5735834, "_lat": 37.5735834, "x": 126.9688181, "_lng": 126.9688181 }
        }
        */

        // e.originalEvent.stopPropagation();
        // e.domEvent.stopPropagation();
        e.pointerEvent.preventDefault();
        if(!e.originalEvent.stopPropagation) console.log("No e.originalEvent.stopPropagation")
        if(!e.domEvent.stopPropagation) console.log("No e.domEvent.stopPropagation")
        if(!e.pointerEvent.stopPropagation) console.log("No e.pointerEvent.stopPropagation")

        overlapRepo(null);
        if(this.lastEvent.event===LocalEvent.MarkerDelete) {
            this.setLastEvent(MapEvent.RIGHT_CLICK);
        }
        else this.runEventHandlers(MapEvent.RIGHT_CLICK, e);
    };

    mapLeftClickHandler = (e) => {
        // event 구조는 위 mapRightClickHandler와 같음.
        // morph(coord, zoom, transitionOptions)
        //this.map.morph(e.coord, this.getZoom());
        overlapRepo(null);
        this.runEventHandlers(MapEvent.CLICK, e);
    };

    mapDragEndedHandler = (e) => {
        this.showPoiMarkers();
        this.runEventHandlers(MapEvent.DRAG_END, e);
    };

    mapBoundsChangedHandler = (e) => {
        // 매번 적용하면 너무 느리다. 마우스가 들어오면 마지막 이벤트를 확인해서 이것이면 지점/클러스터 마커들을 새로 그리도록 한다.(참조: mapMouseOverHandler)
        this.runEventHandlers(MapEvent.BOUNDS_CHANGED, e);
    }

    /**
     * 주어진 차량의 위치로 이동하고 재추적 가능하도록 추가적인 정보 저장.
     * @param {object} vehPos - 차량위치.
     */
    setVehPosSelected = (vehPos) => {
        if(vehPos) {
            this.morphTo(vehPos.lat, vehPos.lon);
            //this.setCenter(vehPos.lat, vehPos.lon);
            this.setLastEvent(LocalEvent.VehPosSelected);
        }
        this.vehPosSelected = vehPos;
    };

    /**
     * 주어진 차량의 위치로 다시 갱신할 수 있으면 지도 중심을 다시 바꿔준다.
     * @param {object} vehPos 
     */
    recenterSameVehPosIfPossible = (vehPos) => {
        if(vehPos && this.vehPosSelected && this.followVehicle) {
            if(vehPos.vehId===this.vehPosSelected.vehId) this.morphTo(vehPos.lat, vehPos.lon);
            else this.followVehicle = false;
        }
        this.vehPosSelected = vehPos;
    };

    onIdleAfterMomphTo = (e) => {
        NM.Event.removeListener(this.__listenerIdleAfterMorphTo);
        this.showPoiMarkers();
    };
    
    morphTo = (lat, lon, level) => {
        this.__listenerIdleAfterMorphTo = NM.Event.addListener(this.map, MapEvent.IDLE, this.onIdleAfterMomphTo);
        this.map.morph(new NM.LatLng(lat, lon), level);
    };

    getCenter = () => {
        const c = this.map.getCenter();
        return {lat:c.lat(), lon:c.lng()};
    };
    setCenter = (lat, lon, level) => {
        this.map.setCenter(new NM.LatLng(lat, lon));
        if(level) this.map.setZoom(level);
    };

    getMap = () => this.map;
    getProjection = () => this.map.getProjection();
    coordToOffset = (coord) => { return this.map.getProjection().fromCoordToOffset(coord); };
    llToOffset = (lat, lon) => { return this.coordToOffset(new NM.LatLng(lat, lon)); };
    offsetToCoord = (offset) => { return this.map.getProjection().fromOffsetToCoord(offset)};
    xyToCoord = (x, y) => { return this.offsetToCoord(new NM.Point(x, y))};
    getZoom = () => this.map.getZoom();
    setZoom = (level)=>this.map.setZoom(level);
    setMapType = (mapType) => this.map.setMapTypeId(mapType);
    getMapType = () => this.map.getMapTypeId();

    mapLevelChangeHandler = (newLevel) => {
        this.showPoiMarkers();
        this.runEventHandlers(MapEvent.ZOOM_CHANGED, newLevel);
    };

    mapMouseOutHandler = (e) => {
        // mapRightClickHandler event와 같은 구조.
        this.runEventHandlers(MapEvent.MOUSE_OUT, e);
    };

    getBoundary = () => {
        const b = this.map.getBounds();
        return {north: b._ne._lat, south: b._sw._lat, east: b._ne._lng, west: b._sw._lng};
    };

    setSize = (size) => this.map.setSize(size);
    getSize = () => this.map.getSize();

    // ###################### Sweeper Route ####################
    /**
     * 선택된 청소차량의 경로를 보여준다. 청소차량 탭에서는 항상 하나의 차량을 선택하고 이 차량의 경로를 보여주게 되어있다.
     * 현재 차량위치들이 표시되어 있다면 지워준다.
     * @param {object} sweeper - 현재 선택된 차량. 기본적으로 RouteDaily와 유사한 SweeperStat 객체.
     * @param {Array} route - 선택된 차량의 경로 데이터.
     */
    showSweeperRoute = (sweeper, route) => {
        this.sweeperRouteData = {sweeper:sweeper, route:route}; // sweeper: selected
        this.vehPosMarkerManager.clear(); // 차량마커들 지우기.

        // speedEnough를 작은 값으로 정해준다.
        this.sweeperRouteMarkerManager.drawMarkers(MapValue.MarkerType.ROUTE_POINT, this.sweeperRouteData.route, {
            speedEnough: this.speedEnough,
            callbacks: [
                // 이벤트들은 일반 경로와 같이 처리.
                {eventKey:MapEvent.CLICK, callback: (e)=>this.onMouseClickRoutePoint(e)},
                {eventKey:MapEvent.MOUSE_OUT, callback: (e)=>this.onMouseLeaveRoutePoint(e)},
                {eventKey:MapEvent.MOUSE_OVER, callback: (e)=>this.onMouseOverRoutePoint(e)},
            ]
        });

        if(this.sweeperRouteData.route.length > 1 && this.sweeperRouteMarkerManager.layer) {
            this.map.fitBounds(this.sweeperRouteMarkerManager.layer.getBounds());
        }

        this.vehLayerMode = MapLayerMode.SweeperRoute;
    };

    /**
     * 주어진 차량+날짜의 청소차량경로가 그려진 상태인지 검사하여 참/거짓 반환.
     * @param {object} sweeper - 선택여부를 검사할 차량. RouteDaily와 유사한 SweeperStat 객체.
     * @returns 현재경로 표시 여부
     */
    isSweeperSelected = (sweeper) => {
        if(this.sweeperRouteData.sweeper)
            return this.sweeperRouteData.sweeper.vehId===sweeper.vehId && this.sweeperRouteData.sweeper.routeDate===sweeper.routeDate;
        return false;
    }

    clearSweeperRoute = () => {
        this.sweeperRouteData = {};
        this.sweeperRouteMarkerManager.clear();
    };

    // ###################### Vehicle Route ####################
    setVehicleRoute = (vehPos, route) => {
        this.routeData = {vehPos:vehPos, route:route};
    };

    onMouseOverRoutePoint = (event) => {
        const userData = event.overlay.getOptions(MapValue.USER_DATA);
        const anchorX = event.originalEvent.pageX > window.innerWidth - INFO_MARKER_WIDTH ? INFO_MARKER_WIDTH : INFO_MARKER_WIDTH / 2;
        const anchorY = event.originalEvent.pageY < 150 ? -10 : INFO_MARKER_HEIGHT + 10;
        const align = event.originalEvent.pageX > window.innerWidth - INFO_MARKER_WIDTH ? 'right' : 'left';

        this.informationMarker = new NM.Marker({
            position: new NM.LatLng(userData.data.lat, userData.data.lon),
            map: this.map,
            icon: {
                content:
                    `<div style="display: inline-block;">
                        <div style="
                            display: inline-block; font-size:10pt;
                            height:${INFO_MARKER_HEIGHT}px;
                            width:${INFO_MARKER_WIDTH}px;"
                            text-align: ${align}
                        >
                            <div style="
                                background-color: white;
                                padding:10px;
                                border: 1px solid black;
                                border-radius: 10px;
                                text-align: center;
                            ">
                                ${userData.data.gpsTime}
                            </div>
                        </div>
                    </div>`,
                size: new NM.Size(INFO_MARKER_WIDTH, INFO_MARKER_HEIGHT),
                anchor: new NM.Point(anchorX, anchorY),
            },
            draggable: false,
            zIndex: MapValue.ROUTE_Z_INDEX+10
        });
    };

    onMouseLeaveRoutePoint = (event) => {
        if(this.informationMarker) this.informationMarker.setMap(null);
        this.informationMarker = null;
    };

    onMouseClickRoutePoint = (event) => {
        const userData = event.overlay.getOptions(MapValue.USER_DATA);
        Util.bubblePopInfoInMap(userData);
    };

    showRouteMarkers = () => {
        // 청소차량 탭에서 차량경로로 직접 넘어가는 경우는 없으므로 청소차량 경로를 지우는 작업을 하지 않아도 된다.
        this.vehLayerMode = MapLayerMode.Route;
        this.routeMarkerManager.drawMarkers(MapValue.MarkerType.ROUTE_POINT, this.routeData.route, {
            speedEnough: this.speedEnough,
            callbacks: [
                {eventKey:MapEvent.CLICK, callback: (e)=>this.onMouseClickRoutePoint(e)},
                {eventKey:MapEvent.MOUSE_OUT, callback: (e)=>this.onMouseLeaveRoutePoint(e)},
                {eventKey:MapEvent.MOUSE_OVER, callback: (e)=>this.onMouseOverRoutePoint(e)},
            ]
        });
        //map.fitBounds(self.models.vehRoutePolyline.getBounds());
        if(this.routeData.route.length > 1 && this.routeMarkerManager.layer) {
            this.map.fitBounds(this.routeMarkerManager.layer.getBounds());
        }
    };

    // ###################### 차량위치 관련 ########################
    setVehPosList = (records) => {
        //this.vehPosTree.clear();
        //this.vehPosTree.setPlaceRecords(records);
        this.vehPosList = records;
        this.posTail.addPositions(records);
    };

    onClickVehPos = (event) => {
        const userData = event.overlay.getOptions(MapValue.USER_DATA);
        //this.morphTo(userData.data.lat, userData.data.lon);
        Util.bubblePopInfoInMap(userData);
    };

    setVehPosTrackLimit = (hours) => this.posTail.setHourLimit(hours);
    getVehPosTrackLimit = () => this.posTail.getHourLimit();

    showSavedTrack = (track) => {
        if(track.length>1) {
            if(this.vehPosTrack) this.vehPosTrack.setMap(null); // if any...
            this.vehPosTrack = new NM.Polyline({
                map:this.map,
                path: track.map((p)=>new NM.LatLng(p.lat,p.lon)),
                strokeColor: '#00f',
                strokeOpacity: 0.7,
                strokeWeight: 3
            });
        }
    };

    hideVehPosTrack = () => {
        if(this.vehPosTrack) {
            this.vehPosTrack.setMap(null);
            this.vehPosTrack = null;
        }
    };

    /**
     * 겹침마커에서 차량번호에 마우스 올라갔을 때 처리. MapMain.js에서 호출하기 위해 별도 작성함.
     * @param {object} data - vehicle position
     */
    handleMouseOverVehPos = (userData) => {
        const track = this.posTail.getTrackToShow(userData.data.vehId);
        const oldestPos = track.length > 0 ? track[track.length-1] : null;

        this.showSavedTrack(track);

        if(this.onMouseOverAnyMarker) {
            this.onMouseOverAnyMarker({
                type: MapValue.MarkerType.VEH_POS,
                markerData: userData.data,
                oldest: oldestPos
            });
        }
    };

    onMouseOverMarker = (event) => {
        const marker = event.overlay;
        //const markerBound = this.poiMarkerManager.getMarkerRect(marker,-1);
        const overlapped = this.vehPosMarkerManager.getDataOverlapped(marker, -1);
        overlapped.push(...this.poiMarkerManager.getDataOverlapped(marker,-1)); // array of userData

        const userData = marker.getOptions(MapValue.USER_DATA);
        if(overlapped.length>1) {
            // 1인 경우 자기 자신이므로.
            if(userData.type===MapValue.MarkerType.POI || userData.type===MapValue.MarkerType.VEH_POS)
                overlapRepo({
                    records: overlapped,
                    x: event.originalEvent.pageX,
                    y: event.originalEvent.pageY
                });
        }
        else {
            overlapRepo(null);
            if(userData.type===MapValue.MarkerType.VEH_POS) {
                this.handleMouseOverVehPos(userData);
            }
            else if(userData.type===MapValue.MarkerType.POI) {
                if(!this.openPoiEditor) {
                    if(userData.data.zoneShape) {
                        if(this.poiNowMarkerManager) this.poiNowMarkerManager.clear();
                        this.polygonNow = new PolygonWork(this);
                        this.polygonNow.setUsingShape(userData.data.zoneShape);
                        this.poiNowMarkerManager = new MarkerManager(this);
                        this.poiNowMarkerManager.drawPolygon(this.polygonNow);
                    }

                    if(this.onMouseOverAnyMarker) {
                        this.onMouseOverAnyMarker({
                            type: userData.type,
                            markerData: userData.data
                        });
                    }
                }

            }
        }
    };

    onMouseLeaveVehPos = (event) => {
        this.hideVehPosTrack();
        if(this.onMouseOverAnyMarker) this.onMouseOverAnyMarker(); // undefined will closed top-info-box
    }

    hideVehPosMarkers = () => {this.vehPosMarkerManager.clear();};

    /**
     * 차량위치들의 마커를 보여줌. 차량위치 마커를 보여줘야 할 때를 판단해야 한다.
     * @param {boolean} force - 강제로 출력모드를 차량위치로 설정해 줌.
     */
    showVehPosMarkers = (force) => {
        if(force) {
            if(this.vehLayerMode === MapLayerMode.Route) {
                this.routeMarkerManager.clear();
            }
            else if(this.vehLayerMode === MapLayerMode.SweeperRoute) {
                if(this.sweeperRouteMarkerManager) this.sweeperRouteMarkerManager.clear();
            }
            this.vehLayerMode = MapLayerMode.VehPos;
        }
        // 차량위치는 현재 차량경로가 선택되어 있는 경우, 청소차량 탭인 경우에는 그리지 않는다.
        if(this.vehLayerMode === MapLayerMode.VehPos) this._markAllVehPositions();
    };

    _markAllVehPositions = () => {
        this.vehPosMarkerManager.clear();
        this.vehPosMarkerManager.drawMarkers(MapValue.MarkerType.VEH_POS,
            this.vehPosList.sort((a,b)=>b.lat-a.lat),
            {
                speedEnough: this.speedEnough,
                callbacks: [
                    {eventKey:MapEvent.CLICK, callback: (e)=>this.onClickVehPos(e)},
                    {eventKey:MapEvent.MOUSE_OVER, callback: (e)=>this.onMouseOverMarker(e)},
                    {eventKey:MapEvent.MOUSE_OUT, callback: (e)=>this.onMouseLeaveVehPos(e)},
                ]
            }
        );
    };

    // ###################### 지점정보 관련 ########################
    setPoiRecords = (records) => {
        this.placeTree.clear();
        this.placeTree.setPlaceRecords(records);
    };

    handleClickPoi = (userData) => {
        //this.morphTo(userData.data.lat, userData.data.lon);
        Util.bubblePopInfoInMap(userData);
    };

    onClickPoi = (event) => {
        const userData = event.overlay.getOptions(MapValue.USER_DATA);
        this.handleClickPoi(userData);
    };

    onMouseOutPoi = (event) => {
        if(this.poiNowMarkerManager && !this.openPoiEditor) {
            this.poiNowMarkerManager.clear();
            if(this.onMouseOverAnyMarker) this.onMouseOverAnyMarker();
        }
    };

    handleClickCluster = (cluster) => {
        this.morphTo(cluster.lat, cluster.lon, this.map.getZoom()+1);
    };

    onClickCluster = (event) => {
        const marker = event.overlay;
        const userData = marker.getOptions(MapValue.USER_DATA);
        this.sessionInfo.ifLocal(()=>console.log(userData));
        if(userData.type===MapValue.MarkerType.POI) {
            // Treat like a POI
            this.handleClickPoi({type:MapValue.MarkerType.POI, data:userData.data});
        }
        else this.handleClickCluster(userData.data);
    };

    /**
     * 현재 보이는 지도화면 내에 뿌려질 지점 또는 클러스터 마커들을 보여준다.
     * 마커 생성 이전에 기존 마커들을 먼저 없앤다.
     */
    showPoiMarkers = () => {
        const boundNow = this.getBoundary();
        const level = this.getZoom();

        this.poiMarkerManager.clear();
    
        if(level > MapValue.Level.MAX_FOR_CLUSTER) {
            const pois = this.placeTree.getPoisIn(boundNow, level).sort((a,b)=>b.lat-a.lat).map((node)=>node.poi);

            this.poiMarkerManager.drawMarkers(MapValue.MarkerType.POI, pois, {
                callbacks: [
                    {eventKey:MapEvent.CLICK, callback: (e)=>this.onClickPoi(e)},
                    {eventKey:MapEvent.MOUSE_OVER, callback: (e)=>this.onMouseOverMarker(e)},
                    {eventKey:MapEvent.MOUSE_OUT, callback: (e)=>this.onMouseOutPoi(e)},
                ]
            });
        }
        else {
            this.clusterTree = new PlaceTree(this);
            const poiNodes = this.placeTree.getPoisIn(boundNow, level);

            const size = this.getSize();
            const geoWidth = (boundNow.east - boundNow.west) * MapValue.ClusterCoverSize / size.width; // 클러스터가 차지하는 경도범위
            const geoHeight = (boundNow.north - boundNow.south) * MapValue.ClusterCoverSize / size.height; // 클러스터가 차지하는 위도범위

            this.clusterTree.buildClusters(poiNodes, geoWidth, geoHeight);
            const clusters = this.clusterTree.getAll().sort((a,b)=>b.north - a.north);

            this.poiMarkerManager.drawMarkers(MapValue.MarkerType.CLUSTER, clusters, {
                callbacks: [
                    {eventKey:MapEvent.MOUSE_OVER, callback: (e)=>this.onMouseOverMarker(e)},
                    {eventKey:MapEvent.MOUSE_OUT, callback: (e)=>this.onMouseOutPoi(e)},
                    {eventKey:MapEvent.CLICK, callback: (e)=>this.onClickCluster(e)}
                ]
            });
        }
    };

    // ################### 지점. 출발도착. ###################
    getPolygonWork = () => this.polygonWork;

    // 폴리곤 꼭지점을 잡아 끈다.
    onAnchorDrag = (e) => {
        const userData = e.overlay.getOptions(MapValue.USER_DATA);
        const markerIndex = userData.index;
        this.polygonWork.changePositionAt(markerIndex, e.coord.y, e.coord.x);
        this.poiEditMarkerManager.setPoiPolygonPath(this.polygonWork);
        this.setLastEvent(LocalEvent.MarkerDrag);
    };

    // 폴리곤 꼭지점을 잡아 끈다-종료
    onAnchorDragEnd = (e) => {
        const userData = e.overlay.getOptions(MapValue.USER_DATA);
        const markerIndex = userData.index;
        this.polygonWork.changePositionAt(markerIndex, e.coord.y, e.coord.x);
        this.poiEditMarkerManager.setPoiPolygonPath(this.polygonWork);
        this.setLastEvent(LocalEvent.MarkerDrag);
    };

    // 폴리곤 꼭지점을 오른쪽 클릭하면 삭제한다.
    onAnchorRightClick = (e) => {
        if(this.polygonWork.countVertex() > 3) {
            const userData = e.overlay.getOptions(MapValue.USER_DATA);
            const markerIndex = userData.index;
            this.polygonWork.removeAt(markerIndex);
            // this.poi_Polygon.splice(markerIndex,1); // ====> removeAt(idx)
            //fakeRepo(this._poiPolygon);
            //e.originalEvent.stopPropagation();
            //e.domEvent.stopPropagation();
            //e.pointerEvent.stopPropagation();
            /*
            console.log(e);
            if(e.originalEvent.stopPropagation) {
                console.log("Have .stopPropagation()");
            }
            else console.log("No .stopPropagation()");
            */
            this.drawEditingPoiPolygon();
            this.setLastEvent(LocalEvent.MarkerDelete);
        }
    };

    // 마우스를 놓아주면 왼쪽 클릭의 경우에만 폴리곤을 새로 그려준다.
    onAnchorMouseUp = (e) => {
        if(e.pointerEvent.button===0) {
            this.poiEditMarkerManager.setPoiPolygonPath(this.polygonWork);
            this.setLastEvent(LocalEvent.MarkerClick);
        }
    };

    /**
     * 폴리곤 꼭지점 사이의 중간점을 drag하면 새로운 꼭지점을 추가해 준다.
     * 꼭지점 마커는 아직 추가하지 않고 폴리곤 그림만 업데이트 하면 된다.
     * @param {object} e - drag start event.
     */
    onMidMarkerDragStart = (e) => {
        const userData = e.overlay.getOptions(MapValue.USER_DATA);
        const markerIndex = userData.index;
        this.polygonWork.insert(markerIndex, e.coord.y, e.coord.x);
        //this._poiPolygon.splice(markerIndex, 0, {lat:e.coord.y, lon:e.coord.x}); //  ==> insert(...) // midMarkers 추가할 때부터 이미 신규추가를 염두에 두고 인덱스를 만들어 둠.
        // fakeRepo(this.poiP_olygon);
        this.poiEditMarkerManager.refreshPoiPolygonPath(this.polygonWork);
        this.setLastEvent(LocalEvent.MarkerAny);
    };

    /**
     * 폴리곤 꼭지점 사이의 중간점을 drag. 계속해서 폴리곤 그림만 업데이트 하면 된다.
     * @param {object} e - drag start event.
     */
    onMidMarkerDrag = (e) => {
        const userData = e.overlay.getOptions(MapValue.USER_DATA);
        const markerIndex = userData.index; // 실제 위치보다 1이 큰 값이 들어 있다. 그대로 사용한다. (위 onMidMarkerDragStart 참조)
        
        this.polygonWork.changePositionAt(markerIndex, e.coord.y, e.coord.x);
        // this.po_iPolygon[markerIndex] = {lat:e.coord.y, lon:e.coord.x};
        // fakeRepo(this.poiP_olygon);
        this.poiEditMarkerManager.refreshPoiPolygonPath(this.polygonWork);
        this.setLastEvent(LocalEvent.MarkerAny);
    };

    // 폴리곤 꼭지점 사이의 중간점을 drag 종료
    onMidMarkerDragEnd = (e) => {
        const userData = e.overlay.getOptions(MapValue.USER_DATA);
        const markerIndex = userData.index; // 실제 위치보다 1이 큰 값이 들어 있다. 그대로 사용한다. (위 onMidMarkerDragStart 참조)
        
        this.polygonWork.changePositionAt(markerIndex, e.coord.y, e.coord.x);
        //this.poiPoly_gon[markerIndex] = {lat:e.coord.y, lon:e.coord.x};
        //fakeRepo(this.poiPoly_gon);
        this.drawEditingPoiPolygon();
        this.setLastEvent(LocalEvent.MarkerAny);
    };

    /**
     * 폴리곤과 마커 그리기.
     */
    drawEditingPoiPolygon = () => {
        this.poiEditMarkerManager.drawEditingPoiPolygon(this.polygonWork, {
            vertexHandlers: [
                {eventKey: MapEvent.DRAG, callback: (e)=>this.onAnchorDrag(e)},
                {eventKey: MapEvent.DRAG_END, callback: (e)=>this.onAnchorDragEnd(e)},
                {eventKey: MapEvent.RIGHT_CLICK, callback: (e)=>this.onAnchorRightClick(e)},
                // {eventKey: MapEvent.MOUSE_UP, callback: (e)=>this.onAnchorMouseUp(e)},
            ],
            midMarkerHandlers: [
                {eventKey: MapEvent.DRAG, callback: (e)=>this.onMidMarkerDrag(e)},
                {eventKey: MapEvent.DRAG_START, callback: (e)=>this.onMidMarkerDragStart(e)},
                {eventKey: MapEvent.DRAG_END, callback: (e)=>this.onMidMarkerDragEnd(e)},
            ]
        });
    };

    getEditingPolygonArea = () => this.poiEditMarkerManager ? this.poiEditMarkerManager.getAreaSize() : 0;


    /**
     * 지점 출발도착구역을 위한 polygon 데이터 설정.
     * 폴리곤을 그리고 Handle marker, Mid marker를 모두 그려야 함.
     * @param {Array} llArray - array of {lat, lon}. first element must be pushed.
     */
    setEditingPoiPolygon = (llArray) => {
        if(this.openPoiEditor) {
            if(this.poiNowMarkerManager) this.poiNowMarkerManager.clear();
            this.polygonWork.setList(llArray);
            this.drawEditingPoiPolygon();
        }
    };

    /**
     * POLYGON Well-Known-Text format 문자열을 받아서 현재 작업중인 폴리곤에 넣어준다.
     * @param {string} zoneShape - POLYGON WKT
     */
    setEditingPoiPolygonShape = (zoneShape) => {
        if(this.openPoiEditor) {
            if(zoneShape.indexOf("POLYGON")===0) {
                if(this.poiNowMarkerManager) this.poiNowMarkerManager.clear();
                this.polygonWork.setUsingShape(zoneShape);
                this.drawEditingPoiPolygon();
            }
        }
    };

    /**
     * 지점 출발도착구역을 위한 polygon 데이터 삭제, 그림 지움.
     */
    clearPoiPolygon = () => {
        this.poiEditMarkerManager.clear();
        this.poiEditMarkerManager.clearMidMarkers();
        this.polygonWork.trim();
        //this.poiPolyg_on = []; // ===> trim()
        //fakeRepo(this.poiPolygon_);
    };

    drawCurrentPolyginWhileNotAdujusting = (poiData) => {
        if(poiData.useBcdYn==='Y') {
            this.polygonNow = new PolygonWork(this);
            this.polygonNow.setUsingShape(poiData.zoneShape);
            this.poiNowMarkerManager = new MarkerManager(this);
            this.poiNowMarkerManager.drawPolygon(this.polygonNow);
        }
    };

    /**
     * 지점정보 추가 또는 수정을 시작하거나 종료함.
     * @param {boolean} bool - Open or close
     * @param {object} poiData - poi data to edit
     * @param {number} lat - new poi position (lattitude)
     * @param {number} lon - new poi position (longitude)
     * @param {Function} onMoveMarker
     */
    setOpenPoiEditor = (bool, poiData, lat, lon, onMoveMarker) => {
        this.openPoiEditor = bool;
        if(bool) {
            if(this.poiNowMarkerManager) this.poiNowMarkerManager.clear();

            this.polygonWork = new PolygonWork(this);
            //this.poiPol_ygon = []; // new PolygonWork();
            //fakeRepo(this.poiPoly_gon); // 별도 클래스로 만드는 것이 좋겠어. ####################################
            if(this.poiEditMarkerManager) {
                this.poiEditMarkerManager.clear();
                this.poiEditMarkerManager.clearMidMarkers();
            }
            this.poiEditMarkerManager = new MarkerManager(this);
            if(poiData) {
                this.__currentPolygonShape = poiData.zoneShape;
                // create a marker.
                this.poiEditMarkerManager.makeAndShowNewPoiMarker(poiData.lat, poiData.lon, (e)=>{
                    onMoveMarker({lat:e.coord.y, lon:e.coord.x});
                });
                
                // 폴리곤을 편집하지 않을 때에는 현재의 폴리곤을 보여준다.
                this.drawCurrentPolyginWhileNotAdujusting(poiData);
            }
            else {
                // create a marker.
                this.poiEditMarkerManager.makeAndShowNewPoiMarker(lat, lon, (e)=>{
                    onMoveMarker({lat:e.coord.y, lon:e.coord.x});
                });
                this.morphTo(lat, lon);
            }
            // polygon data 전달은 repository 사용: fakeRepo
        }
        else {
            if(this.poiEditMarkerManager) {
                this.poiEditMarkerManager.clear();
                this.poiEditMarkerManager.clearMidMarkers();
            }
            this.poiEditMarkerManager = null;
            if(this.poiNowMarkerManager) this.poiNowMarkerManager.clear();
        }
    };

    // ########################## 원, 폴리곤 그리기 #########################
    drawShape = (mapShapeData) => {
        this.removeShape();
        const {lat, lon, data} = mapShapeData;
        const ll = new NM.LatLng(lat, lon);

        if(data.vertex >= 3 && data.vertex <= 16) {
            const polygonVertices = ValueUtil.getPolygon(lat, lon, data.vertex, data.dist);

            this.circleOrPolygon.shape = new NM.Polygon({
                map: this.map,
                paths: [polygonVertices.map((p)=>new NM.LatLng(p.lat,p.lon))],
                fillColor: '#00f',
                fillOpacity: 0.2,
                strokeColor: '#f00',
                strokeOpacity: 0.6,
                strokeWeight: 4,
                clickable: true
            });
        }
        else {
            this.circleOrPolygon.shape = new NM.Circle({
                map: this.map,
                center: ll,
                radius: data.dist,
                fillColor: '#00f',
                fillOpacity: 0.2,
                strokeWeight: 4,
                strokeOpacity: 0.6,
                strokeColor: '#f00',
                clickable: true
            });
        }
        this.circleOrPolygon.listener = NM.Event.addListener(this.circleOrPolygon.shape, MapEvent.CLICK, (e) => {
            this.removeShape();
        });
    };

    removeShape = () => {
        if(this.circleOrPolygon.listener) {
            NM.Event.removeListener(this.circleOrPolygon.listener);
        }
        if(this.circleOrPolygon.shape) {
            this.circleOrPolygon.shape.setMap(null);
        }
        this.circleOrPolygon = {};
    };

    // ########################## indexBatch ############################
    setIndexBatch = (idx) => this.indexBatch = idx ? idx : 0;
    getIndexBatch = () => this.indexBatch;
    increaseIndexBatch = () => {this.indexBatch++; return this.indexBatch;};

    // ########################## 네이버/Naver ############################
    isNaverResponseOk = (status) => NM.Service.Status.OK === status;
    /**
     * 네이버 좌표 to 주소 검색하기. 신규 지점 등록 시 or 경로탐색 시 지정한 좌표에 대해서 검색.
     * @param {number} lat - 위도.
     * @param {number} lon - 경도.
     * @callback callback - 결과를 전달할 함수. callback(status, response)
     */
    queryNaverReverseGeocode = (lat, lon, callback) => {
        const ll = new NM.LatLng(lat, lon);
        NM.Service.reverseGeocode({coords: ll}, callback);
    };

    queryNaverGeocode = (address, callback) => {
        NM.Service.geocode({query: address}, callback);
    };

    // 네이버 경로탐색 브라우저에서 직접 할 수 없음. Access-Control-Allow-Origin 오류나서 안됨. Proxy 써야함.
    setNaverRouteStartPos = (pos) => {
        if(!this.naverRouteMarkerManager) this.naverRouteMarkerManager = new MarkerManager(this);
        this.naverRouteMarkerManager.setNaverRouteStartPos(pos, true); // true for starting position
    };

    setNaverRouteEndPos = (pos) => {
        if(!this.naverRouteMarkerManager) this.naverRouteMarkerManager = new MarkerManager(this);
        this.naverRouteMarkerManager.setNaverRouteStartPos(pos, false); // false for ending position
    };

    clearNaverRouteMarkers = () => {
        if(this.naverRouteMarkerManager) {
            this.naverRouteMarkerManager.clear();
            this.naverRouteMarkerManager = null;
        }
    };

    drawNaverRoute = (naverRoute) => {
        if(!this.naverRouteMarkerManager) this.naverRouteMarkerManager = new MarkerManager(this);
        this.naverRouteMarkerManager.drawNaverRoute(naverRoute);
    };

    drawNaverRouteAndMarkers = (naverRoute) => {
        if(this.naverRouteMarkerManager) this.naverRouteMarkerManager.clear();
        else this.naverRouteMarkerManager = new MarkerManager(this);

        const start = naverRoute.from;
        const end = naverRoute.to;
        if(start) this.naverRouteMarkerManager.setNaverRouteStartPos(start, true); // true for starting position
        if(end) this.naverRouteMarkerManager.setNaverRouteStartPos(end, false); // true for starting position
        this.naverRouteMarkerManager.drawNaverRoute(naverRoute);
    };

    /*
    ############ Naver Route ##############
    // https://naveropenapi.apigw.ntruss.com/map-direction/v1/driving?start=127.1058342,37.359708&goal=129.075986,35.179470&option=trafast
    const startAddress = 'your_start_address';
    const destinationAddress = 'your_destination_address';
    const searchOption = 'your_search_option';
    const clientId = 'your_client_id';
    const clientSecret = 'your_client_secret';

    const apiUrl = `https://naveropenapi.apigw.ntruss.com/map-direction/v1/driving?start=${startAddress}&goal=${destinationAddress}&option=${searchOption}`;

    const headers = new Headers({
    'X-NCP-APIGW-API-KEY-ID': clientId,
    'X-NCP-APIGW-API-KEY': clientSecret,
    });

    fetch(apiUrl, {
        method: 'GET',
        headers: headers,
    })
    .then((response) => response.json())
    .then((data) => {
        ---log(data); // Handle the response data here
    })
    .catch((error) => {
        ---.error('Error:', error);
    });

    ############# Naver 주소 검색 ##############

    // 네이버 주소검색 결과 처리
    this.onResultNaverGeocodeQuery = function(status, response) {
        if (status !== naver.maps.Service.Status.OK) {
            $log.log(response);
            return self.models.alertTitled('네이버 통신오류', ['네이버 서버와의 통신에 문제가 있습니다.', '잠시 후 다시 시도해 주세요.']);
        }

        var result = response.v2; // 검색 결과의 컨테이너
        // $log.log(result);
        var meta = result.meta;
        // $log.log("Got result. Address count="+result.meta.totalCount);
        self.models.naverAddressList = result.addresses; // 검색 결과의 배열

        if(self.models.naverAddressList.length < 20) {
            for(var i=self.models.naverAddressList.length; i<20; i++) {
                self.models.naverAddressList.push({});
            }
        }

        self.models.naverAddressSelected = {};

        $log.log("Counting address array="+self.models.naverAddressList.length);
        // do Something
    };

    // 네이버 주소검색하기 (주소 -> 좌표 검색(geocode) API 호출)
    this.queryNaverGeocode = function(callback) {
        if(self.models.naverAddressString)
            naver.maps.Service.geocode({query: self.models.naverAddressString}, function(status, response) {
                self.onResultNaverGeocodeQuery(status, response);
                if (status == naver.maps.Service.Status.OK) {
                    callback(response.v2);
                }
            });
    };

     */
}



