// UserInfoList.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton, TextField } from '@mui/material';
import {useReactiveVar} from '@apollo/client';
import {userInfoRepo, NoUser} from '../../model/CvoModel';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlawedList from '../message/FlawedList';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import styled from '@emotion/styled';
import { PersonAdd } from '@mui/icons-material';
const LocalTitle = '사용자정보';

const MainListBox = styled(Box)({height:'100%', display:'flex', flexDirection:'column'});
const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

const TableColumns = [
	{ id: 'userId', label: '아이디', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userName', label: '이름', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'mobile', label: '휴대전화', width: 110, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'telno', label: '연락처', width: 110, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userStateValue', label: '상태', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'className', label: '권한', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'loginTime', label: '로그인', width: 180, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'email', label: '메일', width: 200, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function UserInfoList({
	maxHeight,
	maxWidth,
	selected,
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
    onRequestAsIs,
    responseList,
}) {
    const [search, setSearch] = useState('');
    const sessionInfo = useReactiveVar(userInfoRepo);
    const {loading:loadingList, error:errorOfList, data:dataList} = responseList;
    const clientSize = useClientSize();



    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_ASIS: if(onRequestAsIs) onRequestAsIs(row); break;
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
		if(onClickItem) onClickItem(item);
    };


    // Handling list event
    if(ValueUtil.flawedListResponse(responseList))
        return <FlawedList title={LocalTitle} gqlResponse={responseList} />;

    const Tools = [
        {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize='small' color='primary' />},
        {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize='small' color='warning' />}
    ];

    if(sessionInfo.user.etrace) {
        Tools.push({id:AppWord.CMD_ASIS, icon:<VerifiedUserIcon fontSize='small' color='secondary' />});
    }

    return(
        <MainListBox>
            <HeaderBox>
                <ButtonBox>
                    <Button
                        onClick={onRequestAdd}
                        variant='contained' color='primary'>
                        <PersonAdd fontSize='small' />
                        사용자 추가
                    </Button>
                </ButtonBox>
                <TextField id="search_user_list" onChange={onChangeSearch}
                    value={search}
                    label="검색" size='small'/>
            </HeaderBox>
            <FlexyTable
                name="user_info_list"
                uniqueKey="userId"
                columns={TableColumns}
                records={dataList.userInfoList}
                search={search}
                tools={Tools}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </MainListBox>
    );

}
