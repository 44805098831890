// NotifyBoardList.js
import React from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import CloseIcon from '@mui/icons-material/Close';
import { AppPalette } from '../../model/AppConst';
import useClientSize from '../hook/useClientSize';

const InfoTitle = styled(DialogTitle)({
    backgroundColor: '#007', // AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const InfoContentRow = styled(Box)({
    padding: 0, display:'flex'
});

const ContentStyle = {flexGrow:1, overflow:'auto', borderRadius:5, border: AppPalette.BorderCCC, height:300, padding:10};
const TextBox = styled(Box)({...ContentStyle, whiteSpace:'pre-line'});
const MarkdownBox = styled(Box)(ContentStyle);

export default function NotifyBoardItem({
    item,
    open,
    onClose,
    // cvoFiles,
}) {
    const clientSize = useClientSize();
	// const sessionInfo = useReactiveVar(userInfoRepo);

    const onClickClose = () => {
        // setAdminMode(false);
        onClose();
    };

    const BoxWidth = clientSize.width * (clientSize.width >= 1000 ? 0.84 : 0.94);
    
    return (
        <Dialog open={open} onClose={onClickClose} maxWidth="lg">
            <InfoTitle>
                {item.title}

                <IconButton
                    aria-label="close-notify-board-info"
                    onClick={onClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </InfoTitle>
            <DialogContent>
                <InfoContentRow style={{width:BoxWidth, marginTop:10}}>
                    {
                        item.textFormat==='markdown'
                        ?
                        <MarkdownBox>
                            <ReactMarkdown>{item.contents}</ReactMarkdown>
                        </MarkdownBox>
                        :
                        <TextBox>{item.contents}</TextBox>
                    }
                </InfoContentRow>
            </DialogContent>
        </Dialog>
    );
}

