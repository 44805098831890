// SessionBox.js
import React, {useState, useEffect} from 'react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'; // admin
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'; // login AS
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // user.
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import {useLazyQuery, useMutation} from '@apollo/client';
import { useInterval } from 'react-use';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Button, Grow, Link, IconButton } from '@mui/material';
import { AppObject, AppPalette, AppWord, CvoMsg } from '../../model/AppConst';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {useReactiveVar} from '@apollo/client';
import { NoUser, userInfoRepo, tableShapeConfRepo, openTableShapeConfRepo } from '../../model/CvoModel';
import Util from '../../model/Util';
import { LOG_OUT, LOG_IN_AS } from './SessionGql';
import { BATCH_USER_TCOL_CONF21 } from '../user_tcol_conf21/UserTcolConf21Gql';
import ValueUtil from '../../model/ValueUtil';
import UserSessionInfo from '../common/UserSessionInfo';

const InfoBgColor = '#452670';
const SessionContainer = styled(Box)({
    position:"fixed", top: 5, right: 5, display:'flex', justifyContent:AppWord.END, alignItems:AppWord.CENTER,
    color: 'white'
});

const UserInfoWrapper = styled(Box)({backgroundColor:InfoBgColor, color:"white"});

const UserIconBox = styled(Box)({
    display:'inline',
    textAlign:'center',
    padding: 3,
    cursor:'pointer'
});

const UserInfoBox = styled(Box)({
    display:'inline-block',
    minWidth:100,
    textAlign:'center',
    padding: 5,
    color:"white",
});

const NameBox = styled(Box)({
    display:'inline-block', padding:5
});

const UserAsBox = styled(Box)({
    display: 'block',
    backgroundColor:'#553680',
    fontSize: '0.8em', color:'#ff7',
    borderRadius:5, padding:4, marginRight:5, marginTop:2
});

const TableConfChangedForAdmin = styled(Box)({
    float:'right', fontSize:'0.1rem', backgroundColor:'yellowgreen', width:4, height:4, borderRadius:4,
    overflow:'hidden'
});

const WaitBeforeSaveTableConf = 30000; // mil-sec

export default function SessionBox({
    onClickEdit
}) {
    const [logOut, {loading, error, data: resultOfLogOut}] = useLazyQuery(LOG_OUT);
    const [showUserInfoBox, setShowUserInfoBox] = useState(false);
    const [tableChanged, setTableChanged] = useState({changed:0, count:0}); // table column configuration changed
    const sessionInfo = useReactiveVar(userInfoRepo);
    const tableShapeConf = useReactiveVar(tableShapeConfRepo);
    const openTableShapeConf = useReactiveVar(openTableShapeConfRepo);
    const [logInAsUser, responseUserAs] = useLazyQuery(LOG_IN_AS, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteLoginAsUser(data, option)},
        onError: (error) => {
            Util.bubbleSnack( "타 사용자로 가장 종료하기 오류" );
        }
    });
    // ##### GraphQL Mutation.  ###
    const [batchUserTcolConf21, responseBatch] = useMutation( BATCH_USER_TCOL_CONF21, {
		onCompleted: (data, option) => onCompleteBatch(data, option), 
		onError: (error) => {
            sessionInfo.ifLocal(()=>{console.log('Error saving table configuration.');});
        } // 그냥 레포에 저장하고 나중에 처리하게 한다. (true for changed)
	} );

    useEffect(() => {
        if(resultOfLogOut && resultOfLogOut.logout) {
            //userInfoRepo({...data.logout, signedIn:false, sessionChecked: true});
            userInfoRepo(new UserSessionInfo(true, false, resultOfLogOut.logout));
        }
    }, [resultOfLogOut]);

    useEffect(()=>{
        let count=0;
        for(const name of Object.keys(tableShapeConf)) {
            if(tableShapeConf[name].changed) count++;
        }
        setTableChanged({count:count, changed: new Date().getTime()});
    }, [tableShapeConf]);

    const ticker = () => {
        if(
            tableChanged.count > 0
            && ((new Date().getTime()) - tableChanged.changed) > WaitBeforeSaveTableConf
            && !openTableShapeConf // ColumnConfigDialog 참조.
        ) {
            saveUnchangedTableConfig();
        }
    };

    useInterval(ticker, 5000);

    const isEtrace = sessionInfo.isEtrace();
    const isSysAdmin = sessionInfo.isSystemAdmin();

    const onClickLogout = (e) => {
        logOut();
    };

    // admin
    const onCompleteLoginAsUser = (data, option) => {
        if(data.loginAs) {
            if(data.loginAs.userAs.msg===CvoMsg.OK) {
                //userInfoRepo({...data.loginAs, signedIn: true, sessionChecked: true});
                userInfoRepo(new UserSessionInfo(true, true, data.loginAs));
                // inform AppMain.js
                Util.bubbleSnack("타 사용자로 로그인 종료함");
            }
        }
    };

    const onClickUnlinkUserAs = () => {
        // 내 아이디를 사용하면 사용자 가장하기가 끝나게 됨.
        setShowUserInfoBox(false);
        logInAsUser({variables: {userId: sessionInfo.user.userId}});
    };

    const onCompleteBatch = (data, clientOption) => {
        if(data.userTcolConf21Batch.ok) {
            sessionInfo.ifLocal(()=>console.log('Saved table column configurations.'));
            updateAllRepoAsSaved();
        }
        else {
            sessionInfo.ifLocal(()=>console.log('Error: '+data.userTcolConf21Batch.message));
        }
    };

    // 과거에 칼럼 너비만 수정한 것들이 있다면 저장 후 종료한다.
    const saveUnchangedTableConfig = () => {
        if(!sessionInfo.isDisguised() && sessionInfo.signedIn) {
            const inputArray = Object.values(tableShapeConf).filter((conf)=>conf.changed)
                .map((conf)=>{
                    const csv = conf.columns.map((c)=>{
                        return c.column + ':' + c.width
                    }).join(',');
                    return {viewName: conf.table, colsCsv: csv};
                });
            if(inputArray.length > 0) {
                batchUserTcolConf21({variables: {userTcolConf21s:inputArray}});
            }
        }
    };

    const updateAllRepoAsSaved = () => {
        const ymd = ValueUtil.getYmdText();
        const newRepoData = {};
        for(const name of Object.keys(tableShapeConf)) {
            const conf = tableShapeConf[name];
            if(conf.changed) {
                conf.changed = false;
                conf.date = ymd;
            }
            newRepoData[name] = conf;
        }
        tableShapeConfRepo(newRepoData);
    };

    if(ValueUtil.hasAnyAuthError(
        responseUserAs, responseBatch
    )) userInfoRepo(NoUser);

    if(loading) {
        return(<UserIconBox><HourglassEmptyIcon fontSize='large' /></UserIconBox>);
    }
    if(error) {
        // return <ErrorBox graphQLErrors={error.graphQLErrors} />
        Util.bubbleSnack("로그아웃 중 오류발생", {duration:6000});
        return(
            <UserIconBox><ReportProblemIcon fontSize='large' /></UserIconBox>
        );
    }

    const renderIconForClass = () => {
        return (
            isEtrace
            ?
            (
                sessionInfo.isDisguised()
                ?
                <SupervisorAccountIcon fontSize={AppWord.LARGE} style={{verticalAlign: "top"}} />
                :
                <AdminPanelSettingsIcon fontSize={AppWord.LARGE} style={{verticalAlign: "top"}} />
            )
            :
            <AccountCircleIcon fontSize={AppWord.LARGE} style={{verticalAlign: "top"}} />
        );
    };

    const renderUserInfo = () => {
        return(
            <Grow in={showUserInfoBox}>
                <UserInfoBox>
                        <NameBox>
                            <Link onClick={onClickEdit}
                                style={{color:'white', cursor:'pointer'}}
                            >
                                <span style={{margin:5}}>{sessionInfo.user.userName}</span>
                            </Link>
                            {
                                sessionInfo.user.userId !== sessionInfo.userAs.userId
                                ?
                                <UserAsBox>
                                    <IconButton size={AppWord.SMALL}
                                        color={AppPalette.WarnColor}
                                        onClick={onClickUnlinkUserAs}
                                    >
                                        <LinkOffIcon fontSize={AppWord.SMALL} />
                                    </IconButton>
                                    {sessionInfo.userAs.userName}@{sessionInfo.userAs.custName}
                                </UserAsBox>
                                :
                                null
                            }
                        </NameBox>
                        <Button
                            onClick={onClickLogout} 
                            style={{color:"white", backgroundColor:"#5b3d86"}}
                            variant='outlined'>로그아웃</Button>
                </UserInfoBox>
            </Grow>
        );
    };

    const wrapStyle
    = showUserInfoBox
    ? {padding:3, borderRadius:5}
    : {padding:10, borderRadius:30}

    return(
        <SessionContainer>
            <UserInfoWrapper onMouseLeave={(e)=>setShowUserInfoBox(false)}
                style={wrapStyle}
            >
                <UserIconBox onClick={(e)=>setShowUserInfoBox(true)}>
                    {renderIconForClass()}
                </UserIconBox>
                {showUserInfoBox ? renderUserInfo() : null}
            </UserInfoWrapper>
            {
                tableChanged.count > 0 && isSysAdmin ?
                <TableConfChangedForAdmin>&nbsp;</TableConfChangedForAdmin>
                : null
            }
            {/* 
                tableChanged.count > 0 ?
                <IconButton
                    size='large'
                    color={AppPalette.InheritColor}
                    onClick={onClickSaveTableConfig}
                    style={{backgroundColor:InfoBgColor, marginLeft:5}}
                >
                    <DisplaySettingsIcon />
                </IconButton>
                : null */
            }
        </SessionContainer>
    );

    /*
    return(
        <SessionContainer>
            {renderInfoBox()}
            {renderIconBox()}
        </SessionContainer>
    );



    if(showUserInfoBox)
        return (
            <UserInfoBox onMouseLeave={(e)=>setShowUserInfoBox(false)}>
                <AccountCircleIcon fontSize="large" />
                <Box> {sessionInfo.user.userName}</Box>
                <Button onClick={onClickLogout} fullWidth variant='outlined'>로그아웃</Button>
            </UserInfoBox>
        );
    else {
        return(
            <UserIconBox onMouseEnter={(e)=>setShowUserInfoBox(true)}>
                <AccountCircleIcon fontSize="large" />
            </UserIconBox>
        );
    }*/
}
