// NaverSearch.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import FlexyTable from '../common/flexytable/FlexyTable';
import {naverSearchResultRepo, naverSearchRepo} from '../../model/CvoModel';
import { AppNumber, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { useReactiveVar } from '@apollo/client';

const TheContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column', //backgroundColor:'yellow'
});
const TableBox = styled(Box)({flexGrow:1, display:'flex',});

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});

const ButtonBox = styled(Box)({flexGrow:1, textAlign:'right'});

const TableColumns = [
    { id: 'roadAddress', label: '도로주소', width: 140, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'jibunAddress', label: '지번주소', width: 140, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'englishAddress', label: '영문주소', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

const NaverCmd = {SetStart:'setStart', SetEnd:'setEnd'};

export default function NaverSearch({
    mapShell,
}) {
    // const [records, setRecords] = useState([]);
    const records = useReactiveVar(naverSearchResultRepo);
    //const [search, setSearch] = useState('');
    const search = useReactiveVar(naverSearchRepo);


    const onChangeSearch = (event) => {
        naverSearchRepo(event.target.value);
    };

    const onClickSearchNaver = () => {
        if(ValueUtil.realLen(search) > 1) {
            mapShell.queryNaverGeocode(search, (status, response)=>{
                if(mapShell.isNaverResponseOk(status)) {
                    let num = 0;
                    naverSearchResultRepo(response.v2.addresses.map((addr)=>{
                        addr.index = ++num;
                        addr.lat = parseFloat(addr.y);
                        addr.lon = parseFloat(addr.x);
                        return addr;
                    }));
                }
                else console.log("오류 " + status);
            });
        }
        else console.log("Not less than 2 letters");
    };

    const onClickAddress = (row) => {
        mapShell.setCenter(row.lat, row.lon);
    };

    const InputID = "naver-search-poi-list";
    return (
        <TheContainer>
            <HeaderBox>
                <FormControl variant='outlined' size={AppWord.SMALL}>
                    <InputLabel htmlFor={InputID} style={{backgroundColor:'white'}}>NAVER 주소검색</InputLabel>
                    <OutlinedInput id={InputID} onChange={onChangeSearch}
                        value={search}
                        size={AppWord.SMALL}
                        onKeyUp={(e) => {if(e.key==='Enter') onClickSearchNaver()}}
                        endAdornment={
                            Boolean(search) ?
                            <InputAdornment position={AppWord.END}>
                                <IconButton
                                onClick={()=>{naverSearchRepo('')}}
                                size={AppWord.SMALL}
                                edge={AppWord.END}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                            :
                            null
                        }
                    />
                </FormControl>
                <ButtonBox>
                    <Button variant={AppPalette.VariantContained} onClick={onClickSearchNaver}>
                        <SearchIcon fontSize={AppWord.SMALL} />
                        &nbsp; 검색
                        </Button>

                </ButtonBox>
            </HeaderBox>
            <TableBox>
                <FlexyTable
                    name="naver_search"
                    uniqueKey="index"
                    columns={TableColumns}
                    records={Boolean(records) ? records : []}
                    tools={[]}
                    onClickOneRow={onClickAddress}
                />
            </TableBox>
        </TheContainer>
    )
}
