import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

// in package.json ?
  // "proxy": "http://118.37.82.109:3437/",
/* const link = createHttpLink({
  uri: "/graphql",
  credentials: 'same-origin'
}) */
const gqlClient = new ApolloClient({
  cache: new InMemoryCache(), // Add field policies here.
  uri: "/graphql",
  //link
});

// You define your application's field policies in a map that you provide to the constructor of Apollo Client's InMemoryCache.
// Each field policy is a child of a particular type policy (much like the corresponding field is a child of a particular type).
/*
const cache = new InMemoryCache({
  typePolicies: { // Type policy map
    Product: {
      fields: { // Field policy map for the Product type
        isInCart: { // Field policy for the isInCart field
          read(_, { variables }) { // The read function for the isInCart field
            return localStorage.getItem('CART').includes(
              variables.productId
            );
          }
        }
      }
    }
  }
});

*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={gqlClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
