// useClientSize.js
import { useState, useEffect } from "react";
import { AppNumber } from "../../model/AppConst";

// Hook
function useClientSize() {
    const [windowSize, setWindowSize] = useState({
        ...AppNumber,
        width: window.innerWidth, height: window.innerHeight,
        //dataContainerHeight: undefined,
        viewTabHeight: AppNumber.ViewTabHeight,
        dataAreaHeight: window.innerHeight - AppNumber.HeaderHeight - AppNumber.ViewTabHeight, // - AppNumber.dataContainerPadding * 2,
        dataAreaWidth: window.innerWidth //- AppNumber.dataContainerPadding * 2
    });

    useEffect(()=>{
        function handleResize() {
            setWindowSize({
                ...AppNumber,
                width:window.innerWidth,
                height:window.innerHeight,
                //dataContainerHeight: window.innerHeight - AppNumber.HeaderHeight,
                viewTabHeight: AppNumber.ViewTabHeight,
                dataAreaHeight: window.innerHeight - AppNumber.HeaderHeight - AppNumber.ViewTabHeight, // - AppNumber.dataContainerPadding * 2,
                dataAreaWidth: window.innerWidth //- AppNumber.dataContainerPadding * 2
            });
        }

        window.addEventListener("resize", handleResize);
        // call first time.
        handleResize();

        // Remove event listener on cleanup
        return() => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

export default useClientSize;