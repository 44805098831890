// VehInfoLogList.js
import React from 'react';
import FlexyTable from '../common/flexytable/FlexyTable';
import { TableColumnType } from '../../model/AppConst';
const TableColumns = [
	//{ id: 'vehId', label: Dict.vehId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'chTime', label: '변경시각', width: 160, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'devId', label: Dict.devId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'termId', label: '단말기', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'changeReason', label: '변경사유', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'custId', label: Dict.custId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'oldVehPlates', label: '이전차량번호', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'newnameYn', label: '신규번호', width: 120, show: true, option:{align: 'inherit', type:TableColumnType.CHAR, ynBoolean: ['신규이름','기존이름']} },
    //{ id: 'isLatestYn', label: Dict.isLatestYn.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'regUserId', label: Dict.regUserId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} }
    { id: 'regUserName', label: '작업자', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function VehInfoLogList({
    records,
}) {

    return(
        <FlexyTable
            name="veh_info_log_list"
            uniqueKey="dataKey"
            columns={TableColumns}
            records={records}
            tools={[]}
        />
    );

}