// VehGroup.js
import React, {useState, useEffect} from 'react';
import { Box } from '@mui/system';
import { styled  } from '@mui/material/styles';
import { Button, Tabs, Tab, AppBar } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import HelpTopic from '../common/HelpTopic';
import {
    LIST_VEH_GROUP, GET_VEH_GROUP, ADD_VEH_GROUP, EDIT_VEH_GROUP, REMOVE_VEH_GROUP, VehGroupDictionary
} from './VehGroupGql';
//import HandshakeIcon from '@mui/icons-material/Handshake';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import ExpanTree from '../common/ExpanTree/ExpanTree';
import VehGroupInput from './VehGroupInput';
import GroupVehAlloc from '../group_veh_alloc/GroupVehAlloc';
import UserOperAuth from '../user_oper_auth/UserOperAuth';
import CustAgreeListGiving from '../cust_agree/CustAgreeListGiving';
import VehGroupNode from '../../model/VehGroupNode';
import useClientSize from '../hook/useClientSize';

const BorderCcc = '1px solid #cccccc';

const dcPad = AppNumber.dataContainerPadding;
const GroupWorkBox = styled(Box)({position:"absolute", top:dcPad, bottom:dcPad, left:dcPad, right:dcPad, display:"flex" });

const TitleAndDataBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

const TitleBox = styled(Box)({fontWeight:'bold', padding:10, fontSize: '1.1rem'});

const VehGroupsBox = styled(Box)({
    flexGrow: 1,
    overflow: 'auto',
    border: BorderCcc,
    padding: 15,
    borderRadius: 10,
    minWidth: 180
});

const WorkBox = styled(Box)({
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 10,
    display:'flex',
    flexDirection: 'column'
});

const WorkButtonBox = styled(Box)({
    borderBottom: BorderCcc,
    paddingBottom: 10,
    marginBottom: 20,
    textAlign: 'center',
});


const LaButton = styled(Button)({marginRight: 10});

const InfoBox = styled(Box)({padding: 10, fontSize: '0.9rem', color:'#555555'});


const HelpBox = styled(Box)({
    overflow: 'auto',
    fontSize: '0.93rem',
    border: BorderCcc,
    padding: 15,
    borderRadius: 10,
    minWidth: 180,
    maxWidth: 350,
});

const CustAgreeMarkIcon = <AddLocationAltIcon fontSize='small' color='primary' style={{verticalAlign:'bottom'}}/>;

const ErrorTitle =VehGroupDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(VehGroupDictionary.inputMap, true);

const GroupWork = {Alloc:1, Authen:2, Agree:3};

export default function VehGroup() {
    const [vehGroupTreeData, setVehGroupTreeData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [working, setWorking] = useState(GroupWork.Alloc);
    const [vehicleCount, setVehicleCount] = useState(0);
    const [editorState, setEditorState] = useState(null);
    const [isNewTop, setIsNewTop] = useState(false);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize(); // Scroll the tree
    // const responseList = useQuery(GET_VEH_INFO_LIST, {fetchPolicy: "no-cache"});
    const [getVehGroupList, responseList] = useLazyQuery(LIST_VEH_GROUP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)}, 
		onError: (error) => {setResponseAlert({open:true, error: error, title: "전체 그룹 조회 오류"})}
    });
    const [getVehGroupItemToEdit, responseItemToEdit] = useLazyQuery(GET_VEH_GROUP, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    const [addVehGroup, responseAdd] = useMutation( ADD_VEH_GROUP, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editVehGroup, responseEdit] = useMutation( EDIT_VEH_GROUP, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeVehGroup, responseRemove] = useMutation( REMOVE_VEH_GROUP, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});
    // const [responseList, setResponseList] = useState({data:[]});

    useEffect(() => {
        getVehGroupList();
    }, []);

    // <<<<<<<<<<<<< Response handlers <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.vehGroupList) {
            setVehGroupTreeData(VehGroupNode.makeTree(data.vehGroupList));
            setVehicleCount(0);
            setSelected(null);
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.vehGroupAdd.ok) {
            setEditorState(null);
            getVehGroupList(); // getVehGroupListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.vehGroupAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.vehGroupEdit.ok) {
            setEditorState(null);
            getVehGroupList(); // getVehGroupListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.vehGroupEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.vehGroupRemove.ok) getVehGroupList();
        else setResponseAlert({open:true, resultData: data.vehGroupRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.vehGroupItem) {
            const item = {};
            for(const field of EditFields) item[field] = data.vehGroupItem[field];
            setEditorState({item: item});
        }
    };


    // >>>>>>>>> callbacks >>>>>>>>>>>>>
    const onGroupClicked = (group) => {
        setVehicleCount(0);
        setSelected(group);
    };

    const onInformVehicleCount = (groupId, count) => {
        if(groupId && selected) {
            if(selected.groupId === groupId) setVehicleCount(count);
        }
    };

    const isRemovable = () => {
        if(selected) {
            if(selected.children.length===0 && !Boolean(selected.targetCustIdCsv) && vehicleCount===0) return true;
        }
        return false;
    };

    const isLeafNode = () => {
        if(selected) {
            if(selected.children.length===0) return true;
        }
        return false;
    };

    // Handler - Submit for mutation fired by VehGroupInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {vehGroup: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editVehGroup(param);
        else addVehGroup(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };

    const onClickAdd = (isTop) => {
        if(responseAdd) responseAdd.reset();
        setIsNewTop(isTop);
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onClickEdit = () => {
        if(selected) {
            if(responseEdit) responseEdit.reset();
            getVehGroupItemToEdit({variables: {groupId: selected.groupId}});
        }
        // setEditorState({item: item});
    };

    const onClickRemove = () => {

        setPromptToConfirm({
            data: selected,
            title: '차량그룹 삭제',
            messages: [
                '선택된 ' + selected.groupName + ' 차량그룹을 삭제하시겠습니까?',
                '해당 차량그룹 및 부수하는 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{vehGroup:{groupId: data.groupId}}};
                    removeVehGroup(param);
                }
            }
        });
    };

    const onRequestSetCustAgree = (groupId, custIdCsv) => {
        const tree = [...vehGroupTreeData];
        VehGroupNode.addTargetCustIdCsv(tree, groupId, custIdCsv);
        setVehGroupTreeData(tree);
    };

    const onRequestReloadTree = () => {
        getVehGroupList();
    };

    if(ValueUtil.hasAnyAuthError(
        responseList,
        responseItemToEdit,
        responseAdd,
        responseEdit,
        responseRemove
    )) userInfoRepo(NoUser);


    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };


    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <VehGroupInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
            :
            <VehGroupInput
                open={Boolean(editorState)}
                isTop={isNewTop}
                isEdit={false}
                groupSelected={selected}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

    const renderAAA = () => {
        if(selected) {
            if(selected.children.length===0) {
                switch(working) {
                    case GroupWork.Agree:
                        return(<CustAgreeListGiving group={selected}
                                onRequestSetCustAgree={onRequestSetCustAgree}
                                onRequestReloadGroupTree={onRequestReloadTree}/>);
                    case GroupWork.Authen:
                        return (<UserOperAuth
                            groupId={selected.groupId} groupName={selected.groupName} />);
                    default:
                        return(<GroupVehAlloc
                            groupId={selected.groupId} groupName={selected.groupName}
                            informHowmanyInGroup={onInformVehicleCount} />);
                }
            }
        }
        return(
            <InfoBox>
                왼편의 그룹을 선택하여 작업하거나, 위 활성화된 버튼을 눌러 새로운 그룹을 추가하세요.
                그룹을 수정하거나 삭제하려면 먼저 선택해야 합니다.
            </InfoBox>
        );
    };

    return (
        <GroupWorkBox>
            <TitleAndDataBox width={250}>
                <TitleBox>전체 차량그룹</TitleBox>
                <VehGroupsBox>
                    <ExpanTree
                        data={vehGroupTreeData}
                        onLeafClicked={onGroupClicked}
                        markOption={
                            {
                                keyToCheck: 'targetCustIdCsv',
                                marker: CustAgreeMarkIcon
                            }
                        }
                    />
                </VehGroupsBox>
            </TitleAndDataBox>
            <WorkBox>
                <WorkButtonBox>
                    <LaButton variant='contained'
                        onClick={()=>{onClickAdd(true)}}
                    >
                        최상위 그룹 추가
                    </LaButton>
                    <LaButton variant='contained'
                        disabled={!Boolean(selected)}
                        onClick={()=>{onClickAdd(false)}}
                    >
                        하위에 그룹 추가
                    </LaButton>
                    <LaButton variant='contained'
                        disabled={!Boolean(selected)}
                        onClick={onClickEdit}
                    >
                        수정
                    </LaButton>
                    <LaButton disabled={!isRemovable()} variant='contained' color='secondary'
                        onClick={onClickRemove}
                    >
                        삭제
                    </LaButton>
                </WorkButtonBox>
                {
                    isLeafNode()
                    ?
                    <AppBar position="static">
                        <Tabs value={working} onChange={(e,v) => {setWorking(v)}}
                            variant='fullWidth' textColor="inherit"
                        >
                            <Tab value={GroupWork.Alloc} label="차량배정" />
                            <Tab value={GroupWork.Authen} label="관제권한관리" />
                            <Tab value={GroupWork.Agree} label="협력관리" />
                        </Tabs>
                    </AppBar>
                    :
                    null
                }
                {renderAAA()}
            </WorkBox>
            <HelpBox>
                {
                    working === GroupWork.Alloc ?
                    <HelpTopic
                        title="그룹에 차량 배정"
                        content={
                            <div>
                                <p>그룹에 차량을 배정하거나 배정된 차량을 제외시키려면 각 테이블의 화살표 아이콘을 클릭하면 됩니다.</p>
                                <p>배정이나 배정 제외는 사용자의 의견을 묻지 않고 즉시 반영합니다.</p>
                                <p>그룹에의 배정 또는 제외는 출발, 도착 감지와 같은 다른 기능에 영향을 미칠 수 있으므로 업무상황을
                                    충분히 고려하시기 바랍니다.
                                </p>
                            </div>
                        }
                    />
                    : null
                }
                <HelpTopic
                    title="차량그룹 일반사항"
                    content={
                        <ul>
                            <li>차량그룹은 업무와 관련하여 차량을 구분합니다.</li>
                            <li>차량그룹은 트리 구조입니다.</li>
                            <li>차량은 최종단(하위에 더 이상의 그룹이 없는) 그룹에 포함될 수 있습니다.</li>
                            <li>하나의 차량은 여러 그룹에 포함될 수 있습니다.</li>
                            <li>최종단 그룹의 하위에 다시 그룹을 추가할 경우 그 그룹에 속한 차량들은 새로 추가된 차량의 그룹으로 변경되고 그 그룹이
                                협력대상이었다면 협력그룹 정보도 새로운 그룹으로 대체됩니다.</li>
                            <li>그룹을 삭제할 경우 해당 그룹에 속한 차량이 있으면 삭제하지 않고 오류를 표시합니다.</li>
                            <li>그룹이 타 업체에 협력대상으로 제공될 경우 삭제할 수 없습니다.</li>
                        </ul>
                    }
                />
                <HelpTopic
                    title="협력 제공된 그룹"
                    content={
                        <ul>
                            <li>우리 차량을 그룹단위로 타사에서 관제할 수 있도록 하는 것을 협력그룹이라고 합니다.</li>
                            <li>협력대상으로 제공된 차량은 그룹명 오른쪽에 {CustAgreeMarkIcon} 표시가 나타납니다.</li>
                            <li>새로이 협력대상으로 제공하려면 그룹을 선택하고 협력관련 버튼을 누릅니다.</li>
                        </ul>
                    }
                />
            </HelpBox>
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </GroupWorkBox>
    )
}

/*
Now, I have two arrays which are of same type like {id:1, name:'Tom'}. Two MUI Box components show those arrays each. 
I want to make a drag-and-drop interface to move data from one array to the other. 
Can you make a react component for this?

import React, { useState } from "react";
import { Box } from "@mui/material";

const DragAndDrop = () => {
  const initialArray1 = [
    { id: 1, name: "Tom" },
    { id: 2, name: "Jerry" },
    { id: 3, name: "Spike" },
  ];

  const initialArray2 = [];

  const [array1, setArray1] = useState(initialArray1);
  const [array2, setArray2] = useState(initialArray2);

  const handleDragStart = (event, item) => {
    event.dataTransfer.setData("text/plain", JSON.stringify(item));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, targetArray) => {
    const item = JSON.parse(event.dataTransfer.getData("text/plain"));
    const updatedArray = [...targetArray, item];
    if (targetArray === array1) {
      setArray1(updatedArray);
    } else if (targetArray === array2) {
      setArray2(updatedArray);
    }
  };

  return (
    <div>
      <Box
        sx={{ width: 200, height: 200, border: "1px solid", p: 2 }}
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e, array1)}
      >
        <h2>Array 1</h2>
        {array1.map((item) => (
          <Box
            key={item.id}
            draggable
            onDragStart={(e) => handleDragStart(e, item)}
            sx={{ border: "1px solid", p: 1, mb: 1 }}
          >
            {item.name}
          </Box>
        ))}
      </Box>

      <Box
        sx={{ width: 200, height: 200, border: "1px solid", p: 2 }}
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e, array2)}
      >
        <h2>Array 2</h2>
        {array2.map((item) => (
          <Box
            key={item.id}
            draggable
            onDragStart={(e) => handleDragStart(e, item)}
            sx={{ border: "1px solid", p: 1, mb: 1 }}
          >
            {item.name}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default DragAndDrop;

//
In this component, we initialize two arrays array1 and array2 as the source and destination arrays. 
The handleDragStart function sets the dragged item's data using event.dataTransfer.setData. 
The handleDragOver function prevents the default behavior of dragover to enable dropping. 
The handleDrop function retrieves the dragged item's data 
using event.dataTransfer.getData and updates the target array by appending the item. 
The updated arrays are stored in state using setArray1 and setArray2.

The component renders two Box components, each representing an array. 
The items in the arrays are rendered as draggable Box components. 
The onDragStart event is attached to each item to initiate the drag operation. 
The onDragOver event is attached to the target Box components to allow dropping, 
and the `onDropevent is attached to handle the drop event and call thehandleDrop` function accordingly.

By dragging and dropping the items between the two Box components, you can move the data from one array to the other.

*/