// download_info/DownloadFileGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";
import { TableColumnType } from "../../model/AppConst";

export const DownloadFileDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        msgSeq : { required: true, toAdd: true, toEdit: true, },
        fileNid : { required: true, toAdd: true, toEdit: true, },
        title : {
            id: 'title', label: '파일 제목', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '50자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,200)},
        },
    },
    filesColumns : [
        { id: 'title', label: '파일 제목', width: 300, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
        { id: 'fileSize', label: 'File Size', width: 120, show: true, option:{align: 'center', print: (r,i,v) => {return ValueUtil.getSizeInText(v)}} },
    ],
    errorTitle: {
        Add: '다운로드 파일 정보 생성 오류',
        Edit: '다운로드 파일 정보 수정 오류',
        Remove: '다운로드 파일 정보 삭제 오류',
        List: '다운로드 파일 목록 조회 오류',
    }
};

const DOWNLOAD_FILE_LIST_PART = gql`
fragment DownloadFileListPart on DownloadFile {
    msgSeq
    fileNid
    title
    filePath
    driveLink
    fileSize
    memo
    regUserId
    regTime
}
`;

export const LIST_DOWNLOAD_FILE = gql`
${DOWNLOAD_FILE_LIST_PART}
query ListDownloadFile($msgSeq:Int) {
    downloadFileList(msgSeq:$msgSeq) {
        ...DownloadFileListPart
    }
}
`;

export const GET_DOWNLOAD_FILE = gql`
${DOWNLOAD_FILE_LIST_PART}
query GetDownloadFile($msgSeq:Int, $fileNid:Int) {
    downloadFileItem(msgSeq:$msgSeq, fileNid:$fileNid) {
        ...DownloadFileListPart
    }
}
`;

export const ADD_DOWNLOAD_FILE = gql`
${DOWNLOAD_FILE_LIST_PART}
mutation AddDownloadFile($downloadFile:InputDownloadFile!) {
    downloadFileAdd(downloadFile: $downloadFile) {
        ok
        message
        list {
            ...DownloadFileListPart
        }
    }
}
`;

export const EDIT_DOWNLOAD_FILE = gql`
${DOWNLOAD_FILE_LIST_PART}
mutation EditDownloadFile($downloadFile:InputDownloadFile!) {
    downloadFileEdit(downloadFile: $downloadFile) {
        ok
        message
        list {
            ...DownloadFileListPart
        }
    }
}
`;

export const REMOVE_DOWNLOAD_FILE = gql`
${DOWNLOAD_FILE_LIST_PART}
mutation RemoveDownloadFile($downloadFile:InputDownloadFileRemove!) {
    downloadFileRemove(downloadFile: $downloadFile) {
        ok
        message
        list {
            ...DownloadFileListPart
        }
    }
}
`;
