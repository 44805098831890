//FlexyTableBubble.js
import React from 'react';
import { FlexySize, BubbleRowDiv, BubbleColumnDiv, BubbleColumnPair, BubbleBoxForFlexy } from './FlexyTableStyled';

export default function FlexyTableBubble({
    columns,
    data,
    maxWidth,
    maxHeight,
    mouseEvent
}) {
    var pairCnt = Math.ceil( ( FlexySize.BubbleRowHeight * columns.length ) / (maxHeight - FlexySize.CommonPadding * 4) );
    if(pairCnt > 3) pairCnt = 3;

    const rowCnt = Math.ceil(columns.length / pairCnt);
    const pairs = [];
    for(var i=0;i<pairCnt;i++) {
        // const key = 'A'+i;
        pairs.push( columns.slice(i * rowCnt, (i+1) * rowCnt) );
    }

    const heightAll = rowCnt * FlexySize.BubbleRowHeight + 30;
    let yBub = mouseEvent.clientY - 30;
    const yEnd = yBub + heightAll;
    if(yEnd > window.innerHeight) {
        yBub = window.innerHeight - heightAll;
    }

    // top={FlexySize.HeaderRowHeight+5}
    return(
        <BubbleBoxForFlexy
            top={yBub}
            left={mouseEvent.clientX + 30}
            maxWidth={maxWidth} maxHeight={maxHeight}>
            {
                pairs.map((pair,idx)=>{
                    return(
                        <BubbleColumnPair key={idx}>
                            <BubbleColumnDiv>
                                {
                                    pair.map((col)=>{
                                        return(
                                            <BubbleRowDiv key={col.id}>
                                                {col.label}
                                            </BubbleRowDiv>
                                        );
                                    })
                                }
                            </BubbleColumnDiv>
                            <BubbleColumnDiv>
                                {
                                    pair.map((col)=>{
                                        return(
                                            <BubbleRowDiv key={col.id}>
                                                {data[col.id]}
                                            </BubbleRowDiv>
                                        );
                                    })
                                }
                            </BubbleColumnDiv>
                        </BubbleColumnPair>
                    );
                })
            }
        </BubbleBoxForFlexy>
    );
}
