// AddressInfoInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Select, MenuItem } from '@mui/material';
import { AppPalette, AppObject, AppWord } from '../../model/AppConst';
import { AddressInfoDictionary } from './AddressInfoGql';
import { AddressGroupDictionary, LIST_ADDRESS_GROUP, 
    ADD_ADDRESS_GROUP, EDIT_ADDRESS_GROUP, REMOVE_ADDRESS_GROUP } from './AddressGroupGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import RadioSelector from '../common/RadioSelector';
import ValueUtil from '../../model/ValueUtil';
import { useLazyQuery, useMutation } from '@apollo/client';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block', // width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
    borderRadius:5,
});
const InputMap = AddressInfoDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.


export default function AddressInfoInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [openGroupEditor, setOpenGroupEditor] = useState(false);
    const [addressGroupList, setAddressGroupList] = useState([]);
    const [addressGroupData, setAddressGroupData] = useState({});
	const [addressGroupSelected, setAddressGroupSelected] = useState(null);
    const [badGroupName, setBadGroupName] = useState(false);
    const [responseAlert, setResponseAlert] = useState(null); // for response error
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.
    // ##### Call GraphQL to get List #####
    const [getAddressGroupList, responseList] = useLazyQuery(LIST_ADDRESS_GROUP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetGroupList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: AddressGroupDictionary.errorTitle.List})}
    });
    // ##### GraphQL Mutation. ###
    const [addAddressGroup, responseAdd] = useMutation( ADD_ADDRESS_GROUP, {
		onCompleted: (data, option) => onCompleteAddGroup(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: AddressGroupDictionary.errorTitle.Add})}
	} );
    const [editAddressGroup, responseEdit] = useMutation( EDIT_ADDRESS_GROUP, {
		onCompleted: (data, option) => onCompleteEditGroup(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: AddressGroupDictionary.errorTitle.Edit})}
	} );
    const [removeAddressGroup, responseRemove] = useMutation( REMOVE_ADDRESS_GROUP, {
		onCompleted: (data, option) => onCompleteRemoveGroup(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: AddressGroupDictionary.errorTitle.Remove})}
	});

    useEffect(() => {
        if(open) getAddressGroupList();
    }, [open, getAddressGroupList]);

    useEffect(()=>{
        if(open) {
            if(item) {
                const d = {...item};
                // d.tel = ValueUtil.reformWith(item.tel, AppWord.NUM_N_HYPN);
                setInputData(d);
            }
            else {
                const d = {...defaultInputData};
                if(addressGroupList.length>0) d.addrGroupId = addressGroupList[0].addrGroupId;
                setInputData(d);
            }
            setInputError({});
            setChanged(false);
            setHasError(false);
        }
    }, [item, open]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const refreshAddressGroupList = (list) => {
        setAddressGroupList(list);
        if(addressGroupSelected) {
            for(const item of list) {
                if(item.addrGroupId===addressGroupSelected.addrGroupId) {
                    setAddressGroupSelected(item);
                    return;
                }
            }
        }
        setAddressGroupSelected(list[0]);
    };

    const onCompleteGetGroupList = (data, clientOption) => {
        if(data.addressGroupList) {
            refreshAddressGroupList(data.addressGroupList);
        }
    };

    const onCompleteAddGroup = (data, clientOption) => {
        if(data.addressGroupAdd.ok) {
            refreshAddressGroupList(data.addressGroupAdd.list);
            setOpenGroupEditor(false);
        }
        else setResponseAlert({open:true, resultData: data.addressGroupAdd, title: AddressGroupDictionary.errorTitle.Add});
    };

    const onCompleteEditGroup = (data, clientOption) => {
        if(data.addressGroupEdit.ok) {
            refreshAddressGroupList(data.addressGroupEdit.list);
            setOpenGroupEditor(false);
        }
        else setResponseAlert({open:true, resultData: data.addressGroupEdit, title: AddressGroupDictionary.errorTitle.Edit});
    };

    const onCompleteRemoveGroup = (data, clientOption) => {
        if(data.addressGroupRemove.ok) refreshAddressGroupList(data.addressGroupRemove.list);
        else setResponseAlert({open:true, resultData: data.addressGroupRemove, title: AddressGroupDictionary.errorTitle.Remove});
    };

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

    const changeInputData = (data) => {
        setInputData(data);
        setChanged(true);
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // add extra data if necessary.

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeGroupSelector = (event) => {
        const newData = {...inputData};
        newData.addrGroupId = event.target.value;
        for(const group of addressGroupList) {
            if(group.addrGroupId === newData.addrGroupId) {
                setAddressGroupSelected(group);
                break;
            }
        }
        changeInputData(newData);
    };

    const onChangeNumAndHyphen = (event) => {
        if(event.target.value) {
            if(/[^-0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

    const onClickToAddGroup = () => {
        setAddressGroupData({addrGroupName:''});
        setOpenGroupEditor(true);
    };

    const onClickToEditGroup = () => {
        setAddressGroupData({addrGroupId: addressGroupSelected.addrGroupId, addrGroupName: addressGroupSelected.addrGroupName});
        setOpenGroupEditor(true);
    };

    const onChangeGroupName = (event) => {
        const data = {...addressGroupData};
        data.addrGroupName = event.target.value;
        setBadGroupName(!ValueUtil.textInRange(data.addrGroupName, 1, 50));
        setAddressGroupData(data);
    };

    const onClickCancelAddressGroupInput = () => {
        setAddressGroupData({});
        setOpenGroupEditor(false);
    };

    const onClickSaveGroup = () => {
        const param = {variables: {addressGroup: addressGroupData}};
        if(addressGroupData.addrGroupId) editAddressGroup(param);
        else addAddressGroup(param);
    };

    const onRequestRemoveGroup = () => {
        if(addressGroupSelected != null) {
            setPromptToConfirm({
                data: addressGroupSelected,
                title: '주소그룹 삭제',
                messages: [
                    '선택된 ' + addressGroupSelected.addrGroupName + ' 주소그룹을 삭제하시겠습니까?',
                    '이 주소그룹을 사용하는 주소가 있으면 그룹을 삭제하지 않습니다.',
                    '삭제된 정보는 복구할 수 없습니다',
                    '정보 삭제를 진행하시겠습니까?'
                ],
                callback: (data) => {
                    setPromptToConfirm(null);
                    if(data) {
                        if(responseRemove) responseRemove.reset();
                        const param = {variables:{addressGroup:{addrGroupId: data.addrGroupId}}};
                        removeAddressGroup(param);
                    }
                }
            });
        }
    };


    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size="small"
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    const renderAddressGroup = () => {
        if(openGroupEditor) {
            const dict = AddressGroupDictionary.inputMap.addrGroupName;
            return(
                <FieldBox style={{border:'1px solid #f09', paddingLeft:10}}>
                    <TextField id={dict.id} size="small"
                        type={dict.type}
                        label={dict.label}
                        required={dict.required}
                        value={addressGroupData.addrGroupName}
                        error={badGroupName}
                        helperText={dict.help}
                        onChange={onChangeGroupName} />
                    <Button
                        onClick={onClickSaveGroup}
                        disabled={badGroupName || !addressGroupData.addrGroupName}
                        variant='contained' color='primary'
                        style={{marginLeft:10}}
                    >
                        저장</Button>
                    <Button onClick={onClickCancelAddressGroupInput} color='warning'
                        variant='contained'
                        style={{marginLeft:10}}
                    >
                        취소</Button>
                </FieldBox>
            );
        }
        else {
            const labelId = "label_Of"+InputMap.addrGroupId.id;
            return(
                <FieldBox style={{marginBottom:26}}>
                    <Select size='small'
                        labelId={labelId}
                        id={InputMap.addrGroupId.id}
                        name={InputMap.addrGroupId.id}
                        value={inputData.addrGroupId || ''}
                        label={InputMap.addrGroupId.label}
                        style={{minWidth:250}}
                        onChange={onChangeGroupSelector}
                    >
                        {
                            addressGroupList.map((group) => {
                                return(
                                    <MenuItem  key={group.addrGroupId} value={group.addrGroupId}>{group.addrGroupName}</MenuItem>
                                );
                            })
                        }
                    </Select>
                    <Button
                        onClick={onClickToEditGroup}
                        variant='contained' color='inherit'
                        style={{marginLeft:10}}
                    >
                        그룹명 변경</Button>
                    <Button
                        onClick={onClickToAddGroup}
                        variant='contained' color='inherit'
                        style={{marginLeft:10}}
                    >
                        그룹 추가</Button>
                    <Button  color='warning'
                        onClick={onRequestRemoveGroup}
                        variant='contained'
                        style={{marginLeft:10}}
                    >
                        그룹 삭제</Button>
                </FieldBox>
            );
        }
    };

    return (
        <Dialog open={open} maxWidth="lg">
            <EditorTitle>
                {isEdit ? '주소 정보 수정' : '주소 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                            {renderTextFieldBox(InputMap.fullname, inputData.fullname,
								inputError.fullname, onChangeTextData) /* 이름 */}
                            {renderAddressGroup()}
                </EditorContentRow>
                <EditorContentRow>
                    <InputColumn>
                            {renderTextFieldBox(InputMap.company, inputData.company,
								inputError.company, onChangeTextData) /* 회사 */}
                            {renderTextFieldBox(InputMap.department, inputData.department,
								inputError.department, onChangeTextData) /* 부서 */}
                            {renderTextFieldBox(InputMap.workRank, inputData.workRank,
								inputError.workRank, onChangeTextData) /* 직위 */}
                            {renderTextFieldBox(InputMap.job, inputData.job,
								inputError.job, onChangeTextData) /* 업무 */}
                    </InputColumn>
                    <InputColumn>
                            {renderTextFieldBox(InputMap.email, inputData.email,
								inputError.email, onChangeTextData) /* 메일주소 */}
                            {renderTextFieldBox(InputMap.hpNo, inputData.hpNo,
								inputError.hpNo, onChangeTextData) /* 휴대전화 */}
                            {renderTextFieldBox(InputMap.telNo, inputData.telNo,
								inputError.telNo, onChangeNumAndHyphen) /* 일반전화 */}
                            {renderTextFieldBox(InputMap.zipCode, inputData.zipCode,
								inputError.zipCode, onChangeTextData) /* 우편번호 */}
                            {renderTextFieldBox(InputMap.address1, inputData.address1,
								inputError.address1, onChangeTextData) /* 주소 1 */}
                            {/* renderTextFieldBox(InputMap.address2, inputData.address2,
								inputError.address2, onChangeTextData)  */}
                    </InputColumn>
                    <InputColumn>
                        <FieldBox>
                            <TextField id={InputMap.etc.id}
                                rows={5} multiline size="small"
                                type={InputMap.etc.type}
                                label={InputMap.etc.label}
                                required={InputMap.etc.required}
                                value={inputData.etc}
                                error={inputError.etc}
                                helperText={InputMap.etc.help}
                                onChange={onChangeTextData}
                            />
                        </FieldBox>
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed || openGroupEditor}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning' disabled={openGroupEditor}
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}