// map/PopVehPosInfo.js
import React from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import { AppPalette } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { userInfoRepo } from '../../model/CvoModel';
import ValueUtil from '../../model/ValueUtil';

const InfoTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const IconCircle = styled(Box)({
    display: 'inline-flex', backgroundColor:'white', color:AppPalette.HeaderBackground, marginRight: 10,
    borderRadius:40, height: 40, width:40, alignItems:'center', justifyContent:'center'
});

const InfoRow = styled(Box)({
    height:32, display:'flex', alignItems:'center',
});

const InfoLabel = styled('div')({
    width: 200, paddingLeft: 15
});

const Info = styled('div')({
    paddingLeft:15, flexGrow: 1
});

export default function PopVehPosInfo({
    open,
    vehPosData,
    onClickClose
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
	const isStaff = sessionInfo.signedIn ? ValueUtil.isEtrace(sessionInfo) : false;

    const makeDoorSensorText = (pos) => {
        const arr = [];
        if(pos.door0) arr.push('운전석방향 열림');
        if(pos.door1) arr.push('보조석방향 열림');
        if(pos.door2) arr.push('뒷문 열림');

        return arr.length > 0 ? arr.join(", ") : null;
    };

    const renderInfo = (label, text) => {
        return(
            <InfoRow>
                <InfoLabel>{label}</InfoLabel>
                <Info>
                    {text}
                </Info>
            </InfoRow>
        );
    };

    const data = open ? vehPosData : {};

    return (
        <Dialog open={open} maxWidth="md" onClose={onClickClose}>
            <InfoTitle>
                <IconCircle>
                    <NearMeIcon color={AppPalette.InheritColor} />
                </IconCircle>
                {data.vehPlates}
                {
                    isStaff
                    ? ' (veh_id=' + data.vehId + ', ' + data.custId + ')'
                    : null
                }
            </InfoTitle>
            <DialogContent style={{paddingTop:12}}>
                {renderInfo('차량', data.vehPlates + ' (' + data.vehAlias + ', ' + data.custName + ')')}
                {renderInfo('차종-톤수', data.vehTypeTon)}
                {renderInfo('운전자', data.driverName)}
                {renderInfo('연락처', data.driverMobile)}
                {renderInfo('속도', '시속 '+ data.speed + ' 킬로미터')}
                {renderInfo('최종위치', data.location)}
                {renderInfo('최종보고', data.gpsTime)}
                {renderInfo('누적거리', data.distKm + ' 킬로미터')}
                {data.door0 || data.door1 || data.door2 ? renderInfo('도어개폐', makeDoorSensorText(data)) : null}
            </DialogContent>
            <DialogActions>
                <Button
                    color={AppPalette.PrimaryColor}
                    onClick={onClickClose}
                    variant={AppPalette.VariantContained}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    )
}
/*

                    {
                        pos.door0 || pos.door1 || pos.door2
                        ? makeDoorSensorText(pos)
                        : null
                    }

*/