// VehRouteTemprList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button, IconButton, FormControl, InputLabel, InputAdornment, OutlinedInput } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ReportProblemIcon from '@mui/icons-material/ReportProblem'; //Alert on temperature range
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'; // Temperature is good
import ResponseAlert from "../message/ResponseAlert";
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType } from '../../model/AppConst';
import { useLazyQuery } from '@apollo/client';
import { LIST_VEH_ROUTE_TEMPR } from './VehRouteGql';
import DurationPicker from '../common/DurationPicker';
import ThermoChart from '../common/ThermoChart';
import dayjs from 'dayjs';

const dcPad = AppNumber.dataContainerPadding;
const VehRouteListContainer = styled(Box)({
    position:"absolute", top:0, right:0, bottom: 0, left: dcPad,
	display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

const PrintTemp = (value, isBad) => {
    if(ValueUtil.isNumber(value) && value > -333) {
        if(isBad) {
            return (
                <>
                    {ValueUtil.float2str(value, 2)}
                    <ReportProblemIcon fontSize={AppWord.SMALL} color="error"
                        style={{verticalAlign:"middle", marginLeft:5}} />
                </>
            );
        }
        return (
            <>
                {ValueUtil.float2str(value, 2)}
                <InsertEmoticonIcon fontSize={AppWord.SMALL} color="success"
                    style={{verticalAlign:"middle", marginLeft:5, opacity:0.5}} />
            </>
        );
    }
    return <span>&nbsp;</span>
};

const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: false, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehAlias', label: '지도표시명', width: 100, show: false, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpsTime', label: '최종보고', width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'tempr1', label: '전면온도', width: 80, show: true,
        option:{align: 'right', print: (r,i,v)=>{return PrintTemp(v, r.tempr1Break)}} },
    { id: 'tempr3', label: '중면온도', width: 80, show: true,
        option:{align: 'right', print: (r,i,v)=>{return PrintTemp(v, r.tempr3Break)}} },
    { id: 'tempr2', label: '후면온도', width: 80, show: true,
        option:{align: 'right', print: (r,i,v)=>{return PrintTemp(v, r.tempr2Break)}} },
    { id: 'tempr4', label: '온도4', width: 80, show: false,
        option:{align: 'right', print: (r,i,v)=>{return PrintTemp(v, r.tempr4Break)}} },
    { id: 'sessionDist', label: '범주내 운행거리', width: 120, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },

    { id: 'speed', label: '속도(Km/h)', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'distKm', label: '운행거리(Km)', width: 100, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'location', label: '위치', width: 400, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },

    { id: 'poiName', label: '출발/도착지', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'bcdEventValue', label: '출발/도착', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },

];

const EmptyDuration = {from:null, till:null};
const EmptyThermoData = {data:[], min:0, max:0, flag:{fore:false, center:false, aft:false}};

export default function VehRouteTemprList({
    vehPos,
    durationGiven,
	maxHeight,
	maxWidth,
    onClickShowPosList
}) {
    const [route, setRoute] = useState([]);
    const [thermo, setThermo] = useState(EmptyThermoData);
    const [duration, setDuration] = useState(EmptyDuration);
    const [search, setSearch] = useState('');
    const [itemSelected, setItemSelected] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getVehRouteList, responseList] = useLazyQuery(LIST_VEH_ROUTE_TEMPR, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "경로(온도)조회 오류"})}
    });

    useEffect(()=>{
        setRoute([]);
        if(vehPos) {
            if(vehPos.gpsTime) {
                if(durationGiven) {
                    if(durationGiven.from && durationGiven.till) {
                        setDuration(durationGiven);
                        getVehRouteList({variables: {vehId: vehPos.vehId, timeFrom: durationGiven.from, timeTill: durationGiven.till}});
                    }
                    else {
                        setDuration(EmptyDuration);
                        setThermo(EmptyThermoData);
                    }
                }
                else {
                    if(vehPos.gpsTime === AppWord.NOT_REAL_GPS_TIME) {
                        setDuration(EmptyDuration);
                        setThermo(EmptyThermoData);
                    }
                    else {
                        const djs = dayjs(vehPos.gpsTime);
                        const date = djs.toDate();
                        const from = ValueUtil.getYmdHmsInputText(date, 5*3600);
                        setDuration({from:from, till: vehPos.gpsTime});
                        setThermo(EmptyThermoData);
                    }
                }
            }
            else {
                setDuration(EmptyDuration);
                setThermo(EmptyThermoData);
            }
        }
        else {
            setDuration(EmptyDuration);
            setThermo(EmptyThermoData);
        }
    }, [vehPos, durationGiven, getVehRouteList]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        let gpsTimeSel = itemSelected ? itemSelected.gpsTime : undefined;

        if(data.vehRouteTemprList) {
            const newRoute = [];
            const tempr = [];
            let fore=false, center=false, aft=false, cnt=0, min=9999999.0, max=-9999999.0;

            let sdist = 0;
            let preDist = 0;
            let i = 0;
            for(const p of data.vehRouteTemprList) {
                // 범주내 주행거리.
                if(p.distKm > preDist) {
                    if(i>0) {
                        sdist += (p.distKm - preDist);
                    }
                    preDist = p.distKm;
                }
                p.sessionDist = sdist;
                if(p.distKm > 0) i++;
                newRoute.push(p);

                if(gpsTimeSel) {
                    setItemSelected(p);
                    gpsTimeSel = undefined;
                }
                // 온도그래프. #####
                cnt=0;
                const t = {time: p.gpsTime.substring(11, 16)};
                if(ValueUtil.isNumber(p.tempr1) && p.tempr1 > -333) {
                    t["전면온도"] = ValueUtil.float2str(p.tempr1,2);
                    if(p.tempr1 > max) max = p.tempr1;
                    else if(p.tempr1 < min) min = p.tempr1;
                    fore = true;
                    cnt++;
                }
                if(ValueUtil.isNumber(p.tempr2) && p.tempr2 > -333) {
                    t["후면온도"] = ValueUtil.float2str(p.tempr2,2);
                    if(p.tempr2 > max) max = p.tempr2;
                    else if(p.tempr2 < min) min = p.tempr2;
                    aft = true;
                    cnt++;
                }
                if(ValueUtil.isNumber(p.tempr3) && p.tempr3 > -333) {
                    t["중면온도"] = ValueUtil.float2str(p.tempr3,2);
                    if(p.tempr3 > max) max = p.tempr3;
                    else if(p.tempr3 < min) min = p.tempr3;
                    center = true;
                    cnt++;
                }

                if(cnt>0) tempr.push(t);
            }
            setThermo({data:tempr, flag:{fore:fore, center:center, aft:aft}, min:min, max:max});
            setRoute(newRoute);
        }
    };

    const onClickToGetList = () => {
        if(vehPos.vehId && duration.from && duration.till)
            getVehRouteList({variables: {vehId: vehPos.vehId, timeFrom: duration.from, timeTill: duration.till}});
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
    };

    const onChangeFrom = (from) => {
        const dur = {...duration};
        dur.from = from;
        setDuration(dur);
    };

    const onChangeTill = (till) => {
        const dur = {...duration};
        dur.till = till;
        setDuration(dur);
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - AppNumber.dataContainerPadding;
	
    const GraphAndTableHeight = height - clientSize.HeaderToolBoxHeight
        - clientSize.SmallBoxPadding * 2 - 2;
    const GraphHeight = Math.round(GraphAndTableHeight * 0.4);

    const TableHeight = GraphAndTableHeight - GraphHeight;

	//const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    return(
        <VehRouteListContainer>
            <HeaderBox>
                <ButtonBox>
                    <Button variant='contained' color="inherit"
                        onClick={()=>{onClickShowPosList()}}
                    >
                        닫기
                    </Button>
                </ButtonBox>
                <DurationPicker
                    from={duration.from} onChangeFrom={onChangeFrom}
                    till={duration.till} onChangeTill={onChangeTill}
                />
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!Boolean(duration) || !Boolean(vehPos)}
                    onClick={onClickToGetList}
                    style={{marginLeft:10, marginRight:20}}
                >
                    조회
                </Button>
            </HeaderBox>
            <ThermoChart
                data={thermo.data}
                min={thermo.min}
                max={thermo.max}
                thermoFlag={thermo.flag}
                graphHeight={GraphHeight} graphWidth={width}
            />
            <FlexyTable
                name="veh_route_tempr_list"
                uniqueKey="gpsTime"
                columns={TableColumns}
                records={route}
                search={search}
                tools={[]}
                onClickOneRow={onSelectItem}
				selected={itemSelected ? [itemSelected] : null}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehRouteListContainer>
    );

}
