// MapMenuAndWatch.js
import React, {useEffect, useRef, useState} from 'react';
import { styled  } from '@mui/material/styles';
import { useInterval } from 'react-use';
import { Box } from '@mui/system';
import { Button, ButtonGroup, Popover, Slider, IconButton,
    MenuList, MenuItem, ListItemIcon, ListItemText
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PowerIcon from '@mui/icons-material/Power';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { PosTailHourLimit } from './PositionTail';
import { AppObject, AppPalette, AppWord, MapEvent, MapValue } from '../../model/AppConst';
import { LIST_ROUTE_FOR_MAP } from '../veh_route/VehRouteGql';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    NoUser, poiInfoRepo, poiTypeRepo, sweeperRouteRepo, sweeperSelectedRepo, userInfoRepo, vehPosRepo,
    tableShapeConfRepo, openTableShapeConfRepo
} from '../../model/CvoModel';
//import MapControlIcon from './mapControl.svg';
import ValueUtil from '../../model/ValueUtil';
import { LIST_POI_INFO_FOR_MAP } from '../poi_info/PoiInfoGql';
import { LIST_VEH_POS } from '../veh_pos/VehPosGql';
import { SET_USER_INFO_MAP_VIEW_ZERO } from '../user_info/UserInfoGql';
import { BATCH_USER_TCOL_CONF21 } from '../user_tcol_conf21/UserTcolConf21Gql';
import Util from '../../model/Util';

const MinimalContainer = styled(Box)({display:'inline',marginLeft:10});

const PopControlBox = styled(Box)({
    minWidth:200, padding:10, borderRadius: 5,
    backgroundColor: '#f6f9ff', border: "1px solid "+AppPalette.PrimaryRGB,
    display:'flex', flexDirection:'column'
});

const SectionRow = styled(Box)({paddingTop:10, display:'flex', borderTop: '1px solid #cdf'});
/* const MenuContainer = styled(Box)({paddingLeft:10, display:'block', width:'100%'});
const MenuRow = styled(Box)({
    display:'flex', height:30, alignItems: 'center', borderRadius:30, cursor:'pointer', paddingLeft:15,
    overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap',
    '&:hover': {backgroundColor:'#ddd'}
}); */

const MapScaleBox = styled(Box)({ minWidth:180, margin:10 });
const PopTopRight = { vertical: 'top', horizontal: 'right', };
const MapTypeBox = styled(Box)({ paddingLeft: 10 });
const LabelBox = styled('div')({fontSize:'0.85rem', color:AppPalette.PrimaryRGB, padding:"10px 2px 5px 5px"});

const FetchInterval = 61; // seconds
const WaitBeforeSaveTableConf = 30000; // mil-sec

function readLocalStorage() {
    const text = localStorage.getItem(AppWord.MAP_LOCALSTORAGE_KEY);
    if(text) {
        const conf = JSON.parse(text);
        if(conf) {
            return conf;
        }
    }
    return {};
}

function saveLocalStorage(conf) {
    const text = JSON.stringify(conf);
    localStorage.setItem(AppWord.MAP_LOCALSTORAGE_KEY, text);
}

/**
 * 0.Menu, 지도 스케일, 종류 선택을 위한 Popover
 * 1.Sweeper 경로 자동으로 가져오기 위한 watcher
 * 2.차량정보 자동 수신, POI 조회 등을 위한 ...
 * @param {object} param0 {mapShell, onAlert}
 * @returns 
 */
export default function MapMenuAndWatch({
    mapShell,
    onAlert,
    disabled,
    onClickOpenPoiBatchInput
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [anchorElement, setAnchorElement] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(MapValue.Level.ZOOM_INIT);
    const [posTailHourLimit, setPosTailHourLimit] = useState(2);
    const [mapType, setMapType] = useState(window.naver.maps.MapTypeId.NORMAL);
    const vehPosStat = useReactiveVar(vehPosRepo);
    const [retrieveVehPosNow, setRetrieveVehPosNow] = useState(false);
    const [timeTryAndGet, setTimeTryAndGet] = useState(0); // Date().getTime() - 차량위치 가져온 시각.
    const [secNow, setSecNow] = useState(0);
    const [whenPoiRetrieved, setWhenPoiRetrieved] = useState(0);
    // const sweeperRoute = useReactiveVar(sweeperRouteRepo);
    const [tableChanged, setTableChanged] = useState({changed:0, count:0}); // table column configuration changed
    const tableShapeConf = useReactiveVar(tableShapeConfRepo);
    const openTableShapeConf = useReactiveVar(openTableShapeConfRepo);
    const sweeperSelected = useReactiveVar(sweeperSelectedRepo);
    const sweeperChanged = useRef();
    
    const isAdmin = sessionInfo.isEtrace();

    // ##### Call GraphQL to get List #####
    const [getVehRouteList, responseList] = useLazyQuery(LIST_ROUTE_FOR_MAP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {onAlert({open:true, error: error, title: "경로조회 오류"})} // onAlert ==> setResponseAlert
    });

    const [getPoiInfoList, responsePoiList] = useLazyQuery(LIST_POI_INFO_FOR_MAP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetPoiList(data, option)},
		onError: (error) => {onAlert({open:true, error: error, title: "지점목록 조회 오류"})}
    });
    
    const [getVehPosList, responseVehPosList] = useLazyQuery(LIST_VEH_POS, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetVehPosList(data, option)},
        // rendering에 어떤 변화가 있으면 getVehPosList 호출은 되는데 onCompleted 콜백은 호출되지 않음.
        // 그러나 responseList에 대한 useEffect는 동작함. data.vehPosList에 대한 for loop가 문제였던 것 같음. 제거하니 잘 동작.
		onError: (error) => {
            sessionInfo.ifLocal(()=>{ console.log("Error-pos list"); console.log(error); });

            Util.bubbleSnack("차량위치 조회 오류");
        }
    });

    const [setUserInfoMapViewZero, responseSetMapViewZero] = useMutation( SET_USER_INFO_MAP_VIEW_ZERO, {
        onCompleted: (data, option) => onCompleteSetMapViewZero(data, option),
        onError: (error) => {onAlert({open:true, error: error, title: "지도 초기화면 설정 오류"})}
    });

    const [batchUserTcolConf21, responseBatch] = useMutation( BATCH_USER_TCOL_CONF21, {
		onCompleted: (data, option) => onCompleteBatch(data, option), 
		onError: (error) => {
            sessionInfo.ifLocal(()=>{console.log('Error saving table configuration.');});
        } // 그냥 레포에 저장하고 나중에 처리하게 한다. (true for changed)
	} );

    useEffect(()=>{
        getPoiInfoList();
    }, []);

    useEffect(()=>{
        if(mapShell) {
            setZoomLevel(mapShell.getZoom());
            setMapType(mapShell.getMapType());
            mapShell.setMapEventHandler(MapEvent.ZOOM_CHANGED, MapEvent.ZOOM_CHANGED+'map_scale_and_watch', (level) => {
                setZoomLevel(level);
            });

            let hourLimit;
            const conf = readLocalStorage();

            if(conf.posTrackTailLimit) {
                mapShell.setVehPosTrackLimit(conf.posTrackTailLimit);
            }

            hourLimit = mapShell.getVehPosTrackLimit();
            setPosTailHourLimit(hourLimit);
        }
    }, [mapShell]);

    useEffect(()=>{
        if(sweeperSelected) {
            const curr = {...sweeperChanged.current};
            if(sweeperSelected.routeDate && (curr.vehId !== sweeperSelected.vehId || curr.routeDate !== sweeperSelected.routeDate)) {
                if(ValueUtil.isDateFormat(sweeperSelected.routeDate)) {
                    sessionInfo.ifLocal(
                        ()=>console.log(`Sweeper route: ${sweeperSelected.vehPlates} at ${sweeperSelected.routeDate}`)
                    );
                    getVehRouteList({variables: {
                        vehId: sweeperSelected.vehId,
                        timeFrom: sweeperSelected.routeDate + " 00:00:00",
                        timeTill: sweeperSelected.routeDate + " 23:59:59"
                    }});
                }
            }
        }
    }, [getVehRouteList, sweeperSelected]);

    useEffect(()=>{
        let count=0;
        for(const name of Object.keys(tableShapeConf)) {
            if(tableShapeConf[name].changed) count++;
        }
        setTableChanged({count:count, changed: new Date().getTime()});
    }, [tableShapeConf]);

    // 위치조회 반복 실행.
    const ticker = () => {
        const now = new Date().getTime() / 1000;
        const diff = now - vehPosStat.when; // 실제 가저온 시각과의 차이.
        const diffTry = now - timeTryAndGet;
        setSecNow(now);
        if((diff > FetchInterval && diffTry > 5) || retrieveVehPosNow) {
            setRetrieveVehPosNow(false);
            setTimeTryAndGet(now);
            getVehPosList();
        }

        if(
            tableChanged.count > 0
            && ((new Date().getTime()) - tableChanged.changed) > WaitBeforeSaveTableConf
            && !openTableShapeConf // ColumnConfigDialog 참조.
        ) {
            saveUnchangedTableConfig();
        }
    };

    useInterval(ticker, 1000);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.vehRouteList) {
            const newRoute = [];

            let sdist = 0;
            let preDist = 0;
            let i = 0;

            for(const p of data.vehRouteList) {
                if(p.distKm > preDist) {
                    if(i>0) {
                        sdist += (p.distKm - preDist);
                    }
                    preDist = p.distKm;
                }
                p.sessionDist = sdist;
                if(p.distKm > 0) i++;

                newRoute.push(p);
            }

            sweeperRouteRepo(newRoute);

            if(newRoute.length>0) {
                mapShell.showSweeperRoute(sweeperSelected, newRoute);
            }
            else mapShell.clearSweeperRoute();
        }
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetPoiList = (data, clientOption) => {
        if(data.poiInfoListForMap) {
            poiInfoRepo(data.poiInfoListForMap.map((p)=>{
                if((!p.lat || !p.lon) && p.xDist && p.yDist) {
                    const {lat, lon} = mapShell.katek2ll({x:p.xDist, y:p.yDist});
                    p.lat = lat;
                    p.lon = lon;
                    return p;
                }
                return p;
            }));
            setWhenPoiRetrieved(new Date().getTime() / 1000); // again.
        }
        if(data.custPoiTypeList) poiTypeRepo(data.custPoiTypeList);
    };

    // 차량위치목록을 수신하면 일단 전체 데이터를 저장한다. 이후 필터관련 useEffect에 의해서 필터링된 후 필터된 위치목록 repo로 들어감과 동시에 지도로 전달됨.
    const onCompleteGetVehPosList = (data, clientOption) => {
        // find current itemSelected and reset.
        const now = new Date().getTime() / 1000;
        if(data.vehPosList) {
            vehPosRepo({when:now, records: data.vehPosList});
            // rec.man_report_yn=='Y' && rec.ir_action != 0 && rec.ir_action != 255
            data.vehPosList
                .filter((vp)=>vp.manReportYn==='Y' && vp.irAction !==0 && vp.irAction !== 255 && vp.reportAgeSec < 600 && vp.irActionName)
                .forEach(p => {
                    Util.bubbleSnack(`${p.vehPlates} ${p.gpsTime}: ${p.irActionName} (${p.location})`);
                });
        }
    };

    const onCompleteSetMapViewZero = (data, option) => {
        if(data.userInfoSetMapViewZero.ok) Util.bubbleSnack("초기위치와 레벨 설정 완료(재로그인 후 반영)");
        else onAlert({open:true, resultData: data.userInfoSetMapViewZero, title: "지도 초기화면 설정 오류"});
    };

    const onCompleteBatch = (data, clientOption) => {
        if(data.userTcolConf21Batch.ok) {
            sessionInfo.ifLocal(()=>console.log('Saved table column configurations.'));
            updateAllRepoAsSaved();
        }
        else {
            sessionInfo.ifLocal(()=>console.log('Error: '+data.userTcolConf21Batch.message));
        }
    };

    // 과거에 칼럼 너비만 수정한 것들이 있다면 저장 후 종료한다.
    const saveUnchangedTableConfig = () => {
        if(!sessionInfo.isDisguised() && sessionInfo.signedIn) {
            const inputArray = Object.values(tableShapeConf).filter((conf)=>conf.changed)
                .map((conf)=>{
                    const csv = conf.columns.map((c)=>{
                        return c.column + ':' + c.width
                    }).join(',');
                    return {viewName: conf.table, colsCsv: csv};
                });
            if(inputArray.length > 0) {
                batchUserTcolConf21({variables: {userTcolConf21s:inputArray}});
            }
        }
    };

    const updateAllRepoAsSaved = () => {
        const ymd = ValueUtil.getYmdText();
        const newRepoData = {};
        for(const name of Object.keys(tableShapeConf)) {
            const conf = tableShapeConf[name];
            if(conf.changed) {
                conf.changed = false;
                conf.date = ymd;
            }
            newRepoData[name] = conf;
        }
        tableShapeConfRepo(newRepoData);
    };

    const onChangeLevel = (level) => {
        // setZoomLevel(level);
        mapShell.setZoom(level);
    };

    const onChangeMapType = (type) => {
        mapShell.setMapType(type);
        setMapType(type);
    };

    const onChangePosTailHourLimit = (hours) => {
        setPosTailHourLimit(hours);
        mapShell.setVehPosTrackLimit(hours);

        const conf = readLocalStorage();
        conf.posTrackTailLimit = hours;
        saveLocalStorage(conf);
    };

    const onClickSetInitView = () => {
        setAnchorElement(null);
        const level = mapShell.getZoom();
        const pos = mapShell.getCenter(); // {lat, lon}
        const xy = mapShell.ll2katek(pos);
        setUserInfoMapViewZero({variables:{userInfo:{
            viewLevel: level,
            initXcoord: xy.x, initYcoord: xy.y,
            initLat: pos.lat, initLon: pos.lon
        }}});
    };

    const onClickReloadVehPos = () => {
        setRetrieveVehPosNow(true);
        setAnchorElement(null);
    };

    const onClickReloadPOI = () => {
        setWhenPoiRetrieved(new Date().getTime() / 1000); // again.
        getPoiInfoList();
        setAnchorElement(null);
    };

    const popMapWindow = (param) => {
        const width = window.innerWidth; // > 2000 ? 2000 : window.screen.width - 50;
        const height = window.innerHeight; // > 2000 ? 2000 : window.screen.height - 50;
        let url = ValueUtil.getUrlBase() + "#view=map";
        if(param) {
            for(const pkey of Object.keys(param)) {
                const val = param[pkey];
                url += `&${pkey}=${val}`;
            }
        }
        window.open(url, '_blank', MapValue.PopupOption + `,width=${width},height=${height}`);
    };

    const onClickPopAnotherWindow = () => {
        popMapWindow();
        setAnchorElement(null);
    };

    const onClickBatchInput = () => {
        setAnchorElement(null);
        onClickOpenPoiBatchInput();
    };

    const marks = MapValue.LevelOptions.map(option => ({
        value: option.level,
        label: option.label,
    }));

    const popId = 'map-scale-and-watcher-pop';
    const openPopOver = Boolean(anchorElement);

    if(ValueUtil.hasAnyAuthError(
        responseList, responsePoiList, responseVehPosList, responseSetMapViewZero, responseBatch
    )) userInfoRepo(NoUser);

    return (
        <MinimalContainer>
            <IconButton size={AppWord.SMALL} color={AppPalette.InheritColor}
                onMouseEnter={(e)=>{if(!disabled) setAnchorElement(e.currentTarget)}}
            >
                <MenuIcon fontSize={AppWord.SMALL} />
            </IconButton>
            <Popover
                id={popId}
                open={openPopOver}
                onClose={()=>setAnchorElement(null)}
                anchorEl={anchorElement}
                anchorOrigin={PopTopRight}
                transformOrigin={PopTopRight}
            >
                <PopControlBox onMouseLeave={()=>setAnchorElement(null)}>
                    <SectionRow style={{borderTop:'0px'}}>
                        <MapScaleBox>
                            <Slider
                                value={zoomLevel}
                                orientation="vertical"
                                onChange={(e,v)=>onChangeLevel(v)}
                                style={{height:360}}
                                min={MapValue.Level.ZOOM_MIN}
                                max={MapValue.Level.ZOOM_MAX}
                                step={1}
                                valueLabelDisplay="off"
                                aria-labelledby="level-slider"
                                marks={marks}
                            />
                        </MapScaleBox>
                        <MapTypeBox>
                            <ButtonGroup
                                orientation='vertical'
                                aria-label="vertical outlined button group"
                            >
                                {
                                    MapValue.NaverMapTyles.map((type)=>{
                                        return(
                                            <Button
                                                key={type.type}
                                                disabled={mapType===type.type}
                                                onClick={()=>onChangeMapType(type.type)}
                                            >
                                                {type.title}
                                            </Button>
                                        );
                                    })
                                }
                            </ButtonGroup>
                            {
                                isAdmin
                                ?
                                <Box
                                    style={{marginTop:50}}
                                >
                                    <Button size={AppWord.SMALL}
                                        color={AppPalette.WarnColor}
                                        variant={AppPalette.VariantOutlined}
                                        onClick={onClickBatchInput}
                                    >
                                        지점일괄입력
                                    </Button>
                                </Box>
                                : null
                            }
                        </MapTypeBox>

                    </SectionRow>
                    <SectionRow>
                        <Box style={{paddingLeft:30}}>
                            <LabelBox>차량위치 경로꼬리 제한: {posTailHourLimit}시간</LabelBox>
                            <Slider
                                value={posTailHourLimit}
                                onChange={(e,v)=>onChangePosTailHourLimit(v)}
                                min={PosTailHourLimit.Min} max={PosTailHourLimit.Max}
                                step={1}
                                valueLabelDisplay='auto'
                                valueLabelFormat={(v) => `${v} 시간`}
                                aria-labelledby="hour-limit-slider"
                                style={{width:'90%'}}
                            />
                        </Box>
                    </SectionRow>
                    <SectionRow>
                        <MenuList>
                            <MenuItem
                                onClick={onClickSetInitView}
                            >
                                <ListItemIcon>
                                    <PowerIcon fontSize={AppWord.SMALL}/>
                                </ListItemIcon>
                                <ListItemText>지도 초기화면을 지금 상태로 설정</ListItemText>
                            </MenuItem>
                            <MenuItem disabled={(secNow - whenPoiRetrieved) < 10}
                                onClick={onClickReloadPOI}
                            >
                                <ListItemIcon>
                                    <HomeWorkIcon fontSize={AppWord.SMALL}/>
                                </ListItemIcon>
                                <ListItemText>지점목록 다시 읽기</ListItemText>
                            </MenuItem>
                            <MenuItem disabled={ (secNow - vehPosStat.when) > (FetchInterval - 7) || (secNow - vehPosStat.when) < 7}
                                onClick={onClickReloadVehPos}
                            >
                                <ListItemIcon>
                                    <ModeOfTravelIcon fontSize={AppWord.SMALL}/>
                                </ListItemIcon>
                                <ListItemText>차량위치 다시 읽기 ({FetchInterval - Math.round(secNow - vehPosStat.when)})</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={onClickPopAnotherWindow}
                            >
                                <ListItemIcon>
                                    <OpenInNewIcon fontSize={AppWord.SMALL}/>
                                </ListItemIcon>
                                <ListItemText>새로운 지도 창 띄우기</ListItemText>
                            </MenuItem>
                        </MenuList>

                    </SectionRow>

                </PopControlBox>
            </Popover>
        </MinimalContainer>
    )
}

/*

<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
<g id="SVGRepo_iconCarrier">
<rect width="24" height="24" fill="white"></rect>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 13C18.1814 13 18.7678 12.9988 19.2443 13.0473C19.7375 13.0974 20.2228 13.209 20.6667 13.5056C20.9943 13.7245 21.2755 14.0057 21.4944 14.3333C21.791 14.7772 21.9026 15.2625 21.9527 15.7557C22.0001 16.2209 22 16.7907 22 17.4514C22 18.0483 22.0132 18.6497 21.9527 19.2443C21.9026 19.7375 21.791 20.2228 21.4944 20.6667C21.2755 20.9943 20.9943 21.2755 20.6667 21.4944C20.2228 21.791 19.7375 21.9026 19.2443 21.9527C18.7791 22.0001 18.2093 22 17.5486 22C16.9517 22 16.3503 22.0132 15.7557 21.9527C15.2625 21.9026 14.7772 21.791 14.3333 21.4944C14.0057 21.2755 13.7245 20.9943 13.5056 20.6667C13.209 20.2228 13.0974 19.7375 13.0473 19.2443C12.9988 18.7678 13 18.1814 13 17.5C13 16.8186 12.9988 16.2322 13.0473 15.7557C13.0974 15.2625 13.209 14.7772 13.5056 14.3333C13.7245 14.0057 14.0057 13.7245 14.3333 13.5056C14.7772 13.209 15.2625 13.0974 15.7557 13.0473C16.2322 12.9988 16.8186 13 17.5 13Z" fill="#323232"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 13C7.18141 13 7.76776 12.9988 8.24428 13.0473C8.73752 13.0974 9.22279 13.209 9.66671 13.5056C9.99428 13.7245 10.2755 14.0057 10.4944 14.3333C10.791 14.7772 10.9026 15.2625 10.9527 15.7557C11.0001 16.2209 11 16.7907 11 17.4514C11 18.0483 11.0132 18.6497 10.9527 19.2443C10.9026 19.7375 10.791 20.2228 10.4944 20.6667C10.2755 20.9943 9.99428 21.2755 9.66671 21.4944C9.22279 21.791 8.73752 21.9026 8.24428 21.9527C7.77912 22.0001 7.20932 22 6.54857 22C5.95171 22 5.35034 22.0132 4.75572 21.9527C4.26248 21.9026 3.77721 21.791 3.33329 21.4944C3.00572 21.2755 2.72447 20.9943 2.50559 20.6667C2.20898 20.2228 2.09745 19.7375 2.04727 19.2443C1.99879 18.7678 2 18.1814 2 17.5C2 16.8186 1.99879 16.2322 2.04727 15.7557C2.09745 15.2625 2.20898 14.7772 2.50559 14.3333C2.72447 14.0057 3.00572 13.7245 3.33329 13.5056C3.77721 13.209 4.26248 13.0974 4.75572 13.0473C5.23225 12.9988 5.81858 13 6.5 13Z" fill="#323232"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 2C7.18141 2 7.76776 1.99879 8.24428 2.04727C8.73752 2.09745 9.22279 2.20898 9.66671 2.50559C9.99428 2.72447 10.2755 3.00572 10.4944 3.33329C10.791 3.77721 10.9026 4.26248 10.9527 4.75572C11.0001 5.22089 11 5.79069 11 6.45143C11 7.04829 11.0132 7.64966 10.9527 8.24428C10.9026 8.73752 10.791 9.22279 10.4944 9.66671C10.2755 9.99428 9.99428 10.2755 9.66671 10.4944C9.22279 10.791 8.73752 10.9026 8.24428 10.9527C7.77912 11.0001 7.20932 11 6.54857 11C5.95171 11 5.35034 11.0132 4.75572 10.9527C4.26248 10.9026 3.77721 10.791 3.33329 10.4944C3.00572 10.2755 2.72447 9.99428 2.50559 9.66671C2.20898 9.22279 2.09745 8.73752 2.04727 8.24428C1.99879 7.76776 2 7.18142 2 6.5C2 5.81858 1.99879 5.23225 2.04727 4.75572C2.09745 4.26248 2.20898 3.77721 2.50559 3.33329C2.72447 3.00572 3.00572 2.72447 3.33329 2.50559C3.77721 2.20898 4.26248 2.09745 4.75572 2.04727C5.23225 1.99879 5.81858 2 6.5 2Z" fill="#323232"></path>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 3C18.5 2.44772 18.0523 2 17.5 2C16.9477 2 16.5 2.44772 16.5 3V5.5H14C13.4477 5.5 13 5.94772 13 6.5C13 7.05228 13.4477 7.5 14 7.5H16.5V10C16.5 10.5523 16.9477 11 17.5 11C18.0523 11 18.5 10.5523 18.5 10V7.5H21C21.5523 7.5 22 7.05228 22 6.5C22 5.94772 21.5523 5.5 21 5.5H18.5V3Z" fill="#323232"></path>
</g>
</svg>



<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
<g id="SVGRepo_iconCarrier">
    <g id="Edit / List_Add">
    <path id="Vector" d="M3 16H10M15 16H18M18 16H21M18 16V19M18 16V13" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    </g>
</g>
</svg>
*/