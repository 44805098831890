// MapSweeperRoute.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button, IconButton, Popover } from '@mui/material';
import CellTowerIcon from '@mui/icons-material/CellTower'; // cell position
import GpsFixedIcon from '@mui/icons-material/GpsFixed'; // speed < 2
import NavigationIcon from '@mui/icons-material/Navigation'; // speed >= 2
import ResponseAlert from "../message/ResponseAlert";
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType } from '../../model/AppConst';
import { sweeperRouteRepo } from '../../model/CvoModel';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

// const dcPad = AppNumber.dataContainerPadding;
const VehRouteListContainer = styled(Box)({
    // position:"absolute", top:0, right:0, bottom: 0, left: dcPad,
    flexGrow:1,
	display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display:'flex', // height: AppNumber.HeaderToolBoxHeight,
    alignItems: 'center', padding: AppNumber.SmallBoxPadding,
    // '&>*': {verticalAlign: 'middle'}
});

const StateColumn = {
    id:'-mv-mark', label:'상태', width:50, show: true,
    option: {
        aligh:'center', type: TableColumnType.GRAPH,
        form: (rec, rowIndx) => {
            if(rec.posSys==='C' || rec.posSys===null) return <CellTowerIcon fontSize={AppWord.SMALL} color='disabled' />
            if(rec.speed < 2) return <GpsFixedIcon fontSize={AppWord.SMALL} color='success'/>;
            return <NavigationIcon fontSize={AppWord.SMALL} color='primary' style={{transform: 'rotate('+rec.direction+'deg)'}}/>;
        }
    }
};

const TableColumns = [
    { id: 'gpsTime', label: '최종보고', width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    StateColumn,
    { id: 'location', label: '위치', width: 400, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'speed', label: '속도(Km/h)', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'distKm', label: '운행거리(Km)', width: 100, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'pwrTypeValue', label: '전원타입', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'posSysValue', label: '위치타입', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
];

export default function MapSweeperRoute({
    mapShell,
    sweeperStat, // check using isDateFormat
}) {
    //const [route, setRoute] = useState([]);
    const route = useReactiveVar(sweeperRouteRepo);

    const onSelectItem = (item) => {
        mapShell.morphTo(item.lat, item.lon);
    };

    return(
        <VehRouteListContainer>
            <HeaderBox>
                {Boolean(sweeperStat) ? sweeperStat.vehPlates : null} 운행경로
            </HeaderBox>
            <FlexyTable
                name="map_sweeper_route_list"
                uniqueKey="gpsTime"
                columns={TableColumns}
                records={route}
                tools={[]}
                onClickOneRow={onSelectItem}
            />
        </VehRouteListContainer>
    );
}
