// PopMenuIconButton.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Paper, Menu, MenuList, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppPalette } from '../../model/AppConst';

export default function PopMenuIconButton({
    dataKey, // in iteration
    callbackData,
    menuIcon,
    iconSize,
    buttonColor,
    onClickMenu,
    menus, // {id:AppWord.CMD_EDIT, label:"Click it", icon:<NoteAltIcon fontSize='small' />},
}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const onClickMenuItem = (id) => {
        onClickMenu(id, callbackData);
        setAnchorEl(null);
    };

    const IBID = "pop-menu-icon-button-" + dataKey;
    const MenuID = "pop-menu-icon-button-menu-" + dataKey;

    return (
        <>
        <IconButton size={iconSize || 'medium'}
            id={IBID}
            aria-controls={Boolean(anchorEl) ? MenuID : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
            color={buttonColor || AppPalette.PrimaryColor}
            onClick={(e)=>{setAnchorEl(e.currentTarget)}}
        >
            {menuIcon || <MenuIcon fontSize={iconSize || 'medium'} />}
        </IconButton>
        <Menu
            id={MenuID}
            aria-labelledby={IBID}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={()=>{setAnchorEl(null)}}
        >
            {
                menus.map((menu, idx)=>{
                    return(
                        <MenuItem key={idx} onClick={()=>onClickMenuItem(menu.id)}>
                            {
                                menu.icon
                                ? <ListItemIcon>{menu.icon}</ListItemIcon>
                                : null
                            }
                            <ListItemText>{menu.label}</ListItemText>
                        </MenuItem>
                    );
                })
            }
        </Menu>
        </>
    )
}
/*

                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                  </MenuList>

        <MenuItem>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cut</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘X
          </Typography>
        </MenuItem>

*/
