// CustInfoList.js
import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Box } from '@mui/system';
import { IconButton, Menu, MenuItem, TextField, Popover } from '@mui/material';
import { codeTreeRepo } from '../../model/CvoModel';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import LockIcon from '@mui/icons-material/Lock';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FlawedList from '../message/FlawedList';
import FlexyTable from '../common/flexytable/FlexyTable';
import CodeCheckerGroup from '../common/CodeCheckerGroup';
import ValueUtil from '../../model/ValueUtil';
import { CustInfoDictionary } from './CustInfoGql';
import { AppNumber, TableColumnType, CvoCodes, AppPalette, AppWord } from '../../model/AppConst';
import styled from '@emotion/styled';
const LocalTitle = '업체목록';
const IdMenuAnchorButton = 'id_menu_anchor_button';
const IdMenuForCust = 'id_menu_for_cust';
const StyleOpacity6 = {opacity:0.6};

const CustInfoListContainer = styled(Box)({
    flexGrow:1, // backgroundColor:'yellow',//    height:'100%', //
    display:"flex", flexDirection:'column'
});

const PopoverHeader = styled(Box)({
    backgroundColor: '#eeeeee',
    fontWeight: 'bold',
    padding: 10
});

const SearchBox = styled(Box)({flexGrow:1});

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});

const TitleBox = styled(Box)({
    display: 'inline-block',
    fontWeight:'bold',
    paddingTop: 10,
    paddingRight: 30,
    height: AppNumber.HeaderToolBoxHeight-10
});

const TableWrapper = styled(Box)({flexGrow:1, display:'flex'});

const TableColumns = [
    { id: 'custId', label: '업체ID', width: 90, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 150, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'firmTypeValue', label: '가입자구분', width: 90, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'bizTypeValue', label: '업종', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regDate', label: '등록일', width: 100, show: true, option:{align: 'center', print:(r,i,v)=>{return v ? v.substring(0,10) : ''}} },
    { id: 'custStateValue', label: '상태', width: 70, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'smsUseFlag', label: 'SMS사용여부', width: 120, show: true, option:{align: 'center', type:TableColumnType.CHAR, ynBoolean: ['사용 중','사용안함']} },
    { id: 'billMethValue', label: '납부방법', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'agentName', label: '업무담당', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'agentTelNo', label: '전화번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'agentEmail', label: '담당자메일', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'compBizno', label: '사업자등록번호', width: 140, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custCorpNo', label: '법인번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'billStaffName', label: '결제담당', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'billStaffTelNo', label: '결제담당자전화', width: 140, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'billStaffEmail', label: '결제담당자메일', width: 150, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'billStaffFaxNo', label: '결제담당자팩스', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'salesStaffName', label: '영업담당자', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'reprName', label: '대표자명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'telNo', label: '대표전화', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'faxNo', label: '대표팩스', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'addr', label: '주소', width: 350, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'etc', label: '비고', width: 600, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} }
];

const NormalCustCriteria = {custStateCd:['NORMAL']}; // 'STOPPED', 'LEFT'

export default function CustInfoList({
    title,
    selected,
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestStopCust,
    onRequestGoodbyeCust,
    onRequestRestoreCust,
    onRequestManage,
    records,
}) {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [filterCriteria, setFilterCriteria] = useState(null);
    const [filterPopAnchor, setFilterPopAnchor] = useState(null);
    const [search, setSearch] = useState('');
    //const [itemSelected, setItemSelected] = useState(null);
    const [custMenuAnchorElem, setCustMenuAnchorElem] = useState(null);
    // const {loading:loadingList, error:errorOfList, data:dataList} = responseList;

    /* useEffect(()=>{
        setItemSelected(selected);
    }, [selected]); */
    const CustStateCode = ValueUtil.getCodeNode(CvoCodes.CUST_STATE_CD, CodeTree);
    const BizTypeCode = ValueUtil.getCodeNode(CvoCodes.BIZ_TYPE_CD, CodeTree);

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onSelectItem = (item) => {
        // setItemSelected(item);
        //if(onClickItem)
        console.log('onSelectItem', item);
        onClickItem(item);
    };

    const onChengeCodeFilter = (codeType, codes) => {
        const field = CustInfoDictionary.codeToField[codeType];
        const crit = filterCriteria ? {...filterCriteria} : {};
        if(codes && codes.length > 0) crit[field] = codes;
        else delete crit[field];
        
        setFilterCriteria(Object.keys(crit).length===0 ? null : crit);
    };

    const clearAllFilter = () => {
        setFilterCriteria(null);
    };

    const onClickCustMenuIconButton = (event) => {setCustMenuAnchorElem(event.currentTarget)};
    const onCloseMenuForCust = () => {setCustMenuAnchorElem(null)};
    const onClickEdit = () => { if(selected) onRequestEdit(selected);}
    const onClickStopCust = () => { if(selected) onRequestStopCust(selected); };
    const onClickGoodbyeCust = () => { if(selected) onRequestGoodbyeCust(selected);}
    const onClickRestoreCust = () => {
        console.log('onClickRestoreCust', selected);
        if(selected) onRequestRestoreCust(selected);
    };

    // onChange check box
    const onChangeNormalCustOnly = (event) => {
        setFilterCriteria(event.target.checked ? NormalCustCriteria : null);
    };

    const OpenFilterPopover = Boolean(filterPopAnchor);
    const IdForFilterPopover = OpenFilterPopover ? 'id_cust_list_filter_popover' : null;

    const renderFilterPopover = () => {
        return(
            <Popover
                id={IdForFilterPopover}
                open={OpenFilterPopover}
                anchorEl={filterPopAnchor}
                onClose={()=>setFilterPopAnchor(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <PopoverHeader>
                    {
                        filterCriteria
                        ?
                        <IconButton size='small' onClick={()=>clearAllFilter()}>
                            <FilterAltOffIcon />
                        </IconButton>
                        :
                        null
                    }
                    <span style={{margin:'15px 0px 15px 5px', fontWeight:'bold', display:'inline-block'}}>
                        업체 목록 필터
                    </span>
                </PopoverHeader>
                <Box>
                    <CodeCheckerGroup
                        checked={filterCriteria && filterCriteria.custStateCd ? filterCriteria.custStateCd : []}
                        codeGroup={CustStateCode}
                        onChange={onChengeCodeFilter}
                    />

                    <CodeCheckerGroup
                        checked={filterCriteria && filterCriteria.bizTypeCd ? filterCriteria.bizTypeCd : []}
                        codeGroup={BizTypeCode}
                        onChange={onChengeCodeFilter}
                    />
                </Box>
            </Popover>
        );
    };

    const renderCustMenu = () => {
        const isOpenMenuForCust = Boolean(custMenuAnchorElem);

        return(
            <>
            <IconButton color={AppPalette.PrimaryColor}
                onClick={onClickCustMenuIconButton}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id={IdMenuForCust}
                aria-labelledby={IdMenuAnchorButton}
                anchorEl={custMenuAnchorElem}
                open={isOpenMenuForCust}
                onClose={onCloseMenuForCust}
                anchorOrigin={{vertical:'top', horizontal: 'left'}}
                transformOrigin={{vertical:'top', horizontal: 'left'}}
                onMouseLeave={()=>{setCustMenuAnchorElem(null)}}
            >
                <MenuItem onClick={onRequestAdd}>
                    <DomainAddIcon fontSize={AppWord.SMALL} style={StyleOpacity6}/> 업체추가
                </MenuItem>
                <MenuItem onClick={()=>{onRequestManage(true)}} disabled={!selected}>
                    <PeopleIcon fontSize={AppWord.SMALL} style={StyleOpacity6}/> 사용자,담당자 관리
                </MenuItem>
                <MenuItem onClick={onClickEdit}
                    disabled={!selected}
                >
                    <EditIcon fontSize={AppWord.SMALL} style={StyleOpacity6} /> 업체정보 수정
                </MenuItem>
                <MenuItem onClick={onClickStopCust}
                    disabled={!selected || selected.custStateCd !== CustInfoDictionary.custState.NORMAL}
                >
                    <LockIcon fontSize={AppWord.SMALL} style={StyleOpacity6}
                        color="secondary" /> 일시정지
                </MenuItem>
                <MenuItem onClick={onClickGoodbyeCust}
                    disabled={!selected || selected.custStateCd === CustInfoDictionary.custState.LEFT}
                >
                    <DeleteOutlineIcon fontSize={AppWord.SMALL} style={StyleOpacity6}
                        color="warning"/> 업체해지
                </MenuItem>
                <MenuItem onClick={onClickRestoreCust}
                    disabled={!selected || selected.custStateCd === CustInfoDictionary.custState.NORMAL}
                >
                    <FavoriteBorderIcon fontSize={AppWord.SMALL} style={StyleOpacity6}
                        color="primary"/> 정상복귀
                </MenuItem>
            </Menu>
            </>
        );
    };

    return(
        <CustInfoListContainer aria-label="cust-info-list-container">
            <HeaderBox>
                <SearchBox>
                    {
                        title ? <TitleBox>{title}</TitleBox> : null
                    }
                    <TextField id="search" onChange={onChangeSearch}
                        value={search}
                        label="검색" size='small'/>
                    <IconButton color={filterCriteria ? 'secondary' : 'default'}
                        aria-describedby={IdForFilterPopover}
                        onClick={(event)=>{setFilterPopAnchor(event.currentTarget)}}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    {renderFilterPopover()}
                </SearchBox>
                
                {onRequestAdd ? renderCustMenu() : null}
            </HeaderBox>
            <TableWrapper>
                <FlexyTable
                    name="cust_info_list"
                    uniqueKey="custId"
                    columns={TableColumns}
                    records={records}
                    search={search}
                    criteria={filterCriteria}
                    onClickOneRow={onSelectItem}
                    selected={selected ? [selected] : null}
                />
            </TableWrapper>
        </CustInfoListContainer>
    );
}
