// FlexyTable.js
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { Chip, IconButton, Checkbox, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Excel from 'exceljs';
import copy from 'copy-to-clipboard';
import { saveAs } from 'file-saver';
import useClientSize from '../../hook/useClientSize';
import VerifiedIcon from '@mui/icons-material/Verified';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import MenuIcon from '@mui/icons-material/Menu';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
// import ReplayIcon from '@mui/icons-material/Replay';
import ExcelIconSvg from '../../../img/xlsx-file-icon.svg'; // microsoft-excel-icon.svg
import { TableColumnType, AppPalette, AppWord } from '../../../model/AppConst';
import { tableShapeConfRepo, userInfoRepo } from '../../../model/CvoModel';
import ValueUtil from '../../../model/ValueUtil';
import {
    FlexySize,
    FlexyTableContainer, FooterBox, TableContainer,
    TableHeaderRow, TableHeaderRowContent, TableBodyBox, TableTopMarginBox,
    TableRowDiv, HeaderColumnLabel,
    HeaderColumnDiv, HeaderColumnResizer, ResizerBar,
    CellDiv, MonoDiv, IndexCellDiv, TableToolBarDiv,
} from './FlexyTableStyled';
import FlexyTableBubble from './FlexyTableBubble';
import { useReactiveVar } from '@apollo/client';
import ColumnConfigDialog from './ColumnConfigDialog';
import Util from '../../../model/Util';

function isSearchable(column) {
    if(column.option) {
        if(column.option.type) {
            return column.option.type === TableColumnType.TEXT
                || column.option.type === TableColumnType.HHMM_TEXT
                || column.option.type === TableColumnType.CHAR;
        }
        else return true;
    }
    else return true;
}

function getType(id, columns) {
    for(const c of columns) {
        if(c.id===id) {
            if(c.option && c.option.type) return c.option.type;
        }
    }
    return TableColumnType.TEXT;
}

function isMatchToFilter(item, criteria) {
    const fkeys = Object.keys(criteria); // {vehTonId: [1, 3], someField: ['good', 'better']}
    var match = 0;
    for(const fkey of fkeys) {
        for(const value of criteria[fkey]) {
            if(item[fkey]===value) {
                match++;
                break;
            }
        }
    }
    return match === fkeys.length;
}

function getColumnById(columns, id) {
    for(const col of columns) {
        if(col.id === id) return {...col};
    }
    return undefined;
}

function getColumnListToApply(columns, config) {
    if(config) {
        const cols = [];
        for(const cw of config.columns) {
            const col = getColumnById(columns, cw.column);
            if(col) {
                if(cw.width) col.width = cw.width;
                col.show = true;
                cols.push(col);
            }
        }
        return cols;
    }
    return columns.filter((c) => c.show ? true : false);
}

const NoSort = {id:null,reverse:false};

const IdFirstTopButton = "first-top-button";
const IdFirstTopMenu = "first-top-menu";

// ## ##############################################################################################
/**
 * FlexyTable은 PanTable을 개선한 것.
 * @param {*} param0 {name, uniqueKey, columns, records, search, filterFunction, selected, tools, criteria, ...} 
 * @returns 테이블 컴포넌트
 */
export default function FlexyTable({
    name, // name for identification of data.
    uniqueKey,
    pagination, // Object. - 아직 구현하지 않았음.
    columns, // {id, label, type, option: {isKey,}}
    records,
    search,
    option, // in option{} multiChecker, // boolean. No bubble show if true.
    selected, // single if not undefined && NOT multiChecker
    tools, // [ {id, icon}]
    onClickTool, // click on one of tools above.
    onSelectRow, // on select / un-select a row onSelectRow(row)
    onClickOneRow, // on click a row, anyware, not sele
    filterFunction, // if present, criteria will be ignored.
    criteria, // filters {vehTonId: [1, 3], someField: ['good', 'better']} if given.
    hideFirstTopButton, // boolean
    hideColumnConfig, // boolean
}) {
    const [width, setWidth] = useState(200);
    const [height, setHeight] = useState(200);
    const [scrollPos, setScrollPos] = useState({left:0, top:0});
    const [resizing, setResizing] = useState(null);
    const [colList, setColList] = useState([]);
    const [recFound, setRecFound] = useState([]);
    const [enteringEvent, setEnteringEvent] = useState(undefined);
    const [sorter, setSorter] = useState(NoSort); // {id, type, reverse}
    const [rowHover, setRowHover] = useState(null);
    const [bubbleItem, setBubbleItem] = useState(null);
    const [singleSelected, setSingleSelected] = useState(null); //(selected && selected.length>0 ? selected[0][uniqueKey] : null);
    const [anchorTopMenu, setAnchorTopMenu] = useState(null);
    const [openColumnConfiguration, setOpenColumnConfiguration] = useState(false);
	const sessionInfo = useReactiveVar(userInfoRepo);
    const tableShapeConf = useReactiveVar(tableShapeConfRepo);
    const contentRef = useRef(null);
    const clientSize = useClientSize();

    const flexOption = option || {}; // multiChecker, onClickLeftArrow

    useEffect(() => {
        setColList(getColumnListToApply(columns, tableShapeConf[name]));
    }, [columns, tableShapeConf, name]);

    useEffect(()=>{
        if (contentRef.current) {
            const { clientWidth, clientHeight } = contentRef.current;
            if(clientWidth > 10) setWidth(clientWidth);
            if(clientHeight > 10) setHeight(clientHeight);
        }
    },[contentRef]);

    useEffect(()=>{
        if (contentRef.current) {
            const { clientWidth, clientHeight } = contentRef.current;
            if(clientWidth > 10) setWidth(clientWidth);
            if(clientHeight > 10) setHeight(clientHeight);
        }
    },[clientSize]);

    useEffect(()=>{
        if(selected && selected.length>0) {
            setSingleSelected(selected[0][uniqueKey]);
        }
    }, [selected, uniqueKey]);

    const onBoxScroll = (event) => {
        setScrollPos({left:event.target.scrollLeft, top: event.target.scrollTop});
    };

    const onMouseEnter = (event) => {
        if (contentRef.current) {
            const { clientWidth, clientHeight } = contentRef.current;
            if(clientWidth > 10) setWidth(clientWidth);
            if(clientHeight > 10) setHeight(clientHeight);
        }
    };

    useEffect(()=>{

        // To filter
        const filterRecord = (item) => {
            if(filterFunction) {
                // criteria 처리가 너무 복잡해지면 어려우니까 아예 ..
                if(!filterFunction(item)) return false;
            }
            else if(criteria) {
                if(!isMatchToFilter(item, criteria)) return false;
            }
            if(search) {
                const searchText = search.trim();
                if(searchText.length===0) return true;
                for(const c of colList) {
                    if(isSearchable(c)) {
                        if(item[c.id] && item[c.id].indexOf(searchText) >= 0) return true;
                    }
                }
                return false;
            }
            else return true;
        };

        // To sort.
        const compareItems = (a, b) => {
            // return -1 if a is less than b
            if(!sorter.id) return 0;
            const va = sorter.reverse ? b[sorter.id] : a[sorter.id];
            const vb = sorter.reverse ? a[sorter.id] : b[sorter.id];
            if(va===undefined || va===null) {
                if(vb===undefined || vb===null) return 0;
                return -1;
            }
            else {
                if(vb===undefined || vb===null) return 1;
                else {
                    if(va === vb) return 0; // 어떤 타입이든.
                    if(sorter.type === TableColumnType.FLOAT || sorter.type === TableColumnType.INTEGER) return va < vb ? -1 : 1;
                    if(sorter.type === TableColumnType.BOOLEAN) return va === false ? -1 : 1;
                    const ta = va.toUpperCase();
                    const tb = vb.toUpperCase();
                    if(ta === tb) return 0;
                    else if(ta < tb) return -1;
                    return 1;
                }
            }
        };

        setRecFound(sorter.id ?  records.filter(filterRecord).sort(compareItems) : records.filter(filterRecord));
    }, [colList, filterFunction, criteria, records, search, sorter]);

    const onChangeHeight = (readerRef) => {
        if (readerRef.current) {
            const { clientHeight } = readerRef.current;
            setHeight(clientHeight);
        }
    };

    const LastColumnIndex = colList.length-1;
    let RowWidth = FlexySize.IndexCellWidth + 1;
    colList.forEach((c) => {RowWidth += (c.width || 100) + 1});
    // TableHeight : data 들어가는 상자 의 높이.
    let TableHeight = height - 2 - (pagination ? FlexySize.FooterHeight+1 : 0);
    if(TableHeight < 100) TableHeight = 100;
    // AddWidth : 칼럼이 너비를 채우지 못하면 마지막 칼럼에 채워줄 추가 폭.
    const AddWidth = RowWidth >= width ? 0 : width - RowWidth - 18; // 18은 스크롤러.
    if(RowWidth < width) RowWidth = width - 2; // 너비를 모두 채워준다. 18은 스크롤러.
    const TableBodyHeight = TableHeight - 1; // 계산용.
    // record가 너무 적으면 가짜 데이터로 줄긋기.
    var EmptyRows = [];
    if( TableBodyHeight / FlexySize.RowHeight > recFound.length ) {
        // const EmptyRowCount = Math.floor(TableBodyHeight / FlexySize.RowHeight) - recFound.length - 1;
        const EmptyRowCount = Math.ceil((TableBodyHeight-FlexySize.HeaderRowHeight) / FlexySize.RowHeight) - recFound.length - 1;
        for(var i=0;i<EmptyRowCount;i++) EmptyRows.push({_f_:100-i}); // filler
    }

    // Resizing.
    const adjustColumnWidth = (columnIndex, adjust) => {
        if(colList[columnIndex]) {
            const width = (colList[columnIndex].width || 100) + adjust;
            if(width >= 50 && width < 700) {
                var newList = colList.slice();
                newList[columnIndex].width = width;
                setColList(newList);
            }
        }
    };

    const onMouseDownInResizer = (e, columnIndex) => {
        setResizing({columnIndex:columnIndex, x:colList[columnIndex].width-1});
        e.stopPropagation();
    };

    // Click on sort icon (a to z or reverse)
    const onMouseMoveInHeaderRow = (e) => {
        if(resizing) {
            adjustColumnWidth(resizing.columnIndex, e.movementX);
        }
    };

    const makeObjectForRepo = () => {
        const obj = tableShapeConf[name] || {userId: sessionInfo.user.userId, table:name, date:'', changed:true, columns:[]};
        obj.columns = colList.map((col)=>{return {column:col.id, width: col.width}});
        obj.date = ValueUtil.getYmdText();
        obj.changed = true;
        return obj;
    };

    const onMouseUpInHeaderRow = (e) => {
        // Save to repo here.
        const repoMap = {...tableShapeConf};
        repoMap[name] = makeObjectForRepo();
        tableShapeConfRepo(repoMap);

        setResizing(null);
        e.stopPropagation();
    };

    const onClickHeaderToSort = (column) => {
        if(resizing) return;
        const sv = {...sorter};
        if(sv.id===column.id) {
            if(sv.reverse) {
                // No sorting
                setSorter(NoSort);
            }
            else {
                sv.reverse = true;
                setSorter(sv);
            }
        }
        else {
            setSorter({id:column.id, type: getType(column.id, columns), reverse: false});
        }
    };

    // Click on a tool icon at each row (right end).
    const onClickToolIcon = (toolId, row, event) => {
        if(onClickTool) onClickTool(toolId, row, event); // event is undefined
    };

    const onClickRow = (row) => {
        setSingleSelected(row[uniqueKey]);
        if(onClickOneRow) onClickOneRow(row);
    };

    const onClickConfigColumns = () => {
        setAnchorTopMenu(null);
        setOpenColumnConfiguration(true);
    };

    const copyToClipboard = () => {
        if(recFound.length===0) {
            Util.bubbleSnack("클립보드로 복사할 데이터가 없습니다.");
            return;
        }

        const txt = "No.\t" + colList.map((column) => {return column.label;}).join("\t") + "\n"
            + recFound.map((row, rowIndex) => {
                let rowText = `${rowIndex+1}`;
                colList.forEach((column) => {
                    let val = Boolean(row[column.id]) ? row[column.id] : '';
                    if(typeof val === 'string') val = val.replace(/[\r\n]+/g, ' ');
                    rowText += "\t" + val;
                });
                return rowText;
            }).join("\n");

        copy(txt, {});
        
        Util.bubbleSnack(`${txt.length} 글자를 클립보드에 복사했습니다.`);
    };

    const onClickCopyToClipboard = () => {
        setAnchorTopMenu(null);
        copyToClipboard();
    };

    const workbook = new Excel.Workbook();

    const onClickSaveExcel = async () => {
        setAnchorTopMenu(null);
        const workSheetName = 'Worksheet-1';
        // const workBookName = 'MyWorkBook';
        const fileName = (name || 'excel') + '_' + ValueUtil.getYmdHmsText(); // of now

        const worksheet = workbook.addWorksheet(workSheetName);
        worksheet.columns
            = colList
                .filter((c)=>{return c.option.type !== TableColumnType.GRAPH})
                .map((c)=>{return {key:c.id, header:c.label, width:Math.ceil((c.width || 100)/8)}});

        worksheet.getRow(1).font = { bold: true };
        // worksheet.columns.forEach(column => {column.width = column.header.length + 5;});

        recFound.forEach(row => {
            worksheet.addRow(row);
        });
        // loop through all of the rows and set the outline style.
        worksheet.eachRow({ includeEmpty: false }, row => {
            // store each cell to currentCell
            const currentCells = row._cells;

            // loop through currentCell to apply border only for the non-empty cell of excel
            currentCells.forEach(singleCell => {
            // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                const cellAddress = singleCell._address;

                // apply border
                worksheet.getCell(cellAddress).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });
        });
        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();
  
        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
    };


    const onChangeSelection = (row, event) => {
        // row.__checked = event.target.checked; // react doesn't like this.
        onSelectRow(row, event.target.checked);
    };

    const onMouseEnterDataRow = (row, event) => {
        setRowHover(row);
    };

    const onEnterIndexCell = (row, event) => {
        if(!flexOption.multiChecker) {
            setBubbleItem(row);
            setEnteringEvent(event);
        }
    };

    // 실제 출력할 줄들의 범위를 구하여 그 바깥은 빈 줄만 출력한다.
    const foundLen = recFound.length;
    let rowA = Math.floor(scrollPos.top / FlexySize.RowHeight) - 1;
    if(rowA<0) rowA = 0;
    const rowZ = Math.ceil((scrollPos.top + TableHeight) / FlexySize.RowHeight ) + 1;

    // if(idx < rowA || idx > rowZ) ==> print blank line
    const blankTopHeight = rowA * FlexySize.RowHeight;
    const blankBottomHeight = rowZ < foundLen-1 ? (foundLen-rowZ-1) * FlexySize.RowHeight : 0;
    const recToPrint = blankBottomHeight > 0 || blankTopHeight > 0 ? recFound.slice(rowA, rowZ+1) : recFound;

    const renderGraphHeaderColumn = (column, idx)  => {
        // header 너비의 + 1 값은 border.
        const hw = (column.width || 100) - FlexySize.CellPaddingLeft; // + 1;
        return(
            <HeaderColumnDiv key={column.id} style={{width: hw, borderRight:AppPalette.BorderCCC}}>
                <HeaderColumnLabel>{column.label}</HeaderColumnLabel>
            </HeaderColumnDiv>
        );
    };

    const renderHeaderColumn = (column, idx) => {
        // header 너비의 + 1 값은 border.
        const hw = (column.width || 100) - FlexySize.CellPaddingLeft + 1;
        return(
            <HeaderColumnDiv key={column.id} style={{width: hw}}
                onMouseDown={(e)=>onClickHeaderToSort(column)}
            >
                <HeaderColumnLabel>
                    {column.label}
                </HeaderColumnLabel>
                {
                    sorter.id === column.id
                    ?
                    (
                        sorter.reverse ?
                        <NorthIcon fontSize={AppWord.SMALL} style={{verticalAlign:'middle', color:'#99ccff'}} />
                        : <SouthIcon fontSize={AppWord.SMALL} style={{verticalAlign:'middle', color:'#99ccff'}} />
                    )
                    : null
                }
                {
                    idx < LastColumnIndex ? 
                    <HeaderColumnResizer style={{left:hw-1}}>
                        <ResizerBar onMouseDown={(e)=>{onMouseDownInResizer(e,idx)}} />
                    </HeaderColumnResizer>
                    : null
                }
            </HeaderColumnDiv>
        );
    };

    const renderCellIcon = (column, record) => {
        if(column.icon) {
            if(column.icon.getView) return column.icon.getView(record);
            else if(column.icon.condition && column.icon.view) {
                const con = column.icon.condition; // function
                if(con(record)) return column.icon.view;
            }
        }
        return null;
    };

    const renderCell = (column, value, rowIndex, colIndex, record) => {
        const cw = colIndex===LastColumnIndex && AddWidth > 0 
            ? (column.width || 100) - FlexySize.CellPaddingLeft + AddWidth 
            : (column.width || 100) - FlexySize.CellPaddingLeft;
        const mapKey = column.id + '_' + rowIndex;

        if(value===undefined || value===null)
            return(
                <CellDiv key={mapKey} style={{width: cw}}>{null}</CellDiv>
            );

        if(column.option) {
            if( column.option.type === TableColumnType.INTEGER ) {
                return(
                    <CellDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'right'}}>
                        {renderCellIcon(column, record)}
                        <span>{ValueUtil.int2str(value)}</span>
                    </CellDiv>
                );
            }
            if( column.option.type === TableColumnType.FLOAT ) {
                return (
                    <CellDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'left'}}>
                        {renderCellIcon(column, record)}
                        <span>{ValueUtil.float2str(value, column.option.round || 2)}</span>
                    </CellDiv>
                );
            }
            if( column.option.type === TableColumnType.CHAR && column.option.ynBoolean ) {
                if(column.option.toggle) {
                    // Emit toggle event. Use toggle icon.
                    if(value.toUpperCase() === "Y") {
                        return(
                            <CellDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'center'}}>
                                {column.option.ynBoolean[0] || "예"}
                                <IconButton size={AppWord.SMALL} color={AppPalette.PrimaryColor}
                                    onClick={(e)=>{onClickToolIcon(column.option.toggle + "+N", record, e)}}
                                >
                                    <ToggleOnIcon fontSize={AppWord.SMALL} />
                                </IconButton>
                            </CellDiv>
                            
                        );
                    }
                    return(
                        <CellDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'center'}}>
                            {column.option.ynBoolean[1] || "아니오"}
                            <IconButton size={AppWord.SMALL} color={AppPalette.DisabledColor}
                                onClick={(e)=>{onClickToolIcon(column.option.toggle + "+Y", record, e)}}
                            >
                                <ToggleOffIcon fontSize={AppWord.SMALL} />
                            </IconButton>
                        </CellDiv>
                    );
                }
                if(value.toUpperCase() === "Y") {
                    return(
                        <CellDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'center'}}>
                            <Chip icon={<VerifiedIcon fontSize={AppWord.SMALL}/>}
                                size={AppWord.SMALL} color={AppPalette.PrimaryColor} variant={AppPalette.VariantOutlined}
                                label={column.option.ynBoolean[0] || "예"} />
                        </CellDiv>
                        
                    );
                }
                return(
                    <CellDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'center'}}>
                        <Chip icon={<NotInterestedIcon fontSize={AppWord.SMALL}/>}
                            size={AppWord.SMALL} variant={AppPalette.VariantOutlined}
                            label={column.option.ynBoolean[1] || "아니오"} />
                    </CellDiv>
                );
            }
            if( column.option.type === TableColumnType.DATE
                || column.option.type === TableColumnType.DATETIME
                || column.option.type === TableColumnType.HHMM_TEXT
                ) {
                return(
                    <MonoDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'center'}}>
                        {renderCellIcon(column, record)}
                        <span>
                        {
                            column.option.type === TableColumnType.HHMM_TEXT
                            ? (value ? value.substring(0,5) : '--:--')
                            : value
                        }</span>
                    </MonoDiv>
                )
            }
            // TableColumnType.CHAR, ynBoolean
            return(
                <CellDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'left'}}>
                    {renderCellIcon(column, record)}
                    {value}
                </CellDiv>
            );
        }
        return(
            <CellDiv key={mapKey} style={{width: cw, textAlign: 'left'}}>
                {renderCellIcon(column, record)}
                {value}
            </CellDiv>
        );
    };

    const renderGraphValue = (column, record, rowIndex, colIndex) => {
        const cw = colIndex===LastColumnIndex && AddWidth > 0 
            ? (column.width || 100) - FlexySize.CellPaddingLeft + AddWidth 
            : (column.width || 100) - FlexySize.CellPaddingLeft;
        const mapKey = column.id + '_' + rowIndex;
        if(column.option.type === TableColumnType.GRAPH) {
            const func = column.option.form;
            if(func) {
                return(
                    <CellDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'center'}}>
                        {func(record, rowIndex, record[column.id])}
                    </CellDiv>
                );
            }
        }
        return <CellDiv key={mapKey} style={{width: cw}}> <span>{record[column.id]}</span></CellDiv>
    };

    const renderCellSelf = (column, record, rowIndex, colIndex) => {
        const cw = colIndex===LastColumnIndex && AddWidth > 0 
            ? (column.width || 100) - FlexySize.CellPaddingLeft + AddWidth 
            : (column.width || 100) - FlexySize.CellPaddingLeft;
        const mapKey = column.id + '_' + rowIndex;
        const func = column.option.print; // GRAPH 타입의 경우는 form이고, 그 외에는 print

        if(func) {
            return(
                <CellDiv key={mapKey} style={{width: cw, textAlign: column.option.align || 'center'}}>
                    {func(record, rowIndex, record[column.id])}
                </CellDiv>
            );
        }
        return <CellDiv key={mapKey} style={{width: cw}}><span>{record[column.id]}</span></CellDiv>
    };

    const renderRowTool = (row) => {
        return(
            <TableToolBarDiv left={width - tools.length*30 -  25 + scrollPos.left}>
                {
                    tools.map((tool) => {
                        return(
                            <IconButton size='small' key={tool.id} onClick={(e)=>onClickToolIcon(tool.id, row, e)}
                                title={tool.title ? tool.title : ''}
                            >
                                {tool.icon}
                            </IconButton>
                        );
                    })
                }
            </TableToolBarDiv>

        );
    };

    const renderRow = (row, idx) => {
        if(row._f_) {
            // filler
            return(
                <TableRowDiv key={idx} width={RowWidth}>
                    <IndexCellDiv>&nbsp;</IndexCellDiv>
                    {
                        colList.map((c, colIndex) => {return renderCell(c, null, idx, colIndex, row)})
                    }
                </TableRowDiv>
            );
        }
        // scroll 따라서 빈 줄만 출력하여 속도 개선.
        
        if(idx < rowA || idx > rowZ) {
            return(
                <TableRowDiv key={idx} width={5}> </TableRowDiv>
            );
        }
        const rowStyle = {};
        if(singleSelected===row[uniqueKey]) {
            rowStyle.backgroundColor = '#f7f0ff';
        }
        return(
            <TableRowDiv key={idx} width={RowWidth}
                style={rowStyle}
                onMouseEnter={(e)=>{onMouseEnterDataRow(row[uniqueKey], e)}}
                onClick={()=>{onClickRow(row)}}>
                <IndexCellDiv
                    onMouseEnter={(e)=>{onEnterIndexCell(row, e)}}
                    onMouseLeave={()=>{if(!flexOption.multiChecker) setBubbleItem(null)}}
                    >
                    {
                        flexOption.multiChecker
                        ?
                        <Checkbox checked={row.__checked} onChange={(e)=>onChangeSelection(row, e)}
                            size={AppWord.SMALL}
                            color={AppPalette.PrimaryColor} />
                        :
                        rowHover===row[uniqueKey]
                        ? <span style={{color:'blue'}}>ㅁ</span> /*<ChatBubbleOutlineIcon fontSize='small' color='primary' style={{verticalAlign:'center', height:'50%'}} />*/
                        : <span>{idx+1}</span>
                    }
                </IndexCellDiv>
                {
                    colList.map((c, colIndex) => {
                        if(c.option) {
                            if(c.option.type===TableColumnType.GRAPH)
                                return renderGraphValue(c, row, idx, colIndex);
                            if(Boolean(c.option.print))
                                return(renderCellSelf(c, row, idx, colIndex));
                        }
                        return renderCell(c, row[c.id], idx, colIndex, row)
                    })
                }
                {
                    tools && tools.length > 0 && rowHover === row[uniqueKey]
                    ?
                    renderRowTool(row)
                    :
                    null
                }
            </TableRowDiv>
        );
    };

    /*
    Backup:
                <TableBodyBox width={RowWidth} onMouseLeave={()=>setRowHover(null)}>
                    <TableTopMarginBox>&nbsp;</TableTopMarginBox>
                    {
                        recFound.map((rec, idx) => {return renderRow(rec, idx)})
                    }
                    {
                        EmptyRows.map((rec, idx) => {return renderRow(rec, idx)})
                    }
                </TableBodyBox>
    */
   
    return ( // width={width}
        <FlexyTableContainer ref={contentRef} aria-label='flexy-table-container'
                    onMouseEnter={onMouseEnter}
        >
            <TableContainer width={width} onScroll={onBoxScroll}>
                <TableBodyBox width={RowWidth} onMouseLeave={()=>setRowHover(null)}>
                    <TableTopMarginBox>&nbsp;</TableTopMarginBox>
                    {
                        blankTopHeight > 0
                        ? <Box height={blankTopHeight} />
                        : null
                    }
                    {
                        recToPrint.map((rec, idx) => {return renderRow(rec, idx + rowA)})
                    }
                    {
                        EmptyRows.map((rec, idx) => {return renderRow(rec, idx)})
                    }
                    {
                        blankBottomHeight > 0
                        ? <Box height={blankBottomHeight} style={{backgroundColor:'#f9f9f9'}}/>
                        : null
                    }
                </TableBodyBox>
            </TableContainer>
            <TableHeaderRow right={width>=RowWidth ? 0 : 13}
                onMouseMove={onMouseMoveInHeaderRow}
                onMouseUp={onMouseUpInHeaderRow}
            >
                <TableHeaderRowContent left={-scrollPos.left}>
                    <HeaderColumnDiv
                        style={{
                            width: FlexySize.IndexCellWidth - FlexySize.CellPaddingLeft, 
                            textAlign:'center',
                            borderRight: '1px solid #cccccc'
                        }}>
                            <HeaderColumnLabel>
                                {
                                    hideFirstTopButton
                                    ?
                                    <IconButton size={AppWord.SMALL}
                                        disabled
                                    >
                                        <MenuIcon fontSize={AppWord.SMALL} />
                                    </IconButton>
                                    :
                                    <>
                                    <IconButton size={AppWord.SMALL}
                                        id={IdFirstTopButton}
                                        onClick={(e)=>setAnchorTopMenu(e.currentTarget)}
                                    >
                                        <MenuIcon fontSize={AppWord.SMALL} />
                                    </IconButton>
                                    <Menu
                                        id={IdFirstTopMenu}
                                        open={Boolean(anchorTopMenu)}
                                        anchorEl={anchorTopMenu}
                                        onClose={()=>setAnchorTopMenu(null)}
                                        MenuListProps={{'aria-labelledby':IdFirstTopButton}}
                                    >
                                        <MenuItem
                                            onClick={onClickSaveExcel}
                                        >
                                            <ListItemIcon>
                                                <img src={ExcelIconSvg} height={21} alt="Excel"/>
                                            </ListItemIcon>
                                            <ListItemText>엑셀파일 다운로드</ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={onClickCopyToClipboard}
                                        >
                                            <ListItemIcon>
                                                <ContentCopyIcon color={AppPalette.PrimaryColor} fontSize={AppWord.SMALL} />
                                            </ListItemIcon>
                                            <ListItemText>클립보드로 복사</ListItemText>
                                        </MenuItem>
                                        {
                                            hideColumnConfig ? null :
                                            <MenuItem
                                                onClick={onClickConfigColumns}
                                            >
                                                <ListItemIcon>
                                                    <DisplaySettingsIcon color={AppPalette.PrimaryColor} fontSize={AppWord.SMALL} />
                                                </ListItemIcon>
                                                <ListItemText>열(칼럼) 설정</ListItemText>
                                            </MenuItem>
                                        }
                                    </Menu>
                                    </>
                                }
                            </HeaderColumnLabel>
                    </HeaderColumnDiv>
                    {
                        colList.map((c,idx) => {
                            if(c.option.type===TableColumnType.GRAPH)
                                return(renderGraphHeaderColumn(c,idx));
                            return(renderHeaderColumn(c, idx))
                        }) }
                </TableHeaderRowContent>
            </TableHeaderRow>
            {
                pagination
                ?
                <FooterBox>footer for pagination</FooterBox>
                :
                null
            }
            {
                bubbleItem
                ?
                <FlexyTableBubble
                    maxWidth={width-FlexySize.IndexCellWidth - 50}
                    maxHeight={TableBodyHeight - FlexySize.HeaderRowHeight - 20}
                    columns={colList}
                    data={bubbleItem}
                    mouseEvent={enteringEvent}
                />
                :
                null
            }
            <ColumnConfigDialog
                open={openColumnConfiguration}
                tableName={name}
                tableColumns={columns}
                onClose={()=>setOpenColumnConfiguration(false)}
            />
        </FlexyTableContainer>
    );
}
/*

            <HeightReader onCompleteRender={onChangeHeight} />
                            <FormatListNumberedIcon fontSize='small' style={{verticalAlign:'-5px'}} />
*/

