// act_log/ActLogGql.js
import { gql } from "@apollo/client";
import { TableColumnType } from "../../model/AppConst";

export const ActLogDictionary = {
    errorTitle: {
        ListLogin: ' 사용자 로그인 활동 목록 조회 오류',
        List: '사용자 활동 정보 목록 조회 오류',
    }
};

export const LoginTableColumns = [
    { id: 'custName', label: '업체', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userName', label: '이름', width: 60, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'actTime', label: '로그인 시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'regUserId', label: 'ID', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custId', label: 'CUST_ID', width: 90, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];


const ACT_LOG_LIST_PART = gql`
fragment ActLogListPart on ActLog {
    logNid
    tableName
    actType
    actTime
    procName
    actText
    custId
    sessionKey
    regUserId
    userName
    custName
}
`;

// 특정 사용자 세션 전체활동 조회.
export const LIST_ACT_LOG = gql`
${ACT_LOG_LIST_PART}
query ListActLog($sessionKey:String, $regUserId:String) {
    actLogList(sessionKey:$sessionKey, regUserId:$regUserId) {
        ...ActLogListPart
    }
}
`;

// 일정 기간동안 로그인한 기록만 목록 조회.
export const LIST_ACT_LOG_LOGIN = gql`
${ACT_LOG_LIST_PART}
query ListActLogLogin($timeFrom:String, $timeTill:String) {
    actLogListLogin(timeFrom:$timeFrom, timeTill:$timeTill) {
        ...ActLogListPart
    }
}
`;

// 메뉴선택, 중요행위 실행 시 로그 기록. 오류는 처리하지 않음.
export const ADD_ACT_LOG = gql`
mutation AddActLog($actLog:InputActLogAdd!) {
  actLogAdd(actLog: $actLog) {
    ok
    message
  }
}
`;

