// term_mng_log/TermMngLogGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const TermMngLogDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        logNid : { id: 'logNid', required: true, toAdd: false, toEdit: true, },
        //regTime : { id: 'regTime', required: true, toAdd: false, toEdit: true, },
        termId : {
            id: 'termId', label: '단말기ID', type: 'text', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isRegexpMatch(value,/^\d{3,}$/)},
        },
        custName : {
            id: 'custName', label: '업체명', type: 'text', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.textInRange(value,1,60)},
        },
        acNetworkCd : {
            id: 'acNetworkCd', label: '통신사', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        /* changeDate : {
            id: 'changeDate', label: '변경일자', type: 'date', required: false,
            toAdd: true, toEdit: true,
        }, */
        acTermChangeCd : {
            id: 'acTermChangeCd', label: '변경사유', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        openerOld : {
            id: 'openerOld', label: '양도인', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },
        acModelCd : {
            id: 'acModelCd', label: '변경후 모델', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        acModelCdOld : {
            id: 'acModelCdOld', label: '변경전 모델', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        serialNo : {
            id: 'serialNo', label: '변경후 S/N', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,50)},
        },
        serialNoOld : {
            id: 'serialNoOld', label: '변경전 S/N', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,50)},
        },
        openerName : {
            id: 'openerName', label: '양수인', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },
        changeMemo : {
            id: 'changeMemo', label: '변경메모', type: 'text', required: false,
            toAdd: false, toEdit: true,
            help: '300자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,1000)},
        },
        usimNum : {
            id: 'usimNum', label: '변경후 USIM', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },
        usimNumOld : {
            id: 'usimNumOld', label: '변경전 USIM', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        acBillTypeCd : {
            id: 'acBillTypeCd', label: '변경후 요금제', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        acBillTypeCdOld : {
            id: 'acBillTypeCdOld', label: '변경전 요금제', type: 'text', required: false,
            toAdd: true, toEdit: true,
        },
        supportAmount : {
            id: 'supportAmount', label: '단말지원약정', type: 'number', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return value > 0 && value < 9999999},
        },
        /* custId : {
            id: 'custId', label: 'custId label', type: 'text', required: false,
            toAdd: true, toEdit: true,
        }, */

    },
    errorTitle: {
        List: '개통변경 목록조회 오류', // 신규는 없음.
        Edit: ' 정보 수정 오류',
        Remove: ' 정보 삭제 오류',
        Get: ' 정보 조회 오류',
    }
};

const TERM_MNG_LOG_LIST_PART = gql`
fragment TermMngLogListPart on TermMngLog {
    logNid
    regTime
    termId
    custId
    custName
    acTermChangeCd
    acTermChangeValue
    openerOld
    acModelCdOld
    acModelValueOld
    serialNoOld
    openerName
    acModelCd
    acModelValue
    serialNo
    changeDate
    changeMemo
    acNetworkCd
    acNetworkValue
    usimNum
    usimNumOld
    acBillTypeCd
    acBillTypeValue
    acBillTypeCdOld
    acBillTypeValueOld
    supportAmount
    regUserId
}
`;

export const LIST_TERM_MNG_LOG = gql`
${TERM_MNG_LOG_LIST_PART}
query ListTermMngLog($termMngLogSearch:InputTermMngLogSearch!) {
    termMngLogList(termMngLogSearch:$termMngLogSearch) {
        ...TermMngLogListPart
    }
    termMngLogCustList {
        custName
    }
}
`;
/*
InputTermMngLogSearch {
    searchField: String
    search: String
    dateFrom: String
}
*/

export const GET_TERM_MNG_LOG = gql`
${TERM_MNG_LOG_LIST_PART}
query GetTermMngLog($logNid:Int!) {
    termMngLogItem(logNid:$logNid) {
        ...TermMngLogListPart
    }
}
`;

export const LIST_TERM_MNG_LOG_CUST = gql`
query ListTermMngLogCust {
    termMngLogCustList {
        custName
    }
}
`;

export const EDIT_TERM_MNG_LOG = gql`
mutation EditTermMngLog($termMngLog:InputTermMngLogEdit!) {
  termMngLogEdit(termMngLog: $termMngLog) {
    ok
    message
  }
}
`;

export const REMOVE_TERM_MNG_LOG = gql`
mutation RemoveTermMngLog($termMngLog:InputTermMngLogRemove!) {
  termMngLogRemove(termMngLog: $termMngLog) {
    ok
    message
  }
}
`;
