// VehInfoInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress,
    FormControl, InputLabel, Select, MenuItem,
    FormControlLabel, Checkbox, FormGroup
 } from '@mui/material';
import { AppPalette, CvoCodes, AppWord, AppObject } from '../../model/AppConst';
import Util from '../../model/Util';
import { VehInfoDictionary } from './VehInfoGql';
import { ADD_TERM_INFO_SMART_LOGIS } from '../term_info/TermInfoGql';
// import { LIST_TERM_INFO_AVAILABLE } from '../term_info/TermInfoGql';
import { codeTreeRepo } from '../../model/CvoModel';
import CodeSelector from '../common/CodeSelector';
import ConfirmDialog from '../message/ConfirmDialog';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import Time24Picker from '../common/Time24Picker';
import ValueUtil from '../../model/ValueUtil';
import { useLazyQuery, useReactiveVar, useMutation } from '@apollo/client';
import { LISTS_OF_GROUP_TON_TERM, LIST_AVAILABLE_TERMS } from './VehInfoGql';
import VehGroupNode from '../../model/VehGroupNode';
import VehTypeNode from '../../model/VehTypeNode';
import ExpanTree from '../common/ExpanTree/ExpanTree';

const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const EditorContentTable = styled(Box)({display:'table', width:'100%'});

const EditorContentRow = styled(Box)({display:'table-row'});

const InputColumn = styled(Box)({
    display: 'table-cell',
    minWidth: 240, // adjust here.
    padding: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
    minHeight: 80, // check해 봄. 74 - padding 10
});

const LabelBox = styled(Box)({
    fontSize: AppWord.SMALL, color:'#555', marginBottom: 5
});

const TreeBox = styled(Box)({
    overflow: 'auto',
    border: '1px solid #cccccc',
    borderRadius: 5,
    height: 200,
    padding: 10
});

const Info = styled('span')({
    fontSize: AppWord.SMALL,
    color:'#666677'
});

const InputMap = VehInfoDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.

function instr(bigStr, smallStr) {
    if(bigStr) {
        return bigStr.indexOf(smallStr) >= 0;
    }
    return false;
}

export default function VehInfoInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    onRequestTermOff, // Ask controller to take off current device.
    responseSaving // Response if result is NOT OK.
}) {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [openSmartLogisEditor, setOpenSmartLogisEditor] = useState(false);
    const [termId, setTermId] = useState('');
    const [termIdOk, setTermIdOk] = useState(true);
    const [termList, setTermList] = useState([]);
    const [vehGroupTreeData, setVehGroupTreeData] = useState([]);
    const [vehTonTreeData, setVehTonTreeData] = useState([]);
    const [codeOneLabel, setCodeOneLabel] = useState('');
    const [codeTwoLabel, setCodeTwoLabel] = useState('');
    const [custCodeOne, setCustCodeOne] = useState([]);
    const [custCodeTwo, setCustCodeTwo] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null); // for response error
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    // GraphQL
    const [getAllSelectorData, responseLists] = useLazyQuery(LISTS_OF_GROUP_TON_TERM, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteSelectorData(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "가용단말기, 톤, 그룹 데이터 조회 오류"})}
    });
    const [getAvailableTerms, responseListTerms] = useLazyQuery(LIST_AVAILABLE_TERMS, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteSelectorData(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "가용단말기 조회 오류"})}
    });
    const [addTermInfoSmartLogis, responseAddSmartLogis] = useMutation( ADD_TERM_INFO_SMART_LOGIS, {
		onCompleted: (data, option) => onCompleteAddSmartLogis(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: "스마트로지스 추가 오류"})}
	} );

    useEffect(()=>{
        if(item) {
			const d = {...item};
			// d.tel = ValueUtil.reformWith(item.tel, AppWord.NUM_N_HYPN);
			d.vehAddr = ValueUtil.nullToString(item.vehAddr);
			d.driverName = ValueUtil.nullToString(item.driverName);
			d.driverMobile = ValueUtil.nullToString(item.driverMobile);
			d.driveCanStart = ValueUtil.nullToMidnight(item.driveCanStart);
            if(d.driveMustEnd === '23:59:59') d.driveMustEnd = '00:00:00';
			else d.driveMustEnd = ValueUtil.nullToMidnight(item.driveMustEnd);
			d.dailyStatStart = ValueUtil.nullToMidnight(item.dailyStatStart);
			d.dailyStatEnd = ValueUtil.nullToMidnight(item.dailyStatEnd);
            setInputData(d);
        }
		else setInputData(defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
    }, [item]);

    useEffect(()=>{
        if(open) {
            getAllSelectorData();
            setOpenSmartLogisEditor(false);
        }
    }, [getAllSelectorData, open]);

    const termPowerList  = ValueUtil.codeToSelectList(CvoCodes.TERM_POWER_CD, CodeTree);

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

    const changeInputData = (data) => {
        setInputData(data);
        setChanged(true);
    };

    const onCompleteAddSmartLogis = (data, clientOption) => {
        if(data.termInfoAddSmartLogis.ok) {
            setOpenSmartLogisEditor(false);
            getAvailableTerms(); // Again
            Util.bubbleSnack("스마트로지스 단말기 추가 완료!!");
        }
        else setResponseAlert({open:true, resultData: data.termInfoAddSmartLogis, title: "스마트로지스용 단말기 추가 오류"});
    };

    const onCompleteSelectorData = (data, option) => {
        if(data.termInfoListAvailable) {
            const list = [...data.termInfoListAvailable];
            if(isEdit && item.devId > 0) list.unshift({devId:item.devId, termId:item.termId, model:'현재 단말기'});
            //setTermList(data.termInfoListAvailable);
            setTermList(list);
        }
        if(data.vehTonInfoList) {
            const typeTonTree = VehTypeNode.makeTypeTonTree(data.vehTonInfoList);
            if(isEdit && item) VehTypeNode.checkTon(typeTonTree, item.vehTonId);
            setVehTonTreeData(typeTonTree);
        }
        if(data.vehGroupList) {
            const topGroups = VehGroupNode.makeTree(data.vehGroupList);
            if(isEdit && item) {
                if(item.groupCsv && item.groupCsv.length > 0) {
                    const groups = item.groupCsv.split(",").map((gstr) => parseInt(gstr));
                    VehGroupNode.checkNodes(topGroups, groups);
                }
            }
            setVehGroupTreeData(topGroups);
        }
        if(data.custOwnCodeTree) {
            const codeSet = ValueUtil.custOwnCode(data.custOwnCodeTree);
            if(codeSet.VEH01_CD) {
                setCodeOneLabel(codeSet.VEH01_CD.codeName);
                setCustCodeOne(codeSet.VEH01_CD.children);
            }
            else {
                setCodeOneLabel('');
                setCustCodeOne([]);
            }
            if(codeSet.VEH02_CD) {
                setCodeTwoLabel(codeSet.VEH02_CD.codeName);
                setCustCodeTwo(codeSet.VEH02_CD.children);
            }
            else {
                setCodeTwoLabel('');
                setCustCodeTwo([]);
            }
        }
    };

    const onClickCreateSmartLogis = () => {
        if(ValueUtil.goodSmartLogisNumber(termId)) {
            const param = {variables: {termInfo: {termId:termId, etc:'차량정보 '+(isEdit?"수정":"추가")+"작업 중 추가"}}};
            addTermInfoSmartLogis(param);
        }
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // if(isEdit) delete param.termId;
        if(custCodeOne.length < 1) param.veh01Cd = -1;
        if(custCodeTwo.length < 1) param.veh02Cd = -1;

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '차량정보가 변경되었습니다.',
                messages: ['차량정보 입력이 진행되고 있습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);

        changeInputData(newData);
        setInputError(newError);
        setHasError(hasError);
    };

    const onChangeNumAndHyphen = (event) => {
        if(event.target.value) {
            if(/[^-0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

    const onChangeTermSelector = (event) => {
        const newData = {...inputData};
        newData.devId = event.target.value;
        changeInputData(newData);
    };

    const onTonChecked = (nodeList, isAdding, node) => {
        const roots = [...vehTonTreeData];
        const newData = {...inputData};
        newData.vehTonId = node.vehTonId;
        setVehTonTreeData(roots);
        changeInputData(newData);
    };

    const onGroupChecked = (nodeList, isAdding, node) => {
        const groupCsv = VehGroupNode.getPrimaryDataChecked(vehGroupTreeData).join(",");
        const newData = {...inputData};
        newData.groupCsv = groupCsv;
        const roots = [...vehGroupTreeData];
        setVehGroupTreeData(roots);
        changeInputData(newData);
    };

    // 스마트로지스 별도 추가.
    const onChangeSmartLogisNumber = (event) => {
        if(event.target.value) {
            if(/[^0-9]/.test(event.target.value)) return;
        }
        setTermId(event.target.value);
        setTermIdOk(ValueUtil.goodSmartLogisNumber(event.target.value));
    };

    // Time picker as well
    const onChangeValueForId = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        changeInputData(data);
    };

    const onChangeSelect = (event) => {
        const data = {...inputData};
        data[event.target.name] = event.target.value;
        changeInputData(data);
    };

    const onChangeDoorSensor = (event) => {
        const id = event.target.name;
        const bool = event.target.checked;
        const data = {...inputData};
        const array = data.doorSensors ? data.doorSensors.split(",") : [];
        const idx = array.indexOf(id);
        if(idx >= 0 && !bool) {
            const newArray = array.filter(s => s !== id);
            data.doorSensors = newArray.length>0 ? newArray.join(",") : null;
        }
        else if(idx < 0 && bool) {
            array.push(id);
            data.doorSensors = array.join(",");
        }
        changeInputData(data);
    };

    const onClickTermOff = () => {
        console.log("onClickTermOff");
        setPromptToConfirm({
            data: true,
            title: '차량으로부터 단말기 떼어내기.',
            messages: [
                '차량으로부터 현재 단말기를 떼어내려 합니다.',
                '단말기를 떼어내면 차량의 위치정보가 기록되지 않습니다.',
                '떼어낸 단말기는 다른 차량에 장착할 수 있습니다.',
                '실제 떼어내기나 장착 작업은 별도 요청이 필요합니다.',
                '단, 시거잭에 연결된 경우에는 고객사에서 직접 작업하시면 됩니다.',
                '진행 하시겠습니까?'
            ],
            labelToYes: '떼어내기',
            callback: (yes) => {
                setPromptToConfirm(null);
                if(yes) {
                    onRequestTermOff(item);
                }
            }
        });
    };

    // redner -------------------
    const renderConfirmDialog = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size={AppWord.SMALL}
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    const renderSelectorField = (dict, value, selectFrom) => {
        return(
            <FieldBox>
                <CodeSelector
                    id={dict.id}
                    value={value || dict.default}
                    label={dict.label}
                    codes={selectFrom}
                    onChange={onChangeValueForId}
                />
            </FieldBox>
        );
    };

    const renderTermSelector = () => {
        const labelId = "labelOf"+InputMap.devId.id;
        return(
            <FieldBox>
                <FormControl>
                    <InputLabel id={labelId}>{InputMap.devId.label}</InputLabel>
                    <Select
                        labelId={labelId}
                        id={InputMap.devId.id}
                        name={InputMap.devId.id}
                        value={inputData.devId || ''}
                        label={InputMap.devId.label}
                        onChange={onChangeTermSelector}
                        style={{minWidth:250}}
                    >
                        {
                            /* isEdit && inputData.devId > 0
                            ?
                            <MenuItem value={inputData.devId}>{inputData.termId} (현재 단말기)</MenuItem>
                            : null */
                        }
                        {
                            termList.map((term) => {
                                return(
                                    <MenuItem key={term.devId} value={term.devId}>{term.termId} ({term.model})</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
                {
                    inputData.devId ? null :
                    <div style={{marginBottom:10}}><Info>주의: 단말기를 선택하지 않으면 위치정보가<br/> 기록되지 않습니다.</Info></div>
                }
            </FieldBox>
        );
    };

    const renderCustOwnCodeSelector = (dict, label, value, list) => {
        const labelId = "labelOf" + dict.id;
        const defVal = value < 1 ? list[0].codeNum : value;
        return(
            <FieldBox>
                <FormControl>
                    <InputLabel id={labelId}>{label}</InputLabel>
                    <Select
                        id={dict.id}
                        name={dict.id}
                        labelId={labelId}
                        value={defVal}
                        label={label}
                        onChange={onChangeSelect}
                    >
                        {
                            list.map((code) => {
                                return(
                                    <MenuItem key={code.codeNum} value={code.codeNum}>
                                        {code.title}
                                    </MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </FieldBox>
        );
    };

    const renderTimePicker = (dict, value) => {
        return(
                <Time24Picker
                    id={dict.id}
                    label={dict.label}
                    value={value}
                    disableMinute={true}
                    onChange={onChangeValueForId}
                />
        );
    };

    return (
        <Dialog open={open} maxWidth="lg">
            <EditorTitle>
                {isEdit ? '차량정보 수정' : '차량 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentTable>
                    <EditorContentRow>
                        <InputColumn>
                            {renderTextFieldBox(InputMap.vehPlates, inputData.vehPlates,
                                inputError.vehPlates, onChangeTextData) /* 차량번호 */}
                            {renderTextFieldBox(InputMap.vehAlias, inputData.vehAlias,
                                inputError.vehAlias, onChangeTextData) /* 지도표시명 */}
                            {renderTextFieldBox(InputMap.vehAddr, inputData.vehAddr,
                                inputError.vehAddr, onChangeTextData) /* 차고지주소 */}

                        </InputColumn>
                        <InputColumn>
                            {renderTextFieldBox(InputMap.driverName, inputData.driverName,
                                inputError.driverName, onChangeTextData) /* 운전자명 */}
                            {renderTextFieldBox(InputMap.driverMobile, inputData.driverMobile,
                                inputError.driverMobile, onChangeNumAndHyphen) /* 운전자휴대전화 */}
                            <FieldBox>
                                <LabelBox>{InputMap.doorSensors.label}</LabelBox>
                                <FormGroup row>
                                    <FormControlLabel
                                        label="운전석방향"
                                        control={
                                            <Checkbox name="door0"
                                                checked={instr(inputData.doorSensors,'door0')}
                                                onChange={onChangeDoorSensor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label="보조석방향"
                                        control={
                                            <Checkbox name="door1"
                                                checked={instr(inputData.doorSensors,'door1')}
                                                onChange={onChangeDoorSensor}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label="뒷문"
                                        control={
                                            <Checkbox name="door2"
                                                checked={instr(inputData.doorSensors,'door2')}
                                                onChange={onChangeDoorSensor}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </FieldBox>
                        </InputColumn>
                        {
                            openSmartLogisEditor
                            ?
                            <InputColumn>
                                <FieldBox style={{backgroundColor:'#f5f5f5', borderRadius:5, padding:10}}>
                                    <TextField id={VehInfoDictionary.inputSmartLogis.termId.id} size={AppWord.SMALL}
                                        type={VehInfoDictionary.inputSmartLogis.termId.type}
                                        label={VehInfoDictionary.inputSmartLogis.termId.label}
                                        required={VehInfoDictionary.inputSmartLogis.termId.required}
                                        value={termId}
                                        error={!termIdOk}
                                        helperText="안드로이드폰 번호를 숫자만 입력"
                                        onChange={onChangeSmartLogisNumber} />
                                    <div>
                                        <Button
                                            onClick={()=>onClickCreateSmartLogis()}
                                            variant='contained' color='primary'
                                            disabled={!termIdOk || !termId}>
                                            저장</Button>
                                        <Button onClick={()=>{setOpenSmartLogisEditor(false)}} color='warning'
                                            variant='contained'>취소</Button>
                                    </div>
                                </FieldBox>
                            </InputColumn>
                            :
                            <InputColumn>
                                {renderTermSelector() /* 단말기 선택 */}
                                {renderSelectorField(InputMap.termPowerCd, inputData.termPowerCd, termPowerList)  /* 단말기 전원 */}
                                    <Box>
                                        <Button size={AppWord.SMALL}
                                            onClick={()=>setOpenSmartLogisEditor(true)}
                                        >
                                            <PhonelinkRingIcon fontSize={AppWord.SMALL}/>
                                            새로운 안드로이드폰 단말기 추가
                                        </Button>
                                    </Box>
                            </InputColumn>
                        }
                    </EditorContentRow>
                    <EditorContentRow>
                        <InputColumn>
                            <FieldBox>
                                <LabelBox>{InputMap.vehTonId.label}*</LabelBox>
                                <TreeBox style={!changed || inputData.vehTonId > 0 ? null : {border:'1px solid red'}}>
                                    <ExpanTree
                                        data={vehTonTreeData} onLeafChecked={onTonChecked}
                                        exclusive={true}
                                    />
                                </TreeBox>
                                {inputData.vehTonId > 0 ? null : <Info>톤수를 지정해 주세요.</Info>}
                            </FieldBox>
                        </InputColumn>
                        <InputColumn>
                            <FieldBox>
                                <LabelBox>{InputMap.groupCsv.label}</LabelBox>
                                <TreeBox>
                                    <ExpanTree
                                        data={vehGroupTreeData} onLeafChecked={onGroupChecked}
                                    />
                                </TreeBox>
                            </FieldBox>
                        </InputColumn>
                        <InputColumn>
                            <FieldBox>
                                {renderTimePicker(InputMap.driveCanStart, inputData.driveCanStart)}
                                &nbsp;
                                {renderTimePicker(InputMap.driveMustEnd, inputData.driveMustEnd)}
                                <div style={{marginBottom:20}}>
                                    <Info>운행허용시간을 제한하고자 할 때<br/>이 시간 값들을 지정할 수 있습니다.</Info>
                                </div>
                            </FieldBox>
                            <FieldBox>
                                {renderTimePicker(InputMap.dailyStatStart, inputData.dailyStatStart)}
                                &nbsp;
                                {renderTimePicker(InputMap.dailyStatEnd, inputData.dailyStatEnd)}
                                <div style={{marginBottom:20}}>
                                    <Info>일별 통계작성 기준을 0시부터 24시간이 아닌<br/>시간대를 지정할 수 있습니다.</Info>
                                </div>
                            </FieldBox>
                        </InputColumn>
                    </EditorContentRow>
                    <EditorContentRow>
                        <InputColumn>
                            {
                                custCodeOne.length > 0
                                ? renderCustOwnCodeSelector(InputMap.veh01Cd, codeOneLabel, inputData.veh01Cd, custCodeOne)
                                : null
                            }
                        </InputColumn>
                        <InputColumn>
                            {
                                custCodeTwo.length > 0
                                ? renderCustOwnCodeSelector(InputMap.veh02Cd, codeTwoLabel, inputData.veh02Cd, custCodeTwo)
                                : null
                            }
                        </InputColumn>
                    </EditorContentRow>
                </EditorContentTable>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        {
                            isEdit && item.devId
                            &&
                            <Button
                                onClick={onClickTermOff}
                                variant={AppPalette.VariantContained}
                                color={AppPalette.WarnColor}
                                disabled={item.devId !== inputData.devId}
                            >
                                현재 단말기 떼어내기
                            </Button>
                        }
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed || !inputData.vehTonId || openSmartLogisEditor}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderConfirmDialog()}
        </Dialog>
    );
}