// VehGroupGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const VehGroupDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        // custId : {toAdd: true, toEdit: true},
        groupId : {toAdd: false, toEdit: true},
        //parentNodeId : {toAdd: true, toEdit: false},
        groupName : {
            id: 'groupName', label: '그룹이름', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '13자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.textInRange(value,1,40)},
        },
        groupDesc : {
            id: 'groupDesc', label: '그룹설명', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '200자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,200)},
        },
        isDefault : {
            id: 'isDefault', label: '기본그룹으로 설정', type: 'checkbox', required: true,
            toAdd: true, toEdit: true,
            default: 'N', trueValue: 'Y', falseValue: 'N',
            check: (value) => {return value==='Y' || value==='N'},
        },
        /*
        etc : {
            id: 'etc', label: 'etc label', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '800자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,800)},
        }, */

    },
    // for response error printing
    // queryName: {Add:'userInfoAdd', Edit:'userInfoEdit', Remove:'userInfoRemove'},
    errorTitle: {
        Add: '그룹 정보 생성 오류',
        Edit: '그룹 정보 수정 오류',
        Remove: '그룹 정보 삭제 오류',
        Get: '그룹 정보 조회 오류',
    }
};


const VEH_GROUP_LIST_PART = gql`
fragment VehGroupListPart on VehGroup {
    groupId
    custId
    groupName
    parentNodeId
    groupDesc
    treeLevel
    isLastNode
    isDefault
    etc
    targetCustIdCsv
    regUserId
    regDate
}
`;

export const LIST_VEH_GROUP_SIMPLE = gql`
query ListVehGroup($custId:String) {
    vehGroupList(custId:$custId) {
        groupId
        custId
        groupName
        parentNodeId
    }
}
`;

export const LIST_VEH_GROUP_FOR_USER = gql`
query ListVehGroupForUser($userId:String!) {
    vehGroupListForUser(userId:$userId) {
        groupId
        groupName
        parentNodeId
        userId
        treeLevel
    }
}
`;

export const LIST_VEH_GROUP = gql`
${VEH_GROUP_LIST_PART}
query ListVehGroup($custId:String) {
    vehGroupList(custId:$custId) {
        ...VehGroupListPart
    }
}
`;

export const GET_VEH_GROUP = gql`
${VEH_GROUP_LIST_PART}
query GetVehGroup($groupId:Int!) {
    vehGroupItem(groupId:$groupId) {
        ...VehGroupListPart
    }
}
`;


export const ADD_VEH_GROUP = gql`
mutation AddVehGroup($vehGroup:InputVehGroupAdd!) {
    vehGroupAdd(vehGroup: $vehGroup) {
        ok
        message
    }
}
`;

export const EDIT_VEH_GROUP = gql`
mutation EditVehGroup($vehGroup:InputVehGroupEdit!) {
    vehGroupEdit(vehGroup: $vehGroup) {
        ok
        message
    }
}
`;

export const REMOVE_VEH_GROUP = gql`
mutation RemoveVehGroup($vehGroup:InputVehGroupRemove!) {
    vehGroupRemove(vehGroup: $vehGroup) {
        ok
        message
    }
}
`;



