//UserGetAlarmList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_USER_GET_ALARM, ADD_USER_GET_ALARM, REMOVE_USER_GET_ALARM,
	UserGetAlarmDictionary
} from './UserGetAlarmGql';
import UserGetAlarmInput from './UserGetAlarmInput';
import { AppObject } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';


const UgaContainer =  styled(Box)({
    display:'flex', height:'100%', overflow:'auto',
    alignItems:'flex-start', flexWrap:'wrap'
});
const WaitingMsg = styled(Box)({width:'100%', textAlign:'center', marginTop:50, fontSize:'1.3rem', color:'#99ffff'});

const UgaItemBox = styled(Box)({
    minWidth:200,
    padding:5, margin:5,
    borderRadius:10
});

const ItemTitle = styled(Box)({fontSize:'1.05rem', fontWeight:'bold'});

const ErrorTitle =UserGetAlarmDictionary.errorTitle;

export default function UserGetAlarmList({
    userInfo
}) {
    const [dataList, setDataList] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);
    // ##### Call GraphQL to get List #####
    const [getUserGetAlarmList, responseList] = useLazyQuery(LIST_USER_GET_ALARM, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)}, 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    // ##### GraphQL Mutation.  ###
    const [addUserGetAlarm, responseAdd] = useMutation( ADD_USER_GET_ALARM, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [removeUserGetAlarm, responseRemove] = useMutation( REMOVE_USER_GET_ALARM, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        if(userInfo) getUserGetAlarmList({variables: {userId: userInfo.userId}});
    }, [userInfo, getUserGetAlarmList]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.userGetAlarmList) {
            setDataList(data.userGetAlarmList);
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.userGetAlarmSet.ok) setDataList(data.userGetAlarmSet.list);
        else setResponseAlert({open:true, resultData: data.userGetAlarmSet, title: ErrorTitle.Add});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.userGetAlarmRemove.ok) setDataList(data.userGetAlarmRemove.list);
        else setResponseAlert({open:true, resultData: data.userGetAlarmRemove, title: ErrorTitle.Remove});
    };

    const onClickRemove = (param) => {
        removeUserGetAlarm({variables: {userGetAlarm: param}});
    };
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {userGetAlarm: ValueUtil.refineToSubmit(item)}};
        addUserGetAlarm(param);
    };

    if(ValueUtil.hasAnyAuthError(
        responseList, responseAdd, responseRemove
    )) userInfoRepo(NoUser);

    return(
        <UgaContainer>
            {
                dataList.length===0 ? <WaitingMsg>데이터 조회중입니다.</WaitingMsg>
                :
                dataList.map((item)=>{
                    return(
                        <UserGetAlarmInput key={item.alarmTypeId}
                            item={item}
                            userInfo={userInfo}
                            onClickMutate={onClickMutate}
                            onClickRemove={onClickRemove}
                        />
                    );
                })
            }
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </UgaContainer>
    );

}