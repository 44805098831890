// VehRouteList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button, IconButton, FormControl, InputLabel, InputAdornment, OutlinedInput } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CellTowerIcon from '@mui/icons-material/CellTower'; // cell position
import GpsFixedIcon from '@mui/icons-material/GpsFixed'; // speed < 2
import NavigationIcon from '@mui/icons-material/Navigation'; // speed >= 2
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ResponseAlert from "../message/ResponseAlert";
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType, AppPalette } from '../../model/AppConst';
import { useLazyQuery } from '@apollo/client';
import { LIST_VEH_ROUTE } from './VehRouteGql';
import DurationPicker from '../common/DurationPicker';
import dayjs from 'dayjs';

const dcPad = AppNumber.dataContainerPadding;
const VehRouteListContainer = styled(Box)({
    position:"absolute", top:0, right:0, bottom: 0, left: dcPad,
	display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

function printDoorState(v) {
    if(v===null || v===undefined) return null;
    if(v) return <LockOpenIcon size={AppWord.SMALL} color={AppPalette.WarnColor}/>;
    else return <LockIcon size={AppWord.SMALL} color={AppPalette.DisabledColor}/>;
}

const StateColumn = {
    id:'-mv-mark', label:'상태', width:50, show: true,
    option: {
        aligh:'center', type: TableColumnType.GRAPH,
        form: (rec, rowIndx) => {
            if(rec.posSys==='C' || rec.posSys===null) return <CellTowerIcon fontSize={AppWord.SMALL} color='disabled' />
            if(rec.speed < 2) return <GpsFixedIcon fontSize={AppWord.SMALL} color='success'/>;
            return <NavigationIcon fontSize={AppWord.SMALL} color='primary' style={{transform: 'rotate('+rec.direction+'deg)'}}/>;
        }
    }
};

// TODO: 도어 개폐 값 출력.
const TableColumns = [    
    { id: 'vehPlates', label: '차량번호', width: 100, show: false, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehAlias', label: '지도표시명', width: 100, show: false, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpsTime', label: '최종보고', width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    StateColumn,
    { id: 'location', label: '위치', width: 400, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'speed', label: '속도(Km/h)', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'svcCondValue', label: '운행상태', width: 90, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'distKm', label: '운행거리(Km)', width: 100, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'sessionDist', label: '범주내 운행거리', width: 120, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr1', label: '전면온도', width: 70, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr3', label: '중면온도', width: 70, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr2', label: '후면온도', width: 70, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr4', label: '온도4', width: 70, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'door0', label: '운전석방향', width: 80, show: true, option:{align: 'center', print: (r,i,v)=>printDoorState(v)} },
    { id: 'door1', label: '보조석방향', width: 80, show: true, option:{align: 'center', print: (r,i,v)=>printDoorState(v)} },
    { id: 'door2', label: '뒷문', width: 60, show: true, option:{align: 'center', print: (r,i,v)=>printDoorState(v)} },
    { id: 'pwrTypeValue', label: '전원타입', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'posSysValue', label: '위치타입', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'poiName', label: '출발/도착지', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'bcdEventValue', label: '출발/도착', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'irActionName', label: '업무상태', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'roadName', label: '도로', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'roadMaxSpeed', label: '허용속도', width: 80, show: true, option:{align: 'center', type: TableColumnType.INTEGER} },
    { id: 'batteryVolt', label: '배터리전압', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoDist', label: '타코누적거리', width: 100, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoSpeed', label: '타코속도(Km/h)', width: 100, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },

    /*
    보여주지 않지만 사용할 수 있는:
	{ id: 'custId', label: Dict.custId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehId', label: Dict.vehId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'gpsDatetime', label: Dict.gpsDatetime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'direction', label: Dict.direction.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'svcCondCd', label: Dict.svcCondCd.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'eventCd', label: '화물칸 etc', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'pwrType', label: Dict.pwrType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'posSys', label: Dict.posSys.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'xDist', label: Dict.xDist.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'yDist', label: Dict.yDist.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'lat', label: Dict.paraDiff.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'lon', label: Dict.meridCode.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'itinRepType', label: Dict.itinRepType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'irAction', label: Dict.irAction.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },

    -- Not used
    { id: 'devId', label: Dict.devId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'termId', label: Dict.termId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'recvTime', label: Dict.recvTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'insertTime', label: Dict.insertTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'gpsLat', label: Dict.gpsLat.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpsLon', label: Dict.gpsLon.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'statusCode', label: Dict.statusCode.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'loadCondCd', label: Dict.loadCondCd.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpioBits', label: Dict.gpioBits.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'location', label: Dict.location.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custPoiCode', label: Dict.custPoiCode.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'addrPrefix', label: Dict.addrPrefix.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'poiDistM', label: Dict.poiDistM.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'roadRank', label: Dict.roadRank.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'cycleMake', label: Dict.cycleMake.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'cycleReport', label: Dict.cycleReport.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'midRoute', label: Dict.midRoute.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'minsBatteryAlive', label: Dict.minsBatteryAlive.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'isEngineOn01', label: Dict.isEngineOn01.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'isGpsGood01', label: Dict.isGpsGood01.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'extSensorName', label: Dict.extSensorName.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'extSensorData', label: Dict.extSensorData.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'tempr1Alarm', label: Dict.tempr1Alarm.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tempr2Alarm', label: Dict.tempr2Alarm.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tempr3Alarm', label: Dict.tempr3Alarm.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tempr4Alarm', label: Dict.tempr4Alarm.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'dataPath', label: Dict.dataPath.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'tachoTempr1', label: Dict.tachoTempr1.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr2', label: Dict.tachoTempr2.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr3', label: Dict.tachoTempr3.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr4', label: Dict.tachoTempr4.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr5', label: Dict.tachoTempr5.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr6', label: Dict.tachoTempr6.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid1', label: Dict.tachoHumid1.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid2', label: Dict.tachoHumid2.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid3', label: Dict.tachoHumid3.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid4', label: Dict.tachoHumid4.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid5', label: Dict.tachoHumid5.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid6', label: Dict.tachoHumid6.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoPeriod', label: Dict.tachoPeriod.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoRpm', label: Dict.tachoRpm.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tachoBreak', label: Dict.tachoBreak.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tachoTaillight', label: Dict.tachoTaillight.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tachoInput', label: Dict.tachoInput.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irLat', label: Dict.irLat.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irLon', label: Dict.irLon.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irYCoord', label: Dict.irYCoord.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irXCoord', label: Dict.irXCoord.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irDist', label: Dict.irDist.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irMins', label: Dict.irMins.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irPoiType', label: Dict.irPoiType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'irPoiSeq', label: Dict.irPoiSeq.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'dataDeploy', label: Dict.dataDeploy.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} }
    */
];

const EmptyDuration = {from:null, till:null};

export default function VehRouteList({
    vehPos,
    durationGiven,
	maxHeight,
	maxWidth,
    onClickShowPosList
}) {
    const [route, setRoute] = useState([]);
    const [duration, setDuration] = useState(EmptyDuration);
    const [search, setSearch] = useState('');
    const [itemSelected, setItemSelected] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getVehRouteList, responseList] = useLazyQuery(LIST_VEH_ROUTE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "경로조회 오류"})}
    });

    useEffect(()=>{
        setRoute([]);
        if(vehPos) {
            if(vehPos.gpsTime) {
                if(durationGiven) {
                    if(durationGiven.from && durationGiven.till) {
                        setDuration(durationGiven);
                        getVehRouteList({variables: {vehId: vehPos.vehId, timeFrom: durationGiven.from, timeTill: durationGiven.till}});
                    }
                    else setDuration(EmptyDuration);
                }
                else {
                    if(vehPos.gpsTime === AppWord.NOT_REAL_GPS_TIME) setDuration(EmptyDuration);
                    else {
                        const djs = dayjs(vehPos.gpsTime);
                        const date = djs.toDate();
                        const from = ValueUtil.getYmdHmsInputText(date, 5*3600);
                        setDuration({from:from, till: vehPos.gpsTime});
                    }
                }
            }
            else setDuration(EmptyDuration);
        }
        else setDuration(EmptyDuration);
    }, [vehPos, durationGiven, getVehRouteList]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        let gpsTimeSel = itemSelected ? itemSelected.gpsTime : undefined;

        if(data.vehRouteList) {
            const newRoute = [];
            let sdist = 0;
            let preDist = 0;
            let i = 0;
            for(const p of data.vehRouteList) {
                if(p.distKm > preDist) {
                    if(i>0) {
                        sdist += (p.distKm - preDist);
                    }
                    preDist = p.distKm;
                }
                p.sessionDist = sdist;
                if(p.distKm > 0) i++;
                newRoute.push(p);

                if(gpsTimeSel) {
                    setItemSelected(p);
                    gpsTimeSel = undefined;
                }
            }
            setRoute(newRoute);
        }
    };

    const onClickToGetList = () => {
        if(vehPos.vehId && duration.from && duration.till)
            getVehRouteList({variables: {vehId: vehPos.vehId, timeFrom: duration.from, timeTill: duration.till}});
    };

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
    };

    const onChangeFrom = (from) => {
        const dur = {...duration};
        dur.from = from;
        setDuration(dur);
    };

    const onChangeTill = (till) => {
        const dur = {...duration};
        dur.till = till;
        setDuration(dur);
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - AppNumber.dataContainerPadding;
	
    const TableHeight = height - clientSize.HeaderToolBoxHeight
        - clientSize.SmallBoxPadding * 2 - 2;

	//const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    return(
        <VehRouteListContainer>
            <HeaderBox>
                <ButtonBox>
                    <Button variant='contained' color="inherit"
                        onClick={()=>{onClickShowPosList()}}
                    >
                        닫기
                    </Button>
                </ButtonBox>
                <DurationPicker
                    from={duration.from} onChangeFrom={onChangeFrom}
                    till={duration.till} onChangeTill={onChangeTill}
                />
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!Boolean(duration) || !Boolean(vehPos)}
                    onClick={onClickToGetList}
                    style={{marginLeft:10, marginRight:20}}
                >
                    조회
                </Button>
                <FormControl variant='outlined' size='small'>
                    <InputLabel htmlFor="search-veh-route">검색</InputLabel>
                    <OutlinedInput id="search-veh-route" onChange={onChangeSearch}
                        value={search} style={{width:100}}
                        disabled={route.length === 0}
                        size='small'
                        endAdornment={
                            Boolean(search) ?
                            <InputAdornment position={AppWord.END}>
                                <IconButton
                                    onClick={()=>{setSearch('')}}
                                    size={AppWord.SMALL}
                                    edge={AppWord.END}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                            :
                            null
                        }
                    />
                </FormControl>
            </HeaderBox>
            <FlexyTable
                name="veh_route_list"
                uniqueKey="gpsTime"
                columns={TableColumns}
                records={route}
                search={search}
                onClickOneRow={onSelectItem}
				selected={itemSelected ? [itemSelected] : null}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehRouteListContainer>
    );

}
/*

            ////<PanTable
                name="veh_route_list"
                uniqueKey="gpsTime"
                height={TableHeight} width={width}
                columns={TableColumns}
                records={route}
                search={search}
                tools={[]}
				onClickOneRow={onSelectItem}
				selected={itemSelected ? [itemSelected] : null}
            />

*/