// MapSweeperStatList.js
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import useClientSize from '../hook/useClientSize';
import { IconButton, TextField, Button } from '@mui/material';
import ResponseAlert from '../message/ResponseAlert';
import FlexyTable from '../common/flexytable/FlexyTable';
import { sweeperStatRepo, sweeperSelectedRepo } from '../../model/CvoModel';
import { LIST_SWEEPER_STAT } from './SweeperStatGql';
import { AppNumber, AppObject, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';

const TheListContainer = styled(Box)({
    flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display: 'flex',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    alignItems: 'center',
});

const SweeperStatColumns = [
    { id: 'subComName', label: '업체명', width: 120, show: true, option:{align: 'left', type: TableColumnType.TEXT} },
    { id: 'vehAlias', label: '지도표시명', width: 100, show: true, option:{align: 'left', type: TableColumnType.TEXT} },
    //{ id: 'driverName', label: '기사', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'driverMobile', label: '휴대전화', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'runStatus', label: '운행상태', width: 70, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'vehJob', label: '수거종류', width: 110, show: true, option:{align: 'left', type: TableColumnType.TEXT} },
    { id: 'totalDist', label: '총 운행거리', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT}},
    { id: 'hoursRun', label: '총 운행시간', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT}},
    { id: 'avgSpeedRun', label: '평균운행속도', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT}},
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

const MarkDone = 'done';

export default function MapSweeperStatList({
    mapShell
}) {
    const [responseAlert, setResponseAlert] = useState(null);
    const [routeDate, setRouteDate] = useState(null); // 1 hour before.
    const [selectedItem, setSelectedItem] = useState(null);
    const records = useReactiveVar(sweeperStatRepo);
    const sweeperSelected = useReactiveVar(sweeperSelectedRepo);
    const statRequested = useRef();

    // ##### Call GraphQL to get List #####
    const [getSweeperStatList, responseList] = useLazyQuery(LIST_SWEEPER_STAT, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetSweeperStatList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "청소차량상황목록 조회 오류"})}
    });

    useEffect(()=>{
        if(records.length > 0) {
            setRouteDate(records[0].routeDate);
        }
        else {
            if(statRequested.current!==MarkDone) {
                const rd = ValueUtil.getYmdText(3600);
                getSweeperStatList({variables: {routeDate:rd}});
                setRouteDate(rd);
                statRequested.current = MarkDone;
            }
        }
    }, [records, getSweeperStatList])

    useEffect(()=>{
        if(sweeperSelected) setSelectedItem({...sweeperSelected});
        else {
            if(records.length>0) sweeperSelectedRepo(records[0]);
        }
    }, [sweeperSelected, records]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetSweeperStatList = (data, clientOption) => {
        if(data.sweeperStatList) {
            sweeperStatRepo(data.sweeperStatList);

            if(sweeperSelected) {
                // 선택된 차량이 있고 날짜만 바뀌었다면 재선택 해 준다.
                for(const ss of data.sweeperStatList) {
                    if(ss.vehId === sweeperSelected.vehId && ss.routeDate !== sweeperSelected.routeDate) {
                        sweeperSelectedRepo(ss);
                        break;
                    }
                }
            }
        }
    };

    const onChangeDate = (event) => {
        setRouteDate(event.target.value);
    };

    const onClickDownload = () => {
        getSweeperStatList({variables: {routeDate:routeDate}});
    };

    const onSelectItem = (sweeper) => {
        sweeperSelectedRepo(sweeper);
    };

    return (
        <TheListContainer>
            <HeaderBox>
                <TextField id='routeDate' type='date'
                    value={routeDate || ValueUtil.getYmdText(3600)}
                    size={AppWord.SMALL}
                    onChange={onChangeDate}
                />
                &nbsp;
                <Button size={AppWord.SMALL} variant={AppPalette.VariantContained}
                    onClick={onClickDownload}
                    disabled={!Boolean(routeDate)}
                >
                    조회
                </Button>
            </HeaderBox>
            <FlexyTable
                name="map_sweeper_stat_list"
                uniqueKey="vehId"
                columns={SweeperStatColumns}
                records={records}
                tools={[]}
                onClickOneRow={onSelectItem}
                selected={selectedItem ? [selectedItem] : []}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </TheListContainer>
    )
}
