// CustGetAlarmInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { AppPalette } from '../../model/AppConst';
import { CustGetAlarmDictionary } from './CustGetAlarmGql';
import ConfirmDialog from '../message/ConfirmDialog';
import Time24Picker from '../common/Time24Picker';
//import ResponseAlert from '../message/ResponseAlert';
import ValueUtil from '../../model/ValueUtil';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
});
const InputMap = CustGetAlarmDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.


export default function CustGetAlarmInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    alarmTypeList, // If ...
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        if(item) {
			const d = {...item};
			// d.tel = ValueUtil.reformWith(item.tel, AppWord.NUM_N_HYPN);
            setInputData(d);
        }
		else setInputData(defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
    }, [item]);

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

    const changeInputData = (data) => {
        setInputData(data);
        setChanged(true);
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // add extra data if necessary.

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '알람수신정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeNumAndHyphen = (event) => {
        if(event.target.value) {
            if(/[^0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

    const onChangeAlarmSelector = (event) => {
        const newData = {...inputData};
        newData.alarmTypeId = event.target.value;
        changeInputData(newData);
    };

    // Time picker as well
    const onChangeValueForId = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        changeInputData(data);
    };

	// If necessary
    // render -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange, disabled) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size="small"
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange}
                    disabled={disabled}
                />
            </FieldBox>

        );
    };

    const renderAlarmSelector = (disabled) => {
        const labelId = "labelOf"+InputMap.alarmTypeId.id;
        return(
            <FieldBox>
                <FormControl>
                    <InputLabel id={labelId}>{InputMap.alarmTypeId.label}</InputLabel>
                    <Select
                        labelId={labelId}
                        id={InputMap.alarmTypeId.id}
                        name={InputMap.alarmTypeId.id}
                        value={inputData.alarmTypeId || ''}
                        label={InputMap.alarmTypeId.label}
                        onChange={onChangeAlarmSelector}
                        style={{minWidth:250}}
                        disabled={disabled}
                    >
                        {
                            alarmTypeList.map((alarm) => {
                                return(
                                    <MenuItem key={alarm.alarmTypeId} value={alarm.alarmTypeId}>{alarm.alarmType}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </FieldBox>
        );
    };

    const renderTimePicker = (dict, value) => {
        return(
                <Time24Picker
                    id={dict.id}
                    label={dict.label}
                    value={value}
                    onChange={onChangeValueForId}
                    style={{marginRight:20}}
                />
        );
    };

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>
                {isEdit ? '알람수신정보 수정' : '알람수신정보 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                        {renderAlarmSelector(isEdit)}
                        {renderTextFieldBox(InputMap.mobile, inputData.mobile,
                            inputError.mobile, onChangeNumAndHyphen, isEdit) /* 휴대전화 */}
                        {renderTextFieldBox(InputMap.rcvrName, inputData.rcvrName,
                            inputError.rcvrName, onChangeTextData) /* 수신자이름 */}

                    </InputColumn>
                    <InputColumn>
                        <FieldBox>
                            {renderTimePicker(InputMap.timeFrom, inputData.timeFrom)}
                            {renderTimePicker(InputMap.timeTill, inputData.timeTill)}
                        </FieldBox>
                        {renderTextFieldBox(InputMap.alarmInterval, inputData.alarmInterval,
                            inputError.alarmInterval, onChangeTextData) /* 법인번호 */}
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}
