//VehPosListRepeat.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { IconButton, TextField, Button } from '@mui/material';
import ResponseAlert from '../message/ResponseAlert';
import VehFilter from '../common/VehFilter/VehFilter';
import StartIcon from '@mui/icons-material/Start';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TimelineIcon from '@mui/icons-material/Timeline'; // route mode
import BedtimeIcon from '@mui/icons-material/Bedtime'; // No report at all.
import CellTowerIcon from '@mui/icons-material/CellTower'; // cell position
import GpsOffIcon from '@mui/icons-material/GpsOff'; // no position report 1 day or more
import LocalParkingIcon from '@mui/icons-material/LocalParking'; // no position rep 0.5 hour or more
import GpsFixedIcon from '@mui/icons-material/GpsFixed'; // speed < 2
import NavigationIcon from '@mui/icons-material/Navigation'; // speed >= 2
import { AppWord, AppNumber, TableColumnType } from '../../model/AppConst';
import FlexyTable from '../common/flexytable/FlexyTable';
import { useReactiveVar } from '@apollo/client';
import { vehPosFilteredRepo, vehPosFilterCriteriaRepo, vehPosSearchRepo } from '../../model/CvoModel';
import Util from '../../model/Util';

const VehPosListContainer = styled(Box)({
    flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block'
});


const SM = AppWord.SMALL;

export const VehPosTableColumns = [
    { id: 'vehAlias', label: '지도표시명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    {
        id:'-mv-mark', label:'상태', width:50, show: true,
        option: {
            aligh:'center', type: TableColumnType.GRAPH,
            form: (rec, rowIndx) => {
                if(rec.gpsTime===AppWord.NOT_REAL_GPS_TIME) return <BedtimeIcon fontSize={SM} color='disabled' />;
                if(rec.reportAgeSec > 432000) return <GpsOffIcon fontSize={SM} color='warning' />;
                if(rec.reportAgeSec > 86400) return <GpsOffIcon fontSize={SM} color='inherit' />;
                if(rec.posSys==='C' || rec.posSys===null) return <CellTowerIcon fontSize={SM} color='disabled' />
                if(rec.reportAgeSec > 1800)  return <LocalParkingIcon fontSize={SM} color='inherit' />
                if(rec.speed < 2) return <GpsFixedIcon fontSize={SM} color='success'/>;
                return <NavigationIcon fontSize={SM} color='primary' style={{transform: 'rotate('+rec.direction+'deg)'}}/>;
            }
        }
    },
    { id: 'driverName', label: '기사', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'driverMobile', label: '휴대전화', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'location', label: '위치', width: 360, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpsTime', label: '최종보고', width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

/**
 * 일반 업체(청소차량 제외)의 지도용 위치목록.
 * 위치데이터는 repository에 저장해서 rendering시기가 조회 주기에 영향을 미치지 않도록 한다.
 */
export default function MapVehPosListRepeat({
    mapShell,
    onClickShowRoute,
}) {
    const vehPosList = useReactiveVar(vehPosFilteredRepo);
    const [filterBoxState, setFilterBoxState] = useState({open:false});
    const vehFilterCriteria = useReactiveVar(vehPosFilterCriteriaRepo); // TODO : Filter 초기값 적용하기.
    //const [vehFilterCriteria, setVehFilterCriteria] = useState(null);
    const search = useReactiveVar(vehPosSearchRepo);
    const [responseAlert, setResponseAlert] = useState(null);
    const [itemSelected, setItemSelected] = useState(null);
    // const {loading:loadingList, error:errorOfList, data:dataList} = responseList;
    const clientSize = useClientSize();


    const onChangeSearch = (event) => {
        vehPosSearchRepo(event.target.value ? event.target.value.trim() : '');
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
		// if(onClickItem) onClickItem(item);
        //mapShell.morphTo(item.lat, item.lon);
        mapShell.setVehPosSelected(item);
    };

    const onCloseFilterBox = () => {setFilterBoxState({open:false});};

    const onClickSearchFilterButton = (event) => {
        setFilterBoxState({
            open:true, 
            top: clientSize.HeaderHeight,
            left: event.clientX - 10});
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_SET_START:
                Util.bubbleNaverRoutePoint({isStart:true, data:{lat:row.lat, lon:row.lon, address: row.location}});
                break;
            default: return;
        }
    };

    //const criteria = {vehTonId: [1, 3]};
    const onCompleteFilterBox = (criteria) => {
        vehPosFilterCriteriaRepo(criteria);
        setFilterBoxState({open:false});
    };

    return(
        <VehPosListContainer>
            <HeaderBox>
                <ButtonBox>
                    <Button
                        disabled={!Boolean(itemSelected)}
                        onClick={()=>{onClickShowRoute(itemSelected)}}
                        variant='contained' color='primary'>
                        <TimelineIcon fontSize='small' /> 경로보기
                    </Button>
                </ButtonBox>
                <TextField id="search" onChange={onChangeSearch}
                    value={search}
                    label="검색" size='small'/>

                <IconButton size='small' onClick={onClickSearchFilterButton}>
                    <FilterAltIcon />
                </IconButton>

            </HeaderBox>
            <FlexyTable
                name="map_veh_pos_list"
                uniqueKey="vehId"
                columns={VehPosTableColumns}
                records={vehPosList}
                search={search}
                tools={[{id:AppWord.CMD_SET_START, icon:<StartIcon fontSize={AppWord.SMALL} />}]}
                onClickOneRow={onSelectItem}
                onClickTool={onClickToolOnRow}
				selected={itemSelected ? [itemSelected] : null}
            />
            <VehFilter open={filterBoxState.open}
                top={filterBoxState.top}
                left={filterBoxState.left}
                useCustAgree={true}
                onComplete={onCompleteFilterBox}
                onCloseBox={onCloseFilterBox} />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehPosListContainer>
    );
}

