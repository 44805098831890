// CustPoiTypeInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, CircularProgress } from '@mui/material';
import { AppPalette, AppWord, MapValue } from '../../model/AppConst';
import { CustPoiTypeDictionary } from './CustPoiTypeGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ValueUtil from '../../model/ValueUtil';
import SelectFromList from '../common/SelectFromList';


const CustPoiTypeInputContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const EditorBody = styled(Box)({
    padding:10,
});

const EditorContentRow = styled(Box)({
    paddingTop: 10, paddingBottom:10,
});

const FieldBox = styled(Box)({ display: 'inline-block', marginRight: 10, });

const LabelBox = styled('div')({fontSize:'0.85rem', color:AppPalette.PrimaryRGB, padding:"10px 2px 5px 5px"});

const IconListBox = styled(Box)({
    padding:5, border: AppPalette.BorderCCC, borderRadius:5,
    maxHeight:140, overflow:'auto'
});
const IconRecord = styled(Box)({
    display: 'inline-flex', width:160, height:30, verticalAlign:'bottom', borderRadius:5, fontSize:'0.9rem',
    cursor:'pointer',
    '&:hover': {color:'red'}
});
const IconBox = styled(Box)({width: 30, display:'flex', alignItems:'center', justifyContent:'center', padding:5});
const IconNameBox = styled(Box)({flexGrow:1, display:'flex', alignItems:'center', textAlign:'left', padding:5});


const InputMap = CustPoiTypeDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.


export default function CustPoiTypeInput({
    item,
    iconList,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        if(item) {
			const d = {...item};
			// d.tel = ValueUtil.reformWith(item.tel, AppWord.NUM_N_HYPN);
            setInputData(d);
        }
		else setInputData(defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
    }, [item]);

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

    const saveInputData = (newData) => {
        const {errorMap, foundError} = ValueUtil.checkInputData(InputMap, newData, isEdit);
        setInputData(newData);
        setInputError(errorMap);
        setHasError(foundError);
        setChanged(true);
    };

    const onClickIcon = (iconSeq) => {
        const data = {...inputData};
        data.iconSeq = iconSeq;
        saveInputData(data);
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // add extra data if necessary.
        if(isEdit) param.poiType = item.poiType;

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '지점타입 정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput2(event, InputMap, inputData, inputError, isEdit);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

	// If necessary
    const onChangeCode = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        saveInputData(data);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };
    const renderSelectorField = (dict, value, selectFrom) => {
        return(
            <FieldBox>
                <SelectFromList
                    id={dict.id}
                    value={value || dict.default}
                    label={dict.label}
                    list={selectFrom}
                    onChange={onChangeCode}
                    valueKey='level' labelKey='label'
                    minWidth={150}
                />
            </FieldBox>
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size={AppWord.SMALL}
                    style={{minWidth:120}}
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    return (
        <CustPoiTypeInputContainer>
            <EditorBody>
                <EditorContentRow>

                    {renderTextFieldBox(InputMap.typeName, inputData.typeName,
                        inputError.typeName, onChangeTextData) /* 지점타입명 */}
                    {renderTextFieldBox(InputMap.typeDesc, inputData.typeDesc,
                        inputError.typeDesc, onChangeTextData) /* 설명 */}
                    {/* renderTextFieldBox(InputMap.groupNid, inputData.groupNid,
                        inputError.groupNid, onChangeTextData) _____ */}
                </EditorContentRow>
                <EditorContentRow>
                    {renderSelectorField(InputMap.viewLevel, inputData.viewLevel, MapValue.LevelOptions)}
                    {renderTextFieldBox(InputMap.fontColor, inputData.fontColor,
                        inputError.fontColor, onChangeTextData) /* _____ */}
                    {renderTextFieldBox(InputMap.backColor, inputData.backColor,
                        inputError.backColor, onChangeTextData) /* _____ */}
                </EditorContentRow>
                <EditorContentRow>
                    <FieldBox>
                        <LabelBox>아이콘</LabelBox>
                        <IconListBox>
                            {
                                iconList.map((icon)=>{
                                    return(
                                        <IconRecord
                                            key={icon.iconSeq}
                                            style={{backgroundColor: inputData.iconSeq===icon.iconSeq ? '#89c6f2' : 'white'}}
                                            onClick={()=>{onClickIcon(icon.iconSeq)}}
                                        >
                                            <IconBox>
                                                <img src={`${MapValue.POI_ICON_DIR}/${icon.iconSaveName}`}
                                                    alt={`ICON${icon.iconSeq}`}
                                                />
                                            </IconBox>
                                            <IconNameBox>{icon.iconName}</IconNameBox>
                                        </IconRecord>
                                    );
                                })
                            }
                        </IconListBox>
                    </FieldBox>
                </EditorContentRow>
            </EditorBody>
            <Box>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button> &nbsp;
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </Box>
            {renderPromptIgnoreChange()}
        </CustPoiTypeInputContainer>
    );
}