// NaverRouteGql.js
import { gql } from "@apollo/client";

export const NAVER_SEARCH_ROUTE = gql`
query SearchNaverRoute($searchInput:NaverRouteArgDto!) {
    naverSearchRoute(searchInput: $searchInput) {
        ok
        message
        item {
            distance
            tollFare
            fuelPrice
            size
            guides {
                direction
                distance
                duration
                pointIndex
            }
            points {
                lat
                lon
            }
        }
    }
}
`;
