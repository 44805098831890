// TermMngLog.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton, TextField } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import TermMngLogList from './TermMngLogList';
import TermMngLogInput from './TermMngLogInput';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    LIST_TERM_MNG_LOG, GET_TERM_MNG_LOG, LIST_TERM_MNG_LOG_CUST, EDIT_TERM_MNG_LOG, REMOVE_TERM_MNG_LOG,
	TermMngLogDictionary
} from './TermMngLogGql';
import { ViewMode, AppObject, AppNumber, AppWord, CvoCodes, AppPalette } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser, codeTreeRepo } from '../../model/CvoModel';
import CodeSelector from '../common/CodeSelector';
import TextCombo from '../common/TextCombo';

const dcPad = AppNumber.dataContainerPadding;
const TermMngLogContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection:'column'
});

const SearchConditionBox = styled(Box)({ padding: AppNumber.SmallBoxPadding, borderBottom:AppPalette.BorderCCC });
const SearchInputRow = styled(Box)({display:'flex', alignItems:'center', marginTop:0 });
const SearchGroup = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});

const ErrorTitle =TermMngLogDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(TermMngLogDictionary.inputMap, true);
const InputMap = TermMngLogDictionary.inputMap;

const SearchKey = {
    termId: InputMap.termId.id,
    serialNo: InputMap.serialNo.id,
    usimNum: InputMap.usimNum.id,
    date: 'date',
    month: "month",
    year: "year",
    custName: InputMap.custName.id
};
const DefaultSearchParam = {searchField:SearchKey.custName, search:"", dateFrom:""};

const SearchFields = [
    {value: SearchKey.custName, label: InputMap.custName.label},
    {value: SearchKey.termId, label: InputMap.termId.label},
    {value: SearchKey.serialNo, label: 'S/N'},
    {value: SearchKey.usimNum, label: 'USIM'},
    {value: SearchKey.date, label: '변경일자'},
];

function makeYmdToRetrieve(txt) {
    let ymd = '';
    let key;

    if(txt) {
        const len = txt.length;
        if(len >= 4) {
            ymd = txt.substr(0,4);
            if(len >= 6) {
                ymd += '-' + txt.substr(4,2);
                if(len >= 8) {
                    ymd += '-' + txt.substr(6,2);
                    key = "date";
                }
                else {
                    ymd += "-01";
                    key = "month";
                }
            }
            else {
                ymd += "-01-01";
                key = "year";
            }
        }
    }
    return {dateFrom: ymd, searchField: key};
}

export default function TermMngLog() {
	const sessionInfo = useReactiveVar(userInfoRepo);
	const [termMngLogRecords, setTermMngLogRecords] = useState([]);
    const [customerList, setCustomerList] = useState([]);
	const [termMngLogSelected, setTermMngLogSelected] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const [inputForRetrieve, setInputForRetrieve] = useState({
        searchField:SearchKey.custName,
        usingTerm:"", usingSerial:"", usingUsim:"",
        usingCust:"", usingDate:""}); // for interface
    const [paramForList, setParamForList] = useState({...DefaultSearchParam});
    // const responseList = useQuery(GET_TERM_MNG_LOG_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});

    // ##### Call GraphQL to get List #####
    const [getTermMngLogList, responseList] = useLazyQuery(LIST_TERM_MNG_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getTermMngLogCustList, resultListCust] = useLazyQuery(LIST_TERM_MNG_LOG_CUST, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetCustList(data, option)},
        onError: (error) =>  {setResponseAlert({open:true, error: error, title: "업체목록조회 오류"})}
    });
    const [getTermMngLogItemToEdit, responseItemToEdit] = useLazyQuery(GET_TERM_MNG_LOG, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation. ###
    const [editTermMngLog, responseEdit] = useMutation( EDIT_TERM_MNG_LOG, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeTermMngLog, responseRemove] = useMutation( REMOVE_TERM_MNG_LOG, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    /* useEffect(() => {
        getTermMngLogList();
    }, []); */


    useEffect(() => {
        getTermMngLogCustList();
    }, []);

    const makeParam = (paramForList) => {
        if(paramForList.searchField===SearchKey.date) {
            return makeYmdToRetrieve(paramForList.dateFrom);
        }
        else return paramForList;

    };

    const getTermMngLogListWithParam = (param) => {
        if(param) getTermMngLogList({variables:{termMngLogSearch:makeParam(param)}});
        else if(ValueUtil.realLen(paramForList.dateFrom)>=4 || !ValueUtil.isNullString(paramForList.search)) {
            getTermMngLogList({variables: {termMngLogSearch:makeParam(paramForList)}});
        }
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.termMngLogList) setTermMngLogRecords(data.termMngLogList);
        if(data.termMngLogCustList) setCustomerList(data.termMngLogCustList);
    };

    const onCompleteGetCustList = (data, clientOption) => {
        if(data.termMngLogCustList) setCustomerList(data.termMngLogCustList);
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.termMngLogEdit.ok) {
            setEditorState(null);
            getTermMngLogListWithParam(); // setTermMngLogRecords(data.termMngLogEdit.list);
        }
        else setResponseAlert({open:true, resultData: data.termMngLogEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.termMngLogRemove.ok) getTermMngLogListWithParam(); // setTermMngLogRecords(data.termMngLogRemove.list);
        else setResponseAlert({open:true, resultData: data.termMngLogRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.termMngLogItem) {
            const item = {};
            for(const field of EditFields) item[field] = data.termMngLogItem[field];
            setEditorState({item: item});
        }
    };

    // +++++++ UI callbacks ++++++++

    const onRequestEdit = (data) => {
        if(responseEdit) responseEdit.reset();
        getTermMngLogItemToEdit({variables: {logNid: data.logNid}});
        // setEditorState({item: data});
    };

    const onRequestRemove = (item) => {

        setPromptToConfirm({
            data: item,
            title: '변경정보 삭제',
            messages: [
                '선택한 변경정보를 삭제하시겠습니까?',
                '해당 변경정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{termMngLog:{logNid: data.logNid}}};
                    removeTermMngLog(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by TermMngLogInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {termMngLog: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editTermMngLog(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };

    const onClickRetrieveFromServer = () => {
        const param = {...DefaultSearchParam}; // InputTermMngLogSearch
        param.searchField = inputForRetrieve.searchField;

        if(inputForRetrieve.searchField===SearchKey.date) {
            param.dateFrom = inputForRetrieve.usingDate;
        }
        else if(inputForRetrieve.searchField===SearchKey.termId) param.search = inputForRetrieve.usingTerm;
        else if(inputForRetrieve.searchField===SearchKey.serialNo) param.search = inputForRetrieve.usingSerial;
        else if(inputForRetrieve.searchField===SearchKey.usimNum) param.search = inputForRetrieve.usingUsim;
        else if(!ValueUtil.isNullString(inputForRetrieve.usingCust)) {
            param.search = inputForRetrieve.usingCust;
        }
        else return;

        setParamForList(param);
        getTermMngLogListWithParam(param);
    };

    const onChangeSearchField = (id, value) => {
        const search = {...inputForRetrieve};
        search.searchField = value;
        setInputForRetrieve(search);
    };

    // 서버 조회용 openDate, abandonDate (dateFrom) 변경 handler
    const onChangeDateInputToRetrieve = (event) => {
        const txt = event.target.value;
        if(txt) {
            if(/[^0-9]/.test(txt)) return;
            if(txt.length>8) return;
        }
        const search = {...inputForRetrieve};
        search[event.target.id] = txt;
        setInputForRetrieve(search);
    };

    // 서버조회용. termId, usimNum, serialNo handler
    const onChangeTextToRetrieve = (event) => {
        const search = {...inputForRetrieve};
        search[event.target.id] = event.target.value;
        setInputForRetrieve(search);
    };

    // 서버 조회용 업체명 변경 handler
    const onChangeCustInputToRetrieve = (id, newValue) => {
        const search = {...inputForRetrieve};
        search.usingCust = newValue || '';
        setInputForRetrieve(search);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList, resultListCust, responseEdit, responseItemToEdit, responseRemove
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <TermMngLogInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                customerList={customerList}
                />
            :
            null
        );
    };

    const renderSearchInput = (id, value, label, callback) => {
        return(
            <TextField
                id={id} style={{width:250}}
                label={label}
                onChange={callback}
                value={value}
                size={AppWord.SMALL}
            />
        );
    };

	const isAdmin = ValueUtil.isEtrace(sessionInfo);
    if(!isAdmin) return <Box>***</Box>

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <TermMngLogContainer>
            <SearchConditionBox>
                <SearchInputRow>
                    <SearchGroup>
                            <CodeSelector
                                id="searchField"
                                value={inputForRetrieve.searchField|| SearchKey.custName}
                                label="조회조건"
                                codes={SearchFields}
                                onChange={onChangeSearchField}
                            />
                            &nbsp;
                            {
                                inputForRetrieve.searchField===SearchKey.termId
                                ? renderSearchInput("usingTerm", inputForRetrieve.usingTerm, InputMap.termId.label, onChangeTextToRetrieve)
                                :
                                inputForRetrieve.searchField===SearchKey.serialNo
                                ? renderSearchInput("usingSerial", inputForRetrieve.usingSerial, "S/N", onChangeTextToRetrieve)
                                :
                                inputForRetrieve.searchField===SearchKey.usimNum
                                ? renderSearchInput("usingUsim", inputForRetrieve.usingUsim, "USIM", onChangeTextToRetrieve)
                                :
                                inputForRetrieve.searchField===SearchKey.date
                                ? renderSearchInput("usingDate", inputForRetrieve.usingDate, "변경일자 yyyy[mm[dd]]", onChangeDateInputToRetrieve)
                                :
                                <TextCombo
                                    id='usingCust'
                                    label={InputMap.custName.label}
                                    selectFrom={customerList}
                                    uniqueKey={'custName'}
                                    text={inputForRetrieve.usingCust}
                                    onChange={onChangeCustInputToRetrieve}
                                />
                            }
                            &nbsp;
                            <Button variant={AppPalette.VariantContained}
                                disabled={
                                    ( inputForRetrieve.searchField===SearchKey.date && ValueUtil.realLen(inputForRetrieve.usingDate) < 4 )
                                    || ( inputForRetrieve.searchField===SearchKey.termId && ValueUtil.realLen(inputForRetrieve.usingTerm) < 4 )
                                    || ( inputForRetrieve.searchField===SearchKey.serialNo && ValueUtil.realLen(inputForRetrieve.usingSerial) < 3 )
                                    || ( inputForRetrieve.searchField===SearchKey.usimNum && ValueUtil.realLen(inputForRetrieve.usingUsim) < 3 )
                                    || ( inputForRetrieve.searchField===SearchKey.custName && ValueUtil.isNullString(inputForRetrieve.usingCust) )
                                }
                                onClick={onClickRetrieveFromServer}
                            ><GetAppIcon /> 조회</Button>
                    </SearchGroup>
                </SearchInputRow>
            </SearchConditionBox>
            <TermMngLogList
                records={termMngLogRecords}
				selected={termMngLogSelected}
				onClickItem={setTermMngLogSelected}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
            />
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </TermMngLogContainer>
    )
}
