// src/com/cust_agree/CustAgreeGql.js
import { gql } from "@apollo/client";
//#############################
/*
import {
    LIST_CUST_AGREE, GET_CUST_AGREE, ADD_CUST_AGREE, EDIT_CUST_AGREE
} from './CustAgreeGql';
*/

const CUST_AGREE_LIST_PART = gql`
fragment CustAgreeListPart on CustAgree {
    groupId
	groupName
	custId
    custNameUsing
	givingCustId
	givingCustName
	givingUserId
	editYn
	regDate
    userId
}
`;


export const LIST_CUST_AGREE = gql`
${CUST_AGREE_LIST_PART}
query ListCustAgree {
    custAgreeList {
        ...CustAgreeListPart
    }
}
`;

export const LIST_CUST_AGREE_GIVING = gql`
${CUST_AGREE_LIST_PART}
query ListCustAgreeGiving {
    custAgreeListGiving {
        ...CustAgreeListPart
    }
}
`;

export const LIST_CUST_AGREE_FOR_USR = gql`
${CUST_AGREE_LIST_PART}
query ListCustAgreeForUser($userId:String!) {
    custAgreeListForUser(userId:$userId) {
        ...CustAgreeListPart
    }
}
`;

/*
export const GET_CUST_AGREE = gql`
${CUST_AGREE_LIST_PART}
query GetCustAgree($groupId: Int!) {
    custAgreeItem(groupId:$groupId) {
        ...CustAgreeListPart
    }
}
`;

export const GET_CUST_AGREE_GIVING = gql`
${CUST_AGREE_LIST_PART}
query GetCustAgreeGiving($custId: String, $groupId: Int!) {
    custAgreeItemGiving(custId:$custId, groupId:$groupId) {
        ...CustAgreeListPart
    }
}
`;
*/

export const ADD_CUST_AGREE = gql`
mutation AddCustAgree($custAgree:InputCustAgreeAdd!) {
    custAgreeAdd(custAgree: $custAgree) {
        ok
        message
        list {
            groupId
            groupName
            custId
            custNameUsing
            givingUserId
            editYn
            regDate
        }
    }
}
`;

export const REMOVE_CUST_AGREE = gql`
mutation RemoveCustAgree($custAgree:InputCustAgreeRemove!) {
    custAgreeRemove(custAgree: $custAgree) {
        ok
        message
        list {
            groupId
            groupName
            custId
            custNameUsing
            givingUserId
            editYn
            regDate
        }
    }
}
`;


