//DurationPicker.js
import React, { useEffect, useState } from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import ValueUtil from '../../model/ValueUtil';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

const PickerContainer = styled(Box)({
    display:'flex', justifyContent:'center', alignItems:'center'
});
const PickerColumn = styled(Box)({
    display:'inline-block', marginRight:10
});

const ThePicker = styled(DateTimePicker)({
    "& .MuiInputBase-input": {padding:"11px 0px 11px 8px", width:115, fontSize:'0.9rem'}
});

export default function DurationPicker({
    from,
    till,
    onChangeFrom,
    onChangeTill,
}) {
    const [defaultFrom, setDefaultFrom] = useState(dayjs(ValueUtil.getYmdHmsInputText(new Date(), 5*3600)));
    const [defaultTill, setDefaultTill] = useState(dayjs(new Date()));

    useEffect(()=>{
            if(from) {
                setDefaultFrom(dayjs(from));
            }
            else setDefaultFrom(dayjs(dayjs(ValueUtil.getYmdHmsInputText(new Date(), 5*3600))));
    }, [from]);

    useEffect(()=>{
            if(till) {
                setDefaultTill(dayjs(till));
            }
            else setDefaultTill(dayjs(new Date()));
    }, [till]);

    const onChangeFromTime = (value, ctxt) => {
        if(onChangeFrom)
            onChangeFrom(ValueUtil.getYmdHmsInputText(value.toDate()));
    };

    const onChangeTillTime = (value, ctxt) => {
        if(onChangeTill)
            onChangeTill(ValueUtil.getYmdHmsInputText(value.toDate()));
    };


    return (
        <Box style={{display:'inline-block', padding:0}}>
            <PickerContainer>
            <PickerColumn>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                    <ThePicker ampm={false} format='YYYY-MM-DD HH:mm'
                        onChange={onChangeFromTime}
                        value={defaultFrom || ''}
                    />
                </LocalizationProvider>

            </PickerColumn>
                <span style={{maringLeft:10, marginRight:20}}>부터</span>
            <PickerColumn>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                    <ThePicker ampm={false} format='YYYY-MM-DD HH:mm'
                        onChange={onChangeTillTime}
                        value={defaultTill || ''}
                    />
                </LocalizationProvider>

            </PickerColumn>
                <span style={{maringLeft:10}}>까지</span>

            </PickerContainer>
        </Box>
    );
}
/*

        <Box style={{display:'inline-block'}}>
            <PickerColumn>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                    <DemoContainer components={['DateTimePicker']} style={{marginTop:0}}>
                        <DateTimePicker ampm={false} format='YYYY-MM-DD HH:mm' style={{marginTop:0}} />
                    </DemoContainer>
                </LocalizationProvider>

            </PickerColumn>
                <span style={{maringLeft:10, marginRight:20}}>부터</span>
            <PickerColumn>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                    <DemoContainer components={['DateTimePicker']} style={{marginTop:0}}>
                        <DateTimePicker ampm={false} format='YYYY-MM-DD HH:mm' style={{marginTop:0}} />
                    </DemoContainer>
                </LocalizationProvider>

            </PickerColumn>
                <span style={{maringLeft:10}}>까지</span>
        </Box>

*/