// appHooks.js
import { useCallback } from "react";
import { ADD_ACT_LOG } from "../act_log/ActLogGql";
import { useMutation } from "@apollo/client";

export function useAddActLog() {
    const [addActLogApi] = useMutation(ADD_ACT_LOG, {
		onCompleted: (data, option) => {
            if(!data.actLogAdd.ok) {
                console.log('error(Not ok)', data.actLogAdd.message);
            }
        }, 
		onError: (error) => {
            console.log('error', error);
        }
	} );

    return useCallback((tableName, actType, actText) => {
        addActLogApi({
            variables: {
                actLog: {
                    tableName: tableName,
                    actType: actType,
                    actText: actText,
                }
            }
        });
    }, [addActLogApi]);
}

