import { MapValue } from "../../model/AppConst";
import ValueUtil from "../../model/ValueUtil";
const NM = window.naver.maps;

export default class MarkerUtil {
    static degreeToIndex16(direction) { return Math.round(direction / 22.5) % 16; };

    /**
     * 지점에 대응하는 마커 생성
     * @param {object} poi - 지점데이터 오브젝트.
     * @returns 지점표시 마커
     */
    static generatePoiMarker(poi) {
        const MarkerHeight = MapValue.MARKER_LABEL_HEIGHT + poi.height;
    
        const marker = new NM.Marker({
            position: new NM.LatLng(poi.lat, poi.lon),
            // map: this.mapShell.getMap(),
            userData: {data:poi, type:MapValue.MarkerType.POI},
            icon: {
                content:
                    `<div style="
                            z-index: ${MapValue.POI_Z_INDEX};
                            display: inline-block; font-size:8pt;
                            height:${MarkerHeight}px;
                            width:${MapValue.LABEL_CONTAINER_WIDTH}px;
                            text-align: center;">`
                        + `<img src="${MapValue.POI_ICON_DIR}/${poi.iconSaveName}"
                            style="width:${poi.cutWidth}px;height:${poi.height}px;corsor:pointer;"
                            />`
                        + `<div style="width:${MapValue.LABEL_CONTAINER_WIDTH}px;height: 24px;margin: 0px;">`
                            + `<div style="background-color:${poi.backColor};
                                color: ${poi.fontColor}; 
                                display: inline;
                                height: 22px;
                                border: 1px solid #999;
                                border-radius: 3px; box-shadow: 1px 1px 1px 1px #aaa;
                                max-width: ${MapValue.LABEL_CONTAINER_WIDTH - 2}px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin-top:1px;
                                padding: 2px 4px 2px 4px;
                                corsor:pointer;">${poi.poiName}</div>`
                        + '</div>'
                    + '</div>',
                size: new NM.Size(MapValue.LABEL_CONTAINER_WIDTH, MarkerHeight),
                anchor: new NM.Point(MapValue.LABEL_CONTAINER_WIDTH / 2, poi.height),
            },
            draggable: false,
            zIndex: MapValue.POI_Z_INDEX
        });
    
        return marker;
    }

    /**
     * 클러스터용 마커 생성.
     * @param {object} cluster - PoiCluster 객체 (QTreeNode.js) POI 목록을 포함함.
     * @returns 클러스터용 Marker object
     */
    static generateClusterMarker(cluster) {
        const size = cluster.size();
        if(size<1) return null;
        else if(size===1) return MarkerUtil.generatePoiMarker(cluster.getFirst().poi);

        const SizeHalf = MapValue.ClusterIconSize / 2;

        const marker = new NM.Marker({
            position: new NM.LatLng(cluster.lat, cluster.lon),
            userData: {data:cluster, type:MapValue.MarkerType.CLUSTER},
            icon: {
                content:
                    `<div style="
                        box-sizing: border-box;
                        display: inline-block; font-size:9pt; font-weight: bold;
                        width:${MapValue.ClusterIconSize}px;height:${MapValue.ClusterIconSize}px;
                        border: 6px double #339955;
                        border-radius:${MapValue.ClusterIconSize}px;
                        color: #009955;
                        background-color: rgb(255 233 233 / 0.7);
                    ">
                        <div style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height:100%; width:100%;
                        ">
                            <span>${size}</span>
                        </div>
                    </div>`,
                size: new NM.Size(MapValue.ClusterIconSize, MapValue.ClusterIconSize),
                anchor: new NM.Point(SizeHalf, SizeHalf),

            },
            draggable: false,
            zIndex: MapValue.CLUSTER_Z_INDEX
        });
        return marker;
    }

    /**
     * 차량에 대응하는 마커를 생성하여 반환.
     * @param {object} pos - 차량위치 객체. VEH_POS 레코드.
     * @param {number} SpeedEnough - 업체의 industry id에 따른 차량 움직임 판단을 위한 최소속도.
     * @returns 차량위치에 대응하는 마커
     */
    static generateVehPosMarker(pos, SpeedEnough) {
        const MarkerHeight = MapValue.POS_MARKER_SIZE + MapValue.MARKER_LABEL_HEIGHT;
        const MarkerAnchorX = MapValue.LABEL_CONTAINER_WIDTH / 2;

        let iconFile, useRunColor=false;
        if(pos.posSys==='C') iconFile = '/cellpos_w22.png';
        else {
            if(pos.reportAgeSec > 1800) iconFile = '/park_w22.png';
            else {
                if(pos.speed < SpeedEnough) iconFile = '/stopped_22x22.png';
                else {
                    var dir = MarkerUtil.degreeToIndex16(pos.direction % 360);
                    iconFile = '/arrow_' + dir + '.png';
                    useRunColor = true;
                }
            }
        }
        const iconUrl = MapValue.VEH_ICON_DIR + 'black' + iconFile;
        
        let fontColor, backColor;
        if(useRunColor) {
            fontColor = pos.operFontColor;
            backColor = pos.operBackColor;
        }
        else {
            fontColor = pos.noOperFontColor;
            backColor = pos.noOperBackColor;
        }

        const marker = new NM.Marker({
            position: new NM.LatLng(pos.lat, pos.lon),
            userData: {
                type:MapValue.MarkerType.VEH_POS,
                data:pos,
            },
            // map: map,
            icon: {
                content: 
                    `<div style="
                        z-index: ${MapValue.VEHPOS_Z_INDEX};
                        display: inline-block; font-size:8pt;
                        height:${MarkerHeight}px;
                        width:${MapValue.LABEL_CONTAINER_WIDTH}px;
                        text-align: center;">
                        <img src="${iconUrl}" style="width:22;height:22;" />
                        <div style="width:${MapValue.LABEL_CONTAINER_WIDTH}px;height: 24px;margin: 0px;">
                            <div style="
                                display:inline-flex; align-items:center;
                                padding-left:10px; padding-right:10px;
                                border-radius:3px;
                                box-shadow: 1px 1px 1px 1px #aaa;
                                background-color:${backColor};color:${fontColor};
                                height:${MapValue.MARKER_LABEL_HEIGHT}px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            ">
                                ${pos.vehAlias}
                            </div>
                        </div>
                    </div>`,
                size: new NM.Size(MapValue.LABEL_CONTAINER_WIDTH, MarkerHeight),
                anchor: new NM.Point(MarkerAnchorX, MapValue.POS_MARKER_SIZE/2),
            },
            draggable: false,
            zIndex: MapValue.VEHPOS_Z_INDEX
        });

        return marker;
    };


    /**
     * 차량경로용 마커를 생성하여 반환.
     * @param {object} pos - 차량위치 객체. VEH_POS 레코드.
     * @param {number} SpeedEnough - 업체의 industry id에 따른 차량 움직임 판단을 위한 최소속도.
     * @returns 차량위치에 대응하는 마커
     */
    static generateRoutePointMarker(pos, SpeedEnough, isLast) {
        const MarkerHeight = isLast ? MapValue.POS_MARKER_SIZE : MapValue.ROUTE_MARKER_SIZE; // + MapValue.MARKER_LABEL_HEIGHT;
        const MarkerAnchorX = MarkerHeight / 2;

        const isize = isLast ? '22' : '12';
        let iconFile;
        if(pos.posSys==='C') iconFile = `/cellpos_w${isize}.png`;
        else {
            if(pos.speed < SpeedEnough) iconFile = `/stopped_${isize}x${isize}.png`;
            else {
                var dir = MarkerUtil.degreeToIndex16(pos.direction % 360);
                iconFile = (isLast ? '/arrow_' : '/s_arrow_') + dir + '.png';
            }
        }
        const iconUrl = MapValue.VEH_ICON_DIR + (pos.timeArrival ? 'black' : 'red') + iconFile;

        const marker = new NM.Marker({
            position: new NM.LatLng(pos.lat, pos.lon),
            userData: {
                type:MapValue.MarkerType.ROUTE_POINT,
                data:pos,
            },
            // map: map,
            icon: {
                //content: `<div style="display:inline;width:${MarkerHeight};height:${MarkerHeight}"><img src="${iconUrl}" alt="${pos.gpsTime}"/></div>`,
                url: iconUrl,
                size: new NM.Size(MarkerHeight, MarkerHeight),
                anchor: new NM.Point(MarkerAnchorX, MarkerAnchorX),
            },
            draggable: false,
            zIndex: MapValue.ROUTE_Z_INDEX
        });

        return marker;
    }

    /**
     * 동정정보(정차)를 표시하기 위한 마커를 생성하여 반환.
     * @param {object} pos - 경로 지점
     * @returns 마커
     */
    static generateStayMarker(pos) {
        const MarkerHeight = 40;
        const MarkerWidth = 100;
        const MarkerAnchorX = -10;

        const marker = new NM.Marker({
            position: new NM.LatLng(pos.lat, pos.lon),
            userData: {
                type:MapValue.MarkerType.ROUTE_POINT,
                data:pos,
            },
            // map: map,
            icon: {
                content: `<div style="display:inline;width:${MarkerWidth};height:${MarkerHeight}">
                    <div style="
                        display:inline;width:${MarkerWidth};height:${MarkerHeight};
                        border:1px solid black;background-color:yellow;
                    ">
                    ${ValueUtil.formatDuration(pos.staySec)} 정차
                    </div>
                </div>`,
                size: new NM.Size(MarkerWidth, MarkerHeight),
                anchor: new NM.Point(MarkerAnchorX, 0),
            },
            draggable: false,
            zIndex: MapValue.ROUTE_Z_INDEX-10
        });

        return marker;
    }

    /**
     * 주어진 위치에 배열에 담긴 데이터를 보여주는 정보표시용 마커를 생성하여 반환.
     * @param {Array} array - array of userData. {type:Number, data: one of vehPos, poiInfo or PoiCluster}
     * @param {object} position - NAVER Position data {lat,lng}
     * @param {boolean} towardNorth - show marker above the position if true
     */
    static generateOverlapInfoMarker(array, position, towardNorth) {}

    // ######## Naver ROute #########
    static generateNaverRouteMarker(pos, iconUrl, width, height) {
        const marker = new NM.Marker({
            position: new NM.LatLng(pos.lat, pos.lon),
            // map: map,
            icon: {
                //content: `<div style="display:inline;width:${MarkerHeight};height:${MarkerHeight}"><img src="${iconUrl}" alt="${pos.gpsTime}"/></div>`,
                url: iconUrl,
                size: new NM.Size(width, height),
                anchor: new NM.Point(width/2, height),
            },
            draggable: false,
            zIndex: MapValue.POI_Z_INDEX+1
        });

        return marker;
    }
}
