// VehFilter.js
import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery } from "@apollo/client";
import { Box } from '@mui/system';
import { Modal, Button, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ValueUtil from '../../../model/ValueUtil';
import { AppObject, TableColumnType } from '../../../model/AppConst';
import { userInfoRepo, NoUser } from '../../../model/CvoModel';
import { useQuery } from '@apollo/client';
import VehGroupNode from '../../../model/VehGroupNode';
import VehTypeNode from '../../../model/VehTypeNode';
import ExpanTree from '../ExpanTree/ExpanTree';
import FlexyTable from '../flexytable/FlexyTable';

const LIST_ALL_THREE = gql`
    query ListAllThree($custId:String) {
        custAgreeList {
            groupId
            groupName
            custId
            givingCustId
            givingCustName
        }
        vehTonInfoList(custId:$custId) {
            custId
            vehTonId
            vehTypeId
            vehTypeName
            ton
        }
        vehGroupList(custId:$custId) {
            groupId
            custId
            groupName
            parentNodeId
        }
        groupVehAllocList {
            groupId, vehId, custId, regUserId, regDate
        }
    }
`;

const DataBoxHeight = 180;
//const ButtonBoxHeight = 80;
const CommonPadding = 15;
const CommonMargin = 5;
const BorderRadius = 10;
const TonBoxWidth = 200;
const GroupBoxWidth = 250;
const AgreeBoxWidth = TonBoxWidth + GroupBoxWidth + CommonMargin * 2;
//const BoxHeight = DataBoxHeight + ButtonBoxHeight + CommonPadding * 4;
const Border = '1px solid #cccccc';

const ModalBox = styled(Box)({
    display: 'inline-block',
    backgroundColor: 'white',
    // border: '1px solid #cccccc',
    borderRadius: BorderRadius + 5,
    // height: BoxHeight
});

const DataSector = styled(Box)({
    display: 'block',
    paddingLeft: CommonPadding,
    paddingRight: CommonPadding,
});

const DataColumnBox = styled(Box)({
    display: 'inline-block',
    margin: CommonMargin,
});

const LabelBox = styled(Box)({
    padding: CommonPadding+5,
    fontWeight: 'bold'
});

const DataBox = styled(Box)({
    overflow: 'auto',
    border: Border,
    borderRadius: BorderRadius,
    height: DataBoxHeight,
    padding: CommonPadding,
});

const ButtonBox = styled(Box)({
    display: 'block',
    textAlign: 'center',
    padding: CommonPadding,
    '&>*': {margin:CommonMargin}
});


const CustAgreeTableColumns = [
	{ id: 'groupName', label: '협력그룹', width: 180, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'givingCustName', label: '제공업체', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

const ClearIconButton = ({onClick}) => {
    return(
        <IconButton size='small' color='primary'
            onClick={onClick}>
            <UnpublishedIcon fontSize='small' />
        </IconButton>
    );
};

// TODO: 필터 초기값 넣어주기.
// #########################################
export default function VehFilter({
    open,
    left, top,
    useCustAgree, // only for veh_pos ...
    onCloseBox,
    onComplete, // complted selection to filter. onComplete(criteria);
    initalCriteria, // TODO ...
}) {
    const [vehGroupTreeData, setVehGroupTreeData] = useState([]);
    const [vehTonTreeData, setVehTonTreeData] = useState([]);
    const [custAgreeData, setCustAgreeData] = useState([]);
    const [groupVehAlloc, setGroupVehAlloc] = useState([]);
    //const responseGroups  = useQuery(LIST_VEH_GROUP_SIMPLE, AppObject.NoCachedFetch);
    //const responseTypeTon = useQuery(LIST_VEH_TON_INFO_SIMPLE, AppObject.NoCachedFetch);
    //const responseCustAgree = useQuery(LIST_CUST_AGREE, AppObject.NoCachedFetch);

    const [getFourData, responseAll] = useLazyQuery(LIST_ALL_THREE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetAll(data, option)},
		onError: (error) => {console.log(error)}
	});

    useEffect(() => {
        if(open) getFourData();
    }, [open, getFourData]);

    const onCompleteGetAll = (dataList, option) => {
        if(dataList.custAgreeList)
            setCustAgreeData(dataList.custAgreeList.map((item) => {item["__checked"]=false; return item;}));
        if(dataList.vehTonInfoList)
            setVehTonTreeData(VehTypeNode.makeTypeTonTree(dataList.vehTonInfoList));
        if(dataList.vehGroupList)
            setVehGroupTreeData(VehGroupNode.makeTree(dataList.vehGroupList));
        if(dataList.groupVehAllocList)
            setGroupVehAlloc(dataList.groupVehAllocList);
    };

    const uncheckAllTon = () => {
        setVehTonTreeData(vehTonTreeData.map((t)=>{t.uncheckAll(); return t;}));
    };

    const uncheckAllAgreeGroups = () => {
        setCustAgreeData(custAgreeData.map((g)=>{g.__checked = false; return g;}));
    };

    const uncheckAllGroups = () => {
        setVehGroupTreeData(vehGroupTreeData.map((g) => { g.uncheckAll(); return g;}));
    };

    if(ValueUtil.hasAuthError(responseAll)) {
        userInfoRepo(NoUser);
    }

    const {loading, error, data} = responseAll;
    //const {loading:loadingGroup, error:errorOfGroup, data:dataGroup} = responseGroups;
    //const {loading:loadingTon, error:errorOfTon, data:dataTon} = responseGroups;
    //const {loading:loadingCustAgree, error:errorOfCustAgree, data:dataCustAgree} = responseCustAgree;

    const onGroupChecked = (nodeList, isAdding, node) => {
        const roots = [...vehGroupTreeData];
        setVehGroupTreeData(roots);
    };

    const onTonChecked = (nodeList, isAdding, node) => {
        const roots = [...vehTonTreeData];
        setVehTonTreeData(roots);
        if(isAdding) uncheckAllAgreeGroups();
    };

    const onAgreeRowSelected = (row, isChecked) => {
        if(isChecked) uncheckAllTon();

        const groups = custAgreeData.map((item) => {
            if(row.groupId === item.groupId) {item.__checked = isChecked;}
            return item;
        });
        setCustAgreeData(groups);
    };

    const countTonChecked = () => {return VehTypeNode.getTonIdsChecked(vehTonTreeData).length};
    const countGroupChecked = () => {return VehGroupNode.getPrimaryDataChecked(vehGroupTreeData).length};
    const countAgreeChecked = () => {var n=0; custAgreeData.forEach((a)=>{if(a.__checked) n++;}); return n;};

    const onClickOk = () => {
        // check group, group-agreed, type+ton. getPrimaryDataChecked()
        const criteria = {};
        /*
        criteria = {
            custId: 'yyyyMMDDnnnn',
            vehTonId: [] // tonIdsChecked
            vehId: [] // vehId array in selected groups
        };
        */
        const tonIdsChecked = VehTypeNode.getTonIdsChecked(vehTonTreeData);
        const groupsChecked = VehGroupNode.getPrimaryDataChecked(vehGroupTreeData);
        if(tonIdsChecked.length > 0) {
            criteria.vehTonId = tonIdsChecked;
            criteria.custId = [vehTonTreeData[0].custId]; // Not any chance to get from other than ours.
        }
        else {
            for(const agree of custAgreeData) {
                if(agree.__checked) groupsChecked.push(agree.groupId);
            }
        }
        // translate groups to vehicle id list
        const vehIds = [];
        for(const alloc of groupVehAlloc) {
            if(groupsChecked.includes(alloc.groupId)) vehIds.push(alloc.vehId);
        }
        if(vehIds.length>0) {
            criteria.vehId = vehIds;
        }

        if(onComplete) {
            // 필터링할 값이 없으면 NULL 보낸다.
            if(criteria.vehId || criteria.vehTonId) onComplete(criteria);
            else onComplete(null);
        }
    };

    const renderGroupTreeBox = () => {
        if(loading) return <span>Loading...</span>;
        if(error) return <span>{error.message}</span>;
        return(
            <ExpanTree
                data={vehGroupTreeData}
                onLeafChecked={onGroupChecked}
                />
        );
    };

    const renderTonTreeBox = () => {
        if(loading) return <span>Loading...</span>;
        if(error) return <span>{error.message}</span>;
        return(
            <ExpanTree
                data={vehTonTreeData}
                onLeafChecked={onTonChecked}
                />
        );
    };

    const tonChecked = countTonChecked();
    const grpChecked = countGroupChecked();
    const agrChecked = countAgreeChecked();

    const renderCustAgreeTable = () => {
        if(loading) return <span>Loading...</span>;
        if(error) return <span>{error.message}</span>;
        //let height = (PanSize.RowHeight - 1) * custAgreeData.length + 1;
        return(
            <DataSector>
                <LabelBox>
                    협력그룹
                    {
                        agrChecked ?
                        <span>&nbsp; ({agrChecked})
                            <ClearIconButton onClick={uncheckAllAgreeGroups}/>
                        </span> : null
                    }
                </LabelBox>
                <Box style={{height:180, display:'flex'}}>
                    <FlexyTable
                        name="cust_agree_selector"
                        uniqueKey="groupId"
                        columns={CustAgreeTableColumns}
                        records={custAgreeData}
                        onSelectRow={onAgreeRowSelected}
                        option={{multiChecker:true}}
                        hideFirstTopButton={true}
                    />

                </Box>
            </DataSector>
        );
    };

    return (
        <Modal
            open={open}
            onClose={()=>onCloseBox()}
            style={{top:top, left:left}}>
            <ModalBox>
                <DataSector>
                    <DataColumnBox width={GroupBoxWidth}>
                        <LabelBox>
                            차량그룹
                            {
                                grpChecked ?
                                <span>&nbsp; ({grpChecked})
                                    <ClearIconButton onClick={uncheckAllGroups} />
                                </span> : null
                            }
                        </LabelBox>
                        <DataBox>{renderGroupTreeBox()}</DataBox>
                    </DataColumnBox>
                    <DataColumnBox width={TonBoxWidth}>
                        <LabelBox>
                            차종, 톤수
                            {
                                tonChecked ?
                                <span>&nbsp; ({tonChecked})
                                    <ClearIconButton onClick={uncheckAllTon}/>
                                </span> : null
                            }
                        </LabelBox>
                        <DataBox>{renderTonTreeBox()}</DataBox>
                    </DataColumnBox>
                </DataSector>
                {
                    useCustAgree && custAgreeData.length > 0
                    ?
                    renderCustAgreeTable()
                    :
                    null
                }
                <ButtonBox>
                    {
                        useCustAgree && custAgreeData.length > 0
                        ?
                        <Box style={{fontSize:'small'}}>
                            협력그룹과 차종,톤수는 필터링에 동시 사용할 수 없습니다.
                        </Box>
                        : null
                    }
                    <Button variant='contained' color='primary' onClick={onClickOk}>적용</Button>
                    &nbsp;
                    <Button variant='contained' color='inherit' onClick={onCloseBox}>취소</Button>
                </ButtonBox>
            </ModalBox>
        </Modal>
    );
}
