//PositionTail.js
import ValueUtil from "../../model/ValueUtil";

export const COUNT_LIMIT = 1000; // 이 이상의 데이터를 가지고 있지 않는다. 600대면 600,000 object.
export const PosTailHourLimit={Min:1, Max:8};
/**
 * 위치정보에 마우스 올리면 나타나는 경로꼬리의 관리를 위한 클래스.
 */
export default class PositionTail {
    data = {}; // {vehId, tracks:[]}
    limitHours = 2;

    constructor(hours) {
        this.limitHours = hours < PosTailHourLimit.Min ? PosTailHourLimit.Min : hours > PosTailHourLimit.Max ? PosTailHourLimit.Max : hours;
    }

    setHourLimit = (hours) => {
        this.limitHours = hours < PosTailHourLimit.Min ? PosTailHourLimit.Min : hours > PosTailHourLimit.Max ? PosTailHourLimit.Max : hours;
    };
    getHourLimit = () => this.limitHours;

    addPositions = (records) => {
        //let added = 0;
        for(const p of records) {
            const {gpsTime, lat, lon} = p;
            if(this.data[p.vehId]) {
                if(this.data[p.vehId].length>0) {
                    if(this.data[p.vehId][0].gpsTime !== gpsTime) {
                        this.data[p.vehId].unshift({gpsTime,lat,lon});
                        //added++;
                        if(this.data[p.vehId].length > COUNT_LIMIT)  this.data[p.vehId].pop(); // too many
                    }
                }
                else {
                    //added++;
                    this.data[p.vehId] = [{gpsTime,lat,lon}];
                }
            }
            else {
                //added++;
                this.data[p.vehId] = [{gpsTime,lat,lon}];
            }
        }
    };

    getTrackToShow = (vehId) => {
        const limitText = ValueUtil.getYmdHmsInputText(new Date(), this.limitHours*3600);
        if(this.data[vehId]) {
            const track = this.data[vehId].filter((p)=>p.gpsTime >= limitText);
            if(track.length > 1) {
                // 2 이상이어야 함.
                return track;
            }
        }
        return [];
    };
}
