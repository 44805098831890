//VehPosListRepeat.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { IconButton, TextField, Button, Chip, Popover } from '@mui/material';
import ResponseAlert from '../message/ResponseAlert';
import { useInterval } from 'react-use';
import VehFilter from '../common/VehFilter/VehFilter';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TimelineIcon from '@mui/icons-material/Timeline'; // route mode
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'; // itin
import BedtimeIcon from '@mui/icons-material/Bedtime'; // No report at all.
import CellTowerIcon from '@mui/icons-material/CellTower'; // cell position
import GpsOffIcon from '@mui/icons-material/GpsOff'; // no position report 1 day or more
import LocalParkingIcon from '@mui/icons-material/LocalParking'; // no position rep 0.5 hour or more
import GpsFixedIcon from '@mui/icons-material/GpsFixed'; // speed < 2
import NavigationIcon from '@mui/icons-material/Navigation'; // speed >= 2
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
//import PodcastsIcon from '@mui/icons-material/Podcasts'; // normalTerm (primary), workingTerm (success)
//import SensorsOffIcon from '@mui/icons-material/SensorsOff'; // normalOff
import { AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
import { LIST_VEH_POS_AND_TERM_STAT } from './VehPosGql';
import { AppNumber, AppObject } from '../../model/AppConst';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { currentMenuRepo } from '../../model/CvoModel';
import FlexyTable from '../common/flexytable/FlexyTable';

const VehPosListContainer = styled(Box)({
	display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display: 'flex', alignItems:'center',
    height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});

const TermStateBox = styled(Box)({
    flexGrow:3, alignItems:'center', justifyContent:AppWord.END,textAlign:'right', paddingLeft:10, paddingRight: 10
});
const ButtonBox = styled(Box)({textAlign:'right'});

const TermStateTable = styled(Box)({border:AppPalette.BorderCCC, fontSize:'0.9rem', padding:10, borderRadius:10});
const TermStateRow = styled(Box)({display:'flex', padding:5});
const TermStateLabel = styled('div')({flexGrow:1, textAlign:'left', color: '#555', fontSize:'0.85rem', paddingRight:10});
const TermStateNumber = styled('div')({textAlign:'right', width:60, color: AppPalette.PrimaryRGB});

const SM = 'small';

const StateColumn = {
    id:'-mv-mark', label:'상태', width:50, show: true,
    option: {
        aligh:'center', type: TableColumnType.GRAPH,
        form: (rec, rowIndx) => {
            if(rec.gpsTime===AppWord.NOT_REAL_GPS_TIME) return <BedtimeIcon fontSize={SM} color='disabled' />;
            if(rec.reportAgeSec > 432000) return <GpsOffIcon fontSize={SM} color='warning' />;
            if(rec.reportAgeSec > 86400) return <GpsOffIcon fontSize={SM} color='inherit' />;
            if(rec.posSys==='C' || rec.posSys===null) return <CellTowerIcon fontSize={SM} color='disabled' />
            if(rec.reportAgeSec > 1800)  return <LocalParkingIcon fontSize={SM} color='inherit' />
            if(rec.speed < 2) return <GpsFixedIcon fontSize={SM} color='success'/>;
            return <NavigationIcon fontSize={SM} color='primary' style={{transform: 'rotate('+rec.direction+'deg)'}}/>;
        }
    }
};

function printDoorState(v) {
    if(v===null || v===undefined) return null;
    if(v) return <LockOpenIcon size={AppWord.SMALL} color={AppPalette.WarnColor}/>;
    else return <LockIcon size={AppWord.SMALL} color={AppPalette.DisabledColor}/>;
}

export const MiniTableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    StateColumn,
    { id: 'gpsTime', label: '최종보고', width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} }
];

// TODO: 도어 개폐 값 출력.
const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehAlias', label: '지도표시명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpsTime', label: '최종보고', width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    StateColumn,
    { id: 'location', label: '위치', width: 360, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'pwrTypeValue', label: '전원타입', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'posSysValue', label: '위치타입', width: 80, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'speed', label: '속도(Km/h)', width: 90, show: true, option:{align: 'center', type: TableColumnType.FLOAT} },
    { id: 'svcCondValue', label: '운행상태', width: 90, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'distKm', label: '누적거리(Km)', width: 100, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr1', label: '전면온도', width: 70, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr3', label: '중면온도', width: 70, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr2', label: '후면온도', width: 70, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tempr4', label: '온도4', width: 70, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'door0', label: '운전석방향', width: 80, show: true, option:{align: 'center', print: (r,i,v)=>printDoorState(v)} },
    { id: 'door1', label: '보조석방향', width: 80, show: true, option:{align: 'center', print: (r,i,v)=>printDoorState(v)} },
    { id: 'door2', label: '뒷문', width: 60, show: true, option:{align: 'center', print: (r,i,v)=>printDoorState(v)} },
    { id: 'driverName', label: '기사', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'driverMobile', label: '휴대전화', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'termId', label: '단말기번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehTypeTon', label: '차종-톤수', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'irActionName', label: '업무상태', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'roadName', label: '도로', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'roadMaxSpeed', label: '허용속도', width: 80, show: true, option:{align: 'center', type: TableColumnType.INTEGER} },
    { id: 'cargoRemark', label: '화물정보', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'irDist', label: '목적지거리(Km)', width: 120, show: true, option:{align: 'center', type: TableColumnType.FLOAT} },
    { id: 'irMinsText', label: '남은시간', width: 120, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'batteryVolt', label: '배터리전압', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'poiName', label: '현재지점', width: 120, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'poiArrTime', label: '지점도착', width: 165, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'tachoTempr1', label: '타코온도1', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr2', label: '타코온도2', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr3', label: '타코온도3', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr4', label: '타코온도4', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr5', label: '타코온도5', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr6', label: '타코온도6', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid1', label: '습도1'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid2', label: '습도2'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid3', label: '습도3'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid4', label: '습도4'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid5', label: '습도5'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid6', label: '습도6'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoDist', label: '타코누적거리(Km)', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoRpm', label: 'RPM', width: 100, show: false, option:{align: 'right', type: TableColumnType.INTEGER} },
    /*
    { id: 'recvTime', label: Dict.recvTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'insertTime', label: Dict.insertTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'lastGpsTime', label: Dict.lastGpsTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'gpsLat', label: Dict.gpsLat.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpsLon', label: Dict.gpsLon.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'direction', label: Dict.direction.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'statusCode', label: Dict.statusCode.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'eventCd', label: Dict.eventCd.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'loadCondCd', label: Dict.loadCondCd.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpioBits', label: Dict.gpioBits.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'pwrType', label: Dict.pwrType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'xDist', label: Dict.xDist.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'yDist', label: Dict.yDist.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'poiDistM', label: Dict.poiDistM.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'roadName', label: Dict.roadName.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'roadRank', label: Dict.roadRank.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'paraDiff', label: Dict.paraDiff.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'meridCode', label: Dict.meridCode.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'cycleMake', label: Dict.cycleMake.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'cycleReport', label: Dict.cycleReport.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'midRoute', label: Dict.midRoute.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'minsBatteryAlive', label: Dict.minsBatteryAlive.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },

    { id: 'isEngineOn01', label: Dict.isEngineOn01.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'isGpsGood01', label: Dict.isGpsGood01.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'extSensorName', label: Dict.extSensorName.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'extSensorData', label: Dict.extSensorData.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'emergencyYn', label: Dict.emergencyYn.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'staySince', label: Dict.staySince.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'stayX', label: Dict.stayX.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'stayY', label: Dict.stayY.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'memo1', label: Dict.memo1.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'memo2', label: Dict.memo2.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'mealStart', label: Dict.mealStart.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'restStart', label: Dict.restStart.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'preXd', label: Dict.preXd.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'preYd', label: Dict.preYd.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'preTime', label: Dict.preTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'cargoRmkTime', label: Dict.cargoRmkTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'updateSeq', label: Dict.updateSeq.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tempr1Alarm', label: Dict.tempr1Alarm.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tempr2Alarm', label: Dict.tempr2Alarm.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tempr3Alarm', label: Dict.tempr3Alarm.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tempr4Alarm', label: Dict.tempr4Alarm.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tachoPeriod', label: Dict.tachoPeriod.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoSpeed', label: Dict.tachoSpeed.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'tachoBreak', label: Dict.tachoBreak.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tachoTaillight', label: Dict.tachoTaillight.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'tachoInput', label: Dict.tachoInput.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'itinRepType', label: Dict.itinRepType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'irLat', label: Dict.irLat.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irLon', label: Dict.irLon.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irYCoord', label: Dict.irYCoord.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irXCoord', label: Dict.irXCoord.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irMins', label: Dict.irMins.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irPoiType', label: Dict.irPoiType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'irPoiSeq', label: Dict.irPoiSeq.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    { id: 'irAction', label: Dict.irAction.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'midWare', label: Dict.midWare.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'protocol', label: Dict.protocol.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} } */
];

const FetchInterval = 61; // seconds


export default function VehPosListRepeat({
	boxHeight,
	boxWidth,
    onClickItem,
    isMini, // minimized selector
    onClickShowRoute,
    onClickShowItinerary,
    // records,
    //timeRefresh,
    // responseList
}) {
    const [vehPosList, setVehPosList] = useState([]);
    const [timeGotPosList, setTimeGotPosList] = useState(0); // Date().getTime()
    const [filterBoxState, setFilterBoxState] = useState({open:false});
    const [vehFilterCriteria, setVehFilterCriteria] = useState(null);
    const [search, setSearch] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);
    const [itemSelected, setItemSelected] = useState(null);
    const [termStat, setTermStat] = useState(null);
    const [statAnchorEl, setStatAnchorEl] = useState(null);
    const currentMenu = useReactiveVar(currentMenuRepo);
    // const {loading:loadingList, error:errorOfList, data:dataList} = responseList;
    const clientSize = useClientSize();

    let counter = 0;

    // ##### Call GraphQL to get List #####
    const [getVehPosList, responseList] = useLazyQuery(LIST_VEH_POS_AND_TERM_STAT, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetListRepeat(data, option)},
        // rendering에 어떤 변화가 있으면 getVehPosList 호출은 되는데 onCompleted 콜백은 호출되지 않음.
        // 그러나 responseList에 대한 useEffect는 동작함. data.vehPosList에 대한 for loop가 문제였던 것 같음. 제거하니 잘 동작.
		onError: (error) => {
            setResponseAlert({open:true, error: error, title: "위치가져오기 실패."})
        }
    });

    const ticker = () => {
        if(currentMenu.menuId===AppWord.MENU_VEH_POS) {
            const now = new Date().getTime() / 1000;
            const diff = now - timeGotPosList;
            if(diff > FetchInterval) {
                setTimeGotPosList(now);
                counter = FetchInterval; // setCounter(FetchInterval);
                getVehPosList();
            }
            else {
                if(counter>0) counter--; // setCounter(counter - 1);
            }
        }
    };

    useInterval(ticker, 1000);

    useEffect(()=>{
        if(itemSelected) {
            const item = vehPosList.find((one) => {return one.vehId === itemSelected.vehId});
            if(item) setItemSelected(item);
            else setItemSelected(null);
        }
    }, [vehPosList, itemSelected]);

    const onCompleteGetListRepeat = (data, clientOption) => {
        const now = new Date().getTime() / 1000;
        if(data.vehPosList) {
            setVehPosList(data.vehPosList.slice());
            setTimeGotPosList(now);
        }
        else {
            setTimeGotPosList(now - FetchInterval + 5); // try again 5 sec later
        }

        if(data.termsStatItem) {
            setTermStat(data.termsStatItem);
        }
        // else setTermStat(null);
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
		if(onClickItem) onClickItem(item);
    };

    const onCloseFilterBox = () => {setFilterBoxState({open:false});};

    const onClickSearchFilterButton = (event) => {
        setFilterBoxState({
            open:true, 
            top: clientSize.HeaderHeight 
                + clientSize.dataContainerPadding 
                + clientSize.SmallBoxPadding,
            left: event.clientX - 10});
    };

    //const criteria = {vehTonId: [1, 3]};
    const onCompleteFilterBox = (criteria) => {
        setVehFilterCriteria(criteria);
        setFilterBoxState({open:false});
    };

    const renderTermStateRow = (label, value) => {
        return (
            <TermStateRow>
                <TermStateLabel>{label}</TermStateLabel>
                <TermStateNumber>{value}</TermStateNumber>
            </TermStateRow>
        );
    };

    const renderTermStat = () => {
        if(termStat) {
            const open = Boolean(statAnchorEl);
            const workRate = Math.round((termStat.usingTerm * 1000 / termStat.allTerms)) / 10;

            return(
                <TermStateBox>
                    <Chip label={`사용 단말기 ${termStat.usingTerm}(총${termStat.allTerms})`}
                        onMouseEnter={(e)=>{setStatAnchorEl(e.currentTarget)}}
                        variant={AppPalette.VariantOutlined}
                    />
                    <Popover
                        open={open}
                        anchorEl={statAnchorEl}
                        onClose={()=>setStatAnchorEl(null)}
                        anchorOrigin={{ vertical: AppWord.TOP, horizontal: AppWord.RIGHT, }}
                        transformOrigin={{ vertical: AppWord.TOP, horizontal: AppWord.RIGHT, }}
                    >
                        <TermStateTable
                            onMouseLeave={()=>setStatAnchorEl(null)}
                        >
                            {renderTermStateRow('가입 단말기', termStat.allTerms)}
                            {renderTermStateRow('사용 단말기 ', termStat.usingTerm)}
                            {renderTermStateRow('정상가동', termStat.normalTerm)}
                            {renderTermStateRow('정지단말기', termStat.normalOff)}
                            {renderTermStateRow('가동률', `${workRate}%`)}
                            {renderTermStateRow('지금 보고 중(15분)', termStat.workingTerm)}
                            {renderTermStateRow('사용하지 않는 단말기', termStat.notUsing)}
                            {renderTermStateRow('일시정지', termStat.stoppedTerm)}
                            {renderTermStateRow('사용대기', termStat.waitTerm)}
                        </TermStateTable>
                    </Popover>
                </TermStateBox>
            );
        }
        return null;
    };

    return(
        <VehPosListContainer width={boxWidth}>
            <HeaderBox>
                <TextField id="search" onChange={onChangeSearch}
                    value={search}
                    label="검색" size='small'/>
                {
                    isMini ? null :
                    <IconButton size='small' onClick={onClickSearchFilterButton}>
                        <FilterAltIcon />
                    </IconButton>
                }
                {
                    isMini ? null : renderTermStat()
                }
                {
                    isMini ? null :
                    <ButtonBox>
                        <Button
                            disabled={!Boolean(itemSelected)}
                            onClick={()=>{onClickShowRoute(itemSelected)}}
                            variant='contained' color='primary'>
                            <TimelineIcon fontSize='small' /> 차량경로보기
                        </Button>
                        &nbsp;
                        <Button
                            disabled={!Boolean(itemSelected)}
                            onClick={()=>{onClickShowItinerary(itemSelected)}}
                            variant='contained' color='primary'>
                            <WhereToVoteIcon fontSize='small' /> 동정정보보기
                        </Button>
                    </ButtonBox>
                }
            </HeaderBox>
            <FlexyTable
                name="veh_pos_list"
                uniqueKey="vehId"
                columns={TableColumns}
                records={vehPosList}
                search={search}
                criteria={isMini ? undefined : vehFilterCriteria}
                tools={[]}
                onClickOneRow={onSelectItem}
				selected={itemSelected ? [itemSelected] : null}
            />
            <VehFilter open={filterBoxState.open}
                top={filterBoxState.top}
                left={filterBoxState.left}
                useCustAgree={true}
                onComplete={onCompleteFilterBox}
                onCloseBox={onCloseFilterBox} />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehPosListContainer>
    );
}

/*
isMini ? MiniTableColumns : 
*/