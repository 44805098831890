// veh_pos/VehPosGql.js
import { gql } from "@apollo/client";


export const LIST_VEH_TEMPR = gql`
query ListVehTemp4 {
    vehTemprList {
        vehId
        custId
        custName
        vehPlates
        useYn
        gpsTime
        gpsTimeAsSec
        reportAgeSec
        vehAlias
        driverName
        driverMobile
        vehTonId
        ton
        vehTypeId
        vehTypeName
        vehTypeTon
        veh01Cd
        veh02Cd
        veh03Cd
        devId
        termId
        recvTime
        insertTime
        lastGpsTime
        speed
        distKm
        direction
        eventCd
        location
        lon
        lat
        xDist
        yDist
        cargoRemark
        cargoRmkTime
        tempr1
        tempr2
        tempr3
        tempr4
        tempr1Break
        tempr2Break
        tempr3Break
        tempr4Break
        tempr1Alarm
        tempr2Alarm
        tempr3Alarm
        tempr4Alarm
        tachoTempr1
        tachoTempr2
        tachoTempr3
        tachoTempr4
        tachoTempr5
        tachoTempr6
        tachoHumid1
        tachoHumid2
        tachoHumid3
        tachoHumid4
        tachoHumid5
        tachoHumid6
        tachoPeriod
        tachoSpeed
        tachoRpm
        poiId
        poiName
        poiArrTime
    }
}
`;
/*
        midRoute
        tachoBreak
        tachoTaillight
        tachoInput
        tachoDist
        tempr1Ll
        tempr1Ul
        tempr2Ll
        tempr2Ul
        tempr3Ll
        tempr3Ul
        tempr4Ll
        tempr4Ul
        tachoTempr1Ll
        tachoTempr1Ul
        tachoTempr2Ll
        tachoTempr2Ul
        tachoTempr3Ll
        tachoTempr3Ul
        tachoTempr4Ll
        tachoTempr4Ul
        tachoTempr5Ll
        tachoTempr5Ul
        tachoTempr6Ll
        tachoTempr6Ul
        tachoHumid1Ll
        tachoHumid1Ul
        tachoHumid2Ll
        tachoHumid2Ul
        tachoHumid3Ll
        tachoHumid3Ul
        tachoHumid4Ll
        tachoHumid4Ul
        tachoHumid5Ll
        tachoHumid5Ul
        tachoHumid6Ll
        tachoHumid6Ul
        tachoSpeedLimit
        tachoRpmLimit
        tempAlertLimit
*/


const VEH_POS_LIST_PART = gql`
fragment VehPosListPart on VehPos {
    vehId
    custId
    vehPlates
    vehAlias
    custName
    devId
    termId
    gpsTime
    gpsTimeAsSec
    reportAgeSec
    recvTime
    insertTime
    lastGpsTime
    speed
    distKm
    direction
    statusCode
    useYn
    driverName
    driverMobile
    vehTonId
    ton
    vehTypeId
    vehTypeName
    vehTypeTon
    vehAddr
    operFontColor
    operBackColor
    noOperFontColor
    noOperBackColor
    veh01Cd
    veh02Cd
    veh03Cd
    termPowerCd
    termPowerValue
    termStateCd
    termStateValue
    networkCd
    networkValue
    eventCd
    svcCondCd
    svcCondValue
    loadCondCd
    gpioBits
    pwrType
    pwrTypeValue
    posSys
    posSysValue
    xDist
    yDist
    location
    custPoiCode
    addrPrefix
    poiDistM
    roadName
    roadRank
    roadMaxSpeed
    lon
    lat
    minsBatteryAlive
    batteryVolt
    isEngineOn01
    isGpsGood01
    extSensorName
    extSensorData
    door0,
    door1,
    door2,
    doorSensors,
    emergencyYn
    staySince
    stayedForSec
    stayX
    stayY
    memo1
    memo2
    mealStart
    restStart
    cargoRemark
    cargoRmkTime
    tempr1
    tempr2
    tempr3
    tempr4
    tempr1Break
    tempr2Break
    tempr3Break
    tempr4Break
    tempr1Alarm
    tempr2Alarm
    tempr3Alarm
    tempr4Alarm
    tachoTempr1
    tachoTempr2
    tachoTempr3
    tachoTempr4
    tachoTempr5
    tachoTempr6
    tachoHumid1
    tachoHumid2
    tachoHumid3
    tachoHumid4
    tachoHumid5
    tachoHumid6
    tachoPeriod
    tachoSpeed
    tachoRpm
    tachoBreak
    tachoTaillight
    tachoInput
    tachoDist
    itinRepType
    irLat
    irLon
    irYCoord
    irXCoord
    irDist
    irMins
    irMinsText
    irPoiType
    irPoiSeq
    irAction
    irActionName
    manReportYn
    midWare
    protocol
    poiId
    poiName
    poiArrTime
    midPoints {
        diffSeconds
        lon
        lat
    }
}
`;

export const LIST_VEH_POS = gql`
${VEH_POS_LIST_PART}
query ListVehPos {
    vehPosList {
        ...VehPosListPart
    }
}
`;

export const LIST_VEH_POS_AND_TERM_STAT = gql`
${VEH_POS_LIST_PART}
query ListVehPosAndTermStat($custId:String) {
    vehPosList {
        ...VehPosListPart
    }
    termsStatItem(custId: $custId) {
        custId
        custName
        normalTerm
        normalOff
        closedTerm
        stoppedTerm
        waitTerm
        usingTerm
        notUsing
        workingTerm
        allTerms
        regTime
    }
}
`;
