// cust_info/CustInfoGql.js
import { gql } from "@apollo/client";
import { CvoCodes } from "../../model/AppConst";
import ValueUtil from "../../model/ValueUtil";

export const CustInfoDictionary = {
    inputMap: {
        custId : {toAdd: false, toEdit: true},
        custName : {
            id: 'custName', label: '업체명', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '20자 이내로 입력하세요.', // 60 bytes.
            check: (value) => {return !ValueUtil.isNullString(value) && ValueUtil.textInRange(value,1,60)},
        },
        industryId : {
            id: 'industryId', label: '메뉴구분업종', type: 'radio', required: true,
            toAdd: true, toEdit: true, //            help: '최대값은 10 입니다.',
            default: 1,
            select: [
                {value: 1, label: '일반업종'},
                {value: 2, label: '지자체청결관리'},
                {value: 3, label: '일반업종(저가)'},
            ],
            check: (value) => {return [1,2,3].includes(value);}
        },
        firmTypeCd : {
            id: 'firmTypeCd', label: '회사형태', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default: 'FIRM',
            check: (value) => {return true},
        },
        bizTypeCd : {
            id: 'bizTypeCd', label: '업종', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default:'GENERAL',
            check: (value) => {return true},
        },
        billMeth : {
            id: 'billMeth', label: '과금방법', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default:'ETAXBILL',
            check: (value) => {return true},
        },
        payMeth : {
            id: 'payMeth', label: '요금제', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default:'SLIM',
            check: (value) => {return true},
        },
        payTerm : {
            id: 'payTerm', label: '지급구분', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default:'GENERAL',
            check: (value) => {return true},
        },
        // ======
        reprName : {
            id: 'reprName', label: '대표자 이름', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '2~6자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,2,20)},
        },
        compBizno : {
            id: 'compBizno', label: '사업자 등록번호', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '숫자와 하이픈만 사용가능합니다.',
            check: (value) => {return ValueUtil.isNullString(value) || /^\d{3}-?\d{2}-?\d{5}$/.test(value)},
        },
        custCorpNo : {
            id: 'custCorpNo', label: '법인번호', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '13자 숫자로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || /^\d{13}$/.test(value)},
        },
        telNo : {
            id: 'telNo', label: '대표전화', type: 'tel', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value);}
        },
        faxNo : {
            id: 'faxNo', label: '대표팩스', type: 'tel', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value);}
        },
        unitServiceFee : {
            id: 'unitServiceFee', label: '서비스 단가', type: 'number', required: false,
            toAdd: true, toEdit: true,
            help: '단말기 당 월 단가',
            default: 10000,
            check: (value) => {return value >= 0 && value < 999999},
        },
        smsUseFlag : {
            id: 'smsUseFlag', label: '문자전송 서비스 사용', type: 'checkbox', required: false,
            toAdd: true, toEdit: true,
            default: 'N', trueValue: 'Y', falseValue: 'N',
            check: (value) => {return value==='Y' || value==='N'},
        },
        // =====
        zipCd : {
            id: 'zipCd', label: '업체 우편번호', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '7자 이내 입력',
            check: (value) => {return ValueUtil.isNullString(value) || /^[-0-9]{5,7}$/.test(value)},
        },
        addr : {
            id: 'addr', label: '업체 주소', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '비워두거나 한글 33자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,100)},
        },
        postRecvZipCd : {
            id: 'postRecvZipCd', label: '우편물수령지 우편#', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '7자 이내 입력',
            check: (value) => {return ValueUtil.isNullString(value) || /^[-0-9]{5,7}$/.test(value)},
        },
        postRecvAddr : {
            id: 'postRecvAddr', label: '우편물수령지 주소', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '비워두거나 한글 33자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,100)},
        },
        // ======
        billJobType : {
            id: 'billJobType', label: '업태', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '13자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },
        billJobClass : {
            id: 'billJobClass', label: '종목', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '13자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },
        billStaffName : {
            id: 'billStaffName', label: '계산서담당자', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '2자~10자 이내로 입력합니다.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,2,30)},
        },
        billStaffRank : {
            id: 'billStaffRank', label: '계산서담당자 직급', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '2~6자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,2,20)},
        },
        billStaffTelNo : {
            id: 'billStaffTelNo', label: '계산서담당자 연락처', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value);}
        },
        billStaffFaxNo : {
            id: 'billStaffFaxNo', label: '계산서담당자 팩스', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value);}
        },
        billStaffEmail : {
            id: 'billStaffEmail', label: '계산서담당자 메일', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '비워두거나 60자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || (ValueUtil.goodEmail(value) && value.length <= 60 );},
        },
        // ======
        agentName : {
            id: 'agentName', label: '업무담당자', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '2자~10자 이내로 입력합니다.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,2,30)},
        },
        agentTelNo : {
            id: 'agentTelNo', label: '업무담당자 연락처', type: 'tel', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value);},
        },
        agentEmail : {
            id: 'agentEmail', label: '업무담당자 메일', type: 'email', required: false,
            toAdd: true, toEdit: true,
            help: '60자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || (ValueUtil.goodEmail(value) && value.length <= 60 );},
        },
        salesStaffName : {
            id: 'salesStaffName', label: '영업담당자', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '2~6자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,2,20)},
        },
        etc : {
            id: 'etc', label: '비고', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '330자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,1000)},
        },
    },
    // To get common-response data
    queryName: {Add:'custInfoAdd', Edit:'custInfoEdit', Remove:'custInfoRemove'},
    codeToField: {
        [CvoCodes.CUST_STATE_CD]: 'custStateCd',
        [CvoCodes.BIZ_TYPE_CD]: 'bizTypeCd'
    },
    errorTitle: {
        Add: '업체 정보 생성 오류',
        Edit: '업체 정보 수정 오류',
        SetState: '업체상태 설정 오류',
        Get: '업체 정보 조회 오류',
    },
    custState: {NORMAL:'NORMAL', STOPPED: 'STOPPED', LEFT:'LEFT'}
};
const CUST_INFO_LIST_PART = gql`
fragment CustInfoListPart on CustInfo {
    custId
	custName
	firmTypeCd
	firmTypeValue
	bizTypeCd
	bizTypeValue
	billMeth
	billMethValue
	custStateCd
	custStateValue
	agentName
	agentTelNo
	agentEmail
	smsUseFlag
	compBizno
	custCorpNo
	billStaffName
	billStaffTelNo
	billStaffEmail
	billStaffFaxNo
	salesStaffName
	reprName
	telNo
	faxNo
	addr
	etc
	regDate
}
`;

const CUST_INFO_NO_LIST_PART = gql`
fragment CustInfoNoListPart on CustInfo {
	industryId
	industryName
	payMeth
	payMethValue
	payTerm
	payTermValue
	agentDept
	agentMobile
	userLimit
	crmUserId
	interfacePwd
	dataDeploy
	zipCd
	postRecvZipCd
	postRecvAddr
	billJobType
	billJobClass
	billStaffRank
	paymentSmallSum
	paymentElecTaxbill
	paymentGeneralTaxbill
	unitServiceFee
	firstOpenDate
	lastVisitDate
	modelId
	useForWho
	crmDifficulty
	cheatEverYn
	vehOwned
	vehAvail
	indiAddress
	homepage
	sensors
	satisfaction
	debits
	credits
	lastDebitDate
	lastCreditDate
	lastDebit
	lastCredit
	smsRelayYn
	checkStopForSec
	checkShortMoveYn
	checkStopForMeter
	poiByGroupCd
	regUserId
}
`;

export const LIST_CUST_INFO = gql`
${CUST_INFO_LIST_PART}
query ListCustInfo {
    custInfoList {
        ...CustInfoListPart
    }
}
`;

export const GET_CUST_INFO = gql`
${CUST_INFO_LIST_PART}
${CUST_INFO_NO_LIST_PART}
query GetCustInfo($custId:String) {
    custInfoItem(custId:$custId) {
        ...CustInfoListPart
        ...CustInfoNoListPart
    }
}
`;

export const ADD_CUST_INFO = gql`
mutation AddCustInfo($custInfo:InputCustInfoAdd!) {
    custInfoAdd(custInfo: $custInfo) {
        ok
        message
    }
}
`;

export const EDIT_CUST_INFO = gql`
mutation EditCustInfo($custInfo:InputCustInfoEdit!) {
    custInfoEdit(custInfo: $custInfo) {
        ok
        message
    }
}
`;

export const SET_STATE_CUST_INFO = gql`
mutation SetStateCustInfo($custInfo:InputCustInfoSetState!) {
    custInfoSetState(custInfo: $custInfo) {
        ok
        message
    }
}
`;

export const GET_CUST_GEN_DATA = gql`
query GetCustGenData($custId:String) {
    vehReportStatItem(custId: $custId) {
        sumVehicle
        sumUsing
        noDevice
        running
        inDay
        inWeek
        inMonth
        overMonth
    }
    termsStatItem(custId: $custId) {
        custId
        custName
        normalTerm
        normalOff
        closedTerm
        stoppedTerm
        waitTerm
        usingTerm
        notUsing
        workingTerm
        allTerms
        regTime
    }
    viewTelecomStatusList(custId: $custId) {
        custId
        custName
        networkCd
        telecom
        termCount
    }
    custAgreeListAll(custId: $custId) {
        groupId
        groupName
        custId
        custNameUsing
        givingCustId
        givingCustName
        givingUserId
        editYn
        userId
    }
}
`;
