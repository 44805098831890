// DownloadFileInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Button, IconButton, TextField } from '@mui/material';
import ResponseAlert from '../message/ResponseAlert';
import ConfirmDialog from '../message/ConfirmDialog';
import TossTable from '../common/TossTable';
import FlexyTable from '../common/flexytable/FlexyTable';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CvoFileDictionary } from '../cvo_file/CvoFileGql';
import {
    DownloadFileDictionary, LIST_DOWNLOAD_FILE, ADD_DOWNLOAD_FILE, EDIT_DOWNLOAD_FILE, REMOVE_DOWNLOAD_FILE
} from './DownloadFileGql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AppObject, AppPalette, AppWord } from '../../model/AppConst';
import ValueUtil from "../../model/ValueUtil";
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const PairContainer = styled(Box)({
	flexGrow:1, display:'flex',
});
const SetBox =  styled(Box)({ // has SetTitleBox, SearchBox and TableBox
    flexGrow:1,
    display:'flex',
    flexDirection: 'column',
    marginLeft:3,
    marginRight:3,
    width:'50%'
});
const SetTitleBox = styled(Box)({fontWeight:'bold', display:'flex', alignItems:'center', height:40});

const TableBox = styled(Box)({
    flexGrow:1,
    border:'1px solid #ccc',
    overflow:'auto',
    borderRadius:5,
    position:'relative',
    display:'flex',
    flexDirection: 'column'
});

const InputTitleBox = styled(Box)({ marginTop: 10, padding: 10, });
const InputDetailBox = styled(Box)({ padding: 5, fontSize: '0.85em' });
const InputButtonBox = styled(Box)({ padding: 5, '&>*': {marginRight:10}});

const InputMap = DownloadFileDictionary.inputMap;
const ErrorTitle =DownloadFileDictionary.errorTitle;

export default function DownloadFileInput({
    downloadInfo,
    cvoFiles,
    downloadFiles,
    setDownloadFiles,
    onClose,
}) {
    const [candidates, setCandidates] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [cvoFileSelected, setCvoFile] = useState(null);
    const [fileSelected, setFileSelected] = useState(null);
    const [fileTitle, setFileTitle] = useState('');

    // ##### Call GraphQL to get List #####
    const [getDownloadFileList, responseList] = useLazyQuery(LIST_DOWNLOAD_FILE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    
    // ##### GraphQL Mutation. ###
    const [addDownloadFile, responseAdd] = useMutation( ADD_DOWNLOAD_FILE, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editDownloadFile, responseEdit] = useMutation( EDIT_DOWNLOAD_FILE, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeDownloadFile, responseRemove] = useMutation( REMOVE_DOWNLOAD_FILE, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        if(cvoFiles) {
            const files = cvoFiles.filter((cvoFile) => {
                for(const df of downloadFiles) {
                    if(df.fileNid === cvoFile.fileNid) return false;
                }
                return true;
            });
            setCandidates(files);
        }
    }, [cvoFiles, setCandidates, downloadFiles]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.downloadFileList) setDownloadFiles(data.downloadFileList);
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.downloadFileAdd.ok) {
            // setEditorState(null);
            setDownloadFiles(data.downloadFileAdd.list);
            setCvoFile(null);
            setFileTitle('');
        }
        else setResponseAlert({open:true, resultData: data.downloadFileAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.downloadFileEdit.ok) {
            //setEditorState(null);
            setDownloadFiles(data.downloadFileEdit.list);
            setFileSelected(null);
            setFileTitle('');
        }
        else setResponseAlert({open:true, resultData: data.downloadFileEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.downloadFileRemove.ok) setDownloadFiles(data.downloadFileRemove.list);
        else setResponseAlert({open:true, resultData: data.downloadFileRemove, title: ErrorTitle.Remove});
    };

    const onClickEdit = (rec) => {
        setFileSelected(rec);
        setFileTitle(rec.title);
    };

    const onClickDetach = (item) => {
        setPromptToConfirm({
            data: item,
            title: '첨부파일 제거',
            messages: [
                '첨부된 ' + item.title + ' 파일을 삭제하시겠습니까?',
                '해당 파일의 첨부상태만 삭제되고 파일이 삭제되지는 않습니다.',
                '진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{downloadFile:{msgSeq: data.msgSeq,fileNid: data.fileNid}}};
                    removeDownloadFile(param);
                }
            }
        });
    };

    const onClickAttach = (rec) => {
        setCvoFile(rec);
        setFileTitle(rec.memo);
        // addDownloadFile({variables: {downloadFile: {fileNid:rec}}})
    };

    const onClickAdd = () => {
        if(ValueUtil.textInRange(fileTitle, 1, 120)) {
            addDownloadFile({variables: {
                downloadFile: {
                    fileNid:cvoFileSelected.fileNid, msgSeq: downloadInfo.msgSeq,
                    title: fileTitle
                }}});
        }
    };

    const onClickUpdate = () => {
        if(ValueUtil.textInRange(fileTitle, 1, 120)) {
            editDownloadFile({variables: {
                downloadFile: {
                    fileNid:fileSelected.fileNid, msgSeq: fileSelected.msgSeq,
                    title: fileTitle
                }}});
        }
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onClickDetach(row); break;
            case AppWord.CMD_EDIT: onClickEdit(row); break;
            default: return;
        }
    };

    if(ValueUtil.hasAnyAuthError(
        responseList,
        responseAdd,
        responseEdit,
        responseRemove
    ))  userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    return (
        <PairContainer>
            <SetBox>
                <SetTitleBox>
                    {
                        onClose && !Boolean(cvoFileSelected) && !Boolean(fileSelected) ?
                        <IconButton size={AppWord.SMALL}
                            onClick={()=>{if(onClose) onClose()}}
                            color={AppPalette.PrimaryColor}
                            title='첨부관리작업 종료하기'
                        >
                            <ArrowBackIcon fontSize={AppWord.SMALL} />
                        </IconButton>
                        : null
                    }
                    첨부파일
                </SetTitleBox>
                <TableBox>
                    {
                        Boolean(fileSelected)
                        ?
                        <Box>
                            <InputTitleBox>
                                <TextField id={InputMap.title.id} size={AppWord.SMALL}
                                    type={InputMap.title.type}
                                    label={InputMap.title.label}
                                    required={InputMap.title.required}
                                    value={fileTitle || ''}
                                    error={ValueUtil.textInRange(1, 120)}
                                    onChange={(event)=>{setFileTitle(event.target.value)}}
                                />
                            </InputTitleBox>
                            <InputDetailBox>
                                File: {fileSelected.memo}
                            </InputDetailBox>
                            <InputDetailBox>
                                Size: {ValueUtil.getSizeInText(fileSelected.fileSize)}
                            </InputDetailBox>
                            <InputButtonBox>
                                <Button variant={AppPalette.VariantContained} color={AppPalette.PrimaryColor}
                                    onClick={onClickUpdate}
                                >
                                    저장
                                </Button>
                                <Button variant={AppPalette.VariantContained} color={AppPalette.InheritColor}
                                    onClick={()=>{setFileSelected(null); setFileTitle('');}}
                                >
                                    취소
                                </Button>
                            </InputButtonBox>
                        </Box>
                        :
                        <FlexyTable
                            name="download_file_list"
                            uniqueKey="fileNid"
                            columns={DownloadFileDictionary.filesColumns}
                            records={downloadFiles}
                            tools={[
                                {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
                                {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} color={AppPalette.WarnColor} />}
                            ]}
                            onClickTool={onClickToolOnRow}
                            hideFirstTopButton={true}
                        />
                    }
                </TableBox>
            </SetBox>
            <SetBox>
                <SetTitleBox>
                    {
                        Boolean(cvoFileSelected) ?
                        <span>첨부하기</span>
                        : <span>첨부 가능한 파일</span>
                    }
                    
                </SetTitleBox>
                <TableBox>
                {
                    Boolean(cvoFileSelected)
                    ?
                    <Box>
                        <InputTitleBox>
                            <TextField id={InputMap.title.id} size={AppWord.SMALL}
                                type={InputMap.title.type}
                                label={InputMap.title.label}
                                required={InputMap.title.required}
                                value={fileTitle || ''}
                                error={ValueUtil.textInRange(1, 120)}
                                onChange={(event)=>{setFileTitle(event.target.value)}}
                            />
                        </InputTitleBox>
                        <InputDetailBox>
                            File: {cvoFileSelected.memo}
                        </InputDetailBox>
                        <InputDetailBox>
                            Size: {ValueUtil.getSizeInText(cvoFileSelected.fileSize)}
                        </InputDetailBox>
                        <InputButtonBox>
                            <Button variant={AppPalette.VariantContained} color={AppPalette.PrimaryColor}
                                onClick={onClickAdd}
                            >
                                첨부
                            </Button>
                            <Button variant={AppPalette.VariantContained} color={AppPalette.InheritColor}
                                onClick={()=>{setCvoFile(null); setFileTitle('');}}
                            >
                                취소
                            </Button>
                        </InputButtonBox>
                    </Box>
                    :
                    <TossTable
                        columns={CvoFileDictionary.SelectableListColumns}
                        records={candidates}
                        onClickSend={onClickAttach}
                    />

                }
                </TableBox>
            </SetBox>
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </PairContainer>
    )
}
