// cust_get_alarm/CustGetAlarmGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const CustGetAlarmDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        // custId : {toAdd: true, toEdit: true},
        alarmTypeId : {
            id: 'alarmTypeId', label: '알람', type: 'select', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return value > 0 && value < 99999},
        },
        mobile : {
            id: 'mobile', label: '휴대전화', type: 'tel', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value)},
        },
        rcvrName : {
            id: 'rcvrName', label: '수신자이름', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '30자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,30)},
        },
        alarmInterval : {
            id: 'alarmInterval', label: '알람수신간격(분)', type: 'number', required: true,
            toAdd: true, toEdit: true,
            default: 30,
            check: (value) => {return value >= 10 && value < 1440},
        },
        timeFrom : {
            id: 'timeFrom', label: '알람수신시작시각', type: 'time', required: true,
            toAdd: true, toEdit: true,
            //check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,38)},
        },
        timeTill : {
            id: 'timeTill', label: '알람수신종료시각', type: 'time', required: true,
            toAdd: true, toEdit: true,
            //check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,38)},
        },
    },
    // for response error printing
    queryName: {Add:'userInfoAdd', Edit:'userInfoEdit', Remove:'userInfoRemove'},
    errorTitle: {
        Add: '알람수신정보 생성 오류',
        Edit: '알람수신정보 수정 오류',
        Remove: '알람수신정보 삭제 오류',
        Get: '알람수신정보 조회 오류',
        List: '알람수신정보목록 조회 오류',
    }
};

const CUST_GET_ALARM_LIST_PART = gql`
fragment CustGetAlarmListPart on CustGetAlarm {
    custId
    alarmTypeId
    alarmType
    mobile
    rcvrName
    alarmInterval
    timeFrom
    timeTill
    regUserId
    regTime
}
`;

export const LIST_ALARM_TYPE = gql`
query ListAlarmType {
    alarmTypeList {
        alarmTypeId
        alarmType
        alarmTypeCode
    }
}
`;


export const LIST_CUST_GET_ALARM = gql`
${CUST_GET_ALARM_LIST_PART}
query ListCustGetAlarm {
    custGetAlarmList {
        ...CustGetAlarmListPart
    }
}
`;

export const GET_CUST_GET_ALARM = gql`
${CUST_GET_ALARM_LIST_PART}
query GetCustGetAlarm($custId:String, $alarmTypeId:Int, $mobile:String) {
    custGetAlarmItem(custId:$custId, alarmTypeId:$alarmTypeId, mobile:$mobile) {
        ...CustGetAlarmListPart
    }
}
`;

export const ADD_CUST_GET_ALARM = gql`
mutation AddCustGetAlarm($custGetAlarm:InputCustGetAlarmAdd!) {
  custGetAlarmAdd(custGetAlarm: $custGetAlarm) {
    ok
    message
  }
}
`;

export const EDIT_CUST_GET_ALARM = gql`
mutation EditCustGetAlarm($custGetAlarm:InputCustGetAlarmEdit!) {
  custGetAlarmEdit(custGetAlarm: $custGetAlarm) {
    ok
    message
  }
}
`;

export const REMOVE_CUST_GET_ALARM = gql`
mutation RemoveCustGetAlarm($custGetAlarm:InputCustGetAlarmRemove!) {
  custGetAlarmRemove(custGetAlarm: $custGetAlarm) {
    ok
    message
  }
}
`;
