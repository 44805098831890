// TermMng.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Button, IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import GetAppIcon from '@mui/icons-material/GetApp';
import TextCombo from '../common/TextCombo';
import TermMngList from './TermMngList';
import TermMngInput from './TermMngInput';
import TermMngEdit from './TermMngEdit';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    LIST_TERM_MNG, GET_TERM_MNG, ADD_TERM_MNG, EDIT_TERM_MNG, REMOVE_TERM_MNG,
    LIST_TERM_MNG_CUST,
	TermMngDictionary
} from './TermMngGql';
import { AppObject, AppNumber, AppWord, AppPalette, CvoCodes } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser, codeTreeRepo } from '../../model/CvoModel';
import CodeSelector from '../common/CodeSelector';
import Util from '../../model/Util';

const dcPad = AppNumber.dataContainerPadding;
const TermMngContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection:'column'
});

const SearchConditionBox = styled(Box)({ padding: AppNumber.SmallBoxPadding });
const SearchInputRow = styled(Box)({display:'flex', marginTop:8, alignItems:'center'});
const SearchGroup = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});
const TableBox = styled(Box)({flexGrow:1, display:'flex'});
const FilterField = styled(Box)({display:'inline-block', marginRight:10});

const ErrorTitle =TermMngDictionary.errorTitle;
const InputMap = TermMngDictionary.inputMap;
const SearchKey = {
    termId: InputMap.termId.id,
    serialNo: InputMap.serialNo.id,
    usimNum: InputMap.usimNum.id,
    openDate: InputMap.openDate.id,
    openMonth: "openMonth",
    openYear: "openYear",
    abandonDate: InputMap.abandonDate.id,
    abandonMonth: "abandonMonth",
    abandonYear: "abandonYear",
    custName: InputMap.custName.id
};

const SearchFields = [
    {value: SearchKey.custName, label: InputMap.custName.label},
    {value: SearchKey.termId, label: InputMap.termId.label},
    {value: SearchKey.serialNo, label: InputMap.serialNo.label},
    {value: SearchKey.usimNum, label: InputMap.usimNum.label},
    {value: SearchKey.openDate, label: InputMap.openDate.label},
    {value: SearchKey.abandonDate, label: InputMap.abandonDate.label},
];

const DefaultSearchParam = {searchField:SearchKey.custName, search:"", dateFrom:""};
const NotSelectedValue = {value:AppWord.NO_FILTER, label:"(전체)"};
const DefaultFilter = {
    openDate: '',
    abandonDate: '',
    acNetworkCd: NotSelectedValue.value,
    acTermStateCd: NotSelectedValue.value,
    acOpenForCd: NotSelectedValue.value,
    acFirmTypeCd: NotSelectedValue.value,
    acModelCd: NotSelectedValue.value,
    acBillTypeCd: NotSelectedValue.value,
    acContrMonthCd: NotSelectedValue.value,
    acContrAmountCd: NotSelectedValue.value,
};

function getYmdFilterText(txt) {
    let ymd = '';
    if(txt) {
        const len = txt.length;
        if(len >= 4) {
            ymd = txt.substr(0,4);
            if(len >= 6) {
                ymd += '-' + txt.substr(4,2);
                if(len >= 8) ymd += '-' + txt.substr(6,2);
            }
        }
    }
    return ymd;
}

function makeYmdToRetrieve(txt, isOpen) {
    let ymd = '';
    let key = isOpen ? "open" : "abandon";

    if(txt) {
        const len = txt.length;
        if(len >= 4) {
            ymd = txt.substr(0,4);
            if(len >= 6) {
                ymd += '-' + txt.substr(4,2);
                if(len >= 8) {
                    ymd += '-' + txt.substr(6,2);
                    key += "Date";
                }
                else {
                    ymd += "-01";
                    key += "Month";
                }
            }
            else {
                ymd += "-01-01";
                key += "Year";
            }
        }
    }
    return {dateFrom: ymd, searchField: key};
}

export default function TermMng() {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [NetworkList, setNetworkList] = useState([]);
    const [TermStateList, setTermStateList] = useState([]);
    const [OpenForList, setOpenForList] = useState([]);
    const [FirmTypeList, setFirmTypeList] = useState([]);
    const [ModelList, setModelList] = useState([]);
    const [BillTypeList, setBillTypeList] = useState([]);
    const [ContrMonthList, setContrMonthList] = useState([]);
    const [ContrAmountList, setContrAmountList] = useState([]);
    const [inputForRetrieve, setInputForRetrieve] = useState({
        searchField:SearchKey.custName,
        usingTerm:"", usingSerial:"", usingUsim:"",
        usingCust:"", usingOpen:"", usingAbandon:""}); // for interface
    const [paramForList, setParamForList] = useState({...DefaultSearchParam});
	const [termMngRecords, setTermMngRecords] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [search, setSearch] = useState('');  // for filter
    const [filterData, setFilterData] = useState({...DefaultFilter});
    const [filterOpenDate, setFilterOpenDate] = useState('');
    const [filterAbandonDate, setFilterAbandonDate] = useState('');
	const [termMngSelected, setTermMngSelected] = useState(null);
    const [openNewEditor, setOpenNewEditor] = useState(false); // 신규정보 입력창을 보인다. 아니면 검색 조건 필드들을 보여준다.
    const [editorState, setEditorState] = useState(null); // 편집에만 사용한다.
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
	const sessionInfo = useReactiveVar(userInfoRepo);
    // const responseList = useQuery(GET_TERM_MNG_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});

    useEffect(()=>{
        if(CodeTree) {
            setNetworkList(ValueUtil.codeToSelectList(CvoCodes.AC_NETWORK_CD, CodeTree, NotSelectedValue));
            setTermStateList(ValueUtil.codeToSelectList(CvoCodes.AC_TERM_STATE_CD, CodeTree, NotSelectedValue));
            setOpenForList(ValueUtil.codeToSelectList(CvoCodes.AC_OPEN_FOR_CD, CodeTree, NotSelectedValue));
            setFirmTypeList(ValueUtil.codeToSelectList(CvoCodes.AC_FIRM_TYPE_CD, CodeTree, NotSelectedValue));
            setModelList(ValueUtil.codeToSelectList(CvoCodes.AC_MODEL_CD, CodeTree, NotSelectedValue));
            setBillTypeList(ValueUtil.codeToSelectList(CvoCodes.AC_BILL_TYPE_CD, CodeTree, NotSelectedValue));
            setContrMonthList(ValueUtil.codeToSelectList(CvoCodes.AC_CONTR_MONTH_CD, CodeTree, NotSelectedValue));
            setContrAmountList(ValueUtil.codeToSelectList(CvoCodes.AC_CONTR_AMOUNT_CD, CodeTree, NotSelectedValue));

            setFilterData({...DefaultFilter});
        }
    }, [CodeTree]);

    // ##### Call GraphQL to get List #####
    const [getTermMngList, responseList] = useLazyQuery(LIST_TERM_MNG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getTermMngCustList, resultListCust] = useLazyQuery(LIST_TERM_MNG_CUST, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetCustList(data, option)},
        onError: (error) =>  {setResponseAlert({open:true, error: error, title: "업체목록조회 오류"})}
    });
    const [getTermMngItemToEdit, responseItemToEdit] = useLazyQuery(GET_TERM_MNG, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation. ###
    const [addTermMng, responseAdd] = useMutation( ADD_TERM_MNG, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editTermMng, responseEdit] = useMutation( EDIT_TERM_MNG, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeTermMng, responseRemove] = useMutation( REMOVE_TERM_MNG, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getTermMngCustList();
    }, []);

    const makeParam = (paramForList) => {
        if(paramForList.searchField===SearchKey.abandonDate || paramForList.searchField===SearchKey.openDate) {
            return makeYmdToRetrieve(paramForList.dateFrom, paramForList.searchField.indexOf("open")>=0);
        }
        else return paramForList;

    };

    const getTermMngListWithParam = (param) => {
        if(param) getTermMngList({variables:{termMngSearch:makeParam(param)}});
        else if(ValueUtil.realLen(paramForList.dateFrom)>=4 || !ValueUtil.isNullString(paramForList.search)) {
            const schParam = makeParam(paramForList);
            getTermMngList({variables: {termMngSearch:schParam}});
        }
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.termMngList) setTermMngRecords(data.termMngList);
        if(data.termMngCustList) setCustomerList(data.termMngCustList);
    };

    const onCompleteGetCustList = (data, clientOption) => {
        if(data.termMngCustList) setCustomerList(data.termMngCustList);
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.termMngAdd.ok) {
            Util.bubbleSnack("개통정보 저장 OK.");
            // setOpenNewEditor(false); - Don't close it.
            getTermMngListWithParam(); // setTermMngRecords(data.termMngAdd.list);
        }
        else setResponseAlert({open:true, resultData: data.termMngAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.termMngEdit.ok) {
            setEditorState(null);
            getTermMngListWithParam(); // setTermMngRecords(data.termMngEdit.list);
        }
        else setResponseAlert({open:true, resultData: data.termMngEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.termMngRemove.ok) getTermMngListWithParam(); // setTermMngRecords(data.termMngRemove.list);
        else setResponseAlert({open:true, resultData: data.termMngRemove, title: ErrorTitle.Remove});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.termMngItem) {
            //const item = {};
            //for(const field of EditFields) item[field] = data.termMngItem[field];
            setEditorState({item: {...data.termMngItem}});
        }
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setOpenNewEditor(true);
    };

    const onRequestEdit = (data) => {
        if(responseEdit) responseEdit.reset();
        getTermMngItemToEdit({variables: {termMngSeq: data.termMngSeq}});
        // setEditorState({item: data});
    };

    const onRequestRemove = (item) => {

        setPromptToConfirm({
            data: item,
            title: '개통정보 삭제',
            messages: [
                '선택한 개통정보를 삭제하시겠습니까?',
                '해당 개통정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{termMng:{termMngSeq: data.termMngSeq}}};
                    removeTermMng(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by TermMngInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {termMng: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editTermMng(param);
        else {
            if(termMngRecords.length===0) {
                // 저장 후 해당고객의 데이터 목록 가져오게 한다. 목록에 아무것도 없는 경우.
                const param = {...DefaultSearchParam};
                param.searchField = SearchKey.custName;
                param.search = item.custName;
                setParamForList(param);
            }
            addTermMng(param);
        }
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };

    // termId, serialNo, usimNum, openDate, abandonDate or custName selector
    const onChangeSearchField = (id, value) => {
        const search = {...inputForRetrieve};
        search.searchField = value;
        setInputForRetrieve(search);
    };

    // 서버 조회용 openDate, abandonDate (dateFrom) 변경 handler
    const onChangeDateInputToRetrieve = (event) => {
        const txt = event.target.value;
        if(txt) {
            if(/[^0-9]/.test(txt)) return;
            if(txt.length>8) return;
        }
        const search = {...inputForRetrieve};
        search[event.target.id] = txt;
        setInputForRetrieve(search);
    };

    // 서버조회용. termId, usimNum, serialNo handler
    const onChangeTextToRetrieve = (event) => {
        const search = {...inputForRetrieve};
        search[event.target.id] = event.target.value;
        setInputForRetrieve(search);
    };

    // 서버 조회용 업체명 변경 handler
    const onChangeCustInputToRetrieve = (id, newValue) => {
        const search = {...inputForRetrieve};
        search.usingCust = newValue || '';
        setInputForRetrieve(search);
    };

    const onClickRetrieveFromServer = () => {
        const param = {...DefaultSearchParam}; // InputTermMngSearch
        param.searchField = inputForRetrieve.searchField;

        if(inputForRetrieve.searchField===SearchKey.openDate) {
            param.dateFrom = inputForRetrieve.usingOpen; // getYmdFilterText(inputForRetrieve.usingOpen);
        }
        else if(inputForRetrieve.searchField===SearchKey.abandonDate) {
            param.dateFrom = inputForRetrieve.usingAbandon; // getYmdFilterText(inputForRetrieve.usingAbandon);
        }
        else if(inputForRetrieve.searchField===SearchKey.termId) param.search = inputForRetrieve.usingTerm;
        else if(inputForRetrieve.searchField===SearchKey.serialNo) param.search = inputForRetrieve.usingSerial;
        else if(inputForRetrieve.searchField===SearchKey.usimNum) param.search = inputForRetrieve.usingUsim;
        else if(!ValueUtil.isNullString(inputForRetrieve.usingCust)) {
            param.search = inputForRetrieve.usingCust;
        }
        else return;

        setParamForList(param);
        getTermMngListWithParam(param);
    };

    // For filtering in the FlexyTable ##############################
    const onChangeTextFilter = (event) => {
        setSearch(event.target.value);
    };

    const onChangeFilterDate = (event) => {
        const txt = event.target.value;
        const id = event.target.id;
        if(txt) {
            if(/[^0-9]/.test(txt)) return;
            if(txt.length>8) return;
        }

        if(id==="openDate") {
            setFilterOpenDate(txt);
        }
        else {
            setFilterAbandonDate(txt);
        }

        const ymdTxt = getYmdFilterText(txt);
        if(filterData[id] !== ymdTxt) {
            const data = {...filterData};
            data[id] = ymdTxt;
            setFilterData(data);
        }
    };
    
    const onChangeCodeFilter = (id, value) => {
        const data = {...filterData};
        data[id] = value;
        setFilterData(data);
    };

    const onClickClearFilter = () => {
        setFilterData({...DefaultFilter});
        setSearch('');
        setFilterAbandonDate('');
        setFilterOpenDate('');
    };

    if(ValueUtil.hasAnyAuthError(
        responseList, responseAdd, responseEdit, responseRemove, responseItemToEdit, resultListCust
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------

    const renderSelectorField = (dict, value, selectFrom) => {
        return(
            <FilterField>
                <CodeSelector
                    id={dict.id}
                    value={value || dict.default}
                    label={dict.label}
                    codes={selectFrom}
                    onChange={onChangeCodeFilter}
                />
            </FilterField>
        );
    };

    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            <TermMngEdit
                open={Boolean(editorState)}
                item={es.item || {}}
                customerList={customerList}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
            />
        );
    };

    const renderSearchInput = (id, value, label, callback) => {
        return(
            <TextField
                id={id} style={{width:250}}
                label={label}
                onChange={callback}
                value={value}
                size={AppWord.SMALL}
            />
        );
    };

    const renderSearchOrNewEditor = () => {
        if(openNewEditor) {
            return(
                <TermMngInput
                    customerList={customerList}
                    responseSaving={responseAdd}
                    onClickMutate={onClickMutate}
                    onClose={()=>setOpenNewEditor(false)}
                />
            );
        }
        else {
            // by (or) termId, serialNo, usingNum, openDate/Month/Year, abandonDate/Month/Year, custName
            return(
                <SearchConditionBox>
                    <SearchInputRow style={{marginTop:0, borderBottom:AppPalette.BorderCCC, paddingBottom:5}}>
                        <SearchGroup>
                            <CodeSelector
                                id="searchField"
                                value={inputForRetrieve.searchField|| SearchKey.custName}
                                label="조회조건"
                                codes={SearchFields}
                                onChange={onChangeSearchField}
                            />
                            &nbsp;
                            {
                                inputForRetrieve.searchField===SearchKey.termId
                                ? renderSearchInput("usingTerm", inputForRetrieve.usingTerm, InputMap.termId.label, onChangeTextToRetrieve)
                                :
                                inputForRetrieve.searchField===SearchKey.serialNo
                                ? renderSearchInput("usingSerial", inputForRetrieve.usingSerial, InputMap.serialNo.label, onChangeTextToRetrieve)
                                :
                                inputForRetrieve.searchField===SearchKey.usimNum
                                ? renderSearchInput("usingUsim", inputForRetrieve.usingUsim, InputMap.usimNum.label, onChangeTextToRetrieve)
                                :
                                inputForRetrieve.searchField===SearchKey.openDate
                                ? renderSearchInput("usingOpen", inputForRetrieve.usingOpen, "개통일자 yyyy[mm[dd]]", onChangeDateInputToRetrieve)
                                :
                                inputForRetrieve.searchField===SearchKey.abandonDate
                                ? renderSearchInput("usingAbandon", inputForRetrieve.usingAbandon, "해지일자 yyyy[mm[dd]]", onChangeDateInputToRetrieve)
                                :
                                <TextCombo
                                    id='usingCust'
                                    label={InputMap.custName.label}
                                    selectFrom={customerList}
                                    uniqueKey={'custName'}
                                    text={inputForRetrieve.usingCust}
                                    onChange={onChangeCustInputToRetrieve}
                                />

                                
                            }
                            &nbsp;
                            <Button variant={AppPalette.VariantContained}
                                disabled={
                                    ( inputForRetrieve.searchField===SearchKey.openDate && ValueUtil.realLen(inputForRetrieve.usingOpen) < 4 )
                                    || ( inputForRetrieve.searchField===SearchKey.abandonDate && ValueUtil.realLen(inputForRetrieve.usingAbandon) < 4 )
                                    || ( inputForRetrieve.searchField===SearchKey.termId && ValueUtil.realLen(inputForRetrieve.usingTerm) < 4 )
                                    || ( inputForRetrieve.searchField===SearchKey.serialNo && ValueUtil.realLen(inputForRetrieve.usingSerial) < 3 )
                                    || ( inputForRetrieve.searchField===SearchKey.usimNum && ValueUtil.realLen(inputForRetrieve.usingUsim) < 3 )
                                    || ( inputForRetrieve.searchField===SearchKey.custName && ValueUtil.isNullString(inputForRetrieve.usingCust) )
                                }
                                onClick={onClickRetrieveFromServer}
                            ><GetAppIcon /> 조회</Button>
                        </SearchGroup>
                        <Button
                            onClick={onRequestAdd}
                            variant={AppPalette.VariantContained} color={AppPalette.PrimaryColor}>
                            <AddIcon fontSize={AppWord.SMALL} />
                            개통정보 추가
                        </Button>
                    </SearchInputRow>
                    <SearchInputRow>
                        {renderSelectorField(InputMap.acNetworkCd, filterData.acNetworkCd, NetworkList) /* 통신사 */}
                        {renderSelectorField(InputMap.acTermStateCd, filterData.acTermStateCd, TermStateList) /* 개통상태 */}
                        {renderSelectorField(InputMap.acOpenForCd, filterData.acOpenForCd, OpenForList) /* 개통구분 */}
                        {renderSelectorField(InputMap.acFirmTypeCd, filterData.acFirmTypeCd, FirmTypeList) /* 법인구분 */}
                        {renderSelectorField(InputMap.acModelCd, filterData.acModelCd, ModelList) /* 모델 */}
                        {renderSelectorField(InputMap.acBillTypeCd, filterData.acBillTypeCd, BillTypeList) /* 요금제 */}
                        {renderSelectorField(InputMap.acContrMonthCd, filterData.acContrMonthCd, ContrMonthList) /* 약정개월 */}
                        {renderSelectorField(InputMap.acContrAmountCd, filterData.acContrAmountCd, ContrAmountList) /* 약정금액 */}
                    </SearchInputRow>
                    <SearchInputRow>
                        <SearchGroup>
                            <FormControl variant='outlined' size={AppWord.SMALL}>
                                <InputLabel htmlFor="search-TermMng-list" style={{backgroundColor:'white'}}>검색</InputLabel>
                                <OutlinedInput id="search-TermMng-list" onChange={onChangeTextFilter}
                                    value={search}
                                    disabled={termMngRecords.length === 0}
                                    size={AppWord.SMALL}
                                    endAdornment={
                                        Boolean(search) ?
                                        <InputAdornment position={AppWord.END}>
                                            <IconButton
                                            onClick={()=>{setSearch('')}}
                                            size={AppWord.SMALL}
                                            edge={AppWord.END}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        :
                                        null
                                    }
                                />
                            </FormControl>
                            &nbsp;
                            <TextField size={AppWord.SMALL}
                                id="openDate" value={filterOpenDate} label="개통일자 yyyy[mm[dd]]"
                                onChange={onChangeFilterDate}
                            />
                            &nbsp;
                            <TextField size={AppWord.SMALL}
                                id="abandonDate" value={filterAbandonDate} label="해지일자 yyyy[mm[dd]]"
                                onChange={onChangeFilterDate}
                            />
                            &nbsp;
                            <Button
                                variant={AppPalette.VariantContained}
                                onClick={onClickClearFilter}
                            >
                                <FilterAltOffIcon fontSize={AppWord.SMALL} /> 필터 초기화
                            </Button>
                        </SearchGroup>
                    </SearchInputRow>
                </SearchConditionBox>
            );
        }
    };

	const isAdmin = ValueUtil.isEtrace(sessionInfo);
    if(!isAdmin) return <Box>***</Box>

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <TermMngContainer>
            {renderSearchOrNewEditor()}
            <TableBox>
                <TermMngList
                    records={termMngRecords}
                    selected={termMngSelected}
                    onClickItem={setTermMngSelected}
                    onRequestAdd={onRequestAdd}
                    onRequestEdit={onRequestEdit}
                    onRequestRemove={onRequestRemove}
                    search={search}
                    filterData={filterData}
                />
            </TableBox>
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </TermMngContainer>
    )
}