// ViewRoutineDistribList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { useReactiveVar } from '@apollo/client';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import {userInfoRepo, NoUser} from '../../model/CvoModel';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimelineIcon from '@mui/icons-material/Timeline'; // route mode
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BusinessIcon from '@mui/icons-material/Business';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import FlagIcon from '@mui/icons-material/Flag';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import HelpTopic from '../common/HelpTopic';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppPalette } from '../../model/AppConst';
import InfoIconButton from '../common/InfoIconButton';
import VehRouteListMini from '../veh_route/VehRouteListMini';
import PopMenuIconButton from '../common/PopMenuIconButton';


const CellPadding = 5;
const ViewRoutineDistribListContainer = styled(Box)({ flexGrow:1, display:'flex', flexDirection:'column' });

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const SearchBox = styled(Box)({flexGrow:1});

const RoutineListBox = styled(Box)({
    flexGrow:1, overflow:'auto', border: AppPalette.BorderCCC, borderRadius: 3, // backgroundColor:'yellow'
});

//const TableBox = styled(Box)({display:'table', width:'100%', textAlign:'center'});
const TableRowBox = styled(Box)({display:'flex',margin: "5px 5px 0px 5px", paddingBottom:10}); // table-row
const CellVehicle = styled(Box)({ // 차량번호.
    width: 180, border:AppPalette.BorderCCC, borderRadius:10, padding: '5px 5px 0px 5px'
});
const CellMove = styled(Box)({
    textAlign:'center', padding: CellPadding
});
const CellDepart = styled(Box)({ // 출발지.
    width:180, padding: CellPadding, border:AppPalette.BorderCCC, borderRadius:10, textAlign:'center',
    '&:hover':{backgroundColor:'#cfe'}
});
const CellRoute = styled(Box)({ // 모든 도착지display:'table-cell' textAlign:'left',
    flexGrow:1, justifyContent:'left', position:'relative'
});
const RouteWrapper = styled(Box)({
    position:'absolute',top:0,left:0,right:0,bottom:0, textAlign:'left',
    overflowX:'auto', overflowY:'hidden', width:'100%',
    whiteSpace:'nowrap'
});
const VehicleWrapper = styled(Box)({textAlign:'center'});
const VehicleName = styled('span')({fontWeight:'bold', padding:5});
const DriverBox = styled(Box)({ fontSize:'0.8rem', color:'#555' }); // name and mobile in CellVehicle

const PoiWrapper = styled(Box)({display:'inline-block'});
const RoadMarkBox = styled(Box)({
    display:'inline-block', maxWidth:33, overflow:'hidden', whiteSpace:'nowrap', color:'#ccc'
});

const DestinWrapper = styled(Box)({ // 도착지 1개.
    display:'inline-block', padding: CellPadding, border:AppPalette.BorderCCC, borderRadius:10, height: '100%',
    '&:hover':{backgroundColor:'#9df'}
});
const DestinTime = styled('div')({fontSize:'0.8rem', color:'#555'});
const PoiAndTimes = styled(Box)({display:'inline-block', textAlign:'center'});
const PoiName = styled('span')({fontWeight:'bold', color:'#035', padding:5});
const AtaAndAtd = styled(Box)({fontSize:'0.8rem', color:'#333', textAlign:'center', padding:5});
const TimeLabel = styled(Box)({display:'inline-block', minWidth:40, padding:3, border:AppPalette.BorderCCC, borderRadius:3, margin:1});
const ButtonBox = styled('span')({paddingLeft:10});

const HelpCell = styled(Box)({display:'table-cell', padding:20, width:'100%'});
const HelpBox = styled(Box)({display:'flex', justifyContent:'center', textAlign:'left'});
const HelpRoad = styled(Box)({paddingTop:20, paddingLeft:10, color:'#77a'});

const RouteContainer = styled(Box)({ position:'relative' });
/*
const TableColumns = [
	{ id: 'custId', label: Dict.custId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'mapKind', label: Dict.mapKind.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'recId', label: Dict.recId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'vehPlates', label: Dict.vehPlates.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehId', label: Dict.vehId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'departOrDest', label: Dict.departOrDest.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userId', label: Dict.userId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'arrivalTime', label: Dict.arrivalTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'departTime', label: Dict.departTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'notiFrom', label: Dict.notiFrom.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'notiTill', label: Dict.notiTill.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'poiId', label: Dict.poiId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'groupId', label: Dict.groupId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'poiName', label: Dict.poiName.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'notiYn', label: Dict.notiYn.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'notiHourRange', label: Dict.notiHourRange.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'destinMemo', label: Dict.destinMemo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'countSmsSent', label: Dict.countSmsSent.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'intReserv2', label: Dict.intReserv2.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'regTime', label: Dict.regTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} }
]; */


function MdHm(ymdhms) {
    if(Boolean(ymdhms) && ymdhms.length >= 16) {
        return ymdhms.substring(5,7) + '/' + ymdhms.substring(8,10) + ' ' + ymdhms.substring(11,16);
    }
    return '';
}

function hhmm(ymdhms) {
    if(Boolean(ymdhms) && ymdhms.length >= 16) {
        return ymdhms.substring(11,16);
    }
    return '';
}

const Movement = {WAIT:'WAIT', GONE:'GONE', SEEN:'SEEN'}; // 차량과 지점의 상대적 위치.

export default function ViewRoutineDistribList({
    onRequestSet,
    onRequestEdit,
    onRequestRemove,
    onRequestGetLogForVeh,
    onClickPoi,
    records
}) {
    const [durationRoute, setDurationRoute] = useState(null);
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [search, setSearch] = useState('');
    const clientSize = useClientSize();

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    const filterRoutes = (route) => {
        if(Boolean(search)) {
            if(search.length > 0) {
                if(route.vehPlates.indexOf(search) >= 0) return true;
                if(Boolean(route.poiName) && route.poiName.indexOf(search) >= 0) return true;
                for(const dest of route.destins) {
                    if(Boolean(dest.poiName)) {
                        if(dest.poiName.indexOf(search) >= 0) return true;
                    }
                }
                return false;
            }
        }
        return true;
    };

    // 경로리스트 팝업.
    const onClickShowRoute = (route) => {
        const from = route.departTime || route.arrivalTime;
        let till = ValueUtil.getYmdHmsInputText();
        for(const dest of route.destins) {
            if(Boolean(dest.arrivalTime) || Boolean(dest.departTime)) {
                till = dest.arrivalTime || dest.departTime;
            }
        }
        setDurationRoute({vehId:route.vehId, vehPlates:route.vehPlates, from: from, till: till});
    };

    const onClickRoutePopMenu = (cmd, route) => {
        if(cmd===AppWord.CMD_ROUTE) onClickShowRoute(route);
        else if(cmd===AppWord.CMD_LOG) onRequestGetLogForVeh(route);
        if(isAdmin) {
            if(cmd===AppWord.CMD_REMOVE) onRequestRemove(route);
            if(cmd===AppWord.CMD_EDIT) onRequestEdit(route); // 로그 남기지 못하고 대체될 수 있음.
        }
    };

    const getPopMenuSet = (route) => {
        const menuSet = [];
        if(isAdmin) {
            menuSet.push({id:AppWord.CMD_EDIT, label:'수정', icon:<EditLocationIcon size={AppWord.SMALL}/>});
            menuSet.push({id:AppWord.CMD_REMOVE, label:'삭제', icon:<DeleteForeverIcon size={AppWord.SMALL}/>});
        }
        if(Boolean(route.departTime)) {
            menuSet.push({id:AppWord.CMD_ROUTE, label:'경로보기', icon:<TimelineIcon size={AppWord.SMALL}/>});
        }
        menuSet.push({id:AppWord.CMD_LOG, label:'차량 배송기록 보기', icon: <CalendarTodayIcon size={AppWord.SMALL}/>});
        return menuSet;
    }; // {id:AppWord.CMD_EDIT, label:"Click it", icon:<NoteAltIcon fontSize='small' />}

    const renderRouteDialog = () => {

        const dialogWidth = clientSize.dataAreaWidth > 1000 ? 1000 : clientSize.dataAreaWidth * 0.8;
        const maxHeight = clientSize.dataAreaHeight - 200;
        const maxWidth = clientSize.dataAreaWidth - 200;
        const vehPlates = Boolean(durationRoute) ? durationRoute.vehPlates : '';
        return(
            <Dialog open={Boolean(durationRoute)}
                onClose={()=>{setDurationRoute(null)}}
                maxWidth="lg"
            >
                <DialogTitle>
                    {vehPlates} 이동경로
                </DialogTitle>
                <DialogContent style={{minWidth:dialogWidth}}>
                    <RouteContainer height={maxHeight}>
                        <VehRouteListMini
                            vehPos={durationRoute}
                            durationGiven={durationRoute}
                            maxHeight={maxHeight}
                            maxWidth={maxWidth - AppNumber.dataContainerPadding}
                        />
                    </RouteContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setDurationRoute(null)}} variant='contained'>닫기</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderHelp = () => {
        return(
            <HelpCell>
                <HelpBox>
                    <HelpTopic
                        title="배송노선 설정이란"
                        content={
                            <ul>
                                <li>정기배송노선 설정은 차량이 출발지로부터 1개 이상의 목적지로 순행하는 상황을 모니터링하기 위한 설정입니다.</li>
                                <li>하나의 차량은 하나의 노선만 설정할 수 있습니다.</li>
                                <li>차량은 출발지에서 설정된 목적지들로 반복적으로 운행합니다.</li>
                                <li>출발지 출발 시 모든 목적지로 문자 알림을 전송합니다.</li>
                                <li>출발지 출발이나 목적지 도착 시 해당 시각을 기록하므로 후에 출발/도착 정보를 확인할 수 있습니다.</li>
                                <li>정기배송이 아닌 경우, 또는 하나의 차량이 여러 노선을 설정하는 경우에는 에는 "배송노선 및 알림 관리" 기능을 사용하는 것이 좋습니다.</li>
                                <li>실제 필요한 경우에만 사용하시면 됩니다.</li>
                            </ul>
                        }
                    />
                </HelpBox>
                    <HelpBox>
                        <BusinessIcon fontSize='large' color={AppPalette.PrimaryColor} style={{verticalAlign:'bottom'}}/>
                        <HelpRoad>========</HelpRoad>
                        <FlagCircleIcon fontSize='large' color={AppPalette.PrimaryColor} />
                        <HelpRoad>========</HelpRoad>
                        <FlagCircleIcon fontSize='large' color={AppPalette.PrimaryColor} />
                        <HelpRoad>.......</HelpRoad>
                        <LocalShippingIcon fontSize='large' color={AppPalette.SuccessColor} />
                        <HelpRoad>.......</HelpRoad>
                        <FlagIcon fontSize='large' color={AppPalette.PrimaryColor} />
                    </HelpBox>
            </HelpCell>
        );
    };

    /*
    route.vehInRec : 데이터 수신 후 만들어. 0이면 없고, 0보다 크면 해당 recId 앞에 차량이 있는 것임.
    */

    const NoTimeColor = '#eee';
    const NoTimeBox = <TimeLabel style={{backgroundColor:NoTimeColor, width:30}}>&nbsp;</TimeLabel>;

    const renderDestin = (dest, route, idx) => {
        return (
            <PoiWrapper key={dest.poiId}>
                <RoadMarkBox>{
                    route.vehInRec === dest.recId
                    ? <>-<LocalShippingIcon color={AppPalette.PrimaryColor} fontSize={AppWord.SMALL} />-</>
                    :
                    dest.movement === Movement.SEEN || dest.movement === Movement.GONE
                    ? <span style={{color:AppPalette.PrimaryRGB}}>--</span> : "--"
                    }
                </RoadMarkBox>
                <DestinWrapper>
                    <PoiAndTimes>
                        <PoiName>
                            <Link onClick={()=>{onClickPoi(dest.poiId)}} underline='hover' color={AppPalette.InheritColor}>
                                {dest.poiName}
                            </Link>
                        </PoiName>
                            <DestinTime>
                            {
                                dest.arrivalTime ? hhmm(dest.arrivalTime)
                                : <span>[&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]</span>
                            }
                            </DestinTime>
                    </PoiAndTimes>
                </DestinWrapper>
            </PoiWrapper>
        );
    };
    /*
                        {
                            dest.arrivalTime
                            ?
                            <InfoIconButton icon={<FlagCircleIcon fontSize={AppWord.SMALL}/>}
                                    text={'도착 '+hhmm(dest.arrivalTime)}
                                    />
                            : null
                        }

                        <AtaAndAtd>
                            {
                                dest.arrivalTime ? <TimeLabel>{hhmm(dest.arrivalTime)}</TimeLabel> : NoTimeBox
                            }
                            {
                                dest.departTime
                                ? <TimeLabel>{hhmm(dest.departTime)}</TimeLabel>
                                :
                                route.vehInRec === dest.recId
                                ? <TimeLabel style={{borderColor:AppPalette.PrimaryRGB, color:AppPalette.PrimaryRGB}}>출발대기</TimeLabel>
                                : NoTimeBox
                            }
                        </AtaAndAtd>
    */


    const renderRoutineDistribRecord = (route, idx) => {
        return(
            <TableRowBox key={idx}>
                {
                    isAdmin
                    ?
                    <CellMove>
                        <PopMenuIconButton
                                dataKey={route.vehId}
                                callbackData={route}
                                menus={getPopMenuSet(route)}
                                onClickMenu={onClickRoutePopMenu}
                            />
                    </CellMove>
                    : null
                }
                <CellVehicle>
                    <VehicleWrapper>
                        <VehicleName>{route.vehPlates}</VehicleName>
                        <DriverBox>
                            {route.driverName} {
                            route.driverMobile
                                ? <InfoIconButton icon={<PhoneIphoneIcon fontSize={AppWord.SMALL}/>}
                                    text={'휴대전화 '+route.driverMobile}
                                    />
                                : <IconButton disabled={true}><PhoneIphoneIcon color="disabled"/></IconButton>
                            }
                        </DriverBox>
                    </VehicleWrapper>
                </CellVehicle>
                <CellDepart>
                    <PoiAndTimes>
                        <PoiName>
                            <Link onClick={()=>{onClickPoi(route.poiId)}} underline='hover' color={AppPalette.InheritColor}>
                                {route.poiName}
                            </Link>
                        </PoiName>
                        <AtaAndAtd>
                            {route.arrivalTime ? <TimeLabel>{MdHm(route.arrivalTime)}</TimeLabel> : NoTimeBox}
                            {route.departTime  ? <TimeLabel>{MdHm(route.departTime )}</TimeLabel> : NoTimeBox}
                        </AtaAndAtd>
                    </PoiAndTimes>
                </CellDepart>
                <CellRoute>
                    <RouteWrapper>
                    {
                        route.destins.map((dest, idx)=>{
                            return renderDestin(dest, route, idx)
                        })
                    }
                    </RouteWrapper>
                </CellRoute>
            </TableRowBox>
        );
    };


    return(
        <ViewRoutineDistribListContainer>
            <HeaderBox>
                <SearchBox>
                    <FormControl variant='outlined' size='small'>
                        <InputLabel htmlFor="search-ViewRoutineDistrib-list" style={{backgroundColor:'white'}}>검색</InputLabel>
                        <OutlinedInput id="search-ViewRoutineDistrib-list" onChange={onChangeSearch}
                            value={search}
                            disabled={records.length === 0}
                            size='small'
                            endAdornment={
                                Boolean(search) ?
                                <InputAdornment position={AppWord.END}>
                                    <IconButton
                                    onClick={()=>{setSearch('')}}
                                    size={AppWord.SMALL}
                                    edge={AppWord.END}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                                :
                                null
                            }
                        />
                    </FormControl>
                </SearchBox>
				{
					isAdmin
					?
					<ButtonBox>
						<Button
							onClick={onRequestSet}
							variant='contained' color='primary'>
							<AddIcon fontSize='small' />
							정기배송노선 추가
						</Button>
					</ButtonBox>
					: null
				}
            </HeaderBox>
            <RoutineListBox>
                {
                        records.length === 0
                        ?
                        <TableRowBox>
                            {renderHelp()}
                        </TableRowBox>
                        :
                        records.filter(filterRoutes).map((route, idx) => {
                            return renderRoutineDistribRecord(route, idx);
                        })

                }
            </RoutineListBox>
            {renderRouteDialog()}
        </ViewRoutineDistribListContainer>
    );

}