// map/PopRoutePointInfo.js
import React from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import { AppPalette } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { userInfoRepo } from '../../model/CvoModel';
import ValueUtil from '../../model/ValueUtil';

const InfoTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const IconCircle = styled(Box)({
    display: 'inline-flex', backgroundColor:'white', color:AppPalette.HeaderBackground, marginRight: 10,
    borderRadius:40, height: 40, width:40, alignItems:'center', justifyContent:'center'
});

const InfoRow = styled(Box)({
    height:32, display:'flex', alignItems:'center',
});

const InfoLabel = styled('div')({
    width: 200, paddingLeft: 15
});

const Info = styled('div')({
    paddingLeft:15, flexGrow: 1
});

export default function PopRoutePointInfo({
    open,
    vehPosData,
    onClickClose
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
	const isStaff = sessionInfo.signedIn ? ValueUtil.isEtrace(sessionInfo) : false;

    const renderInfo = (label, text) => {
        return(
            <InfoRow>
                <InfoLabel>{label}</InfoLabel>
                <Info>
                    {text}
                </Info>
            </InfoRow>
        );
    };

    const data = open ? vehPosData : {};

    return (
        <Dialog open={open} maxWidth="md" onClose={onClickClose}>
            <InfoTitle>
                <IconCircle>
                    <NearMeIcon color={AppPalette.InheritColor} />
                </IconCircle>
                {data.vehPlates}
                {
                    isStaff
                    ? ' (veh_id=' + data.vehId + ', ' + data.custId + ')'
                    : null
                }
            </InfoTitle>
            <DialogContent style={{paddingTop:12}}>
                {renderInfo('차량', data.vehPlates + ' (' + data.vehAlias + ')')}
                {renderInfo('속도', '시속 '+ data.speed + ' 킬로미터')}
                {renderInfo('위치', data.location)}
                {renderInfo('위치타입', data.posSysValue)}
                {renderInfo('보고시각', data.gpsTime)}
                {renderInfo('누적거리', data.distKm + ' 킬로미터')}
                {data.roadName ? renderInfo('도로', data.roadName) : null}
                {data.roadName ? renderInfo('허용속도', data.roadMaxSpeed + ' 킬로미터') : null}
                {
                    data.tempr1 || data.tempr3 || data.tempr2 ?
                    <hr/> : null
                }
                {data.tempr1 ? renderInfo('전면온도', data.tempr1) : null}
                {data.tempr3 ? renderInfo('중면온도', data.tempr3) : null}
                {data.tempr2 ? renderInfo('후면온도', data.tempr2) : null}
                {
                    data.timeArrival || data.staySec ?
                    <hr/> : null
                }
                {data.timeArrival ? renderInfo('현위치 도착', data.timeArrival) : null}
                {data.staySec ? renderInfo('현위치 정차', ValueUtil.formatDuration(data.staySec)) : null}
                {data.timeDeparture ? renderInfo('현위치 출발', data.timeDeparture) : null}

            </DialogContent>
            <DialogActions>
                <Button
                    color={AppPalette.PrimaryColor}
                    onClick={onClickClose}
                    variant={AppPalette.VariantContained}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    )
}
