//

import React, { useState } from "react";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import { Button, Popover, InputLabel } from "@mui/material";

const TimeBoxHeight = 200;

const TimePickerButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    padding: 0,
    minHeight: 0,
    "&:hover": {
        backgroundColor: "transparent",
    },
}));
/*
const TimePickerLabel = styled("div")(({ theme }) => ({
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));
*/
const TimePickerLabel = styled(InputLabel)({
    marginTop: -8,
    backgroundColor:'white'
});

const TimePickerContainer = styled("div")(({ theme }) => ({
    display: "inline-block",
    textAlign: "center",
    border: '1px solid #cccccc',
    borderRadius: 5,
    padding: '0px 5px 12px 5px',
    marginBottom: 5
}));

const TimePickerValue = styled("span")(({ theme }) => ({
    display: 'inline',
}));

const TimeSelectContainer = styled(Box)({
    display: 'table',
    width: 220,
});

const TimeSelectRow = styled(Box)({
    display: 'table-row',
});

const TimeColumn = styled(Box)({
    display: 'table-cell',
    padding: 10,
});

const HourMinBox = styled(Box)({
    height: TimeBoxHeight,
    overflow: 'auto',
    borderRadius: 5,
    border: '1px solid #cccccc',
});

export default function Time24Picker({
    id,
    value,
    label,
    onChange,
    disabled,
    disableMinute,
    style
}) {
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const onClosePop = () => {
        setAnchorEl(null);
    };
  
    const handleHourClick = (hour) => {
        onChange(id, `${hour}:${value.split(":")[1]}:00`);
    };
  
    const handleMinuteClick = (minute) => {
        onChange(id, `${value.split(":")[0]}:${minute}:00`);
    };

    const hms = value.split(":");
    const hhNow = hms[0];
    const mmNow = hms[1];
    return (
        <>
            <TimePickerContainer style={style}>
                <TimePickerLabel shrink>{label}</TimePickerLabel>
                <TimePickerButton onClick={handleClick} disabled={disabled} size="small">
                    <TimePickerValue>{hhNow}</TimePickerValue><span>
                        :
                    </span><TimePickerValue>{mmNow}</TimePickerValue>
                </TimePickerButton>
            </TimePickerContainer>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={onClosePop}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <TimeSelectContainer>
                    <TimeSelectRow>
                        <TimeColumn>
                            <TimePickerLabel>시</TimePickerLabel>
                            <HourMinBox>
                            {[...Array(24).keys()].map((hour) => {
                                const hh = hour.toString().padStart(2, "0");
                                return(
                                    <Box key={hour}>
                                        {
                                            hhNow === hh
                                            ?
                                            <Button variant="contained" size="small">{hh}</Button>
                                            :
                                            <Button onClick={() => handleHourClick(hh)} size="small">{hh}</Button>
                                        }
                                    </Box>
                                )
                            })}
                            </HourMinBox>

                        </TimeColumn>
                        <TimeColumn>
                            <TimePickerLabel>분</TimePickerLabel>
                            <HourMinBox>
                            {[...Array(60).keys()].map((minute) => {
                                const mm = minute.toString().padStart(2, "0");
                                return (
                                    <Box key={minute}>
                                        {
                                            mmNow === mm
                                            ?
                                            <Button variant="contained" color="primary" size="small">{mm}</Button>
                                            :
                                            <Button onClick={() => handleMinuteClick(mm)} size="small"
                                                disabled={disableMinute}
                                            >
                                                {mm}
                                            </Button>
                                        }
                                    </Box>
                                )
                            })}
                            </HourMinBox>
                            
                        </TimeColumn>
                    </TimeSelectRow>
                </TimeSelectContainer>
                <Box style={{textAlign:'center'}}>
                    <Button size="small" onClick={onClosePop}>닫기</Button>
                </Box>
            </Popover>
        </>
    );

}

/*
Please make a 24-hour format time picker react component using MUI5.
Value format is "HH:mm:ss".
Don't use TextField but Popover and Botton components.
Label is shown above the time in small case as usual MUI components do.
Display time without seconds part in 24 hour format.
Clicking on the time will trigger a Popover to print buttons displaying
each 0 to 23 o'clock
and 0 to 59 minute in orgarnized Boxes.
Call setter whenever click on hour or minute buttons.


import React, { useState } from "react";
import {
  Box,
  Button,
  Popover,
  Typography,
  makeStyles,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.body2.fontSize,
    textTransform: "capitalize",
    marginBottom: theme.spacing(1),
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(0.5),
    minWidth: 0,
    padding: theme.spacing(1),
    borderRadius: "50%",
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

function TimePicker({ value, setValue, label }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHourClick = (hour) => {
    const [h, m] = value.split(":");
    setValue(`${hour}:${m}`);
    handleClose();
  };

  const handleMinuteClick = (minute) => {
    const [h, m] = value.split(":");
    setValue(`${h}:${minute}`);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography variant="body2" className={classes.label}>
        {label}
      </Typography>
      <Button onClick={handleClick} sx={{ minWidth: 0 }}>
        {value.slice(0, 5)}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className={classes.buttonGroup}>
          {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
            <Button
              key={hour}
              className={classes.button}
              onClick={() => handleHourClick(hour.toString().padStart(2, "0"))}
            >
              {hour.toString().padStart(2, "0")}
            </Button>
          ))}
        </Box>
        <Box className={classes.buttonGroup}>
          {Array.from({ length: 60 }, (_, i) => i).map((minute) => (
            <Button
              key={minute}
              className={classes.button}
              onClick={() =>
                handleMinuteClick(minute.toString().padStart(2, "0"))
              }
            >
              {minute.toString().padStart(2, "0")}
            </Button>
          ))}
        </Box>
      </Popover>
    </>
  );
}

export default TimePicker;


// Use styled instead of makeStyles.


import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";

const TimePickerButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  padding: 0,
  minHeight: 0,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const TimePickerLabel = styled("div")(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const TimePickerContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const TimePickerValue = styled("div")(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 500,
  marginRight: theme.spacing(1),
}));

const TimePicker = ({ label, value, setValue }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHourClick = (hour) => {
    setValue(`${hour}:${value.split(":")[1]}`);
    handleClose();
  };

  const handleMinuteClick = (minute) => {
    setValue(`${value.split(":")[0]}:${minute}`);
    handleClose();
  };

  return (
    <>
      <TimePickerLabel>{label}</TimePickerLabel>
      <TimePickerContainer>
        <TimePickerValue>{value.split(":")[0]}</TimePickerValue>
        <TimePickerButton onClick={handleClick}>
          <TimePickerValue>{value.split(":")[1]}</TimePickerValue>
        </TimePickerButton>
      </TimePickerContainer>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1}>
          <TimePickerLabel>Select Hour</TimePickerLabel>
          <Box display="flex" flexWrap="wrap">
            {[...Array(24).keys()].map((hour) => (
              <Box key={hour} p={0.5}>
                <Button onClick={() => handleHourClick(hour)}>
                  {hour.toString().padStart(2, "0")}
                </Button>
              </Box>
            ))}
          </Box>
          <TimePickerLabel>Select Minute</TimePickerLabel>
          <Box display="flex" flexWrap="wrap">
            {[...Array(60).keys()].map((minute) => (
              <Box key={minute} p={0.5}>
                <Button onClick={() => handleMinuteClick(minute)}>
                  {minute.toString().padStart(2, "0")}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

TimePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default TimePicker;


*/
