// RouteHourlyListOff.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, Button, FormControlLabel, Checkbox } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FlexyTable from '../common/flexytable/FlexyTable';
import ResponseAlert from '../message/ResponseAlert';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType } from '../../model/AppConst';
import { useLazyQuery } from '@apollo/client';
import { LIST_ROUTE_HOURLY_OFF } from './RouteHourlyGql';

const dcPad = AppNumber.dataContainerPadding;

const RouteHourlyListContainer = styled(Box)({
    position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection:'column'
	//flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    display:'flex', alignItems:'center'
});

const KeyTotalDist = 'totalDist';
const KeyDistNotAllowed = 'distNotAllowed';
const LabelTotalDist = '총 주행거리';
const LabelDistNotAllowed = '휴무일 주행거리';
const TableColumns = [
	{ id: 'vehPlates', label: "차량번호", width: 110, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehAlias', label: '지도표시명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'routeDate', label: '날짜', width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
	{ id: 'driverName', label: '기사', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'driverMobile', label: '휴대전화', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehTypeTon', label: '차종-톤수', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'totalDist', label: LabelTotalDist, width: 150, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'distNotAllowed', label: '업무시간 외 주행거리', width: 150, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
	{ id: 'driveCanStart', label: '운행허용시작시각', width: 120, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'driveMustEnd', label: '운행종료의무시각', width: 120, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    //{ id: 'avgSpeed', label: Dict.avgSpeed.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    //{ id: 'avgSpeedRun', label: '평균속도', width: 120, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    //{ id: 'avgCycleMake', label: Dict.avgCycleMake.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    //{ id: 'positionCount', label: Dict.positionCount.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
];

export default function RouteHourlyListOff({
	maxHeight,
	maxWidth,
}) {
    const [date, setDate] = useState(ValueUtil.getYmdText(3600*18));
    const [dayOff, setDayOff] = useState(false);
    const [records, setRecords] = useState([]);
    const [search, setSearch] = useState('');
    const [columns, setColumns] = useState(TableColumns.slice());
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getRouteHourlyListOff, responseList] = useLazyQuery(LIST_ROUTE_HOURLY_OFF, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetRouteHourlyList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "시간대별 운행정보 조회 오류"})}
    });

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetRouteHourlyList = (data, clientOption) => {
        if(data.routeHourlyListOff) {
            setRecords(data.routeHourlyListOff.slice());
            //setRecords(dailySected);
        }
    };

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onChangeDate = (event) => {
        setDate(event.target.value);
    };

    const onClickGetList = () => {
        getRouteHourlyListOff({variables:{date:date}});
    };

    const onChangeDayOff = (event) => {
        const newColumns = [];
        for(const col of columns) {
            if(col.id===KeyTotalDist) {
                col.label = event.target.checked ? LabelDistNotAllowed : LabelTotalDist;
            }
            else if(col.id===KeyDistNotAllowed) {
                col.show = !event.target.checked;
            }
            newColumns.push(col);
        }
        setColumns(newColumns);
        setDayOff(event.target.checked);
    };

    return(
        <RouteHourlyListContainer>
            <HeaderBox>
                <span>주행날짜 &nbsp;</span>
                <TextField type='date' value={date} size="small" onChange={onChangeDate} />
                &nbsp;
                <FormControlLabel label="휴무일로 간주"
                    control={
                        <Checkbox value={dayOff} onChange={onChangeDayOff} />
                    }
                />
                &nbsp;
                <Button size={AppWord.SMALL}
                    onClick={onClickGetList}
                    variant='contained' color="primary"
                    disabled={!Boolean(date)}
                >
                    조회
                </Button>
                &nbsp;&nbsp;
                <FormControl variant='outlined' size='small'>
                    <InputLabel htmlFor="search-RouteHourly-list" style={{backgroundColor:'white'}}>검색</InputLabel>
                    <OutlinedInput id="search-RouteHourly-list" onChange={onChangeSearch}
                        value={search}
                        disabled={records.length === 0}
                        size='small'
                        endAdornment={
                            Boolean(search) ?
                            <InputAdornment position={AppWord.END}>
                                <IconButton
                                onClick={()=>{setSearch('')}}
                                size={AppWord.SMALL}
                                edge={AppWord.END}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                            :
                            null
                        }
                    />
                </FormControl>
            </HeaderBox>
            <FlexyTable
                name="route_hourly_list_off"
                uniqueKey="vehId"
                columns={columns}
                records={records}
                search={search}
                tools={[]}
				selected={null}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </RouteHourlyListContainer>
    );

}
