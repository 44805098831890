// AppMain.js
import React, {useEffect, useState, useRef} from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Grow, IconButton } from '@mui/material';
import {useReactiveVar, useLazyQuery} from '@apollo/client';
import ClearIcon from '@mui/icons-material/Clear';
import AlertDialog from './com/message/AlertDialog';
import AppHeader from './com/header/AppHeader';
import SessionBox from './com/session/SessionBox';
import CvoLogin from './com/session/CvoLogin';
import { AppNumber, AppWord, AppObject, UserClass, MapValue } from './model/AppConst';
import { userInfoRepo, currentMenuRepo, appAlertRepo, menuTreeRepo, codeTreeRepo, tableShapeConfRepo } from './model/CvoModel';
import useClientSize from './com/hook/useClientSize';
import { useAddActLog } from './com/hook/appHooks';
//import { MENU_TREE } from './com/menu/MenuGql';
import { GET_CODE_N_MENU } from './com/session/SessionGql';
import MenuBox from './com/menu/MenuBox';
// for menus
import ActLog from './com/act_log/ActLog';
import AddressInfo from './com/address_info/AddressInfo';
import ArchiveDownload from './com/archive/ArchiveDownload';
import BcdIoData from './com/bcd_io_data/BcdIoData';
import BcdNoticeMore from './com/bcd_notice_more/BcdNoticeMore';
import CustAgree from './com/cust_agree/CustAgree';
import CustGetAlarm from './com/cust_get_alarm/CustGetAlarm';
import CustInfo from './com/cust_info/CustInfo';
import CvoFile from './com/cvo_file/CvoFile';
import DownloadInfo from './com/download_info/DownloadInfo';
import NotifyBoard from './com/notify_board/NotifyBoard';
import RegulationReport from './com/reg_report/RegulationReport';
import RouteDailyBill from './com/route_daily_bill/RouteDailyBill';
import RouteDailyList from './com/route_daily/RouteDailyList';
import RouteHourlyList from './com/route_hourly/RouteHourlyList';
import RouteHourlyListOff from './com/route_hourly/RouteHourlyListOff';
import RouteMonthly from './com/route_monthly/RouteMonthly';
import TermChangeLog from './com/term_change_log/TermChangeLog';
import TermInfoAdmin from './com/term_info/TermInfoAdmin';
import TermInfo from './com/term_info/TermInfo';
import TermMng from './com/term_mng/TermMng';
import TermMngLog from './com/term_mng_log/TermMngLog';
import TermsStatAll from './com/terms_stat/TermsStatAll';
import TermStock from './com/term_stock/TermStock';
import TermStockAll from './com/term_stock/TermStockAll';
import UserInfo from './com/user_info/UserInfo';
import VehConfList from './com/veh_conf/VehConfList';
import VehGroup from './com/veh_group/VehGroup';
import VehGroupBcdMap from './com/veh_group_bcd_map/VehGroupBcdMap';
import VehInfo from './com/veh_info/VehInfo';
import VehInfoLog from './com/veh_info_log/VehInfoLog';
import VehPos from './com/veh_pos/VehPos';
import VehTempr from './com/veh_pos/VehTempr';
import VehTypeTon from './com/veh_type_ton/VehTypeTon';
import ViewCruise from './com/view_cruise/ViewCruise';
import ViewRoutineDistrib from './com/view_routine_distrib/ViewRoutineDistrib';
import MyInfo from './com/user_info/MyInfo';
import NotImplemented from './com/common/NotImplemented';
import ValueUtil from './model/ValueUtil';

const TabBgColor = '#dddddd';

const MenuContainer = styled(Box)({
    position: "fixed",
    display:"inline-block",
    left: AppNumber.MenuLeft,
    top: AppNumber.HeaderHeight - 30,
    minHeight:100,
    zIndex: 999999999
});

const ContentContainer = styled(Box)({
    // marginTop: AppNumber.HeaderHeight,
    overflow: "auto", // padding: AppNumber.dataContainerPadding,
    position:'relative', // backgroundColor:'gray'
});

const ViewTabBox = styled(Box)({
    marginTop: AppNumber.HeaderHeight,
    height: AppNumber.ViewTabHeight,
    backgroundColor: TabBgColor,
    //display: 'flex',
    //alignItems: 'center'
});

const ViewTab = styled(Box)({
    // display:'inline-block',
    height: AppNumber.ViewTabHeight - 1,
    paddingLeft: 10, paddingRight: 10, // marginRight: 10,
    borderRight: '2px solid white',
    borderBottom: TabBgColor,
    //borderTopRightRadius: 3,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': {color: 'red'}
});

const TabLabel = styled('div')({
    paddingTop: 1,
});

//  background: 'linear-gradient(to right, #aaaaaa, #eeeeee, #aaaaaa)',

// Table (Data grid) : https://github.com/gregnb/mui-datatables

export default function AppMain() {
    const containerRef = useRef(null);
    const [isStaff, setIsStaff] = useState(false);
    const [getOnLogin, {loading, error, data: codeAndMenuData}] = useLazyQuery(GET_CODE_N_MENU, AppObject.NoCachedFetch);
    // const [getMenuTree, {loading:loadingMenu, error:errorMenu, data:dataMenu}]        = useLazyQuery(MENU_TREE, AppObject.NoCachedFetch);
    const [menuVisible, showMenuVisible] = useState(false);
    const [menuNow, setMenuNow] = useState(AppObject.DefaultMenu);
    const [cvoViews, setCvoViews] = useState([]);
    const clientSize = useClientSize();
    const sessionInfo = useReactiveVar(userInfoRepo);
    const appAlerts = useReactiveVar(appAlertRepo);
    const addActLog = useAddActLog();
    const mainLogAdded = useRef(false);
    // const currentMenu = useReactiveVar(currentMenuRepo);

    const getViewFromMenu = (menuNow) => {
        const menuId = menuNow.menuId;
        if(menuId==='address_info')     return <AddressInfo />;
        if(menuId==='veh_pos_zip_list') return <ArchiveDownload />;
        if(menuId==='bcd_io_data')      return <BcdIoData />;
        if(menuId==='bcd_more_noti')    return <BcdNoticeMore />;
        if(menuId==='cust_agree')       return <CustAgree />;
        if(menuId==='cust_get_alarm')   return <CustGetAlarm />;
        if(menuId==='cvo_file')         return <CvoFile />;
        if(menuId==='download_info_list')   return <DownloadInfo />;
        if(menuId==='notice_board')     return <NotifyBoard />;
        if(menuId==='pos_download_log') return <RegulationReport />;
        if(menuId==='route_daily_bill') return <RouteDailyBill />;
        if(menuId==='route_daily')      return <RouteDailyList />;
        if(menuId==='route_hourly')     return <RouteHourlyList />;
        if(menuId==='route_off')        return <RouteHourlyListOff />;
        if(menuId==='route_monthly')    return <RouteMonthly />; // <RouteMonthlyList />;
        if(menuId===AppWord.MENU_TERM_CH_LOG
            || menuId===AppWord.MENU_TERM_STT_LOG)  return <TermChangeLog />;
        if(menuId==='term_info')        return <TermInfo />;
        if(menuId==='user_info')        return <UserInfo />;
        if(menuId==='limit_config')     return <VehConfList />;
        if(menuId==='veh_group')        return <VehGroup />;
        if(menuId==='bcd_group_border') return <VehGroupBcdMap />;
        if(menuId==='veh_info')         return <VehInfo />;
        if(menuId==='veh_info_log')     return <VehInfoLog />;
        if(menuId===AppWord.MENU_VEH_POS)          return <VehPos />;
        if(menuId==="temp_and_humid")   return <VehTempr />;
        if(menuId==='veh_type_tree')    return <VehTypeTon />;
        if(menuId===AppWord.MENU_CRUISE_SCHED)      return <ViewCruise />;
        if(menuId===AppWord.MENU_ROUTINE_ROUTE)     return <ViewRoutineDistrib />;

        if(menuId===AppObject.EditMyInfoMenu.menuId) return <MyInfo />;

        // for Admin
        if(menuId==='act_log')          return <ActLog />;
        if(menuId===AppWord.MENU_CUST_INFO) return <CustInfo />;
        if(menuId==='term_info_admin')  return <TermInfoAdmin />;
        if(menuId==='term_mng')         return <TermMng />;
        if(menuId==='term_mng_log')     return <TermMngLog />;
        if(menuId==='term_stock')       return <TermStock />;
        if(menuId==='term_stock_all')   return <TermStockAll />;
        if(menuId==='terms_stat_all')   return <TermsStatAll />;

        // return <VehPos />;
        return <NotImplemented />;
    };

    useEffect(() => {
        if(sessionInfo.signedIn) {
            sessionInfo.ifLocal(()=>console.log("I can log whenever I want!!")); // -- 오류.
            getOnLogin(); // 코드 등을 가져오기.

            // ################################## 여기부터 작업 ############################
            if(sessionInfo.user.classNo <= UserClass.EtraceStaff.classNo) {
                // admin메뉴
                setCvoViews([{
                    when: new Date().getTime(),
                    menu: AppObject.DefaultAdminMenu,
                    view: getViewFromMenu(AppObject.DefaultAdminMenu)
                }]);
                currentMenuRepo(AppObject.DefaultAdminMenu);
                setMenuNow(AppObject.DefaultAdminMenu);
                setIsStaff(true);
            }
            else {
                // normal user.
                // 기본메뉴로 시작(위치, 경로...)
                setCvoViews([{
                    when: new Date().getTime(),
                    menu: AppObject.DefaultMenu,
                    view: getViewFromMenu(AppObject.DefaultMenu)
                }]);
                currentMenuRepo(AppObject.DefaultMenu);
                setMenuNow(AppObject.DefaultMenu);
                setIsStaff(false);
            }
            showMenuVisible(false);
        }
    }, [sessionInfo, getOnLogin]);

    useEffect(()=>{
        if(sessionInfo.signedIn && !mainLogAdded.current) {
            let actText;
            if(sessionInfo.user.classNo <= UserClass.EtraceStaff.classNo) actText = AppObject.DefaultAdminMenu.menuName;
            else actText = AppObject.DefaultMenu.menuName;
            addActLog('AppMain', 'start', '관제서비스 이용 시작: ' + actText);
            mainLogAdded.current = true;
        }
    }, [addActLog, sessionInfo])

    useEffect(() => {
        if(codeAndMenuData && codeAndMenuData.menuToUserTree) {
            if(codeAndMenuData.menuToUserTree) menuTreeRepo(codeAndMenuData.menuToUserTree);
            if(codeAndMenuData.codeTree) codeTreeRepo(codeAndMenuData.codeTree);
            if(codeAndMenuData.userTcolConf21List) {
                if(!sessionInfo.isDisguised())
                    tableShapeConfRepo(ValueUtil.reformTableColumnListToObject(codeAndMenuData.userTcolConf21List));
            }
        }
    }, [codeAndMenuData, sessionInfo]);

    const showMenu = (bool) => {showMenuVisible(bool)};
    const onClickMenuInHeader = () => {showMenuVisible(!menuVisible)};
    const onMouseLeaveMenuBox = () => {showMenuVisible(false)};

    const popMapWindow = () => {
        const width = window.screen.width - 50; // > 2000 ? 2000 : window.screen.width - 50;
        const height = window.screen.height - 70; // > 2000 ? 2000 : window.screen.height - 50;
        const url = ValueUtil.getUrlBase() + "#view=map";
        window.open(url, '_blank', MapValue.PopupOption + `,width=${width},height=${height}`);
    };

    const onMenuSelected = (menu) => {
        if(menu.menuId==='map') {
            popMapWindow();
            showMenuVisible(false);
            addActLog('MapMain', 'pop', menu.menuName);
            return;
        }
        const views = [...cvoViews];
        if(!isViewOpened(menu)) {
            const container = containerRef.current;
            const children = container.children;
            const childrenWidth = Array.from(children).reduce(
                (totalWidth, child) => totalWidth + child.offsetWidth + 5, 30 + 250 // 30 for icon button, 250 for next one.
            );

            // if(views.length > 10) {
            if(childrenWidth > container.offsetWidth) {
                const idx = findForgottenView();
                if(idx >= 0) views.splice(idx, 1);
            }
            views.unshift({when: new Date().getTime(), menu: menu, view: getViewFromMenu(menu)});
        }
        setCvoViews(views);
        currentMenuRepo(menu);
        showMenuVisible(false);
        setMenuNow(menu);
        addActLog('AppMain', 'menu-selected', menu.menuName);
    };

    const isViewOpened = (menu) => {
        for(const cv of cvoViews) {
            if(menu.menuId === cv.menu.menuId) return true;
        }
        return false;
    };

    const removeViewAt = (idx) => {
        if(cvoViews.length < 2) return;
        const views = [...cvoViews];
        views.splice(idx, 1);
        currentMenuRepo(views[0].menu);
        setMenuNow(views[0].menu);
        setCvoViews(views);
    };

    const onClickRemoveView = (idx, event) => {
        removeViewAt(idx);
        event.preventDefault();
        event.stopPropagation();
    };

    const findForgottenView = () => {
        if(cvoViews.length < 2) return -1;
        const series = cvoViews.map((view, idx) => {
            return {index:idx, when: view.when};
        }).sort((a, b) => {return a.when - b.when});
        return series[0].index;
    };

    const onTabSelected = (view) => {
        view.when = new Date().getTime();
        currentMenuRepo(view.menu);
        setMenuNow(view.menu);
    };

    const onRequestEditMyData = () => {
        onMenuSelected(AppObject.EditMyInfoMenu);
    };

    const renderMenuContainer = () => {
        return(
            <Grow in={menuVisible}>
            <MenuContainer onMouseLeave={onMouseLeaveMenuBox}>
                <MenuBox itemMode={true} onMenuSelected={onMenuSelected}/>
            </MenuContainer>
            </Grow>
        );
    };

    if(sessionInfo.signedIn) {
        return (
            <Box style={{fontSize:'11pt', position:'relative'}}>
                <AppHeader showMenu={showMenu}
                    onClickMenuInHeader={onClickMenuInHeader} />
                <SessionBox onClickEdit={onRequestEditMyData} />
                <ViewTabBox ref={containerRef}>
                    {
                        cvoViews.map((view, idx) => {
                            const menu = view.menu;
                            return(
                                <ViewTab key={menu.menuId}
                                    style={menu.menuId === menuNow.menuId ? {backgroundColor:'white', paddingRight:0} : null}
                                    onClick={() => {onTabSelected(view)}}
                                >
                                    <TabLabel>{menu.menuName}</TabLabel>
                                {
                                    menu.menuId !== menuNow.menuId
                                    ? null
                                    :
                                    cvoViews.length > 1
                                    ?
                                    <IconButton size='small' onClick={(e)=>{onClickRemoveView(idx, e)}}
                                        style={{backgroundColor:'white', transform: 'scale(0.8)', float:'right'}}
                                        color='primary'
                                    >
                                        <ClearIcon fontSize='small' />
                                    </IconButton>
                                    : <span>&nbsp;</span>
                                }
                                </ViewTab>
                            );
                        })
                    }
                </ViewTabBox>
                {
                    cvoViews.map((view) => {
                        const disp = view.menu.menuId === menuNow.menuId ? 'block' : 'none';
                        return(
                            <ContentContainer
                                key={view.menu.menuId}
                                height={clientSize.dataAreaHeight}
                                style={{display: disp}}
                            >
                                {view.view}
                            </ContentContainer>)
                    })
                }
                {
                    appAlerts.length > 0
                    ?
                    <AlertDialog open={appAlerts.length > 0}
                        data={appAlerts[0]}
                        onClose={()=>{
                            appAlertRepo(appAlerts.slice(1));
                        }}/>
                    :
                    null
                }
                {renderMenuContainer()}
            </Box>
        );
    }
    else return(<CvoLogin/>);
}


/*

{
  "data": {
    "menuToUserTree": [
      {
        "kindId": "cvo",
        "kindName": "차량관제",
        "menus": [
          {
            "menuId": "veh_pos", # ------------- O.K.
            "menuName": "위치, 경로, 동정정보",
            "menuOrder": 10
          },
          {
            "menuId": "route_daily", # ------------- O.K.
            "menuName": "차량누적거리통계",
            "menuOrder": 20
          },
          {
            "menuId": "route_off", # ------------- O.K.
            "menuName": "업무시간 외 운행기록",
            "menuOrder": 30
          },
          {
            "menuId": "route_hourly", # ------------- O.K.
            "menuName": "시간대별 운행거리",
            "menuOrder": 40
          },
          {
            "menuId": "route_monthly", # ------------- Working
            "menuName": "월별 운행통계",
            "menuOrder": 42
          },
          {
            "menuId": "temp_and_humid", # ------------- O.K.
            "menuName": "온도, 습도 관제",
            "menuOrder": 50
          },
          {
            "menuId": "limit_config", # ------------- O.K.
            "menuName": "온도알람설정",
            "menuOrder": 60
          },
          {
            "menuId": "veh_pos_zip_list", # ------------- O.K.
            "menuName": "위치추적사실확인자료",
            "menuOrder": 90
          }
        ]
      },
      {
        "kindId": "bcd",
        "kindName": "출발 및 도착",
        "menus": [
          {
            "menuId": "bcd_io_data", # ------------- O.K.
            "menuName": "출발과 도착 기록",
            "menuOrder": 10
          },
          {
            "menuId": "bcd_group_border", # ------------- O.K.
            "menuName": "출발,도착 설정",
            "menuOrder": 20
          },
          {
            "menuId": "routine_route", # ------------- O.K.
            "menuName": "정기배송노선관리",
            "menuOrder": 30
          },
          {
            "menuId": "cruise_sched", # ------------- O.K.
            "menuName": "배송노선 및 알림 관리",
            "menuOrder": 40
          },
          {
            "menuId": "bcd_more_noti", # ------------- O.K.
            "menuName": "차량별 도착지점 설정", => 방문지 및 알림 설정
            "menuOrder": 50 => 25
          }
        ]
      },
      {
        "kindId": "dtg", @@@@@@@@@@@@@@@@@@@@@@@@ 보류 @@@
        "kindName": "디지털운행기록",
        "menus": [
          {
            "menuId": "dtg_batch_data",
            "menuName": "종합운행기록",
            "menuOrder": 10
          },
          {
            "menuId": "dtg_veh_operation",
            "menuName": "일일운행기록",
            "menuOrder": 20
          },
          {
            "menuId": "dtg_files",
            "menuName": "운행기록다운로드",
            "menuOrder": 30
          },
          {
            "menuId": "dtg_info",
            "menuName": "DTG 단말기",
            "menuOrder": 40
          }
        ]
      },
      {
        "kindId": "info",
        "kindName": "기초정보관리",
        "menus": [
          {
            "menuId": "veh_info", # ------------- O.K.
            "menuName": "차량정보 관리",
            "menuOrder": 10
          },
          {
            "menuId": "veh_info_log4cust",
            "menuName": "과거 차량정보 변경기록",
            "menuOrder": 15
          },
          {
            "menuId": "veh_type_tree", # ------------- O.K.
            "menuName": "차종, 톤수정보 관리",
            "menuOrder": 20
          },
          {
            "menuId": "veh_group", # ------------- O.K.
            "menuName": "차량그룹 관리",
            "menuOrder": 30
          },
          {
            "menuId": "cust_agree", # ------------- O.K.
            "menuName": "협력관계관리",
            "menuOrder": 35
          },
          {
            "menuId": "address_info", # ------------- O.K.
            "menuName": "주소록 관리",
            "menuOrder": 40
          },
          {
            "menuId": "user_info", # ------------- O.K.
            "menuName": "사용자관리",
            "menuOrder": 50
          },
          {
            "menuId": "cust_get_alarm", # ------------- O.K.
            "menuName": "알람수신자 관리",
            "menuOrder": 60
          },
          {
            "menuId": "term_info", # ------------- O.K.
            "menuName": "단말기 정보",
            "menuOrder": 70
          },
          {
            "menuId": "user_info_pswd", # ********* 없앰.
            "menuName": "내 암호 변경",
            "menuOrder": 80
          }
        ]
      },
      {
        "kindId": "msg",
        "kindName": "메시지",
        "menus": [
          {
            "menuId": "notice_board", # ===========
            "menuName": "공지사항",
            "menuOrder": 10
          },
          {
            "menuId": "sms_message", @@@@@@@@@@@@@@@@@@@@@@@@ 보류 @@@
            "menuName": "SMS 보내기",
            "menuOrder": 20
          },
          {
            "menuId": "sms_message_list", @@@@@@@@@@@@@@@@@@@@@@@@ 보류 @@@
            "menuName": "SMS 전송기록",
            "menuOrder": 30
          },
          {
            "menuId": "cvo_file", # ------------- O.K.
            "menuName": "파일 관리",
            "menuOrder": 40
          }
        ]
      },
      {
        kindId:"cs",
        menus: [
            terms_stat_all	cs	단말상태통계 ---------- O.K.
            term_mng	cs	단말기 개통관리 ------ working
            term_mng_log	cs	개통정보 변경기록 ---- working
            term_stock	cs	단말기 재고 --------------- O.K.
            term_stock_all  cs  단말기 재고 종합 ------- O.K.
        ]
      },
      {
        "kindId": "tool",
        "kindName": "도구",
        "menus": [
          {
            "menuId": "cust_info", # ------------- O.K.
            "menuName": "업체 정보관리",
            "menuOrder": 10
          },
          {
            "menuId": "term_info_admin", # ------------- O.K.
            "menuName": "단말기정보관리",
            "menuOrder": 20
          },
          {
            "menuId": "veh_info_log", # ------------- O.K.
            "menuName": "차량정보 변경이력",
            "menuOrder": 30
          },
          {
            "menuId": "term_change_log", # ------------- O.K.
            "menuName": "단말기정보 변경이력",
            "menuOrder": 40
          },
          {
            "menuId": "term_state_log", # ------------- O.K.
            "menuName": "단말기상태 변경이력",
            "menuOrder": 45
          },
          {
            "menuId": "route_daily_bill", # ------------- O.K.
            "menuName": "단말기 정산정보",
            "menuOrder": 50
          },
          {
            "menuId": "sms_usage", @@@@@@@@@@@@@@@@@@@@@@@@ 보류 @@@
            "menuName": "SMS 정산정보",
            "menuOrder": 60
          },
          {
            "menuId": "pos_download_log", # ------------- O.K.
            "menuName": "위치정보법 관련 관리대장",
            "menuOrder": 70
          },
          {
            "menuId": "act_log", # -------------
            "menuName": "사용기록추적",
            "menuOrder": 200
          },
          {
            "menuId": "download_info_list", # ------------- O.K.
            "menuName": "파일 다운로드",
            "menuOrder": 300
          }
        ]
      }
    ]
  }
}

*/