// VehGroupBcdMapInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette, CvoCodes, AppWord } from '../../model/AppConst';
import { VehGroupBcdMapDictionary } from './VehGroupBcdMapGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import RadioSelector from '../common/RadioSelector';
import CodeSelector from '../common/CodeSelector';
import ValueUtil from '../../model/ValueUtil';
import { useReactiveVar } from '@apollo/client';
import { codeTreeRepo } from '../../model/CvoModel';
import { StyledCo } from '../../model/AppConst';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
});

const SelectSlot = styled(Box)({
    display: 'table-cell',
});

const InfoLabelBox = styled(Box)({marginBottom:10});

const InputMap = VehGroupBcdMapDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.


export default function VehGroupBcdMapInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        if(item) {
			const d = {...item};
			// d.tel = ValueUtil.reformWith(item.tel, AppWord.NUM_N_HYPN);
            setInputData(d);
        }
		else setInputData(defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
    }, [item]);

    const BcdEventList = ValueUtil.codeToSelectList(CvoCodes.BCD_EVENT_CD, CodeTree);
    // const BcdBorderList = ValueUtil.codeToSelectList(CvoCodes.BCD_BORDER_CD, CodeTree);

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '출발도착 감지설정정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeCode = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        setInputData(data);
        setChanged(true);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size="small"
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value || ''}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    const renderSelectorSlot = (dict, value, selectFrom) => {
        return(
            <SelectSlot>
                    <CodeSelector
                        id={dict.id}
                        value={value || dict.default}
                        label={dict.label}
                        codes={selectFrom}
                        onChange={onChangeCode}
                    />
            </SelectSlot>
        );
    };

    const FieldLabel = StyledCo.FieldLabel;

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>
                {isEdit ? '출발도착 감지설정 수정' : '출발도착 감지설정 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                        <FieldLabel>지점</FieldLabel>
                        <InfoLabelBox>{item.poiName}</InfoLabelBox>
                        <FieldLabel>차량그룹</FieldLabel>
                        <InfoLabelBox>{item.groupName}</InfoLabelBox>
                        <div>&nbsp;</div>

                        {renderSelectorSlot(InputMap.bcdEventCd, inputData.bcdEventCd, BcdEventList) /* 감지 내용 */}
                        {renderTextFieldBox(InputMap.borderDesc, inputData.borderDesc,
							inputError.borderDesc, onChangeTextData) /* 메모(70자 이내) */}
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || (isEdit && !changed)}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}