// com/map/PolygonWork.js

export default class PolygonWork {
    list = [];
    mapShell = null;

    constructor(mapShell) {
        // this.list = [];
        this.mapShell = mapShell;
    }

    /**
     * MariaDB에 저장하기 위한 폴리곤 텍스트를 만들어 반환.
     * @returns polygon WKT
     */
    makeZoneShape = () => {
        const array = this.getToDraw();
        if(array.length<4) return null;
        return 'POLYGON((' + array.map((ll)=>{
            const p = this.mapShell.ll2katek(ll);
            return `${p.x} ${p.y}`;
        }).join(', ') + '))';
    };

    makeZoneRectFromXY = (kx, ky, radius) => {
        const left = kx - radius; // 서
        const right = kx + radius; // 동
        const top = ky + radius; // 북
        const bottom = ky - radius; // 남. 남쪽이 작음.
        const points = [
            [left, top], [right, top], [right, bottom], [left, bottom], [left, top]
        ];
        return 'POLYGON((' + points.map((xy)=>{
            return `${xy[0]} ${xy[1]}`;
        }).join(', ') + '))';
    };

    /**
     * 새로운 꼭지점들을 저장함.
     * @param {Array} positions - 꼭지점 배열 {lat,lon}
     */
    setList = (positions) => {
        this.list = [...positions];
    };

    /**
     * MariaDB 폴리곤 표현 텍스트를 사용해서 폴리곤 정보를 저장함.
     * @param {string} shape - MariaDB polygon WKT value
     */
    setUsingShape = (shape) => {
        if(shape.indexOf('POLYGON') === 0) {
            const pairs = shape.replace(/^POLYGON\s*\(*/,'').replace('))', '').split(/\s*,/).map((p)=>p.trim());
            const array = [];
            for(const pair of pairs) {
                const [kx, ky] = pair.split(/\s+/);
                const ll = this.mapShell.katek2ll({x:kx, y:ky});
                array.push(ll);
            }
            array.pop(); // 마지막은 처음과 같으므로 제외시킴.
            this.setList(array);
        }
        else this.setList([]);
    };

    getList = () => this.list;

    trim = () => {
        this.list = [];
    };
    /**
     * 폴리곤의 꼭지점 수를 반환
     * @returns 꼭지점 수
     */
    countVertex = () => this.list.length;

    /**
     * 지정한 인덱스의 꼭지점을 삭제하고 그 점의 경위도 객체를 반환
     * @param {number} index - 꼭지점 인덱스
     * @returns 삭제한 경위도 객체 {lat,lon}
     */
    removeAt = (index) => {
        const p = this.list.splice(index,1);
        return p;
    };

    /**
     * 지정한 인덱스에 새로운 꼭지점을 추가한다.
     * @param {number} index - 지정한 인덱스
     * @param {number} lat - lattitude
     * @param {number} lon - longitude
     */
    insert = (index, lat, lon) => {
        this.list.splice(index, 0, {lat,lon});
    };
    
    /**
     * 지정 인덱스 위치의 위치를 변경한다.
     * @param {number} index - 지정한 인덱스
     * @param {number} lat - lattitude
     * @param {number} lon - longitude
     */
    changePositionAt = (index, lat, lon) => {
        //$log.log("Redraw polygon at " + markerIndex);
        this.list[index] = {lat,lon}; // 지점의 위치를 변경한다.
    };

    getToDraw = () => {
        if(this.list.length>=3) {
            const first = this.list[0];
            const array = [...this.list];
            array.push({...first});
            return array;
        }
        return [];
    };
}