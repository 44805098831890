// BcdNoticeMore.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton } from '@mui/material';
import BcdNoticeMoreList from './BcdNoticeMoreList';
import BcdNoticeMoreInput from './BcdNoticeMoreInput';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    LIST_BCD_NOTICE_MORE, REMOVE_BCD_NOTICE_MORE, UPLOAD_BCD_NOTICE_MORE,
	BcdNoticeMoreDictionary
} from './BcdNoticeMoreGql';
import { ViewMode, AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;
const BcdNoticeMoreContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});


const ErrorTitle =BcdNoticeMoreDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(BcdNoticeMoreDictionary.inputMap, true);
/*
0 차량번호*: 시스템에 등록된 차량번호입니다.
1 지점코드*: 지점등록 시 지점별로 설정한 코드값입니다.
2 배송내역: 해당 지점과 관련된 배송내역으로 한글15자, 영문50자 이하입니다.
3 연락처 A*: 문자를 받을 수 있는 전화번호입니다.
4 연락처 B: 문자를 받을 수 있는 추가 전화번호입니다.
*/
function checkRecord(idx, rec) {
    const [veh, pcode, cargo, tel1, tel2] = rec;
    let isOk = true;
    const arr = [];
    if(Boolean(tel1)) {
        arr.push(tel1);
        if(!/^01[-\d]+$/.test(tel1)) isOk=false;
    }
    if(Boolean(tel2)) {
        arr.push(tel2);
        if(!/^01[-\d]+$/.test(tel2)) isOk=false;
    }
    if(isOk) {
        if(!Boolean(veh) || !Boolean(pcode) || (Boolean(cargo) && !ValueUtil.textInRange(cargo,0,50)) || arr.length<1) {
            isOk = false;
        }
    }

    const rr = {
        dataKey: 'idx-' + idx,
        vehPlates: veh, custPoiCode: pcode, cargo: cargo,
        noticeTo: arr.length > 0 ? arr.map(t=>t.replace(/\D/g,'')).join(',') : '',
        uploadMessage: isOk ? '' : '입력내용 오류',
        isOk: isOk
    };
    // const rr = [veh, pcode, cargo, arr.length > 0 ? arr.map(t=>t.replace(/\D/g,'')).join(',') : '', isOk];
    return rr;
}
function parseCsvLines(text) {
    const lines = text.split(/\r?\n/).filter((v)=>Boolean(v)).map((line, idx) => {
        return checkRecord(idx, line.split(","));
    });
    return lines;
}

export default function BcdNoticeMore({
    maxWidth,
    maxHeight,
    custId,
}) {
    const [noticeList, setNoticeList] = useState([]); // from server
    const [inputList, setInputList] = useState([]); // from CSV or response from uploading.
    const [openInputBox, setOpenInputBox] = useState(false);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
	const sessionInfo = useReactiveVar(userInfoRepo);

    // ##### Call GraphQL to get List #####
    const [getBcdNoticeMoreList, responseList] = useLazyQuery(LIST_BCD_NOTICE_MORE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });

    const [uploadBcdNoticeMore, responseUpload] = useMutation( UPLOAD_BCD_NOTICE_MORE, {
		onCompleted: (data, option) => onCompleteUpload(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Upload})}
	} );
    const [removeBcdNoticeMore, responseRemove] = useMutation( REMOVE_BCD_NOTICE_MORE, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});
    
    // ##### GraphQL Mutation.  ###
    /*
    const [addBcdNoticeMore, responseAdd] = useMutation( ADD_BCD_NOTICE_MORE, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editBcdNoticeMore, responseEdit] = useMutation( EDIT_BCD_NOTICE_MORE, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} ); */

    useEffect(() => {
        getBcdNoticeMoreList();
    }, [custId, getBcdNoticeMoreList]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.bcdNoticeMoreList) {
            setNoticeList(data.bcdNoticeMoreList);
        }
    };

    const onCompleteUpload = (data, clientOption) => {
        if(data.bcdNoticeMoreUpload.ok) {
            setInputList(data.bcdNoticeMoreUpload.list);
            getBcdNoticeMoreList();
        }
        else setResponseAlert({open:true, resultData: data.bcdNoticeMoreUpload, title: ErrorTitle.Upload});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.bcdNoticeMoreRemove.ok) setNoticeList(data.bcdNoticeMoreRemove.list);
        else setResponseAlert({open:true, resultData: data.bcdNoticeMoreRemove, title: ErrorTitle.Remove});
    };

    // +++++++ UI callbacks ++++++++
    const onRequestSave = () => {
        const inputData
        = inputList.filter((v)=>v.isOk).map((v)=>{
            return {vehPlates: v.vehPlates, custPoiCode: v.custPoiCode, noticeTo: v.noticeTo, cargo: v.cargo};
        });
        uploadBcdNoticeMore({variables: {bcdNoticeMore: inputData}});
    };

    const onRequestRemove = (item) => {
        setPromptToConfirm({
            data: item,
            title: '방문지 및 알림 설정 삭제',
            messages: [
                item.vehPlates + ' 차량의 ' + item.poiName + ' 방문 설정을 삭제하시겠습니까?',
                '해당 설정 및 부수하는 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{bcdNoticeMore:{vehId: data.vehId,poiId: data.poiId}}};
                    removeBcdNoticeMore(param);
                }
            }
        });
    };

    const onRequestParse = (content) => {
        // Here, you can parse the content of the CSV file
        // For example, you can split the content by lines or commas
        // and process the data as needed
        if(Boolean(content)) {
            const lines = parseCsvLines(content);
            if(responseUpload) responseUpload.reset();
            setInputList(lines);
            setOpenInputBox(true);
        }
        else sessionInfo.ifLocal(()=>console.log("No text"));
    };

    // Handler - Submit for mutation fired by BcdNoticeMoreInput component.

    const onCloseEditor = () => {
        if(responseUpload) responseUpload.reset();
        setInputList([]);
        setOpenInputBox(false);
    };

    if(ValueUtil.hasAnyAuthError(
        responseList, responseUpload, responseRemove
        )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };



	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <BcdNoticeMoreContainer>
            <BcdNoticeMoreList
                records={noticeList}
                onRequestRemove={onRequestRemove}
                onRequestParse={onRequestParse}
            />
            {renderPromptToConfirmBox()}
            <BcdNoticeMoreInput
                open={openInputBox}
                records={inputList}
                responseSaving={responseUpload}
                onRequestSave={onRequestSave}
                onClose={onCloseEditor}
                />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </BcdNoticeMoreContainer>
    )
}
