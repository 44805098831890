// PoiInfoList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo} from '../../model/CvoModel';
import ClearIcon from '@mui/icons-material/Clear';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';

const PoiInfoListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});

const TitleBox = styled('span')({fontWeight:'bold', marginRight:10, fontSize:'1.05rem'});

const TableColumns = [
    { id: 'poiName', label: '지점', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'typeName', label: '지점타입', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'roadKind', label: Dict.roadKind.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'radius', label: Dict.radius.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'addr', label: '주소', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'tel', label: '연락처', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'bcdMapped', label: '출발도착감지', width: 100, show: true, option:{align: 'center', print: (r,i,v) => {return v ? '있음' : '-'}} },
    { id: 'custPoiCode', label: '고객지점코드', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'etc', label: Dict.etc.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function PoiInfoList({
    title,
    records,
	maxHeight,
	maxWidth,
	selected,
    onClickItem,
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    //const [poiInfoList, setPoiInfoList] = useState([]);
    const [search, setSearch] = useState('');
    const clientSize = useClientSize();

    const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onSelectItem = (item) => {
		if(onClickItem) onClickItem(item);
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - AppNumber.dataContainerPadding * 2;
	
    const TableHeight = height - clientSize.HeaderToolBoxHeight
        - clientSize.SmallBoxPadding * 2 - 2;

    const InputID = "search-PoiInfo-list";
    return(
        <PoiInfoListContainer>
            <HeaderBox>
                {
                    title ? <TitleBox>{title}</TitleBox> : null
                }
				<FormControl variant='outlined' size={AppWord.SMALL}>
					<InputLabel htmlFor={InputID} style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id={InputID} onChange={onChangeSearch}
						value={search}
						disabled={records.length === 0}
						size={AppWord.SMALL}
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
            </HeaderBox>
            <FlexyTable
                name="poi_info_list"
                uniqueKey="poiId"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[]}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </PoiInfoListContainer>
    );
}