// Util.js
import { AppWord, MapValue } from "./AppConst";
import ValueUtil from "./ValueUtil";

export default class Util {
    static makeSnackMessage(message, duration, key) {
        return {
            message: message,
            duration: duration || 5000,
            key: key || (new Date()).getTime()
        };
    }

    static bubbleSnack(message, option) {
        const opt = option || {};
        const data = {
            message: message,
            duration: opt.duration || 3000,
            anchorOrigin: {
                vertical:opt.vertical || 'bottom',
                horizontal: opt.horizontal || 'center'
            }
        };
        if(opt.sx) data.sx = {...opt.sx};

        const event = new CustomEvent(AppWord.SNACK_EVENT, {
            detail: data
        });
        document.dispatchEvent(event);
    }

    static bubblePopInfoInMap(userData) {
        const ev = new CustomEvent(MapValue.MapEvent.PopInfoBox, {detail: userData});
        document.dispatchEvent(ev);
    }

    // 경로탐색용 출발 또는 도착지 설정. {isStart, {lat,lon,address}}
    static bubbleNaverRoutePoint(point) {
        const ev = new CustomEvent(MapValue.MapEvent.SetRoutePoint, {detail: point});
        document.dispatchEvent(ev);
    }

    static makeTopLeft(width, height, eventX, eventY) {
        let x = eventX || 10;
        let y = eventY || 10;
        if(x + width + 30 > window.innerWidth) x -= width;
        if(y + height + 30 > window.innerHeight) y -= height;
        return {top:y, left:x};
    }

    static ifLoggable(sessionInfo) {
        return new Promise((resolve, reject) => {
            let canLog = window.location.href.includes('//localhost');
            if(!canLog) {
                canLog = ValueUtil.isEtrace(sessionInfo);
            }

            if (canLog) {
                resolve();
            }
            else {
                if(reject) reject();
            }
        });
    }
}