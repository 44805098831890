// CustGetAlarm.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton } from '@mui/material';
import CustGetAlarmList from './CustGetAlarmList';
import CustGetAlarmInput from './CustGetAlarmInput';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_ALARM_TYPE,
    LIST_CUST_GET_ALARM, ADD_CUST_GET_ALARM, EDIT_CUST_GET_ALARM, REMOVE_CUST_GET_ALARM,
	CustGetAlarmDictionary
} from './CustGetAlarmGql';
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const ErrorTitle =CustGetAlarmDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(CustGetAlarmDictionary.inputMap, true);

const dcPad = AppNumber.dataContainerPadding;
const CustGetAlarmContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});


export default function CustGetAlarm() {
    const [alarmTypeList, setAlarmTypeList] = useState([]);
	const [custGetAlarmSelected, setCustGetAlarmSelected] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    // const responseList = useQuery(GET_CUST_GET_ALARM_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getAlarmTypeList, responseAlarmList] = useLazyQuery(LIST_ALARM_TYPE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetAlarmList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
	});
    const [getCustGetAlarmList, responseList] = useLazyQuery(LIST_CUST_GET_ALARM, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
	});
    
    // ##### GraphQL Mutation.  ###
    const [addCustGetAlarm, responseAdd] = useMutation( ADD_CUST_GET_ALARM, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editCustGetAlarm, responseEdit] = useMutation( EDIT_CUST_GET_ALARM, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeCustGetAlarm, responseRemove] = useMutation( REMOVE_CUST_GET_ALARM, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getCustGetAlarmList();
    }, [getCustGetAlarmList]);

    useEffect(() => {
        getAlarmTypeList();
    }, [getAlarmTypeList]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetAlarmList = (data, option) => {
        if(data.alarmTypeList) setAlarmTypeList(data.alarmTypeList);
    };

    const onCompleteGetList = (data, clientOption) => {
        if(custGetAlarmSelected && data.custGetAlarmList) {
            for(const item of data.custGetAlarmList) {
                if(item.custId===custGetAlarmSelected.custId && item.alarmTypeId===custGetAlarmSelected.alarmTypeId && item.mobile===custGetAlarmSelected.mobile) {
                    setCustGetAlarmSelected(item);
                    return;
                }
            }
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.custGetAlarmAdd.ok) {
            setEditorState(null);
            getCustGetAlarmList(); // getCustGetAlarmListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.custGetAlarmAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.custGetAlarmEdit.ok) {
            setEditorState(null);
            getCustGetAlarmList(); // getCustGetAlarmListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.custGetAlarmEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.custGetAlarmRemove.ok) getCustGetAlarmList();
        else setResponseAlert({open:true, resultData: data.custGetAlarmRemove, title: ErrorTitle.Remove});
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    // 다시 가져오지 말고 직접 편집창으로 넣자.
    const onRequestEdit = (data) => {
        const item = {};
        for(const field of EditFields) item[field] = data[field];
        setEditorState({item: item});
    };

    const onRequestRemove = (item) => {

        setPromptToConfirm({
            data: item,
            title: '알람수신정보 삭제',
            messages: [
                '지정하신 ' + item.rcvrName + '의 ' + item.mobile + ' 번호로의 알람수신설정을 삭제하시겠습니까?',
                '해당 정보 및 부수하는 정보는 즉시, 완전히 삭제되고 해당 수신자에게는 더이상 알람이 전송되지 않게됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{custGetAlarm:{alarmTypeId: data.alarmTypeId,mobile: data.mobile}}};
                    removeCustGetAlarm(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by CustGetAlarmInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {custGetAlarm: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editCustGetAlarm(param);
        else addCustGetAlarm(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList,
        responseAdd,
        responseEdit,
        responseRemove,
        responseAlarmList
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <CustGetAlarmInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                alarmTypeList={alarmTypeList}
                />
            :
            <CustGetAlarmInput
                open={Boolean(editorState)}
                isEdit={false}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                alarmTypeList={alarmTypeList}
                />
        );
    };

	const height = clientSize.dataAreaHeight;
	const width = clientSize.dataAreaWidth - dcPad * 2;

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <CustGetAlarmContainer>
            <CustGetAlarmList
                alarmTypeList={alarmTypeList}
				maxHeight={height}
				maxWidth={width}
                responseList={responseList}
				selected={custGetAlarmSelected}
				onClickItem={setCustGetAlarmSelected}
                onRequestAdd={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
            />
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </CustGetAlarmContainer>
    )
}
