//TermChangeLog.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, AppBar } from '@mui/material';
import useClientSize from '../hook/useClientSize';
import CustInfoSelector from '../cust_info/CustInfoSelector';
import TermInfoNarrowList from '../term_info/TermInfoNarrowList';
import TermChangeLogList from './TermChangeLogList';
import TermStateLogList from './TermStateLogList';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { SIMPLE_LIST_TERM_INFO } from '../term_info/TermInfoGql';
import { LIST_TERM_CHANGE_LOG, LIST_TERM_STATE_LOG } from './TermChangeLogGql';
import { AppObject, AppNumber, AppPalette, AppWord } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser, currentMenuRepo } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;

const TermInfoLogContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection: 'column'
});
const HeaderBox = styled(Box)({ // in VehInfoLogContainer
    height: AppNumber.HeaderToolBoxHeight, display:'flex', alignItems:'center'
});
const SubjectsAndLog = styled(Box)({flexGrow:1, display:'flex'}); // in VehInfoLogContainer

// in SubjectsAndLog
const SubjectListBoxWidth = 300;
const SubjectListBox = styled(Box)({marginRight:10, display:'flex', width:SubjectListBoxWidth, border: AppPalette.BorderCCC, borderRadius:5});
const LogSwitchBox = styled(Box)({ // contains AppBar + TermStateLogList/TermChangeLogList
    flexGrow:1, display: 'flex', flexDirection:'column', 
});
const LogListBox = styled(Box)({
    flexGrow: 1, display: 'flex'
});

export default function TermChangeLog() {
    const currentMenu = useReactiveVar(currentMenuRepo);
    const [tabMenu, setTabMenu] = useState(
        currentMenu.menuId===AppWord.MENU_TERM_STT_LOG ? currentMenu.menuId : AppWord.MENU_TERM_CH_LOG);
    const [custInfo, setCustInfo] = useState(null);
    const [termInfo, setTermInfo] = useState(null);
    const [termInfoList, setTermInfoList] = useState([]);
    const [termChangeLogList, setTermChangeLogList] = useState([]);
    const [termStateLogList, setTermStateLogList] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getTermChangeLogList, resTermChLog] = useLazyQuery(LIST_TERM_CHANGE_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {if(data.termChangeLogList) setTermChangeLogList(data.termChangeLogList);},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "단말기변경이력 조회 오류"})}
    });
    const [getTermStateLogList, resTermStateLog] = useLazyQuery(LIST_TERM_STATE_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {if(data.termStateLogList) setTermStateLogList(data.termStateLogList);},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "단말기상태변경이력 조회 오류"})}
    });
    const [getTermInfoList, responseTermInfoList] = useLazyQuery(SIMPLE_LIST_TERM_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {if(data.termInfoList) setTermInfoList(data.termInfoList);},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '단말기목록 조회 오류'})}
    });

    useEffect(() => {
        if(custInfo) getTermInfoList({variables:{custId:custInfo.custId}});
    }, [custInfo, getTermInfoList]);

    useEffect(()=>{
        if(termInfo) {
            if(tabMenu===AppWord.MENU_TERM_STT_LOG && termInfo.devId) {
                getTermStateLogList({variables: {devId:termInfo.devId}});
            }
            else if(tabMenu===AppWord.MENU_TERM_CH_LOG && termInfo.termId) {
                getTermChangeLogList({variables: {termId: termInfo.termId}});
            }
        }
    },[tabMenu, termInfo, getTermStateLogList, getTermChangeLogList]); // tab menu or termInfo was changed


    const onChangeCustSelection = (custInfo) => {
        setCustInfo(custInfo);
    };

    const onClickTerminal = (dev) => {
        if(!termInfo || dev.devId !== termInfo.devId) {
            setTermInfo(dev);
            // getTermChangeLogList({variables:{termId: dev.termId}});
        }
    };

    const onChangeTabMenu = (menu) => {
        if(menu !== tabMenu) setTabMenu(menu);
    };

    if(ValueUtil.hasAnyAuthError(
        resTermChLog,
        resTermStateLog,
        responseTermInfoList
        )) userInfoRepo(NoUser);

    return (
        <TermInfoLogContainer>
            <HeaderBox>
                <CustInfoSelector
                    popoverHeight={clientSize.dataAreaHeight - AppNumber.HeaderToolBoxHeight - 50}
                    onChange={onChangeCustSelection}
                />
            </HeaderBox>
            <SubjectsAndLog>
                <SubjectListBox>
                    <TermInfoNarrowList
                        columnList={['termId', 'model']}
                        records={termInfoList}
                        selected={termInfo}
                        onClickItem={onClickTerminal}
                    />
                </SubjectListBox>
                <LogSwitchBox>
                    <AppBar position='static'>
                        <Tabs value={tabMenu} variant='fullWidth' textColor={AppPalette.InheritColor}
                            onChange={(e,v) => {onChangeTabMenu(v)}}
                        >
                            <Tab value={AppWord.MENU_TERM_CH_LOG} label="단말기 정보 변경 이력" />
                            <Tab value={AppWord.MENU_TERM_STT_LOG} label="단말기 상태 변경 이력" />
                        </Tabs>
                    </AppBar>
                    <LogListBox>
                    {
                        tabMenu===AppWord.MENU_TERM_STT_LOG
                        ?
                        <TermStateLogList
                            records={termStateLogList}
                        />
                        :
                        <TermChangeLogList
                            records={termChangeLogList}
                        />
                    }
                    </LogListBox>
                </LogSwitchBox>
            </SubjectsAndLog>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </TermInfoLogContainer>
    )
}
