
// BcdNoticeMoreInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import FlexyTable from '../common/flexytable/FlexyTable';
import { FlexySize } from '../common/flexytable/FlexyTableStyled';
import { AppPalette, AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import useClientSize from '../hook/useClientSize';
import { useMutation } from '@apollo/client';


const LogDialogTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground, color: 'white', minWidth: 300,
});

const LogContainer = styled(Box)({ position:'relative' });

const VehRouteListContainer = styled(Box)({
    position:"absolute", top:40, right:0, bottom: 0, left: AppNumber.dataContainerPadding,
	display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    display:'flex', alignItems:'center'
});

const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custPoiCode', label: '지점코드', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'cargo', label: '배송내역', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'noticeTo', label: '통보대상', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'uploadMessage', label: '오류', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function RoutineDistribLogListPop({
    open,
    records,
    onClose,
    onRequestSave,
    responseSaving 
}) {
    const [doneSaving, setDoneSaving] = useState(false);
    const clientSize = useClientSize();
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        let done = false;
        if(resultSave) {
            done=resultSave.bcdNoticeMoreUpload.ok;
        }
        setDoneSaving(done);
    }, [resultSave]);
    

    const maxHeight = clientSize.dataAreaHeight - 200;
    const maxWidth = TableColumns.reduce((prev, obj)=>{return prev + obj.width + 1},FlexySize.IndexCellWidth+5) + 10;

    const onClickSave = () => {
        onRequestSave();
    };

    return(
        <Dialog open={open} maxWidth="lg">
            <LogDialogTitle>
                방문지 및 알람 설정 {doneSaving ? '저장 결과' : null}
            </LogDialogTitle>
            <DialogContent style={{height:maxHeight, }}>
                <LogContainer width={maxWidth} height={maxHeight}>
                    <VehRouteListContainer>
                        <FlexyTable
                            name="routine_distrib_log_list"
                            uniqueKey="dataKey"
                            columns={TableColumns}
                            records={records}
                            tools={[]}
                        />
                    </VehRouteListContainer>
                </LogContainer>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    doneSaving
                    ? <span>저장 완료 &nbsp;</span>
                    :
                    <Button onClick={onClickSave} variant='contained' color={AppPalette.PrimaryColor}>
                        오류있는 행 제외하고 업로드
                    </Button>
                }
                <Button onClick={onClose} variant='contained' color={AppPalette.InheritColor}>닫기</Button>
            </DialogActions>
        </Dialog>
    );
}
