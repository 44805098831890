// VehInfoGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";


export const VehInfoDictionary = {
    inputSmartLogis: {
        termId : {
            id: 'termId', label: '스마트폰 번호', type: 'tel', required: true,
            toAdd: true, toEdit: true,
            help: '숫자 11자 이내로 입력하세요.',
            check: (value) => {return value && /^01\d{9,10}$/.test(value)},
        },
    },
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        // custId : {toAdd: true, toEdit: true},
        vehId : {toAdd: false, toEdit: true},
        vehPlates : {
            id: 'vehPlates', label: '차량번호', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '10자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,30)},
        },
        vehAlias : {
            id: 'vehAlias', label: '지도표시명', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '10자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,30)},
        },
        devId : {
            id: 'devId', label: '단말기', type: 'select', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return value > 0 && value < 99999999},
        },
        vehTonId : {
            id: 'vehTonId', label: '차종, 톤수', type: null, required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return value > 0 && value < 99999},
        },
        groupCsv: {
            id: 'groupCsv', label: '차량그룹(복수가능)', type: null, required: false,
            toAdd: true, toEdit: true,
            default: '',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isCsvText(value);},
        },
        vehAddr : {
            id: 'vehAddr', label: '차고지주소', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '60자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,200)},
        },
        driverName : {
            id: 'driverName', label: '운전자명', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '10자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,30)},
        },
        driverMobile : {
            id: 'driverMobile', label: '운전자휴대전화', type: 'tel', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isPhoneNumber(value)},
        },
        termPowerCd : {
            id: 'termPowerCd', label: '단말기 전원', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default:'VEHPOWER',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,20)},
        },
        veh01Cd : {
            id: 'veh01Cd', label: '자체코드1', type: 'select', required: false,
            toAdd: true, toEdit: true,
            default:-1,
            check: (value) => {return value < 99},
        },
        veh02Cd : {
            id: 'veh02Cd', label: '자체코드2', type: 'select', required: false,
            toAdd: true, toEdit: true,
            default:-1,
            check: (value) => {return value < 99},
        },
        veh03Cd : {
            id: 'veh03Cd', label: '자체코드3', type: 'select', required: false,
            toAdd: true, toEdit: true,
            default:-1,
            check: (value) => {return value < 99},
        },
        driveCanStart : {
            id: 'driveCanStart', label: '운행시작시각', type: 'time', required: false,
            toAdd: true, toEdit: true,
            //check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,38)},
        },
        driveMustEnd : {
            id: 'driveMustEnd', label: '운행종료시각', type: 'time', required: false,
            toAdd: true, toEdit: true,
            //check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,38)},
        },
        dailyStatStart : {
            id: 'dailyStatStart', label: '통계생성시작', type: 'time', required: false,
            toAdd: true, toEdit: true,
            //check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,38)},
        },
        dailyStatEnd : {
            id: 'dailyStatEnd', label: '통계생성종료', type: 'time', required: false,
            toAdd: true, toEdit: true,
            //check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,38)},
        },
        doorSensors : {
            id: 'doorSensors', label: '도어개폐센서(별도장착)', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isCsvText(value);},
        }
    },
    // for response error printing
    queryName: {Add:'userInfoAdd', Edit:'userInfoEdit', Remove:'userInfoRemove'},
    errorTitle: {
        Add: '차량정보 생성 오류',
        Edit: '차량정보 수정 오류',
        Remove: '차량정보 삭제 오류',
        Get: '차량정보 조회 오류',
    }
};

export const MapForSmartLogis = {
    vehPlates: VehInfoDictionary.inputMap.vehPlates,
    termId : {
        id: 'termId', label: '안드로이드 단말기번호', type: 'tel', required: true,
        toAdd: true, toEdit: false,
        help: '숫자 11자 이내로 입력하세요.',
        check: (value) => {return value && /^01\d{9,10}$/.test(value)},
    },
    vehTonId: VehInfoDictionary.inputMap.vehTonId,
    driverName: VehInfoDictionary.inputMap.driverName
};


const VEH_INFO_LIST_PART = gql`
fragment VehInfoListPart on VehInfo {
    vehId
    custId
    custName
    vehPlates
    vehAlias
    devId
    termId
    vehTypeTon
    vehTypeId
    vehTypeName
    vehTonId
    ton
    vehAddr
    driverName
    driverMobile
    termPowerCd
    termPowerValue
    gpsTime
    distKm
    pastHour
    useYn
    termEtc
    doorSensors
    regTime
}
`;

const VEH_INFO_NO_LIST_PART = gql`
fragment VehInfoNoListPart on VehInfo {
    groupCsv
    veh01Cd
    veh02Cd
    veh03Cd
    regUserId
    driveCanStart
    driveMustEnd
    operFontColor
    operBackColor
    noOperFontColor
    noOperBackColor
    emerFontColor
    emerBackColor
    offFontColor
    offBackColor
    vehMemo
    dailyStatStart
    dailyStatEnd
    serialNo
    modelId
    networkCd
    networkValue
    posSys
    posSysValue
    termStateCd
    termStateValue
    cycleMake
    cycleReport
    reportTimeFrom
    reportTimeTill
    model
    protocol
    maker
    memo1
}
`;

export const LIST_VEH_INFO = gql`
${VEH_INFO_LIST_PART}
query ListVehInfo($custId:String) {
    vehInfoList(custId:$custId) {
        ...VehInfoListPart
    }
}
`;

export const SIMPLE_LIST_VEH_INFO = gql`
query SimpleListVehInfo($custId:String) {
    vehInfoList(custId:$custId) {
        vehId
        vehPlates
        vehAlias
        devId
        termId
        vehTypeTon
        driverName
        driverMobile
    }
}
`;

export const GET_VEH_INFO = gql`
${VEH_INFO_LIST_PART}
${VEH_INFO_NO_LIST_PART}
query GetVehInfo($vehId:Int!) {
    vehInfoItem(vehId:$vehId) {
        ...VehInfoListPart
        ...VehInfoNoListPart
    }
}
`;

export const ADD_VEH_INFO = gql`
mutation AddVehInfo($vehInfo:InputVehInfoAdd!) {
  vehInfoAdd(vehInfo: $vehInfo) {
    ok
    message
  }
}
`;

export const EDIT_VEH_INFO = gql`
mutation EditVehInfo($vehInfo:InputVehInfoEdit!) {
  vehInfoEdit(vehInfo: $vehInfo) {
    ok
    message
  }
}
`;

export const REMOVE_VEH_INFO = gql`
mutation RemoveVehInfo($vehInfo:InputVehInfoRemove!) {
  vehInfoRemove(vehInfo: $vehInfo) {
    ok
    message
  }
}
`;

export const LISTS_OF_GROUP_TON_TERM = gql`
    query ListsOfGroupTonTerm($custId:String) {
        vehGroupList(custId:$custId) {
            groupId
            custId
            groupName
            parentNodeId
        }
        vehTonInfoList(custId:$custId) {
            custId
            vehTonId
            vehTypeId
            vehTypeName
            ton
            vehTypeTon
            tonDesc
        }
        termInfoListAvailable(custId:$custId) {
            devId
            termId
            serialNo
            modelId
            model
        }
        custOwnCodeTree {
            codeKind
            codeName
            codes {
                codeNum
                title
                descript
            }
        }
    }
`;

export const LIST_AVAILABLE_TERMS = gql`
    query ListsOfAvailableTerms($custId:String) {
        termInfoListAvailable(custId:$custId) {
            devId
            termId
            serialNo
            modelId
            model
        }
    }
`;

/*
vehInfoTermOff(vehInfo: InputVehInfoTermOff!): CommonResponse
InputVehInfoTermOff {vehId: Int!}
*/
export const VEH_INFO_TERM_OFF = gql`
mutation VehInfoTermOff($vehInfo:InputVehInfoTermOff!) {
  vehInfoTermOff(vehInfo: $vehInfo) {
    ok
    message
  }
}
`;

/*
vehInfoSetUsage(vehInfo: InputVehInfoSetUsage!): CommonResponse
InputVehInfoSetUsage {vehId: Int!, useYn: String!}
*/
export const VEH_INFO_SET_USAGE = gql`
mutation VehInfoSetUsage($vehInfo:InputVehInfoSetUsage!) {
  vehInfoSetUsage(vehInfo: $vehInfo) {
    ok
    message
  }
}
`;

