// MapPoiInfo.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import MapPoiInfoList from './MapPoiInfoList';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
    REMOVE_POI_INFO, PoiInfoDictionary
} from './PoiInfoGql';
import { AppObject, AppNumber, MapValue } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser, poiTypeRepo, poiInfoRepo } from '../../model/CvoModel';
import Util from '../../model/Util';
import CustPoiType from '../cust_poi_type/CustPoiType';

const PoiInfoContainer = styled(Box)({
    flexGrow:1, display:'flex', //backgroundColor:'yellow'
	// position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});


const ErrorTitle =PoiInfoDictionary.errorTitle;

export default function MapPoiInfo({
    mapShell,
    onRequestOpenEditor, // 탭 컨트롤 등의 편의를 위해서 편집기는 상위에서 열어야 함.
}) {
	const [poiInfoSelected, setPoiInfoSelected] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [openTypeManager, setOpenTypeManager] = useState(false);
    const [responseAlert, setResponseAlert] = useState(null);
    // const responseList = useQuery(GET_POI_INFO_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});


    // ##### Call GraphQL #####
    const [removePoiInfo, responseRemove] = useMutation( REMOVE_POI_INFO, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    // >>>>>>>>> callbacks <<<<<<<<<<<<<

    const onCompleteRemove = (data, clientOption) => {
        if(data.poiInfoRemove.ok) poiInfoRepo(data.poiInfoRemove.list);
        else setResponseAlert({open:true, resultData: data.poiInfoRemove, title: ErrorTitle.Remove});
    };

    // +++++++ UI callbacks ++++++++
    const onRequestTypeManager = () => {
        setOpenTypeManager(true);
    };

    const onRequestEdit = (data) => {
        onRequestOpenEditor(data);
    };

    const onClickPoiItem = (item) => {
        setPoiInfoSelected(item);
        if(item.lat && item.lon)
            mapShell.morphTo(item.lat, item.lon, MapValue.Level.MAX_FOR_CLUSTER+1);
        else Util.bubbleSnack(item.poiName + '지점의 위치(위도, 경도)가 설정되어 있지 않습니다');
    };

    const onRequestRemove = (item) => {

        setPromptToConfirm({
            data: item,
            title: '지점 정보 삭제',
            messages: [
                '지점 ' + item.poiName + '을 삭제하시겠습니까?',
                '해당 지점의 정보 및 부수하는 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{poiInfo:{poiId: data.poiId}}};
                    removePoiInfo(param);
                }
            }
        });
    };

    if(ValueUtil.hasAnyAuthError(
        responseRemove
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <PoiInfoContainer>
            <MapPoiInfoList
                selected={poiInfoSelected}
                onClickItem={onClickPoiItem}
                onRequestTypeManager={onRequestTypeManager}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
            />
            <CustPoiType
                open={openTypeManager}
                onClose={()=>setOpenTypeManager(false)}
            />
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </PoiInfoContainer>
    )
}
