

export default class VehGroupNode {
    groupId = 0;
    custId = null;
    groupName = null;
    parentNodeId = null;
    groupDesc = null;
    treeLevel = 0;
    isLastNode = null;
    isDefault = null;
    etc = null; // 불필요.
    regUserId = null;
    regDate = null;

    targetCustIdCsv = null; // when records exist in cust_agree
    userId = null;

    boolRoot = null; // 이 노드가 루트인가?
    boolChecked = false;
    children = [];


    constructor() {
        this.boolRoot = true;
    }

    setFromObject = (data) => {
        this.groupId = data.groupId;
        this.custId = data.custId;
        this.groupName = data.groupName;
        this.parentNodeId = data.parentNodeId;
        this.groupDesc = data.groupDesc;
        this.treeLevel = data.treeLevel;
        this.isLastNode = data.isLastNode;
        this.isDefault = data.isDefault;
        this.etc = data.etc;
        this.regUserId = data.regUserId;
        this.regDate = data.regDate;

        this.targetCustIdCsv = data.targetCustIdCsv;
        this.userId = data.userId;

        this.boolRoot = false;
        if(data.__checked) this.boolChecked = true;
    };

    equalTo = (node) => {
        if(node) return node.getPrimaryData() === this.getPrimaryData();
        return false;
    };
    getPrimaryData = () => {return this.groupId;};
    getLabel = () => {return this.groupName;};
    hasParent = () => {return this.parentNodeId ? true : false;};
    getParentNodeId = () => {return this.parentNodeId;};
    isRoot = () => {return this.boolRoot;};
    isChecked = () => {return this.boolChecked;};
    // check = () => {this.boolChecked = true;};
    // uncheck = () => {this.boolChecked = false;};
    toggle = () => {this.boolChecked = !this.boolChecked;};
    hasChildren = () => {return this.children.length > 0};
    uncheckAll = () => {
        this.boolChecked = false;
        for(const child of this.children) child.uncheckAll();
    };

    setTargetCustIdCsv = (custIdCsv) => {
        this.targetCustIdCsv = custIdCsv;
    };

    checkAndAddTargetCustIdCsv = (groupId, custIdCsv) => {
        if(this.groupId === groupId) { this.setTargetCustIdCsv(custIdCsv); return true; }
        else {
            for(const child of this.children) {
                if(child.checkAndAddTargetCustIdCsv(groupId, custIdCsv)) return true;
            }
        }
    };

    check = (groupId, checked) => {
        if(this.groupId === groupId) {
            if(this.children.length === 0)
                this.boolChecked = checked; // last node 경우만 실행.
            return true;
        }
        for(const child of this.children) {
            if(child.check(groupId, checked)) return true;
        }
        return false;
    };

    getPrimaryDataChecked = () => {
        if(this.children.length > 0) {
            var ids = [];
            for(const child of this.children) {
                ids = ids.concat(child.getPrimaryDataChecked());
            }
            return ids;
        }
        else {
            if(this.boolChecked) return [this.getPrimaryData()];
            else return [];
        }
    };

    static initFromObject(data) {
        var vo = new VehGroupNode();
        vo.setFromObject(data);
        return vo;
    }

    static makeTree(array) {
        const map = {};
        const roots = [];

        // Build a map of records keyed by their group_id
        for (const record of array) {
            const vo = VehGroupNode.initFromObject(record);
            map[vo.groupId] = vo;
            if(!vo.parentNodeId) {
                vo.boolRoot = true;
                roots.push(vo);
            }
        }

        // Populate each record's parent's children array
        for (const record of array) {
            const { groupId, parentNodeId } = record;
            if (parentNodeId) {
                map[parentNodeId].children.push(map[groupId]);
            }
        }

        return roots;
    }

    static getPrimaryDataChecked(array) {
        var ids = [];
        for(const child of array) {
            ids = ids.concat(child.getPrimaryDataChecked());
        }
        return ids;
    }

    /*
    static checkNode(array, groupId, checked) {
        for(const child of array) {
            if(child.check(groupId, checked)) return;
        }
    } */

    static checkNodes(array, groupsToCheck) {
        for(const gid of groupsToCheck) {
            for(const child of array) {
                child.check(gid, true);
            }
        }
    }

    static addTargetCustIdCsv(array, groupId, custIdCsv) {
        for(const group of array) {
            if(group.checkAndAddTargetCustIdCsv(groupId, custIdCsv)) return true;
        }
        return false;
    }
}

/*
         = 0;
         = null;
         = null;
         = null;
         = null;
         = 0;
         = null;
         = null;
         = null;
         = null;
         = null;

groupId: Int!
custId: String!
groupName: String!
parentNodeId: Int
groupDesc: String
treeLevel: Int
isLastNode: String
isDefault: String
etc: String
regUserId: String
regDate: String
*/