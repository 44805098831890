// CustAgreeListGiving.js
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button, IconButton, TextField, Checkbox, FormControlLabel } from '@mui/material';
import {userInfoRepo, NoUser} from '../../model/CvoModel';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AppObject, AppWord, TableColumnType } from '../../model/AppConst';
import { LIST_CUST_AGREE_GIVING, ADD_CUST_AGREE, REMOVE_CUST_AGREE } from './CustAgreeGql';
import ValueUtil from '../../model/ValueUtil';

const LocalTitle = "협력그룹"

const AllocBox = styled(Box)({ // has TitleBox and PairBox
    flexGrow:1,
    display:'flex',
    flexDirection: 'column',
});

const TitleBox = styled(Box)({fontWeight:'bold', padding:10, fontSize: '1.1rem', marginTop:10});

const NewAgreeBox = styled(Box)({border:'1px solid #ccc', borderRadius:5, padding: 5});

const TitleOfListBox = styled(Box)({fontWeight:'bold', padding:10, fontSize: '1.03rem', marginTop:10, borderBottom:'1px solid #ccc'});
const ListWrapper = styled(Box)({flexGrow:1, display:'flex', marginTop:10});
const ListBox = styled(Box)({flex:1});
const AgreeItemBox = styled(Box)({
    border:'1px solid #ccc', borderRadius:5, padding:5, display:'inline-table', width: 240, marginRight: 20
});
const AgreeRow = styled(Box)({display:'table-row'});
const AgreeLabel = styled(Box)({
    display:'table-cell', fontSize:'0.85rem', color:'#058', textAlign:'right',
    paddingRight: 10, paddingTop:5, paddingBottom: 5, width:90});
const AgreeData = styled(Box)({display:'table-cell', textAlign:'left'});

const InfoBox = styled(Box)({padding: 10, fontSize: '0.9rem', color:'#555555'});
const ButtonBox = styled(Box)({padding:5});
const LaButton = styled(Button)({marginRight: 10});
const ErrorTitle = {
    List: '제공된 협력관계 목록 조회 오류',
    Add: '신규 협력관계 생성 오류',
    Remove: '협력관계 삭제 오류',
};

export default function CustAgreeListGiving({
    group,
    onRequestSetCustAgree,
    onRequestReloadGroupTree,
}) {
    const [openEditor, setOpenEditor] = useState(false);
    const [targetCustId, setTargetCustId] = useState(null);
    const [editAllowed, setEditAllowed] = useState(false);
    const [isBadCustId, setBadCustId] = useState(false);
    const [custAgreeList, setCustAgreeList] = useState([]);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const groupId = group.groupId;
    const groupName = group.groupName;
    // ##### Call GraphQL to get List #####
    const [getCustAgreeListGiving, responseList] = useLazyQuery(LIST_CUST_AGREE_GIVING, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
    });
    
    // ##### GraphQL Mutation.   ###
    const [addCustAgree, responseAdd] = useMutation( ADD_CUST_AGREE, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [removeCustAgree, responseRemove] = useMutation( REMOVE_CUST_AGREE, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(()=>{
        if(groupId) getCustAgreeListGiving();
    }, [groupId, getCustAgreeListGiving]);

    const setNewList = (list) => {
        const custIds = list.map((agree) => agree.custId);
        setOpenEditor(false);
        setTargetCustId(null);
        setCustAgreeList(list);
        return custIds;
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.custAgreeListGiving) setNewList(data.custAgreeListGiving.filter((agree)=>agree.groupId===groupId));
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.custAgreeAdd.ok) {
            const custIdList = setNewList(data.custAgreeAdd.list.filter((agree)=>agree.groupId===groupId));
            onRequestSetCustAgree(groupId, custIdList.length > 0 ? custIdList.join(',') : null);
        }
        else setResponseAlert({open:true, resultData: data.custAgreeAdd, title: ErrorTitle.Add});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.custAgreeRemove.ok) {
            const custIdList = setNewList(data.custAgreeRemove.list.filter((agree)=>agree.groupId===groupId));
            onRequestSetCustAgree(groupId, custIdList.length > 0 ? custIdList.join(',') : null);
        }
        else setResponseAlert({open:true, resultData: data.custAgreeRemove, title: ErrorTitle.Remove});
    };

    // <<<<<<<<<< UI <<<<<<<<<<<<<
    const onClickOpenAgreementEditor = () => {
        setTargetCustId('');
        setEditAllowed(false);
        setBadCustId(false);
        setOpenEditor(true);
    };

    const onClickSaveAgreement = () => {
        if(ValueUtil.isGoodCustId(targetCustId))
            addCustAgree({variables: {custAgree:{groupId: groupId, custId: targetCustId, editYn:editAllowed?'Y':'N'}}});
    };

    const onClickCancelAgreement = () => {
        setTargetCustId(null);
        setOpenEditor(false);
    };

    const onChangeCustId = (event) => {
        if(event.target.value) {
            if(/[^0-9]/.test(event.target.value)) return;
            if(event.target.value.length > 10) return;
        }
        setTargetCustId(event.target.value);
        setBadCustId(!ValueUtil.isGoodCustId(event.target.value));
    };

    const onChangeEditable = (event) => {setEditAllowed(event.target.checked)};

    const onClickRemove = (item) => {
        setPromptToConfirm({
            data: item,
            title: '그룹의 협력관계 삭제',
            messages: ['이 그룹의 협력관계를 삭제합니다.', '대상업체에서는 더 이상 이 그룹의 차량들을 관제하지 못하게 됩니다.', '계속하시겠습니까?'],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    removeCustAgree({variables: {custAgree:{groupId: groupId, custId: data.custId}}});
                }
            }
        });

    };

    if(!groupId) return null;

    // redner -------------------
    const renderConfirmDialog = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderNewAgreeBox = () => {
        return(
            <NewAgreeBox>
                <div>&nbsp;</div>
                <TextField
                    value={targetCustId}
                    onChange={onChangeCustId}
                    size='small'
                    label="대상업체 아이디"
                    helperText="10자의 숫자입니다."
                    error={isBadCustId}
                />
                &nbsp;
                <FormControlLabel label="대상업체가 차량정보를 변경할 수 있게 함"
                    control={
                        <Checkbox value={editAllowed} onChange={onChangeEditable} />
                    }
                />
                <ul>
                    <li>협력받을 업체와 필요한 절차를 완료해야 합니다.</li>
                    <li>협력받을 업체가 대상그룹에 속한 모든 차량에 대한 관제권한을 가집니다.</li>
                    <li>귀사는 협력받는 업체가 운전자의 개인정보를 열람할 수 있다는 사실을 운전자에게 통보해야 합니다.</li>
                    <li>향후 협력대상 그룹에 차량이 추가되면 협력받을 업체가 추가로 이를 관제할 수 있으므로 위와 같은 주의 및 조치를 그 때마다 취해야 합니다.</li>
                    <li>향후 협력대상 그룹에서 차량이 삭제되면 협력받을 업체에서 더 이상 관제할 수 없게됩니다.</li>
                    <li>협력받을 업체에서는 귀사가 별도로 허가하는 경우 해당 차량의 기초정보를 변경할 수 있습니다.</li>
                    <li>협력받을 업체는 해당 차량에 대해 특정지점에 대한 자동 출발, 도착 이벤트를 받을 수 있습니다.</li>
                    <li>위와 같은 모든 주의사항을 숙지하고 필요한 조치가 취해진 후에만 진행하시기 바랍니다.</li>
                </ul>

                <ButtonBox>
                    <LaButton color='secondary'
                        onClick={onClickSaveAgreement}
                        variant='contained'>
                        협력관계 추가
                    </LaButton>
                    <LaButton onClick={onClickCancelAgreement} variant='contained'>
                        취소
                    </LaButton>
                </ButtonBox>
            </NewAgreeBox>
        );
    };

    const renderButtonBox = () => {
        return(
            <NewAgreeBox>
                <ul>
                    <li>협력관계는 우리 차량을 다른 업체에서 관제할 수 있게 하는 방법입니다.</li>
                    <li>우리 차량의 위치가 노출되므로 극도의 주의가 필요합니다.</li>
                    <li>특정 그룹에 꼭 필요한 차량만 넣은 후 진행해야 합니다.</li>
                    <li>위치, 개인정보보호 관련하여 운전자, 대상업체 등에 적절한 법적조치들을 취한 후 진행해 주세요.</li>
                    <li>문의 사항은 (주)이트레이스로 연락 바랍니다.</li>
                </ul>
            </NewAgreeBox>
        );
    };

    return(
        <AllocBox>
            <TitleBox>
                { openEditor ? null :
                    <Button onClick={onClickOpenAgreementEditor} variant='contained' style={{float:'right'}}>
                        새로운 협력관계 추가하기
                    </Button>
                }
                {groupName} 차량그룹에 대한 협력상태 관리
            </TitleBox>
            { openEditor ? renderNewAgreeBox() : renderButtonBox() }
            <TitleOfListBox>
                {groupName} 차량그룹의 협력상태 현황
            </TitleOfListBox>
            <ListWrapper>
                <ListBox>
                    {
                        custAgreeList.length===0
                        ? <InfoBox>이 차량그룹에 대한 협력설정이 없습니다.</InfoBox>
                        : null
                    }
                    {
                        custAgreeList.map((item,idx) => {
                            return(
                                <AgreeItemBox key={idx}>
                                    <AgreeRow>
                                        <AgreeLabel>대상업체</AgreeLabel>
                                        <AgreeData>{item.custNameUsing}</AgreeData>
                                    </AgreeRow>
                                    {
                                        openEditor
                                        ?
                                        <AgreeRow>
                                            <AgreeLabel>..</AgreeLabel>
                                            <AgreeData>....</AgreeData>
                                        </AgreeRow>
                                        :
                                        <>
                                        <AgreeRow>
                                            <AgreeLabel>대상업체 ID</AgreeLabel>
                                            <AgreeData>{item.custId}</AgreeData>
                                        </AgreeRow>
                                        <AgreeRow>
                                            <AgreeLabel>정보변경허용</AgreeLabel>
                                            <AgreeData>{item.editYn==='Y' ? '허용' : '불허'}</AgreeData>
                                        </AgreeRow>
                                        <AgreeRow>
                                            <AgreeLabel>설정작업자 ID</AgreeLabel>
                                            <AgreeData>{item.givingUserId}</AgreeData>
                                        </AgreeRow>
                                        <AgreeRow>
                                            <AgreeLabel>설정일</AgreeLabel>
                                            <AgreeData>{item.regDate}</AgreeData>
                                        </AgreeRow>
                                        <AgreeRow>
                                            <AgreeLabel>&nbsp;</AgreeLabel>
                                            <AgreeData>
                                                <IconButton color='secondary'
                                                    onClick={()=>{onClickRemove(item)}}
                                                >
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </AgreeData>
                                        </AgreeRow>
                                        </>
                                    }
                                </AgreeItemBox>
                            );
                        })
                    }
                </ListBox>
            </ListWrapper>
            {renderConfirmDialog()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </AllocBox>
    );
}

/*
To create a horizontally scrollable container using Material-UI (MUI), you can use the overflow-x CSS property combined with the overflow-scrolling class from MUI.

Here's an example of how you can achieve a horizontally scrollable container:

jsx
Copy code
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    overflowX: 'auto',
    '-webkit-overflow-scrolling': 'touch', // For iOS Safari smooth scrolling
    // Add any additional styling for the container
  },
  item: {
    flex: '0 0 auto',
    width: 200, // Adjust the width of your items as needed
    // Add any additional styling for the items
  },
}));

const HorizontalScrollContainer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {/* Render your items inside the container * /}
      <div className={classes.item}>Item 1</div>
      <div className={classes.item}>Item 2</div>
      <div className={classes.item}>Item 3</div>
      {/* Add more items as needed * /}
    </Box>
  );
};
In this example, we use the display: flex property on the container to make the items align horizontally. The overflowX: auto property enables the horizontal scrollbar when the items exceed the container's width. The -webkit-overflow-scrolling: touch property provides smooth scrolling for iOS Safari.

Each item inside the container has a fixed width (width: 200px in this example). Adjust the width to match the width of your items.

You can customize the styling of the container and items by modifying the container and item classes defined in the makeStyles function.

By wrapping your items inside the Box component with the container class, you can create a horizontally scrollable container with fixed-width items.

I hope this helps! Let me know if you have any further questions.





Regenerate response
*/