//TermStockAll.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
//import useClientSize from '../hook/useClientSize';
import { Button, IconButton } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import {userInfoRepo} from '../../model/CvoModel';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType, AppPalette } from '../../model/AppConst';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { TermStockDictionary, LIST_TERM_STOCK_ALL } from './TermStockGql';
import ResponseAlert from '../message/ResponseAlert';
import Util from '../../model/Util';

const dcPad = AppNumber.dataContainerPadding;
const TermStockAllContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});

const TermStockListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
	display:'flex', alignItems:AppWord.CENTER, justifyContent:AppWord.END,
    height:AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
});

const Dict = TermStockDictionary.inputMap;
const TableColumns = [
    { id: 'model', label: Dict.modelId.label, width: 200, show: true, option:{align: 'left', type: TableColumnType.TEXT} },
    { id: 'inCount', label: Dict.inCount.label, width: 90, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'outCount', label: Dict.outCount.label, width: 90, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'stockNow', label: '재고', width: 90, show: true, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'ioEtc', label: '공급사', width: 250, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} }
];

const ErrorTitle =TermStockDictionary.errorTitle;

export default function TermStockAll() {
	const [termStockRecords, setTermStockRecords] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);
    const [timeGot, setTimeGot] = useState(0);
	const sessionInfo = useReactiveVar(userInfoRepo);

    // ##### Call GraphQL to get List #####
    const [getTermStockList, responseList] = useLazyQuery(LIST_TERM_STOCK_ALL, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });

    useEffect(()=>{
        getTermStockList();
    }, [getTermStockList]);

    const onCompleteGetList = (data, clientOption) => {
        if(data.termStockListSum) {
            setTermStockRecords(data.termStockListSum);
            setTimeGot(new Date().getTime()/1000);
        }
    };

    const onClickReload = () => {
        const now = new Date().getTime()/1000;
        const diff = now - timeGot;
        if(diff < 5) {
            Util.bubbleSnack("5초 이내 다시 읽기 금지!!");
            return;
        }
        getTermStockList();
    };

	const isAdmin = ValueUtil.isEtrace(sessionInfo);
    if(!isAdmin) return <Box>***</Box>

    return (
        <TermStockAllContainer>
            <TermStockListContainer>
                <HeaderBox>
                    <IconButton size={AppWord.SMALL}
                        onClick={onClickReload}
                        color={AppPalette.PrimaryColor}
                    >
                        <ReplayIcon />
                    </IconButton>
                </HeaderBox>
                <FlexyTable
                    name="term_stock_list_sum"
                    uniqueKey="modelId"
                    columns={TableColumns}
                    records={termStockRecords}
                    tools={[]}
                />
            </TermStockListContainer>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </TermStockAllContainer>
    );
}
