// BcdIoDataListForPoi.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button, IconButton, Popover, TextField } from '@mui/material';
import LocalParkingIcon from '@mui/icons-material/LocalParking'; // staying
import AirIcon from '@mui/icons-material/Air'; // Not staying
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import CallIcon from '@mui/icons-material/Call';
import ResponseAlert from '../message/ResponseAlert';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType } from '../../model/AppConst';
import { useLazyQuery } from '@apollo/client';
import { LIST_BCD_IO_DATA_FOR_POI, LIST_OF_POI } from './BcdIoDataGql';
import DurationPicker from '../common/DurationPicker';

const BcdIoDataListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display: 'flex', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding, alignItems: 'center'
});

const ControllerBox = styled(Box)({
    display: 'flex', alignItems: 'center'
});

const TabBox = styled(Box)({
    flexGrow:1,
    textAlign: 'right', display: 'flex', alignItems: 'center', paddingLeft: 20
});

const SelectorBox = styled(Box)({
    display: 'inline-block', overflow:'auto', backgroundColor:'#f5f5f5', border:'1px solid #ccc', borderRadius:5, padding:10
});

const SelectRow = styled(Box)({
    borderBottom: '1px solid #ddd', cursor:'pointer',
    '&:hover': {backgroundColor:'white'}
});

const ItemMainText = styled(Box)({
    display:'inline-block', width:160, overflow:'hidden', padding:5,
});

const ItemSubText = styled(Box)({
    display:'inline-block', maxWidth:300, overflow:'hidden', padding:5, fontSize: '0.9em', color:'#555'
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'arrTime', label: '도착시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'depTime', label: '출발시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'secStay', label: '머문시간', width: 90, show: true, option:{align: 'right', print:(r,i,v)=>{return ValueUtil.formatDuration(v)}} },
    { id: 'staying', label: '방문상태', width: 80, show: true, option:{align: 'center', print:(r,i,v) => {
        if(v) return <><LocalParkingIcon fontSize={AppWord.SMALL} color="primary" style={{verticalAlign:'bottom'}}/> 방문 중</>
        else return <><AirIcon fontSize={AppWord.SMALL} color="disabled" style={{verticalAlign:'bottom'}}/> 떠남</>
    }} },
	{ id: 'driverName', label: '기사', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	{ id: 'driverMobile', label: '휴대전화', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'poiName', label: '지점', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

const EmptyDuration = {from:ValueUtil.getYmdHmsInputText(null, 86400), till:ValueUtil.getYmdHmsInputText(null)};
const NullPoi = {poiName:'지점선택:'};

export default function BcdIoDataListForPoi({
    tabSelector,
	maxHeight,
	maxWidth,
}) {
    const [poi, setPoi] = useState(NullPoi);
    const [poiList, setPoiList] = useState([]);
    const [searchSelector, setSearchSelector] = useState('');
    const [selectorAnchor, setSelectorAnchor] = useState(null);
    const [records, setRecords] = useState([]);
    const [duration, setDuration] = useState(EmptyDuration);
    const [search, setSearch] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getPoiList, responsePoiList] = useLazyQuery(LIST_OF_POI, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetPoiList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '지점목록 조회 오류'})}
    });

    useEffect(()=>{
        getPoiList();
    },[getPoiList]);

    const [getBcdIoDataList, responseList] = useLazyQuery(LIST_BCD_IO_DATA_FOR_POI, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '출발, 도착 기록 조회 오류'})}
    });

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetPoiList = (data, option) => {
        if(data.poiInfoList) {
            if(data.poiInfoList.length>0) {
                let newPoi = data.poiInfoList[0];
                for(var rec of data.poiInfoList) {
                    if(rec.poiId===poi.poiId) {
                        newPoi = {...rec};
                        break;
                    }
                }
                setPoi(newPoi);
            }
            else setPoi(NullPoi);
            setPoiList(data.poiInfoList);
        }
    };

    const onCompleteGetList = (data, clientOption) => {
        if(data.bcdIoDataListForPoi) {
            setRecords(data.bcdIoDataListForPoi);
        }
    };

    const onChangeFrom = (from) => {
        const dur = {...duration};
        dur.from = from;
        setDuration(dur);
    };

    const onChangeTill = (till) => {
        const dur = {...duration};
        dur.till = till;
        setDuration(dur);
    };

    const onClickToGetList = () => {
        if(duration.from && duration.till && poi && poi.poiId)
            getBcdIoDataList({
                variables: {
                    poiId: poi.poiId,
                    timeFrom: duration.from,
                    timeTill: duration.till}
            });
    };

    const onClickSelectPoi = (item) => {
        setPoi(item);
        setSelectorAnchor(null);
    };

    const onCloseSelector = () => {
        setSelectorAnchor(null);
    };

    const onClickOpenSelector = (event) => {if(poiList) setSelectorAnchor(event.currentTarget)};

    const onChangeSearchSelector = (event) => {
        if(event.target.value) {
            setSearchSelector(event.target.value.trim());
        }
        else setSearchSelector('');
    };

    const filterSelector = (item) => {
        if(searchSelector && searchSelector.length>0) {
            if(item.addr) {
                if(item.addr.indexOf(searchSelector) >= 0) return true;
            }
            if(item.tel) {
                if(item.tel.indexOf(searchSelector) >= 0) return true;
            }
            return item.poiName.indexOf(searchSelector) >= 0;
        }
        return true;
    };

    const renderPoiSelector = (height) => {
        const open = Boolean(selectorAnchor);
        return(
            <Popover
                open={open}
                anchorEl={selectorAnchor}
                onClose={onCloseSelector}
                anchorOrigin={{ vertical: AppWord.TOP, horizontal: AppWord.RIGHT, }}
                transformOrigin={{ vertical: AppWord.TOP, horizontal: AppWord.LEFT, }}
            >
                <SelectorBox
                    maxHeight={height}
                >
                    {
                        poiList.length > 5
                        ?
                        <>
                        <TextField size={AppWord.SMALL}
                            onChange={onChangeSearchSelector}
                        />
                        <IconButton size={AppWord.SMALL}
                            onClick={()=>{setSearchSelector('')}}
                        >
                            <ClearIcon size={AppWord.SMALL} />
                        </IconButton>
                        </>
                        :
                        null
                    }
                    {
                        poiList.length===0
                        ? <span>지점이 없습니다</span>
                        :
                        poiList.filter(filterSelector).map((item) => {
                            return(
                                <SelectRow key={item.poiId}
                                    onClick={()=>{onClickSelectPoi(item)}}
                                >
                                    <ItemMainText>{item.poiName}</ItemMainText>
                                    <ItemSubText>
                                        {item.addr} {
                                        item.tel
                                        ? <><CallIcon color='primary' size={AppWord.SMALL} /> {item.tel}</>
                                        : null
                                        }
                                    </ItemSubText>
                                </SelectRow>
                            );
                        })
                    }
                </SelectorBox>
            </Popover>
        );
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	
    const TableHeight = height - clientSize.HeaderToolBoxHeight
        - clientSize.SmallBoxPadding * 2 - 2;

    return(
        <BcdIoDataListContainer>
            <HeaderBox>
                <ControllerBox>
                    <span style={{fontWeight:'bold'}}>{poi.poiName}</span>
                    <IconButton
                        onClick={onClickOpenSelector}
                        color="primary"
                    >
                        <KeyboardArrowDownIcon />
                    </IconButton>
                    {renderPoiSelector(TableHeight-clientSize.SmallBoxPadding * 2)}
                    <DurationPicker
                        from={duration.from} onChangeFrom={onChangeFrom}
                        till={duration.till} onChangeTill={onChangeTill}
                    />
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={!Boolean(duration)}
                        onClick={onClickToGetList}
                        style={{marginLeft:10, marginRight:20}}
                    >
                        조회
                    </Button>
                </ControllerBox>
                <TabBox>
                    {tabSelector}
                </TabBox>
            </HeaderBox>
            <FlexyTable
                name="bcd_io_data_list_for_poi"
                uniqueKey="dataKey"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[]}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </BcdIoDataListContainer>
    );
}

/*

            ////<PanTable
                name="bcd_io_data_list_for_poi"
                uniqueKey="dataKey"
                height={TableHeight} width={width}
                columns={TableColumns}
                records={records}
                search={search}
                tools={[]}
            />

*/