// CustPeopleList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button, IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlexyTable from '../common/flexytable/FlexyTable';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import { CustPeopleDictionary } from './CustPeopleGql';

const LocalTitle = '담당자 정보'; // ###################################

const CustPeopleListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const HeaderControl = styled(Box)({ flexGrow:1, display:'flex', alignItems:'center' });
const ButtonBox = styled(Box)({'&>*': {marginLeft:10}});

const Dict = CustPeopleDictionary.inputMap;
const TableColumns = [
    { id: 'pName', label: Dict.pName.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'roleValue', label: "담당자 역할", width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'telNo', label: Dict.telNo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'faxNo', label: Dict.faxNo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'dept', label: Dict.dept.label, width: 80, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'jobRank', label: Dict.jobRank.label, width: 80, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'roleDetail', label: Dict.roleDetail.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'mobile', label: Dict.mobile.label, width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'email', label: Dict.email.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'messenger', label: Dict.messenger.label, width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'zipCd', label: Dict.zipCd.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'addr', label: Dict.addr.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regTime', label: "등록", width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATE} }
];

export default function CustPeopleList({
	selected,
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
    records,
}) {
    const [search, setSearch] = useState('');
    const clientSize = useClientSize();

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
		if(onClickItem) onClickItem(item);
    };

    return(
        <CustPeopleListContainer>
            <HeaderBox>
                <HeaderControl>
                    <FormControl variant='outlined' size='small'>
                        <InputLabel htmlFor="search-CustPeople-list" style={{backgroundColor:'white'}}>검색</InputLabel>
                        <OutlinedInput id="search-CustPeople-list" onChange={onChangeSearch}
                            value={search}
                            disabled={records.length === 0}
                            size='small'
                            endAdornment={
                                Boolean(search) ?
                                <InputAdornment position={AppWord.END}>
                                    <IconButton
                                    onClick={()=>{setSearch('')}}
                                    size="small"
                                    edge={AppWord.END}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                                :
                                null
                            }
                        />
                    </FormControl>
                </HeaderControl>
                <ButtonBox>
                    <Button
                        onClick={onRequestAdd}
                        variant='contained' color='primary'>
                        <AddIcon fontSize='small' />
                        담당자 추가
                    </Button>
                </ButtonBox>
            </HeaderBox>
            <FlexyTable
                name="cust_people_list"
                uniqueKey="recId"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize='small' />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize='small' />}
                ]}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </CustPeopleListContainer>
    );

}
