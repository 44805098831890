// veh_itin/VehItinGql.js
import { gql } from "@apollo/client";

export const LIST_VEH_ITIN = gql`
query ListVehItin($vehId:Int, $timeFrom: String, $timeTill: String) {
    vehItinList(vehId:$vehId, timeFrom:$timeFrom, timeTill:$timeTill) {
        vehId
        keyTime
        vehPlates
        vehPlatesNow
        timeArrival
        timePrevStop
        timeDeparture
        location
        lon
        lat
        xDist
        yDist
        distKm
        distBetween
        runSec
        speed
        staySec
        custName
        custId
    }
}
`;
