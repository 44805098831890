// RouteDailyBillList.js
import React from 'react';
import FlexyTable from '../common/flexytable/FlexyTable';
import { TableColumnType } from '../../model/AppConst';

const TableColumns = [
    { id: 'custId', label: '업체ID', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 150, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	// { id: 'vehId', label: Dict.vehId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'routeDate', label: 'Month', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.DATE} },
    { id: 'termId', label: '단말기번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'serialNo', label: '시리얼번호', width: 180, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'model', label: '단말기모델', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehPlates', label: '차량번호', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpsTime', label: '최종보고', width: 150, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'openDate', label: '개통일', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.DATE} },
    { id: 'termStateValue', label: '현재상태', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'daysUsed', label: '사용일수', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'positionCount', label: '보고회수', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'billAmount', label: '청구금액', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'payTermValue', label: '구분', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'termStateTime', label: '상태최종변경', width: 150, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'etc', label: '비고', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function RouteDailyBillList({records, search}) {
    return(
        <FlexyTable
            name="term_state_log_list"
            uniqueKey="dataKey"
            columns={TableColumns}
            records={records}
            search={search}
            tools={[]}
        />
    );
}
