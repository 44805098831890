// VehTypeTon.js
import React, {useState, useEffect} from 'react';
import { Box } from '@mui/system';
import { styled  } from '@mui/material/styles';
import { TextField, Button } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';
import VehTypeNode from '../../model/VehTypeNode';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import ExpanTree from '../common/ExpanTree/ExpanTree';
import HelpTopic from '../common/HelpTopic';
import {
    LIST_VEH_TON_INFO, GET_VEH_TON_INFO,
    ADD_VEH_TON_INFO, EDIT_VEH_TON_INFO, REMOVE_VEH_TON_INFO, ADD_VEH_TYPE_TON_SET,
    VehTonInfoDictionary
} from './VehTonInfoGql';
import { GET_VEH_TYPE_INFO, EDIT_VEH_TYPE_INFO, REMOVE_VEH_TYPE_INFO } from './VehTypeInfoGql';
import useClientSize from '../hook/useClientSize';

const BorderCcc = '1px solid #cccccc';

const dcPad = AppNumber.dataContainerPadding;
const VehTypeTonContainer = styled(Box)({
    position:"absolute", top:dcPad, bottom:dcPad, left:dcPad, right:dcPad, display: 'flex',
});

const TitleAndDataBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

const TitleBox = styled(Box)({fontWeight:'bold', padding:10, fontSize: '1.1rem'});
const EditorTitleBox = styled(Box)({
    fontWeight:'bold', padding:10, fontSize: '1.1rem',
    borderBottom: BorderCcc,
    textAlign: 'left'
});

const VehTonBox = styled(Box)({
    flexGrow: 1,
    overflow: 'auto',
    border: BorderCcc,
    padding: 15,
    borderRadius: 10,
    minWidth: 180
});

const WorkBox = styled(Box)({
    flexGrow: 1,
    paddingLeft: 10,
    paddingRight: 10
});

const WorkButtonBox = styled(Box)({
    border: BorderCcc,
    borderRadius: 10,
    marginBottom: 20,
    textAlign: 'center',
});

const WorkButtonTitle = styled(Box)({
    fontWeight: 'bold',
    textAlign: 'left',
    padding: 10, marginBottom: 10,
    backgroundColor: '#eeeeee',
    borderBottom: BorderCcc,
});

const LaButton = styled(Button)({marginRight: 10});

const InfoBox = styled(Box)({padding: 10, fontSize: '0.9rem', color:'#555555'});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
    minHeight: 80, // check해 봄. 74 - padding 10
});

const HelpBox = styled(Box)({
    overflow: 'auto',
    border: BorderCcc,
    padding: 15,
    borderRadius: 10,
    minWidth: 180,
    maxWidth: 400,
});
const InputMapTon = VehTonInfoDictionary.inputMap; // 입력양식 상수, validation
const defaultTonInputData = ValueUtil.defaultValuesFromInputMap(InputMapTon); // 입력양식 상수 중 기초 데이터(default)만 추출.
const TonEditFields = ValueUtil.getFieldsToSubmit(InputMapTon, true);
const InputMapType = VehTonInfoDictionary.inputMapType; // type. no new type sbmit.
const TypeEditFields = ValueUtil.getFieldsToSubmit(InputMapType, true);
const InputMapAll  = VehTonInfoDictionary.inputMapAll; // type + ton
const defaultAllInputData = ValueUtil.defaultValuesFromInputMap(InputMapAll); // type + ton
const ErrorTitle =VehTonInfoDictionary.errorTitle;

// 기능: 신규 차종+톤수 / 차종정보 수정 / 차종 및 하위톤수들 삭제 / 톤수정보 수정 / 톤수정보 추가 / 톤수정보 삭제
const VttWork = {newTypeTon: 1, editType: 2, remType: 3, newTon:11, editTon:12, remTon:13};

export default function VehTypeTon() {
    const [vehTonTreeData, setVehTonTreeData] = useState([]);
    const [editorMode, setEditorMode] = useState(VttWork.newTypeTon); // newTypeTon, editType, newTon, editTon
    const [selected, setSelected] = useState(null);
    const [inputData, setInputData] = useState(defaultAllInputData); // 입력한 데이터.
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize(); // Scroll the tree
    // const responseList = useQuery(GET_VEH_INFO_LIST, {fetchPolicy: "no-cache"});
    const [getVehTypeTonList, responseList] = useLazyQuery(LIST_VEH_TON_INFO, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetTree(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.GetTree})}
	});
    const [getVehTypeInfoItemToEdit, responseItemToEditType] = useLazyQuery(GET_VEH_TYPE_INFO, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetType(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.GetType})}
	});
    const [getVehTonInfoItemToEdit, responseItemToEditTon] = useLazyQuery(GET_VEH_TON_INFO, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetTon(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.GetTon})}
	});
    const [addVehTypeTonSet, responseAddSet] = useMutation( ADD_VEH_TYPE_TON_SET, {
		onCompleted: (data, option) => onCompleteAddTypeTonSet(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.AddTon})}
	} );
    const [editVehTypeInfo, responseEditType] = useMutation( EDIT_VEH_TYPE_INFO, {
		onCompleted: (data, option) => onCompleteEditType(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.EditType})}
	} );
    const [removeVehTypeInfo, responseRemoveType] = useMutation( REMOVE_VEH_TYPE_INFO, {
		onCompleted: (data, option) => onCompleteRemoveType(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.RemoveType})}
	});
    const [addVehTonInfo, responseAddTon] = useMutation( ADD_VEH_TON_INFO, {
		onCompleted: (data, option) => onCompleteAddTon(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.AddTon})}
	} );
    const [editVehTonInfo, responseEditTon] = useMutation( EDIT_VEH_TON_INFO, {
		onCompleted: (data, option) => onCompleteEditTon(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.EditTon})}
	} );
    const [removeVehTonInfo, responseRemoveTon] = useMutation( REMOVE_VEH_TON_INFO, {
		onCompleted: (data, option) => onCompleteRemoveTon(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.RemoveTon})}
	});

    useEffect(() => {
        getVehTypeTonList();
        setInputData(defaultAllInputData);
    }, []);

    const changeInputData = (data) => {
        setInputData(data);
        setChanged(true);
    };

	const resetData = (data) => {
        setInputData(data);
        setChanged(false);
		setInputError({});
		setHasError(false);
	};

    // <<<<<<<<<<< onComplete:: gql query <<<<<<<<<<<<<<<<
    // 차종, 톤수 목록 가져오면 트리로 만들어 준다.
    const onCompleteGetTree = (data, option) => {
        if(data.vehTonInfoList) {
            setVehTonTreeData(VehTypeNode.makeTypeTonTree(data.vehTonInfoList));
        }
    };

    // 단일 차종 정보 조회 결과 처리(편집용)
    const onCompleteGetType = (data, option) => {
        if(data.vehTypeInfoItem) {
            const item = {};
            for(const field of TypeEditFields) item[field] = data.vehTypeInfoItem[field];
            resetData(item);
            setEditorMode(VttWork.editType);
        }
    };

    // 단일 톤수 정보 조회 결과 처리(편집용)
    const onCompleteGetTon = (data, option) => {
        if(data.vehTonInfoItem) {
            const item = {};
            for(const field of TonEditFields) item[field] = data.vehTonInfoItem[field];
            resetData(item);
            setEditorMode(VttWork.editTon);
        }
    };

    // 추가, 수정, 삭제 등의 작업이 성공하면 에디터 상태를 초기화하고 차종+톤수 트리를 새로 가져와서 표시한다. 선택도 없앰.
    const resetAfterMutation = () => {
        resetData(defaultAllInputData);
        setEditorMode(VttWork.newTypeTon);
        setSelected(null);
        getVehTypeTonList();
    };

    // 차종, 톤수 일괄 추가 완료 결과 처리.
    const onCompleteAddTypeTonSet  = (data, clientOption) => {
        if(data.vehTonInfoAddSet.ok) resetAfterMutation();
        else setResponseAlert({open:true, resultData: data.vehTonInfoAddSet, title: ErrorTitle.AddTypeTon});
    };

    // 차종 정보 수정 완료 결과 처리.
    const onCompleteEditType = (data, clientOption) => {
        if(data.vehTypeInfoEdit.ok) resetAfterMutation();
        else setResponseAlert({open:true, resultData: data.vehTypeInfoEdit, title: ErrorTitle.EditType});
    };

    // 차종 및 하위 톤수 삭제 완료 결과 처리.
    const onCompleteRemoveType = (data, clientOption) => {
        if(data.vehTypeInfoRemove.ok) resetAfterMutation();
        else setResponseAlert({open:true, resultData: data.vehTypeInfoRemove, title: ErrorTitle.RemoveType});
    };

    // 톤수 추가 완료 결과 처리.
    const onCompleteAddTon = (data, clientOption) => {
        if(data.vehTonInfoAdd.ok) resetAfterMutation();
        else setResponseAlert({open:true, resultData: data.vehTonInfoAdd, title: ErrorTitle.AddTon});
    };

    // 톤수 수정 완료 결과 처리.
    const onCompleteEditTon = (data, clientOption) => {
        if(data.vehTonInfoEdit.ok) resetAfterMutation();
        else setResponseAlert({open:true, resultData: data.vehTonInfoEdit, title: ErrorTitle.EditTon});
    };

    // 톤수 삭제 완료 결과 처리.
    const onCompleteRemoveTon = (data, clientOption) => {
        if(data.vehTonInfoRemove.ok) resetAfterMutation();
        else setResponseAlert({open:true, resultData: data.vehTonInfoRemove, title: ErrorTitle.RemoveTon});
    };

    // >>>>>>>>>>>>>>>>>. Callbacks >>>>>>>>>>>>>>
    // 차종 또는 톤수를 클릭하면 선택되었음을 표시하기 위해 저장한다.
    const onTypeTonClicked = (node) => {
        if(node.vehTonId) setSelected({isTon: true, node: node});
        else setSelected({isTon:false, node: node});
    };

    // 입력 필드(텍스트) 변경 이벤트 대응.
    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const iMap = editorMode === VttWork.newTypeTon ? InputMapAll : editorMode === VttWork.editType ? InputMapType : InputMapTon;

        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, iMap, inputData, inputError);

        changeInputData(newData);
        setInputError(newError);
        setHasError(hasError);
    };

    // 차종을 수정하고 싶다오.
    const onClickEditType = () => {
        // if(responseEdit) responseEdit.reset();
        if(selected) {
            if(!selected.isTon) {
                const data = selected.node;
                getVehTypeInfoItemToEdit({variables: {vehTypeId: data.vehTypeId}});
            }
        }
    };

    // 톤수를 생성하겠소.
    const onClickAddTon = () => {
        if(selected) {
            if(!selected.isTon) {
                const data = {...defaultTonInputData};
                data.vehTypeId = selected.node.vehTypeId;
                resetData(data);
                setEditorMode(VttWork.newTon);
            }
        }
    };

    // 톤수를 수정하고 싶소.
    const onClickEditTon = () => {
        // if(responseEdit) responseEdit.reset();
        if(selected) {
            if(selected.isTon) {
                const data = selected.node;
                getVehTonInfoItemToEdit({variables: {vehTonId: data.vehTonId}});
            }
        }
    };

    // 차종, 톤수 일괄 추가.
    const onClickSaveTypeTonSet = () => {
        const typeTonSet = ValueUtil.refineToSubmit(ValueUtil.getDataToSubmit(inputData, InputMapAll, false)); // false for isEdit
        addVehTypeTonSet({variables: {vehTonInfo: typeTonSet}});
    };

    // 차종 수정하겠소.
    const onClickSaveType = () => {
        const typeInfo = ValueUtil.refineToSubmit(ValueUtil.getDataToSubmit(inputData, InputMapType, true)); // true for isEdit
        editVehTypeInfo({variables: {vehTypeInfo: typeInfo}});
    };

    // 차종과 하위 톤수(들) 전체를 삭제해 버리자!
    const onClickRemoveType = () => {
        if(selected) {
            if(!selected.isTon) {
                setPromptToConfirm({
                    data: selected.node,
                    title: '차종 정보 삭제',
                    messages: [
                        '선택된 ' + selected.node.vehTypeName + ' 차종을 삭제하시겠습니까?',
                        '해당 차종의 정보 및 부수하는 톤수정보는 즉시, 완전히 삭제됩니다.',
                        '해당 차종을 사용하는 차량이 있을 경우 삭제되지 않으니 안심하세요.',
                        '삭제된 정보는 복구할 수 없습니다',
                        '정보 삭제를 진행하시겠습니까?'
                    ],
                    callback: (data) => {
                        setPromptToConfirm(null);
                        if(data) {
                            if(responseRemoveType) responseRemoveType.reset();
                            const param = {variables:{vehTypeInfo:{vehTypeId: data.vehTypeId}}};
                            removeVehTypeInfo(param);
                        }
                    }
                });
                return;
            }
        }
    };

    // 톤수 추가 또는 수정.
    const onClickSaveTon = () => {
        const isEdit = editorMode === VttWork.editTon;
        const tonInfo = ValueUtil.refineToSubmit(ValueUtil.getDataToSubmit(inputData, InputMapTon, isEdit));
        if(isEdit) editVehTonInfo({variables: {vehTonInfo: tonInfo}});
        else addVehTonInfo({variables: {vehTonInfo: tonInfo}});
    };

    // 일괄, 차종, 톤수 각각의 편집을 취소하는 경우 모두 적용.
    const onClickCancelEdit = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 편집 취소',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData(defaultAllInputData);
                        setEditorMode(VttWork.newTypeTon);
                    }
                }
            });
        }
        else {
            resetData(defaultAllInputData);
            setEditorMode(VttWork.newTypeTon);
        }
    };

    const onClickRemoveTon = () => {
        if(selected) {
            if(selected.isTon) {
                setPromptToConfirm({
                    data: selected.node,
                    title: '톤수 정보 삭제',
                    messages: [
                        '선택된 ' + selected.node.ton + ' 톤수정보를 삭제하시겠습니까?',
                        '해당 톤수를 사용하는 차량이 있을 경우 삭제되지 않으니 안심하세요.',
                        '상위 차종에 다른 톤수가 없으면 그 차종 정보도 같이 삭제됩니다.',
                        '삭제된 정보는 복구할 수 없습니다',
                        '정보 삭제를 진행하시겠습니까?'
                    ],
                    callback: (data) => {
                        setPromptToConfirm(null);
                        if(data) {
                            if(responseRemoveTon) responseRemoveTon.reset();
                            const param = {variables:{vehTonInfo:{vehTonId: data.vehTonId}}};
                            removeVehTonInfo(param);
                        }
                    }
                });
                return;
            }
        }
    };

    if(ValueUtil.hasAnyAuthError(
        responseList,
        responseItemToEditTon,
        responseItemToEditType,
        responseAddSet,
        responseEditType,
        responseRemoveType,
        responseAddTon,
        responseEditTon,
        responseRemoveTon
        )) userInfoRepo(NoUser);


    const renderTextFieldBox = (dict, value, error, runOnChange) => {
        const style = {marginRight: 20};
        if( dict.type === 'color' ) style.width = 110;
        else if(dict.width > 100) style.width = dict.width;
        return(
            <TextField id={dict.id} style={style}
                size="small"
                type={dict.type}
                label={dict.label}
                required={dict.required}
                value={value || ''}
                error={error}
                helperText={dict.help}
                onChange={runOnChange} />

        );
    };

    const getEditorTitle = () => {
        switch(editorMode) {
            case VttWork.newTon : return '신규 톤수정보';
            case VttWork.editTon : return '톤수정보 수정';
            case VttWork.editType : return '차종정보 수정';
            case VttWork.newTypeTon : return '차종, 톤수 일괄 생성';
            default: return '-';
        }
    };
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const title = getEditorTitle();
        return(
            <Box>
                <EditorTitleBox>
                    {title}
                </EditorTitleBox>
                {
                    editorMode === VttWork.newTon || editorMode === VttWork.editTon ? null :
                    <FieldBox>
                        {renderTextFieldBox(InputMapAll.vehTypeName, inputData.vehTypeName,
                            inputError.vehTypeName, onChangeTextData) /* 차종 */}
                        {renderTextFieldBox(InputMapAll.vehTypeDesc, inputData.vehTypeDesc,
                            inputError.vehTypeDesc, onChangeTextData) /* 차종설명 */}
                    </FieldBox>
                }
                {
                    editorMode === VttWork.editType ? null :
                    <>
                    <FieldBox>
                        {renderTextFieldBox(InputMapAll.ton, inputData.ton,
                            inputError.ton, onChangeTextData) /* 톤수 */}
                        {renderTextFieldBox(InputMapAll.tonDesc, inputData.tonDesc,
                            inputError.tonDesc, onChangeTextData) /* 톤수 설명 */}
                    </FieldBox>
                    <FieldBox>
                        {renderTextFieldBox(InputMapAll.operFontColor, inputData.operFontColor,
                            inputError.operFontColor, onChangeTextData) /* 운행중 전경색 */}
                        {renderTextFieldBox(InputMapAll.operBackColor, inputData.operBackColor,
                            inputError.operBackColor, onChangeTextData) /* 운행중 배경색 */}
                        {renderTextFieldBox(InputMapAll.noOperFontColor, inputData.noOperFontColor,
                            inputError.noOperFontColor, onChangeTextData) /* 비운행중 전경색 */}
                        {renderTextFieldBox(InputMapAll.noOperBackColor, inputData.noOperBackColor,
                            inputError.noOperBackColor, onChangeTextData) /* 비운행중 배경색 */}
                    </FieldBox>
                    </>
                }
                <Box style={{marginTop:10}}>
                    {
                        editorMode === VttWork.newTon || editorMode === VttWork.editTon
                        ?
                        <>
                            <LaButton variant='contained' disabled={hasError || !changed}
                                onClick={onClickSaveTon}
                            >
                                톤수 정보 저장
                            </LaButton>
                            <LaButton color='warning' variant='contained'
                                onClick={onClickCancelEdit}
                            >
                                취소
                            </LaButton>
                        </>
                        :
                        editorMode === VttWork.editType
                        ?
                        <>
                            <LaButton variant='contained' disabled={hasError || !changed}
                                onClick={onClickSaveType}
                            >
                                차종 정보 저장
                            </LaButton>
                            <LaButton color='warning' variant='contained'
                                onClick={onClickCancelEdit}
                            >
                                취소
                            </LaButton>
                        </>
                        :
                        <>
                            <LaButton variant='contained' disabled={hasError || !changed}
                                onClick={onClickSaveTypeTonSet}
                            >
                                차종+톤수 정보 저장
                            </LaButton>
                            <LaButton color='warning' variant='contained'
                                onClick={onClickCancelEdit}
                            >
                                취소
                            </LaButton>
                        </>
                    }
                </Box>
            </Box>
        );
    };

    const renderButtonBoxForSelection = () => {
        if(selected) {
            if(selected.isTon) {
                return(
                    <WorkButtonBox>
                        <WorkButtonTitle>
                            <span>선택된 톤수 정보: </span>
                            <span style={{color:'blue'}}>{selected.node.ton}</span>
                        </WorkButtonTitle>
                        <LaButton color='inherit' variant='contained'
                            disabled={changed || editorMode === VttWork.editTon}
                            onClick={onClickEditTon}
                        >
                            톤수 정보 수정
                        </LaButton>
                        <LaButton color='secondary' variant='contained'
                            disabled={changed || editorMode === VttWork.editTon}
                            onClick={onClickRemoveTon}
                        >
                            톤수 정보 삭제
                        </LaButton>
                        {
                            changed || editorMode === VttWork.editTon ?
                            <InfoBox>아래 작업을 취소(버튼)하거나 저장을 완료해야 위 버튼이 활성화 됩니다.</InfoBox>
                            :
                            <InfoBox>
                                삭제 시도 시 해당 톤수를 사용하는 차량이 있으면 삭제하지 않고 오류 메시지를 표시합니다.
                            </InfoBox>
                        }
                    </WorkButtonBox>
                )
            }
            else {
                return(
                    <WorkButtonBox>
                        <WorkButtonTitle>
                            <span>선택된 차종 정보: </span>
                            <span style={{color:'blue'}}>{selected.node.vehTypeName}</span>
                        </WorkButtonTitle>
                        <LaButton color='inherit' variant='contained'
                            disabled={changed || editorMode === VttWork.editType}
                            onClick={onClickEditType}
                        >
                            차종 정보 수정
                        </LaButton>
                        <LaButton color='secondary' variant='contained'
                            disabled={changed || editorMode === VttWork.editType}
                            onClick={onClickRemoveType}
                        >
                            차종 및 하위 톤수 전체 삭제
                        </LaButton>
                        <LaButton color='primary' variant='contained'
                            disabled={changed || editorMode === VttWork.editType}
                            onClick={onClickAddTon}
                        >
                            차종 하위에 톤수 추가
                        </LaButton>
                        {
                            changed || editorMode === VttWork.editType ?
                            <InfoBox>아래 작업을 취소(버튼)하거나 저장을 완료해야 위 버튼이 활성화 됩니다.</InfoBox>
                            :
                            <InfoBox>
                                삭제 시도 시 해당 차종을 사용하는 차량이 있으면 삭제하지 않고 오류 메시지를 표시합니다.
                            </InfoBox>
                        }
                    </WorkButtonBox>
                )
            }
        }
        return null;
    };

    return (
        <VehTypeTonContainer>
            <TitleAndDataBox>
                <TitleBox>차종, 톤수</TitleBox>
                <VehTonBox>
                    <ExpanTree
                        data={vehTonTreeData}
                        onLeafClicked={onTypeTonClicked}
                        disabled={changed || editorMode === VttWork.editTon || editorMode === VttWork.editType}
                    />
                </VehTonBox>
            </TitleAndDataBox>
            <WorkBox>
                {renderButtonBoxForSelection()}
                {renderEditor()}
            </WorkBox>
            <HelpBox>
                {
                    vehTonTreeData.length === 0 ?
                    <HelpTopic
                        title="차종, 톤수 정보가 없습니다."
                        content="차종, 톤수가 있어야 차량정보를 등록할 수 있습니다. 차종과 톤수를 일괄 등록해 주세요."
                    />
                    :
                    null
                }
                <HelpTopic
                    title="차종과 톤수"
                    content={
                        <ul>
                            <li>차종은 고객님 자체적으로 구분한 차량 종류입니다. (예: 윙바디)</li>
                            <li>톤수는 차종에 속하는 톤수를 말합니다. 반드시 숫자로 표시하지 않아도 됩니다. (예: 3톤)</li>
                            <li>여러 개의 차종을 생성할 수 있고, 한 차종 아래에 여러 개의 톤수를 생성할 수 있습니다.</li>
                            <li>톤수의 색상은 지도에 차량을 표시할 때 차량번호를 표시하는 색상을 말합니다.</li>
                            <li>차종, 톤수는 차량의 특성 기준으로 분류하는 것이 일반적이지만, 필요한 경우 업무, 운행지역 등에 따라 작성, 이용할 수도 있습니다.
                                단, 그러한 필요성을 위해서는 차량그룹을 이용할 수도 있으므로, 어떤 방식으로 사용할지는 고객의 필요에 따라 결정하면 됩니다.
                            </li>
                        </ul>
                    }
                />
                {
                    selected
                    ?
                    <HelpTopic
                        title="차종, 톤수 삭제 시 알아두세요"
                        content={
                            <ul>
                                <li>차종이나 톤수를 삭제할 때 해당 차량 또는 톤수를 사용하는 차량이 있으면 삭제되지 않습니다.</li>
                                <li>차종을 삭제하면 하위의 모든 톤수도 삭제됩니다.</li>
                                <li>톤수를 삭제할 때 상위 차종에 다른 톤수가 없다면 차종까지 함께 삭제됩니다. 만일 해당 차종에 다른 톤수를
                                추가할 예정이라면 먼저 추가하고 그 다음에 삭제처리를 하세요.
                                </li>
                            </ul>
                        }
                    />
                    :
                    <HelpTopic
                        title="차종, 톤수를 선택해서 작업합니다."
                        content="차종 또는 톤수를 클릭한 후 그에 대한 작업을 할 수 있습니다. 차종과 톤수를 통시에 추가할 때에는 선택하지 않아도 됩니다."
                    />
                }
            </HelpBox>
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehTypeTonContainer>
    )
}
