// view_routine_distrib/ViewRoutineDistribGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const ViewRoutineDistribDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        vehId : {id:'vehId', toAdd: true, toEdit: true,},
        poiId : {id:'poiId', toAdd: true, toEdit: true},
        notiYn : {id:'notiYn', label: '출발알림', toAdd: true, toEdit: true},
        notiFrom : { id:'notiFrom', label: '출발알림허용', toAdd: true, toEdit: true, check: (value) => {return value >= 0 && value <= 23}},
        notiTill : { id:'notiTill', label: '출발알림허용', toAdd: true, toEdit: true, check: (value) => {return value >= 0 && value <= 23}},
        destCsv : {id:'destCsv', toAdd: true, toEdit: true},
        notiDestFrom : { id:'notiDestFrom', label: '도착알림허용', toAdd: true, toEdit: true, check: (value) => {return value >= 0 && value <= 23}},
        notiDestTill : { id:'notiDestTill', label: '도착알림허용', toAdd: true, toEdit: true, check: (value) => {return value >= 0 && value <= 23}},
        destinMemo : {
            id: 'destinMemo', label: '메모', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '500자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,2000)},
        },
    },
    errorTitle: {
        Add: '정기배송노선 설정 오류',
        Remove: '정기배송노선 삭제 오류',
        List: '정기배송노선 조회 오류',
        GetLog: '정기배송노선 과거기록 조회 오류'
    }
};

const VIEW_ROUTINE_DISTRIB_LIST_PART = gql`
fragment ViewRoutineDistribListPart on ViewRoutineDistrib {
    custId
    recId
    vehId
    vehPlates
    driverName
    driverMobile
    departOrDest
    userId
    arrivalTime
    departTime
    notiFrom
    notiTill
    poiId
    poiName
    notiYn
    destinMemo
    countSmsSent
    regTime
}
`;


export const LIST_VIEW_ROUTINE_DISTRIB = gql`
${VIEW_ROUTINE_DISTRIB_LIST_PART}
query ListViewRoutineDistrib {
    viewRoutineDistribList {
        ...ViewRoutineDistribListPart
    }
}
`;

export const LIST_VIEW_ROUTINE_DIST_VEH_AND_POI = gql`
${VIEW_ROUTINE_DISTRIB_LIST_PART}
query ListThree($custId:String) {
    viewRoutineDistribList {
        ...ViewRoutineDistribListPart
    }
    poiInfoList {
        poiId
        poiName
        poiType
        typeName
        custPoiCode
        addr
        tel
        useBcdYn
        bcdMapped
    }
    vehInfoList(custId:$custId) {
        vehId
        custId
        custName
        vehPlates
        vehAlias
        devId
        vehTypeTon
        driverName
        driverMobile
        useYn
        gpsTime
    }
}
`;


export const SET_VIEW_ROUTINE_DISTRIB = gql`
${VIEW_ROUTINE_DISTRIB_LIST_PART}
mutation SetupViewRoutineDistrib($viewRoutineDistrib:InputViewRoutineDistribSet!) {
    viewRoutineDistribSet(viewRoutineDistrib: $viewRoutineDistrib) {
        ok
        message
        list {
            ...ViewRoutineDistribListPart
        }
    }
}
`;


export const REMOVE_VIEW_ROUTINE_DISTRIB = gql`
${VIEW_ROUTINE_DISTRIB_LIST_PART}
mutation RemoveViewRoutineDistrib($viewRoutineDistrib:InputViewRoutineDistribRemove!) {
    viewRoutineDistribRemove(viewRoutineDistrib: $viewRoutineDistrib) {
        ok
        message
        list {
            ...ViewRoutineDistribListPart
        }
    }
}
`;

const ROUTINE_DISTRIB_LOG_LIST_PART = gql`
fragment RoutineDistribLogListPart on RoutineDistribLog {
    distribNid
    logTime
    custId
    recId
    vehId
    vehPlates
    poiId
    centerName
    centerAta
    centerAtd
    lastAta
    destinCsv
    userId
}
`;

export const LIST_ROUTINE_DISTRIB_LOG_FOR_VEH = gql`
${ROUTINE_DISTRIB_LOG_LIST_PART}
query ListRoutineDistribLogForVeh($vehId:Int) {
    routineDistribLogListForVeh(vehId:$vehId) {
        ...RoutineDistribLogListPart
    }
}
`;

export const LIST_ROUTINE_DISTRIB_LOG_FOR_POI = gql`
${ROUTINE_DISTRIB_LOG_LIST_PART}
query ListRoutineDistribLogForPoi($poiId:Int) {
    routineDistribLogListForPoi(poiId:$poiId) {
        ...RoutineDistribLogListPart
    }
}
`;
