//HelpPop.js
import React, { useState } from 'react';
import { Button, IconButton, Popover } from '@mui/material';
import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import { AppWord, AppPalette } from '../../model/AppConst';

const DefaultAnchorOrigin={
    vertical: 'top',
    horizontal: 'left',
};
const DefaultTransformOrigin={
    vertical: 'top',
    horizontal: 'left',
};

export default function HelpPop({
    id,
    title,
    content,
    anchorOrigin,
    transformOrigin
}) {
    const [anchorElement, setAnchorElement] = useState(null);

    const aOri = anchorOrigin || DefaultAnchorOrigin;
    const tOri = transformOrigin || DefaultTransformOrigin;
    
    const renderIcon = () => {
        if(title)
            return(
                <Button size={AppWord.SMALL}
                    onClick={(e)=>{setAnchorElement(e.currentTarget)}}>
                    <HelpIcon fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor}/>{title}
                </Button>
            );
        else
            return(
                <IconButton size={AppWord.SMALL} color={AppPalette.PrimaryColor}
                    onClick={(e)=>{setAnchorElement(e.currentTarget)}}>
                    <HelpIcon fontSize={AppWord.SMALL}/>
                </IconButton>
            );
    };

    const popId = id || 'popover-id' + (new Date()).getTime();

    const open = Boolean(anchorElement);
    const idForOpen = open ? popId : undefined;

    return (
        <>
            {renderIcon()}
            <Popover
                id={idForOpen}
                open={open}
                onClose={()=>{setAnchorElement(null)}}
                anchorEl={anchorElement}
                anchorOrigin={aOri}
                transformOrigin={tOri}
            >
                {content}
            </Popover>
        </>
    )
}
