// SweeperStatGql.js
import { gql } from "@apollo/client";

export const LIST_SWEEPER_STAT = gql`
query ListSweeperStat($routeDate: String!) {
    sweeperStatList(routeDate:$routeDate) {
        vehId
        custId
        routeDate
        vehPlates
        vehAlias
        driverName
        driverMobile
        totalDist
        secondsRun
        hoursRun
        runStatus
        avgSpeedRun
        veh01Cd
        subComName
        veh02Cd
        vehJob
    }
}
`;

