// PlaceTree.js
import RBush from "rbush";
//import { MapValue } from "../../model/AppConst";

function llBoxToRTreeBox(box) {
    return {
        minX: box.west,
        maxX: box.east,
        minY: box.south,
        maxY: box.north
    };
}

function diffOfThree(one, two, thr) {
    const three = [one, two, thr].sort((a, b) => a-b);
    return three[2] - three[0];
}

function compareDist(a, b) {
    const ddLatA = a.from.lat - a.to.lat;
    const ddLonA = a.from.lon - a.to.lon;
    const ddLatB = b.from.lat - b.to.lat;
    const ddLonB = b.from.lon - b.to.lon;
    return (ddLatA * ddLatA + ddLonA * ddLonA) - (ddLatB * ddLatB + ddLonB * ddLonB);
}

class PoiNode {
    poiId = null;
    lat = 0; // of center
    lon = 0; // of center
    minX = 0;
    maxX = 0;
    minY = 0;
    maxY = 0;

    constructor(poi) {
        this.poiId = poi.poiId;
        this.lat = poi.lat;
        this.lon = poi.lon;
        this.minX = poi.lon;
        this.minY = poi.lat;
        this.maxX = poi.lon;
        this.maxY = poi.lat;
        this.poi = poi;
    }

    getBoundary = () => {
        return {north:this.maxY, east: this.maxX, south: this.minY, west: this.minX};
    }

    isPrintable = (level) => {
        return this.poi.viewLevel <= level;
    };
}

export class PoiCluster extends PoiNode {
    clusterId = null;
    poiRecords = [];
    isCluster = true;

    sumLat = 0;
    sumLon = 0;
    _cnt = 0;
    /**
     * 주어진 PoiNode 객체를 요소로 삼는 클러스터 생성
     * @param {PoiNode} poi PoiNode 객체
     */
    constructor(poi) {
        super(poi);
        this.clusterId = poi.poiId;
        this.poiRecords = [poi];
        this.sumLat = poi.lat;
        this.sumLon = poi.lon;
        this._cnt = 1;
    }

    isPrintable = () => {return true;}; // always

    //renewLat = () => {this.lat = (this.maxY+this.minY)/2;};
    //renewLon = () => {this.lon = (this.maxX+this.minX)/2;};

    /**
     * 새로운 지점이 이 클러스터에 포함될 수 있는지 계산하여 참, 거짓을 반환
     * @param {object} poi PoiNode object
     * @param {number} geoWidth 클러스터가 차지할 수 있는 영역의 현재 레벨에서의 동서 경도차
     * @param {number} geoHeight 클러스터가 현재 레벨에서 차지할 수 있는 영역의 남북 위도차
     */
    isMergeable = (poi, geoWidth, geoHeight) => {
        const dLat = diffOfThree(poi.lat, this.minY, this.maxY);
        const dLon = diffOfThree(poi.lon, this.minX, this.maxX);
        return geoWidth >= dLon && geoHeight >= dLat;
    };

    /**
     * 클러스터에 지점을 추가하고 중심 위치를 개정함.
     * @param {object} poi 이 클러스터에 추가할 PoiNode 객체
     */
    add = (poi) => {
        if(this.maxY < poi.lat) {this.maxY = poi.lat;}
        else if(this.minY > poi.lat) {this.minY = poi.lat;}
        if(this.minX > poi.lon) {this.minX = poi.lon;}
        else if(this.maxX < poi.lon) {this.maxX = poi.lon;}
        this.poiRecords.push(poi);
        this._cnt++;
        this.sumLat += poi.lat;
        this.sumLon += poi.lon;
        this.lat = this.sumLat / this._cnt;
        this.lon = this.sumLon / this._cnt;
    };

    clear = () => {
        this.poiRecords = [];
        this.poiId = this.clusterId = null;
        this.lat = this.lon = this.maxY = this.minY = this.maxX = this.minX = 0;
    };

    size = () => this.poiRecords.length;
    getFirst = () => this.poiRecords.length > 0 ? this.poiRecords[0] : undefined;
    getPois = (count) => this.poiRecords.slice(0, count).map(node=>node.poi);
}

/**
 * 지점 데이터 저장, 조회를 위한 PlaceTree.
 * Cluster 관리에도 사용.
 */
export default class PlaceTree {
    rtree = null;
    mapShell = null;

    count = 0;
    north = null;
    south = null;
    east = null;
    west = null;
    width = 0;
    height = 0;

    forCluster = false;

    constructor(mapShell, boundary) {
        this.rtree = new RBush();
        this.count = 0;

        this.mapShell = mapShell;
        this.forCluster = !Boolean(boundary);
        let rect = this.forCluster ? mapShell.getBoundary() : boundary;

        this.south = rect.south;
        this.west = rect.west;
        this.north = rect.north; // rect.south + rect.height;
        this.east  = rect.east; // rect.east + rect.width;
        this.width = rect.east - rect.west;
        this.height = rect.north - rect.south;
    }

    addNode = (poiOrCluster) => {
        if(poiOrCluster.minX) {
            this.rtree.insert(poiOrCluster); // PoiCluster or PoiNode
        }
        else {
            const node = new PoiNode(poiOrCluster); // object from server (in list)
            this.rtree.insert(node);
        }
        this.count++;
    };

    /**
     * 차량위치 또는 지점정보를 일괄 등록함.
     * @param {Array} records - POI or VEH_POS 데이터
     */
    setPlaceRecords = (records) => {
        for(const pobj of records) this.addNode(pobj);
    };

    clear = () => {
        this.rtree = new RBush();
        this.count = 0;

        this.north = null;
        this.south = null;
        this.east = null;
        this.west = null;
        this.width = 0;
        this.height = 0;
    };

    /**
     * 주어진 영역 내에 포함되는 모든 차량을 찾아서 배열로 반환.
     * @param {Object} box - 보이는 지도 영역
     * @returns 주어진 영역 내에 포함되는 모든 차량의 배열. PoiNode 아닌 그 안에 들어있는 데이터.
     */
    getVehiclesIn = (box) => {
        return this.rtree.search(llBoxToRTreeBox(box)).map((node)=>node.poi).sort((a,b)=>b.lat-a.lat);
    };

    /**
     * 주어진 사각형 내에 포함되는 모든 지점 또는 클러스터 목록을 조회.
     * @param {Object} box - 보이는 지도 영역
     * @param {number} zoomLevel - 현재 지도 축적 수전.
     * @returns 지점(PoiNide) 또는 클러스터(PoiCluster) 목록
     */
    getPoisIn = (box, zoomLevel) => {
        const result = this.rtree.search(llBoxToRTreeBox(box)).filter((node)=>node.isPrintable(zoomLevel));
        return result;
    };

    /**
     * 가장 가까운 지점 또는 클러스터 목록을 거리 순으로 조회. 주어진 너비와 높이를 사용하여 overlap되는 지점 또는 클러스터를 찾는다.
     * 반환된 것을 실제 사용가능한 것인지 먼저 체크한 후 사용해야 한다.
     * @param {Object} pos - any object having lat, lon
     * @param {number} geoWidth  - 너비를 경도차로 계산한 값.
     * @param {number} geoHeight  - 높이를 위도차로 계산한 값.
     * @returns 가장 가까운 지점 또는 클러스터 목록을 거리 순으로 반환
     */
    getNearests = (pos, geoWidth, geoHeight) => {
        const locArray = this.getPoisIn({west: pos.lon-geoWidth, east: pos.lon + geoWidth, north: pos.lat + geoHeight, south: pos.lat - geoHeight}, this.mapShell.getZoom());
        if(locArray.length <= 1) return locArray;
        else {
            return locArray.sort((a, b) => compareDist({from:pos, to:a}, {from:pos, to:b}));
        }
    };

    /**
     * 모든 지점(또는 클러스터) 반환.
     */
    getAll = () => this.rtree.all();

    size = () => this.count;

    /**
     * 클러스터용 트리에서 사용하며, 가장 가까운 클러스터를 찾아서 조건이 맞으면 거기에 추가하고, 그렇지 않으면 새로운 클러스터를 만들어 트리에 넣는다.
     * @param {object} poi - PoiNode object
     * @param {number} geoWidth  - 클러스터 너비를 경도차로 계산한 값.
     * @param {number} geoHeight  - 클러스터 높이를 위도차로 계산한 값.
     */
    _addPoiInCluster = (poi, geoWidth, geoHeight) => {
        const clusters = this.getNearests(poi, geoWidth, geoHeight);
        for(const c of clusters) {
            if(c.isMergeable(poi, geoWidth, geoHeight)) {
                c.add(poi);
                return this;
            }
        }
        const cluster = new PoiCluster(poi);
        this.addNode(cluster);
        return this;
    };

    /**
     * 주어진 너비와 높이를 가지는 클러스터 데이터를 만들어 r트리에 저장함. (클러스터 아이콘을 만드는 것이 아님)
     * @param {Array} poiNodes - 클러스터로 만들 PoiNode 객체의 배열.
     * @param {number} geoWidth - 클러스터 너비를 경도차로 계산한 값.
     * @param {number} geoHeight - 클러스터 높이를 위도차로 계산한 값.
     * @returns 만들어진 클러스터 트리(PlaceTree)
     */
    buildClusters = (poiNodes, geoWidth, geoHeight) => {
        if(!this.forCluster) return null;
        for(const node of poiNodes) {
            this._addPoiInCluster(node, geoWidth, geoHeight);
        }
        return this;
    };


    // 지도 일부를 임의의 사각형으로 정의하고 그 안에 몇개의 지점이 있는지 테스트해 보기.
    testPoiInBound = () => {
    };
}

// ##############################################################################################################

