// RegulationReport.js
import React, {useEffect, useState} from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery } from '@apollo/client';
import { AppObject, AppNumber, AppWord, AppPalette } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';
import {
    GET_POS_REPORT_LOG, GET_POS_REPORT_NOTI_LOG,
    GET_POS_DOWNLOAD_LOG, GET_SERVICE_LOG,
    GET_POS_USAGE_LOG, GET_SESSION_LOG
} from './RegulationReportGql';
import RegulationMenu, {ReportMenuKey} from './RegulationMenu';
import PosReportNotiLog from './PosReportNotiLog';
import PosReportLog from './PosReportLog';
import PosDownloadLog from './PosDownloadLog';
import RegServiceLog from './RegServiceLog';
import PosUsageLog from './PosUsageLog';
import RegSessionLog from './RegSessionLog';

const dcPad = AppNumber.dataContainerPadding;
const ReportContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});

export default function RegulationReport() {
    const [menu, setMenu] = useState({});
    const [responseAlert, setResponseAlert] = useState(null);
    const [posReportLogList, setPosReportLogList] = useState([]);
    const [posReportNotiLogList, setPosReportNotiLogList] = useState([]);
    const [posDownloadLog, setPosDownloadLog] = useState([]);
    const [serviceLog, setServiceLog] = useState([]);
    const [posUsageLog, setPosUsageLog] = useState([]);
    const [sessionLog, setSessionLog] = useState([]);

    useEffect(()=>{
        if(!menu.key) {
            setMenu(ReportMenuKey.posReportLog);
        }
    }, [menu, setMenu]);

    // ##### Call GraphQL to get List #####
    const [getPosReportLog, resPosReportLog] = useLazyQuery(GET_POS_REPORT_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {console.log("comp a"); if(data.getPosReportLog) setPosReportLogList(data.getPosReportLog);},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ReportMenuKey.posReportLog.label + " 조회 오류"})}
    });
    const [getPosReportNotiLog, resPosReportNotiLog] = useLazyQuery(GET_POS_REPORT_NOTI_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {console.log("comp b"); if(data.getPosReportNotiLog) setPosReportNotiLogList(data.getPosReportNotiLog)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ReportMenuKey.posReportNotiLog.label + " 조회 오류"})}
    });
    const [getPosDownloadLog, resPosDownloadLog] = useLazyQuery(GET_POS_DOWNLOAD_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {console.log("comp c"); if(data.getPosDownloadLog) setPosDownloadLog(data.getPosDownloadLog)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ReportMenuKey.posDownloadLog.label + " 조회 오류"})}
    });
    const [getServiceLog, resServiceLog] = useLazyQuery(GET_SERVICE_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {console.log("comp d"); if(data.getServiceLog) setServiceLog(data.getServiceLog)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ReportMenuKey.serviceLog.label + " 조회 오류"})}
    });
    const [getPosUsageLog, resPosUsageLog] = useLazyQuery(GET_POS_USAGE_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {console.log("comp E"); if(data.getPosUsageLog) setPosUsageLog(data.getPosUsageLog)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ReportMenuKey.posUsageLog.label + " 조회 오류"})}
    });
    const [getSessionLog, resSessionLog] = useLazyQuery(GET_SESSION_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {console.log("comp F"); if(data.getSessionLog) setSessionLog(data.getSessionLog);},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ReportMenuKey.sessionLog.label + " 조회 오류"})}
    });

    const onChangeMenu = (newMenu) => {
        setMenu(newMenu);
    };

    const onRequestPosReportLog = (routeDate) => {getPosReportLog({variables: {routeDate: routeDate}});};
    const onRequestPosReportNotiLog = () => {getPosReportNotiLog();};
    const onRequestPosDownloadLog = () => {getPosDownloadLog();};
    const onRequestServiceLog = (date) => {getServiceLog({variables: {svcTime:date}});};
    const onRequestPosUsageLog = () => {getPosUsageLog();};
    const onRequestSessionLog = (date) => {getSessionLog({variables: {actDate:date}});};

    if(ValueUtil.hasAnyAuthError(
        resPosReportLog,
        resPosReportNotiLog,
        resPosDownloadLog,
        resServiceLog,
        resPosUsageLog,
        resSessionLog
        )
    ) userInfoRepo(NoUser);

    const renderReport = () => {
        if(menu.key === ReportMenuKey.posReportNotiLog.key) {
            return (
                <PosReportNotiLog
                    menu={<RegulationMenu onChange={onChangeMenu} menu={menu}/>}
                    records={posReportNotiLogList}
                    onRequestData={onRequestPosReportNotiLog}
                />
            );
        }
        else if(menu.key === ReportMenuKey.posDownloadLog.key) {
            return(
                <PosDownloadLog
                    menu={<RegulationMenu onChange={onChangeMenu} menu={menu}/>}
                    records={posDownloadLog}
                    onRequestData={onRequestPosDownloadLog}
                />
            );
        }
        else if(menu.key === ReportMenuKey.serviceLog.key) {
            return(
                <RegServiceLog
                    menu={<RegulationMenu onChange={onChangeMenu} menu={menu}/>}
                    records={serviceLog}
                    onRequestData={onRequestServiceLog}
                />
            );
        }
        else if(menu.key === ReportMenuKey.posUsageLog.key) {
            return(
                <PosUsageLog
                    menu={<RegulationMenu onChange={onChangeMenu} menu={menu}/>}
                    records={posUsageLog}
                    onRequestData={onRequestPosUsageLog}
                />
            );
        }
        else if(menu.key === ReportMenuKey.sessionLog.key) {
            return(
                <RegSessionLog
                    menu={<RegulationMenu onChange={onChangeMenu} menu={menu}/>}
                    records={sessionLog}
                    onRequestData={onRequestSessionLog}
                />
            );
        }
        else {
            return (
                <PosReportLog
                    menu={<RegulationMenu onChange={onChangeMenu} menu={menu}/>}
                    records={posReportLogList}
                    onRequestData={onRequestPosReportLog}
                />
            );
        }
    };

    return (
        <ReportContainer>
            {renderReport()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </ReportContainer>
    )
}
