// TermInfoAdmin.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Popover, List, ListItem } from '@mui/material';
import useClientSize from '../hook/useClientSize';
import CustInfoList from '../cust_info/CustInfoList';
import TermInfoList from './TermInfoList';
import TermInfoInput from './TermInfoInput';
import TermInfoInputSmartLogis from './TermInfoInputSmartLogis';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { LIST_CUST_INFO } from '../cust_info/CustInfoGql';
import {
    LIST_TERM_INFO, GET_TERM_INFO,
    ADD_TERM_INFO, EDIT_TERM_INFO, REMOVE_TERM_INFO, ADD_TERM_INFO_SMART_LOGIS, SET_TERM_INFO_STATE,
    TermInfoDictionary
} from './TermInfoGql';
import { AppObject, AppNumber, CvoCodes } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser, codeTreeRepo } from '../../model/CvoModel';
import Util from '../../model/Util';

const MinCustListBoxWidth = 250;
const MinSubBoxWidth = 250;
const DeviderWidth = 4;
const DeviderRightMargin = 4;
const CustNameTitleHeight = 48;
const UserBoxMargin = 10;
const StateSetterItemHeight = 40;

const dcPad = AppNumber.dataContainerPadding;
// cust + term box
const TermInfoAdminContainer = styled(Box)({
    position:"absolute", top:dcPad, bottom:dcPad, left:dcPad, right:dcPad,
    display: 'flex'
});
const CustListBox = styled(Box)({
    display:'inline-flex', //backgroundColor:'#eee',
    minWidth: MinCustListBoxWidth,
});
// Container for term-list
const SubBox = styled(Box)({
    display:'inline-block',
    minWidth: MinSubBoxWidth,
    marginLeft: DeviderWidth + DeviderRightMargin,
    position: 'relative',// backgroundColor:'#f9f9f9'
});

const TermListWrapper = styled(Box)({
    position:"absolute", top:0, bottom:0, left:dcPad, right:0, display: 'flex', flexDirection:'column'
});

const DeviderBox = styled(Box)({
    position: 'absolute',
    width: DeviderWidth,
    minHeight: 50,
    height: '100%',
    background: 'linear-gradient(to right, #aaaaaa, #eeeeee, #aaaaaa)',
    cursor: 'ew-resize',
});

const CustNameTitle = styled(Box)({
    display:'block',
    fontSize: 'large',
    fontWeight: 'bold',
    padding: 10,
    borderBottom: '1px solid #cccccc',
    height: CustNameTitleHeight - 21, // 21 = padding * 2 + border
});

const SetterMenuItem = styled(ListItem)({
    height: StateSetterItemHeight, display:'flex', alignItems:'center', cursor:'pointer', paddingLeft:10, paddingRight:10,minWidth:100,
    '&:nth-of-type(odd)':{backgroundColor:'#eee'},
    '&:hover':{color:'blue'}
});


const ErrorTitle =TermInfoDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(TermInfoDictionary.inputMap, true);

export default function TermInfoAdmin() {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [custInfoSelected, setCustInfoSelected] = useState(null);
    const [leftWidth, setLeftWidth] = useState(window.innerWidth * 0.35 < MinCustListBoxWidth ? MinCustListBoxWidth : window.innerWidth * 0.35);
	const [termInfoSelected, setTermInfoSelected] = useState(null);
    const [openSmartLogisEditor, setOpenSmartLogisEditor] = useState(false);
    const [editorState, setEditorState] = useState(null);
    const [openStateSetter, setOpenStateSetter] = useState(false);
    const [termForStateSetting, setTermForStateSetting] = useState(null);
    const [eventOnRow, setEventOnRow] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    // const responseList = useQuery(GET_TERM_INFO_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});
    const clientSize = useClientSize();
    const TermStateList = ValueUtil.codeToSelectList(CvoCodes.TERM_STATE_CD, CodeTree).filter((c)=>c.value !== 'IN');

    // ##### Call GraphQL to get List #####
    const [getCustInfoList, responseListCust] = useLazyQuery(LIST_CUST_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetCustList(data, option)}
    });
    const [getTermInfoList, responseList] = useLazyQuery(LIST_TERM_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)}
    });
    const [getTermInfoItemToEdit, responseItemToEdit] = useLazyQuery(GET_TERM_INFO, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});
    
    // ##### GraphQL Mutation. 0###
    const [addTermInfo, responseAdd] = useMutation( ADD_TERM_INFO, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [addTermInfoSmartLogis, responseAddSmartLogis] = useMutation( ADD_TERM_INFO_SMART_LOGIS, {
		onCompleted: (data, option) => onCompleteAddSmartLogis(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: "스마트로지스 추가 오류"})}
	} );
    const [editTermInfo, responseEdit] = useMutation( EDIT_TERM_INFO, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeTermInfo, responseRemove] = useMutation( REMOVE_TERM_INFO, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});
    const [setTermInfoState, responseState] = useMutation( SET_TERM_INFO_STATE, {
		onCompleted: (data, option) => onCompleteSetState(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getCustInfoList();
    }, []);


    const getTermInfoListOfCust = (custId) => {
        if(custId) getTermInfoList({variables:{custId:custId}});
        else if(custInfoSelected) getTermInfoList({variables:{custId:custInfoSelected.custId}});
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetCustList = (data, clientOption) => {
        // find current itemSelected and reset.
        if(custInfoSelected && data.custInfoList) {
            for(const item of data.custInfoList) {
                if(item.custId===custInfoSelected.custId) {
                    setCustInfoSelected(item);
                    return;
                }
            }
        }
    };
    const onCompleteGetList = (data, clientOption) => {
        if(termInfoSelected && data.termInfoList) {
            for(const item of data.termInfoList) {
                if(item.devId===termInfoSelected.devId) {
                    setTermInfoSelected(item);
                    return;
                }
            }
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.termInfoAdd.ok) {
            setEditorState(null);
            getTermInfoListOfCust(); // getTermInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.termInfoAdd, title: ErrorTitle.Add});
    };

    const onCompleteAddSmartLogis = (data, clientOption) => {
        if(data.termInfoAddSmartLogis.ok) {
            setOpenSmartLogisEditor(false);
            getTermInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.termInfoAddSmartLogis, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.termInfoEdit.ok) {
            setEditorState(null);
            getTermInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.termInfoEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.termInfoRemove.ok) getTermInfoListOfCust();
        else setResponseAlert({open:true, resultData: data.termInfoRemove, title: ErrorTitle.Remove});
    };

    const onCompleteSetState = (data, clientOption) => {
        if(data.termInfoSetState.ok) getTermInfoListOfCust();
        else setResponseAlert({open:true, resultData: data.termInfoSetState, title: '단말기 상태설정 오류'});
    };

    const onCompleteGetItem = (data, option) => {
        if(data.termInfoItem) {
            const item = {};
            for(const field of EditFields) item[field] = data.termInfoItem[field];
            setEditorState({item: item});
        }
    };

    // +++++++ UI callbacks ++++++++
    const onClickCustInfoItem = (custInfo) => {
        if(custInfoSelected) {
            if(custInfo.custId === custInfoSelected.custId) {
                return;
            }
        }
        setCustInfoSelected(custInfo);
        getTermInfoListOfCust(custInfo.custId);
    };

    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestAddSmartLogis = () => {
        if(responseAddSmartLogis) responseAddSmartLogis.reset();
        setOpenSmartLogisEditor(true);
    };

    const onRequestEdit = (item) => {
        if(responseEdit) responseEdit.reset();
        getTermInfoItemToEdit({variables: {devId: item.devId}});
    };

    const onClickSetState = (code) => {
        setOpenStateSetter(false);
        setPromptToConfirm({
            data: {termStateCd:code.value, devId:termForStateSetting.devId, custId: termForStateSetting.custId},
            title: '단말기 상태 변경',
            messages: [
                '지정한 ' + termForStateSetting.termId + ' 단말기의 상태를 '+code.label+' 상태로 전환합니다.',
                '계속 하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    const param = {variables: {termInfo: data}};
                    setTermInfoState(param);
                }
            }
        });
    };

    const onRequestSetState = (item, event) => {
        setEventOnRow(event);
        setTermForStateSetting(item);
        setOpenStateSetter(true);
    };

    const onRequestRemove = (item) => {
        setPromptToConfirm({
            data: item,
            title: '단말기 삭제',
            messages: [
                '지정한 ' + item.termId + ' 단말기를 삭제하시겠습니까?',
                '해당 단말기의 정보 및 부수하는 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{termInfo:{devId: data.devId, custId: custInfoSelected.custId}}};
                    removeTermInfo(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by TermInfoInput component.
    const onClickMutate = (item, isEdit) => {
        if(!isEdit) {
            if(custInfoSelected)
                item.custId = custInfoSelected.custId; // testing error callback.
            else {
                alert("CustInfo-Not selected!!");
                return;
            }
        }

        const param = {variables: {termInfo: ValueUtil.refineToSubmit(item)}};
        if(isEdit) editTermInfo(param);
        else addTermInfo(param);
    };

    const onClickMutateSmartLogis = (item, isEdit) => {
        // item.custId = ''; -- session puts it.
        item.custId = custInfoSelected.custId;
        const param = {variables: {termInfo: ValueUtil.refineToSubmit(item)}};
        addTermInfoSmartLogis(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };

    const onCloseSmartLogisWriter = () => {
        setOpenSmartLogisEditor(false);
    };

    if(ValueUtil.hasAnyAuthError(
        responseListCust,
        responseList,
        responseItemToEdit,
        responseAdd,
        responseAddSmartLogis,
        responseEdit,
        responseRemove,
        responseState
    )) userInfoRepo(NoUser);

    // <<<<<<<<<<<<<<<<<<<<<<<<<<< Devider >>>>>>>>>>>>>>>>>>>>>>>>>
    const onMouseMoveDevider = (event) => {
        if (event.buttons === 1) {
            const w = event.clientX - clientSize.dataContainerPadding;
            setLeftWidth(w < MinCustListBoxWidth ? MinCustListBoxWidth : w);
            event.stopPropagation();
        }
    };

    const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMoveDevider);
        document.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDown = (event) => {
        document.addEventListener('mousemove', onMouseMoveDevider);
        document.addEventListener('mouseup', onMouseUp);
        event.preventDefault();
        event.stopPropagation();
    };

    // ---------------------------- Render Components ----------------------------
    const renderTermStateMenu = () => {
        const term = termForStateSetting || {};
        const evt = eventOnRow || {};
        return(
            <Popover
                open={openStateSetter}
                onClose={()=>{setOpenStateSetter(false)}}
                anchorReference="anchorPosition"
                anchorPosition={Util.makeTopLeft(100, StateSetterItemHeight * 3, evt.clientX, evt.clientY)}
            >
                <List>
                    {
                        TermStateList.filter((c)=>c.value !== term.termStateCd).map((code)=>{
                            return(
                                <SetterMenuItem key={code.value}
                                    onClick={()=>{onClickSetState(code)}}
                                >
                                    {code.label}
                                </SetterMenuItem>
                            )
                        })
                    }
                </List>
            </Popover>
        );
    };

    const renderSamrtLogisWriter = () => {
        return(
            <TermInfoInputSmartLogis
                open={openSmartLogisEditor}
                onClose={onCloseSmartLogisWriter}
                responseSaving={responseAddSmartLogis}
                onClickMutate={onClickMutateSmartLogis}
            />
        );
    };

    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <TermInfoInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
            :
            <TermInfoInput
                open={Boolean(editorState)}
                isEdit={false}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

    const ContentHeight = clientSize.dataAreaHeight - dcPad * 2; // - clientSize.SmallBoxPadding * 2 - 2;
    const SubBoxWidth = clientSize.dataAreaWidth - leftWidth - DeviderWidth - DeviderRightMargin;
    //clientSize.dataAreaWidth - leftWidth - DeviderWidth - DeviderRightMargin;

    if(custInfoSelected) {
        const UserBoxHeight = ContentHeight - CustNameTitleHeight - UserBoxMargin - 2; // -2 for border
        const UserBoxWidth = SubBoxWidth - UserBoxMargin * 2 - 2;
        const custInfoText = custInfoSelected
            ? custInfoSelected.custName + "(" + custInfoSelected.custStateValue + ")"
            : "업체를 선택하세요";
        return (
            <TermInfoAdminContainer>
                <CustListBox width={leftWidth}>
                    <CustInfoList
                        maxWidth={leftWidth - dcPad}
                        maxHeight={ContentHeight}
                        responseList={responseListCust}
                        selected={custInfoSelected}
                        onClickItem={onClickCustInfoItem}
                    />
                </CustListBox>
                <SubBox width={SubBoxWidth} height={ContentHeight}>
                    <TermListWrapper>
                        <CustNameTitle>단말기 관리 - {custInfoText}</CustNameTitle>

                        <TermInfoList
                            maxHeight={UserBoxHeight}
                            maxWidth={UserBoxWidth}
                            responseList={responseList}
                            selected={termInfoSelected}
                            isAdminMode={true}
                            onClickItem={setTermInfoSelected}
                            onRequestAdd={onRequestAdd}
                            onRequestEdit={onRequestEdit}
                            onRequestRemove={onRequestRemove}
                            onRequestSetState={onRequestSetState}
                            onRequestAddSmartLogis={onRequestAddSmartLogis}
                        />
                    </TermListWrapper>
                </SubBox>
                <DeviderBox onMouseDown={onMouseDown}
                    style={{left:leftWidth, top:0}}
                    />
                {renderEditor()}
                {renderSamrtLogisWriter()}
                {renderPromptToConfirmBox()}
                {renderTermStateMenu()}
                <ResponseAlert open={responseAlert ? responseAlert.open : false}
                    alertData={responseAlert}
                    onClose={() => {setResponseAlert(null)}}/>
            </TermInfoAdminContainer>
        );
    }
    else {
        return(
            <TermInfoAdminContainer>
                <CustInfoList
                    title={"단말기목록 조회를 위해 업체를 선택하세요"}
                    maxWidth={clientSize.dataAreaWidth - dcPad * 2}
                    maxHeight={ContentHeight}
                    responseList={responseListCust}
                    onClickItem={onClickCustInfoItem}
                />
                <ResponseAlert open={responseAlert ? responseAlert.open : false}
                    alertData={responseAlert}
                    onClose={() => {setResponseAlert(null)}}/>
            </TermInfoAdminContainer>
        );
    }
}
