// TermMngInput.js - Dialog 아닌, 검색조건 컴포넌트 자리에 서로 배타적으로 나타남.
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, CircularProgress } from '@mui/material';
import { AppNumber, CvoCodes, AppWord, AppPalette } from '../../model/AppConst';
import { TermMngDictionary } from './TermMngGql';
import TextCombo from '../common/TextCombo';
import ConfirmDialog from '../message/ConfirmDialog';
import ValueUtil from '../../model/ValueUtil';
import { codeTreeRepo } from '../../model/CvoModel';
import CodeSelector from '../common/CodeSelector';
import { useReactiveVar } from '@apollo/client';

const ColumnMargin = 5;

const TermMngInputContainer = styled(Box)({ paddingBottom: 5});

const EditorContent = styled(Box)({padding:'5px 2px 5px 10px', borderRadius:5, border:'1px solid '+AppPalette.PrimaryRGB});

const InputColumn = styled(Box)({
    display: 'inline-block',
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({ display: 'block', paddingBottom: 5, });

const InputMap = TermMngDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.

// 오직 신규 입력용도로 사용함.
export default function TermMngInput({
    customerList, // 회계용 고객목록.
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [NetworkList, setNetworkList] = useState([]);
    const [TermStateList, setTermStateList] = useState([]);
    const [OpenForList, setOpenForList] = useState([]);
    const [FirmTypeList, setFirmTypeList] = useState([]);
    const [ModelList, setModelList] = useState([]);
    const [BillTypeList, setBillTypeList] = useState([]);
    const [ContrMonthList, setContrMonthList] = useState([]);
    const [ContrAmountList, setContrAmountList] = useState([]);
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
    const [oldData, setOldData] = useState({}); // inputData 복제. 일부 데이터는 지움.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [loadingSave, setLoadingSave] = useState(null);
    //const [responseAlert, setResponseAlert] = useState(null); // for response error
    //const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        setInputData(defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
    }, []);

    useEffect(()=>{
        if(CodeTree) {
            setNetworkList(ValueUtil.codeToSelectList(CvoCodes.AC_NETWORK_CD, CodeTree));
            setTermStateList(ValueUtil.codeToSelectList(CvoCodes.AC_TERM_STATE_CD, CodeTree));
            setOpenForList(ValueUtil.codeToSelectList(CvoCodes.AC_OPEN_FOR_CD, CodeTree));
            setFirmTypeList(ValueUtil.codeToSelectList(CvoCodes.AC_FIRM_TYPE_CD, CodeTree));
            setModelList(ValueUtil.codeToSelectList(CvoCodes.AC_MODEL_CD, CodeTree));
            setBillTypeList(ValueUtil.codeToSelectList(CvoCodes.AC_BILL_TYPE_CD, CodeTree));
            setContrMonthList(ValueUtil.codeToSelectList(CvoCodes.AC_CONTR_MONTH_CD, CodeTree));
            setContrAmountList(ValueUtil.codeToSelectList(CvoCodes.AC_CONTR_AMOUNT_CD, CodeTree));
        }
    }, [CodeTree]);

    useEffect(()=>{
        const {loading, error, data} = responseSaving;
        setLoadingSave(loading);

        if(data) {
            if(data.termMngAdd.ok) {
                setInputData(oldData);
                setChanged(false);
            }
        }
    }, [responseSaving]); // Don't add oldData

    const changeInputData = (data) => {
		setInputData(data ? data : defaultInputData);
        setChanged(true);
    };

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const oldInput = {...inputData};
        oldInput.termId = '';
        oldInput.usimNum = '';
        oldInput.serialNo = '';

        const param = ValueUtil.getDataToSubmit(inputData, InputMap, false); // {...inputData}; false for isEdit
        // add extra data if necessary.
        //if(!isEdit) param.custId = custId;
        setOldData(oldInput);
        onClickMutate(param, false); // false for isEdit
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput2(event, InputMap, inputData, inputError, false);

        changeInputData(newData);
        setInputError(newError);
        setHasError(hasError);
    };

    const onChangeNumeric = (event) => {
        if(event.target.value) {
            if(/[^0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

	// If necessary
    const onChangeCode = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        changeInputData(data);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange, width) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size={AppWord.SMALL}
                    style={{width:width || 120}}
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    const renderDateBox = (dict, value, error, runOnChange, width) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size={AppWord.SMALL}
                    style={{width:width || 164}}
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value}
                    error={error}
                    helperText={dict.help}
                    InputLabelProps={{ shrink: true }}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    const renderSelectorField = (dict, value, selectFrom) => {
        return(
            <FieldBox>
                <CodeSelector
                    id={dict.id}
                    value={value || dict.default}
                    label={dict.label}
                    codes={selectFrom}
                    onChange={onChangeCode}
                    size={AppWord.SMALL}
                />
            </FieldBox>
        );
    };


    return (
        <TermMngInputContainer>
            <EditorContent>
                <InputColumn>
                    {renderTextFieldBox(InputMap.termId, inputData.termId,
                        inputError.termId, onChangeNumeric) /* 단말기ID */}
                    {renderTextFieldBox(InputMap.serialNo, inputData.serialNo,
                        inputError.serialNo, onChangeTextData) /* S/N */}
                    {renderTextFieldBox(InputMap.usimNum, inputData.usimNum,
                        inputError.usimNum, onChangeTextData) /* USIM */}
                </InputColumn>
                <InputColumn>
                    <FieldBox>
                        <TextCombo
                            id={InputMap.custName.id}
                            label={InputMap.custName.label}
                            selectFrom={customerList}
                            uniqueKey={InputMap.custName.id}
                            text={inputData.custName || ''}
                            error={inputError.custName}
                            onChange={(id,value)=>{onChangeTextData({target:{id:id, value:value}})}}
                        />{ /* 업체명 - combo */}
                    </FieldBox>
                    {renderDateBox(InputMap.openDate, inputData.openDate,
                        inputError.openDate, onChangeTextData) /* 개통일자 */}
                    {renderDateBox(InputMap.abandonDate, inputData.abandonDate,
                        inputError.abandonDate, onChangeTextData) /* 해지일자 */}
                </InputColumn>
                <InputColumn>
                    {renderSelectorField(InputMap.acNetworkCd, inputData.acNetworkCd, NetworkList) /* 통신사 */}
                    {renderSelectorField(InputMap.acTermStateCd, inputData.acTermStateCd, TermStateList) /* 개통상태 */}
                    {renderSelectorField(InputMap.acOpenForCd, inputData.acOpenForCd, OpenForList) /* 개통구분 */}
                </InputColumn>
                <InputColumn>
                    {renderSelectorField(InputMap.acModelCd, inputData.acModelCd, ModelList) /* 모델 */}
                    {renderTextFieldBox(InputMap.salePrice, inputData.salePrice,
                        inputError.salePrice, onChangeTextData) /* 출고가. text */}
                    {renderTextFieldBox(InputMap.supportAmount, inputData.supportAmount,
                        inputError.supportAmount, onChangeTextData) /* 단말지원약정 */}
                </InputColumn>
                <InputColumn>
                    {renderSelectorField(InputMap.acBillTypeCd, inputData.acBillTypeCd, BillTypeList) /* 요금제 */}
                    {renderSelectorField(InputMap.acContrMonthCd, inputData.acContrMonthCd, ContrMonthList) /* 약정개월 */}
                    {renderSelectorField(InputMap.acContrAmountCd, inputData.acContrAmountCd, ContrAmountList) /* 약정금액 */}
                </InputColumn>
                <InputColumn>
                    {renderSelectorField(InputMap.acFirmTypeCd, inputData.acFirmTypeCd, FirmTypeList) /* 법인구분 */}
                    {renderTextFieldBox(InputMap.custCorpNo, inputData.custCorpNo,
                        inputError.custCorpNo, onChangeNumeric) /* 법인#/생년월일 */}
                    {renderTextFieldBox(InputMap.compBizno, inputData.compBizno,
                        inputError.compBizno, onChangeNumeric) /* 사업자등록번호 */}
                </InputColumn>
                <InputColumn>
                    {renderTextFieldBox(InputMap.openerName, inputData.openerName,
                        inputError.openerName, onChangeTextData) /* 명의자명 */}
                    {renderTextFieldBox(InputMap.representitive, inputData.representitive,
                        inputError.representitive, onChangeTextData) /* 대리인 */}
                    {renderTextFieldBox(InputMap.groupMemo, inputData.groupMemo,
                        inputError.groupMemo, onChangeTextData) /* 소그룹 */}
                </InputColumn>
                <InputColumn>
                    {renderTextFieldBox(InputMap.salesStaffName, inputData.salesStaffName,
                        inputError.salesStaffName, onChangeTextData) /* 영업담당자 */}
                    {renderTextFieldBox(InputMap.thermComp, inputData.thermComp,
                        inputError.thermComp, onChangeTextData) /* 온도업체 */}
                    {renderTextFieldBox(InputMap.memoInstall, inputData.memoInstall,
                        inputError.memoInstall, onChangeTextData) /* 설치여부 */}
                </InputColumn>
                <InputColumn>
                    {renderTextFieldBox(InputMap.generalMemo, inputData.generalMemo,
                        inputError.generalMemo, onChangeTextData) /* 적요 */}
                    
                {
                    loadingSave
                    ?
                    <FieldBox>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </FieldBox>
                    :
                    <>
                        <FieldBox>
                            <Button
                                onClick={onClickSubmit} fullWidth
                                disabled={hasError || !changed}
                                variant='contained' color='primary'>
                                개통정보 추가</Button>
                        </FieldBox>
                        <FieldBox>
                            <Button onClick={onClickCancel} color='warning' fullWidth
                                variant='contained'>닫기</Button>
                        </FieldBox>
                    </>
                }
                </InputColumn>
            </EditorContent>
            {renderPromptIgnoreChange()}
        </TermMngInputContainer>
    );
}
