// com/map/NaverRouteSearch.js
import React, {useEffect, useState} from 'react';
import { styled  } from '@mui/material/styles';
import { Box, darken } from '@mui/system';
import { Button, Select, MenuItem, Chip } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { NAVER_SEARCH_ROUTE } from './NaverRouteGql';
import NaverRoute from '../../model/NaverRoute';
import { AppObject, AppPalette, AppWord, MapValue } from '../../model/AppConst';
import { naverRouteRepo } from '../../model/CvoModel';
import ValueUtil from '../../model/ValueUtil';
import { useLazyQuery, useReactiveVar } from '@apollo/client';

const MainBox = styled(Box)({
    display:'inline-flex', alignItems:'center', justifyContent:'start',
    border:'1px solid ' + AppPalette.NaverGreen, borderRadius:5, marginRight:20, padding:5,
    backgroundColor: darken(AppPalette.PrimaryRGB, 0.5), color:'white',
});
const PlaceColumn = styled(Box)({
    maxWidth: 300, textOverflow: 'ellipsis', overflow:'hidden', display:'inline-flex', flexDirection:'column', // border: '1px solid',
    fontSize: '0.8rem'
});
const PlaceBox = styled(Box)({padding: '1px 10px 1px 10px', minWidth:180, textAlign:'left', overflow:'hidden', marginRight:10});

const PlaceAddress = styled('span')({cursor:'pointer', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'});

const RouteTypeBox = styled(Box)({display:'flex', alignItems:AppWord.END, color:'white', fontSize:'0.8rem', marginRight:5});

const DistBox = styled(Box)({padding: '1px 10px 1px 10px', textAlign:'center', fontSize:'0.8rem', marginRight:5});
const DistLabel = styled(Box)({color:'#ff9'});
const DistValue = styled(Box)({fontWeight:'bold'});

const ChooseBracket = '[선택]';
const CHECKING = '확인중';

/*
TODO:
    - 과거 경로탐색 내용을 배열에 넣어 두고 꺼내볼 수 있게 한다.
    - 경로탐색의 guide 내용를 보여준다.
*/

/**
 * 네이버 경로탐색을 위한 출발지, 도착지 설정 컴포넌트. 지도화면의 상단에 필요할 때만 표시됨.
 * @param {object} param0 - mapShell, posFrom, posTo, onCancel, onAlert
 * @returns 출발, 도착지 및 옵션 선택용 컴포넌트
 */
export default function NaverRouteSearch({
    mapShell,
    posFrom, // {lat,lon}
    posTo, // {lat,lon}
    resultNaverRoute, // null or NaverRoute object.
    onCancel,
    onAlert
}) {
    const [addrFrom, setAddrFrom] = useState(ChooseBracket);
    const [addrTo, setAddrTo] = useState(ChooseBracket);
    const [trackOption, setTrackOption] = useState(MapValue.NaverTrackMethod.Fast);
    const [routeData, setRouteData] = useState(null);
    const naverRoutesInRepo = useReactiveVar(naverRouteRepo);

    // ##### Call GraphQL to get List #####
    const [searchNaverRoute, responseList] = useLazyQuery(NAVER_SEARCH_ROUTE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteSearchRoute(data, option)},
		onError: (error) => {onAlert({open:true, error: error, title: "경로탐색 오류"})}
    });

    useEffect(()=>{
        if(posFrom) {
            mapShell.setNaverRouteStartPos(posFrom);
            if(posFrom.address) setAddrFrom(posFrom.address);
            else {
                setAddrFrom(CHECKING);
                mapShell.queryNaverReverseGeocode(posFrom.lat, posFrom.lon, (status, response)=>{
                    if(mapShell.isNaverResponseOk(status)) {
                        setAddrFrom(ValueUtil.getNaverV2AddressString(response));
                    }
                    else setAddrFrom("출발지 확인 오류 " + status);
                });
            }
        }
    }, [mapShell, posFrom]);

    useEffect(()=>{
        if(posTo) {
            mapShell.setNaverRouteEndPos(posTo);
            if(posTo.address) setAddrTo(posTo.address);
            else {
                setAddrTo(CHECKING);
                mapShell.queryNaverReverseGeocode(posTo.lat, posTo.lon, (status, response)=>{
                    if(mapShell.isNaverResponseOk(status)) {
                        setAddrTo(ValueUtil.getNaverV2AddressString(response));
                    }
                    else setAddrTo("도착지 확인 오류 " + status);
                });
            }
        }
    }, [mapShell, posTo]);

    useEffect(()=>{
        if(resultNaverRoute && mapShell) {
            mapShell.drawNaverRouteAndMarkers(resultNaverRoute);
            setRouteData(resultNaverRoute);
        }
    }, [mapShell, resultNaverRoute]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteSearchRoute = (data, clientOption) => {
        if(data.naverSearchRoute) {
            if(data.naverSearchRoute.ok) {
                const naverRoute = new NaverRoute(
                    data.naverSearchRoute.item,
                    {...posFrom, address: addrFrom},
                    {...posTo, address: addrTo},
                    trackOption
                    );
                naverRoute.build();
                //naverRoute.dump();
                // mapShell.drawNaverRoute(naverRoute);
                mapShell.drawNaverRouteAndMarkers(naverRoute);
                setRouteData(naverRoute);
                // Save route data for later display.
                naverRouteRepo(ValueUtil.pushToRepoArray(naverRoutesInRepo, naverRoute, true)); // true for unshift
            }
            else {
                onAlert({open:true, error: data.naverSearchRoute.message, title: "경로탐색 오류"})
            }
        }
    };

    const goToPlace = (place) => {if(place) mapShell.setCenter(place.lat, place.lon)};

    const onClickSearch = () => {
        if(posFrom && posTo) {
            const param = {
                latFrom: posFrom.lat,
                lonFrom: posFrom.lon,
                latTo: posTo.lat,
                lonTo: posTo.lon,
                trackOption: trackOption
            };
            searchNaverRoute({variables: {searchInput: param}})
        }
    };

    const onClickCancel = () => {
        mapShell.clearNaverRouteMarkers();
        // save before clear data.
        onCancel();
    };

    return (
        <MainBox>
            <PlaceColumn>
                <PlaceBox>
                    <PlaceAddress onClick={()=>goToPlace(posFrom)}>출발: {addrFrom}</PlaceAddress>
                </PlaceBox>
                <PlaceBox>
                    <PlaceAddress onClick={()=>goToPlace(posTo)}>도착: {addrTo}</PlaceAddress>
                </PlaceBox>
            </PlaceColumn>
            <RouteTypeBox>
                <Select
                    id="roue-type-select" size={AppWord.SMALL}
                    value={trackOption || ''}
                    onChange={(e)=>setTrackOption(e.target.value)}
                    label={null}
                    style={{minWidth: 200, color:'white', fontSize:'0.85rem', textAlign:'left', backgroundColor:AppPalette.PrimaryRGB}}
                >
                    {
                        MapValue.NaverTrackOption.map((code,idx) => {
                            return(
                                <MenuItem key={code.value} value={code.value}>
                                    {code.label}
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            </RouteTypeBox>
            <Button
                disabled={!posFrom || !posTo}
                color={AppPalette.PrimaryColor}
                variant={AppPalette.VariantContained}
                onClick={onClickSearch}
            >
                <QueryStatsIcon color={AppPalette.InheritColor} style={{marginLeft:3, marginRight:3}} size={AppWord.SMALL}/>
                경로탐색
            </Button>
            &nbsp;
            {
                routeData
                ?
                <>
                <DistBox>
                    <DistLabel>
                        거리(Km)
                    </DistLabel>
                    <DistValue>{Math.round(routeData.distance/100)/10}</DistValue>
                </DistBox> <Chip label="x" color='warning' size={AppWord.SMALL} onClick={onClickCancel}/>
                </>
                :
                <Button
                    color={AppPalette.WarnColor}
                    variant={AppPalette.VariantContained}
                    onClick={onClickCancel}
                >취소</Button>

            }
        </MainBox>
    );
}


/*
############# Naver 위치를 주소로 ##############

            // 네이버 좌표 to 주소 검색하기. 신규 지점 등록 시 지정한 좌표에 대해서 검색함.
            this.queryNaverReverseGeocode = function(lat, lon, callback) {
                naver.maps.Service.reverseGeocode({coords: new naver.maps.LatLng(lat, lon)}, function(status, response) {
                    if (status !== naver.maps.Service.Status.OK) {
                        return self.models.alertTitled('네이버 지도오류', ['네이버 지도서버와의 통신에 문제가 있습니다.', '잠시 후 다시 시도해 주세요.']);
                    }
                    //var result = response.v2;
                    $log.log(response.v2);
                    callback(response.v2.address); // {jibunAddress: "경기도 성남시 분당구 정자동 178-1",roadAddress: "경기도 성남시 분당구 불정로 6 NAVER그린팩토리"}
                });
            };

############# Naver 주소 검색 ##############

            // 네이버 주소검색 결과 처리
            this.onResultNaverGeocodeQuery = function(status, response) {
                if (status !== naver.maps.Service.Status.OK) {
                    $log.log(response);
                    return self.models.alertTitled('네이버 통신오류', ['네이버 서버와의 통신에 문제가 있습니다.', '잠시 후 다시 시도해 주세요.']);
                }

                var result = response.v2; // 검색 결과의 컨테이너
                // $log.log(result);
                var meta = result.meta;
                // $log.log("Got result. Address count="+result.meta.totalCount);
                self.models.naverAddressList = result.addresses; // 검색 결과의 배열

                if(self.models.naverAddressList.length < 20) {
                    for(var i=self.models.naverAddressList.length; i<20; i++) {
                        self.models.naverAddressList.push({});
                    }
                }

                self.models.naverAddressSelected = {};

                $log.log("Counting address array="+self.models.naverAddressList.length);
                // do Something
            };

            // 네이버 주소검색하기 (주소 -> 좌표 검색(geocode) API 호출)
            this.queryNaverGeocode = function(callback) {
                if(self.models.naverAddressString)
                    naver.maps.Service.geocode({query: self.models.naverAddressString}, function(status, response) {
                        self.onResultNaverGeocodeQuery(status, response);
                        if (status == naver.maps.Service.Status.OK) {
                            callback(response.v2);
                        }
                    });
            };

*/