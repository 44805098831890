// com/common/CvoButtons.js

import { styled } from '@mui/material/styles';
import { Button, darken, lighten } from '@mui/material';
import { AppPalette } from '../../model/AppConst';


export const ButtonPrimary = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: AppPalette.PrimaryRGB,
    boxShadow: '1px 1px 1px 1px rgba(190,200,200,.7)',
    '&:hover': {
        backgroundColor: darken(AppPalette.PrimaryRGB, 0.2),
        boxShadow: '2px 2px 4px 2px rgba(150,200,200,.6)',
    },'&:focus': {
        boxShadow: '2px 2px 4px 2px rgba(150,200,200,.6)',
    },'&:disabled': {
        color:'#ccc',
        backgroundColor: lighten(AppPalette.PrimaryRGB, 0.5),
        boxShadow: 'unset',
    },
}));

export const ButtonWarning = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: AppPalette.WarnRGB,
    boxShadow: '1px 1px 1px 1px rgba(190,170,170,.7)',
    '&:hover': {
        backgroundColor: darken(AppPalette.WarnRGB, 0.2),
        boxShadow: '2px 2px 4px 2px rgba(230,170,170,.6)',
    },'&:focus': {
        boxShadow: '2px 2px 4px 2px rgba(230,170,170,.6)',
    },'&:disabled': {
        color:'#ccc',
        backgroundColor: lighten(AppPalette.WarnRGB, 0.5),
        boxShadow: 'unset',
    },
}));

export const ButtonOutlinedRound = styled(Button)(({ theme }) => ({
    color: '#222222',
    backgroundColor: '#ffffff',
    boxShadow: '1px 1px 1px 1px rgba(190,200,200,.7)',
    border: '1px solid darkgrey',
    borderRadius: 20,
    paddingLeft:20, paddingRight:20,
    '&:hover': {
        color: '#000055',
        backgroundColor: '#eeffbb',
        boxShadow: '2px 2px 4px 2px rgba(150,200,200,.6)',
    },'&:focus': {
        boxShadow: '2px 2px 4px 2px rgba(150,200,200,.6)',
    },'&:disabled': {
        color:'#ccc',
        backgroundColor: '#efefef',
        boxShadow: 'unset',
    },
}));

export const ButtonLeftAligned = styled(Button)(({ theme }) => ({
    textAlign:'left'
}));