//
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import RouteDailyBillList from './RouteDailyBillList';
import DownloadIcon from '@mui/icons-material/Download';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery } from '@apollo/client';
import { LIST_ROUTE_DAILY_BILL } from './RouteDailyBillGql';
import { AppObject, AppNumber, AppWord, AppPalette } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;
const BillContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display:'flex', height: AppNumber.HeaderToolBoxHeight, alignItems:'center',
});

export default function RouteDailyBill() {
    const [search, setSearch] = useState('');
    const [billList, setBillList] = useState([]);
    const [month, setMonth] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);

    // ##### Call GraphQL to get List #####
    const [getRouteDailyBillList, responseList] = useLazyQuery(LIST_ROUTE_DAILY_BILL, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetBillList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '단말기 정산정보 조회 오류'})}
    });

    const onCompleteGetBillList = (data, clientOption) => {
        if(data.routeDailyBillList) {
            setBillList(data.routeDailyBillList);
        }
    };

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onChangeMonth = (event) => {
        setMonth(event.target.value); // YYYY-MM
    };

    const onClickToGetBillList = () => {
        if(month) {
            getRouteDailyBillList({variables: {routeDate: month + '-01'}});
        }
    };

    if(ValueUtil.hasAnyAuthError(responseList)) userInfoRepo(NoUser);

    return (
        <BillContainer>
            <HeaderBox>
                <TextField type='month'
                    value={month || ''}
                    label={month ? "월 선택" : null}
                    onChange={onChangeMonth}
                    variant='outlined'
                    size={AppWord.SMALL}
                />
                &nbsp;
                <Button
                    disabled={!month}
                    variant={AppPalette.VariantContained}
                    onClick={onClickToGetBillList}
                >
                    <DownloadIcon fontSize={AppWord.SMALL} />
                    조회
                </Button>
                &nbsp; &nbsp; &nbsp;
				<FormControl variant='outlined' size={AppWord.SMALL}>
					<InputLabel htmlFor="search-RouteDaily-list" style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id="search-RouteDaily-list" onChange={onChangeSearch}
						value={search}
						disabled={billList.length === 0}
						size={AppWord.SMALL}
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
            </HeaderBox>
            <RouteDailyBillList records={billList} search={search} />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </BillContainer>
    )
}
