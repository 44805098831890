// ChangeMyPassword.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette, AppWord } from '../../model/AppConst';
import { UserInfoDictionary } from './UserInfoGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import RadioSelector from '../common/RadioSelector';
import ValueUtil from '../../model/ValueUtil';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;
//const ColumnCount = 2;
//const EditorWidth = ColumnBoxWidth * ColumnCount + ColumnMargin * ColumnCount * 2 + 20;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
});

const InputMap = UserInfoDictionary.passwordMap; // 입력양식 상수, validation
const defaultInputData = {password:'', newPassword:'', confirm:''}; // 입력양식 상수 중 기초 데이터(default)만 추출.

export default function ChangeMyPassword({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    onClose,
    userInfo,
    onClickMutate, // Ask controller to submit.
    responseSaving, // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    //const [inputData, setInputData] = useState(item ? item : {...defaultInputData}); // 입력한 데이터.
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const {loading:loadingSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    const resetData = (data) => {
        setInputData(data ? data : defaultInputData);
        setInputError({});
        setChanged(false);
        setHasError(false);
    };

    // 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        if(inputData.newPassword === inputData.confirm) {
            const param = {
                password: inputData.password,
                newPassword: inputData.newPassword,
                userId: userInfo.userId,
                custId: userInfo.custId
            };

            onClickMutate(param);
        }
    };

    const onClickCancel = () => {
        resetData();
        onClose();
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const id = event.target.id;
        let [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);
        
        if(id === InputMap.confirm.id && newData[id] !== newData.newPassword) {
            newError[id] = true; //"새 암호와 암호확인이 서로 다릅니다";
            hasError = true;
        }
        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>
                내 암호 변경하기
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                        <form>
                        <FieldBox>
                            <TextField id={InputMap.password.id}
                                type={InputMap.password.type}
                                label={InputMap.password.label}
                                required={InputMap.password.required}
                                value={inputData.password || ''}
                                error={inputError.password}
                                helperText={InputMap.password.help}
                                style={{width:400}}
                                onChange={onChangeTextData} />
                        </FieldBox>

                        <FieldBox>
                            <TextField id={InputMap.newPassword.id}
                                type={InputMap.newPassword.type}
                                label={InputMap.newPassword.label}
                                required={InputMap.newPassword.required}
                                value={inputData.newPassword}
                                error={inputError.newPassword}
                                helperText={InputMap.newPassword.help}
                                style={{width:400}}
                                onChange={onChangeTextData} />
                        </FieldBox>

                        <FieldBox>
                            <TextField id={InputMap.confirm.id}
                                type={InputMap.confirm.type}
                                label={InputMap.confirm.label}
                                required={InputMap.confirm.required}
                                value={inputData.confirm}
                                error={inputError.confirm}
                                helperText={inputError.confirm ? "새 암호와 암호확인이 서로 다릅니다" : undefined}
                                style={{width:400}}
                                onChange={onChangeTextData} />
                        </FieldBox>
                        </form>
                    </InputColumn>

                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed || inputData.newPassword !== inputData.confirm}
                            variant={AppPalette.VariantContained} color={AppPalette.PrimaryColor}>
                            변경</Button>
                        <Button onClick={onClickCancel} color={AppPalette.WarnColor}
                            variant={AppPalette.VariantContained}>취소</Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    );
}
