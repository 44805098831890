// RouteMonthlyList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Popover, Button, IconButton, Checkbox, FormControl, InputLabel, OutlinedInput, InputAdornment, FormControlLabel } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SelectFromList from '../common/SelectFromList';
import { showRouteMonthlyActiveOnlyRepo } from '../../model/CvoModel';
import { AppNumber, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import ValueUtil from '../../model/ValueUtil';

const RouteMonthlyListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column', height:'100%', boxSizing:'border-box'
});
const HeaderBox = styled(Box)({
	display:'flex', alignItems:'center', height:AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const HeaderControl = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});
const VehicleSelected = styled(Box)({
    padding:5, border:'1px solid #faa', borderRadius:5, marginRight:20
});
const HeaderRight = styled(Box)({display:'flex', alignItems:'center'});
const ParamEditor = styled(Box)({
    padding:10, border:AppPalette.BorderCCC, borderRadius:5,
});
const ParamField = styled(Box)({padding:5});
const DataTableContainer = styled(Box)({
    flexGrow:1,
    position:'relative',
    borderTop: AppPalette.BorderCCC,
});
const DataTableWrapper = styled(Box)({position:'absolute', top:0, left:0, bottom:0, right:0, overflow:'auto'});

const RecordBox = styled(Box)({
    display:'flex', alignItems:'center', padding: AppNumber.SmallBoxPadding,
    borderBottom: AppPalette.BorderCCC, cursor:'pointer',
    '&:hover': {backgroundColor:'#eee'}
});
const VehicleColumn = styled(Box)({
    width: 140, marginRight: AppNumber.SmallBoxPadding, borderRight: AppPalette.BorderCCC,
    display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'
});
const VehicleLabel = styled(Box)({fontSize:'1.05rem'});
const CustLabel = styled(Box)({fontSize:'0.8rem', color:'#888'});
const DataColumn = styled(Box)({
    flexGrow:1,
});
const DataItemHeight = 24;
const DataItem = styled(Box)({
    // contains DataGraph, DataText
    display:'flex', alignItems:'center', height: DataItemHeight, fontSize:'0.8rem',
});
const DataGraph = styled(Box)({
    textAlign:'right', display:'flex', alignItems:'center', justifyContent:AppWord.END, padding: 3,
    borderTopRightRadius: 5, borderBottomRightRadius: 5,
    border: AppPalette.BorderCCC, boxSizing:'border-box',
});
const DataTextInGraph = styled(Box)({color:'black'});
const DataText = styled(Box)({
    flexGrow:1, paddingLeft:10, color:'#333', // width: 100,
});

const HoursRunColor = '#fce8fc'; // AppPalette.PrimaryRGB;
const TotalDistColor = '#c5fcfc'; // AppPalette.SuccessRGB
const AvgSpeedRunColor = '#fcfcae'; // AppPalette.InfoRGB
const DaysRunColor = '#eee';

/**
 * 월별 운행 통계 목록.
 * 특정 월의 모든 차량의 운행 통계를 조회하여 그래프 형식의 목록으로 보여준다.
 * 보여줄 내용: 총 이동거리, 총 이동 소요시간, 평균이동속도, 이동이 있었던 일수.
 * 선택(체크박스): 이동거리가 > 0인 경우만 보기.
 */
export default function RouteMonthlyList({
    routeDate,
    monthList,
    onChangeMonth,
	records,
    onRequestListOfVeh,
}) {
	// const sessionInfo = useReactiveVar(userInfoRepo);
    const showActiveOnly = useReactiveVar(showRouteMonthlyActiveOnlyRepo); // 이동거리가 > 0인 경우만 보기.
    const [search, setSearch] = useState('');
    const [recordsToShow, setRecordsToShow] = useState(records); //showActiveOnly, search 필터링
    const [recordSelected, setRecordSelected] = useState(null); // 선택된 차량 record
    const [anchorForParam, setAnchorForParam] = useState(null); // 차량별 운행 통계 파라미터 설정용 Popup anchor
    const [paramForListOfVeh, setParamForListOfVeh] = useState({ vehId:0,dateFrom:'',dateTill:''}); // 차량별 운행 통계 목록을 가져오기 위한 파라미터
    const [maxes, setMaxes] = useState({totalDist:0, avgSpeedRun:0, hoursRun:0, daysRun:0});
    // const [filterCriteria, setFilterCriteria] = useState(null);

    useEffect(()=>{
        if(monthList.length > 0) {
            const dateFrom = monthList.length > 5 ? monthList[5].value : monthList[monthList.length-1].value;
            const dateTill = monthList[0].value;
            setParamForListOfVeh({vehId:0, dateFrom, dateTill});
        }
    }, [monthList]);

    useEffect(()=>{
        const max = {totalDist:0, avgSpeedRun:0, hoursRun:0, daysRun:0};
        const toShow = records.filter(rec=>{
            if(rec.totalDist > max.totalDist) max.totalDist = rec.totalDist;
            if(rec.avgSpeedRun > max.avgSpeedRun) max.avgSpeedRun = rec.avgSpeedRun;
            if(rec.hoursRun > max.hoursRun) max.hoursRun = rec.hoursRun;
            if(rec.daysRun > max.daysRun) max.daysRun = rec.daysRun;

            if(showActiveOnly && rec.totalDist <= 0) return false;
            if(search) {
                const s = search.trim();
                if(s.length>0) {
                    return rec.vehPlates.indexOf(s) >= 0 || rec.custName.indexOf(s) >= 0;
                }
            }
            return true;
        });
        setMaxes(max);
        setRecordsToShow(toShow.map(rec=>{
            return {
                routeDate: rec.routeDate,
                vehId: rec.vehId,
                vehPlates: rec.vehPlates,
                custName: rec.custName,
                secondsRun: rec.secondsRun,
                hoursRun: rec.hoursRun,
                hoursRunRate: Math.round(rec.hoursRun*1000/max.hoursRun)/10,
                daysRun: rec.daysRun,
                daysRunRate: Math.round(rec.daysRun*1000/max.daysRun)/10,
                totalDist: rec.totalDist,
                totalDistRate: Math.round(rec.totalDist*1000/max.totalDist)/10,
                avgSpeedRun: Math.round(rec.avgSpeedRun*10)/10,
                avgSpeedRunRate: Math.round(rec.avgSpeedRun*1000/max.avgSpeedRun)/10,
                maxSpeed: rec.maxSpeed,
            }
        }));
    }, [records, search, showActiveOnly]);

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onChangeRouteMonth = (id, value) => {
        onChangeMonth(value);
    };

    const onClickToGetMonthlyListOfVeh = () => {
        setAnchorForParam(null);
        if(paramForListOfVeh && paramForListOfVeh.vehId > 0) {
            const param = {...paramForListOfVeh};
            if(param.dateFrom > param.dateTill) {
                const tmp = param.dateFrom;
                param.dateFrom = param.dateTill;
                param.dateTill = tmp;
            }
            // console.log('onClickToGetMonthlyListOfVeh', param);
            onRequestListOfVeh(param);
        }
    };

    const PercentToPutTextInGraph = 60;
    const renderDataItem = (rate, label, color) => {
        return(
            <DataItem>
                <DataGraph
                    style={{
                        backgroundColor:color,
                        width: `${rate}%`
                    }}
                >
                    {
                        rate > PercentToPutTextInGraph
                        &&
                        <DataTextInGraph>{label}</DataTextInGraph>
                    }
                    <span>&nbsp;</span>
                </DataGraph>
                <DataText>
                    {
                        rate <= PercentToPutTextInGraph
                        ?
                        label
                        :
                        null
                    }
                </DataText>
            </DataItem>
        );
    };

    const renderDataRecord = (rec) => {
        const icon = recordSelected && recordSelected.vehId === rec.vehId
            ? <LocalShippingIcon color={AppPalette.PrimaryColor}/>
            : <LocalShippingOutlinedIcon />;
        return(
            <RecordBox key={rec.vehId}
                onClick={()=>{setRecordSelected(rec)}}
            >
                <VehicleColumn>
                    {icon}
                    <VehicleLabel>{rec.vehPlates}</VehicleLabel>
                    <CustLabel>{rec.custName}</CustLabel>
                </VehicleColumn>
                <DataColumn>
                    {renderDataItem(rec.hoursRunRate, `총 이동시간 ${rec.hoursRun}시간`, HoursRunColor)}
                    {renderDataItem(rec.totalDistRate, `총 이동거리 ${ValueUtil.float2str(rec.totalDist,2)}km`, TotalDistColor)}
                    {renderDataItem(rec.avgSpeedRunRate, `이동 평균속도 ${rec.avgSpeedRun}km/h`, AvgSpeedRunColor)}
                    {renderDataItem(rec.daysRunRate, `이동발생 일 수 ${rec.daysRun}일`, DaysRunColor)}
                </DataColumn>
            </RecordBox>
        );
    };

    const renderVehAndPopover = () => {
        if(!recordSelected) return null;
        return(
            <>
                <VehicleSelected>
                    <LocalShippingIcon
                        size={AppWord.SMALL} style={{verticalAlign:'middle', marginRight:10}}
                        color={AppPalette.PrimaryColor}
                    />
                    <span>{recordSelected.vehPlates} 차량의 월별 통계</span>
                    &nbsp;&nbsp;
                    <Button size={AppWord.SMALL}
                        color={AppPalette.PrimaryColor}
                        variant={AppPalette.VariantContained}
                        onClick={(e)=>{setAnchorForParam(e.currentTarget); setParamForListOfVeh({...paramForListOfVeh, vehId:recordSelected.vehId})}}
                    >
                        상세보기
                    </Button>
                </VehicleSelected>
                <Popover
                    open={Boolean(anchorForParam)}
                    anchorEl={anchorForParam}
                    onClose={()=>setAnchorForParam(null)}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <ParamEditor>
                        <ParamField>
                            <VehicleLabel>{recordSelected.vehPlates}</VehicleLabel>
                        </ParamField>
                        <ParamField>
                            <SelectFromList
                                id='route-monthly-list-month-from'
                                value={paramForListOfVeh.dateFrom}
                                list={monthList}
                                label="통계 조회 시작"
                                onChange={(id, value)=>{
                                    setParamForListOfVeh({...paramForListOfVeh, dateFrom:value});
                                }}
                            />
                            &nbsp;&nbsp;
                            <SelectFromList
                                id='route-monthly-list-month-till'
                                value={paramForListOfVeh.dateTill}
                                list={monthList}
                                label="통계 조회 종료"
                                onChange={(id, value)=>{
                                    setParamForListOfVeh({...paramForListOfVeh, dateTill:value});
                                }}
                            />
                        </ParamField>
                        <ParamField>
                            <Button size={AppWord.SMALL}
                                variant={AppPalette.VariantContained}
                                onClick={onClickToGetMonthlyListOfVeh}
                            >조회하기</Button>
                            &nbsp;&nbsp;
                            <Button size={AppWord.SMALL}
                                variant={AppPalette.VariantContained}
                                color={AppPalette.InheritColor}
                                onClick={()=>{setAnchorForParam(null)}}
                            >취소</Button>
                        </ParamField>
                    </ParamEditor>
                </Popover>
            </>
        );
    };

    const SearchInputId = "search-RouteMonthly-list";
    return(
        <RouteMonthlyListContainer>
            <HeaderBox>
				<HeaderControl>
                    <SelectFromList
                        id='route-monthly-list-month-selector'
                        value={routeDate}
                        list={monthList}
                        label="통계를 조회할 월"
                        onChange={onChangeRouteMonth}
                    />
                    &nbsp;&nbsp;
					<FormControl variant='outlined' size={AppWord.SMALL}>
						<InputLabel htmlFor={SearchInputId} style={{backgroundColor:'white'}}>검색</InputLabel>
						<OutlinedInput id={SearchInputId} onChange={onChangeSearch}
							value={search}
							disabled={records.length === 0}
							size={AppWord.SMALL}
							endAdornment={
								Boolean(search) ?
								<InputAdornment position={AppWord.END}>
									<IconButton
                                        onClick={()=>{setSearch('')}}
                                        size={AppWord.SMALL}
                                        edge={AppWord.END}
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
								:
								null
							}
						/>
					</FormControl>
				</HeaderControl>
                <HeaderRight>
                    {renderVehAndPopover()}
                    <FormControlLabel
                        control={<Checkbox
                            checked={showActiveOnly}
                            onChange={(event)=>{showRouteMonthlyActiveOnlyRepo(event.target.checked)}}
                        />}
                        label="이동거리가 있는 차량만 보기"
                    />
                </HeaderRight>
            </HeaderBox>
            <DataTableContainer>
                <DataTableWrapper>
                    {
                        recordsToShow.map((rec, index)=>{
                            return renderDataRecord(rec);
                        })
                    }
                </DataTableWrapper>
            </DataTableContainer>
        </RouteMonthlyListContainer>
    );
}