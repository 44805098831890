// term_stock/TermStockGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const TermStockDictionary = {
    inputMap: {
        ioDate : {
            id: 'ioDate', label: '날짜', type: 'date', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return true},
        },
        modelId : {
            id: 'modelId', label: '단말기 모델', type: 'select', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return value > 0 && value < 9999999999},
        },
        inCount : {
            id: 'inCount', label: '입고수량', type: 'number', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return value > 0 && value < 999999},
        },
        outCount : {
            id: 'outCount', label: '출고수량', type: 'number', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return value > 0 && value < 999999},
        },
        ioEtc : {
            id: 'ioEtc', label: '비고', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '200자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,500)},
        },

    },
    errorTitle: {
        Add: '재고 정보 생성 오류',
        Edit: '재고 정보 수정 오류',
        Remove: '재고 정보 삭제 오류',
        List: '재고 목록 조회 오류',
        Get: '재고 정보 조회 오류',
    }
};

const TERM_STOCK_LIST_PART = gql`
fragment TermStockListPart on TermStock {
    dataKey
    ioDate
    modelId
    model
    inCount
    outCount
    stockNow
    regUserId
    regUserName
    ioEtc
    regDate
}
`;



export const LIST_TERM_STOCK = gql`
${TERM_STOCK_LIST_PART}
query ListTermStock($dateFrom:String!, $dateTill:String!) {
    termStockList(dateFrom:$dateFrom, dateTill:$dateTill) {
        ...TermStockListPart
    }
}
`;

export const LIST_TERM_STOCK_ALL = gql`
query ListTermStockAll {
    termStockListSum {
        modelId
        model
        inCount
        outCount
        stockNow
        ioEtc
    }
}
`;

export const LIST_TERM_STOCK_AT = gql`
${TERM_STOCK_LIST_PART}
query ListTermStockAt($ioDate:String!) {
    termStockListAt(ioDate:$ioDate) {
        ...TermStockListPart
    }
}
`;

export const GET_TERM_STOCK = gql`
${TERM_STOCK_LIST_PART}
query GetTermStock($ioDate:String!, $modelId:Int!) {
    termStockItem(ioDate:$ioDate, modelId:$modelId) {
        ...TermStockListPart
    }
}
`;

export const ADD_TERM_STOCK = gql`
mutation AddTermStock($termStock:InputTermStockAdd!) {
  termStockAdd(termStock: $termStock) {
    ok
    message
  }
}
`;

export const EDIT_TERM_STOCK = gql`
mutation EditTermStock($termStock:InputTermStockEdit!) {
  termStockEdit(termStock: $termStock) {
    ok
    message
  }
}
`;

export const REMOVE_TERM_STOCK = gql`
mutation RemoveTermStock($termStock:InputTermStockRemove!) {
  termStockRemove(termStock: $termStock) {
    ok
    message
  }
}
`;