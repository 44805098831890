// VehTempr.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
// import { MiniTableColumns } from './VehPosGql';
import { AppNumber } from '../../model/AppConst';
import { FlexySize } from '../common/flexytable/FlexyTableStyled';
import VehTemprListRepeat, { MiniTableColumns } from './VehTemprListRepeat';
import VehRouteTemprList from '../veh_route/VehRouteTemprList';

const dcPad = AppNumber.dataContainerPadding;
const VehPosContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad,
    display:'flex'
});

const RouteItinBox = styled(Box)({
	paddingLeft:AppNumber.dataContainerPadding, position:"relative"
});

const RIVisibleStyle = {flexGrow:1};
const RIHiddenStyle = {visibility:"hidden", display:"none"};

const ShowMode = {PosList:1, Route: 2,};


export default function VehTempr() {
    const [mode, setMode] = useState(ShowMode.PosList);
	const [vehPosSelected, setVehPosSelected] = useState(null);
    const [duration, setDuration] = useState(null);
    const clientSize = useClientSize();


    const onClickShowRoute = (vehPos) => {
        onClickVehPosItem(vehPos);
        setMode(ShowMode.Route);
    };

    const onClickShowPosList = () => {
        onClickVehPosItem(null);
        setMode(ShowMode.PosList);
    };

    const onClickVehPosItem = (item) => {
        setVehPosSelected(item);
        setDuration(null);
    };

    // ---------------------------- Render Components ----------------------------

    const renderRouteOrItin = (width) => {
        if(vehPosSelected) {
            if(mode===ShowMode.Route) {
                return(
                    <VehRouteTemprList
                        vehPos={vehPosSelected}
                        maxWidth={width}
                        onClickShowPosList={onClickShowPosList}
                        durationGiven={duration}
                    />
                );
            }
        }
        return null;
    };

	const height = clientSize.dataAreaHeight;
	const width = clientSize.dataAreaWidth - dcPad * 2;
    const widthPosBox = mode === ShowMode.PosList
        ? width
        : MiniTableColumns.reduce((accumulator, item) => accumulator + item.width, 0) + FlexySize.IndexCellWidth + 20;
	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.

    return (
        <VehPosContainer>
            <VehTemprListRepeat
				boxHeight={height}
				boxWidth={widthPosBox}
				onClickItem={onClickVehPosItem}
                isMini={mode !== ShowMode.PosList}
                onClickShowRoute={onClickShowRoute}
            />
            <RouteItinBox
                style={mode === ShowMode.PosList ? RIHiddenStyle : RIVisibleStyle}
            >
                {renderRouteOrItin(width - widthPosBox - AppNumber.dataContainerPadding)}
            </RouteItinBox>
        </VehPosContainer>
    );
}
