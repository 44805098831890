//DownloadInfoItem.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import IsoIcon from '@mui/icons-material/Iso';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { AppPalette, AppWord } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { userInfoRepo } from '../../model/CvoModel';
import DownloadFileInput from './DownloadFileInput';
import ValueUtil from '../../model/ValueUtil';
import useClientSize from '../hook/useClientSize';

const InfoTitle = styled(DialogTitle)({
    backgroundColor: '#007', // AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const InfoContentRow = styled(Box)({
    padding: 0, display:'flex'
});

const ContentStyle = {flexGrow:1, overflow:'auto', borderRadius:5, border: AppPalette.BorderCCC, height:300, padding:10};
const TextBox = styled(Box)({...ContentStyle, whiteSpace:'pre-line'});
const MarkdownBox = styled(Box)(ContentStyle);

const FileItemBox = styled(Box)({padding:10, borderBottom: AppPalette.BorderCCC, fontSize:'0.85em'});

const PairBox = styled(Box)({ height:240, display:'flex', width:'100%', });

export default function DownloadInfoItem({
    item,
    open,
    onClose,
    cvoFiles,
}) {
    const [downloadFiles, setDownloadFiles] = useState([]);
    const [adminMode, setAdminMode] = useState(false);
    const clientSize = useClientSize();
	const sessionInfo = useReactiveVar(userInfoRepo);

	const isStaff = ValueUtil.isEtrace(sessionInfo);

    useEffect(() => {
        if(item.files) setDownloadFiles(item.files);
        else setDownloadFiles([]);
    }, [item]);

    const onClickClose = () => {
        setAdminMode(false);
        onClose();
    };

    const onCloseFileInputBox = () => {
        setAdminMode(false);
    };

    const renderFileList = () => {
        if(isStaff && adminMode) {
            return(
                <PairBox>
                    <DownloadFileInput
                        downloadInfo={item}
                        cvoFiles={cvoFiles}
                        downloadFiles={downloadFiles}
                        setDownloadFiles={(files)=>{setDownloadFiles(files)}}
                        onClose={onCloseFileInputBox}
                    />
                </PairBox>
            );
        }
        else if(downloadFiles.length > 0) {
            return(
                <Box>
                    {
                        isStaff  ?
                        <Box><Button onClick={()=>{setAdminMode(true)}}><IsoIcon fontSize={AppWord.SMALL} /> 첨부파일 관리</Button></Box>
                        : null
                    }
                    {
                        downloadFiles.map((file) => {
                            return(
                                <FileItemBox key={file.fileNid}>
                                    첨부 : &nbsp;
                                    <a href={file.driveLink} target="_blank" rel="noopener noreferrer">
                                        <DownloadIcon fontSize={AppWord.SMALL} color={AppPalette.PrimaryColor}
                                            style={{verticalAlign:'bottom'}}
                                        />
                                        {file.title}
                                    </a>
                                </FileItemBox>
                            );
                        })
                    }
                </Box>
            );
        }
        else {
            return (
                <Box>첨부파일이 없습니다. &nbsp; &nbsp; &nbsp;
                    {
                        isStaff
                        ?
                        <Button onClick={()=>{setAdminMode(true)}}>
                            <IsoIcon fontSize={AppWord.SMALL} /> 첨부파일 관리
                        </Button>
                        : null
                    }
                </Box>
            );
        }
    };

    const BoxWidth = clientSize.width * (clientSize.width >= 1000 ? 0.84 : 0.94);

    return (
        <Dialog open={open} onClose={onClickClose} maxWidth="lg">
            <InfoTitle>
                {item.title}

                <IconButton
                    aria-label="close-download-info"
                    onClick={onClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </InfoTitle>
            <DialogContent>
                <InfoContentRow style={{width:BoxWidth, marginTop:10}}>
                    {
                        item.textFormat==='markdown'
                        ?
                        <MarkdownBox>
                            <ReactMarkdown>{item.contents}</ReactMarkdown>
                        </MarkdownBox>
                        :
                        <TextBox>{item.contents}</TextBox>
                    }
                </InfoContentRow>
                <InfoContentRow>
                {renderFileList()}
                </InfoContentRow>
            </DialogContent>
        </Dialog>
    );
}

/*

            <DialogActions>
                <Button
                    onClick={onClose}
                    color={AppPalette.PrimaryColor} variant={AppPalette.VariantContained}
                >
                    닫기
                </Button>
            </DialogActions>

*/
