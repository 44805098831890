// BcdIoDataList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Button } from '@mui/material';
import LocalParkingIcon from '@mui/icons-material/LocalParking'; // staying
import AirIcon from '@mui/icons-material/Air'; // Not staying
import TimelineIcon from '@mui/icons-material/Timeline'; // Route
import ResponseAlert from '../message/ResponseAlert';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType } from '../../model/AppConst';
import { useLazyQuery } from '@apollo/client';
import { LIST_BCD_IO_DATA } from './BcdIoDataGql';
import DurationPicker from '../common/DurationPicker';

const BcdIoDataListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display: 'flex', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding, alignItems: 'center'
});

const ControllerBox = styled(Box)({
    display: 'flex', alignItems: 'center'
});

const TabBox = styled(Box)({
    flexGrow:1,
    textAlign: 'right', display: 'flex', alignItems: 'center', paddingLeft: 20
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'poiNamePrev', label: '이전지점', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'depTimePrev', label: '이전지점 출발시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'distKm', label: '이동거리(Km)', width: 90, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'secRun', label: '이동시간', width: 90, show: true, option:{align: 'right', print:(r,i,v)=>{return ValueUtil.formatDuration(v)}} },
    { id: 'poiName', label: '지점', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'arrTime', label: '도착시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'depTime', label: '출발시각', width: 160, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },
    { id: 'secStay', label: '머문시간', width: 90, show: true, option:{align: 'right', print:(r,i,v)=>{return ValueUtil.formatDuration(v)}} },
    { id: 'staying', label: '방문상태', width: 80, show: true, option:{align: 'center', print:(r,i,v) => {
        if(v) return <><LocalParkingIcon fontSize={AppWord.SMALL} color="primary" style={{verticalAlign:'bottom'}}/> 방문 중</>
        else return <><AirIcon fontSize={AppWord.SMALL} color="disabled" style={{verticalAlign:'bottom'}}/> 떠남</>
    }} },
    { id: 'custName', label: '업체명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

const EmptyDuration = {from:ValueUtil.getYmdHmsInputText(null, 86400), till:ValueUtil.getYmdHmsInputText(null)};

export default function BcdIoDataList({
    tabSelector,
	maxHeight,
	maxWidth,
    onRequestViewRoute,
}) {
    const [records, setRecords] = useState([]);
    const [duration, setDuration] = useState(EmptyDuration);
    const [search, setSearch] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getBcdIoDataList, responseList] = useLazyQuery(LIST_BCD_IO_DATA, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '출발, 도착 기록 조회 오류'})}
    });

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(data.bcdIoDataList) {
            setRecords(data.bcdIoDataList);
        }
    };

    const onChangeFrom = (from) => {
        const dur = {...duration};
        dur.from = from;
        setDuration(dur);
    };

    const onChangeTill = (till) => {
        const dur = {...duration};
        dur.till = till;
        setDuration(dur);
    };

    const onClickToGetList = () => {
        if(duration.from && duration.till)
            getBcdIoDataList({
                variables: {
                    timeFrom: duration.from,
                    timeTill: duration.till}
            });
    };

    const onClickToolOnRow = (toolId, row) => {
        if(toolId === AppWord.ROUTE) {
            if(onRequestViewRoute) {
                // find timeFrom.
                const timeTill = row.arrTime;
                let timeFrom = duration.from;
                let prevRec = {vehId:null};
                for(const rec of records) {
                    if(rec.vehId===row.vehId) {
                        if(row.vehId===prevRec.vehId && row.arrTime === rec.arrTime) {
                            timeFrom = prevRec.depTime;
                            break;
                        }
                        prevRec = rec;
                    }
                }
                onRequestViewRoute({vehId:row.vehId, vehPlates:row.vehPlates}, timeFrom, timeTill);
            }
        }
    };

    return(
        <BcdIoDataListContainer>
            <HeaderBox>
                <ControllerBox>
                    <DurationPicker
                        from={duration.from} onChangeFrom={onChangeFrom}
                        till={duration.till} onChangeTill={onChangeTill}
                    />
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={!Boolean(duration)}
                        onClick={onClickToGetList}
                        style={{marginLeft:10, marginRight:20}}
                    >
                        조회
                    </Button>
                </ControllerBox>
                <TabBox>
                    {tabSelector}
                </TabBox>
            </HeaderBox>
            <FlexyTable
                name="bcd_io_data_list"
                uniqueKey="dataKey"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[{id:AppWord.ROUTE, icon:<TimelineIcon fontSize={AppWord.SMALL} color="primary" />}]}
                onClickTool={onClickToolOnRow}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </BcdIoDataListContainer>
    );
}

/*

           //// <PanTable
                name="bcd_io_data_list"
                uniqueKey="dataKey"
                height={TableHeight} width={width}
                columns={TableColumns}
                records={records}
                search={search}
                tools={[{id:AppWord.ROUTE, icon:<TimelineIcon fontSize={AppWord.SMALL} color="primary" />}]}
                onClickTool={onClickToolOnRow}
            />///

*/
