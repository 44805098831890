// user_get_alarm/UserGetAlarmGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const UserGetAlarmDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        userId : { toAdd: true, toEdit: true, },
        alarmTypeId : { toAdd: true, toEdit: true, },
        timeFrom : {
            id: 'timeFrom', label: '알람수신시작시각', type: 'time', required: false,
            toAdd: true, toEdit: true,
            default: '08:00:00',
            check: (value) => {return ValueUtil.textInRange(value,1,38)},
        },
        timeTill : {
            id: 'timeTill', label: '알람수신종료시각', type: 'time', required: false,
            toAdd: true, toEdit: true,
            default: '19:00:00',
            check: (value) => {return ValueUtil.textInRange(value,1,38)},
        },
        alarmInterval : {
            id: 'alarmInterval', label: '알람수신간격(분)', type: 'number', required: true,
            toAdd: true, toEdit: true,
            default: 30,
            check: (value) => {return value >= 10 && value <= 1440},
        },
    },
    // for response error printing
    errorTitle: {
        Add: '알람수신설정 저장 오류',
        Remove: '알람수신설정 삭제 오류',
        List: '알람수신설정 조회 오류',
    }
};



export const LIST_USER_GET_ALARM = gql`
query ListUserGetAlarm($userId:String) {
    userGetAlarmList(userId:$userId) {
        userId
        alarmTypeId
        alarmType
        timeFrom
        timeTill
        alarmInterval
        regUserId
        regDate
    }
}
`;

export const ADD_USER_GET_ALARM = gql`
mutation SetUserGetAlarm($userGetAlarm:InputUserGetAlarmSet!) {
  userGetAlarmSet(userGetAlarm: $userGetAlarm) {
    ok
    message
    list {
        userId
        alarmTypeId
        alarmType
        timeFrom
        timeTill
        alarmInterval
    }
  }
}
`;

export const REMOVE_USER_GET_ALARM = gql`
mutation RemoveUserGetAlarm($userGetAlarm:InputUserGetAlarmRemove!) {
  userGetAlarmRemove(userGetAlarm: $userGetAlarm) {
    ok
    message
    list {
        userId
        alarmTypeId
        alarmType
        timeFrom
        timeTill
        alarmInterval
    }
  }
}
`;