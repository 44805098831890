// NotifyBoardInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { AppPalette, AppObject, AppWord } from '../../model/AppConst';
import { NotifyBoardDictionary } from './NotifyBoardGql';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ConfirmDialog from '../message/ConfirmDialog';
import RadioSelector from '../common/RadioSelector';
import ValueUtil from '../../model/ValueUtil';
import useClientSize from '../hook/useClientSize';

const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({ padding: 0, display:'flex' });

const InputColumn = styled(Box)({
    flexGrow: 1, paddingTop: 10, margin: ColumnMargin, verticalAlign: 'top', display:'flex', flexDirection:'column'
});

const PreviewBox = styled(Box)({flexGrow:1, overflow:'auto', borderRadius:5, border: AppPalette.BorderCCC});

// const FieldBox = styled(Box)({ display: 'block', paddingTop: 10, });
const InputMap = NotifyBoardDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.


export default function NotifyBoardInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.
    const clientSize = useClientSize();

    useEffect(()=>{
        if(open) {
            if(item && isEdit) {
                const d = {...item};
                setInputData(d);
            }
            else setInputData(defaultInputData);
            setInputError({});
            setChanged(false);
            setHasError(false);
        }
    }, [item, open, isEdit]);

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // add extra data if necessary.
        if(isEdit) param.msgSeq = item.msgSeq;

        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '공지사항 정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput2(event, InputMap, inputData, inputError, isEdit);
        
        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

	// If necessary
    const onChangeRadioSelector = (event) => {
        const data = {...inputData};
        data[event.target.id] = event.target.value;
        setInputData(data);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const BoxWidth = clientSize.width * 0.7; // >= 1000 ? clientSize.width * 0.84 : clientSize.width * 0.94;

    return (
        <Dialog open={open} maxWidth="lg">
            <EditorTitle>
                {isEdit ? '공지사항 정보 수정' : '공지사항 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow minWidth={BoxWidth} style={{paddingTop:10}}>
                    <InputColumn style={{paddingRight:10}}>
                            <TextField id={InputMap.title.id} size={AppWord.SMALL}
                                type={InputMap.title.type}
                                label={InputMap.title.label}
                                required={InputMap.title.required}
                                value={inputData.title || ''}
                                error={inputError.title}
                                helperText={InputMap.title.help}
                                onChange={onChangeTextData}
                                fullWidth
                            />
                    </InputColumn>
                    <Box>
                            <RadioSelector
                                label={InputMap.textFormat.label}
                                id={InputMap.textFormat.id}
                                selectFrom={AppObject.TEXT_FORMATS}
                                initialValue={inputData.textFormat}
                                onChange={onChangeRadioSelector}
                                horizontal={true}
                            />
                    </Box>
                </EditorContentRow>
                <EditorContentRow>
                    <InputColumn>
                            <Box style={{fontWeight:'bold'}}>본문내용</Box>
                            <TextField id={InputMap.contents.id}
                                multiline rows={8}
                                type={InputMap.contents.type}
                                required={InputMap.contents.required}
                                value={inputData.contents || ''}
                                error={inputError.contents}
                                onChange={onChangeTextData}
                                fullWidth />
                    </InputColumn>
                    {
                        inputData.textFormat==="markdown"
                        ?
                        <InputColumn maxHeight={250} maxWidth={BoxWidth/2-10}>
                            <Box style={{fontWeight:'bold'}}>미리보기</Box>
                            <PreviewBox>
                                <ReactMarkdown>{inputData.contents}</ReactMarkdown>
                            </PreviewBox>
                        </InputColumn>
                        :
                        null
                    }
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}
