//HelpTopic.js
import React from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';

const HelpBox = styled(Box)({
    padding:0,
    borderRadius: 10
});

const TitleBox = styled(Box)({
    padding: 15,
    fontSize: 'large',
    fontWeight: 'bold',
    borderBottom: '1px solid #cccccc'
});

const TextBox = styled(Box)({
    padding: 15,
    fontSize:'0.95rem'
});


export default function HelpTopic({
    id,
    title,
    content,
    borderColor
}) {

    const boxBorderStyle = borderColor ? {border:'1px solid '+borderColor} : {border: '1px solid white'};

    return (
        <HelpBox id={id} style={boxBorderStyle}>
            <TitleBox>{title}</TitleBox>
            <TextBox>{content}</TextBox>
        </HelpBox>
    )
}
