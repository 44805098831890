// GroupVehAlloc.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { IconButton, TextField, Button } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LIST_VEH_INFO_OVER_GROUP, ADD_GROUP_VEH_ALLOC, REMOVE_GROUP_VEH_ALLOC, EMPTY_GROUP_VEH_ALLOC } from './GroupVehAllocGql';
import TossTable from '../common/TossTable';
import { AppObject, AppWord } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { NoUser, userInfoRepo } from '../../model/CvoModel';

const AllocBox = styled(Box)({ // has TitleBox and PairBox
    flexGrow:1,
    display:'flex',
    flexDirection: 'column',
});

const TitleBox = styled(Box)({fontWeight:'bold', padding:10, fontSize: '1.1rem', borderBottom:'1px solid #ccc'});

const PairBox = styled(Box)({ // has 2 SetBox
    display:'flex',
    flexGrow:1,
});

const SetBox =  styled(Box)({ // has SetTitleBox, SearchBox and TableBox
    flexGrow:1,
    display:'flex',
    flexDirection: 'column',
    marginLeft:3,
    marginRight:3,
    width:'50%'
});
const SetTitleBox = styled(Box)({fontWeight:'bold', padding:10});
const SearchBox = styled(Box)({padding:5});

const TableBox = styled(Box)({
    flexGrow:1,
    border:'1px solid #ccc',
    overflow:'auto',
    borderRadius:5,
    position:'relative'
});


const ErrorToAdd = "차량의 그룹배정 오류";
const ErrorToRemove = "차량의 그룹배정 해제(삭제) 오류";
const ErrorToEmpty = "차량의 그룹배정 완전삭제 오류";

const AllocTableColumns = [
    {id:'vehPlates', minWidth:80, label:'차량'},
    {id:'driverName', label:'운전자'}
];

export default function GroupVehAlloc({
    groupId,
    groupName,
    onCloseView,
    informHowmanyInGroup
}) {
    const [vehListIn, setVehListIn] = useState([]);
    const [vehListOut, setVehListOut] = useState([]);
    const [searchIn, setSearchIn] = useState('');
    const [searchOut, setSearchOut] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);

    // ##### Call GraphQL to get List #####
    const [getVehListOverGroup, responseList] = useLazyQuery(LIST_VEH_INFO_OVER_GROUP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetVehList(data, option)}, 
		onError: (error) => {setResponseAlert({open:true, error: error, title: "그룹배정 차량목록 조회 오류"})}
    });
    const [addGroupVehAlloc, responseAdd] = useMutation( ADD_GROUP_VEH_ALLOC, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorToAdd})}
	} );
    const [removeGroupVehAlloc, responseRemove] = useMutation( REMOVE_GROUP_VEH_ALLOC, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorToRemove})}
	});
    const [emptyGroupVehAlloc, responseEmpty] = useMutation( EMPTY_GROUP_VEH_ALLOC, {
		onCompleted: (data, option) => onCompleteEmpty(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorToEmpty})}
	});

    useEffect(()=>{
        if(groupId) getVehListOverGroup({variables: {groupId: groupId}});
    }, [groupId, getVehListOverGroup]);

    const devideVehList = (list) => {
        const groupIn = [];
        const groupOut = [];
        for(const g of list) {
            if(g.groupId) groupIn.push(g);
            else groupOut.push(g);
        }
        setVehListIn(groupIn);
        setVehListOut(groupOut);
        if(informHowmanyInGroup) informHowmanyInGroup(groupId, groupIn.length);
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.groupVehAllocAdd.ok) {
            devideVehList(data.groupVehAllocAdd.list);
        }
        else setResponseAlert({open:true, resultData: data.groupVehAllocAdd, title: ErrorToAdd});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.groupVehAllocRemove.ok) devideVehList(data.groupVehAllocRemove.list);
        else setResponseAlert({open:true, resultData: data.groupVehAllocRemove, title: ErrorToRemove});
    };

    const onCompleteEmpty = (data, clientOption) => {
        if(data.groupVehAllocEmpty.ok) devideVehList(data.groupVehAllocEmpty.list);
        else setResponseAlert({open:true, resultData: data.groupVehAllocEmpty, title: ErrorToEmpty});
    };

    // <<<<<<<<<<< onComplete:: gql query <<<<<<<<<<<<<<<<
    // 최초 목록 가져오기 완료. 2개의 목록으로 분리해 준다.
    const onCompleteGetVehList = (data, clientOption) => {
        if(data.vehInfoListOverGroup) {
            devideVehList(data.vehInfoListOverGroup);
        }
    };

    // >>>>>>>>>>>> callback >>>>>>>>>>>>>
    const onClickAlloc = (rec) => {
        addGroupVehAlloc({variables: {groupVehAlloc: {vehId: rec.vehId, groupId: groupId}}});
    };

    const onClickDealloc = (rec) => {
        removeGroupVehAlloc({variables: {groupVehAlloc: {vehId: rec.vehId, groupId: groupId}}});
    };

    const onClickEmpty = () => {
        setPromptToConfirm({
            data: true,
            title: '그룹에 배정된 모든 차량의 배정 해제',
            messages: ['그룹에 배정된 모든 차량의 배정을 해제합니다.', '계속하시겠습니까?'],
            callback: (yes) => {
                setPromptToConfirm(null);
                if(yes) {
                    emptyGroupVehAlloc({variables: {groupId: groupId}});
                }
            }
        });
    };

    if(!groupId) return null;

    if(ValueUtil.hasAnyAuthError(
        responseAdd, responseRemove, responseList, responseEmpty
    )) userInfoRepo(NoUser);

    const renderPromptToConfirm = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    return(
        <AllocBox>
            <TitleBox>
                {
                    onCloseView ?
                    <Button variant='contained'
                        onClick={onCloseView}
                        size={AppWord.SMALL}
                        color='inherit'
                        style={{float:'right',display:'relative'}}
                    >
                        <VisibilityOffIcon fontSize={AppWord.SMALL} />
                        숨기기
                    </Button>
                    : null
                }
                {groupName} 그룹 차량 배정
            </TitleBox>
            <PairBox>
                <SetBox>
                    <SetTitleBox>
                        배정된 차량
                    </SetTitleBox>
                    <SearchBox>
                        {
                            vehListIn.length > 1
                            ?
                            <IconButton size='small' title='모두 삭제!' color='secondary'
                                style={{float:'right'}}
                                onClick={onClickEmpty}
                            >
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                            : null
                        }
                        <TextField size="small" label="검색"
                            value={searchIn}
                            onChange={(e) => setSearchIn(e.target.value)}
                        />
                        {
                            searchIn ?
                            <IconButton size="small"
                                onClick={()=>{setSearchIn('')}}
                            >
                                <ClearIcon fontSize='small'/>
                            </IconButton> : null
                        }
                    </SearchBox>
                    <TableBox>
                        <TossTable
                            columns={AllocTableColumns}
                            records={vehListIn}
                            search={searchIn}
                            onClickSend={onClickDealloc}
                            isLeft={true}
                        />
                    </TableBox>
                </SetBox>
                <SetBox>
                    <SetTitleBox>배정 가능한 차량</SetTitleBox>
                    <SearchBox>
                        <TextField size="small" label="검색"
                            value={searchOut}
                            onChange={(e) => setSearchOut(e.target.value)}
                        />
                        {
                            searchOut ?
                            <IconButton size="small"
                                onClick={()=>{setSearchOut('')}}
                            >
                                <ClearIcon fontSize='small'/>
                            </IconButton> : null
                        }
                    </SearchBox>
                    <TableBox>
                        <TossTable
                            columns={AllocTableColumns}
                            records={vehListOut}
                            search={searchOut}
                            onClickSend={onClickAlloc}
                        />
                    </TableBox>
                </SetBox>
            </PairBox>
            {renderPromptToConfirm()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </AllocBox>
    );
}
