// MapSweeperStat.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
//import useClientSize from '../hook/useClientSize';
import MapSweeperStatList from './MapSweeperStatList';
import MapSweeperRoute from './MapSweeperRoute';
import { sweeperSelectedRepo } from '../../model/CvoModel';
import { useReactiveVar } from '@apollo/client';
import ResponseAlert from '../message/ResponseAlert';

const TheContainer = styled(Box)({flexGrow: 1, display:'flex', flexDirection:'column'});
const StatBox = styled(Box)({flexGrow:3, display:'flex'});
const RouteBox = styled(Box)({flexGrow:2, display:'flex'});

export default function MapSweeperStat({
    mapShell
}) {
    const sweeperSelected = useReactiveVar(sweeperSelectedRepo);
    const [responseAlert, setResponseAlert] = useState(null);
    //const sweeperChanged = useRef();


    return (
        <TheContainer>
            <StatBox>
                <MapSweeperStatList
                    mapShell={mapShell}
                />
            </StatBox>
            <RouteBox>
                <MapSweeperRoute
                    mapShell={mapShell}
                    sweeperStat={sweeperSelected}
                />
            </RouteBox>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </TheContainer>
    );
}
