// cvo_file/CvoFileGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";
import { TableColumnType } from "../../model/AppConst";

const cvoInputMap = {
    // toAdd, toEdit : mark if need to submit for mutation
    fileNid : { toAdd: false, toEdit: true, },
    url : {
        id: 'url', label: '파일 URL', type: 'text', required: true,
        toAdd: true, toEdit: true,
        help:'누구든 접근 가능한 URL을 제공해야 합니다.',
        check: (value) => {return ValueUtil.isHttpUrl(value);},
    },
    forWhom : {
        id: 'forWhom', label: '대상업체', type: 'text', required: true,
        toAdd: true, toEdit: true,
        default: 'all',
        check: (value) => {return 'all'===value || ValueUtil.textInRange(value,10,10)},
    },
    memo : {
        id: 'memo', label: '제목', type: 'text', required: true,
        toAdd: true, toEdit: true,
        check: (value) => {return ValueUtil.textInRange(value,1,1000)},
    },
    fileSize : {
        id: 'fileSize', label: 'File Size', type: 'number', required: false,
        toAdd: true, toEdit: true,
        default:1,
        help: '참고용',
        check: (value) => {return value > 0},
    },
    conType : {
        id: 'conType', label: '파일타입', type: 'text', required: false,
        toAdd: true, toEdit: true,
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,60)},
    },
};

export const CvoFileDictionary = {
    inputMap: cvoInputMap,
    SelectableListColumns: [
        // 파일 다운로드, 공지 등에서 선택할 목록에서 사용하는 칼럼들.
        { id: 'memo', label: cvoInputMap.memo.label, width: 160, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
        { id: 'fileSize', label: cvoInputMap.fileSize.label, width: 90, show: true, option:{align: 'center', print: (r,i,v) => {return ValueUtil.getSizeInText(v)}} },
    ],
    errorTitle: {
        Add: '파일 정보 생성 오류',
        Edit: '파일 정보 수정 오류',
        Remove: '파일 정보 삭제 오류',
        Get: '파일 정보 조회 오류',
        List: '파일 목록 조회 오류',
    }
};

const CVO_FILE_LIST_PART = gql`
fragment CvoFileListPart on CvoFile {
    fileNid
    filePath
    driveLink
    forWhom
    memo
    fileSize
    conType
    usingDownload
    usingNotify
    regUserId
    regTime
}
`;

export const LIST_CVO_FILE = gql`
${CVO_FILE_LIST_PART}
query ListCvoFile($forWhom:String) {
    cvoFileList(forWhom:$forWhom) {
        ...CvoFileListPart
    }
}
`;

export const GET_CVO_FILE = gql`
${CVO_FILE_LIST_PART}
query GetCvoFile($fileNid:Int) {
    cvoFileItem(fileNid:$fileNid) {
        ...CvoFileListPart
    }
}
`;

export const ADD_CVO_FILE = gql`
${CVO_FILE_LIST_PART}
mutation AddCvoFile($cvoFile:InputCvoFileAdd!) {
    cvoFileAdd(cvoFile: $cvoFile) {
        ok
        message
        list {
            ...CvoFileListPart
        }
    }
}
`;

export const EDIT_CVO_FILE = gql`
${CVO_FILE_LIST_PART}
mutation EditCvoFile($cvoFile:InputCvoFileEdit!) {
    cvoFileEdit(cvoFile: $cvoFile) {
        ok
        message
        list {
            ...CvoFileListPart
        }
    }
}
`;

export const REMOVE_CVO_FILE = gql`
${CVO_FILE_LIST_PART}
mutation RemoveCvoFile($cvoFile:InputCvoFileRemove!) {
    cvoFileRemove(cvoFile: $cvoFile) {
        ok
        message
        list {
            ...CvoFileListPart
        }
    }
}
`;
