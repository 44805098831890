// VehTonInfoGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

const InputDict = {
    vehTypeName : {
        id: 'vehTypeName', label: '차종', type: 'text', required: true,
        toAdd: true, toEdit: true,
        help: '10자 이내로 입력하세요.',
        check: (value) => {return  ValueUtil.textInRange(value,1,32)},
    },
    vehTypeDesc : {
        id: 'vehTypeDesc', label: '차종설명', type: 'text', required: false,
        toAdd: true, toEdit: true,
        help: '30자 이내로 입력하세요.', width: 300,
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,100)},
    },
    // Ton
    ton : {
        id: 'ton', label: '톤수', type: 'text', required: true,
        toAdd: true, toEdit: true,
        help: '32자 이내로 입력하세요.',
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,32)},
    },
    tonDesc : {
        id: 'tonDesc', label: '톤수 설명', type: 'text', required: false,
        toAdd: true, toEdit: true,
        help: '100자 이내로 입력하세요.', width: 300,
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,100)},
    },
    operFontColor : {
        id: 'operFontColor', label: '운행중 전경색', type: 'color', required: false,
        toAdd: true, toEdit: true,
        default: '#000000',
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isColorFormat(value)},
    },
    operBackColor : {
        id: 'operBackColor', label: '운행중 배경색', type: 'color', required: false,
        toAdd: true, toEdit: true,
        default: '#FFFFFF',
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isColorFormat(value)},
    },
    noOperFontColor : {
        id: 'noOperFontColor', label: '비운행중 전경색', type: 'color', required: false,
        toAdd: true, toEdit: true,
        default: '#555555',
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isColorFormat(value)},
    },
    noOperBackColor : {
        id: 'noOperBackColor', label: '비운행중 배경색', type: 'color', required: false,
        toAdd: true, toEdit: true,
        default: '#DDDDDD',
        check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isColorFormat(value)},
    },
};

export const VehTonInfoDictionary = {
    inputMap: {
        vehTonId : { toAdd: false, toEdit: true },
        vehTypeId : { toAdd: true, toEdit: false },
        ton: InputDict.ton,
        tonDesc: InputDict.tonDesc,
        operFontColor : InputDict.operFontColor,
        operBackColor : InputDict.operBackColor,
        noOperFontColor : InputDict.noOperFontColor,
        noOperBackColor :InputDict.noOperBackColor,
    },

    inputMapType: {
        vehTypeId : { toAdd: false, toEdit: true },
        vehTypeName: InputDict.vehTypeName,
        vehTypeDesc: InputDict.vehTypeDesc,
    },

    inputMapAll: {
        vehTypeName: InputDict.vehTypeName,
        vehTypeDesc: InputDict.vehTypeDesc,
        ton: InputDict.ton,
        tonDesc: InputDict.tonDesc,
        operFontColor : InputDict.operFontColor,
        operBackColor : InputDict.operBackColor,
        noOperFontColor : InputDict.noOperFontColor,
        noOperBackColor :InputDict.noOperBackColor,
    },
    errorTitle: {
        GetTree: '차종, 톤수 트리 데이터 조회 오류',
        AddTon: '톤수 정보 생성 오류',
        EditTon: '톤수 정보 수정 오류',
        RemoveTon: '톤수 정보 삭제 오류',
        GetTon: '톤수 정보 조회 오류',
        AddTypeTon: '차종,톤수 일괄 생성 오류',
        EditType: '차종 정보 수정 오류',
        RemoveType: '차종 및 하위톤수 일괄 삭제 오류',
        GetType: '차종 정보 조회 오류',
    }
}

const VEH_TON_INFO_LIST_PART = gql`
fragment VehTonInfoListPart on VehTonInfo {
    custId
    vehTonId
    vehTypeId
    vehTypeName
    ton
    vehTypeTon
    tonDesc
    operFontColor
    operBackColor
    noOperFontColor
    noOperBackColor
    emerFontColor
    emerBackColor
    offFontColor
    offBackColor
    regUserId
    regDate
}
`;

export const LIST_VEH_TON_INFO_SIMPLE = gql`
query ListVehTonInfo($custId:String) {
    vehTonInfoList(custId:$custId) {
        vehTonId
        vehTypeId
        vehTypeName
        ton
    }
}
`;

export const LIST_VEH_TON_INFO = gql`
${VEH_TON_INFO_LIST_PART}
query ListVehTonInfo($custId:String) {
    vehTonInfoList(custId:$custId) {
        ...VehTonInfoListPart
    }
}
`;

export const GET_VEH_TON_INFO = gql`
${VEH_TON_INFO_LIST_PART}
query GetVehTonInfo($vehTonId: Int!) {
    vehTonInfoItem(vehTonId:$vehTonId) {
        ...VehTonInfoListPart
    }
}
`;


export const ADD_VEH_TYPE_TON_SET = gql`
mutation AddVehTonInfoSet($vehTonInfo:InputVehTonInfoAddSet!) {
    vehTonInfoAddSet(vehTonInfo: $vehTonInfo) {
        ok
        message
    }
}
`;


export const ADD_VEH_TON_INFO = gql`
mutation AddVehTonInfo($vehTonInfo:InputVehTonInfoAdd!) {
    vehTonInfoAdd(vehTonInfo: $vehTonInfo) {
        ok
        message
    }
}
`;

export const EDIT_VEH_TON_INFO = gql`
mutation EditVehTonInfo($vehTonInfo:InputVehTonInfoEdit!) {
    vehTonInfoEdit(vehTonInfo: $vehTonInfo) {
        ok
        message
    }
}
`;

export const REMOVE_VEH_TON_INFO = gql`
mutation RemoveVehTonInfo($vehTonInfo:InputVehTonInfoRemove!) {
    vehTonInfoRemove(vehTonInfo: $vehTonInfo) {
        ok
        message
    }
}
`;
