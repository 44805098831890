// CvoLogin.js
import React, {useState, useEffect} from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { TextField, Button } from '@mui/material';
import { Waiting } from '../message/Waiting';
import { LOG_IN } from './SessionGql';
import {useLazyQuery} from '@apollo/client';
import {userInfoRepo} from '../../model/CvoModel';
import { CvoMsg, ErrorMsg, AppObject, AppWord, AppPalette } from '../../model/AppConst';
import NotifyBoard from '../notify_board/NotifyBoard';
import UserSessionInfo from '../common/UserSessionInfo';
import Util from '../../model/Util';
import useClientSize from '../hook/useClientSize';
import LogInLogo from './etrace_login_logo.svg';

const MinBoardHeight = 460;

const OuterLoginContainer = styled(Box)({
    width: '100%',
    textAlign:'center',
    verticalAlign:'bottom',
    paddingTop:30,
});

const InnerContainer = styled(Box)({ display:'inline-flex', flexDirection:'column', verticalAlign:'bottom', minWidth:1120 });

const BottomBar = styled(Box)({
    display:'block',
    color:'#333333',
    backgroundColor:'#dbdbea',
    padding:5,
    fontSize:'small',
    borderRadius:30,
    border:'1px solid #9595a0',
    marginTop:10,
});
// In InnerContainer, above BottomBar. CONTAINS LeftBox + MainBox
const ControlContainer = styled(Box)({ flexGrow:1, display:'flex' });

const LeftBox = styled(Box)({ width:240, paddingRight: 10, paddingBottom:10, display:'flex', flexDirection:'column'});
const UpperFiller = styled(Box)({flexGrow:1});
// 공지사항
const MainBox = styled(Box)({
    flexGrow: 1,
    minHeight: MinBoardHeight,
    textAlign:'left',
    display:'flex', flexDirection:'column'
});

const MainTitle = styled(Box)({
    padding:10, fontWeight:'bold', backgroundColor: AppPalette.HeaderBackground, color:'white',
    borderRadius:20, textAlign:'center'
});
const BoardBox = styled(Box)({
    flexGrow:1,
    padding: 5,
    position:'relative',
});

const SmallTitle = styled(Box)({
    backgroundColor:'#1c1c60',
    border: '1px solid #1c1c60',
    color:'#fff',
    fontWeight:'bold',
    textAlign:'center',
    width: '100%',
    marginTop: 5, marginBottom: 5,
    paddingTop: 10, paddingBottom: 10,
    borderRadius: 20
});

// ######################
const InfoTable = styled(Box)({
    display:'table',
    border: '1px solid #cccccc',
    width: '100%',
    borderRadius: 5
});
const TableRow = styled(Box)({display:'table-row'});
const TableTitleCell = styled(Box)({display:'table-cell', textAlign:'center', paddingTop: 10, paddingBottom: 10, fontSize:'0.9em', color:'#333'});
const TableCell = styled(Box)({display:'table-cell', textAlign:'left', paddingTop: 10, paddingBottom: 10, fontWeight:'bold',});


export default function CvoLogin() {
    const [logInNow, {loading, error, data}]
        = useLazyQuery(LOG_IN, AppObject.NoCachedFetch);
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [showTestUsers, setShowTestUsers] = useState(false);
    const [loginError, setLoginError] = useState(undefined);
    const clientSize = useClientSize();

    useEffect(() => {
        const id = localStorage.getItem(AppWord.LOCAL_ID_KEY);
        if(id) setUserId(id);
    }, []);

    useEffect(() => {
        if(data && data.login) {
            if(data.login.user.msg===CvoMsg.OK) {
                setLoginError(undefined);
                // userInfoRepo({...data.login, signedIn: true, sessionChecked: true});
                userInfoRepo(new UserSessionInfo(true, true, data.login));
                localStorage.setItem(AppWord.LOCAL_ID_KEY, userId);
                setPassword(null);
                Util.bubbleSnack("이트레이스 차량관제입니다. 반갑습니다.");
            }
            else
                setLoginError(
                    data.login.user.msg===ErrorMsg.AUTH_ERROR 
                    ? '아이디/암호가 맞는지 확인해 주세요' : data.login.user.msg);
        }
    }, [data, userId]);

    const IsDevVSCode = window.location.href.startsWith("http://localhost");
    const IsDevLocal = window.location.href.startsWith("http://");

    const onClickSignIn = (e) => {
        logInNow({variables:{userId:userId, password:password}});
    };

    const onChangePassword = (e) => {
        if(e.target.value==='showmethemoney') {
            setShowTestUsers(true);
        }
        setPassword(e.target.value);
    };

    const onClickTestUser = (id) => {
        setUserId(id);
        setPassword(id==='etracedev' ? 'tldus25' : 'tldus,fk5');
        setShowTestUsers(false);
    };

    if(loading) return <Waiting message="로그인 중입니다." />;
    if(error) return <Box>Something went wrong in signing in</Box>

    let BoardHeight = clientSize.height - 130;
    if(BoardHeight < MinBoardHeight) BoardHeight = MinBoardHeight;

    return (
        <OuterLoginContainer>
            <InnerContainer>
                <ControlContainer>
                    <LeftBox>
                        <UpperFiller>&nbsp;</UpperFiller>
                        <Box style={{paddingBottom:20}}>
                            <img src={LogInLogo} alt="eTrace" width={160} />
                        </Box>
                        <Box>
                            <form>
                            {/* <SmallTitle>차량관제 로그인</SmallTitle> */}
                            <TextField id="userId" label="아이디" size='small' fullWidth
                                value={userId}
                                onChange={(e)=>setUserId(e.target.value)}
                                onKeyUp={(e) => {if(e.key==='Enter') { document.getElementById('password').focus();}}}
                                style={{marginBottom:8}}/>
                            <TextField id="password" label="암호" size='small' fullWidth
                                value={password}
                                onChange={onChangePassword}
                                onKeyUp={(e) => {if(e.key==='Enter') onClickSignIn(e)}}
                                type="password"
                                style={{marginBottom:8}} />
                            <Button onClick={onClickSignIn} fullWidth
                                variant='contained'>
                                로그인</Button>
                            </form>
                        </Box>
                        { loginError ? <Box>{loginError}</Box> : null }
                        <Box>&nbsp;</Box>
                        <SmallTitle>연락처</SmallTitle>
                        <InfoTable>
                            <TableRow>
                                <TableTitleCell width={80}>대표전화</TableTitleCell>
                                <TableCell>(02)3437-5858</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableTitleCell width={80}>일반전화</TableTitleCell>
                                <TableCell>(02)3437-9410~2</TableCell>
                            </TableRow>
                            
                            <TableRow>
                                <TableTitleCell width={80}>팩스</TableTitleCell>
                                <TableCell>(02)3437-5005</TableCell>
                            </TableRow>
                        </InfoTable>
                    </LeftBox>
                    <MainBox height={BoardHeight}>
                        <MainTitle>이트레이스 차량관제 공지사항</MainTitle>
                        <BoardBox><NotifyBoard /></BoardBox>
                    </MainBox>
                </ControlContainer>
                <BottomBar>
                    (주)이트레이스. 서울특별시 영등포구 양평동........
                    {
                        IsDevVSCode || (IsDevLocal && showTestUsers)
                        ?
                        <span>
                            <Button size={AppWord.SMALL} onClick={()=>{onClickTestUser('mailetrace0')}}>mailetrace0</Button>
                            <Button size={AppWord.SMALL} onClick={()=>{onClickTestUser('mailetrace1')}}>mailetrace1</Button>
                            <Button size={AppWord.SMALL} onClick={()=>{onClickTestUser('dwonetrace0')}}>dwonetrace0</Button>
                            <Button size={AppWord.SMALL} onClick={()=>{onClickTestUser('yjetrace0')}}>yjetrace0</Button>
                            <Button size={AppWord.SMALL} onClick={()=>{onClickTestUser('yjetrace1')}}>yjetrace1</Button>
                            <Button size={AppWord.SMALL} onClick={()=>{onClickTestUser('etracedev')}}>etracedev</Button>
                            <Button size={AppWord.SMALL} onClick={()=>{onClickTestUser('logetrace')}}>logetrace</Button>
                            <Button size={AppWord.SMALL} onClick={()=>{onClickTestUser('logetrace1')}}>logetrace1</Button>
                        </span>
                        :
                        null
                    }
                </BottomBar>
            </InnerContainer>
        </OuterLoginContainer>
    );
    // mailetrace0, mailetrace1
    // yjetrace0, yjetrace1
    // tldus,fk5
}
