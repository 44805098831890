// veh_conf/VehConfGql.js
import { gql } from "@apollo/client";
import { AppNumber } from "../../model/AppConst";

export const VehConfDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        vehId : {toAdd: true, toEdit: true},
        tempr1Ll : {
            id: 'tempr1Ll', label: '최저전면온도', type: 'number', required: false,
            toAdd: true, toEdit: true,
            min:AppNumber.MinGoodTemp, max:AppNumber.MaxGoodTemp,
            check: (value) => {return value > -999999999 && value < 999999999},
        },
        tempr1Ul : {
            id: 'tempr1Ul', label: '최고전면온도', type: 'number', required: false,
            toAdd: true, toEdit: true,
            min:AppNumber.MinGoodTemp, max:AppNumber.MaxGoodTemp,
            check: (value) => {return value > -999999999 && value < 999999999},
        },
        tempr2Ll : {
            id: 'tempr2Ll', label: '최저후면온도', type: 'number', required: false,
            toAdd: true, toEdit: true,
            min:AppNumber.MinGoodTemp, max:AppNumber.MaxGoodTemp,
            check: (value) => {return value > -999999999 && value < 999999999},
        },
        tempr2Ul : {
            id: 'tempr2Ul', label: '최고후면온도', type: 'number', required: false,
            toAdd: true, toEdit: true,
            min:AppNumber.MinGoodTemp, max:AppNumber.MaxGoodTemp,
            check: (value) => {return value > -999999999 && value < 999999999},
        },
        tempr3Ll : {
            id: 'tempr3Ll', label: '최저중면온도', type: 'number', required: false,
            toAdd: true, toEdit: true,
            min:AppNumber.MinGoodTemp, max:AppNumber.MaxGoodTemp,
            check: (value) => {return value > -999999999 && value < 999999999},
        },
        tempr3Ul : {
            id: 'tempr3Ul', label: '최고중면온도', type: 'number', required: false,
            toAdd: true, toEdit: true,
            min:AppNumber.MinGoodTemp, max:AppNumber.MaxGoodTemp,
            check: (value) => {return value > -999999999 && value < 999999999},
        },
        tempr4Ll : {
            id: 'tempr4Ll', label: '최저온도4', type: 'number', required: false,
            toAdd: true, toEdit: true,
            min:AppNumber.MinGoodTemp, max:AppNumber.MaxGoodTemp,
            check: (value) => {return value > -999999999 && value < 999999999},
        },
        tempr4Ul : {
            id: 'tempr4Ul', label: '최고온도4', type: 'number', required: false,
            toAdd: true, toEdit: true,
            min:AppNumber.MinGoodTemp, max:AppNumber.MaxGoodTemp,
            check: (value) => {return value > -999999999 && value < 999999999},
        },
        tempAlertLimit : {
            id: 'tempAlertLimit', label: '온도경고횟수제한', type: 'number', required: false,
            toAdd: true, toEdit: true,
            min:1, max:20,
            default: 3,
            check: (value) => {return value >= 1 && value <= 20},
        },
    },
    errorTitle: {
        Edit: '차량 온도알람 설정 오류',
        Get: '차량 온도알람 정보 조회 오류',
    }
};

const VEH_CONF_LIST_PART = gql`
fragment VehConfListPart on VehConf {
    vehId
    vehPlates
    tempr1Ll
    tempr1Ul
    tempr2Ll
    tempr2Ul
    tempr3Ll
    tempr3Ul
    tempr4Ll
    tempr4Ul
    tempAlertLimit
}
`;

const VEH_CONF_NO_LIST_PART = gql`
fragment VehConfNoListPart on VehConf {
    dailyStatStart
    dailyStatEnd
    vehMemo
    rentalYn
    prodYear
    fuelEfficiency
    dateDriverJoined
    rearCameraYn
    fuelPayYn
    tollPayYn
    custVehId
    custDriverCd
    termId
    speed
    svcCondCd
    tachoTempr1Ll
    tachoTempr1Ul
    tachoTempr2Ll
    tachoTempr2Ul
    tachoTempr3Ll
    tachoTempr3Ul
    tachoTempr4Ll
    tachoTempr4Ul
    tachoTempr5Ll
    tachoTempr5Ul
    tachoTempr6Ll
    tachoTempr6Ul
    tachoHumid1Ll
    tachoHumid1Ul
    tachoHumid2Ll
    tachoHumid2Ul
    tachoHumid3Ll
    tachoHumid3Ul
    tachoHumid4Ll
    tachoHumid4Ul
    tachoHumid5Ll
    tachoHumid5Ul
    tachoHumid6Ll
    tachoHumid6Ul
    tachoSpeed
    tachoRpm
    countTempAlert
    timeTempAlert
    editAllowed
}
`;


export const LIST_VEH_CONF = gql`
${VEH_CONF_LIST_PART}
query ListVehConf {
    vehConfList {
        ...VehConfListPart
    }
}
`;

export const GET_VEH_CONF = gql`
${VEH_CONF_LIST_PART}
${VEH_CONF_NO_LIST_PART}
query GetVehConf($vehId:Int) {
    vehConfItem(vehId:$vehId) {
        ...VehConfListPart
        ...VehConfNoListPart
    }
}
`;

export const SET_VEH_CONF_TEMP_ALARM = gql`
mutation SetVehConfTempAlarm($vehConf:InputVehConfSetTempAlarm!) {
    vehConfSetTempAlarm(vehConf: $vehConf) {
        ok
        message
        list {
            vehId
            vehPlates
            tempr1Ll
            tempr1Ul
            tempr2Ll
            tempr2Ul
            tempr3Ll
            tempr3Ul
            tempr4Ll
            tempr4Ul
            tempAlertLimit
        }
    }
}
`;
