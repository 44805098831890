//Boolbox.js
import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

export default function Boolbox({
    dictionary,
    textValue,
    onChange
}) {
    const [value, setValue] = useState(false);
    const trueValue = dictionary.trueValue || "Y";
    const falseValue = dictionary.falseValue || "N";

    useEffect(()=>{
        setValue(textValue === dictionary.trueValue ? true : false);
    }, [textValue, dictionary]);

    const onChangeBoolean = (event) => {
        const bool = event.target.checked;
        setValue(bool);
        if(onChange) {
            onChange(dictionary.id, bool ? trueValue : falseValue);
        }
    };

    return (
        <FormControlLabel
            label={dictionary.label}
            control={
                <Checkbox
                    name={dictionary.id}
                    checked={value}
                    onChange={onChangeBoolean}
                />
            }
        />
    );
}

/*

                                <FormControlLabel
                                    label={InputMap.smsUseFlag.label}
                                    control={
                                        <Checkbox
                                            name={InputMap.smsUseFlag.id}
                                            checked={useSms}
                                            onChange={(e) => {onChnageCheckbox(InputMap.smsUseFlag.id, e)}}
                                        />
                                    }
                                />

*/