// MapDataContainer.js
import React, {useEffect, useState} from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Tabs, Tab, IconButton, darken } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ResponseAlert from "../message/ResponseAlert";
import MapSweeperStat from '../sweeper_stat/MapSweeperStat';
import MapVehPosListRepeat from '../veh_pos/MapVehPosListRepeat';
import MapPoiInfo from '../poi_info/MapPoiInfo';
import MapVehRoute from './MapVehRoute';
import MapPoiInfoInput from '../poi_info/MapPoiInfoInput';
import NaverSearch from './NaverSearch';
import { MapMode } from './MapShell';
import { AppPalette, AppWord } from '../../model/AppConst';
import { poiEditCommandRepo, userInfoRepo, sweeperRouteRepo, sweeperSelectedRepo } from '../../model/CvoModel';
import { useReactiveVar } from '@apollo/client';

const DataContainer = styled(Box)({
    flexGrow:1, display:'flex', flexDirection:'column', height:'100%'
});
const DataBox = styled(Box)({flexGrow:1, display:'flex'});
const WaitBox = styled(Box)({flexGrow:1, display:'flex', alignItems:'center'});

const       TheHeader = styled(Box)({display:'flex', alignItems:'baseline', backgroundColor:AppPalette.PrimaryRGB, color:'white'});
// In TheHead
const TabBox = styled(Box)({display:'flex', alignItems:AppWord.END, height:'100%'});
const RouteOrPoiHeader = styled(Box)({
    display:'flex', alignItems:'center', height:'100%', width:'100%'
});
const RouteTitle = styled(Box)({
    flexGrow:1, display:'flex', alignItems:'center', justifyContent:'center', fontSize:'1.1rem', fontWeight:'bold',
    marginLeft:15, paddingLeft:10, border:'2px solid white', //backgroundColor: AppPalette.HeaderBackground,
    borderRadius:40, backgroundColor: darken(AppPalette.PrimaryRGB, 0.2)
});
const RouteCloseBox = styled(Box)({flexGrow:1, justifyContent:AppWord.END,});

const PoiEditorTitle = styled(Box)({
    display:'flex', alignItems:'center', justifyContent:'center', fontSize:'1.1rem', fontWeight:'bold', marginLeft:15,
});

// export const MapMenu = {Pos:1, Loc:2, Naver:3};
const TabCommandsNormal = [
    {value: MapMode.VehPos, label:'위치, 경로', default: true},
    {value: MapMode.POI, label:'지점'},
    {value: MapMode.NaverSearch, label:'주소검색'},
];

const TabCommandsSweeper = [
    {value: MapMode.Sweeper, label:'운행정보', default: true},
    {value: MapMode.VehPos, label:'위치, 경로'},
    {value: MapMode.POI, label:'지점'},
    {value: MapMode.NaverSearch, label:'주소검색'},
];

/**
 * 하위 컨트롤러들과 지도와의 인터페이스. 조정 역할.
 * 청소차량용 경로는 조회요청을 최소화하기 위해 이 곳에 둠.
 */
export default function MapDataContainer({
    mapShell,
    headerHeight,
    mapMenu,
    onChangeMapMenu,
}) {
    const [routeState, setRouteState] = useState({showRoute:false});
    const [poiEdit, setPoiEdit] = useState({open:false}); // {open, item}
    const [responseAlert, setResponseAlert] = useState(null);
    const sweeperRoute = useReactiveVar(sweeperRouteRepo);
    const sweeperSelected = useReactiveVar(sweeperSelectedRepo);
    const sessionInfo = useReactiveVar(userInfoRepo);
    const poiEditCommand = useReactiveVar(poiEditCommandRepo);
    // const sweeperChanged = useRef();

    /* // ##### Call GraphQL to get List #####
    const [getVehRouteList, responseList] = useLazyQuery(LIST_ROUTE_FOR_MAP, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "경로조회 오류"})}
    }); */

    const IsSweeper = sessionInfo.userAs.industryId===2;
    const TabCommands = IsSweeper ? TabCommandsSweeper : TabCommandsNormal;

    useEffect(()=>{
        if(poiEditCommand && mapShell) {
            setPoiEdit({open:true, item:{lat:poiEditCommand.lat, lon:poiEditCommand.lon, addr:poiEditCommand.addr}, isEdit:false});
            //mapShell.setOpenPoiEditor(true);
        }
    }, [poiEditCommand, mapShell]);

    // UI
    const onRequestOpenEditor = (poi) => {
        if(poi) {
            const copy = {...poi};
            // 과거 경위도를 기록하지 않은 데이터가 있을 수 있다.
            if((!poi.lat && !poi.lon) && poi.xDist && poi.yDist) {
                const {lat, lon} = mapShell.katek2ll({x:poi.xDist, y:poi.yDist});
                copy.lat = lat;
                copy.lon = lon;
            }
            setPoiEdit({open:true, item:copy, isEdit: true});
        }
        // 신규 생성의 경우는 poiEditCommandRepo를 통해서만 발생됨.
    };

    const onClosePoiEditor = () => {
        setPoiEdit({open:false});
        poiEditCommandRepo(null);
        mapShell.setOpenPoiEditor(false);
    };

    const onClickShowRoute = (vehPos) => {
        mapShell.hideVehPosMarkers();
        setRouteState({showRoute:true, vehPos: vehPos});
    };

    // 차량경로를 닫으면 돌아갈 곳은 모든 차량 마커 보여주기이다.
    const onClickCloseRoute = (e) => {
        setRouteState({showRoute:false});
        mapShell.showVehPosMarkers(true); // true : force to show veh-pos markers.
    };

    // Menu
    const onChangeMenu = (event, newValue) => {
        onChangeMapMenu(newValue);
        if(newValue===MapMode.Sweeper) {
            if(sweeperRoute.length>0) mapShell.showSweeperRoute(sweeperSelected, sweeperRoute);
            else mapShell.clearSweeperRoute();
        }
    };

    // 메뉴, 모드에 따라 컴포넌트를 보여준다. 단, 보여주지 않는 것들도 display:none으로 설정해서 데이터를 유지하게 한다.
    const renderByMenu = () => {
        if(mapShell) {
            if(routeState.showRoute) {
                return(
                    <DataBox>
                        <MapVehRoute
                            vehPos={routeState.vehPos}
                            mapShell={mapShell}
                        />
                    </DataBox>
                );
            }
            else if(poiEdit.open) {
                return(
                    <DataBox>
                        <MapPoiInfoInput
                            open={poiEdit.open}
                            mapShell={mapShell}
                            item={poiEdit.item}
                            isEdit={poiEdit.isEdit}
                            onClose={onClosePoiEditor}
                        />
                    </DataBox>
                );
            }
            return(
                <DataBox>
                    {
                        mapMenu === MapMode.NaverSearch
                        ? <NaverSearch
                            mapShell={mapShell}/>
                        :
                        mapMenu === MapMode.Sweeper
                        ? <MapSweeperStat
                            mapShell={mapShell}
                        />
                        :
                        mapMenu === MapMode.VehPos
                        ? <MapVehPosListRepeat
                            mapShell={mapShell}
                            onClickShowRoute={onClickShowRoute}
                        />
                        :
                        <MapPoiInfo
                            mapShell={mapShell}
                            onRequestOpenEditor={onRequestOpenEditor}
                        />
                    }
                </DataBox>
            );
        }
        else return <WaitBox>Wait...</WaitBox>;
    };

    return (
        <DataContainer>
            <TheHeader height={headerHeight}>
                {
                    routeState.showRoute
                    ?
                    <RouteOrPoiHeader>
                        <RouteTitle
                            height={headerHeight-14}
                        >
                            {routeState.vehPos.vehPlates} 경로
                        </RouteTitle>
                        <RouteCloseBox>
                            <IconButton
                                onClick={onClickCloseRoute}
                                color={AppPalette.InheritColor}
                            >
                                <CloseIcon/>
                            </IconButton>
                        </RouteCloseBox>
                    </RouteOrPoiHeader>
                    :
                    poiEdit.open
                    ?
                    <RouteOrPoiHeader>
                        <PoiEditorTitle>
                            {
                                poiEdit.item
                                ? poiEdit.item.poiName
                                : "지점 추가"
                            }
                        </PoiEditorTitle>
                    </RouteOrPoiHeader>
                    :
                    <TabBox>
                        <Tabs
                            value={mapMenu || MapMode.VehPos}
                            onChange={onChangeMenu}
                            textColor='inherit' indicatorColor='secondary'
                        >
                            {
                                TabCommands.map((menu)=>{
                                    return(
                                        <Tab key={menu.value}
                                            value={menu.value}
                                            label={menu.label}
                                        />
                                    );
                                })
                            }
                        </Tabs>
                    </TabBox>
                }
            </TheHeader>
            {renderByMenu()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </DataContainer>
    )
}
