// veh_type_info/VehTypeInfoGql.js
import { gql } from "@apollo/client";

export const GET_VEH_TYPE_INFO = gql`
query GetVehTypeInfo($vehTypeId:Int) {
    vehTypeInfoItem(vehTypeId:$vehTypeId) {
        custId
        vehTypeId
        vehTypeName
        vehTypeDesc
        regUserId
        regDate
    }
}
`;

export const EDIT_VEH_TYPE_INFO = gql`
mutation EditVehTypeInfo($vehTypeInfo:InputVehTypeInfoEdit!) {
  vehTypeInfoEdit(vehTypeInfo: $vehTypeInfo) {
    ok
    message
  }
}
`;

export const REMOVE_VEH_TYPE_INFO = gql`
mutation RemoveVehTypeInfo($vehTypeInfo:InputVehTypeInfoRemove!) {
  vehTypeInfoRemove(vehTypeInfo: $vehTypeInfo) {
    ok
    message
  }
}
`;