// VehInfoLog.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import CustInfoSelector from '../cust_info/CustInfoSelector';
import VehInfoNarrowList from '../veh_info/VehInfoNarrowList';
import VehInfoLogList from './VehInfoLogList';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery } from '@apollo/client';
import { SIMPLE_LIST_VEH_INFO } from '../veh_info/VehInfoGql';
import { LIST_VEH_INFO_LOG } from './VehInfoLogGql';
import { AppObject, AppNumber, AppPalette } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;

const VehInfoLogContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection: 'column'
});
const HeaderBox = styled(Box)({ // in VehInfoLogContainer
    height: AppNumber.HeaderToolBoxHeight, display:'flex', alignItems:'center'
});
const SubjectsAndLog = styled(Box)({flexGrow:1, display:'flex'}); // in VehInfoLogContainer

// in SubjectsAndLog
const SubjectListBoxWidth = 300;
const SubjectListBox = styled(Box)({marginRight:10, display:'flex', width:SubjectListBoxWidth, border: AppPalette.BorderCCC, borderRadius:5});


export default function VehInfoLog() {
    const [custInfo, setCustInfo] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [vehInfoList, setVehInfoList] = useState([]);
    const [vehInfoLogList, setVehInfoLogList] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getVehInfoLogList, responseList] = useLazyQuery(LIST_VEH_INFO_LOG, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {if(data.vehInfoLogList) setVehInfoLogList(data.vehInfoLogList);},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '차량정보변경이력 조회 오류'})}
    });
    const [getVehInfoList, responseVehInfoList] = useLazyQuery(SIMPLE_LIST_VEH_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {if(data.vehInfoList) setVehInfoList(data.vehInfoList);},
		onError: (error) => {setResponseAlert({open:true, error: error, title: '차량목록 조회 오류'})}
    });

    useEffect(() => {
        if(custInfo) getVehInfoList({variables:{custId:custInfo.custId}});
    }, [custInfo, getVehInfoList]);


    const onChangeCustSelection = (custInfo) => {
        setCustInfo(custInfo);
    };

    const onClickVehicle = (veh) => {
        if(!vehicle || veh.vehId !== vehicle.vehId) {
            setVehicle(veh);
            getVehInfoLogList({variables:{vehId: veh.vehId, custId: veh.custId}});
        }
    };

    if(ValueUtil.hasAnyAuthError(
        responseList,
        responseVehInfoList
        )) userInfoRepo(NoUser);

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <VehInfoLogContainer>
            <HeaderBox>
                <CustInfoSelector
                    popoverHeight={clientSize.dataAreaHeight - AppNumber.HeaderToolBoxHeight - 50}
                    onChange={onChangeCustSelection}
                />
            </HeaderBox>
            <SubjectsAndLog>
                <SubjectListBox>
                    <VehInfoNarrowList
                        columnList={['vehPlates', 'driverName']}
                        records={vehInfoList}
                        selected={vehicle}
                        onClickItem={onClickVehicle}
                    />
                </SubjectListBox>
                <VehInfoLogList
                    records={vehInfoLogList}
                />
            </SubjectsAndLog>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehInfoLogContainer>
    )
}
