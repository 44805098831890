// RegSessionLog.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment, TextField, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import FlexyTable from '../common/flexytable/FlexyTable';
import { AppNumber, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';

const ReportLogContainer = styled(Box)({
    flexGrow:1, display:'flex', flexDirection: 'column'
});

const HeaderBox = styled(Box)({
    height: AppNumber.HeaderToolBoxHeight, display:'flex', alignItems:'center',
});

const TableColumns = [
    { id: 'custName', label: '업체명', width: 160, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userName', label: '사용자', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userId', label: 'ID', width: 110, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'sessionIo', label: '접근구분', width: 110, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'act_time', label: '접근시각', width: 160, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'actMemo', label:'작업내역', width: 200, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
];

export default function RegSessionLog({records, menu, onRequestData}) {
    const [search, setSearch] = useState('');
    const [date, setDate] = useState(ValueUtil.getYmdText(86400));

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <ReportLogContainer>
            <HeaderBox>
                <VpnKeyIcon color={AppPalette.ErrorColor}/>
                <span style={{display:'inline-block', marginRight:10, color:'#ccc'}}>.....</span>
                {menu}
                <TextField
                    type="date"
                    value={date}
                    size={AppWord.SMALL}
                    onChange={(e)=>{setDate(e.target.value);}}
                />
                <Button
                    variant={AppPalette.VariantContained}
                    style={{marginRight:20, marginLeft:5}}
                    disabled={!Boolean(date)}
                    onClick={()=>{onRequestData(date)}}
                >
                    조회
                </Button>
				<FormControl variant='outlined' size='small'>
					<InputLabel htmlFor="search-RouteDaily-list" style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id="search-RouteDaily-list" onChange={onChangeSearch}
						value={search}
						disabled={records.length === 0}
						size='small'
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
            </HeaderBox>
            <FlexyTable
                name="session_log_list"
                uniqueKey="dataKey"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[]}
            />
        </ReportLogContainer>
    );
}
