//CustGeneralData.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { Button, IconButton, AppBar, Toolbar } from '@mui/material';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery } from '@apollo/client';
import { AppPalette, AppObject, AppNumber, TableColumnType } from '../../model/AppConst';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, custListRepo, NoUser } from '../../model/CvoModel';
import { GET_CUST_GEN_DATA } from './CustInfoGql';

const CustGenContainer = styled(Box)({
    flexGrow:1, // backgroundColor:'yellow',//    height:'100%', //
    display:"flex", flexDirection:'column', padding: 5
});

const UpperBox = styled(Box)({ display:'flex', minHeight:200 });
const LowerBox = styled(Box)({ flexGrow:1, display:'flex', marginTop:10 });

const StatBox = styled(Box)({
    flexGrow:1, display:'flex', flexDirection:'column', padding: 10, border: AppPalette.BorderCCC, borderRadius: 5, marginLeft: 10,
    '&:first-of-type':{marginLeft:0}
});

const StatTitle = styled(Box)({
    fontWeight:'bold', backgroundColor: AppPalette.PrimaryRGB, color:'white', padding: 10, borderRadius: 25, marginBottom:10, textAlign:'center'
});

const InfoColumnPadding = '8px 2px 6px 1px';
const InfoRow = styled(Box)({display:'flex', borderBottom: AppPalette.BorderCCC});
const InfoName = styled(Box)({
    flexGrow:1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',textAlign:'center',
    padding: InfoColumnPadding
});
const InfoCount = styled(Box)({textAlign:'center', width: 70, padding: InfoColumnPadding});

const CustAgreeColumn = [
	{ id: 'custName', label: '업체', width: 110, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custId', label: '업체 ID', width: 80, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'groupName', label: '차량그룹', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'editYn', label: '편집허용', width: 70, show: true, option:{align: 'inherit', type:TableColumnType.CHAR, ynBoolean: ['허용','불허']} },

];

export default function CustGeneralData({
    custInfo
}) {
    const [responseAlert, setResponseAlert] = useState(null);
    const [vehReportStat, setVehReportStat] = useState({});
    const [termsStat, setTermsStat] = useState({});
    const [telcomList, setTelcomList] = useState([]);
    const [custAgreeGet, setCustAgreeGet] = useState([]);
    const [custAgreeGive, setCustAgreeGive] = useState([]);

    const [getCustGenData, responseData] = useLazyQuery(GET_CUST_GEN_DATA, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetData(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "차량, 단말기, 협력정보 등 조회 오류"})}
    });

    useEffect(()=>{
        if(custInfo) {
            if(custInfo.custId) {
                getCustGenData({variables: {custId: custInfo.custId}});
            }
        }
    }, [custInfo, getCustGenData]);

    const onCompleteGetData = (data, option) => {
        if(data.vehReportStatItem) setVehReportStat(data.vehReportStatItem);
        if(data.termsStatItem) {
            const tst = {...data.termsStatItem};
            tst.workRate = tst.allTerms > 0 ? Math.round((tst.usingTerm * 1000 / tst.allTerms)) / 10 : 0;
            setTermsStat(tst);
        }
        if(data.viewTelecomStatusList) setTelcomList(data.viewTelecomStatusList);
        if(data.custAgreeListAll) {
            const agreeGet = [];
            const agreeGive = [];
            for(const agree of data.custAgreeListAll) {
                if(agree.custId===custInfo.custId) {
                    // 협력 받는 것
                    agreeGet.push({
                        groupId: agree.groupId,
                        custId : agree.givingCustId,
                        custName : agree.givingCustName,
                        groupName: agree.groupName,
                        editYn: agree.editYn
                    });
                }
                else {
                    agreeGive.push({
                        groupId: agree.groupId,
                        custId : agree.custId,
                        custName : agree.custNameUsing,
                        groupName: agree.groupName,
                        editYn: agree.editYn
                    });
                }
            }
            setCustAgreeGet(agreeGet);
            setCustAgreeGive(agreeGive);
        }
    };

    if(!custInfo) return <CustGenContainer>&nbsp;</CustGenContainer>;

    if(ValueUtil.hasAnyAuthError(responseData)) userInfoRepo(NoUser);

    const renderRow = (title, number) => {
        return(
            <InfoRow>
                <InfoName>{title}</InfoName>
                <InfoCount>{number}</InfoCount>
            </InfoRow>
        );
    };
    /*
            return(
                <Box className={cocx('tiny-table')}>ㅇ
 
 
                    <Box className={cocx('tiny-column')}>
                        <Box className={cocx('tiny-title')}>일시정지</Box>
                        <Box className={cocx('tiny-text')}>{tsi.stopped_term}</Box>
                    </Box>
                    <Box className={cocx('tiny-column')}>
                        <Box className={cocx('tiny-title')}>대기</Box>
                        <Box className={cocx('tiny-text')}>{tsi.wait_term}</Box>
                    </Box>
                </Box>
            );
    */

    return (
        <CustGenContainer>
            <UpperBox>
                <StatBox>
                    <StatTitle>차량 및 위치보고</StatTitle>
                    {renderRow('전체차량', vehReportStat.sumVehicle)}
                    {renderRow('사용차량', vehReportStat.sumUsing)}
                    {renderRow('운행중', vehReportStat.running)}
                    {renderRow('1일 이내', vehReportStat.inDay)}
                    {renderRow('1주 이내', vehReportStat.inWeek)}
                    {renderRow('1개월 이내', vehReportStat.inMonth)}
                    {renderRow('1개월 초과', vehReportStat.overMonth)}
                    {renderRow('단말기 없음', vehReportStat.noDevice)}
                </StatBox>
                <StatBox>
                    <StatTitle>단말기 통계</StatTitle>
                    {renderRow('가입 수', termsStat.allTerms)}
                    {renderRow('사용', termsStat.usingTerm)}
                    {renderRow('정상가동', termsStat.normalTerm)}
                    {renderRow('정지단말기', termsStat.normalOff)}
                    {renderRow('가동률', ''+termsStat.workRate+'%')}
                    {renderRow('미사용', termsStat.notUsing)}
                    {renderRow('일시정지', termsStat.stoppedTerm)}
                    {renderRow('대기', termsStat.waitTerm)}
                </StatBox>
                <StatBox>
                    <StatTitle>통신사별 단말기 수</StatTitle>
                    {
                        telcomList.map((ts)=>{
                            return(
                                <InfoRow key={ts.networkCd}>
                                    <InfoName>{ts.telecom}</InfoName>
                                    <InfoCount>{ts.termCount}</InfoCount>
                                </InfoRow>
                            );
                        })
                    }
                </StatBox>
            </UpperBox>
            <LowerBox>
                <StatBox>
                    <StatTitle>이 업체에 협력차량을 제공</StatTitle>
                    <FlexyTable
                        name="cust_agree_get"
                        uniqueKey="groupId"
                        columns={CustAgreeColumn}
                        records={custAgreeGet}
                        hideFirstTopButton={true}
                    />
                </StatBox>
                <StatBox>
                    <StatTitle>이 업체로부터 협력받는 업체</StatTitle>
                    <FlexyTable
                        name="cust_agree_give"
                        uniqueKey="groupId"
                        columns={CustAgreeColumn}
                        records={custAgreeGive}
                        hideFirstTopButton={true}
                    />
                </StatBox>
            </LowerBox>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </CustGenContainer>
    )
}
