// cust_poi_type/CustPoiTypeGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const CustPoiTypeDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        // custId : {toAdd: true, toEdit: true},
        poiType : { id:"poiType", required: true, toAdd: false, toEdit: true, },
        typeName : {
            id: 'typeName', label: '지점타입명', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '10자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.textInRange(value,1,30)},
        },
        typeDesc : {
            id: 'typeDesc', label: '설명', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '33자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,100)},
        },
        /*
        groupNid : {
            id: 'groupNid', label: 'groupNid label', type: 'number', required: false,
            toAdd: true, toEdit: true,
            help: '최대값은 10 입니다.',
            check: (value) => {return value > 0 && value < 99999},
        },*/
        viewLevel : {
            id: 'viewLevel', label: '지도레벨', type: 'select', required: true,
            toAdd: true, toEdit: true,
            default: 14,
            check: (value) => {return value > 0 && value < 99999},
        },
        iconSeq : {
            id: 'iconSeq', label: '아이콘', type: 'select', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return value > 0 && value < 99999},
        },
        /*


    const onChangeCode = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        setInputData(data);
    };
        */
        fontColor : {
            id: 'fontColor', label: '지도지점 전경색', type: 'color', required: true,
            toAdd: true, toEdit: true,
            default: '#000000',
            check: (value) => {return ValueUtil.isColorFormat(value)},
        },
        backColor : {
            id: 'backColor', label: '지도지점 배경색', type: 'color', required: true,
            toAdd: true, toEdit: true,
            default: '#FFFFFF',
            check: (value) => {return ValueUtil.isColorFormat(value)},
        },
        /*
        fontSize : {
            id: 'fontSize', label: 'fontSize label', type: 'number', required: false,
            toAdd: true, toEdit: true,
            help: '최대값은 10 입니다.',
            check: (value) => {return value > 0 && value < 99999},
        },*/

    },
    errorTitle: {
        Add: '지점타입 생성 오류',
        Edit: '지점타입 수정 오류',
        Remove: '지점타입 삭제 오류',
        List: '지점타입 목록 조회 오류',
        Get: '지점타입 조회 오류',
    }
};

const ICON_POOL_LIST_PART = gql`
fragment IconPoolListPart on IconPool {
    iconSeq
    iconName
    iconSaveName
    svgText
    iconPath
    iconSize
    iconContentType
    cutWidth
    height
    cutCount
    custId
    userId
    userName
    regTime
}
`;


const CUST_POI_TYPE_LIST_PART = gql`
fragment CustPoiTypeListPart on CustPoiType {
    custId
    poiType
    typeName
    typeDesc
    groupNid
    viewLevel
    iconSeq
    fontColor
    backColor
    fontSize
    iconName
    iconSaveName
    svgText
    iconPath
    iconSize
    iconContentType
    cutWidth
    height
    cutCount
}
`;

export const LIST_CUST_POI_TYPE = gql`
${CUST_POI_TYPE_LIST_PART}
${ICON_POOL_LIST_PART}
query ListCustPoiType {
    custPoiTypeList {
        ...CustPoiTypeListPart
    }
    iconPoolList {
        ...IconPoolListPart
    }
}
`;

export const GET_CUST_POI_TYPE = gql`
${CUST_POI_TYPE_LIST_PART}
query GetCustPoiType($poiType:Int) {
    custPoiTypeItem(poiType:$poiType) {
        ...CustPoiTypeListPart
    }
}
`;

export const ADD_CUST_POI_TYPE = gql`
${CUST_POI_TYPE_LIST_PART}
mutation AddCustPoiType($custPoiType:InputCustPoiTypeAdd!) {
    custPoiTypeAdd(custPoiType: $custPoiType) {
        ok
        message
        list {
            ...CustPoiTypeListPart
        }
    }
}
`;

export const EDIT_CUST_POI_TYPE = gql`
${CUST_POI_TYPE_LIST_PART}
mutation EditCustPoiType($custPoiType:InputCustPoiTypeEdit!) {
    custPoiTypeEdit(custPoiType: $custPoiType) {
        ok
        message
        list {
            ...CustPoiTypeListPart
        }
    }
}
`;

export const REMOVE_CUST_POI_TYPE = gql`
${CUST_POI_TYPE_LIST_PART}
mutation RemoveCustPoiType($custPoiType:InputCustPoiTypeRemove!) {
    custPoiTypeRemove(custPoiType: $custPoiType) {
        ok
        message
        list {
            ...CustPoiTypeListPart
        }
    }
}
`;
