// term_mng/TermMngGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";

export const TermMngDictionary = {
    inputMap: {
		// toAdd, toEdit : mark if need to submit for mutation
        termMngSeq: {required: false, toAdd: false, toEdit: true,},
        openDate : {
            id: 'openDate', label: '개통일자', type: 'date', required: false,
            toAdd: true, toEdit: true,
        },
        acNetworkCd : {
            id: 'acNetworkCd', label: '통신사', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        acTermStateCd : {
            id: 'acTermStateCd', label: '개통상태', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        acOpenForCd : {
            id: 'acOpenForCd', label: '개통구분', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        groupMemo : {
            id: 'groupMemo', label: '소그룹', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },
        abandonDate : {
            id: 'abandonDate', label: '해지일자', type: 'date', required: false,
            toAdd: true, toEdit: true,
        },
        custName : {
            id: 'custName', label: '업체명', type: 'text', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.textInRange(value,1,60)},
        },
        acFirmTypeCd : {
            id: 'acFirmTypeCd', label: '법인구분', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        openerName : {
            id: 'openerName', label: '명의자명', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },
        custCorpNo : {
            id: 'custCorpNo', label: '법인#/생년월일', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isRegexpMatch(value, /^\d{6,13}$/)},
        },
        compBizno : {
            id: 'compBizno', label: '사업자등록번호', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isRegexpMatch(value,/^\d{10}$/)},
        },
        termId : {
            id: 'termId', label: '단말기ID', type: 'text', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isRegexpMatch(value,/^\d{3,}$/)},
        },
        acModelCd : {
            id: 'acModelCd', label: '모델', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        serialNo : {
            id: 'serialNo', label: 'S/N', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,50)},
        },
        usimNum : {
            id: 'usimNum', label: 'USIM', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },
        acBillTypeCd : {
            id: 'acBillTypeCd', label: '요금제', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        salePrice : {
            id: 'salePrice', label: '출고가', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,50)},
        },
        acContrMonthCd : {
            id: 'acContrMonthCd', label: '약정개월', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        acContrAmountCd : {
            id: 'acContrAmountCd', label: '약정금액', type: 'select', required: false,
            toAdd: true, toEdit: true,
        },
        supportAmount : {
            id: 'supportAmount', label: '단말지원약정', type: 'number', required: true,
            toAdd: true, toEdit: true,
            check: (value) => {return value > 0 && value < 9999999},
        },
        representitive : {
            id: 'representitive', label: '대리인', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,20)},
        },
        thermComp : {
            id: 'thermComp', label: '온도업체', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,40)},
        },

        salesStaffName : {
            id: 'salesStaffName', label: '영업담당자', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,12)},
        },
        memoInstall : {
            id: 'memoInstall', label: '설치여부', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,200)},
        },
        generalMemo : {
            id: 'generalMemo', label: '적요', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,1000)},
        },
        acTermChangeCd : {
            id: 'acTermChangeCd', label: '변경사유', type: 'select', required: true,
            toAdd: false, toEdit: true,
        },
        changeDate : {
            id: 'changeDate', label: '변경일자', type: 'date', required: false,
            toAdd: false, toEdit: true,
            check: (value) => {return ValueUtil.isDateFormat(value)},
        },
        changeMemo : {
            id: 'changeMemo', label: '변경메모', type: 'text', required: false,
            toAdd: false, toEdit: true,
            help: '300자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,1000)},
        },
        /* custId : {
            id: 'custId', label: 'custId label', type: 'text', required: false,
            toAdd: true, toEdit: true,
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,10)},
        }, */

    },
    errorTitle: {
        Add: '개통정보 생성 오류',
        Edit: '개통정보 수정 오류',
        Remove: '개통정보 삭제 오류',
        Get: '개통정보 조회 오류',
        List: '개통정보 목록 조회 오류',
    }
};

const TERM_MNG_LIST_PART = gql`
fragment TermMngListPart on TermMng {
    termMngSeq
    termId
    serialNo
    salesStaffName
    acNetworkCd
    acNetworkValue
    acTermStateCd
    acTermStateValue
    acOpenForCd
    acOpenForValue
    acFirmTypeCd
    acFirmTypeValue
    acModelCd
    acModelValue
    acBillTypeCd
    acBillTypeValue
    acContrMonthCd
    acContrMonthValue
    acContrAmountCd
    acContrAmountValue
    groupMemo
    openDate
    custId
    custName
    openerName
    custCorpNo
    compBizno
    usimNum
    salePrice
    representitive
    thermComp
    memoInstall
    generalMemo
    abandonDate
    supportAmount
    regUserId
    regDate
}
`;



export const LIST_TERM_MNG = gql`
${TERM_MNG_LIST_PART}
query ListTermMng($termMngSearch: InputTermMngSearch!) {
    termMngList(termMngSearch:$termMngSearch) {
        ...TermMngListPart
    }
    termMngCustList {
        custName
    }
}
`;

export const LIST_TERM_MNG_CUST = gql`
query ListTermMngCust {
    termMngCustList {
        custName
    }
}
`;

/*
InputTermMngSearch {
    searchField: String
    search: String
    dateFrom: String
}
*/

export const GET_TERM_MNG = gql`
${TERM_MNG_LIST_PART}
query GetTermMng($termMngSeq:Int!) {
    termMngItem(termMngSeq:$termMngSeq) {
        ...TermMngListPart
    }
}
`;

export const ADD_TERM_MNG = gql`
mutation AddTermMng($termMng:InputTermMngAdd!) {
    termMngAdd(termMng: $termMng) {
        ok
        message
    }
}
`;

export const EDIT_TERM_MNG = gql`
mutation EditTermMng($termMng:InputTermMngEdit!) {
    termMngEdit(termMng: $termMng) {
        ok
        message
    }
}
`;

export const REMOVE_TERM_MNG = gql`
mutation RemoveTermMng($termMng:InputTermMngRemove!) {
    termMngRemove(termMng: $termMng) {
        ok
        message
    }
}
`;