// RouteMonthlyListOfVeh.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { AppNumber, AppPalette, AppWord } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';

const DialogHeaderBox = styled(DialogTitle)({backgroundColor:AppPalette.PrimaryRGB, color:'white'});

const RouteMonthlyListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});
const HeaderBox = styled(Box)({
	display:'flex', alignItems:'center', height:AppNumber.HeaderToolBoxHeight,
    fontSize:'0.9rem', margin:0, padding:0
});

const RecordBox = styled(Box)({
    display:'flex', alignItems:'center', padding: AppNumber.SmallBoxPadding,
    borderBottom: AppPalette.BorderCCC, cursor:'pointer',
    '&:hover': {backgroundColor:'#eee'}
});
const VehicleColumn = styled(Box)({
    width: 140, marginRight: AppNumber.SmallBoxPadding, borderRight: AppPalette.BorderCCC,
    display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'
});
const DataColumn = styled(Box)({
    flexGrow:1,
});

const DataItemHeight = 24;
const DataItem = styled(Box)({
    // contains DataGraph, DataText
    display:'flex', alignItems:'center', height: DataItemHeight, fontSize:'0.8rem',
});
const DataGraph = styled(Box)({
    textAlign:'right', display:'flex', alignItems:'center', justifyContent:AppWord.END, padding: 3,
    borderTopRightRadius: 5, borderBottomRightRadius: 5,
    border: AppPalette.BorderCCC, boxSizing:'border-box',
});
const DataTextInGraph = styled(Box)({color:'black'});
const DataText = styled(Box)({
    flexGrow:1, paddingLeft:10, color:'#333', // width: 100,
});

const DataTableContainer = styled(Box)({
    flexGrow:1,
    position:'relative',
    whiteSpace:'nowrap',
});

const HoursRunColor = '#fce8fc'; // AppPalette.PrimaryRGB;
const TotalDistColor = '#c5fcfc'; // AppPalette.SuccessRGB
const AvgSpeedRunColor = '#fcfcae'; // AppPalette.InfoRGB
const DaysRunColor = '#eee';

/**
 * 차량별 운행 통계 목록.
 * 특정 차량의 여러달의 운행 통계를 조회하여 그래프 형식의 목록으로 보여준다.
 */
export default function RouteMonthlyListOfVeh({
    open,
    param, // used to get records. {vehId, dateFrom, dateTill}
	records,
    onClose,
}) {
	const [vehPlates, setVehPlates] = useState('');
    const [recordsToShow, setRecordsToShow] = useState([]);

    useEffect(()=>{
        if(records.length > 0) {
            console.log('records', records);
            setVehPlates(records[0].vehPlates + ' 운행통계');
            const max = {totalDist:0, avgSpeedRun:0, hoursRun:0, daysRun:0};
            records.forEach(rec=>{
                if(rec.totalDist > max.totalDist) max.totalDist = rec.totalDist;
                if(rec.avgSpeedRun > max.avgSpeedRun) max.avgSpeedRun = rec.avgSpeedRun;
                if(rec.hoursRun > max.hoursRun) max.hoursRun = rec.hoursRun;
                if(rec.daysRun > max.daysRun) max.daysRun = rec.daysRun;
            });
            const toShow = records.map(rec=>{
                return {
                    routeDate: rec.routeDate,
                    routeMonth: rec.routeMonth,
                    vehId: rec.vehId,
                    vehPlates: rec.vehPlates,
                    custName: rec.custName,
                    secondsRun: rec.secondsRun,
                    hoursRun: rec.hoursRun,
                    hoursRunRate: Math.round(rec.hoursRun*1000/max.hoursRun)/10,
                    daysRun: rec.daysRun,
                    daysRunRate: Math.round(rec.daysRun*1000/max.daysRun)/10,
                    totalDist: rec.totalDist,
                    totalDistRate: Math.round(rec.totalDist*1000/max.totalDist)/10,
                    avgSpeedRun: Math.round(rec.avgSpeedRun*10)/10,
                    avgSpeedRunRate: Math.round(rec.avgSpeedRun*1000/max.avgSpeedRun)/10,
                    maxSpeed: rec.maxSpeed,
                }
            });
            setRecordsToShow(toShow);
        }
        else {
            setVehPlates('운행통계 데이터 없음');
        }
        
    }, [records]);

    const PercentToPutTextInGraph = 60;
    const renderDataItem = (rate, label, color) => {
        return(
            <DataItem>
                <DataGraph
                    style={{
                        backgroundColor:color,
                        width: `${rate}%`
                    }}
                >
                    {
                        rate > PercentToPutTextInGraph
                        &&
                        <DataTextInGraph>{label}</DataTextInGraph>
                    }
                    <span>&nbsp;</span>
                </DataGraph>
                <DataText>
                    {
                        rate <= PercentToPutTextInGraph
                        ?
                        label
                        :
                        null
                    }
                </DataText>
            </DataItem>
        );
    };

    const renderDataRecord = (rec) => {
        return(
            <RecordBox key={rec.routeDate}>
                <VehicleColumn>
                    {rec.routeMonth}
                </VehicleColumn>
                <DataColumn>
                    {renderDataItem(rec.hoursRunRate, `총 이동시간 ${rec.hoursRun}시간`, HoursRunColor)}
                    {renderDataItem(rec.totalDistRate, `총 이동거리 ${ValueUtil.float2str(rec.totalDist,2)}km`, TotalDistColor)}
                    {renderDataItem(rec.avgSpeedRunRate, `이동 평균속도 ${rec.avgSpeedRun}km/h`, AvgSpeedRunColor)}
                    {renderDataItem(rec.daysRunRate, `이동발생 일 수 ${rec.daysRun}일`, DaysRunColor)}
                </DataColumn>
            </RecordBox>
        );
    };

    const renderContent = () => {
        return(
            <RouteMonthlyListContainer>
                <DataTableContainer>
                {
                    recordsToShow.map((rec, index)=>{
                        return renderDataRecord(rec);
                    })
                }
                </DataTableContainer>
            </RouteMonthlyListContainer>
        );

    };
    return(
        <Dialog open={open} fullScreen>
            <DialogHeaderBox>
                {vehPlates}

                <HeaderBox>
                    기간: {param.dateFrom} ~ {param.dateTill}
                </HeaderBox>
            </DialogHeaderBox>
            <DialogContent>
                {renderContent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant={AppPalette.VariantContained}>닫기</Button>
            </DialogActions>
        </Dialog>
    );
}