// AddressInfo.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import AddressInfoList from './AddressInfoList';
import AddressInfoInput from './AddressInfoInput';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_ADDRESS_INFO, ADD_ADDRESS_INFO, EDIT_ADDRESS_INFO, REMOVE_ADDRESS_INFO,
	AddressInfoDictionary
} from './AddressInfoGql';
import { AppObject, AppNumber } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;
const AddressInfoContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});


const ErrorTitle =AddressInfoDictionary.errorTitle;


export default function AddressInfo({
    maxWidth,
    maxHeight,
}) {
	const [addressInfoSelected, setAddressInfoSelected] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    // const responseList = useQuery(GET_ADDRESS_INFO_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getAddressInfoList, responseList] = useLazyQuery(LIST_ADDRESS_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    /*const [getAddressInfoItemToEdit, responseItemToEdit] = useLazyQuery(GET_ADDRESS_INFO, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
	});*/
    
    // ##### GraphQL Mutation.  ###
    const [addAddressInfo, responseAdd] = useMutation( ADD_ADDRESS_INFO, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editAddressInfo, responseEdit] = useMutation( EDIT_ADDRESS_INFO, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeAddressInfo, responseRemove] = useMutation( REMOVE_ADDRESS_INFO, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});

    useEffect(() => {
        getAddressInfoList();
    }, [getAddressInfoList]);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(addressInfoSelected && data.addressInfoList) {
            for(const item of data.addressInfoList) {
                if(item.custId===addressInfoSelected.custId && item.addressId===addressInfoSelected.addressId) {
                    setAddressInfoSelected(item);
                    return;
                }
            }
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.addressInfoAdd.ok) {
            setEditorState(null);
            getAddressInfoList(); // getAddressInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.addressInfoAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.addressInfoEdit.ok) {
            setEditorState(null);
            getAddressInfoList(); // getAddressInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.addressInfoEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.addressInfoRemove.ok) getAddressInfoList();
        else setResponseAlert({open:true, resultData: data.addressInfoRemove, title: ErrorTitle.Remove});
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(responseAdd) responseAdd.reset();
        setEditorState({item: undefined}); // input component will set a default data.
    };

    const onRequestEdit = (item) => {
        if(responseEdit) responseEdit.reset();
        //getAddressInfoItemToEdit({variables: {custId: data.custId,addressId: data.addressId}});
        setEditorState({item: item});
    };

    const onRequestRemove = (item) => {

        setPromptToConfirm({
            data: item,
            title: '주소정보 삭제',
            messages: [
                '선택한 ' + item.fullname + ' 주소를 삭제하시겠습니까?',
                '해당 주소의 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{addressInfo:{addressId: data.addressId}}};
                    removeAddressInfo(param);
                }
            }
        });
    };

    // Handler - Submit for mutation fired by AddressInfoInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; // testing error callback.
        const param = {variables: {addressInfo: item}}; // ValueUtil.refineToSubmit(item)
        if(isEdit) editAddressInfo(param);
        else addAddressInfo(param);
    };

    const onCloseEditor = () => {
        setEditorState(null);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList, responseAdd, responseEdit, responseRemove
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderEditor = () => {
        const es = editorState ? editorState : {item: undefined};

        return(
            es.item
            ?
            <AddressInfoInput
                open={Boolean(editorState)}
                isEdit={true}
                item={es.item}
                responseSaving={responseEdit}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
            :
            <AddressInfoInput
                open={Boolean(editorState)}
                isEdit={false}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - dcPad * 2;

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <AddressInfoContainer>
            <AddressInfoList
				maxHeight={height}
				maxWidth={width}
                responseList={responseList}
				selected={addressInfoSelected}
				onClickItem={setAddressInfoSelected}
                onRequestAdd={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
            />
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </AddressInfoContainer>
    )
}
