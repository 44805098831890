// ViewCruise.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import { useInterval } from 'react-use';
import { Slide, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ViewCruiseList from './ViewCruiseList';
import ViewCruiseInput from './ViewCruiseInput';
import CruiseLogListPop from './CruiseLogListPop';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
    LIST_VIEW_CRUISE, LIST_VIEW_CRUISE_AND_POI, LIST_CRUISE_LOG_FOR_VEH,
    ADD_VIEW_CRUISE, EDIT_VIEW_CRUISE, REMOVE_VIEW_CRUISE,
    SET_FLAG_VIEW_CRUISE, TRUNCATE_VIEW_CRUISE,
	ViewCruiseDictionary
} from './ViewCruiseGql';
import { AppObject, AppNumber, AppWord } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser, currentMenuRepo } from '../../model/CvoModel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function newRoute(rec) {
    return {
        custId: rec.custId,
        routeId: rec.routeId,
        vehId: rec.vehId,
        vehPlates: rec.vehPlates,
        driverName: rec.driverName,
        driverMobile: rec.driverMobile,
        depNotiDoneYn: rec.depNotiDoneYn,
        lastNotiTime: rec.lastNotiTime,
        centerAta: rec.centerAta,
        atdFrom: rec.atdFrom,
        poiIdDep: rec.poiIdDep,
        poiNameDep: rec.poiNameDep,
        movement: rec.movement,
        sendDepMsgTo: rec.sendDepMsgTo,
        arrNotiDoneYn: rec.arrNotiDoneYn,
        currentFlag: rec.currentFlag,
        routeDone: rec.routeDone,
        routeRunning: rec.routeRunning,
        destins: []
    };
}

function reformCruiseList(records) {
    const cmap = {}; // routeId and sub records
    const list = [];

    for(const rec of records) {
        let route;
        if(rec.recId === 0) {
            route = newRoute(rec);
            cmap[rec.routeId] = route;
            list.push(route);
        }
        else {
            route = cmap[rec.routeId]
            route.destins.push(rec);
        }
    }
    return list;
}

const dcPad = AppNumber.dataContainerPadding;
const ViewCruiseContainer = styled(Box)({
	position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex'
});

const ErrorTitle =ViewCruiseDictionary.errorTitle;
const EditFields = ValueUtil.getFieldsToSubmit(ViewCruiseDictionary.inputMap, true);
const FetchInterval = 601; // seconds


export default function ViewCruise({
    maxWidth,
    maxHeight,
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [cruiseRecords, setCruiseRecords] = useState([]);
    const [timeGotCruiseRecords, setTimeGotCruiseRecords] = useState(new Date().getTime() / 1000); // 처음에는 따로 가져오므로. 스케줄로는 10분 후부터.
    const [poiList, setPoiList] = useState([]);
    const [poiIndex, setPoiIndex] = useState({}); // poiId to poiList index.
    const [poiPopData, setPoiPopData] = useState(null);
    const [vehicleList, setVehicleList] = useState([]);
    const [cruiseLog, setCruiseLog] = useState([]);
    const [showCruiseLog, setShowCruiseLog] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [responseAlert, setResponseAlert] = useState(null);
    const currentMenu = useReactiveVar(currentMenuRepo);
    // const responseList = useQuery(GET_VIEW_CRUISE_LIST, {fetchPolicy: "no-cache"});
    // const [responseList, setResponseList] = useState({data:[]});
    const clientSize = useClientSize();
	
	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    // ##### Call GraphQL to get List #####
    const [getCruiseListPoiAndVeh, responseList] = useLazyQuery(LIST_VIEW_CRUISE_AND_POI, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetListCruisePoiAndVeh(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List+"(2)"})}
    });
    const [getCruiseListOnly, responseCruiseList] = useLazyQuery(LIST_VIEW_CRUISE, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetCruiseList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.List})}
    });
    const [getCruiseLogListForVeh, responseCruiseLogForVeh] = useLazyQuery(LIST_CRUISE_LOG_FOR_VEH, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetCruiseLogForVeh(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.CruiseLog})}
    });
    
    // ##### GraphQL Mutation. 'onError' for prevent from printing error at consol-e (Uncaught exception) ###
    const [addViewCruise, responseAdd] = useMutation( ADD_VIEW_CRUISE, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Add})}
	} );
    const [editViewCruise, responseEdit] = useMutation( EDIT_VIEW_CRUISE, {
		onCompleted: (data, option) => onCompleteEdit(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Edit})}
	} );
    const [removeViewCruise, responseRemove] = useMutation( REMOVE_VIEW_CRUISE, {
		onCompleted: (data, option) => onCompleteRemove(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Remove})}
	});
    const [setFlagViewCruise, responseSetFlag] = useMutation( SET_FLAG_VIEW_CRUISE, {
		onCompleted: (data, option) => onCompleteSetFlag(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.SetFlag})}
	});
    const [truncateViewCruise, responseTruncate] = useMutation( TRUNCATE_VIEW_CRUISE, {
		onCompleted: (data, option) => onCompleteTruncate(data, option),
		onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Truncate})}
	});

    useEffect(() => {
        getCruiseListPoiAndVeh();
    }, [getCruiseListPoiAndVeh]);

    useInterval(()=>{
        // 10초에 1번씩 실행.
        if(currentMenu.menuId===AppWord.MENU_CRUISE_SCHED) {
            const now = new Date().getTime() / 1000;
            const diff = now - timeGotCruiseRecords;
            if(diff > FetchInterval) {
                setTimeGotCruiseRecords(now);
                getCruiseListOnly();
            }
        }
    }, 10000);

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetListCruisePoiAndVeh = (data, clientOption) => {
        if(data.viewCruiseList) {
            setCruiseRecords(reformCruiseList(data.viewCruiseList));
        }
        if(data.poiInfoList) {
            const indexMap = {};
            data.poiInfoList.forEach((poi, idx) => {indexMap[poi.poiId] = idx;});
            setPoiIndex(indexMap);
            setPoiList(data.poiInfoList);
        }
        if(data.vehInfoList) setVehicleList(data.vehInfoList);
    };

    const onCompleteGetCruiseList = (data, clientOption) => {
        if(data.viewCruiseList) {
            setCruiseRecords(reformCruiseList(data.viewCruiseList));
        }
    };

    const onCompleteGetCruiseLogForVeh = (data, clientOption) => {
        if(data.cruiseLogListForVeh) {
            setCruiseLog(data.cruiseLogListForVeh);
            setShowCruiseLog(true);
        }
        else setCruiseLog([]);
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.viewCruiseAdd.ok) {
            setItemToEdit(null);
            setCruiseRecords(reformCruiseList(data.viewCruiseAdd.list));
        }
        else setResponseAlert({open:true, resultData: data.viewCruiseAdd, title: ErrorTitle.Add});
    };

    const onCompleteEdit = (data, clientOption) => {
        if(data.viewCruiseEdit.ok) {
            setItemToEdit(null);
            setCruiseRecords(reformCruiseList(data.viewCruiseEdit.list));
        }
        else setResponseAlert({open:true, resultData: data.viewCruiseEdit, title: ErrorTitle.Edit});
    };

    const onCompleteRemove = (data, clientOption) => {
        if(data.viewCruiseRemove.ok) setCruiseRecords(reformCruiseList(data.viewCruiseRemove.list));
        else setResponseAlert({open:true, resultData: data.viewCruiseRemove, title: ErrorTitle.Remove});
    };

    const onCompleteTruncate = (data, clientOption) => {
        if(data.viewCruiseTruncate.ok) setCruiseRecords(reformCruiseList(data.viewCruiseTruncate.list));
        else setResponseAlert({open:true, resultData: data.viewCruiseTruncate, title: ErrorTitle.Truncate});
    };

    const onCompleteSetFlag = (data, clientOption) => {
        if(data.viewCruiseSetFlag.ok) setCruiseRecords(reformCruiseList(data.viewCruiseSetFlag.list));
        else setResponseAlert({open:true, resultData: data.viewCruiseSetFlag, title: ErrorTitle.SetFlag});
    };

    // Handler - Submit for mutation fired by ViewCruiseInput component.
    const onClickMutate = (item, isEdit) => {
        if(!isAdmin) return;
        // item.custId = ''; // testing error callback.
        const param = {variables: {viewCruise: item}};
        if(isEdit) editViewCruise(param);
        else addViewCruise(param);
    };

    const onRequestSetDone = (item) => {
        if(!isAdmin) return;
        setPromptToConfirm({
            data: item,
            title: item.vehPlates + ' 차량의 배송노선 강제 완료시키기',
            messages: [
                '해당 차량의 배송노선을 완료상태로 변경합니다.',
                '정보 변경을 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseSetFlag) responseSetFlag.reset();
                    const param = {variables:{viewCruise:{routeId: data.routeId, currentFlag:'D'}}};
                    setFlagViewCruise(param);
                }
            }
        });
    };

    const onRequestSetReady = (item) => {
        if(!isAdmin) return;
        setPromptToConfirm({
            data: item,
            title: item.vehPlates + ' 차량의 배송노선 대기상태로 변경',
            messages: [
                '해당 차량의 배송노선을 대기상태로 변경합니다.',
                '이미 기록된 도착, 출발시각 등이 배송노선에서 지워지고 복구할 수 없습니다',
                '정보 변경을 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseSetFlag) responseSetFlag.reset();
                    const param = {variables:{viewCruise:{routeId: data.routeId, currentFlag:'N'}}};
                    setFlagViewCruise(param);
                }
            }
        });
    };

    //const onRequestSetReload = () => {};
    const onRequestRemoveAll = () => {
        if(!isAdmin) return;
        setPromptToConfirm({
            data: {rem:true},
            title: '모든 배송노선 삭제',
            messages: [
                '모든 배송노선이 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다.',
                '완료되거나 대기중인 배송정보를 개별적으로 삭제하는 방법을 고려해 보세요.',
                '모든 정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseTruncate) responseTruncate.reset();
                    truncateViewCruise();
                }
            }
        });
    };

    const onRequestGetCruiseLogForVeh = (route) => {
        const param = {variables: {vehId: route.vehId}};
        getCruiseLogListForVeh(param);
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAdd = () => {
        if(!isAdmin) return;
        if(responseAdd) responseAdd.reset();
        setItemToEdit({destinList:[]}); // input component will set a default data.
    };

    const onRequestEdit = (item) => {
        if(!isAdmin) return;
        if(responseEdit) responseEdit.reset();
        setItemToEdit(item);
    };

    const onRequestRemove = (item) => {
        if(!isAdmin) return;
        setPromptToConfirm({
            data: item,
            title: item.vehPlates + ' 차량의 배송노선 정보 삭제',
            messages: [
                '해당 차량의 배송노선 정보는 즉시, 완전히 삭제됩니다.',
                '삭제된 정보는 복구할 수 없습니다',
                '정보 삭제를 진행하시겠습니까?'
            ],
            callback: (data) => {
                setPromptToConfirm(null);
                if(data) {
                    if(responseRemove) responseRemove.reset();
                    const param = {variables:{viewCruise:{routeId: data.routeId}}};
                    removeViewCruise(param);
                }
            }
        });
    };

    const onClickPoiLink = (poiId) => {
        if(poiIndex[poiId]) {
            const poi = poiList[poiIndex[poiId]];
            if(poi) {
                setPoiPopData(poi);
                return;
            }
        }
        for(const poi of poiList) {
            if(poi.poiId === poiId) {
                setPoiPopData(poi);
                return;
            }
        };
    };

    const onCloseEditor = () => {
        setItemToEdit(null);
    };


    if(ValueUtil.hasAnyAuthError(
        responseList, responseCruiseList, responseCruiseLogForVeh,
        responseAdd, responseEdit, responseRemove,
        responseSetFlag, responseTruncate)
        ) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------
    const renderPromptToConfirmBox = () => {
        const prompt = promptToConfirm ? promptToConfirm : {};
        return (
            <ConfirmDialog
                open={Boolean(promptToConfirm)}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };


    const renderEditor = () => {
        const es = itemToEdit ? itemToEdit : {};
        const editing = Boolean(es.routeId);
        const res = editing ? responseEdit : responseAdd;

        return(
            <ViewCruiseInput
                open={Boolean(itemToEdit)}
                isEdit={editing}
                item={es}
                poiList={poiList}
                vehList={vehicleList}
                routeList={cruiseRecords}
                responseSaving={res}
                onClickMutate={onClickMutate}
                onClose={onCloseEditor}
                />
        );
    };

    const renderPoiInfoDialog = () => {
        const open = Boolean(poiPopData);
        const poi = open ? poiPopData : {};
        return(

        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>{setPoiPopData(null)}}
            aria-describedby="alert-dialog-slide-poi-description"
        >
            <DialogTitle>{poi.poiName}</DialogTitle>
            <DialogContent>
                    <Box style={{fontWeight:'bold'}}>지점: {poi.poiName}</Box>
                    {
                        poi.addr
                        ?
                        <div>{poi.addr}</div> : null
                    }
                    {
                        poi.tel
                        ?
                        <div>연락처: {poi.tel}</div> : null
                    }
            </DialogContent>
        </Dialog>
        );
    };

	// AppMain.js의 resize에 따라 창을 채우는 고정된 높이를 갖는 <ContentContainer> 안에 놓임.
    return (
        <ViewCruiseContainer>
            <ViewCruiseList
                records={cruiseRecords}
                onRequestAdd={onRequestAdd}
                onRequestEdit={onRequestEdit}
                onRequestRemove={onRequestRemove}
                onRequestSetDone={onRequestSetDone}
                onRequestSetReady={onRequestSetReady}
                onRequestTruncate={onRequestRemoveAll}
                onRequestGetLogForVeh={onRequestGetCruiseLogForVeh}
                onClickPoi={onClickPoiLink}
            />
            {renderEditor()}
            {renderPromptToConfirmBox()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
            {renderPoiInfoDialog()}
            <CruiseLogListPop
                open={showCruiseLog} records={cruiseLog}
                onClose={()=>{setShowCruiseLog(false)}}
            />
        </ViewCruiseContainer>
    )
}
