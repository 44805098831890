// TermInfoInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress,
    FormControl, InputLabel } from '@mui/material';
import { AppPalette, CvoCodes } from '../../model/AppConst';
import { TermInfoDictionary } from './TermInfoGql';
import CodeSelector from '../common/CodeSelector';
import ConfirmDialog from '../message/ConfirmDialog';
import ValueUtil from '../../model/ValueUtil';
import { codeTreeRepo } from '../../model/CvoModel';
import { useQuery, useReactiveVar } from '@apollo/client';
import { LIST_TERM_MODEL_INFO } from '../term_model_info/TermModelInfoGql';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const EditorContentRow = styled(Box)({
    padding: 0,
});

const InputColumn = styled(Box)({
    display: 'inline-block',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
    minHeight:80
});
const InputMap = TermInfoDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.


export default function TermInfoInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const [modelList, setModelList] = useState([]);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.
    const responseModelList = useQuery(LIST_TERM_MODEL_INFO, {
        onCompleted: (data, option) => {onCompleteGetModelList(data, option)}
    });

    useEffect(()=>{
        if(open) {
            if(item) {
                const d = {...item};
                d.openDate = ValueUtil.nullToString(item.openDate);
                d.installDate = ValueUtil.nullToString(item.installDate);
                d.altTermId = ValueUtil.nullToString(item.altTermId);
                d.etc = ValueUtil.nullToString(item.etc);
                resetData(d);
            }
            else {
                console.log('defaultInputData', defaultInputData);
                resetData(defaultInputData);
            }
        }
    }, [open, item]);

    const NetworkList = ValueUtil.codeToSelectList(CvoCodes.NETWORK_CD, CodeTree);
    const PosSysList  = ValueUtil.codeToSelectList(CvoCodes.POS_SYS, CodeTree);

    const resetData = (data) => {
        setInputData(data ? data : defaultInputData);
        setInputError({});
        setChanged(false);
        setHasError(false);
    };

    const onCompleteGetModelList = (data, option) => {
        if(data.termModelInfoList) {
            setModelList(data.termModelInfoList.map((obj) => {
                return {value: obj.modelId, label: obj.model}
            }));
        }
    };
	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        //param.vat = 10;
        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        // check if data changed.
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['단말기 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput2(event, InputMap, inputData, inputError, isEdit);
        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);

        /* if(hasError) {
            console.log('hasError', newError);
            console.log('inputData', newData);
        } */
    };

    const onChangeDateData = (event) => {
        const data = {...inputData};
        data[event.target.id] = ValueUtil.isDateFormat(event.target.value) ? event.target.value : '';
        setChanged(true);
        setInputData(data);
    };

    const onChangeNumericText = (event) => {
        if(event.target.value) {
            if(/[^0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

    const onChangeCode = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        setInputData(data);
    };

    // render ---------------------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderSelectorField = (dict, value, selectFrom) => {
        return(
            <FieldBox>
                <CodeSelector
                    id={dict.id}
                    value={value || dict.default}
                    label={dict.label}
                    codes={selectFrom}
                    onChange={onChangeCode}
                />
            </FieldBox>
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange, disabled) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size="small"
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange}
                    disabled={disabled?true:false}
                    />
            </FieldBox>
        );
    };

    const renderDateFieldBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id={"input-label-" + dict.id} shrink>{dict.label}</InputLabel>
                    <TextField id={dict.id} type={dict.type} error={error}
                        required={dict.required}
                        value={value}
                        helperText={dict.help}
                        onChange={runOnChange}
                        size='small'
                    />
                </FormControl>
            </FieldBox>
        )
    };

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>
                {isEdit ? '단말기 정보 수정' : '단말기 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                    <InputColumn>
                        {renderTextFieldBox(InputMap.termId, inputData.termId,
                            inputError.termId, onChangeNumericText, isEdit) /* 단말기번호 */}
                        {renderTextFieldBox(InputMap.serialNo, inputData.serialNo,
                            inputError.serialNo, onChangeTextData) /* 시리얼 번호 */}
                        {renderSelectorField(InputMap.modelId, inputData.modelId, modelList)}
                        {renderSelectorField(InputMap.networkCd, inputData.networkCd, NetworkList)}
                        {renderSelectorField(InputMap.posSys, inputData.posSys, PosSysList)}
                        {renderTextFieldBox(InputMap.billAmount, inputData.billAmount,
                            inputError.billAmount, onChangeTextData) /* 청구금액 */}
                        {/* renderTextFieldBox(InputMap.vat, inputData.vat,
                            inputError.vat, onChangeTextData) 항상 10을 넣어주자 */}

                    </InputColumn>
                    <InputColumn>
                        {renderDateFieldBox(InputMap.openDate, inputData.openDate,
                            inputError.openDate, onChangeDateData) /* 개통일 */}
                        {renderDateFieldBox(InputMap.installDate, inputData.installDate,
                            inputError.installDate, onChangeDateData) /* 설치일 */}
                        {renderTextFieldBox(InputMap.altTermId, inputData.altTermId,
                            inputError.altTermId, onChangeNumericText) /* 대체단말기번호 */}
                        <FieldBox>
                            <TextField id={InputMap.etc.id} rows={3} multiline size="small"
                                type={InputMap.etc.type}
                                label={InputMap.etc.label}
                                required={InputMap.etc.required}
                                value={inputData.etc}
                                error={inputError.etc}
                                helperText={InputMap.etc.help}
                                onChange={onChangeTextData} />
                        </FieldBox>
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}
