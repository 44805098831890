// VehConfInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress,
    FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { AppPalette, CvoCodes, AppWord } from '../../model/AppConst';
import { VehConfDictionary } from './VehConfGql';
import ConfirmDialog from '../message/ConfirmDialog';
import ResponseAlert from '../message/ResponseAlert';
import RadioSelector from '../common/RadioSelector';
import ValueUtil from '../../model/ValueUtil';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});


const EditorContentRow = styled(Box)({
    padding: 0,
    display:'flex'
});

const InputColumn = styled(Box)({
    flexGrow:1,
    // display: 'inline-block',
    // width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    // margin: ColumnMargin,
    //verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
    margin:5,
});
const InputMap = VehConfDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.


export default function VehConfInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    vehPlates,
    item,
    editAllowed, // boolean
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave, error:errorSave, data:resultSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        setInputData({...item});
		setInputError({});
		setChanged(false);
		setHasError(false);
    }, [item]);

	const resetData = (data) => {
		setInputData(data ? data : defaultInputData);
		setInputError({});
		setChanged(false);
		setHasError(false);
	};

    const changeInputData = (data) => {
        setInputData(data);
        setChanged(true);
    };

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, true); // {...inputData};
        // add extra data if necessary.

        onClickMutate(param);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['이 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const newData = {...inputData};
        newData[event.target.id] = event.target.value;
        changeInputData(newData);
    };

    const onChangeAlertLimit = (event) => {
        const newData = {...inputData};
        newData.tempAlertLimit = event.target.value;
        changeInputData(newData);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderNumberInputdBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size="small"
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value >= -555 && value <= 555 ? value : ''}
                    inputProps={{min:dict.min, max:dict.max}}
                    style={{minWidth:150}}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>
        );
    };

    const renderAlertLimitBox = (dict, value, error, runOnChange) => {
        const labelId = "label_of_" + dict.id;
        return(
            <FieldBox>
                <FormControl>
                    <InputLabel id={labelId}>
                        {dict.label}
                    </InputLabel>
                    <Select
                        labelId={labelId} size='small'
                        id={dict.id}
                        name={dict.id}
                        value={value || ''}
                        label={dict.label}
                        onChange={runOnChange}
                        error={error}
                        style={{minWidth:150, textAlign:'center'}}
                    >
                        {
                            [...Array(dict.max).keys()].map((cnt) => {
                                return(
                                    <MenuItem
                                        key={cnt}
                                        value={cnt+1}
                                    >{cnt+1}회</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </FieldBox>
        );
    };

    return (
        <Dialog open={open} maxWidth="md">
            <EditorTitle>
            {vehPlates} 차량 온도알람 설정
            </EditorTitle>
            <DialogContent>
                {
                    editAllowed
                    ? null
                    :
                    <Box>
                        해당 차량에 대한 알람설정이 허용되지 않았습니다. 관제권한을 획득하거나 협력받은 타사 차량의 경우 편집허용이 선행되어야 합니다.
                    </Box>
                }
                <EditorContentRow>
                    <InputColumn>
                            {renderNumberInputdBox(InputMap.tempr1Ll, inputData.tempr1Ll,
								inputError.tempr1Ll, onChangeTextData) /* 최저전면온도 */}
                            {renderNumberInputdBox(InputMap.tempr1Ul, inputData.tempr1Ul,
								inputError.tempr1Ul, onChangeTextData) /* 최고전면온도 */}
                            {renderNumberInputdBox(InputMap.tempr2Ll, inputData.tempr2Ll,
								inputError.tempr2Ll, onChangeTextData) /* 최저후면온도 */}
                            {renderNumberInputdBox(InputMap.tempr2Ul, inputData.tempr2Ul,
								inputError.tempr2Ul, onChangeTextData) /* 최고후면온도 */}

                    </InputColumn>
                    <InputColumn>
                            {renderNumberInputdBox(InputMap.tempr3Ll, inputData.tempr3Ll,
								inputError.tempr3Ll, onChangeTextData) /* 최저중면온도 */}
                            {renderNumberInputdBox(InputMap.tempr3Ul, inputData.tempr3Ul,
								inputError.tempr3Ul, onChangeTextData) /* 최고중면온도 */}
                            {renderNumberInputdBox(InputMap.tempr4Ll, inputData.tempr4Ll,
								inputError.tempr4Ll, onChangeTextData) /* 최저온도4 */}
                            {renderNumberInputdBox(InputMap.tempr4Ul, inputData.tempr4Ul,
								inputError.tempr4Ul, onChangeTextData) /* 최고온도4 */}
                    </InputColumn>
                    <InputColumn>
                            {renderAlertLimitBox(InputMap.tempAlertLimit, inputData.tempAlertLimit,
								inputError.tempAlertLimit, onChangeAlertLimit) /* 온도경고횟수제한 */}
                    </InputColumn>
                </EditorContentRow>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        {
                            editAllowed
                            ?
                            <Button
                                onClick={onClickSubmit}
                                disabled={hasError || !changed}
                                variant='contained' color='primary'>
                                저장</Button>
                            : null
                        }
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}