// TermInfo.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import useClientSize from '../hook/useClientSize';
import TermInfoList from './TermInfoList';
import TermInfoInputSmartLogis from './TermInfoInputSmartLogis';
import ResponseAlert from '../message/ResponseAlert';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    LIST_TERM_INFO, ADD_TERM_INFO_SMART_LOGIS, TermInfoDictionary
} from './TermInfoGql';
import { AppNumber, AppObject } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import { userInfoRepo, NoUser } from '../../model/CvoModel';

const dcPad = AppNumber.dataContainerPadding;
const TermInfoContainer = styled(Box)({ position:"absolute", top:dcPad, bottom:dcPad, left:dcPad, right:dcPad, display:"flex" });

const ErrorTitle =TermInfoDictionary.errorTitle;

// 일반 업체용. 스마트폰만 등록할 수 있음.
export default function TermInfo({
    maxWidth,
    maxHeight,
    custId,
}) {
	const [termInfoSelected, setTermInfoSelected] = useState(null);
    const [openSmartLogisEditor, setOpenSmartLogisEditor] = useState(false);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    // ##### Call GraphQL to get List #####
    const [getTermInfoList, responseList] = useLazyQuery(LIST_TERM_INFO, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)}
    });

    // ##### GraphQL Mutation.###
    const [addTermInfo, responseAdd] = useMutation( ADD_TERM_INFO_SMART_LOGIS, {
		onCompleted: (data, option) => onCompleteAdd(data, option), 
		onError: (error) => {setResponseAlert({open:true, error: error, title: "스마트로지스 추가 오류"})}
	} );

    useEffect(() => {
        if(custId) getTermInfoList({variables:{custId:custId}});
        else getTermInfoList();
    }, [custId, getTermInfoList]);


    const getTermInfoListOfCust = () => {
        getTermInfoList();
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetList = (data, clientOption) => {
        if(termInfoSelected && data.termInfoList) {
            for(const item of data.custInfoList) {
                if(item.devId===termInfoSelected.devId) {
                    setTermInfoSelected(item);
                    return;
                }
            }
        }
    };

    const onCompleteAdd = (data, clientOption) => {
        if(data.termInfoAddSmartLogis.ok) {
            setOpenSmartLogisEditor(false);
            getTermInfoListOfCust();
        }
        else setResponseAlert({open:true, resultData: data.termInfoAddSmartLogis, title: ErrorTitle.Add});
    };

    // +++++++ UI callbacks ++++++++
    const onRequestAddSmartLogis = () => {
        if(responseAdd) responseAdd.reset();
        setOpenSmartLogisEditor(true);
    };

    // Handler - Submit for mutation fired by TermInfoInput component.
    const onClickMutate = (item, isEdit) => {
        // item.custId = ''; -- session puts it.
        const param = {variables: {termInfo: item}};
        addTermInfo(param);
    };

    const onCloseSmartLogisWriter = () => {
        setOpenSmartLogisEditor(false);
    };


    if(ValueUtil.hasAnyAuthError(
        responseAdd, responseList
    )) userInfoRepo(NoUser);

    // ---------------------------- Render Components ----------------------------

    const renderSamrtLogisWriter = () => {
        return(
            <TermInfoInputSmartLogis
                open={openSmartLogisEditor}
                onClose={onCloseSmartLogisWriter}
                responseSaving={responseAdd}
                onClickMutate={onClickMutate}
            />
        );
    };

	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - AppNumber.dataContainerPadding * 2;

    return (
        <TermInfoContainer>
            <TermInfoList
				maxHeight={height}
				maxWidth={width}
                responseList={responseList}
				selected={termInfoSelected}
				onClickItem={setTermInfoSelected}
                onRequestAddSmartLogis={onRequestAddSmartLogis}
            />
            {renderSamrtLogisWriter()}
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </TermInfoContainer>
    )
}
