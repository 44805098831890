//UserSessionInfo.js
import { UserClass } from "../../model/AppConst";

const EmptyUser =  {
    userName:"", custId:"", custName:"", userId:"",
    industryId:0,
    classNo:99999,
    admin:false, etrace:false,
    loginTime:"", logoutTime:"",
    viewLevel:15, initLat:37.564657445, initLon:126.974999644, initXcoord:0, initYcoord:0,
    smsUseFlag:"N", poiByGroupCd:"NO", option:"",
    secondAuthMsg:"", msg:""
};

export default class UserSessionInfo {
    sessionChecked = false;
    signedIn = false;
    user = {...EmptyUser};
    userAs = {...EmptyUser};

    constructor(sessionChecked, signedIn, loginData) {
        this.sessionChecked = sessionChecked;
        this.signedIn = signedIn;
        if(loginData) {
            this.user = loginData.user || {...EmptyUser};
            this.userAs = loginData.userAs || {...EmptyUser};
        }
    }

    isCustAdmin = () => this.user.classNo <= UserClass.CustAdmin.classNo;
    isEtrace = () => this.user.classNo <= UserClass.EtraceStaff.classNo;
    isSystemAdmin = () => this.user.classNo <= UserClass.EtraceAdmin.classNo;
    isDisguised = () => this.user.userId !== this.userAs.userId;

    /* loggable = () => {
        return new Promise((resolve, reject)=>{
            let possible = window.location.href.includes('//localhost');
            if(!possible) {
                possible = this.user.classNo <= UserClass.EtraceStaff.classNo;
            }
            //possible = false;
            if(possible) {
                resolve(true);
            }
            else {
                if(reject) {
                    reject(false);
                }
            }
        });
    }; */

    /**
     * 로컬호스트일 경우 또는 이트레이스 직원으로 로그인했을 때에만 로그를 출력할 수 있게 함.
     * @param {*} callback - calls console.log(..)
     */
    /* logLocal = (callback) => {
        this.loggable().then(callback).catch(()=>{});
    }; */

    ifLocal = (callback) => {
        let isLocal = window.location.href.includes('//localhost');
        if(!isLocal) {
            isLocal = this.user.classNo <= UserClass.EtraceStaff.classNo;
        }
        if(isLocal) callback(this);
    };
}
