// CruiseLogListPop.js
import React from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FlexyTable from '../common/flexytable/FlexyTable';
import { AppNumber, TableColumnType, AppPalette } from '../../model/AppConst';

const LogDialogTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground, color: 'white', minWidth: 300,
});

const LogContainer = styled(Box)({ position:'relative' });

const VehRouteListContainer = styled(Box)({
    position:"absolute", top:40, right:0, bottom: 0, left: AppNumber.dataContainerPadding,
	display:'flex', flexDirection:'column'
});

const TableColumns = [
    //{ id: 'logTime', label: Dict.logTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    //{ id: 'custId', label: Dict.custId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'routeId', label: Dict.routeId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    //{ id: 'vehId', label: Dict.vehId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'poiId', label: Dict.poiId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'centerName', label: "출발지", width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'centerAta', label: "출발지 도착", width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'centerAtd', label: "출발지 출발", width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'lastAta', label: "종료시각", width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'destinCsv', label: "도착지", width: 180, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} }
];

export default function CruiseLogListPop({
    open,
    records,
    onClose
}) {
    const clientSize = useClientSize();

    const maxHeight = clientSize.dataAreaHeight - 200;
    const maxWidth = clientSize.dataAreaWidth - 200;

    return(
        <Dialog open={open} maxWidth="lg">
            <LogDialogTitle>
                {
                    records.length > 0
                    ? records[0].vehPlates + " 과거 배송 기록"
                    : "배송기록 없음"
                }
            </LogDialogTitle>
            <DialogContent style={{height:maxHeight, width:maxWidth}}>
                <LogContainer width={maxWidth} height={maxHeight}>
                    <VehRouteListContainer>
                        <FlexyTable
                            name="cruise_log_list"
                            uniqueKey="cruiseNid"
                            columns={TableColumns}
                            records={records}
                            tools={[]}
                        />
                    </VehRouteListContainer>
                </LogContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='contained' color='primary'>닫기</Button>
            </DialogActions>
        </Dialog>
    );
}
