// NaverRoute.js
import { MapValue } from "./AppConst";
import ValueUtil from "./ValueUtil";

const NAVER_ROAD_COLOR_10 = '#FF0000';
const NAVER_ROAD_COLOR_30 = '#ff9900';
const NAVER_ROAD_COLOR_60 = '#3333FF';
const NAVER_ROAD_COLOR_FAST = '#00BB00';

const ColorForSpeedRange = {
    0 : '#000000',
    10 : NAVER_ROAD_COLOR_10,
    30 : NAVER_ROAD_COLOR_30,
    60 : NAVER_ROAD_COLOR_60,
    1000 : NAVER_ROAD_COLOR_FAST,
};

function speedRange(speed) {
    if(speed <= 10) return 10;
    if(speed <= 30) return 30;
    if(speed <= 60) return 60;
    return 1000;
}

class RouteGuide {
    direction=0;
    distance=0; // converted from meters to Km
    duration=0;
    hours = 0;

    speed = 0;
    range = 0;

    firstIndex=0;
    lastIndex=0;

    path = [];

    constructor(firstIndex, guide, path) {
        this.firstIndex = firstIndex;
        this.direction = guide.direction;
        this.distance = guide.distance / 1000;
        this.duration = guide.duration;
        this.hours = guide.duration / 3600000;
        this.lastIndex = guide.pointIndex;

        if(this.duration > 0) {
            this.speed = Math.round(this.distance * 10 / this.hours)/10; // 소숫점 하나.
            this.range = speedRange(this.speed);
        }
        else {
            this.speed = 0;
            this.range = 0;
        }

        for(var i=firstIndex; i<=this.lastIndex; i++) this.path.push({...path[i]});
    }

    // 속도 범위가 같으면 받아들일 수 있다.
    isAcceptable = (guide) => {
        const distance = guide.distance / 1000;
        const hours = guide.duration / 3600000;
        if(guide.duration>0) {
            const speed = distance / hours;
            const range = speedRange(speed);
            return range===this.range;
        }
        return false;
    };

    // 같은 색으로 표현할 수 있는 경로를 뒤에 추가함.
    addPath = (firstIndex, guide, path) => {
        const distance = guide.distance / 1000;
        const hours = guide.duration / 3600000;
        this.distance += distance;
        this.duration += guide.duration;
        this.hours += hours;
        this.lastIndex = guide.pointIndex;

        if(this.duration>0) {
            this.speed = Math.round(this.distance * 10 / this.hours)/10; // 소숫점 하나.
            this.range = speedRange(this.speed);
        }

        for(var i=firstIndex; i<=this.lastIndex; i++) this.path.push({...path[i]});
    };

    dump = () => {
        console.log(`: ${this.firstIndex} ~ ${this.lastIndex}: points=${this.path.length}. spd=${this.speed} range=${this.range} dist=${this.distance} hrs=${this.hours}`);
        console.log(this.path);
    };

    getPathToDraw = () => {
        const color = ColorForSpeedRange[this.range];
        return {color: color, path: this.path};
    };
}

/**
 * 네이버 경로를 해석하고 네이버 지도에서 그 경로를 출력할 수 있는 데이터를 제공하기 위한 클래스.
 */
export default class NaverRoute {
    routeId = 0; // getTime
    timeString = '';
    from = {};
    to = {};
    trackType = '';
    
    distance=0;
    tollFare=0;
    fuelPrice=0;
    size=0;

    path = [];
    guides = []; // raw guides

    routeGuides = []; // guides built by this class.
    /**
     * 네이버 경로를 해석하기 위해 기본 정보를 주고 객체를 생성.
     * @param {object} data - 네이버의 경로 및 가이드 정보. path에 대한 index 가짐.
     */
    constructor(data, from, to, trackType) {
        this.from = from;
        this.to = to;
        this.trackType = trackType;

        if(data) {
            this.distance = data.distance || 0;
            this.tollFare = data.tollFare || 0;
            this.fuelPrice = data.fuelPrice || 0;
            this.path = data.points ? data.points : []; // {lat, lon}
            this.guides = data.guides ? data.guides : []; // {direction,distance,duration,pointIndex}
        }
        this.routeGuides = [];

        this.routeId = new Date().getTime();
        this.timeString = ValueUtil.getYmdHmsInputText().substring(11); // hh:mm:ss
    }

    addRouteGuide = (firstIndex, guide) => {
        if(this.routeGuides.length > 0) {
            const guideUnit = this.routeGuides[this.routeGuides.length-1];
            if(guideUnit.isAcceptable(guide)) {
                guideUnit.addPath(firstIndex, guide, this.path);
            }
        }

        // 두번쨰부터는 이전데이터의 마지막 인덱스를 써야 폴리곤 사이가 뜨지 않는다. (guide.pointIndex+1 하지 않는다)
        const first = firstIndex>0 ? firstIndex-1 : firstIndex;
        const guideUnit = new RouteGuide(first, guide, this.path);
        this.routeGuides.push(guideUnit);
    };

    build = () => {
        var from = 0;
        for(const guide of this.guides) {
            this.addRouteGuide(from, guide);
            from = guide.pointIndex + 1; // pointIndex는 가이드가 가리키는 마지막 path 인덱스를 가리킴.
        }
        if(from !== this.path.length) {
            console.log(`Guide and path are not sync'd remain index=${from-1} should be ${this.path.length}-1`);
        }

        //this.guides = []; 나중에 보여주기 위해서 지우지 말자.
        this.path = []; // release memory
    };

    dump = () => {
        console.log(`~~~ Route ${this.routeGuides.length} guides`);
        console.log(`track type: ${this.trackType}`);
        console.log(`from : ${this.from.address}`);
        console.log(`to: ${this.to.address}`);
        console.log(` d=${this.distance} toll=${this.tollFare} fuel=${this.fuelPrice}`);
        for(const g of this.routeGuides) {
            g.dump();
        }
    };

    getPathsToDraw = () => {
        const paths = []; // {color, path}
        for(const g of this.routeGuides) {
            paths.push(g.getPathToDraw());
        }
        return paths;
    };

    getTrackLabel = () => {
        for(const t of MapValue.NaverTrackOption) {
            if(t.value===this.trackType) return t.label;
        }
        return this.trackType;
    };
}