//CheckTable.js
import React, { useEffect, useState } from 'react';
import { styled  } from '@mui/material/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    IconButton, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow
} from '@mui/material';

const HeaderCell = styled(TableCell)({
    backgroundColor:'#eee',
    cursor:'pointer'
});

function isInRecord(record, columns, search) {
    if(!Boolean(search)) return false;
    if(search.length === 0) return false;
    for(const column of columns) {
        const val = record[column.id];
        if(val) {
            const str = '' + val;
            if(str.indexOf(search) >= 0) return true;
        }
    }
    return false;
}

function compareForSearchOnly(a, b, search, columns) {
    let toSearch = false;
    if(search) toSearch = search.length > 0;
    if(!toSearch) return 0;

    if(isInRecord(a, columns, search)) {
        if(isInRecord(b, columns, search)) return 0;
        else return -1;
    }
    else {
        if(isInRecord(b, columns, search)) return 1;
        else return 0;
    }
}

function compareWithSearch(a, b, isReverse, id, search, columns) {
    let toSearch = false;
    if(search) toSearch = search.length > 0;

    if(toSearch) {
        if(!a[id]) {
            if(!b[id]) return 0;
            else return 1;
        }
        else {
            if(!b[id]) return -1;

            if(isInRecord(a, columns, search)) {
                if(isInRecord(b, columns, search)) {
                    if(a[id] === b[id]) return 0;
                    else if(a[id] < b[id]) return isReverse ? 1 : -1;
                    else return isReverse ? -1 : 1;
                }
                else return -1;
            }
            else {
                if(isInRecord(b, columns, search)) return 1;
                else {
                    if(a[id] === b[id]) return 0;
                    else if(a[id] < b[id]) return isReverse ? 1 : -1;
                    else return isReverse ? -1 : 1;
                }
            }
        }
    }
    else {
        if(!a[id]) {
            if(!b[id]) return 0;
            else return 1;
        }
        else {
            if(!b[id]) return -1;
            
            if(a[id] === b[id]) return 0;
            else if(a[id] < b[id]) return isReverse ? 1 : -1;
            else return isReverse ? -1 : 1;
        }
    }
}

// Check(select)를 위한 테이블.
export default function CheckTable({
    columns, // id, label, minWidth
    records,
    search,
    iconTrue,
    iconFalse,
    onCheckItem,
    idToCheck
}) {
    const [sortKey, setSortKey] = useState(null);
    const [sortReverse, setSortReverse] = useState(false);

    useEffect(()=>{
        setSortKey(null);
    }, [records]);

    const onClickHeader = (column) => {
        if(sortKey === column.id) setSortReverse(!sortReverse);
        else {
            setSortKey(column.id);
            setSortReverse(false);
        }
    };

    const compareRecord = (a, b) => {
        return compareWithSearch(a, b, sortReverse, sortKey, search, columns);
    };

    const compareSearching = (a, b) => {
        return compareForSearchOnly(a, b, search, columns);
    };

    const renderRecords = () => {
        const list = sortKey ? records.sort(compareRecord) : records.sort(compareSearching);
        return(
            <TableBody style={{overflow:'auto'}}>
                {
                    list.map((rec, idx) => {
                        return(
                            <TableRow key={idx} hover>
                                <TableCell>
                                    <IconButton size='small' onClick={()=>{onCheckItem(rec)}} color='primary'>
                                        {
                                            Boolean(rec[idToCheck]) ?
                                            (iconTrue || <CheckBoxIcon fontSize='small' />) :
                                            (iconFalse || <CheckBoxOutlineBlankIcon fontSize='small' color="disabled" />)
                                        }
                                    </IconButton>
                                </TableCell>
                                {
                                    columns.map((c, colIdx) => {
                                        const id = c.id;
                                        const val = c.format ? c.format(rec[id]) : rec[id];
                                        return(
                                            <TableCell key={colIdx}>
                                                {val}
                                            </TableCell>
                                        );
                                    })
                                }
                            </TableRow>
                        );
                    })
                }
            </TableBody>
        );
    };

    return (
        <TableContainer>
            <Table aria-label='toss-table' size='small' stickyHeader>
                <TableHead>
                    <TableRow>
                        <HeaderCell width={36}>&nbsp;</HeaderCell>
                        {
                            columns.map((column) => {
                                const minWidth = column.minWidth ? column.minWidth : 40;
                                const style = {minWidth: minWidth, overflow:'hidden'};
                                if(column.width) {
                                    style.width = column.width;
                                }

                                if(sortKey === column.id) {
                                    if(sortReverse) style.color = 'red';
                                    else style.color = 'blue';
                                }

                                return(
                                    <HeaderCell
                                        key={column.id}
                                        align={column.align || 'left'}
                                        style={style}
                                        onClick={()=>{onClickHeader(column)}}
                                    >
                                        {column.label}
                                    </HeaderCell>
                                );
                            })
                        }
                    </TableRow>
                </TableHead>
                {renderRecords()}
            </Table>
        </TableContainer>
    )
}
