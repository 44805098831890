// RouteHourlyList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FlexyTable from '../common/flexytable/FlexyTable';
import ResponseAlert from '../message/ResponseAlert';
import MultiPicker from '../common/MultiPicker';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, AppObject, TableColumnType } from '../../model/AppConst';
import { useLazyQuery } from '@apollo/client';
import { LIST_ROUTE_HOURLY } from './RouteHourlyGql';

const dcPad = AppNumber.dataContainerPadding;

const RouteHourlyListContainer = styled(Box)({
    position:"absolute", top:dcPad, right:dcPad, bottom: dcPad, left: dcPad, display:'flex', flexDirection:'column'
	//flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    display:'flex', alignItems:'center'
});

function makeDailyData(array) {
    const vehDaily = [];
    const mapVeh = {};
    for(const rec of array) {
        const {vehId} = rec;
        const hourData = {
            timeStart:rec.timeStart,
            hourStart:rec.hourStart,
            gpsTime:rec.gpsTime,
            secondsRun:rec.secondsRun,
            totalDist:rec.totalDist,
            avgSpeed:rec.avgSpeed,
            avgSpeedRun:rec.avgSpeedRun,
            maxSpeed:rec.maxSpeed,
            avgCycleMake:rec.avgCycleMake,
            avgCycleReport:rec.avgCycleReport,
            positionCount:rec.positionCount
        };
        let veh;
        if(mapVeh[vehId]) {
            veh = mapVeh[vehId];
            veh.hourly.push(hourData);
        }
        else {
            veh = {
                info:{vehId:vehId, vehPlates: rec.vehPlates, vehAlias: rec.vehAlias, custId: rec.custId, custName: rec.custName, vehTypeTon: rec.vehTypeTon},
                hourly: [hourData]
            };
            mapVeh[rec.vehId] = veh;
            vehDaily.push(veh);
        }
    }
    return vehDaily;
}
const DistTypeCol = {width:100, show:true, option: {align:'right', type: TableColumnType.FLOAT}}
function makeHourId(from, till) {return 'hh_' + from + '_' + till}
function makeColumnsForHours(hoursSelected) {
    let prevLast = 0;
    const hours = [...hoursSelected, 24];
    const columns = [];
    for(const hour of hours) {
        const id = makeHourId(prevLast, hour);
        const col = {
            id: id,
            label: '' + prevLast + '~' + hour + '시',
            ...DistTypeCol
        };
        columns.push(col);
        prevLast = hour;
    }
    return columns;
};

function makeRecordsForHours(hoursSelected, array) {
    let prevLast = 0;
    const hours = [...hoursSelected, 24].map((h)=> {
        const range = {from: prevLast, untill: h, length: h-prevLast, id: makeHourId(prevLast, h)};
        prevLast = h;
        return range;
    });
    const records = [];
    for(const rec of array) {
        const daily = {...rec.info, sections:[]};
        const hourly = rec.hourly;
        daily.date = hourly[0].timeStart.substring(0,10);
        let totalDist = 0;
        for(const range of hours) {
            const dist = hourly.slice(range.from, range.untill).reduce((prev,curr)=>{
                return prev + curr.totalDist;
            },0);
            daily[range.id] = dist;
            totalDist += dist;
        }
        daily.dist = totalDist;
        daily.maxSpeed = hourly.reduce((max,rec)=>{return rec.maxSpeed > max ? rec.maxSpeed : max},0);
        records.push(daily);
    }
    return records;
}

const HourRec = [...Array(23).keys()].map((h)=>{const hour=h+1; return {key:hour, label:hour, miniLabel:'.', pop:'' + hour + '시'}});
const defaultHours = [8,12,18];

const TableColumns = [
	{ id: 'vehPlates', label: "차량번호", width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehAlias', label: '지도표시명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 150, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'date', label: '주행날짜', width: 120, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    //{ id: 'runTime', label: '주행시간', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'dist', label: '주행거리', width: 120, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    //{ id: 'avgSpeed', label: Dict.avgSpeed.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
    //{ id: 'avgSpeedRun', label: '평균속도', width: 120, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'maxSpeed', label: '최대속도', width: 120, show: true, option:{align: 'right', type: TableColumnType.FLOAT} },
    //{ id: 'avgCycleMake', label: Dict.avgCycleMake.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    //{ id: 'avgCycleReport', label: '위치보고주기', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    //{ id: 'positionCount', label: Dict.positionCount.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.FLOAT} },
];

export default function RouteHourlyList({
	maxHeight,
	maxWidth,
}) {
    const [date, setDate] = useState(ValueUtil.getYmdText(3600*18));
    const [records, setRecords] = useState([]);
    const [daily, setDaily] = useState([]); // buffer from response data to records
    const [search, setSearch] = useState('');
    const [columns, setColumns] = useState(TableColumns.slice());
    const [itemSelected, setItemSelected] = useState(null);
    const [hoursSelected, setHoursSelected] = useState(defaultHours);
    const [pickerEnlarged, setPickerEnlarged] = useState(false);
    const [responseAlert, setResponseAlert] = useState(null);
    const clientSize = useClientSize();

    useEffect(()=>{
        if(daily && daily.length > 0) {
            //
            const cols = TableColumns.slice();
            const hours = hoursSelected.sort((a,b)=>a-b);
            for(const c of makeColumnsForHours(hours)) cols.push(c);
            const dailySected = makeRecordsForHours(hours, daily);
            setColumns(cols);
            setRecords(dailySected);
        }
    }, [hoursSelected, daily]);

    // ##### Call GraphQL to get List #####
    const [getRouteHourlyList, responseList] = useLazyQuery(LIST_ROUTE_HOURLY, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetRouteHourlyList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "시간대별 운행정보 조회 오류"})}
    });

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetRouteHourlyList = (data, clientOption) => {
        if(data.routeHourlyList) {
            const vehDaily = makeDailyData(data.routeHourlyList);

            //setColumns(cols);
            setDaily(vehDaily);
            //setRecords(dailySected);
        }
    };

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
    };

    const onChangeDate = (event) => {
        setDate(event.target.value);
    };

    const onChangeHours = (hours) => {
        setHoursSelected(hours.slice());
    };

    const onClickGetList = () => {
        getRouteHourlyList({variables:{date:date}});
    };


	const height = maxHeight || clientSize.dataAreaHeight;
	const width = maxWidth || clientSize.dataAreaWidth - AppNumber.dataContainerPadding * 2;
	
    const TableHeight = height - clientSize.HeaderToolBoxHeight
        - clientSize.SmallBoxPadding * 2 - 2;

    return(
        <RouteHourlyListContainer>
            <HeaderBox>
                <span>주행날짜 &nbsp;</span>
                <TextField type='date' value={date} size="small" onChange={onChangeDate} />
                &nbsp;
                <Button size={AppWord.SMALL}
                    onClick={onClickGetList}
                    variant='contained' color="primary"
                    disabled={!Boolean(date)}
                >
                    조회
                </Button>
                &nbsp;&nbsp;
                <MultiPicker
                    title="시간대 분할"
                    data={HourRec}
                    selected={hoursSelected}
                    minItemWidth={20}
                    fontSize="0.85em"
                    maxCount={4}
                    onChange={onChangeHours}
                    onChangeSize={setPickerEnlarged}
                />
                &nbsp;&nbsp;
                {
                    pickerEnlarged ? null :
                    <FormControl variant='outlined' size='small'>
                        <InputLabel htmlFor="search-RouteHourly-list" style={{backgroundColor:'white'}}>검색</InputLabel>
                        <OutlinedInput id="search-RouteHourly-list" onChange={onChangeSearch}
                            value={search}
                            disabled={records.length === 0}
                            size='small'
                            endAdornment={
                                Boolean(search) ?
                                <InputAdornment position={AppWord.END}>
                                    <IconButton
                                    onClick={()=>{setSearch('')}}
                                    size={AppWord.SMALL}
                                    edge={AppWord.END}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                                :
                                null
                            }
                        />
                    </FormControl>
                }
            </HeaderBox>
            <FlexyTable
                name="route_hourly_list"
                uniqueKey="vehId"
                columns={columns}
                records={records}
                search={search}
                tools={[]}
				onClickOneRow={onSelectItem}
				selected={null}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </RouteHourlyListContainer>
    );

}

/*

Make a react component named MultiPicker using MUI.
Properties will be given as:
1. Array of data to display. Item of the array is an object like {key:anyData, label:'label text'}.
2. Maximum number a user can pick.
3. Default items selected using key value of array above.
This component displays all the items given in small boxes.
Selected item should be colored 'black', otherwise 'lightgrey'.
If a user select an item and the counts of selected items exceeds the maximum number, alert and cancel the operation.
Use 'styled' to apply styles to subcomponents.
*/