// CustInfoInput.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { AppPalette, CvoCodes, AppWord } from '../../model/AppConst';
import { CustInfoDictionary } from './CustInfoGql';
import ConfirmDialog from '../message/ConfirmDialog';
import Boolbox from '../common/Boolbox';
import RadioSelector from '../common/RadioSelector';
import CodeSelector from '../common/CodeSelector';
import ValueUtil from '../../model/ValueUtil';
import { codeTreeRepo } from '../../model/CvoModel';

const ColumnBoxWidth = 400;
const ColumnMargin = 5;

const EditorTitle = styled(DialogTitle)({
    backgroundColor: AppPalette.HeaderBackground,
    color: 'white',
    minWidth: 300,
});

const EditorContentTable = styled(Box)({
    display:'table',
    width:'100%'
});

const EditorContentRow = styled(Box)({
    padding: 0,
    display:'table-row'
});

const InputColumn = styled(Box)({
    display: 'table-cell',
    width: ColumnBoxWidth, // adjust here.
    paddingTop: 10,
    margin: ColumnMargin,
    verticalAlign: 'top'
});

const FieldBox = styled(Box)({
    display: 'block',
    paddingTop: 10,
    height: 64, // check해 봄. 74 - padding 10
});

const SelectorBox = styled(Box)({
    display: 'table-row',
});

const SelectSlot = styled(Box)({
    display: 'table-cell',
});

const MildHr = styled('hr')({
    backgroundColor:'#eeeeee',
    height:1,
    border: 0,
});

const InputMap = CustInfoDictionary.inputMap; // 입력양식 상수, validation
const defaultInputData = ValueUtil.defaultValuesFromInputMap(InputMap); // 입력양식 상수 중 기초 데이터(default)만 추출.
const defaultErrors = Object.keys(InputMap).map((key) => {return {key:false}});

export default function CustInfoInput({
    open, // 편집 후 저장이 성공적이면 open==false 된다.
    custId, // If ...
    item,
    isEdit, // == Boolean(item)
    onClose, // Cancel editing.
    onClickMutate, // Ask controller to submit.
    responseSaving // Response if result is NOT OK.
}) {
    const CodeTree = useReactiveVar(codeTreeRepo);
    const [changed, setChanged] = useState(false); // 입력으로 인한 변경이 있는가?
    //const [inputData, setInputData] = useState(item ? item : {...defaultInputData}); // 입력한 데이터.
    const [inputData, setInputData] = useState(defaultInputData); // 입력한 데이터.
	const [inputError, setInputError] = useState({...defaultErrors}); // 입력된 것들 중 오류 여부 표시.
    const [hasError, setHasError] = useState(false); // 하나라도 오류가 있는가? 입력되지 않은 것이 있는가?
    const [promptToConfirm, setPromptToConfirm] = useState(null);
    const {loading:loadingSave} = responseSaving; // 전송 결과에 대한 View 기능 소화.

    useEffect(()=>{
        if(item) {
            const d = {...item};
            d.compBizno = ValueUtil.reformWith(item.compBizno, AppWord.NUM_N_HYPN);
            d.custCorpNo = ValueUtil.reformWith(item.custCorpNo, AppWord.NUM_N_HYPN);
            d.zipCd = ValueUtil.reformWith(item.zipCd, AppWord.NUM_N_HYPN);
            d.telNo = ValueUtil.reformWith(item.telNo, AppWord.NUM_N_HYPN);
            d.faxNo = ValueUtil.reformWith(item.faxNo, AppWord.NUM_N_HYPN);
            d.postRecvZipCd = ValueUtil.reformWith(item.postRecvZipCd, AppWord.NUM_N_HYPN);
            d.agentTelNo = ValueUtil.reformWith(item.agentTelNo, AppWord.NUM_N_HYPN);
            
            setInputData(d);
        }
        else setInputData(defaultInputData);
        setInputError({});
        setChanged(false);
        setHasError(false);
    }, [item]);

    const FirmTypeList = ValueUtil.codeToSelectList(CvoCodes.FIRM_TYPE_CD, CodeTree);
    const BizTypeList = ValueUtil.codeToSelectList(CvoCodes.BIZ_TYPE_CD, CodeTree);
    const BillMethList = ValueUtil.codeToSelectList(CvoCodes.BILL_METH, CodeTree);
    const PayMethList = ValueUtil.codeToSelectList(CvoCodes.PAY_METH, CodeTree);
    const PayTermList = ValueUtil.codeToSelectList(CvoCodes.PAY_TERM, CodeTree);

    const resetData = (data) => {
        setInputData(data ? data : defaultInputData);
        setInputError({});
        setChanged(false);
        setHasError(false);
    };
    

	// 저장 호출은 컨트롤러로 보내고, 그 결과를 responseSaving 받아서 보여준다.
    const onClickSubmit = () => {
        const param = ValueUtil.getDataToSubmit(inputData, InputMap, isEdit); // {...inputData};
        // add extra data if necessary.
        if(!isEdit) param.custId = custId;
        onClickMutate(param, isEdit);
    };

    const onClickCancel = () => {
        if(changed) {
            setPromptToConfirm({
                data: true,
                title: '정보가 변경되었습니다.',
                messages: ['고객 정보를 변경했습니다.', '변경사항을 무시하고 편집을 종료하시겠습니까?'],
                labelToYes: '무시하고 종료',
                callback: (yes) => {
                    setPromptToConfirm(null);
                    if(yes) {
                        resetData();
                        onClose();
                    }
                }
            });
        }
        else {
            resetData();
            onClose();
        }
    };

    const onChangeTextData = (event) => {
        // evaluate input data against readiness.
        const [newData, newError, hasError] = ValueUtil.evalTextInput(event, InputMap, inputData, inputError);

        setInputData(newData);
        setInputError(newError);
        setHasError(hasError);
        setChanged(true);
    };

    const onChangeNumAndHyphen = (event) => {
        if(event.target.value) {
            if(/[^-0-9]/.test(event.target.value)) return;
        }
        onChangeTextData(event);
    };

	// If necessary
    const onChangeRadioSelector = (event) => {
        const data = {...inputData};
        data[event.target.id] = event.target.value;
        setInputData(data);
    };

    const onChangeCode = (id, value) => {
        const data = {...inputData};
        data[id] = value;
        setInputData(data);
    };

    const onChangeBoolbox = (id, boolText) => {
        const data = {...inputData};
        data[id] = boolText;
        setInputData(data);
    };

    // redner -------------------
    const renderPromptIgnoreChange = () => {
        const open = Boolean(promptToConfirm);
        const prompt = open ? promptToConfirm : {}; // onClose 오류나지 않도록
        return (
            <ConfirmDialog
                open={open}
                prompt={prompt}
                onClose={prompt.callback}
            />
        );
    };

    const renderSelectorSlot = (dict, value, selectFrom) => {
        return(
            <SelectSlot>
                    <CodeSelector
                        id={dict.id}
                        value={value || dict.default}
                        label={dict.label}
                        codes={selectFrom}
                        onChange={onChangeCode}
                    />
            </SelectSlot>
        );
    };

    const renderTextFieldBox = (dict, value, error, runOnChange) => {
        return(
            <FieldBox>
                <TextField id={dict.id} size="small"
                    type={dict.type}
                    label={dict.label}
                    required={dict.required}
                    value={value || ''}
                    error={error}
                    helperText={dict.help}
                    onChange={runOnChange} />
            </FieldBox>

        );
    };

    return (
        <Dialog open={open} maxWidth="lg">
            <EditorTitle>
                {isEdit ? '업체 정보 수정' : '업체 추가'}
            </EditorTitle>
            <DialogContent>
                <EditorContentRow>
                        <FieldBox>
                            <TextField
                                id={InputMap.custName.id} style={{width:300, marginRight:50}}
                                type={InputMap.custName.type}
                                label={InputMap.custName.label}
                                required={InputMap.custName.required}
                                value={inputData.custName}
                                error={inputError.custName}
                                helperText={InputMap.custName.help}
                                onChange={onChangeTextData}
                            />
                            <RadioSelector
                                id={InputMap.industryId.id}
                                label={InputMap.industryId.label}
                                selectFrom={InputMap.industryId.select}
                                initialValue={inputData.industryId}
                                horizontal={true}
                                onChange={onChangeRadioSelector}
                                disabled={isEdit}
                            />
                        </FieldBox>
                </EditorContentRow>
                <MildHr />
                <EditorContentTable>
                    <SelectorBox>
                        {renderSelectorSlot(InputMap.firmTypeCd, inputData.firmTypeCd, FirmTypeList)}
                        {renderSelectorSlot(InputMap.bizTypeCd, inputData.bizTypeCd, BizTypeList)}
                        {renderSelectorSlot(InputMap.billMeth, inputData.billMeth, BillMethList)}
                        {renderSelectorSlot(InputMap.payMeth, inputData.payMeth, PayMethList)}
                        {renderSelectorSlot(InputMap.payTerm, inputData.payTerm, PayTermList)}
                    </SelectorBox>
                </EditorContentTable>
                <MildHr />
                <EditorContentTable>
                    <EditorContentRow>
                        <InputColumn>
                            {renderTextFieldBox(InputMap.compBizno, inputData.compBizno, inputError.compBizno,
                                onChangeNumAndHyphen) /* 사업자번호 */}
                            {renderTextFieldBox(InputMap.custCorpNo, inputData.custCorpNo, inputError.custCorpNo,
                                onChangeNumAndHyphen) /* 법인번호 */}
                            {renderTextFieldBox(InputMap.reprName, inputData.reprName, inputError.reprName,
                                onChangeTextData)}
                            {renderTextFieldBox(InputMap.telNo, inputData.telNo, inputError.telNo,
                                onChangeNumAndHyphen)}
                            {renderTextFieldBox(InputMap.faxNo, inputData.faxNo, inputError.faxNo,
                                onChangeNumAndHyphen)}
                            {renderTextFieldBox(InputMap.unitServiceFee, inputData.unitServiceFee, inputError.unitServiceFee,
                                onChangeTextData)}
                            <FieldBox>
                                <Boolbox dictionary={InputMap.smsUseFlag}
                                    textValue={inputData.smsUseFlag}
                                    onChange={onChangeBoolbox} />
                            </FieldBox>
                        </InputColumn>
                        <InputColumn>
                            {renderTextFieldBox(InputMap.zipCd, inputData.zipCd, inputError.zipCd,
                                onChangeNumAndHyphen)}
                            {renderTextFieldBox(InputMap.addr, inputData.addr, inputError.addr,
                                onChangeTextData)}
                            {renderTextFieldBox(InputMap.postRecvZipCd, inputData.postRecvZipCd, inputError.postRecvZipCd,
                                onChangeNumAndHyphen)}
                            {renderTextFieldBox(InputMap.postRecvAddr, inputData.postRecvAddr, inputError.postRecvAddr,
                                onChangeTextData)}
                        </InputColumn>
                        <InputColumn>
                            {renderTextFieldBox(InputMap.billJobType, inputData.billJobType, inputError.billJobType,
                                onChangeTextData)}
                            {renderTextFieldBox(InputMap.billJobClass, inputData.billJobClass, inputError.billJobClass,
                                onChangeTextData)}
                            {renderTextFieldBox(InputMap.billStaffName, inputData.billStaffName, inputError.billStaffName,
                                onChangeTextData)}
                            {renderTextFieldBox(InputMap.billStaffRank, inputData.billStaffRank, inputError.billStaffRank,
                                onChangeTextData)}
                            {renderTextFieldBox(InputMap.billStaffTelNo, inputData.billStaffTelNo, inputError.billStaffTelNo,
                                onChangeNumAndHyphen)}
                            {renderTextFieldBox(InputMap.billStaffFaxNo, inputData.billStaffFaxNo, inputError.billStaffFaxNo,
                                onChangeNumAndHyphen)}
                            {renderTextFieldBox(InputMap.billStaffEmail, inputData.billStaffEmail, inputError.billStaffEmail,
                                onChangeTextData)}
                        </InputColumn>

                        <InputColumn>
                            {renderTextFieldBox(InputMap.agentName, inputData.agentName, inputError.agentName,
                                onChangeTextData)}
                            {renderTextFieldBox(InputMap.agentTelNo, inputData.agentTelNo, inputError.agentTelNo,
                                onChangeNumAndHyphen)}
                            {renderTextFieldBox(InputMap.agentEmail, inputData.agentEmail, inputError.agentEmail,
                                onChangeTextData)}
                            <FieldBox>&nbsp;</FieldBox>
                            {renderTextFieldBox(InputMap.salesStaffName, inputData.salesStaffName, inputError.salesStaffName,
                                onChangeTextData)}
                            <FieldBox>
                                <TextField id={InputMap.etc.id} rows={4} multiline size="small"
                                    type={InputMap.etc.type}
                                    label={InputMap.etc.label}
                                    required={InputMap.etc.required}
                                    value={inputData.etc}
                                    error={inputError.etc}
                                    helperText={InputMap.etc.help}
                                    onChange={onChangeTextData} />
                            </FieldBox>
                        </InputColumn>
                    </EditorContentRow>
                </EditorContentTable>
            </DialogContent>
            <DialogActions>
                {
                    loadingSave
                    ?
                    <span>
                        <CircularProgress />
                        &nbsp;데이터 저장 중...
                    </span>
                    :
                    <>
                        <Button
                            onClick={onClickSubmit}
                            disabled={hasError || !changed}
                            variant='contained' color='primary'>
                            저장</Button>
                        <Button onClick={onClickCancel} color='warning'
                            variant='contained'>취소</Button>
                    </>
                }
            </DialogActions>
            {renderPromptIgnoreChange()}
        </Dialog>
    );
}