import ValueUtil from "../../model/ValueUtil";

export const UploadStat = {
    NEW: 'new', BAD: 'bad', SEARCH: 'sch', READY: 'ready', NA: 'n/a', UPLOAD: 'up', FAIL: 'fail', OK:'ok',
};

/**
 * POI_INFO 정보 일괄 업로드를 위한 데이터 관리용 클래스.
 */
export default class PoiBatch {

    /**
     * Parse TSV text and return.
     * @param {string} tsvText - 일괄저장을 위해 작성된 TSV 텍스트.
     * @param {object} poiTypeSelected - poi type object for each poi
     */
    static parse(tsvText, poiTypeSelected) {

        if(tsvText) {
            const array = [];
            let errorCount = 0;

            tsvText.split(/[\r\n]+/).forEach((line, idx)=>{
                const num = idx+1;
                let obj;

                if(ValueUtil.realLen(line)>0) {
                    const cols = line.split(/\t/);

                    if(cols.length<2) {
                        obj = {
                            poiId:num, // fake poi id for later use
                            stat:UploadStat.BAD,
                            poiName:line,
                            custPoiCode:null,
                            tel: null,
                            addr: null,
                            poiType: poiTypeSelected.poiType,
                            typeName: poiTypeSelected.typeName,
                            error: `데이터 부족`
                        };
                        errorCount++;
                    }
                    else {
                        const address = cols[1];
                        const isGood = ValueUtil.realLen(address) > 5;
                        obj = {
                            poiId:num, // fake poi id for later use
                            stat: isGood ? UploadStat.NEW : UploadStat.BAD,
                            poiName:cols[0],
                            addr:address,
                            tel: null,
                            custPoiCode:null,
                            poiType: poiTypeSelected.poiType,
                            typeName: poiTypeSelected.typeName,
                            error: isGood ? '' : '너무 짧은 주소'
                        };
                        if(!isGood) errorCount++;

                        if(cols.length > 2) {
                            if(cols[2]) obj.tel = cols[2];
                            if(cols.length >= 4) {
                                if(cols[3]) obj.custPoiCode =cols[3];
                            }
                        }
                    }
                    array.push(obj);
                }
            });
            // setRecordsParsed(array);
            // setTsvErrors(errorCount);
            return {
                errorCount: errorCount,
                records: array
            };
            // if(array.length > errorCount) setStage(WorkStage.SearchNaver);
        }
    };

    /**
     * Apply the value of results on records using poiId key
     * @param {Array} records - poi records in the browser
     * @param {Array} results - results of batch submit {poiId, saveOk, result}
     * @returns new records updated using results parameter
     */
    static refreshWithResult(records, results) {
        const resMap = {};
        for(const res of results) {
            resMap[res.poiId] = res;
        }

        return records.map((item) => {
            if(resMap[item.poiId].saveOk) {
                item.stat = UploadStat.OK;
                item.error = null;
            }
            else {
                item.stat = UploadStat.FAIL;
                item.error = resMap[item.poiId].result;
            }
            return item;
        });
    }

    static getFirstPoiOfStat(records, itemStat) {
        for(const poi of records) {
            if(poi.stat === itemStat) return poi;
        }
        return null;
    }

    static updateToRecords(records, item) {
        const array = [];
        for(const poi of records) {
            if(poi.poiId===item.poiId) {
                array.push(item);
            }
            else array.push(poi);
        }
        return array;
    }
}