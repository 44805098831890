// CodeSelector.js
import React, { useState } from 'react';
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { AppWord } from '../../model/AppConst';

export default function CodeSelector({
    id,
    label,
    codes, // code array {value, label}
    value, // initial selection if not null/undef
    onChange,
    minWidth,
    size,
}) {
    //const [value, setValue] = useState(selected);

    const onMenuSelected = (event) => {
        //setValue(event.target.value);
        onChange(id, event.target.value);
    };

    const IdInputLabel = "id-input-label-" + id;
    return(
        <FormControl
            size={size || 'medium'}
        >
            <InputLabel id={IdInputLabel}>{label}</InputLabel>
            <Select
                id={id} size={AppWord.SMALL}
                labelId={IdInputLabel}
                value={value || ''}
                onChange={onMenuSelected}
                label={label}
                style={{minWidth: minWidth ? minWidth : 100}}
            >
                {
                    codes.map((code,idx) => {
                        return(
                            <MenuItem key={code.value} value={code.value}>
                                {code.label}
                            </MenuItem>
                        );
                    })
                }
            </Select>
        </FormControl>
    );
}
