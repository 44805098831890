// TermInfoNarrowList.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { TextField } from '@mui/material';
import FlexyTable from '../common/flexytable/FlexyTable';
import { AppNumber, AppWord } from '../../model/AppConst';
import styled from '@emotion/styled';
import { TermInfoTableColumns as TableColumns } from './TermInfoGql';

const TermInfoListContainer = styled(Box)({
    flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    display: 'flex', alignItems:'center',
});

const ColumnTermId = 'termId';
export default function TermInfoNarrowList({
    itemSelected,
    columnList,
    onClickItem,
    records,
    tools,
}) {
    const [columns, setColumns] = useState([ColumnTermId]);
    const [search, setSearch] = useState('');

    useEffect(()=>{
        const newCols = [];
        for(const col of TableColumns) {
            if(columnList.includes(col.id)) {
                newCols.push(col);
            }
        }
        if(newCols.length===0) newCols.push(ColumnTermId);
        setColumns(newCols);
    }, [columnList]);

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <TermInfoListContainer>
            <HeaderBox>
                <TextField id="search" onChange={onChangeSearch}
                    value={search}
                    disabled={records.length === 0}
                    label="단말기검색" size={AppWord.SMALL}/>
            </HeaderBox>
            <FlexyTable
                name="term_info_narrow_list"
                uniqueKey="devId"
                columns={columns}
                records={records}
                selected={itemSelected ? [itemSelected] : null}
                onClickOneRow={onClickItem}
                search={search}
                tools={tools ? tools : []}
                hideFirstTopButton={true}
            />
        </TermInfoListContainer>
    )
}
