//VehTemprListRepeat.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { TextField, Button } from '@mui/material';
import ResponseAlert from '../message/ResponseAlert';
import { useInterval } from 'react-use';
import TimelineIcon from '@mui/icons-material/Timeline'; // route mode
import ReportProblemIcon from '@mui/icons-material/ReportProblem'; //Alert on temperature range
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'; // Temperature is good
import { AppWord, AppNumber, AppObject, TableColumnType } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';
import FlexyTable from '../common/flexytable/FlexyTable';
import { LIST_VEH_TEMPR } from './VehPosGql';
import { useLazyQuery } from '@apollo/client';

const VehPosListContainer = styled(Box)({
	display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block'
});

export const MiniTableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpsTime', label: '최종보고', width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    {
        id: 'tempr', label: '온도', width: 120, show: true,
        option:{
            align:'center',
            print: (record, rowIndex, value) => {
                const values = [];
                if(ValueUtil.isNumber(record.tempr1) && record.tempr1 > -333) {
                    values.push(ValueUtil.float2str(record.tempr1, 2));
                }
                if(ValueUtil.isNumber(record.tempr3) && record.tempr3 > -333) {
                    values.push(ValueUtil.float2str(record.tempr3, 2));
                }
                if(ValueUtil.isNumber(record.tempr2) && record.tempr2 > -333) {
                    values.push(ValueUtil.float2str(record.tempr2, 2));
                }
                if(values.length > 0) return values.join("/");
                return("-");
            }
        }
    }
];

const PrintTemp = (value, isBad) => {
    if(ValueUtil.isNumber(value) && value > -333) {
        if(isBad) {
            return (
                <>
                    {ValueUtil.float2str(value, 2)}
                    <ReportProblemIcon fontSize={AppWord.SMALL} color="error"
                        style={{verticalAlign:"middle", marginLeft:5}} />
                </>
            );
        }
        return (
            <>
                {ValueUtil.float2str(value, 2)}
                <InsertEmoticonIcon fontSize={AppWord.SMALL} color="success"
                    style={{verticalAlign:"middle", marginLeft:5, opacity:0.5}} />
            </>
        );
    }
    return <span>&nbsp;</span>
};

const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehAlias', label: '지도표시명', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'gpsTime', label: '최종보고', width: 165, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'tempr1', label: '전면온도', width: 80, show: true,
        option:{align: 'right', print: (r,i,v)=>{return PrintTemp(v, r.tempr1Break)}} },
    { id: 'tempr3', label: '중면온도', width: 80, show: true,
        option:{align: 'right', print: (r,i,v)=>{return PrintTemp(v, r.tempr3Break)}} },
    { id: 'tempr2', label: '후면온도', width: 80, show: true,
        option:{align: 'right', print: (r,i,v)=>{return PrintTemp(v, r.tempr2Break)}} },
    { id: 'tempr4', label: '온도4', width: 80, show: false,
        option:{align: 'right', print: (r,i,v)=>{return PrintTemp(v, r.tempr4Break)}} },
    { id: 'driverName', label: '기사', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'driverMobile', label: '휴대전화', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'vehTypeTon', label: '차종-톤수', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'location', label: '위치', width: 360, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'poiName', label: '현재지점', width: 120, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'poiArrTime', label: '지점도착', width: 165, show: true, option:{align: 'center', type: TableColumnType.DATETIME} },

    { id: 'speed', label: '속도(Km/h)', width: 90, show: false, option:{align: 'center', type: TableColumnType.FLOAT} },
    { id: 'distKm', label: '누적거리(Km)', width: 100, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr1', label: '타코온도1', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr2', label: '타코온도2', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr3', label: '타코온도3', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr4', label: '타코온도4', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr5', label: '타코온도5', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoTempr6', label: '타코온도6', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid1', label: '습도1'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid2', label: '습도2'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid3', label: '습도3'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid4', label: '습도4'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid5', label: '습도5'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoHumid6', label: '습도6'.label, width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoDist', label: '타코누적거리(Km)', width: 120, show: false, option:{align: 'right', type: TableColumnType.FLOAT} },
    { id: 'tachoRpm', label: 'RPM', width: 100, show: false, option:{align: 'right', type: TableColumnType.INTEGER} },
    { id: 'cargoRemark', label: '화물정보', width: 120, show: false, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

const FetchInterval = 61;

export default function VehTemprListRepeat({
	boxHeight,
	boxWidth,
    onClickItem,
    isMini, // minimized selector
    onClickShowRoute,
    // records,
    //timeRefresh,
    // responseList
}) {
    const [vehTemprList, setVehTemprList] = useState([]);
    const [timeGotPosList, setTimeGotPosList] = useState(0); // Date().getTime()
    const [search, setSearch] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);
    const [itemSelected, setItemSelected] = useState(null);
    // const {loading:loadingList, error:errorOfList, data:dataList} = responseList;
    const clientSize = useClientSize();

    let counter = 0;

    // ##### Call GraphQL to get List #####
    const [getVehPosList, responseList] = useLazyQuery(LIST_VEH_TEMPR, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetListRepeat(data, option)},
        // rendering에 어떤 변화가 있으면 getVehPosList 호출은 되는데 onCompleted 콜백은 호출되지 않음.
        // 그러나 responseList에 대한 useEffect는 동작함. data.vehPosList에 대한 for loop가 문제였던 것 같음. 제거하니 잘 동작.
		onError: (error) => {
            setResponseAlert({open:true, error: error, title: "위치(온도)목록가져오기 실패."})
        }
    });

    const ticker = () => {
        const now = new Date().getTime() / 1000;
        const diff = now - timeGotPosList;
        if(diff > FetchInterval) {
            setTimeGotPosList(now);
            counter = FetchInterval; // setCounter(FetchInterval);
            getVehPosList();
        }
        else {
            if(counter>0) counter--; // setCounter(counter - 1);
        }
    };

    useInterval(ticker, 1000);

    useEffect(()=>{
        if(itemSelected) {
            const item = vehTemprList.find((one) => {return one.vehId === itemSelected.vehId});
            if(item) setItemSelected(item);
            else setItemSelected(null);
        }
    }, [vehTemprList, itemSelected]);

    const onCompleteGetListRepeat = (data, clientOption) => {
        const now = new Date().getTime() / 1000;
        if(data.vehTemprList) {
            setVehTemprList(data.vehTemprList.slice());
            setTimeGotPosList(now);
        }
        else {
            setTimeGotPosList(now - FetchInterval + 5); // try again 5 sec later
        }
    };

    // >>>>>>>>> callbacks <<<<<<<<<<<<<

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
		if(onClickItem) onClickItem(item);
    };

    return(
        <VehPosListContainer width={boxWidth}>
            <HeaderBox>
                {
                    isMini ? null :
                    <ButtonBox>
                        <Button
                            disabled={!Boolean(itemSelected)}
                            onClick={()=>{onClickShowRoute(itemSelected)}}
                            variant='contained' color='primary'>
                            <TimelineIcon fontSize='small' /> 경로내 온도 보기
                        </Button>
                    </ButtonBox>
                }
                <TextField id="search" onChange={onChangeSearch}
                    value={search}
                    label="검색" size='small'/>
            </HeaderBox>
            <FlexyTable
                name="veh_temp4_list_mini"
                uniqueKey="vehId"
                columns={TableColumns}
                records={vehTemprList}
                search={search}
                tools={[]}
                onClickOneRow={onSelectItem}
                selected={itemSelected ? [itemSelected] : null}
                hideFirstTopButton={isMini}
            />
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </VehPosListContainer>
    );
}

