// App.js
// import './App.css';
import { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import AppMain from './AppMain';
import MapMain from './MapMain';
import UserSessionInfo from './com/common/UserSessionInfo';
import { Waiting, ErrorBox } from './com/message/Waiting';
import { CvoMsg, AppObject, AppWord } from './model/AppConst';
import {userInfoRepo} from './model/CvoModel';
import {useLazyQuery, useReactiveVar} from '@apollo/client';
import {GET_SESSION_DATA} from './com/session/SessionGql';
//import { MENU_TREE } from './com/menu/MenuGql';

function App() {
    //const [checkSession, {loading, error, data, called}]
    //    = useLazyQuery(GET_SESSION_DATA, AppObject.NoCachedFetch);
    const [checkError, setCheckError] = useState(null);
    const sessionInfo = useReactiveVar(userInfoRepo);
    const [snackBars, setSnackBars] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [messageInfo, setMessageInfo] = useState(undefined);
    const [checkSession, responseCheck] = useLazyQuery(GET_SESSION_DATA,
        {
            ...AppObject.NoCachedFetch,
            onCompleted: (data, option) => {onCompletionCheckSession(data, option);},
            onError: (error) => {
                setCheckError(error);
            }
        });

    /*

    const [getUserInfoItemToEdit, responseItemToEdit] = useLazyQuery(GET_USER_INFO,
        {
            ...AppObject.NoCachedFetch,
            onCompleted: (data, option) => {onCompleteGetItem(data, option)},
            onError: (error) => {setResponseAlert({open:true, error: error, title: ErrorTitle.Get})}
        });
    */
    // 지도를 띄우는지 확인해서 설정할 필요.
    useEffect(() => {
        document.title = '차량관제-eTrace';
        checkSession();
    }, []);

    useEffect(() => {
        const onBubbleSnack = (event) => {
            const snacks = [...snackBars];
            const {detail} = event;
            snacks.push({...detail});
            setSnackBars(snacks);
        };

        document.addEventListener(AppWord.SNACK_EVENT, onBubbleSnack);
        return () => {document.removeEventListener(AppWord.SNACK_EVENT, onBubbleSnack);};
    }, []);

    const onCompletionCheckSession = (data, option) => {
        if(data.getSessionData) {
            const isSignedIn = data.getSessionData.user.msg===CvoMsg.OK;
            /* userInfoRepo({
                ...data.getSessionData,
                signedIn: isSignedIn,
                sessionChecked: true
            }); */
            userInfoRepo(new UserSessionInfo(true, isSignedIn, data.getSessionData));
        }
    };

    useEffect(() => {
        if (snackBars.length && !openSnackbar) {
            // Set a new snack when we don't have an active one
            setMessageInfo(snackBars.shift());
            setSnackBars([...snackBars]);
            setOpenSnackbar(true);
        }
        else if (snackBars.length && messageInfo && openSnackbar) {
            // Close an active snack when a new one is added
            setOpenSnackbar(false);
        }
    }, [snackBars, messageInfo, openSnackbar]);

    const closeSnackBar = () => {
        setOpenSnackbar(false);
    };

    // if(loading) return <Waiting message="로그인 상태 점검중입니다."/>;
    if(checkError) return <ErrorBox messages={[
        "로그인 상태 점검중 오류가 발생했습니다.",
        "잠시 후 다시 시도해 주세요."]} />;

    const IsMapApp = window.location.href.indexOf("view=map") > 1;

    if(sessionInfo.sessionChecked) {
        return (
            <>
            {
                IsMapApp ? <MapMain /> : <AppMain />
            }
            <Snackbar
                open={openSnackbar}
                autoHideDuration={messageInfo && messageInfo.duration ? messageInfo.duration : 5000}
                onClose={closeSnackBar}
                message={messageInfo ? messageInfo.message : 'Undefined message'}
                anchorOrigin={messageInfo && messageInfo.anchorOrigin ? messageInfo.anchorOrigin : {vertical:'bottom', horizontal:'center'}}
                sx={messageInfo ? messageInfo.sx : undefined}
            />
            </>
        );
    }
    else {
        return(<Waiting message="로그인 상태 점검중입니다......"/>);
    }
}

export default App;

/*
Queries:
getSessionData: SessionData
login(userId: String!,password: String!): SessionData
loginAs(userId: String!): SessionData
logout: SessionData
actLogList(sessionKey: String,regUserId: String): [ActLog]!
actLogListLogin(timeFrom: String,timeTill: String): [ActLog]!
addressGroupList: [AddressGroup]!
addressInfoList: [AddressInfo]!
addressInfoItem(addressId: Int): AddressInfo
alarmTypeList: [AlarmType]!
bcdIoDataList(timeFrom: String,timeTill: String): [BcdIoData]!
bcdIoDataListForVehicle(vehId: Int,timeFrom: String,timeTill: String): [BcdIoData]!
bcdIoDataListForPoi(poiId: Int,timeFrom: String,timeTill: String): [BcdIoData]!
bcdNoticeMoreList: [BcdNoticeMore]!
bcdNoticeMoreItem(vehId: Int,poiId: Int): BcdNoticeMore
codeTree: [CodeType!]!
codeDetailList(cdType: String): [CodeDetail!]!
cruiseLogListForVeh(vehId: Int): [CruiseLog]!
cruiseLogListForPoi(poiId: Int): [CruiseLog]!
custAgreeList: [CustAgree]!
custAgreeListAll(custId: String): [CustAgree]!
custAgreeItem(groupId: Int!): CustAgree
custAgreeListGiving: [CustAgree]!
custAgreeItemGiving(custId: String!,groupId: Int!): CustAgree
custAgreeListForUser(userId: String!): [CustAgree]!
custGetAlarmList: [CustGetAlarm]!
custGetAlarmItem(alarmTypeId: Int,mobile: String): CustGetAlarm
custGetAlarmListForAlarm(alarmTypeId:Int): [CustGetAlarm]!
custGetAlarmListForMobile(mobile:String): [CustGetAlarm]!
custHistoryList(custId: String): [CustHistory]!
custHistoryItem(custId: String,recId: Int): CustHistory
custInfoList: [CustInfo]!
custInfoItem(custId: String): CustInfo
custOwnCodeTree: [CustOwnCodeKind]!
custPeopleList(custId: String!): [CustPeople]!
custPeopleItem(custId: String!,recId: Int!): CustPeople
custPoiTypeList: [CustPoiType]!
custPoiTypeItem(poiType: Int): CustPoiType
cvoFileList(forWhom: String): [CvoFile]!
cvoFileItem(fileNid: Int): CvoFile
getArchiveList: [Download]!
downloadFileList(msgSeq: Int): [DownloadFile]!
downloadFileItem(msgSeq: Int,fileNid: Int): DownloadFile
downloadInfoList: [DownloadInfo]!
downloadInfoItem(msgSeq: Int): DownloadInfo
dtgMakersList: [DtgMakers]!
dtgModelsList: [DtgModels]!
groupVehAllocList: [GroupVehAlloc]!
groupVehAllocListOf(vehId: Int,groupId: Int): [GroupVehAlloc]!
groupVehAllocItem(vehId: Int,groupId: Int): GroupVehAlloc
iconPoolList: [IconPool]!
menuToUserTree: [MenuKind]!
menuToUserTreeForSelection(userId: String!): [MenuKind]!
naverSearchRoute(searchInput: NaverRouteArgDto!): ResponseWithRoute!
notifyBoardList: [NotifyBoard]!
notifyBoardItem(msgSeq: Int!): NotifyBoard
notifyFileList(msgSeq: Int): [NotifyFile]!
notifyFileItem(msgSeq: Int,fileNid: Int): NotifyFile
poiGroupList: [PoiGroup]!
poiGroupItem(groupNid: Int!): PoiGroup
poiInfoList: [PoiInfo]!
poiInfoListForMap: [PoiInfo]!
poiInfoItem(poiId: Int): PoiInfo
getPosReportLog(routeDate: String): [PosReportLog]!
getPosReportNotiLog: [PosStatViewLog]!
getPosDownloadLog: [PosDownloadLog]!
getServiceLog(svcTime: String): [ServiceLog]!
getPosUsageLog: [PosStatViewLog]!
getSessionLog(actDate: String): [SessionLog]!
routeDailyList(dateFrom: String,dateTill: String): [RouteDaily]!
routeDailyBillList(routeDate: String): [RouteDailyBill]!
routeHourlyList(date: String): [RouteHourly]!
routeHourlyListOff(date: String): [RouteHourlyAllowed]!
routeMonthlyList(routeDate: String!): [RouteMonthly]!
routeMonthlyListOfVeh(vehId: Int!,dateFrom: String!,dateTill: String!): [RouteMonthly]!
routineDistribLogListForVeh(vehId: Int): [RoutineDistribLog]!
routineDistribLogListForPoi(poiId: Int): [RoutineDistribLog]!
smsMessageList(dateFrom: String,dateTill: String): [SmsMessage]!
smsPhraseList: [SmsPhrase]!
smsPhraseItem(phraseId: Int!): SmsPhrase
sweeperStatList(routeDate: String!): [SweeperStat]!
termChangeLogList(termId: String): [TermChangeLog]!
termInfoList(custId: String): [TermInfo]!
termInfoListAvailable(custId: String): [TermInfo]!
termInfoItem(devId: Int): TermInfo
termMakerInfoList: [TermMakerInfo]!
termMngList(termMngSearch: InputTermMngSearch!): [TermMng]!
termMngItem(termMngSeq: Int!): TermMng
termMngCustList: [TermMngCust]!
termMngLogList(termMngLogSearch: InputTermMngLogSearch!): [TermMngLog]!
termMngLogItem(logNid: Int!): TermMngLog
termMngLogCustList: [TermMngLogCust]!
termModelInfoList: [TermModelInfo]!
termStateLogList(devId: Int): [TermStateLog]!
termStockList(dateFrom: String!,dateTill: String!): [TermStock]!
termStockListSum: [TermStock]!
termStockListAt(ioDate: String!): [TermStock]!
termStockItem(ioDate: String!,modelId: Int!): TermStock
termsStatList: [TermsStat]!
termsStatItem(custId: String): TermsStat
termsStatTotal: TermsStat
userGetAlarmList(userId: String): [UserGetAlarm]!
userInfoList(custId: String): [UserInfo]!
userInfoListOverGroup(groupId: Int!): [UserInfo]!
userInfoItem(userId: String!): UserInfo
userPoiRelationPoiListForUser(userPoiRel: String,userId: String): [UserPoiRelation]!
userPoiRelationUserListForPoi(userPoiRel: String,poiId: Int): [UserPoiRelation]!
userTcolConf21List: [UserTcolConf21]!
userTcolConf21Item(viewName: String!): UserTcolConf21
vehConfList: [VehConf]!
vehConfItem(vehId: Int): VehConf
vehGroupList(custId: String): [VehGroup]!
vehGroupListForVeh(vehId: Int!): [VehGroup]!
vehGroupListForUser(userId: String!): [VehGroup]!
vehGroupItem(groupId: Int!): VehGroup
vehGroupBcdMapList: [VehGroupBcdMap]!
vehGroupBcdMapListForPoi(poiId: Int): [VehGroupBcdMap]!
vehGroupBcdMapItem(groupId: Int,poiId: Int): VehGroupBcdMap
vehInfoList(custId: String): [VehInfo]!
vehInfoItem(custId: String,vehId: Int!): VehInfo
vehInfoListOverGroup(groupId: Int!): [VehInfoOverGroup]!
vehInfoListInGroup(groupId: Int!): [VehInfoOverGroup]!
vehInfoListEditable: [VehPlatesInfo]!
canUserEdit(vehId: Int!): UserCanEditVehInfoVo!
vehReportStatItem(custId: String): VehReportStat
vehInfoLogList(vehId: Int!,custId: String): [VehInfoLog]!
vehItinList(vehId: Int,timeFrom: String,timeTill: String): [VehItin]!
vehPosList: [VehPos]!
vehTemprList: [VehTempr]!
vehRouteList(vehId: Int,timeFrom: String,timeTill: String): [VehRoute]!
vehRouteTemprList(vehId: Int,timeFrom: String,timeTill: String): [VehRouteTempr]!
vehTonInfoList(custId: String): [VehTonInfo]!
vehTonInfoItem(vehTonId: Int): VehTonInfo
vehTypeInfoItem(vehTypeId: Int): VehTypeInfo
viewCruiseList: [ViewCruise]!
viewRoutineDistribList: [ViewRoutineDistrib]!
viewTelecomStatusList(custId: String): [ViewTelecomStatus]!


*/