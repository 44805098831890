//
import React, {useState, useEffect} from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { IconButton, Menu, MenuItem, Fade } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AppPalette, AppWord } from '../../model/AppConst';

const MenuContainer = styled(Box)({
    display:'inline-flex', alignItems:'center',
});

const MenuLabel = styled('span')({fontWeight:'bold', display:'inline-block', marginRight:5});

export const ReportMenuKey = {
    posReportLog: {key:'a_pr1', label:'위치정보 수집사실 확인자료'},
    posReportNotiLog: {key:'b_prn2', label:'위치정보 수집사실 열람, 고지 확인자료'},
    posDownloadLog:{key:'c_pd3', label:'위치정보 아카이브 다운로드 기록'},
    serviceLog:{key:'d_sL4', label:'위치정보 이용사실 확인자료'},
    posUsageLog:{key:'e_pu5', label:'위치정보 이용, 제공사실 열람, 고지 확인자료'},
    sessionLog:{key:'f_ses6', label:'시스템 접근 이력'}
};

export default function RegulationMenu({
    menu,
    onChange
}) {
    //const [menu, setMenu] = useState({});
    const [anchor, setAnchor] = useState(null);

    /*
    useEffect(()=>{
        if(!menu.key) {
            setMenu(ReportMenuKey.posReportLog);
        }
    }, [menu, setMenu]);*/

    const onClickMenu = (menuObject) => {
        setAnchor(null);
        if(menu.key !== menuObject.key) {
            //setMenu(menuObject);
            onChange(menuObject);
        }
    };

    const menuId = 'reg-rep-menu-id';
    const buttonId = menuId + '-button';
    const open = Boolean(anchor);
    return (
        <>
            <MenuContainer>
                <MenuLabel>{menu.label}</MenuLabel>
                <IconButton size={AppWord.SMALL}
                    id={buttonId}
                    aria-controls={open ? menuId : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e)=>{setAnchor(e.currentTarget)}}
                    color={AppPalette.PrimaryColor}
                    style={{marginRight:15}}
                >
                    <MenuIcon fontSize={AppWord.SMALL} />
                </IconButton>
            </MenuContainer>
            <Menu
                id={menuId}
                MenuListProps={{'aria-labelledby':buttonId}}
                anchorEl={anchor}
                open={open}
                onClose={()=>setAnchor(null)}
                TransitionComponent={Fade}
            >
                {
                    Object.values(ReportMenuKey).sort((a,b)=>{
                        if(a.key > b.key) return 1;
                        if(a.key < b.key) return -1;
                        return 0;
                    }).map((menu)=>{
                        return(
                            <MenuItem key={menu.key} onClick={()=>onClickMenu(menu)}>{menu.label}</MenuItem>
                        );
                    })
                }
            </Menu>
        </>
    );
}
