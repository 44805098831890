// TermStateLogList.js
import React from 'react';
import FlexyTable from '../common/flexytable/FlexyTable';
import { TableColumnType } from '../../model/AppConst';

const TableColumns = [
	{ id: 'termId', label: '단말기', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'changeTime', label: '변경시각', width: 160, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'termStateValue', label: '상태', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regUserId', label: '작업자ID', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regUserName', label: '작업자', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
	//{ id: 'devId', label: Dict.devId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    //{ id: 'termStateCd', label: Dict.termStateCd.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    //{ id: 'isLatest', label: Dict.isLatest.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function TermStateLogList({records}) {
    return(
        <FlexyTable
            name="term_state_log_list"
            uniqueKey="dataKey"
            columns={TableColumns}
            records={records}
            tools={[]}
        />
    );
}
