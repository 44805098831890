// VehItinList.js
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
//import useClientSize from '../hook/useClientSize';
import {
    Link, Button,
    Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import CabinIcon from '@mui/icons-material/Cabin'; // stay more than 4 hours
import ChairIcon from '@mui/icons-material/Chair'; // stay more than 2 hors
import LocalParkingIcon from '@mui/icons-material/LocalParking'; // stay 0.5 hour or more
import LocationOnIcon from '@mui/icons-material/LocationOn'; // stay less than 0.5 hour
import ValueUtil from '../../model/ValueUtil';
import ResponseAlert from '../message/ResponseAlert';
import { AppNumber, AppWord, AppObject } from '../../model/AppConst';
import { useLazyQuery } from '@apollo/client';
import DurationPicker from '../common/DurationPicker';
import { LIST_VEH_ITIN } from './VehItinGql';
import VehRouteListMini from '../veh_route/VehRouteListMini';
import dayjs from 'dayjs';
import useClientSize from '../hook/useClientSize';

const LocationBoxHeight = 45;
const dcPad = AppNumber.dataContainerPadding;
const VehItinListContainer = styled(Box)({
    position:"absolute", top:0, right:0, bottom: 0, left: dcPad,
	display:'flex', flexDirection:'column'
});

const ItinTableContainer = styled(Box)({
    flexGrow:1,
    position:'relative',
    whiteSpace:'nowrap',
    border: '1px solid #ddd',
    borderRadius: 3, //backgroundColor:'yellow'
});
const ItinTableWrapper = styled(Box)({
    //position: 'relative',
    position:'absolute', top:50, left:0, right:0, bottom:0,
    display: "flex",
    flexWrap: "wrap", alignContent: 'flex-start',
    overflowY: "auto", //backgroundColor:'#ddd',
    paddingTop: 5,
    "& > *": {
      margin: 1,
    },
});
const IconIndexBox = styled(Box)({
    position:'absolute', top:0, left:0, right:0, padding:10, textAlign:"right",
    display: "flex", alignItems:'center', justifyContent: AppWord.END,
    borderBottom:'1px solid #ddd',
    backgroundColor: '#efefff'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '& > *': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '& > *': {marginLeft:10}
});

const ItinItemBox = styled(Box)({
    display:'inline-block',
    marginBottom: 10
});

const ItinPathBox = styled(Box)({
    display:'inline-block',
    minWidth:120,
    textAlign: 'right', verticalAlign:'bottom'
});

const RunDistBox = styled(Box)({
    padding:10,
});

const RunDurationBox = styled(Box)({
    borderTop: "5px solid #555", borderTopStyle: "dashed", padding:10, height:LocationBoxHeight
});

const ItinPlaceBox = styled(Box)({
    display:'inline-block', verticalAlign:'bottom',
    width: 240,
    borderRadius: 10,
    border: '1px solid #ccc',
    fontSize:'0.95rem'
});

const StayIconStyle = {marginRight:10};
const PlaceTimeBox = styled(Box)({ padding: 10 });
const PlaceTime = styled(Box)({ padding: '4px 0px 0px 34px', color:'#333', fontSize: '0.9em' });

const LocationBox = styled(Box)({
    borderTop: '5px solid #eee', padding:10, height:LocationBoxHeight,
    wordWrap: 'break-word', whiteSpace: 'break-spaces',
    overflow: 'hidden'
});

// ---------
const RouteContainer = styled(Box)({
    position:'relative'
});

const EmptyDuration = {from:null, till:null};

export default function VehItinList({
    vehPos,
    maxWidth, maxHeight, // for dialog
    onClickShowPosList,
    onClickGetRoute, // onClickGetRoute(duration);
}) {
    const [duration, setDuration] = useState(EmptyDuration);
    const [vehItinList, setVehItinList] = useState([]);
    const [responseAlert, setResponseAlert] = useState(null);
    const [durationRoute, setDurationRoute] = useState(null);
    const clientSize = useClientSize();

    useEffect(()=>{
        if(vehPos) {
            if(vehPos.gpsTime) {
                if(vehPos.gpsTime === AppWord.NOT_REAL_GPS_TIME) setDuration(EmptyDuration);
                else {
                    const djs = dayjs(vehPos.gpsTime);
                    const date = djs.toDate();
                    const from = ValueUtil.getYmdHmsInputText(date, 86400);
                    setDuration({from:from, till: vehPos.gpsTime});
                }
            }
            else setDuration(EmptyDuration);
        }
        else setDuration(EmptyDuration);
        setVehItinList([]);
    }, [vehPos]);

    // ##### Call GraphQL to get List #####
    const [getVehItinList, responseList] = useLazyQuery(LIST_VEH_ITIN, {
        ...AppObject.NoCachedFetch,
        onCompleted: (data, option) => {onCompleteGetList(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: "동정정보 조회 오류"})}
    });

    const onCompleteGetList = (data, clientOption) => {
        if(data.vehItinList) {
            setVehItinList(data.vehItinList.slice());
        }
    };

    const onChangeFrom = (from) => {
        const dur = {...duration};
        dur.from = from;
        setDuration(dur);
    };

    const onChangeTill = (till) => {
        const dur = {...duration};
        dur.till = till;
        setDuration(dur);
    };

    const onClickToSeeRoute = (itin) => {
        // onClickGetRoute({from: itin.timePrevStop, till: itin.timeArrival});
        setDurationRoute({from: itin.timePrevStop, till: itin.timeArrival});
    };

    const onClickToGetList = () => {
        if(vehPos.vehId && duration.from && duration.till)
            getVehItinList({
                variables: {
                    vehId: vehPos.vehId,
                    timeFrom: duration.from,
                    timeTill: duration.till}
            });
    };

    const renderIconIndex = () => {
        return(
            <IconIndexBox style={{textAlign:"right"}}>
                <CabinIcon size={AppWord.SMALL} color='success' style={StayIconStyle} />
                4시간 초과 주차 &nbsp;
                <ChairIcon size={AppWord.SMALL} color='primary' style={StayIconStyle} />
                2시간 초과 주차 &nbsp;
                <LocalParkingIcon size={AppWord.SMALL} color='info' style={StayIconStyle} />
                30분 초과 주차 &nbsp;
                <LocationOnIcon size={AppWord.SMALL} color='inherit' style={StayIconStyle} />
                30분 이하 주차
            </IconIndexBox>
        );
    };

    const renderStayIcon = (itin) => {
        if(itin.staySec > 14400) return <CabinIcon size={AppWord.SMALL} color='success' style={StayIconStyle} />;
        if(itin.staySec > 7200)  return <ChairIcon size={AppWord.SMALL} color='primary' style={StayIconStyle} />;
        if(itin.staySec > 1800)  return <LocalParkingIcon size={AppWord.SMALL} color='info' style={StayIconStyle} />;
        return <LocationOnIcon size={AppWord.SMALL} color='inherit' style={StayIconStyle} />;
    };

    const dialogWidth = clientSize.dataAreaWidth > 1000 ? 1000 : clientSize.dataAreaWidth * 0.8;

    return(
        <VehItinListContainer>
            <HeaderBox>
                <ButtonBox>
                    <Button variant='contained' color="inherit"
                        onClick={()=>{onClickShowPosList()}}
                    >
                        닫기
                    </Button>
                </ButtonBox>
                <span style={{fontWeight:'bold'}}>
                {vehPos.vehPlates} &nbsp;</span>
                <DurationPicker
                    from={duration.from} onChangeFrom={onChangeFrom}
                    till={duration.till} onChangeTill={onChangeTill}
                />
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!Boolean(duration) || !Boolean(vehPos)}
                    onClick={onClickToGetList}
                    style={{marginLeft:10, marginRight:20}}
                >
                    조회
                </Button>
            </HeaderBox>
            <ItinTableContainer>
                {renderIconIndex()}
                <ItinTableWrapper>
                    {
                        vehItinList.length===0
                        ?
                        <Box style={{
                            flex:1, height:'90%',
                            display:'flex', justifyContent:'center', alignItems:'center'}}
                        >
                            <Box style={{
                                display:'inline-block', width:340,
                                wordWrap: 'break-word', whiteSpace: 'break-spaces',
                                border: "5px solid #eee", borderRadius:20, padding:20
                            }}>
                                <Box style={{fontWeight:'bold'}}>
                                    <TipsAndUpdatesIcon fontSize='large' color='success'/> &nbsp; &nbsp;
                                    {vehPos.vehPlates} 차량의 동정정보
                                </Box>
                                <ul>
                                    <li>기간을 정한 후 조회 버튼을 누르세요.</li>
                                    <li>기본으로 입력되는 기간은 최근 위치기준 24시간 전부터입니다.</li>
                                    <li>정보가 출력된 후 이동거리 링크를 눌러 경로를 조회할 수 있습니다.</li>
                                </ul>
                            </Box>
                        </Box>
                        :
                        vehItinList.map((item) => {
                            return(
                                <ItinItemBox key={item.keyTime}>
                                    <ItinPathBox>
                                        <RunDistBox>
                                            <Link onClick={()=>{onClickToSeeRoute(item)}} style={{cursor:'pointer'}}
                                                title='경로보기'
                                            >
                                                {item.distBetween} Km 이동
                                            </Link>
                                        </RunDistBox>
                                        <RunDurationBox
                                            style={{color: item.runSec > 7200 ? 'red' : 'inherit'}}
                                        >
                                            {ValueUtil.formatDuration(item.runSec)} 운행
                                        </RunDurationBox>
                                    </ItinPathBox>
                                    <ItinPlaceBox>
                                        <PlaceTimeBox>
                                            <Box>
                                                {renderStayIcon(item)}
                                                {ValueUtil.formatDuration(item.staySec)} 주차
                                            </Box>
                                            <PlaceTime>{item.timeArrival.substring(0,16)} 도착</PlaceTime>
                                            <PlaceTime>{item.timeDeparture.substring(0,16)} 출발</PlaceTime>
                                        </PlaceTimeBox>
                                        <LocationBox>
                                            {item.location}
                                        </LocationBox>
                                    </ItinPlaceBox>
                                </ItinItemBox>
                            );
                        })
                    }
                </ItinTableWrapper>
            </ItinTableContainer>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
            <Dialog open={Boolean(durationRoute)}
                onClose={()=>{setDurationRoute(null)}}
                maxWidth="lg"
            >
                <DialogTitle>
                    {vehPos.vehPlates} 이동경로
                </DialogTitle>
                <DialogContent
                    style={{minWidth:dialogWidth}}>
                    <RouteContainer height={clientSize.dataAreaHeight-50 - 200}>
                        <VehRouteListMini
                            vehPos={vehPos}
                            durationGiven={durationRoute}
                            maxHeight={clientSize.dataAreaHeight-50 - 20}
                            maxWidth={clientSize.dataAreaWidth - dcPad * 3 - 160}
                        />
                    </RouteContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setDurationRoute(null)}} variant='contained'>닫기</Button>
                </DialogActions>
            </Dialog>
        </VehItinListContainer>
    );

}