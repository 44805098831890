//CodeCheckerGroup.js
import React, { useEffect, useState } from 'react';
import { styled  } from '@mui/material/styles';
import { Box } from '@mui/system';
import { IconButton, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const CheckerGroupBox = styled(Box)({
    display:'inline-block',
    padding: 10,
    minWidth: 170,
});

const HeaderBox = styled(Box)({
    display:'block',
    paddingLeft: 10,
});

const BodyBox = styled(Box)({
    display:'block',
    border: '1px solid #dddddd',
    borderRadius: 10,
    padding: 10,
});

export default function CodeCheckerGroup({
    codeGroup,
    checked, // array of checked codes
    onChange
}) {
    const [codesChecked, setCodesChecked] = useState(checked); // array
    const [checkState, setCheckState] = useState(
        codeGroup.codes.reduce((accum, code) => {
            return {...accum, [code.cd]: checked.includes(code.cd)}
        }, {})
    ); // object. true false

    /* useEffect(() => {
        const cs = {};
        for(const code of codeGroup.codes) {
            cs[code.cd] = checked.includes(code.cd);
        }
        setCheckState(cs);
    }, [codeGroup, checked]); */

    const onChangeCheckbox = (cd, event) => {
        const cs = {...checkState};
        cs[cd] = event.target.checked;
        const checked = [];
        for(const key of Object.keys(cs)) {
            if(cs[key]) checked.push(key);
        }
        if(onChange) {
            onChange(codeGroup.cdType, checked);
        }
        setCodesChecked(checked);
        setCheckState(cs);
    };

    const clearAll = () => {
        const cleared = {...checkState};
        for(const cd of Object.keys(checkState)) {
            cleared[cd] = false;
        }
        setCodesChecked([]);
        setCheckState(cleared);
        if(onChange) {
            onChange(codeGroup.cdType, []);
        }

    };

    return (
        <CheckerGroupBox>
            <HeaderBox>
                {
                    codesChecked.length > 0 ?
                    <IconButton size='small' style={{verticalAlign:'middle'}}
                        onClick={clearAll}
                    >
                        <FilterAltOffIcon />
                    </IconButton>
                    : null
                }
                <span style={{margin:'15px 0px 15px 5px', fontWeight:'bold', display:'inline-block'}}>
                    {codeGroup.cdTypeName}
                </span>
            </HeaderBox>
            <BodyBox>
                <FormGroup>
                    {
                        codeGroup.codes.map((code) => {
                            /* 
                            return(
                                <Box key={code.cd}>{code.cd} is {code.cdName}</Box>
                            );
                            */
                            return(
                                <FormControlLabel key={code.cd}
                                    label={code.cdName}
                                    control={
                                        <Checkbox
                                            checked={checkState[code.cd]}
                                            onChange={(e)=>onChangeCheckbox(code.cd, e)}
                                        />
                                    }
                                />
                            );
                        })
                    }
                </FormGroup>
            </BodyBox>
        </CheckerGroupBox>
    )
}


/*

React complaint when I use matirial ui checker with checked value parent components provide:
Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component.

and emits warnings for each Checkbox like :
MUI: A component is changing the uncontrolled checked state of SwitchBase to be controlled.
Elements should not switch from uncontrolled to controlled (or vice versa).
Decide between using a controlled or uncontrolled SwitchBase element for the lifetime of the component.
The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.

The snippent is like:

<FormGroup>
    {
        codeGroup.codes.map((code) => {
            return(
                <FormControlLabel key={code.cd}
                    label={code.cdName}
                    control={
                        <Checkbox
                            checked={checkState[code.cd]}
                            onChange={(e)=>onChangeCheckbox(code.cd, e)}
                        />
                    }
                />
            );
        })
    }
</FormGroup>
What could be wrong in my code?


*/