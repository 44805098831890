// MapPoiInfoList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment, Button } from '@mui/material';
import {NoUser, poiInfoRepo, userInfoRepo} from '../../model/CvoModel';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import ClearIcon from '@mui/icons-material/Clear';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StartIcon from '@mui/icons-material/Start';
//import SportsScoreIcon from '@mui/icons-material/SportsScore';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppObject, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { GET_POI_INFO, PoiInfoDictionary } from '../poi_info/PoiInfoGql';
import ResponseAlert from '../message/ResponseAlert';
import Util from '../../model/Util';

const PoiInfoListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});

const TableBox = styled(Box)({flexGrow:1, display:'flex'});
const ButtonBox = styled(Box)({flexGrow:1, textAlign:'right'});

const TableColumns = [
    {
        id: 'poiName', label: '지점', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT},
        icon: {
            getView : (record) => record.useBcdYn==='Y' ? <HighlightAltIcon fontSize={AppWord.SMALL} style={{marginRight:5}}/> : null,
            //view: <HighlightAltIcon fontSize={AppWord.SMALL} style={{marginRight:5}}/>,
            //condition: (record)=>record.useBcdYn==='Y'
        }
    },
    { id: 'tel', label: '연락처', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'addr', label: '주소', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custPoiCode', label: '고객지점코드', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'typeName', label: '지점타입', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    // { id: 'bcdMapped', label: '출발도착감지', width: 100, show: true, option:{align: 'center', print: (r,i,v) => {return v ? '있음' : '-'}} },
    { id: 'etc', label: '비고', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

const PoiErrorTitle =PoiInfoDictionary.errorTitle;

/**
 * 지도 화면에서 POI 목록 출력.
 */
export default function MapPoiInfoList({
    // records,
    onClickItem,
    onRequestTypeManager,
    onRequestRemove,
    onRequestEdit,
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const records = useReactiveVar(poiInfoRepo);
    const [search, setSearch] = useState('');
    const [responseAlert, setResponseAlert] = useState(null);

    // 수정 요청 시 편집대상 데이터를 (일반적인 흐름과 달리) 이 안에서 처리함. 
    // ##### GraphQL Mutation.###
    const [getPoiInfoItemToEdit, responseItem] = useLazyQuery(GET_POI_INFO, {
		...AppObject.NoCachedFetch,
		onCompleted: (data, option) => {onCompleteGetItem(data, option)},
		onError: (error) => {setResponseAlert({open:true, error: error, title: PoiErrorTitle.Get})}
	});

    // >>>>>>>>> callbacks <<<<<<<<<<<<<
    const onCompleteGetItem = (data, option) => {
        if(data.poiInfoItem) {
            onRequestEdit(data.poiInfoItem);
        }
    };

    const requestItemToEdit = (row) => {
        getPoiInfoItemToEdit({variables: {poiId:row.poiId}});
    };

    const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onSelectItem = (item) => {
		if(onClickItem) onClickItem(item);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: requestItemToEdit(row); break;
            case AppWord.CMD_SET_START:
                Util.bubbleNaverRoutePoint({isStart:true, data:{lat:row.lat, lon:row.lon, address: row.addr}});
                break;
            case AppWord.CMD_SET_END:
                Util.bubbleNaverRoutePoint({isStart:false, data:{lat:row.lat, lon:row.lon, address: row.addr}});
                break;
            default: return;
        }
    };

    if(ValueUtil.hasAnyAuthError(
        responseItem
    )) userInfoRepo(NoUser);

    const RowTools = [
        {id:AppWord.CMD_SET_START, icon:<StartIcon fontSize={AppWord.SMALL} />},
        {id:AppWord.CMD_SET_END, icon: <KeyboardTabIcon fontSize={AppWord.SMALL} />}
    ];
    if(isAdmin) RowTools.push(
            {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
            {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} />});

    const InputID = "search-map-PoiInfo-list";
    return(
        <PoiInfoListContainer>
            <HeaderBox>
				<FormControl variant={AppPalette.VariantOutlined} size={AppWord.SMALL}>
					<InputLabel htmlFor={InputID} style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id={InputID} onChange={onChangeSearch}
						value={search}
						disabled={Boolean(records) ? records.length === 0 : true}
						size={AppWord.SMALL}
                        style={{width:140}}
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
                <ButtonBox>
                    <Button variant={AppPalette.VariantContained} onClick={onRequestTypeManager}>
                        <SettingsApplicationsIcon fontSize={AppWord.SMALL} />
                        &nbsp; 지점타입
                        </Button>

                </ButtonBox>
            </HeaderBox>
            <TableBox>
                <FlexyTable
                    name="map_poi_info_list"
                    uniqueKey="poiId"
                    columns={TableColumns}
                    records={Boolean(records) ? records : []}
                    search={search}
                    tools={RowTools}
                    onClickTool={onClickToolOnRow}
                    onClickOneRow={onSelectItem}
                />
            </TableBox>
            <ResponseAlert open={responseAlert ? responseAlert.open : false}
                alertData={responseAlert}
                onClose={() => {setResponseAlert(null)}}/>
        </PoiInfoListContainer>
    );
}