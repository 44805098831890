// AppHeader.js
import React from 'react';
import { styled  } from '@mui/material/styles';
import {useReactiveVar} from '@apollo/client';
import { Box } from '@mui/system';
import { AppNumber, AppPalette } from '../../model/AppConst';
import AppMenuInHeader from '../menu/AppMenuInHeader';
import { currentMenuRepo } from '../../model/CvoModel'; // {signedIn:bool, user:{}, userAs:{}}

const LOGO_WIDTH = 200;

const HeaderContainer = styled(Box)({
    width: '100%',
    height: AppNumber.HeaderHeight,
    verticalAlign:'bottom',
    overflow:"hidden",
    position:"fixed",
    top:0,left:0,
    backgroundColor:AppPalette.HeaderBackground,
    color:"#e8eadb",
});


const HeaderRow = styled(Box)({
    marginTop:10, verticalAlign:'bottom', // backgroundColor:'yellow',
    position: "absolute",
    left:0, bottom: 0, right:0
});
const LogoBox = styled(Box)({
    display:"inline-block", fontSize:"32pt", color:"white",
    verticalAlign:'bottom',
    paddingLeft: 30,
    fontStyle:"italic", fontWeight:"bold"
});
const TitleBox = styled(Box)({
    display:"inline-block",
    fontSize:"18pt",
    textAlign:"Left",
    verticalAlign:'bottom',
    paddingLeft: 30,
    paddingBottom: 10,
});

export default function AppHeader({showMenu, onClickMenuInHeader}) {
    const menuInfo = useReactiveVar(currentMenuRepo);

    //                    onMouseEnter={onClickHeaderMenu}
    const onClickHeaderMenu = () => {onClickMenuInHeader()};

    return (
        <HeaderContainer>
            <HeaderRow>
                <LogoBox width={LOGO_WIDTH}>eTrace</LogoBox>
                <TitleBox >{menuInfo.menuName}</TitleBox>
                <AppMenuInHeader
                    onClick={onClickHeaderMenu} />
            </HeaderRow>
        </HeaderContainer>
    );
}
