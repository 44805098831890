// poi_info/PoiInfoGql.js
import { gql } from "@apollo/client";
import ValueUtil from "../../model/ValueUtil";



export const PoiInfoDictionary = {
    inputMap: {
        poiId : { required: true, toAdd: false, toEdit: true, },
        poiName : {
            id: 'poiName', label: '지점', type: 'text', required: true,
            toAdd: true, toEdit: true,
            help: '지점명을 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,80)},
        },
        poiType : {
            id: 'poiType', label: '지점타입', type: 'select', required: true,
            toAdd: true, toEdit: true,
            help: '지점타입을 선택하세요',
            check: (value) => {return value > 0 && value < 99999},
        },
        custPoiCode : {
            id: 'custPoiCode', label: '고객지점코드', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '30자 이내 영문, 숫자, -, 컴마, 마침표로 구성',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isRegexpMatch(value, /^[-,.\w]+$/)},
        },
        /* 의미 없음. 디폴트로 넣어주자.
        roadKind : {
            id: 'roadKind', label: 'roadKind label', type: 'select', required: false,
            toAdd: true, toEdit: true,
            help: '20자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,20)},
        }, */
        lat : { required: true, toAdd: true, toEdit: true, },
        lon : { required: true, toAdd: true, toEdit: true, },
        xDist : { required: true, toAdd: true, toEdit: true, },
        yDist : { required: true, toAdd: true, toEdit: true, },
        zoneShape : { required: false, toAdd: true, toEdit: true, // 폴리곤은 KATEK 좌표 사용함.
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.isRegexpMatch(value, /^POLYGON\(\(.*\)\)$/)},
            // POLYGON ((x1 y1, x2 y2, x3 y3, ......, x1 y1))
        },
        radius : { required: false, toAdd: true, toEdit: true, },
        // xmin,xmax,ymin,ymax : Procedure에서 
        // st_x(st_pointn(st_boundary(st_envelope(zone_shape)),1)) as x1
        // st_x(st_pointn(st_boundary(st_envelope(zone_shape)),2)) as x2 과 같이 구해서 넣음.
        addr : {
            id: 'addr', label: '주소', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '500자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,500)},
        },
        tel : {
            id: 'tel', label: '연락처', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '15자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,15)},
        },
        // branchOrder : 항상 0,
        etc : {
            id: 'etc', label: '비고', type: 'text', required: false,
            toAdd: true, toEdit: true,
            help: '500자 이내로 입력하세요.',
            check: (value) => {return ValueUtil.isNullString(value) || ValueUtil.textInRange(value,1,500)},
        },
    },
    errorTitle: {
        Add: '지점 정보 생성 오류',
        Edit: '지점 정보 수정 오류',
        Remove: '지점 정보 삭제 오류',
        Get: '지점 정보 조회 오류',
        List: '지점 목록 조회 오류',
    }
};

const POI_INFO_LIST_PART = gql`
fragment PoiInfoListPart on PoiInfo {
    poiId
    custId
    poiName
    poiType
    typeName
    custPoiCode
    lat
    lon
    xDist
    yDist
    useBcdYn
    bcdMapped
    zoneShape
    xmin
    xmax
    ymin
    ymax
    addr
    tel
    etc
    viewLevel
    fontColor
    backColor
    fontSize
    iconName
    iconSaveName
    iconContentType
    svgText
    cutWidth
    height
    cutCount
}
`;

const POI_INFO_NO_LIST_PART = gql`
fragment PoiInfoNoListPart on PoiInfo {
    radius
    poiSeq
    roadKind
    roadKindValue
    branchOrder
    iconPath
    iconSize
    regUserId
    regTime
}
`;

export const LIST_POI_INFO = gql`
${POI_INFO_LIST_PART}
query ListPoiInfo {
    poiInfoList {
        ...PoiInfoListPart
    }
}
`;

export const LIST_POI_INFO_FOR_MAP = gql`
${POI_INFO_LIST_PART}
query ListPoiInfoForMap {
    poiInfoListForMap {
        ...PoiInfoListPart
    }
    custPoiTypeList {
        custId
        poiType
        typeName
        typeDesc
        groupNid
        viewLevel
        iconSeq
        fontColor
        backColor
        fontSize
        iconName
        iconSaveName
        svgText
        iconPath
        iconSize
        iconContentType
        cutWidth
        height
        cutCount
    }
}
`;


export const GET_POI_INFO = gql`
${POI_INFO_LIST_PART}
${POI_INFO_NO_LIST_PART}
query GetPoiInfo($poiId:Int) {
    poiInfoItem(poiId:$poiId) {
        ...PoiInfoListPart
        ...PoiInfoNoListPart
    }
}
`;

export const ADD_POI_INFO = gql`
${POI_INFO_LIST_PART}
mutation AddPoiInfo($poiInfo:InputPoiInfoAdd!) {
    poiInfoAdd(poiInfo: $poiInfo) {
        ok
        message
        list {
            ...PoiInfoListPart
        }
    }
}
`;

export const ADD_POI_INFO_ONLY = gql`
mutation AddPoiInfoOnly($poiInfo:InputPoiInfoAdd!) {
    poiInfoAddOnly(poiInfo: $poiInfo) {
        ok
        message
    }
}
`;

export const EDIT_POI_INFO = gql`
${POI_INFO_LIST_PART}
mutation EditPoiInfo($poiInfo:InputPoiInfoEdit!) {
    poiInfoEdit(poiInfo: $poiInfo) {
        ok
        message
        list {
            ...PoiInfoListPart
        }
    }
}
`;

export const REMOVE_POI_INFO = gql`
${POI_INFO_LIST_PART}
mutation RemovePoiInfo($poiInfo:InputPoiInfoRemove!) {
    poiInfoRemove(poiInfo: $poiInfo) {
        ok
        message
        list {
            ...PoiInfoListPart
        }
    }
}
`;

export const BATCH_POI_INFO = gql`
mutation BatchPoiInfo($poiInfoList:[InputPoiInfoForBatch!]!) {
    poiInfoBatch(poiInfoList:$poiInfoList) {
        ok
        message
        list {
            poiId
            poiName
            saveOk
            result
        }
    }
}
`;
