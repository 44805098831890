//
import VehTonNode from "./VehTonNode";

export default class VehTypeNode {
    custId = null;
    vehTypeId = 0;
    vehTypeName = null;

    // boolRoot = true; // 이 노드가 루트인가?
    // boolChecked = false;
    children = []; // of type VehTonNode

    setFromTonNode = (tonNode) => {
        this.custId = tonNode.custId;
        this.vehTypeId = tonNode.vehTypeId;
        this.vehTypeName = tonNode.vehTypeName;
    };

    addChild = (tonInfo) => {this.children.push(tonInfo)};

    equalTo = (node) => {
        if(node) return node.getPrimaryData() === this.getPrimaryData();
        return false;
    };
    getPrimaryData = () => {return this.vehTypeId;};
    getLabel = () => {return this.vehTypeName;};
    hasParent = () => false;
    getParentNodeId = () => undefined;
    isRoot = () => true;
    isChecked = () => false;
    // check = () => {this.boolChecked = true;};
    // uncheck = () => {this.boolChecked = false;};
    toggle = () => {return};
    hasChildren = () => true;
    uncheckAll = () => {
        for(const child of this.children) child.uncheckAll();
    };

    check = (tonId) => {
        for(const child of this.children) {
            if(child.checkIfSame(tonId)) return true;
        }
    };

    getPrimaryDataChecked = () => {
        const rv = [];
        for(const ton of this.children) {
            if(ton.isChecked()) rv.push(ton.getPrimaryData());
        }
        return rv;
    };

    getTypeTonChecked = () => {
        // only children
        const rv = [];
        for(const ton of this.children) {
            if(ton.isChecked()) rv.push(ton.vehTypeTon);
        }
        return rv;
    };

    static makeTypeTonTree(array) {
        const vehTypes = [];
        const map = {};

        for(const tt of array) {
            var typeInfo;

            const tonInfo = new VehTonNode();
            tonInfo.setFromObject(tt);

            if(!map.hasOwnProperty(tt.vehTypeId)) {
                typeInfo = new VehTypeNode();
                typeInfo.setFromTonNode(tonInfo);
                map[tt.vehTypeId] = typeInfo;
                vehTypes.push(typeInfo);
            }
            else {
                typeInfo = map[tt.vehTypeId];
            }
            typeInfo.addChild(tonInfo);
        }

        return vehTypes;
    }

    static getTonIdsChecked(array) {
        var ids = [];
        for(const vtype of array) {
            ids = ids.concat(vtype.getPrimaryDataChecked());
        }
        return ids;
    }

    static getPrimaryDataChecked(array) {
        var ids = [];
        for(const vtype of array) {
            ids = ids.concat(vtype.getTypeTonChecked());
        }
        return ids;
    }

    static checkTon(types, tonId) {
        for(const type of types) {
            if(type.check(tonId)) return;
        }
    }
}
