// AddressInfoList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FlawedList from '../message/FlawedList';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import { AddressInfoDictionary } from './AddressInfoGql';

const LocalTitle = '주소목록'; // ###################################

const AddressInfoListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    position: 'relative',
    height: AppNumber.HeaderToolBoxHeight,
    padding: AppNumber.SmallBoxPadding,
    '&>*': {verticalAlign: 'middle'}
});

const ButtonBox = styled(Box)({
    float:'right',
    display:'inline-block',
    '&>*': {marginLeft:10}
});

const Dict = AddressInfoDictionary.inputMap;
const TableColumns = [
    { id: 'addrGroupName', label: Dict.addrGroupId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'fullname', label: Dict.fullname.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'company', label: Dict.company.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'department', label: Dict.department.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'workRank', label: Dict.workRank.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'job', label: Dict.job.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'zipCode', label: Dict.zipCode.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'address1', label: Dict.address1.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    // { id: 'address2', label: Dict.address2.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'hpNo', label: Dict.hpNo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'telNo', label: Dict.telNo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'email', label: Dict.email.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'etc', label: Dict.etc.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    // { id: 'senderYn', label: Dict.senderYn.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'regDate', label: '등록일', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATE} }
];

export default function AddressInfoList({
	maxHeight,
	maxWidth,
	selected,
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
    responseList,
}) {
    const [search, setSearch] = useState('');
    const [itemSelected, setItemSelected] = useState(selected);
    const {loading:loadingList, error:errorOfList, data:dataList} = responseList;

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
        setItemSelected(item);
		if(onClickItem) onClickItem(item);
    };

    // Handling list event
    if(ValueUtil.flawedListResponse(responseList))
        return <FlawedList title={LocalTitle} gqlResponse={responseList} />;

    return(
        <AddressInfoListContainer>
            <HeaderBox>
                <ButtonBox>
                    <Button
                        onClick={onRequestAdd}
                        variant='contained' color='primary'>
                        <AddIcon fontSize={AppWord.SMALL} /> 주소추가
                    </Button>
                </ButtonBox>
                <TextField id="search" onChange={onChangeSearch}
                    value={search}
                    label="검색" size={AppWord.SMALL}/>
            </HeaderBox>
            <FlexyTable
                name="address_info_list"
                uniqueKey="addressId"
                columns={TableColumns}
                records={dataList.addressInfoList}
                search={search}
                tools={[
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} />}
                ]}
                onClickTool={onClickToolOnRow}
                onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </AddressInfoListContainer>
    );

}

/*

            //////<PanTable
                name="address_info_list"
                uniqueKey="addressId"
                height={TableHeight} width={width}
                columns={TableColumns}
                records={dataList.addressInfoList}
                search={search}
                criteria={filterCriteria}
                tools={[
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} />}
                ]}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />

*/
