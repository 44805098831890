// CvoFileList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { Button, IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import {userInfoRepo} from '../../model/CvoModel';
import AddIcon from '@mui/icons-material/Add';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FlexyTable from '../common/flexytable/FlexyTable';
import ValueUtil from '../../model/ValueUtil';
import { AppNumber, AppWord, TableColumnType } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';
import { CvoFileDictionary } from './CvoFileGql';

const CvoFileListContainer = styled(Box)({
	flexGrow:1, display:'flex', flexDirection:'column'
});

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const HeaderControl = styled(Box)({ flexGrow:1, display:'flex', alignItems:'center' });
const ButtonBox = styled(Box)({'&>*': {marginLeft:10}});

const Dict = CvoFileDictionary.inputMap;
const TableColumns = [
    { id: 'memo', label: Dict.memo.label, width: 160, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'filePath', label: Dict.url.label, width: 540, show: true,
        option:{
            align: 'inherit',
            print: (r,i,v) => {
                return(
                    <span>
                        <a href={r.driveLink} target="_blank" rel="noopener noreferrer">
                            <DownloadForOfflineIcon fontSize={AppWord.SMALL} style={{verticalAlign:'bottom'}}/>
                        </a>
                        <a href={v} target="_blank" rel="noopener noreferrer">{v}</a>
                    </span>
                );
            }
        } },
    { id: 'forWhom', label: Dict.forWhom.label, width: 70, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'fileSize', label: Dict.fileSize.label, width: 90, show: true, option:{align: 'center', print: (r,i,v) => {return ValueUtil.getSizeInText(v)}} },
    //{ id: 'conType', label: Dict.conType.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'usingNotify', label: "공지사항에서 사용", width: 120, show: true, option:{align: 'center', type: TableColumnType.INTEGER} },
    { id: 'usingDownload', label: "파일다운로드 사용", width: 120, show: true, option:{align: 'center', type: TableColumnType.INTEGER} },
    { id: 'regTime', label: "등록일", width: 100, show: true, option:{align: 'center', print: (r,i,v)=>{return v ? v.substring(0,10) : ''}} }
];

export default function CvoFileList({
    records,
	selected,
    onClickItem,
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
}) {
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [search, setSearch] = useState('');

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const onClickToolOnRow = (toolId, row) => {
        switch(toolId) {
            case AppWord.CMD_REMOVE: onRequestRemove(row); break;
            case AppWord.CMD_EDIT: onRequestEdit(row); break;
            default: return;
        }
    };

    const onSelectItem = (item) => {
		if(onClickItem) onClickItem(item);
    };

	const isAdmin = ValueUtil.isEtrace(sessionInfo);

    return(
        <CvoFileListContainer>
            <HeaderBox>
                <HeaderControl>
                    <FormControl variant='outlined' size={AppWord.SMALL}>
                        <InputLabel htmlFor="search-CvoFile-list" style={{backgroundColor:'white'}}>검색</InputLabel>
                        <OutlinedInput id="search-CvoFile-list" onChange={onChangeSearch}
                            value={search}
                            disabled={records.length === 0}
                            size={AppWord.SMALL}
                            endAdornment={
                                Boolean(search) ?
                                <InputAdornment position={AppWord.END}>
                                    <IconButton
                                    onClick={()=>{setSearch('')}}
                                    size={AppWord.SMALL}
                                    edge={AppWord.END}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                                :
                                null
                            }
                        />
                    </FormControl>
                </HeaderControl>
                <ButtonBox>
				{
					isAdmin
					?
						<Button
							onClick={onRequestAdd}
							variant='contained' color='primary'>
							<AddIcon fontSize={AppWord.SMALL} />
							파일 추가
						</Button>
					: null
				}
                </ButtonBox>
            </HeaderBox>
            <FlexyTable
                name="cvo_file_list"
                uniqueKey="fileNid"
                columns={TableColumns}
                records={records}
                search={search}
                tools={isAdmin ? [
                    {id:AppWord.CMD_EDIT, icon:<NoteAltIcon fontSize={AppWord.SMALL} />},
                    {id:AppWord.CMD_REMOVE, icon: <DeleteForeverIcon fontSize={AppWord.SMALL} />}
                ] : []}
                onClickTool={onClickToolOnRow}
				onClickOneRow={onSelectItem}
				selected={selected ? [selected] : null}
            />
        </CvoFileListContainer>
    );

}