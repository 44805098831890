// ViewCruiseList.js
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import useClientSize from '../hook/useClientSize';
import { Link, Button, IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import {userInfoRepo, NoUser} from '../../model/CvoModel';
import TimelineIcon from '@mui/icons-material/Timeline'; // route mode
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery'; // for WAIT
import DoneIcon from '@mui/icons-material/Done'; // for DONE
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel'; // RUNNING (READY)
import EditLocationIcon from '@mui/icons-material/EditLocation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HelpTopic from '../common/HelpTopic';
import ValueUtil from '../../model/ValueUtil';
import InfoIconButton from '../common/InfoIconButton';
import PopMenuIconButton from '../common/PopMenuIconButton';
import VehRouteListMini from '../veh_route/VehRouteListMini';
import { AppNumber, AppPalette, AppWord } from '../../model/AppConst';
import { useReactiveVar } from '@apollo/client';

const CellPadding = 5;

const ViewCruiseListContainer = styled(Box)({ flexGrow:1, display:'flex', flexDirection:'column' });

const HeaderBox = styled(Box)({
    display:'flex', alignItems:'center', height: AppNumber.HeaderToolBoxHeight, padding: AppNumber.SmallBoxPadding,
});
const SearchBox = styled(Box)({flexGrow:1});

const CruiseListBox = styled(Box)({
    flexGrow:1, overflow:'auto', border: AppPalette.BorderCCC, borderRadius: 3, // backgroundColor:'yellow'
});

//const TableBox = styled(Box)({display:'table', width:'100%', textAlign:'center'});
const TableRowBox = styled(Box)({display:'flex',margin: "5px 5px 0px 5px",}); // table-row
const CellVehicle = styled(Box)({ // 차량번호.
    width: 140, border:AppPalette.BorderCCC, borderRadius:10, padding: '5px 5px 0px 5px', marginBottom:20
});
const CellMove = styled(Box)({
    textAlign:'center', padding: CellPadding
});
const CellDepart = styled(Box)({ // 출발지.
    width:200, padding: CellPadding, border:AppPalette.BorderCCC, borderRadius:10, textAlign:'center', marginBottom:20,
    '&:hover':{backgroundColor:'#bfe'}
});
const CellRoute = styled(Box)({ // 모든 도착지display:'table-cell' textAlign:'left',
    flexGrow:1, justifyContent:'left', position:'relative'
});
const RouteWrapper = styled(Box)({
    position:'absolute',top:0,left:0,right:0,bottom:0, textAlign:'left',
    overflowX:'auto', overflowY:'hidden', width:'100%',
    whiteSpace:'nowrap'
});
const VehicleWrapper = styled(Box)({textAlign:'center'});
const VehicleName = styled('span')({fontWeight:'bold', padding:5});
const DriverBox = styled(Box)({ fontSize:'0.8rem', color:'#555' }); // name and mobile in CellVehicle

const PoiWrapper = styled(Box)({display:'inline-block'});
const RoadMarkBox = styled(Box)({
    display:'inline-block', maxWidth:33, overflow:'hidden', whiteSpace:'nowrap', color:'#ccc'
});

const DestinWrapper = styled(Box)({ // 도착지 1개.
    display:'inline-block', padding: CellPadding, border:AppPalette.BorderCCC, borderRadius:10, height: '100%',
    '&:hover':{backgroundColor:'#9df'}
});
const PoiAndTimes = styled(Box)({display:'inline-block', textAlign:'center'});
const PoiName = styled('span')({fontWeight:'bold', color:'#035', padding:5});
const AtaAndAtd = styled(Box)({fontSize:'0.8rem', color:'#333', textAlign:'center', padding:5});
const TimeLabel = styled(Box)({display:'inline-block', minWidth:40, padding:3, border:AppPalette.BorderCCC, borderRadius:3, margin:1});
const StateLabel = styled(Box)({display:'inline-block', padding:3, border:'1px solid ' + AppPalette.PrimaryRGB, borderRadius:3, margin:1, color:AppPalette.PrimaryRGB});
const ButtonBox = styled('span')({paddingLeft:10});

const HelpCell = styled(Box)({display:'table-cell', padding:20, width:'100%'});
const HelpBox = styled(Box)({display:'flex', justifyContent:'center', textAlign:'left'});
const HelpRoad = styled(Box)({paddingTop:20, paddingLeft:10, color:'#77a'});

const RouteContainer = styled(Box)({ position:'relative' });
/*
const TableColumns = [
    { id: 'vehPlates', label: Dict.vehPlates.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'recId', label: Dict.recId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'vehId', label: Dict.vehId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'depNotiDoneYn', label: Dict.depNotiDoneYn.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'userId', label: Dict.userId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'lastNotiTime', label: Dict.lastNotiTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'centerAta', label: Dict.centerAta.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'atdFrom', label: Dict.atdFrom.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'ataTo', label: Dict.ataTo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} },
    { id: 'poiIdDep', label: Dict.poiIdDep.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'poiIdArr', label: Dict.poiIdArr.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'sendDepMsgTo', label: Dict.sendDepMsgTo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'sendArrMsgTo', label: Dict.sendArrMsgTo.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'arrNotiDoneYn', label: Dict.arrNotiDoneYn.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'currentFlag', label: Dict.currentFlag.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'groupId', label: Dict.groupId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'routeId', label: Dict.routeId.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'regTime', label: Dict.regTime.label, width: 120, show: true, option:{align: 'inherit', type: TableColumnType.DATETIME} }
];
55연3333: 홈플부천3 - 남천안5 -  등촌홈플3
55연4444: 홈플러스동대전점 - 남천안5 - (화성휴게3) - 홈플러스작전점
55연5555: 홈플러스대전둔산점 - 남천안5 - (죽전휴게5)- 문래홈플5 - (기흥휴게5)

*/

function MdHm(ymdhms) {
    if(Boolean(ymdhms) && ymdhms.length >= 16) {
        return ymdhms.substring(5,7) + '/' + ymdhms.substring(8,10) + ' ' + ymdhms.substring(11,16);
    }
    return '';
}

function hhmm(ymdhms) {
    if(Boolean(ymdhms) && ymdhms.length >= 16) {
        return ymdhms.substring(11,16);
    }
    return '';
}

const Movement = {DONE:'DONE', WAIT:'WAIT', GONE:'GONE', READY:'READY', INSIDE:'INSIDE', NEXT:'NEXT'}; // 루트 상태 또는 차량과 지점의 상대적 위치.

export default function ViewCruiseList({
    onRequestAdd,
    onRequestEdit,
    onRequestRemove,
    onRequestSetDone,
    onRequestSetReady,
    onRequestGetLogForVeh,
    onRequestTruncate,
    onClickPoi,
    records
}) {
    const [durationRoute, setDurationRoute] = useState(null);
	const sessionInfo = useReactiveVar(userInfoRepo);
    const [search, setSearch] = useState('');
    const clientSize = useClientSize();

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };
	
	const isAdmin = ValueUtil.isCustAdmin(sessionInfo);

    const filterRoutes = (route) => {
        if(Boolean(search)) {
            if(search.length > 0) {
                if(route.vehPlates.indexOf(search) >= 0) return true;
                if(Boolean(route.poiNameDep) && route.poiNameDep.indexOf(search) >= 0) return true;
                for(const dest of route.destins) {
                    if(Boolean(dest.poiNameArr)) {
                        if(dest.poiNameArr.indexOf(search) >= 0) return true;
                    }
                }
                return false;
            }
        }
        return true;
    };

    // 경로리스트 팝업.
    const onClickShowRoute = (route) => {
        const from = route.atdFrom || route.centerAta;
        let till = ValueUtil.getYmdHmsInputText();
        for(const dest of route.destins) {
            if(Boolean(dest.ataTo) || Boolean(dest.ataFrom)) {
                till = dest.ataTo || dest.ataFrom;
            }
        }
        setDurationRoute({vehId:route.vehId, vehPlates:route.vehPlates, from: from, till: till});
    };

    const onClickRoutePopMenu = (cmd, route) => {
        if(cmd===AppWord.CMD_ROUTE) onClickShowRoute(route);
        else if(cmd===AppWord.CMD_LOG) onRequestGetLogForVeh(route);
        if(isAdmin) {
            if(cmd===AppWord.CMD_EDIT) onRequestEdit(route);
            else if(cmd===AppWord.CMD_REMOVE) onRequestRemove(route);
            else if(cmd===AppWord.CMD_DONE) onRequestSetDone(route);
            else if(cmd===AppWord.CMD_READY) onRequestSetReady(route);
        }
    };

    /*

                        <IconButton
                            disabled={route.movement === Movement.WAIT}
                            onClick={()=>{onClickShowRoute(route)}}
                        >
                            <TimelineIcon fontSize={AppWord.SMALL}
                                color={route.movement === Movement.WAIT ? AppPalette.InheritColor : AppPalette.PrimaryColor} />
                        </IconButton>
    */
    const getPopMenuSet = (route) => {
        const menuSet = [];
        if(isAdmin) {
            if(route.routeRunning) {
                menuSet.push({id:AppWord.CMD_READY, label:'대기상태로 강제변경', icon:<BrowseGalleryIcon size={AppWord.SMALL} color={AppPalette.WarnColor}/>});
                menuSet.push({id:AppWord.CMD_DONE, label:'완료상태로 강제변경', icon:<DoneIcon size={AppWord.SMALL} color={AppPalette.WarnColor}/>});
            }
            else {
                menuSet.push({id:AppWord.CMD_EDIT, label:'수정', icon:<EditLocationIcon size={AppWord.SMALL}/>});
                menuSet.push({id:AppWord.CMD_REMOVE, label:'삭제', icon:<DeleteForeverIcon size={AppWord.SMALL}/>});
            }
        }
        if(route.movement !== Movement.WAIT) {
            menuSet.push({id:AppWord.CMD_ROUTE, label:'경로보기', icon:<TimelineIcon size={AppWord.SMALL}/>});
        }
        menuSet.push({id:AppWord.CMD_LOG, label:'차량 배송기록 보기', icon: <CalendarTodayIcon size={AppWord.SMALL}/>});
        return menuSet;
    }; // {id:AppWord.CMD_EDIT, label:"Click it", icon:<NoteAltIcon fontSize='small' />}

    const dialogWidth = clientSize.dataAreaWidth > 1000 ? 1000 : clientSize.dataAreaWidth * 0.8;

    const renderRouteDialog = () => {
        const maxHeight = clientSize.dataAreaHeight - 200;
        const maxWidth = clientSize.dataAreaWidth - 200;
        const vehPlates = Boolean(durationRoute) ? durationRoute.vehPlates : '';
        return(
            <Dialog open={Boolean(durationRoute)}
                onClose={()=>{setDurationRoute(null)}}
                maxWidth="lg"
            >
                <DialogTitle>
                    {vehPlates} 이동경로
                </DialogTitle>
                <DialogContent style={{minWidth:dialogWidth}}>
                    <RouteContainer height={maxHeight}>
                        <VehRouteListMini
                            vehPos={durationRoute}
                            durationGiven={durationRoute}
                            maxHeight={maxHeight - 200}
                            maxWidth={maxWidth - AppNumber.dataContainerPadding}
                        />
                    </RouteContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{setDurationRoute(null)}} variant='contained'>닫기</Button>
                </DialogActions>
            </Dialog>);
    };

    const renderHelp = () => {
        return(
            <HelpCell>
                <HelpBox>
                    <HelpTopic
                        title="배송노선 설정이란"
                        content={
                            <ul>
                                <li>배송노선 설정은 차량이 출발지로부터 1개 이상의 목적지로 순행하는 상황을 모니터링하기 위한 설정입니다.</li>
                                <li>출발지 출발 시 모든 목적지로 문자 알림을 전송합니다.</li>
                                <li>출발지 출발이나 목적지 도착 시 해당 시각을 기록하므로 후에 출발/도착 정보를 확인할 수 있습니다.</li>
                                <li>고정된 정기배송의 경우에는 "정기배송노선 관리" 기능을 사용하는 것이 좋습니다.</li>
                                <li>실제 필요한 경우에만 사용하시면 됩니다.</li>
                            </ul>
                        }
                    />
                </HelpBox>
                    <HelpBox>
                        <HomeWorkIcon fontSize='large' color={AppPalette.PrimaryColor} style={{verticalAlign:'bottom'}}/>
                        <HelpRoad>========</HelpRoad>
                        <WhereToVoteIcon fontSize='large' color={AppPalette.PrimaryColor} />
                        <HelpRoad>========</HelpRoad>
                        <WhereToVoteIcon fontSize='large' color={AppPalette.PrimaryColor} />
                        <HelpRoad>--------</HelpRoad>
                        <LocalShippingIcon fontSize='large' color={AppPalette.SuccessColor} />
                        <HelpRoad>--------</HelpRoad>
                        <LocationOnIcon fontSize='large' color={AppPalette.PrimaryColor} />
                    </HelpBox>
            </HelpCell>
        );
    };

    const NoTimeColor = '#eee';
    const NoTimeBox = <TimeLabel style={{backgroundColor:NoTimeColor, width:30}}>&nbsp;</TimeLabel>;

    const renderDestin = (dest, route, idx) => {
        return (
            <PoiWrapper key={dest.poiIdArr}>
                <RoadMarkBox>{
                    route.movement === Movement.DONE ? "_____" :
                    route.movement === Movement.WAIT ? "-----" :
                    dest.movement === Movement.GONE ? "=====" :
                    dest.movement === Movement.NEXT
                    ? <>-<LocalShippingIcon color={AppPalette.PrimaryColor} fontSize={AppWord.SMALL} />-</>
                    : <span style={{color:AppPalette.PrimaryRGB}}>-----</span>
                    }
                </RoadMarkBox>
                <DestinWrapper>
                    <PoiAndTimes>
                        <PoiName>
                            <Link onClick={()=>{onClickPoi(dest.poiIdArr)}} underline='hover' color={AppPalette.InheritColor}>
                                {dest.poiNameArr}
                            </Link>
                        </PoiName>
                        {
                            route.movement===Movement.WAIT 
                            ?
                            <AtaAndAtd>{NoTimeBox}{NoTimeBox}</AtaAndAtd>
                            :
                            <AtaAndAtd>
                                {
                                    dest.ataTo ? <TimeLabel>{hhmm(dest.ataTo)}</TimeLabel> : NoTimeBox}
                                {
                                    dest.atdFrom
                                    ? <TimeLabel>{hhmm(dest.atdFrom)}</TimeLabel>
                                    :
                                    dest.movement===Movement.INSIDE
                                    ? <TimeLabel style={{borderColor:AppPalette.PrimaryRGB, color:AppPalette.PrimaryRGB}}>출발대기</TimeLabel>
                                    : NoTimeBox
                                }
                            </AtaAndAtd>
                        }
                    </PoiAndTimes>
                </DestinWrapper>
            </PoiWrapper>
        );
    };

    const renderCruiseRecord = (route, idx) => {
        return(
            <TableRowBox key={idx}>
                {
                    isAdmin
                    ?
                    <CellMove>
                        <PopMenuIconButton
                                dataKey={route.routeId}
                                callbackData={route}
                                menus={getPopMenuSet(route)}
                                onClickMenu={onClickRoutePopMenu}
                            />
                    </CellMove>
                    : null
                }
                <CellVehicle>
                    <VehicleWrapper>
                        <VehicleName>{route.vehPlates}</VehicleName>
                        <DriverBox>
                            {route.driverName} {
                            route.driverMobile
                                ? <InfoIconButton icon={<PhoneIphoneIcon fontSize={AppWord.SMALL}/>}
                                    text={'휴대전화 '+route.driverMobile}
                                    />
                                : <IconButton disabled={true}><PhoneIphoneIcon color="disabled"/></IconButton>
                            }
                        </DriverBox>
                    </VehicleWrapper>
                </CellVehicle>
                <CellMove>
                    {
                        route.movement === Movement.DONE
                        ? <DoneIcon color='inherit' fontSize='large' />
                        :
                        route.movement === Movement.WAIT
                        ? <BrowseGalleryIcon color='disabled' fontSize='large' />
                        : <ModeOfTravelIcon color={AppPalette.PrimaryColor} fontSize='large' />
                    }
                    <Box>
                        {
                            route.movement === Movement.DONE
                            ? "완료"
                            :
                            route.movement === Movement.WAIT
                            ? "대기"
                            : "진행"
                        }
                    </Box>
                </CellMove>
                <CellDepart>
                    <PoiAndTimes>
                        <PoiName>
                            <Link onClick={()=>{onClickPoi(route.poiIdDep)}} underline='hover' color={AppPalette.InheritColor}>
                                {route.poiNameDep}
                            </Link>
                        </PoiName>
                        <AtaAndAtd style={{paddingBottom:0}}>
                            {
                                route.movement===Movement.WAIT
                                ? <><StateLabel>대기</StateLabel> 배송대기</>
                                :
                                route.movement===Movement.READY
                                ? <><StateLabel>진행</StateLabel> 배송진행</>
                                :
                                route.movement===Movement.INSIDE
                                ?
                                    <>
                                        <TimeLabel>{MdHm(route.centerAta)}</TimeLabel>
                                        <TimeLabel style={{borderColor:AppPalette.PrimaryRGB, color:AppPalette.PrimaryRGB}}>출발대기</TimeLabel>
                                    </>
                                :
                                    <>
                                    {route.centerAta ? <TimeLabel>{MdHm(route.centerAta)}</TimeLabel> : NoTimeBox}
                                    {route.atdFrom ? <TimeLabel>{MdHm(route.atdFrom)}</TimeLabel> : NoTimeBox}
                                    </>
                            }
                        </AtaAndAtd>
                    </PoiAndTimes>
                </CellDepart>
                <CellRoute>
                    <RouteWrapper>
                    {
                        route.destins.map((dest, idx)=>{
                            return renderDestin(dest, route, idx)
                        })
                    }
                    </RouteWrapper>
                </CellRoute>
            </TableRowBox>
        );
    };

    return(
        <ViewCruiseListContainer>
            <HeaderBox>
                <SearchBox>
                    <FormControl variant='outlined' size='small'>
                        <InputLabel htmlFor="search-ViewCruise-list" style={{backgroundColor:'white'}}>검색</InputLabel>
                        <OutlinedInput id="search-ViewCruise-list" onChange={onChangeSearch}
                            value={search}
                            disabled={records.length === 0}
                            size='small'
                            endAdornment={
                                Boolean(search) ?
                                <InputAdornment position={AppWord.END}>
                                    <IconButton
                                    onClick={()=>{setSearch('')}}
                                    size={AppWord.SMALL}
                                    edge={AppWord.END}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                                :
                                null
                            }
                        />
                    </FormControl>
                </SearchBox>
				{
					isAdmin
					?
					<ButtonBox>
						<Button
							onClick={onRequestAdd}
							variant='contained' color='primary'>
							<AddIcon fontSize='small' />
							배송노선 추가
						</Button>
					</ButtonBox>
					: null
				}
            </HeaderBox>
            <CruiseListBox>
                    {
                        records.length === 0
                        ?
                        <TableRowBox>
                            {renderHelp()}
                        </TableRowBox>
                        :
                        records.filter(filterRoutes).map((route, idx) => {
                            return renderCruiseRecord(route, idx);
                        })
                    }
            </CruiseListBox>
            {renderRouteDialog()}
        </ViewCruiseListContainer>
    );

}