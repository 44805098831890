// PosReportLog.js
import React, { useState } from 'react';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment, TextField, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import NearMeIcon from '@mui/icons-material/NearMe';
import FlexyTable from '../common/flexytable/FlexyTable';
import { AppNumber, AppPalette, AppWord, TableColumnType } from '../../model/AppConst';
import ValueUtil from '../../model/ValueUtil';

const PosReportContainer = styled(Box)({
    flexGrow:1, display:'flex', flexDirection: 'column'
});

const HeaderBox = styled(Box)({
    height: AppNumber.HeaderToolBoxHeight, display:'flex', alignItems:'center',
});

const TableColumns = [
    { id: 'vehPlates', label: '차량번호', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'routeDate', label: '수집일자', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.DATE} },
    { id: 'etrace', label:'수집자', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'svc', label: '차량관제', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'custName', label: '업체명', width: 150, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'hhmm_from', label: '수집시작', width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'hhmm_till', label: '수집종료', width: 100, show: true, option:{align: 'center', type: TableColumnType.TEXT} },
    { id: 'positionCount', label: '보고회수', width: 120, show: true, option:{align: 'inherit', type: TableColumnType.INTEGER} },
    { id: 'model', label: '단말기모델', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
    { id: 'collMean', label: '수집방식', width: 100, show: true, option:{align: 'inherit', type: TableColumnType.TEXT} },
];

export default function PosReportLog({records, menu, onRequestData}) {
    const [search, setSearch] = useState('');
    const [date, setDate] = useState(ValueUtil.getYmdText(86400));

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <PosReportContainer>
            <HeaderBox>
                <NearMeIcon color={AppPalette.PrimaryColor}/>
                <span style={{display:'inline-block', marginRight:10, color:'#ccc'}}>.....</span>
                {menu}
                <TextField
                    type="date"
                    value={date}
                    size={AppWord.SMALL}
                    onChange={(e)=>{setDate(e.target.value);}}
                />
                <Button
                    variant={AppPalette.VariantContained}
                    style={{marginRight:20, marginLeft:5}}
                    disabled={!Boolean(date)}
                    onClick={()=>{onRequestData(date)}}
                >
                    조회
                </Button>
				<FormControl variant='outlined' size='small'>
					<InputLabel htmlFor="search-RouteDaily-list" style={{backgroundColor:'white'}}>검색</InputLabel>
					<OutlinedInput id="search-RouteDaily-list" onChange={onChangeSearch}
						value={search}
						disabled={records.length === 0}
						size='small'
						endAdornment={
							Boolean(search) ?
							<InputAdornment position={AppWord.END}>
								<IconButton
								onClick={()=>{setSearch('')}}
								size={AppWord.SMALL}
								edge={AppWord.END}
								>
									<ClearIcon />
								</IconButton>
							</InputAdornment>
							:
							null
						}
					/>
				</FormControl>
            </HeaderBox>
            <FlexyTable
                name="pos_report_log_list"
                uniqueKey="vehId"
                columns={TableColumns}
                records={records}
                search={search}
                tools={[]}
            />
        </PosReportContainer>
    );
}
